import React from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Resource, Content, Text } from 'components/common'
import { BusinessError } from 'navigation/BusinessError'
import { FileDownloadButton } from './common'
import {
  ISectionResponse,
  IUserSchoolProfileTypeEnum,
  IContentResponse,
  IMediaResponse
} from 'services/types'
import { useStore } from 'store'
import Analytics from 'utils/analytics'
import useStyle from '../../../../style'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import Curriculum from '../Curriculum'
import PrintButton from 'pages/ContentUnit/components/PrintButton'

interface IStartPointProps {
  sections: ISectionResponse[]
  language: string
  isCloeExpand: boolean
}

interface IWorkbookResource extends IMediaResponse {
  resource_id: number
  resource_type: string
}

export const StartPoint: React.FC<IStartPointProps> = ({ sections, language, isCloeExpand }) => {
  const { contentId, classId } = useParams<{ contentId: string, classId: string }>()
  const classes = useStyle()
  const { session, profile, schoolPeriod } = useStore()
  const subscription = session?.subscriptions.find(cur => cur.user_school_profile.id === profile?.id && cur.class.id === Number(classId))
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  if (!sections.length) {
    return <BusinessError error={t('Falha ao carregar seções')} />
  }

  const handleEventAnalyticsDownload = (params: IWorkbookResource) => {
    Analytics.recordEventClick({
      name: 'activity_workbook_download_pdf',
      attributes: {
        ...subscription?.analytics,
        profile_type: profile?.type,
        content_unit_id: contentId,
        resource_id: params.resource_id,
        resource_type: params.resource_type,
        period_status: schoolPeriod?.current
      }
    })
  }

  const showFileDownloadButton = (section: ISectionResponse) => {
    const files: IWorkbookResource[] = []
    section.resources?.forEach(cur => {
      cur.medias?.forEach(media => files.push({ ...media, resource_id: cur.id, resource_type: cur.type }))
    })

    return files.map((file, fileIndex) => (
      <FileDownloadButton
        title={`${t('Baixar PDF', true)} ${files.length > 1 ? (fileIndex + 1) : ''}`}
        fileUrl={file.url}
        fileName={file.name}
        dataTestId='starting_point_download_book_activities'
        onClick={() => handleEventAnalyticsDownload(file)}
      />
    ))
  }

  return (
    <div className={classes.readingContainer}>
      {
        sections
          .map((section, index) =>
            <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {
                index === 0
                  ? <Grid container m={0} justifyContent='space-between'>
                    <Grid item xs={8} sm={6}>
                      <h1 className={classes.title}>
                        {t(section.title)}
                      </h1>
                    </Grid>
                    <Grid item xs={4} sm={6} textAlign='right'>
                      <PrintButton
                        content={contentId}
                        mode={IUserSchoolProfileTypeEnum.teacher}
                        lng={language}
                        dataTestId='starting_point_print_starting_point'
                      />
                    </Grid>
                  </Grid>
                  : <h1 className={classes.title}>
                    {t(section.title)}
                  </h1>
              }
              {
                section.text &&
                  <Text content={section.text ?? ''} />
              }
              {section.section_type === 'workbook' && (
                <Grid container xs={12} pt={2} pb={4} rowSpacing={2} columnSpacing={2}>
                  {showFileDownloadButton(section)}
                </Grid>
              )}
              {
                section.contents.length > 0
                  ? (
                    <div className={classes.contentText}>
                      {
                        section.contents.map((content: IContentResponse) => {
                          return <Content content={content} key={content.id} type={content.content_type} />
                        })
                      }
                    </div>
                  )
                  : <></>
              }
              {
                section?.resources && section.section_type !== 'workbook' &&
                section.resources.map(cur =>
                  <React.Fragment key={cur.id}>
                    <Resource key={cur.id} resource={cur} />
                    <br />
                  </React.Fragment>)
              }
            </div>)
      }
      <Curriculum
        contentId={contentId}
        isCloeExpand={isCloeExpand}
      />
    </div>
  )
}

export default StartPoint
