import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  cardMonth: {
    width: theme.spacingStack.xxxl,
    height: theme.spacingStack.md,
    background: theme.colorBrand.lightest,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingStack.nano,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '141px',
      height: theme.spacingStack.lg,
      fontSize: theme.font.fontSize.md
    }
  }
}))
