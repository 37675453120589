import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ICoverProps } from './'
import scheduleCardBackgroundImage from 'assets/schedule-card-background.svg'

export default makeStyles((theme: Theme) => createStyles({
  backgroundImage: {
    width: '100%',
    background: theme.colors.support.colorSupport01,
    borderRadius: '0px',
    maxHeight: '149px',
    backgroundSize: 'cover',
    backgroundImage: ({ imageUrl }: ICoverProps) => imageUrl ? `url(${imageUrl.formats.medium.url})` : `url(${scheduleCardBackgroundImage})`,

    [theme.breakpoints.between('sm', 'xl')]: {
      backgroundImage: ({ imageUrl }: ICoverProps) => imageUrl ? `url(${imageUrl.formats.medium.url})` : `url(${scheduleCardBackgroundImage})`,
      maxHeight: '250px',
      borderRadius: '16px 16px 0px 0px'
    },
    [theme.breakpoints.up('xl')]: {
      backgroundImage: ({ imageUrl }: ICoverProps) => imageUrl ? `url(${imageUrl.formats.medium.url})` : `url(${scheduleCardBackgroundImage})`,
      maxHeight: '250px',
      borderRadius: '16px 16px 0px 0px'
    }
  },
  boxCover: {
    background: 'linear-gradient(180deg, rgba(20,20,20,0) 0%, rgba(20,20,20,0.7035189075630253) 100%)',
    padding: theme.spacingInset.sm,
    gap: '16px',
    display: 'grid',
    paddingBottom: theme.spacingInline.md,
    borderBottom: `6px solid ${theme.colors.support.colorSupport01}`,
    justifyContent: 'space-between',
    gridTemplateColumns: 'auto auto',
    borderRadius: '0px',

    [theme.breakpoints.between('sm', 'xl')]: {
      display: 'inherit',
      gridTemplateColumns: 'none',
      borderRadius: '16px 16px 0px 0px'
    },
    [theme.breakpoints.up('xl')]: {
      display: 'inherit',
      gridTemplateColumns: 'none',
      borderRadius: '16px 16px 0px 0px'
    }
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  navationButton: {
    '&.MuiButton-root': {
      borderColor: 'white',
      color: 'white',
      padding: theme.spacingSquish.nano,
      fontSize: theme.font.fontSize.xxs,
      '& svg': {
        marginRight: theme.spacingStack.xxxs
      },
      '&:hover': {
        background: 'none'
      }
    }
  },
  disciplineContainer: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px'
    },

    '& label': {
      marginRight: theme.spacingInline.nano
    }
  },
  exitbuttonWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  expeditionContainer: {
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,
    lineHeight: theme.spacingInline.sm,
    marginTop: theme.spacingStack.nano,

    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm,
      lineHeight: '100%',
      marginTop: theme.spacingStack.xxxs
    }
  },
  titleContainer: {
    fontSize: theme.font.fontSize.md,
    lineHeight: theme.spacingInline.xs,
    marginTop: theme.spacingStack.nano,
    color: theme.colors.neutral.lightBase,

    [theme.breakpoints.between('sm', 'xl')]: {
      fontSize: theme.font.fontSize.xl,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.md
    }
  }
}))
