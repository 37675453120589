import { IAlternativeResponse } from 'services/types/content-unit'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme, useTheme } from '@mui/material'
import { parseKaTex } from 'utils/katex'
import { Resource } from 'components/common'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      padding: theme.spacingStack.nano,
      marginLeft: 0,
      marginBlock: theme.spacingStack.xxxs,
      borderRadius: theme.spacingStack.nano,
      width: '100%',
      border: (props: IAlternativeProps) => {
        if (props.isStudentView) {
          return `1px solid ${theme.colors.neutral.light30}`
        }

        if (props.isWrong) {
          return `2px solid ${theme.colors.negative.feedbackMedium}`
        } else if (props.isCorrect) {
          return `2px solid ${theme.colors.positive.feedbackMedium}`
        } else if (props.isActive) {
          return `2px solid ${theme.colorBrand.medium}`
        } else {
          return `1px solid ${theme.colors.neutral.light30}`
        }
      },
      '& span': {
        fontWeight: theme.font.fontWeight.medium,
        color: (props: IAlternativeProps) => {
          if (props.isStudentView) {
            return `${theme.colors.neutral.light30}`
          }

          if (props.isWrong) {
            return `${theme.colors.negative.feedbackMedium}`
          } else if (props.isCorrect) {
            return `${theme.colors.positive.feedbackMedium}`
          } else if (props.isActive) {
            return `${theme.colorBrand.medium}`
          } else {
            return `${theme.colors.neutral.light30}`
          }
        }
      },
      '& .MuiFormControlLabel-label': {
        width: '100%'
      }
    },
    resource: {
      '& img': {
        width: 'inherit'
      }
    },
    colorButtonDefault: {
      color: theme.colors.neutral.dark20
    },
    correctText: {
      padding: `0 ${theme.spacingInline.xxxs}`,
      fontSize: theme.font.fontSize.xxs,
      alignSelf: 'center'
    },
    alternativeText: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: theme.font.fontSize.sm,
      color: (props: IAlternativeProps) => {
        if (props.isStudentView) {
          return `${theme.colors.neutral.dark40}`
        }

        if (props.isWrong) {
          return `${theme.colors.negative.feedbackMedium}`
        } else if (props.isCorrect) {
          return `${theme.colors.positive.feedbackMedium}`
        } else if (props.isActive) {
          return `${theme.colorBrand.medium}`
        } else {
          return `${theme.colors.neutral.dark40}`
        }
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.xxs
      }
    }
  })
)

interface IAlternativeProps {
  alternative: IAlternativeResponse
  isActive: boolean
  isWrong?: boolean
  isCorrect?: boolean
  disabled?: boolean
  isStudentView?: boolean
}

export const Alternative: React.FC<IAlternativeProps> = ({ alternative, isActive, isWrong, isCorrect, disabled, isStudentView = false }) => {
  const classes = useStyles({ alternative, isActive, isWrong, isCorrect, disabled, isStudentView })
  const alternativeParsed = parseKaTex(alternative.text) ?? ''

  const theme = useTheme()

  return (
    <div>
      <FormControlLabel
        value={alternative.id}
        disabled={disabled}
        className={classes.question}
        sx={{
          marginLeft: 0,
          width: '100%',
          '&:checked': {
            backgroundColor: 'pink'
          }
        }}
        control={
          <Radio
            checked={!isStudentView && isActive}
            sx={{
              color: classes.colorButtonDefault,
              '&.Mui-checked': {
                color: theme.colorBrand.medium
              }
            }}
          />
        }
        label={
          <div className={classes.alternativeText}>
            <div dangerouslySetInnerHTML={{ __html: alternativeParsed }} />
            {
              alternative?.resources?.length
                ? alternative.resources.map(resource =>
                  <div className={classes.alternativeText} key={resource.id}>
                    <Resource resource={resource} />
                    <br />
                  </div>)
                : ''
            }
            <div className={classes.correctText}>
              {!isStudentView && isCorrect ? 'Resposta correta' : ''}
              {!isStudentView && isWrong ? 'Resposta incorreta' : ''}
            </div>
          </div>
        }
      />
    </div>
  )
}
