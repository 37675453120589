import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    input: {
      width: '100%',
      textAlign: 'left'
    },
    backButton: {
      color: theme.colors.support.colorSupport03,
      '& svg': {
        marginRight: '8px'
      }
    },
    submitButton: {
      width: '100%',
      '&.active': {
        background: `${theme.colors.support.colorSupport01}!important`,
        color: `${theme.colors.neutral.lightBase}!important`
      }
    },
    defineLater: {
      width: '100%',
      fontSize: `${theme.font.fontSize.xs}!important`,
      color: theme.colors.support.colorSupport01,
      '&.ghost': {
        color: theme.colors.support.colorSupport01,
        '&:hover': {
          background: theme.colorBrand.lightest
        }
      },
      '& svg': {
        marginRight: '8px'
      }
    },
    messageError: {
      color: 'red',
      fontSize: theme.font.fontSize.xs
    }
  }
})
