import { Grid, CardContent, useTheme, useMediaQuery } from '@mui/material'
import { Avatar, Card, Text2 } from 'components/design-system'
import React from 'react'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { StudentCardInfo } from '../StudentCardInfo'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { ICurrentUserPerformance } from 'pages/Dashboard/teacher/ReportsTab/atomStore'
import { truncateExtension } from 'utils/string'

export const StudentCardInfoWithAvatar: React.FC<ICurrentUserPerformance> = ({ user, userSchoolProfile }) => {
  dayjs.extend(customParseFormat)

  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const { created_at: createdAt } = user
  const { last_used: lastUsed } = userSchoolProfile

  const fullname = user ? `${user.name} ${user.surname}` : ''

  return (
    <Card format='horizontal' extraClasses={classes.studentCard} extraClassesCardContent={classes.extraClassesCardContent}>
      <CardContent className={classes.cardContent}>
        <Grid container alignItems='center' className={classes.cardContentGrid} columnSpacing={3} rowSpacing={isMobile ? 2 : 0}>
          <Grid item xs={12} lg='auto' className={classes.avatarContainer}>
            <Avatar size='large' userName={fullname} className={classes.customAvatar} />
          </Grid>
          <Grid container item xs={12} lg alignItems={isMobile ? 'center' : 'flex-start'} direction='column'>
            <Grid item className={classes.studentName}>
              <Text2 fontSize='lg' fontWeight='semibold' lineHeight='xs' mobile='md' neutral='dark30'>
                {truncateExtension(fullname, 20)}
              </Text2>
            </Grid>
            <Grid item>
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark40'>
                { t('Estudante desde {{date}}', { date: createdAt }) }
              </Text2>
            </Grid>
            <Grid item>
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark40'>
                { lastUsed
                  ? `${t('Último acesso')}: ${dayjs(lastUsed, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}`
                  : t('Nunca acessou')
                }
              </Text2>
            </Grid>
          </Grid>
          {isMobile && user && (
            <Grid container item justifyContent='center' xs={12}>
              <StudentCardInfo user={user} userSchoolProfile={userSchoolProfile} noBorder noPadding center />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
