import React from 'react'
import Grid from '@mui/material/Grid'
import { Resource } from 'components/common'
import { IActivityResponse } from 'services/types'
import useStyles from './style'

interface IQuestionEnunciateProps {
  activity: IActivityResponse
}
// Ainda está sendo usado na tela de professor, substituir no próximo refactor e usar o componente de estudante
export const OldActivitiyEnunciate: React.FC<IQuestionEnunciateProps> = ({
  activity
}) => {
  const classes = useStyles()
  if (activity?.text ?? activity?.resources?.length) {
    return (
      <Grid container gap='24px' className={classes.enunciate}>
        <div
          className={classes.utteranceText}
          dangerouslySetInnerHTML={{ __html: activity.text ?? '' }}
        />
        {activity?.resources?.map((resource, i) => (
          <React.Fragment key={resource.id}>
            <Resource resource={resource} />
            <br />
          </React.Fragment>
        ))}
      </Grid>
    )
  }
  return null
}

export default OldActivitiyEnunciate
