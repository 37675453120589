import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  youTubeWrapper: {
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  iFrameContainer: {
    position: 'relative',
    height: '0',
    paddingBottom: '56.25%',
    background: '#EAEAEA',
    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%'
    }
  },
  errorMessage: {
    textAlign: 'center',
    width: '100%'
  }
}))
