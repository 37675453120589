import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  headerTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '16px 16px 0 0',
    marginTop: '-15px',

    [theme.breakpoints.down('md')]: {
      position: 'sticky',
      top: '0',
      zIndex: 2
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: '60px'
    }
  },
  tabLink: {
    background: theme.colorBrand.lightest,
    color: theme.colorBrand.dark,
    height: 'auto',
    minHeight: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingInline: '22px',
    fontWeight: theme.font.fontWeight.bold,
    textDecoration: 'none',
    flex: 1,
    float: 'right',
    borderBottom: `${theme.borderThickness.thick} solid ${theme.colorBrand.medium}`,
    borderRadius: '8px 8px 0 0 !important',
    boxSizing: 'content-box',

    [theme.breakpoints.up('md')]: {
      minHeight: '72px',
      borderRadius: '16px 16px 0 0 !important'
    },

    '&[aria-disabled="true"]': {
      cursor: 'not-allowed !important'
    },

    '&.is-active': {
      background: theme.colorBrand.medium,
      color: theme.colors.neutral.lightBase,

      '& ~ a': {
        borderTopRightRadius: '0'
      },
      '& + a': {
        borderTopLeftRadius: '16px'
      }
    },

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      paddingBlock: theme.spacingInset.sm,
      lineHeight: '15px'
    },

    // mobile
    [theme.breakpoints.down('md')]: {
      paddingInline: '12px',
      fontSize: theme.font.fontSize.xs,

      '& > span': {
        paddingBlock: '12px'
      },

      // to show only icon
      '& span > span': {
        display: 'none'
      },

      // to show title
      '&.is-active': {
        flex: 4,

        '& span > span': {
          display: 'block',
          wordBreak: 'keep-all'
        }
      }
    }
  }
}))
