import { Field, FieldProps } from 'formik'
import { Alert, Button } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from '../../style'
import { CodeInput } from 'components/common'
import { Grid } from '@mui/material'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import { NavLink } from 'react-router-dom'
import { NavigateBefore } from '@mui/icons-material'
import { LOGIN } from 'navigation/CONSTANTS'

interface ISchoolTokenProps {
  token: string
  tokenError: boolean
  dirty: boolean
  onCancel: () => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

interface FormValues {
  token: string
}

export const SchoolToken: React.FC<ISchoolTokenProps> = ({ token, tokenError, onCancel, setFieldValue }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const buttonDisabled = token.length < 6 || tokenError

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NavLink to={LOGIN} className={styles.returnLink}>
          <NavigateBefore fontSize='small' />
          <span>{t('Voltar')}</span>
        </NavLink>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.purpleTitle}>
          <AdminPanelSettingsOutlinedIcon sx={{ marginRight: '8px' }} /> {t('Cadastro autônomo')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.description}>
          {t('O cadastro autônomo é destinado apenas à equipe da escola.')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.description}>
          {t('Digite o código fornecido.')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Field name='token'>
          {({
            field,
            form: { isValid },
            meta
          }: FieldProps<FormValues>) => (
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                <CodeInput
                  {...field}
                  type='text'
                  value={String(field.value ?? '')}
                  inputMode='verbatim'
                  autoFocus={false}
                  fields={6}
                  isValid={isValid}
                  onChange={(value: string) => setFieldValue('token', value)}
                />
              </Grid>
              {meta.error && (
                <Grid item>
                  <Alert
                    content={t(meta.error === 'Token expirado'
                      ? 'Código expirado. Verifique o código digitado ou entre em contato com o responsável da sua escola.'
                      : 'Código inválido. Verifique o código digitado ou entre em contato com o responsável da sua escola.'
                    )}
                    severity='error'
                    className={styles.alertError}
                    style={{ marginTop: '12px' }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Field>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Button
          className={`${!buttonDisabled && 'active'} ${styles.submitButton}`}
          variant='primary'
          type='submit'
          disabled={buttonDisabled}
          data-testid='autonomous_registration_code_advance'
        >
          {t('Continuar')}
          <ArrowForwardIcon sx={{ marginLeft: '14px' }} />
        </Button>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Button
          className={styles.cancelButton}
          variant='ghost'
          onClick={onCancel}
          type='button'
          data-testid='autonomous_registration_code_close'
        >
          <CloseIcon />
          {t('Fechar cadastro autônomo')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SchoolToken
