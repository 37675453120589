import useStyles from './style'
import { ResourceCaption } from 'components/common'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { LinearProgress } from '@mui/material'

export interface ISoundCloudPlayerProps {
  url: string
  source?: string | null
  description?: string | null
}

const convertAudioUrl = (url: string, source: string | null | undefined, description: string | null | undefined) => {
  const classes = useStyles()
  const [embedHTML, setEmbedHTML] = useState('')

  useEffect(() => {
    if (url) {
      void axios.get('https://soundcloud.com/oembed', {
        params: { url },
        headers: { Accept: 'application/json' }
      }).then(response => {
        setEmbedHTML(response.data.html)
      }).catch(error => {
        console.log(error)
      })
    }
  }, [url])

  return (
    <div className={classes.audio}>
      {
        embedHTML
          ? <div
            dangerouslySetInnerHTML={{
              __html: embedHTML
            }}
          />
          : <LinearProgress />
      }
      <ResourceCaption source={source} description={description}></ResourceCaption>
    </div>
  )
}

export const SoundCloudPlayer: React.FC<ISoundCloudPlayerProps> = ({ url, source, description }) => {
  return (
    convertAudioUrl(url, source, description)
  )
}
