import { IAnswerCached, IAnswerCachedStatus, makeDatabaseInstanceOffline } from 'contentCacheManager'
import { addAnswerOffline, getAnswerOffline } from '.'

export const updateAnswerOffline = async (params: Pick<IAnswerCached['requestAnswer'], 'activity' | 'class' | 'content_unit'>, upDateData: Partial<IAnswerCached>) => {
  const database = makeDatabaseInstanceOffline()
  const existis = await getAnswerOffline(params)
  if (existis) {
    database.answersCached.update(existis, { ...upDateData, status: upDateData.status ?? IAnswerCachedStatus.INITIAL })
    return
  }
  const newAnswer = await addAnswerOffline({
    activity: params.activity,
    class: params.class,
    content_unit: params.content_unit,
    isEditing: !!upDateData.isEditing,
    answers: []
  })

  if (newAnswer) {
    database.answersCached.update(newAnswer, upDateData)
  }

  return newAnswer
}
