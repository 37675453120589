import { Alert, Avatar, Button, Loading, Modal, Text2, TextField } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
  useMediaQuery,
  IconButton
} from '@mui/material'
import useStyles from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloeAvatar from 'assets/cloe-avatar-com-brilho.svg'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from 'components/common'
import { useChatAi } from 'pages/Dashboard/teacher/ReportsTab/hooks/useChatAiPrompt'
import CloseIcon from '@mui/icons-material/Close'
import Analytics from 'utils/analytics'
import { toast } from 'components/design-system/Toast/manager'
import { useCurricularStore } from 'store'
import { getMessages, sendMessage } from 'services/messages'
import { expandedTextGeneratorAccordionAtom, showParentalMessagesAtom } from 'pages/Dashboard/teacher/ReportsTab/atomStore'
import { useAtom } from 'jotai'
import { ISendMessageRequest } from 'services/types/messages'
import { ICurricularData } from 'pages/Dashboard/teacher/ReportsTab/hooks/useChatAiPrompt/types'
import { useUpdateAtom } from 'jotai/utils'

export interface ITextGeneratorAccordion {
  commonAttributes?: {}
  curricularData: ICurricularData
  studentId: number
  userName: string
}

interface ICloseButtonProps {
  onClose: () => void
}

export const TextGeneratorAccordion: React.FC<ITextGeneratorAccordion> = ({ commonAttributes, curricularData, studentId, userName }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { currentDiscipline, classId } = useCurricularStore()
  const chatAI = useChatAi(curricularData)

  // states
  const [hasError, setHasError] = useState<boolean>(false)
  const [hasGeneratedText, setHasGeneratedText] = useState<boolean>(false)
  const [hasMessages, setHasMessages] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [generatedMessage, setGeneratedMessage] = useState<string>('')
  const [editableMessage, setEditableMessage] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openGeneratedTextAlert, setOpenGeneratedTextAlert] = useState<boolean>(false)

  // atoms
  const [expanded, setExpanded] = useAtom(expandedTextGeneratorAccordionAtom)
  const setShowParentalMessages = useUpdateAtom(showParentalMessagesAtom)

  const fetchMessages = async () => {
    try {
      setHasMessages(false)

      const response = await getMessages(
        'parental_notice',
        studentId,
        classId ?? 0,
        typeof currentDiscipline?.id === 'number' ? currentDiscipline?.id : 0,
        'DESC',
        1,
        1
      )

      if (response.success) {
        setHasMessages(response?.data?.totalItems > 0)
      } else {
        setHasMessages(false)
        throw new Error('Erro ao obter dados de mensagens')
      }
    } catch (e: any) {
      if (e instanceof Error) {
        toast.handler({
          content: e.message,
          duration: 3000,
          severity: 'error'
        })
      }
    }
  }

  useEffect(() => {
    fetchMessages()
  }, [currentDiscipline?.id])

  const reset = () => {
    setHasError(false)
    setHasGeneratedText(false)
    setOpenGeneratedTextAlert(false)
    setGeneratedMessage('')
    setEditableMessage('')
  }

  useEffect(() => {
    reset()
  }, [curricularData.curricular_component])

  const handleRecordEventClick = (eventName: string, exclusiveAttributes?: {}) => {
    Analytics.recordEventClick({
      name: eventName,
      attributes: {
        ...commonAttributes,
        ...exclusiveAttributes
      }
    })
  }

  const handleChange = (isExpanded: boolean) => setExpanded(isExpanded)

  const generateText = async () => {
    try {
      handleRecordEventClick('chatgpt_generate_text')
      setIsLoading(true)
      const response = await chatAI.generateFeedback()

      if (response.success) {
        handleRecordEventClick(
          'chatgpt_finished_text_generation',
          {
            generated_text: response.data[0]?.message?.content ?? ''
          }
        )
        setHasError(false)
        setHasGeneratedText(true)
        setOpenGeneratedTextAlert(true)
        const message = response.data[0]?.message?.content?.trim() ?? ''
        setGeneratedMessage(message)
        setEditableMessage(message)
      } else {
        handleRecordEventClick(
          'chatgpt_connection_fail',
          {
            generated_text_error_code: response.message
          }
        )
        throw new Error('Erro ao obter dados da inteligência artificial')
      }
    } catch (e: any) {
      setHasError(true)
      setHasGeneratedText(false)
      setOpenGeneratedTextAlert(false)
      if (e instanceof Error) {
        toast.handler({
          content: e.message,
          duration: 3000,
          severity: 'error'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const viewMessageHistory = () => {
    setShowParentalMessages(true)
    handleRecordEventClick('chat_gpt_view_message_history')
  }

  const cleanText = () => {
    handleRecordEventClick(
      'chat_gpt_cancel_send',
      {
        generated_text_by_chat: hasGeneratedText,
        generated_text_edited: generatedMessage !== editableMessage
      }
    )
    reset()
  }

  const handleOpen = () => {
    handleRecordEventClick(
      'chatgpt_send_text',
      {
        generated_text_by_chat: hasGeneratedText,
        generated_text_edited: generatedMessage !== editableMessage
      }
    )
    setGeneratedMessage(generatedMessage.trim())
    setEditableMessage(editableMessage.trim())
    setOpenModal(true)
  }

  const handleClose = () => {
    handleRecordEventClick(
      'chat_gpt_back_to_editing',
      {
        generated_text_by_chat: hasGeneratedText,
        generated_text_edited: generatedMessage !== editableMessage
      }
    )
    setOpenModal(false)
  }

  const handleSendMessage = async () => {
    try {
      setIsSending(true)
      handleRecordEventClick(
        'chat_gpt_confirm_send',
        {
          generated_text_by_chat: hasGeneratedText,
          generated_text_edited: generatedMessage !== editableMessage
        }
      )

      const params: ISendMessageRequest = {
        message: editableMessage.trim(),
        classId: classId ?? 0,
        disciplineId: typeof currentDiscipline?.id === 'number' ? currentDiscipline?.id : 0,
        studentId
      }

      const response = await sendMessage('parental_notice', params)

      if (response.success) {
        handleRecordEventClick(
          'chat_gpt_message_sent_success',
          {
            generated_text_by_chat: hasGeneratedText,
            generated_text_edited: generatedMessage !== editableMessage
          }
        )

        reset()

        toast.handler({
          content: 'Mensagem enviada com sucesso.',
          duration: 3000,
          severity: 'success'
        })

        if (!hasMessages) fetchMessages()
      } else {
        handleRecordEventClick(
          'chat_gpt_sending_error',
          {
            generated_text_by_chat: hasGeneratedText,
            generated_text_edited: generatedMessage !== editableMessage
          }
        )
        throw new Error('Erro no no envio da mensagem. Tente novamente.')
      }
    } catch (e: any) {
      if (e instanceof Error) {
        toast.handler({
          content: e.message,
          duration: 3000,
          severity: 'error'
        })
      }
    } finally {
      setIsSending(false)
      setOpenModal(false)
    }
  }

  const CloseButton: React.FC<ICloseButtonProps> = ({ onClose }) => (
    <IconButton className='actionButton' size='small' onClick={onClose}>
      <CloseIcon />
    </IconButton>
  )

  return (
    <>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={(_event, isExpanded) => handleChange(isExpanded)}
        sx={{ borderRadius: `32px 16px 16px ${(expanded || isMobile) ? '16px' : '32px'}!important` }}
        disableGutters
      >
        <AccordionSummary
          aria-controls='text-generator-accordion'
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon sx={{ color: theme.colorBrand.dark }} />}
          id='text-generator-accordion'
          onClick={() => handleRecordEventClick('chat_gpt_generate_hide_show')}
        >
          <div className={classes.cloeAvatar}>
            <img src={CloeAvatar} />
          </div>
          <Grid container direction={isMobile ? 'row' : 'row-reverse'} justifyContent={isMobile ? 'flex-start' : 'space-between'}>
            <Grid container item xs={isMobile ? 12 : 'auto'} justifyContent='flex-end'>
              <Grid item className={classes.expandText}>
                <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='xs' mobile='xxs' customColor={theme.colorBrand.dark} cursorpointer>
                  {t(expanded ? 'Esconder' : 'Mostrar')}
                </Text2>
              </Grid>
            </Grid>
            <Grid item xs={isMobile ? 12 : 'auto'} className={classes.accordionTitle}>
              <Text2 fontSize='lg' fontWeight='semibold' lineHeight='xs' mobile='sm' customColor={theme.colorBrand.dark} cursorpointer>
                {t('Novidade! Mensagem aos responsáveis')}
              </Text2>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' customColor={theme.colorBrand.dark}>
                {t('Agora você pode enviar mensagens sobre o desempenho do estudante aos responsáveis. Elas serão mostradas na área de acompanhamento do perfil do estudante.')}
              </Text2>
            </Grid>
            <Grid item xs={12}>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' customColor={theme.colorBrand.dark}>
                <Trans>
                  Para ajudar na redação, utilize nossa ferramenta de <strong>Inteligência Artificial</strong> e gere os textos de forma automática.
                </Trans>
                <Tooltip
                  component={
                    <div className={classes.tooltip}>
                      <InfoOutlinedIcon fontSize='small' />
                    </div>
                  }
                  placement='top'
                  text={t('Os textos criados consideram apenas a média e a situação do estudante.')}
                />
              </Text2>
            </Grid>
            <Grid container item>
              <Grid container spacing={2}>
                <Grid item xs={12} md={hasMessages ? 6 : 12}>
                  <Button
                    className={classes.generateTextButton}
                    onClick={generateText}
                    size='small'
                    startIcon={isLoading ? <Loading /> : <AutoAwesomeOutlinedIcon />}
                    disabled={isLoading || hasGeneratedText}
                    fullWidth
                  >
                    {t(isLoading ? 'Gerando texto...' : 'Gerar texto')}
                  </Button>
                </Grid>
                {hasMessages && (
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={viewMessageHistory}
                      size='small'
                      variant='outlined'
                      fullWidth
                    >
                      {t('Ver histórico de mensagens')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {hasError && (
              <Grid item xs={12}>
                <Alert
                  content={t('Falha na conexão com o gerador de textos. Aguarde alguns instantes e tente novamente ou digite o texto no campo abaixo.')}
                  severity='error'
                  action={<CloseButton onClose={() => setHasError(false)} />}
                />
              </Grid>
            )}
            {openGeneratedTextAlert && (
              <Grid item xs={12}>
                <Alert
                  title={t('Geração de texto concluída.')}
                  content={t('Atenção: a funcionalidade está em período de testes e pode apresentar um comportamento diferente do previsto. Revise o texto gerado e faça as alterações necessárias antes do envio. Clique dentro do campo com o texto para editar.')}
                  closeButton={<CloseButton onClose={() => setOpenGeneratedTextAlert(false)} />}
                  severity='info'
                />
              </Grid>
            )}
            <Grid container item xs={12} columnSpacing={1}>
              <Grid item xs='auto'>
                <Avatar userName={userName} size='medium' />
              </Grid>
              <Grid item xs>
                <TextField
                  className={classes.textGeneratorTextField}
                  id='text-generator-textfield'
                  label={t('Escreva sua mensagem...')}
                  onChange={e => setEditableMessage(e.target.value)}
                  value={editableMessage}
                  variant='filled'
                  fullWidth
                  multiline
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className={classes.buttonGroup}>
              <Grid item xs={12} sm='auto'>
                <Button
                  className={classes.generateTextButton}
                  onClick={handleOpen}
                  size='small'
                  disabled={!editableMessage.trim().length}
                  fullWidth
                >
                  {t('Enviar')}
                </Button>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <Button
                  onClick={cleanText}
                  size='small'
                  variant='ghost'
                  disabled={!editableMessage.length}
                  fullWidth
                >
                  {t('Cancelar')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Modal open={openModal} onClose={handleClose} className={classes.modal}>
        <>
          <Grid container justifyContent='center' rowSpacing={1} className={classes.modalDescription}>
            <Grid item xs={12} sm='auto'>
              <Text2
                fontSize='md'
                fontWeight='medium'
                lineHeight='xs'
                mobile='md'
                colorbrand='medium'
              >
                Por favor, revise o texto antes de enviar.
              </Text2>
            </Grid>
            <Grid item xs={12} sm='auto'>
              <Text2
                fontSize='sm'
                fontWeight='medium'
                lineHeight='xs'
                mobile='sm'
                neutral='dark20'
              >
                O texto não poderá ser editado ou excluído após o envio.
              </Text2>
            </Grid>
            <Grid container item xs={12}>
              <Grid item className={classes.modalGeneratedText}>
                <Text2
                  fontSize='sm'
                  fontWeight='regular'
                  lineHeight='xs'
                  mobile='sm'
                  neutral='dark20'
                >
                  {editableMessage}
                </Text2>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end' className={classes.modalFooter}>
            <Grid item xs={12} sm='auto'>
              <Button
                onClick={handleClose}
                variant='ghost'
                fullWidth
              >
                {t('Voltar para a edição')}
              </Button>
            </Grid>
            <Grid item xs={12} sm='auto'>
              <Button
                className={classes.confirmButton}
                onClick={handleSendMessage}
                startIcon={isSending ? <Loading /> : null}
                disabled={isSending}
                fullWidth
              >
                {t(isSending ? 'Enviando...' : 'Confirmar envio')}
              </Button>
            </Grid>
          </Grid>
        </>
      </Modal>
    </>
  )
}
