import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    height: 'auto',
    paddingTop: theme.spacingInset.sm,
    zIndex: 1,
    // desktop
    [theme.breakpoints.up('sm')]: {
      minHeight: '100vh'
    },
    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      padding: '0px'
      // paddingTop: '64px'
    }
  }
}))
