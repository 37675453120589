import { request } from 'services/base'
import { IApiResponse, IProgramContentResourcesResponse } from 'services/types'
import { PROGRAM_CONTENT_RESOURCE } from './CONSTANTS'

export interface IGetProgramContentResourceParams {
  gradeType: number
  discipline?: number
  isAll?: boolean
}

export const getProgramContentResource = async ({ gradeType, discipline, isAll }: IGetProgramContentResourceParams): Promise<IApiResponse<IProgramContentResourcesResponse>> =>
  await request({
    url: PROGRAM_CONTENT_RESOURCE({ grade_type: gradeType, discipline, isAll })
  })
