import dayjs from 'dayjs'

import { ISchoolPeriodResponse } from 'services/types'

type IInputMoment = 'initialDate' | 'finalDate'

export default (schoolPeriod: Partial<ISchoolPeriodResponse> | null, inputMoment?: IInputMoment) => {
  const currentDay = dayjs().startOf('day').toDate()
  const yearFirstDay = dayjs(schoolPeriod?.start_date).set('month', 0).set('date', 1).toDate()
  const minDate = dayjs(schoolPeriod?.start_date).toDate()
  let maxDate = dayjs(schoolPeriod?.end_date).toDate()

  if (maxDate > currentDay) {
    maxDate = currentDay
  }

  const schoolPeriodActive = {
    yearFirstDay,
    calendarMonth: currentDay,
    deadline: currentDay
  }

  const schoolPeriodInactive = {
    yearFirstDay,
    deadline: maxDate
  }

  switch (inputMoment) {
    case 'initialDate':
      if (schoolPeriod?.current) {
        return schoolPeriodActive
      }

      return {
        ...schoolPeriodInactive,
        calendarMonth: minDate
      }

    case 'finalDate':
      if (schoolPeriod?.current) {
        return schoolPeriodActive
      }

      return {
        ...schoolPeriodInactive,
        calendarMonth: maxDate
      }

    default:
      if (schoolPeriod?.current) {
        return schoolPeriodActive
      }

      return {
        ...schoolPeriodInactive,
        calendarMonth: maxDate
      }
  }
}
