import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'calc(100% - 140px)',
    minHeight: '240px',
    maxHeight: 'calc(100vh - 140px)',
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 10fr 1fr',
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.md,
    '& > .TabsUnstyled-root': {
      height: '100%',
      minHeight: '100%'
    },
    '& > .TabsUnstyled-root > div[role=tabpanel]': {
      height: '100%',
      minHeight: '100%'
    },
    '&.lowheight': {
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 10fr 1fr',
      transform: 'unset',
      top: '0',
      left: '0',
      maxWidth: 'unset',
      maxHeight: '100vh',
      '& > .TabsUnstyled-root': {
        minHeight: '100%'
      },
      '& > .TabsUnstyled-root > div[role=tabpanel]': {
        height: '100%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 10fr 1fr',
      transform: 'unset',
      top: '0',
      borderRadius: '0px',
      left: '0',
      maxWidth: 'unset',
      maxHeight: '100vh',
      '& > .TabsUnstyled-root': {
        minHeight: '100%'
      },
      '& > .TabsUnstyled-root > div[role=tabpanel]': {
        height: '100%'
      }
    }
  }
}))
