import { IContentSelectAtom, IScheduleAtom } from '../atomStore'

export enum ContentEnum {
  empty = 'emtpy',
  filled = 'filled'
}

export type IContentSelectProps = {
  status: ContentEnum
  description: string | null
  eventObject: IScheduleAtom | null
  contentSelect: IContentSelectAtom[] | null
  handleOpenModal: () => void

  isShowItems: boolean
  setIsShowItems: (value: boolean) => void
}
