import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  accordion: {
    '&.MuiAccordion-root': {
      boxShadow: 'none'
    }
  },
  accordionDetails: {
    padding: '0!important'
  },
  accordionSummary: {
    margin: '0 auto!important',
    width: '242px',
    '& .MuiAccordionSummary-content': {
      margin: '0'
    }
  },
  avatarContainer: {
    width: '144px',
    '& .MuiAvatar-root': {
      height: '96px',
      width: '96px',
      [theme.breakpoints.down('lg')]: {
        height: '72px',
        width: '72px'
      }
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0 auto'
    }
  },
  cardContent: {
    background: `linear-gradient(90deg, ${theme.colorBrand.light} 72px, ${theme.colors.neutral.lightBase} 72px)`,
    height: '168px',
    padding: `${theme.spacingInline.xxs}!important`,
    [theme.breakpoints.down('lg')]: {
      background: `linear-gradient(180deg, ${theme.colorBrand.light} 60px, ${theme.colors.neutral.lightBase} 60px)`,
      height: '100%'
    }
  },
  cardContentGrid: {
    height: '100%'
  },
  customAvatar: {
    [theme.breakpoints.down('lg')]: {
      margin: '0 auto'
    }
  },
  expandIcon: {
    color: theme.colorBrand.medium
  },
  studentCard: {
    borderRadius: '16px!important',
    minHeight: '168px'
  },
  extraClassesCardContent: {
    padding: '0px !important'
  },
  studentName: {
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    }
  }
}))
