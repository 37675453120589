/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'

import { IStudentResponse } from 'services/types'
import { getClassStudents } from 'services/class'
import { recipientsModalSelectorAtom, subscriptionsAtom, subscriptionsAllSelectedAtom, selectedStudentsModalAtom } from '../atomStore'

import { RecipientsModal } from './RecipientsModal'

import { IGroupStudentsByFirstLetter } from './types'

export const RecipientsModalContainer = () => {
  // states
  const [students, setStudents] = useState<IStudentResponse[]>([])
  const [groupStudentsByFirstLetter, setGroupStudentsByFirstLetter] = useState<IGroupStudentsByFirstLetter>({})

  //  atoms and stores
  const { session } = useStore()
  const [selectedStudents, setSelectedStudents] = useAtom(selectedStudentsModalAtom)
  const [subscriptions, setSubscriptions] = useAtom(subscriptionsAtom)
  const [recipientsModal, setRecipientsModalSelector] = useAtom(recipientsModalSelectorAtom)
  const [subscriptionsAllSelected, setSubscriptionsAllSelected] = useAtom(subscriptionsAllSelectedAtom)

  // params
  const { classId } = useParams<{ classId: string }>()

  // const
  const classSelected = session?.subscriptions.filter(cur => cur.class).find(sub => sub?.class?.id?.toString() === classId)?.class

  const groupByFirstLetter = (students: IStudentResponse[]): IGroupStudentsByFirstLetter => {
    return students?.reduce((previousValue: IGroupStudentsByFirstLetter, currentValue) => {
      const firstLetter = currentValue.user_id?.name?.toUpperCase().charAt(0)
      return { ...previousValue, [firstLetter]: [...(previousValue[firstLetter] || []), currentValue] }
    }, {})
  }

  const isStudentSelected = (id: number): boolean => {
    if ((selectedStudents ?? []).length === 0) return false

    return (selectedStudents ?? []).some(subscription => subscription.id === id)
  }

  const removeStudent = (id: number): void => {
    setSelectedStudents((selectedStudents ?? []).filter(subscription => subscription.id !== id))
  }

  const getStudents = async () => {
    const { data } = await getClassStudents(classId)
    setStudents(data)
    setGroupStudentsByFirstLetter(groupByFirstLetter(data))
  }

  useEffect(() => {
    getStudents()
  }, [])

  const setAllStudents = async () => {
    if ((students ?? []).length > 0) {
      const allStudentsPromise = (students ?? []).map(student => {
        return { id: student.id, name: `${student.user_id?.name} ${student.user_id.surname}` }
      })

      await Promise.all(allStudentsPromise)

      setSelectedStudents(allStudentsPromise)
    }
  }

  const checkAllSelected = () => {
    return selectedStudents.length === students.length
  }

  const onChangeSelectAll = () => {
    if (checkAllSelected()) {
      setSelectedStudents([])
      return
    }

    setAllStudents()
  }

  const onSelect = () => {
    // FULL SELECTED ?
    if (checkAllSelected()) {
      setSubscriptionsAllSelected(true)
      setSubscriptions(null)
      setRecipientsModalSelector(false)
      return
    }

    setSubscriptionsAllSelected(false)
    setSubscriptions(selectedStudents)
    setRecipientsModalSelector(false)
  }

  useEffect(() => {
    if (recipientsModal) {
      if (subscriptionsAllSelected) {
        setAllStudents()
      }

      setSelectedStudents(subscriptions ?? [])
    }
  }, [recipientsModal])

  return <RecipientsModal {...{
    // states
    recipientsModal,
    classSelected,
    selectedStudents,
    groupStudentsByFirstLetter,

    // functions
    isStudentSelected,
    setRecipientsModalSelector,
    setSelectedStudents,
    removeStudent,
    checkAllSelected,

    onSelect,
    onChangeSelectAll
  }} />
}
