import { useCallback, useEffect, useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { IconButton, useTheme } from '@mui/material'
import useStyles from './style'

export interface IBackToTopProps {
  e?: any
  staryHeight?: any
  left?: any
}

const BackToTop = (props: IBackToTopProps) => {
  const { e, staryHeight } = props
  const classes = useStyles(props)
  const [buttonClass, setButtonClass] = useState<string>(classes.block)
  const [y, setY] = useState(staryHeight)
  const theme = useTheme()

  const handleNavigation = useCallback((e) => {
    const element = e.currentTarget
    if (e === window) {
      if (y <= 10) {
        setButtonClass(classes.dissapear)
      } else if (y > element.scrollY) {
        setButtonClass(classes.scrollTop)
      } else if (y < element.scrollY) {
        setButtonClass(classes.scrollDown)
      }
      setY(element.scollY)
    } else {
      if (y <= 10) {
        setButtonClass(classes.dissapear)
      } else if (y > element.scrollTop) {
        setButtonClass(classes.scrollTop)
      } else if (y < element.scrollTop) {
        setButtonClass(classes.scrollDown)
      }
      setY(element.scrollTop)
    }
  }, [y])

  useEffect(() => {
    let scrollElement: any
    e === window
      ? scrollElement = window
      : scrollElement = document.getElementById(e as string)
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleNavigation)
      e === window ? setY(window.scrollY) : setY(scrollElement.scrollTop)
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleNavigation)
      }
    }
  }, [handleNavigation])

  let scrollElement: any
  e === window
    ? scrollElement = window
    : scrollElement = document.getElementById(e as string)

  function scrollToTop() {
    e === window
      ? window.scrollTo({ top: 0, behavior: 'smooth' })
      : scrollElement.scroll({ top: 0, behavior: 'smooth' })
  }

  return (
    <IconButton size='large' onClick={() => scrollToTop()} className={buttonClass}>
      <ArrowUpwardIcon sx={{ color: theme.colorBrand.medium, fontSize: '2rem' }} />
    </IconButton>
  )
}

export default BackToTop
