import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  headerContainer: {
    alignItems: 'center',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex !important'
    }
  },
  tabsContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium,
    padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.nano}`,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: '18px',
    // larger screens
    [theme.breakpoints.up('sm')]: {
      padding: 0
    },
    '& svg': {
      marginRight: theme.spacingInline.nano
    }
  },
  tabs: {
    width: 'unset !important',
    '& .MuiTabs-flexContainer': {
      display: 'block'
    },
    '& .MuiButtonBase-root': {
      color: theme.colorBrand.medium,
      padding: 0,
      textTransform: 'none',
      margin: 0,
      marginRight: theme.spacingInline.xxxs,
      fontSize: theme.font.fontSize.sm,
      // larger screens
      [theme.breakpoints.up('lg')]: {
        fontSize: theme.font.fontSize.md,
        margin: `0 ${theme.spacingInline.xxs}`
      }
    },
    '& .MuiTabs-scroller': {
      display: 'flex'
    },
    '&.only-desktop': {
      display: 'none',
      // larger screens
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    '&.only-mobile': {
      display: 'block',
      // larger screens
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  },
  tabsItem: {
    marginInline: `${theme.spacingInline.xxxs} !important`
  }
}))
