import { useTranslation } from 'react-i18next'
import { useEffect, useLayoutEffect, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import useStyles from './style'
import { useTheme } from '@mui/material'
import { scheduleAtom, expeditionDisciplinesAtom, updateScheduleDisciplineIdAtom } from '../atomStore'
import { useAtom } from 'jotai'
import { IDisciplineResponse } from 'services/types'
import { useCurricularStore } from 'store'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
}

interface IDisciplineSelect {
  disciplines: IDisciplineResponse[]
  onCallback?: (selectedDiscipline: IDisciplineResponse | undefined) => void
}

export const DisciplineSelect = ({
  disciplines,
  onCallback
}: IDisciplineSelect) => {
  const [schedule] = useAtom(scheduleAtom)
  const [, updateEventDisciplineId] = useAtom(updateScheduleDisciplineIdAtom)
  const [expeditionDisciplines, setExpeditionDisciplines] = useAtom(expeditionDisciplinesAtom)
  const [selectorDisciplineCode, setSelectorDisciplineCode] = useState<string>('')
  const { currentDiscipline } = useCurricularStore()

  const { t } = useTranslation()

  /* para começar eu preciso que para quando eu clique numa disciplina mude o selecionado
  no atom e também o que é mostrado no select */

  const handleChange = (event: SelectChangeEvent) => {
    setSelectorDisciplineCode(event.target.value)
    const selectedDiscipline = findSelectedDisciplineByCode(event.target.value)
    if (selectedDiscipline) {
      void updateEventDisciplineId(selectedDiscipline.id)
    }
  }

  const populateDisciplinesList = () => {
    setExpeditionDisciplines(disciplines)
  }

  const handleFilteredDiscipline = (selectedDisciplineCode: string) => {
    setSelectorDisciplineCode(selectedDisciplineCode)

    if (onCallback) onCallback(disciplines.find(d => d.code === selectedDisciplineCode))
  }

  const findSelectedDisciplineById = (disciplineId: number | string) => {
    return disciplines.find(d => d.id === disciplineId)
  }

  const findSelectedDisciplineByCode = (disciplineCode: string) => {
    return disciplines.find(d => d.code === disciplineCode)
  }

  useEffect(() => {
    if (disciplines && currentDiscipline) {
      populateDisciplinesList()
      const selectedDiscipline = findSelectedDisciplineById(currentDiscipline?.id)
      if (selectedDiscipline) {
        void updateEventDisciplineId(selectedDiscipline.id)
        handleFilteredDiscipline(selectedDiscipline.code)
      }
    }
  }, [])

  useEffect(() => {
    if (disciplines && schedule === null && currentDiscipline?.id) {
      populateDisciplinesList()
      const selectedDiscipline = findSelectedDisciplineById(currentDiscipline?.id)
      if (selectedDiscipline) {
        handleFilteredDiscipline(selectedDiscipline.code || '')
      } else handleFilteredDiscipline('')
    } else if (disciplines && schedule?.disciplineId) {
      const selectedDiscipline = findSelectedDisciplineById(schedule.disciplineId)
      if (selectedDiscipline) {
        handleFilteredDiscipline(selectedDiscipline.code)
      }
    } else if (!currentDiscipline?.id) {
      handleFilteredDiscipline('')
    }
  }, [schedule])

  useEffect(() => {
    if (disciplines && currentDiscipline?.id && !schedule?.content_unit) {
      const selectedDiscipline = findSelectedDisciplineById(currentDiscipline?.id)
      if (selectedDiscipline) {
        void updateEventDisciplineId(selectedDiscipline.id)
        handleFilteredDiscipline(selectedDiscipline.code)
      }
    } else if (!currentDiscipline?.id) {
      handleFilteredDiscipline('')
    }
  }, [currentDiscipline, disciplines])

  useLayoutEffect(() => {
    if (disciplines && currentDiscipline?.id) {
      populateDisciplinesList()
      const selectedDiscipline = findSelectedDisciplineById(currentDiscipline?.id)
      if (selectedDiscipline) {
        void updateEventDisciplineId(null)
        handleFilteredDiscipline(selectedDiscipline.code)
      }
    }
  }, [])

  const theme = useTheme()

  const classes = useStyles()

  return (
    <FormControl fullWidth>
      <Select
        disabled={!!(schedule?.content_unit && schedule.disciplineId)}
        displayEmpty
        value={selectorDisciplineCode}
        onChange={handleChange}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon style={{
            pointerEvents: 'none'
          }}{...props} />
        )}
        className={classes.selector}
        sx={{
          overflowX: 'hidden',
          fontFamily: theme.font.fontFamily,
          lineHeight: theme.font.lineHeight.xs,
          fontSize: theme.font.fontSize.sm,
          fontWeight: theme.font.fontWeight.semibold,
          color: theme.colorBrand.medium
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem sx={{
          fontFamily: theme.font.fontFamily,
          lineHeight: theme.font.lineHeight.xs,
          fontSize: theme.font.fontSize.sm,
          fontWeight: theme.font.fontWeight.semibold,
          color: theme.colorBrand.medium
        }} disabled value=''>
          <em>{t('Componente curricular')}</em>
        </MenuItem>
        {
          expeditionDisciplines?.map((d) => (
            <MenuItem key={`${d.code}`} data-testid='event_without_expedition_select_curriculum_component' sx={{
              fontFamily: theme.font.fontFamily,
              lineHeight: theme.font.lineHeight.xs,
              fontSize: theme.font.fontSize.sm,
              fontWeight: theme.font.fontWeight.semibold,
              color: theme.colorBrand.medium
            }} value={d.code}>{d.name}</MenuItem>
          ))
        }
        {!expeditionDisciplines &&
          disciplines?.map((d) => (
            <MenuItem key={`${d.code}`} data-testid='event_without_expedition_select_curriculum_component' sx={{
              fontFamily: theme.font.fontFamily,
              lineHeight: theme.font.lineHeight.xs,
              fontSize: theme.font.fontSize.sm,
              fontWeight: theme.font.fontWeight.semibold,
              color: theme.colorBrand.medium
            }} value={d.code}>{d.name}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}
