import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      background: theme.colors.neutral.lightBase,
      borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0`,
      boxShadow: theme.shadowLevels.level5,
      padding: theme.spacingInset.sm,
      height: 'auto',
      // mobile
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'unset',
        paddingInline: theme.spacingInline.xxxs,
        paddingTop: '64px' // header height
      }
    }
  })
)
