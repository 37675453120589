import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  errorMessage: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.sm,
    textAlign: 'center',
    color: theme.colors.neutral.dark30,
    width: '759px',
    margin: '0 auto',
    whiteSpace: 'pre-wrap',

    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: theme.font.fontSize.md
    }
  }
}))
