import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { toast } from 'components/design-system/Toast/manager'

const useInternetToast = () => {
  const { t } = useTranslation()
  const onlineAlertRef = useRef(false)

  return (isOnline: boolean) => {
    if (!isOnline && !onlineAlertRef.current) {
      onlineAlertRef.current = true
      toast.handler({
        content: t('Parece que você está sem internet.'),
        severity: 'error',
        duration: 10000
      })
    } else if (onlineAlertRef.current) {
      onlineAlertRef.current = false
    }
  }
}

export default useInternetToast
