import { useEffect, useState } from 'react'
import { toast } from 'components/design-system/Toast/manager'

import { useCurricularStore, useStore } from 'store'
import { ParentalMessageLoading, ParentalMessageReady } from './components'
import { getMessages } from 'services/messages'
import { IMessage } from 'services/types/messages'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

export const ParentalMessage = () => {
  const { profile } = useStore()
  const { classId, currentDiscipline } = useCurricularStore()

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [message, setMessage] = useState<IMessage | null>(null)
  const [showHistory, setShowHistory] = useState<Boolean>(false)

  useEffect(() => {
    fetchMessage()
  }, [currentDiscipline?.id, classId, profile?.id])

  const fetchMessage = async () => {
    setStateMachine(StateMachineEnum.LOADING)
    setShowHistory(false)
    setMessage(null)

    try {
      if (!profile?.id || !classId || !currentDiscipline?.id || typeof currentDiscipline?.id !== 'number') {
        return
      }

      const response = await getMessages(
        'parental_notice',
        profile.id,
        classId ?? 0,
        typeof currentDiscipline?.id === 'number' ? currentDiscipline?.id : 0,
        'DESC',
        1,
        1
      )

      if (!response.success) {
        throw new Error('Erro ao obter dados de mensagens')
      }

      if (response.data.totalItems > 0) {
        setMessage(response.data.messages[0])
      }

      setShowHistory(response.data.totalItems > 1)
      setStateMachine(StateMachineEnum.READY)
    } catch (e: any) {
      if (e instanceof Error) {
        toast.handler({
          content: e.message,
          duration: 3000,
          severity: 'error'
        })
      }
      setStateMachine(StateMachineEnum.IDLE)
    }
  }

  const states = {
    IDLE: <></>,
    LOADING: <ParentalMessageLoading />,
    READY: <ParentalMessageReady message={message} showHistory={showHistory} />
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
