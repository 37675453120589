import { IAnswerRecordRequest, makeDatabaseInstanceOffline } from 'contentCacheManager'
import { getProfile } from 'contentCacheManager/utils'

export const getAnswerOffline = async (params: Omit<IAnswerRecordRequest, 'answers'>) => {
  const database = makeDatabaseInstanceOffline()
  const { id: profileId } = getProfile()
  const found = await database.answersCached.toArray()

  return found.find(item => item.requestAnswer.activity === params.activity &&
    item.requestAnswer.class === params.class &&
    item.requestAnswer.content_unit === params.content_unit &&
    item.userId && item.userId === String(profileId)
  )
}
