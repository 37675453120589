import { IApiResponse } from 'services/types'
import { ACCEPT_TERMS, GET_TERMS } from './CONSTANTS'
import { request } from 'services/base'
import { ITermsResponse, IAcceptanceTermsResponse } from '../types/terms'

export const getTerms = async (): Promise<IApiResponse<ITermsResponse[]>> =>
  await request({ url: GET_TERMS() })

export const acceptTerms = async (data: IAcceptanceTermsResponse): Promise<IApiResponse<IAcceptanceTermsResponse>> =>
  await request({ url: ACCEPT_TERMS(), method: 'POST', data: data })
