import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  boxContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  boxClose: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: 'none',
    border: 'none'
  },
  text: {
    color: theme.colors.neutral.dark40,
    marginInline: '16px',
    padding: '16px',
    borderRadius: '8px',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginInline: theme.spacingStack.xxs,
      color: theme.colors.neutral.dark40
    }
  },
  boxIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.colorBrand.medium
    }
  },
  close: {
    paddingLeft: '14px',
    margin: 0,
    fontWeight: 600,
    fontSize: '14px',
    color: theme.colorBrand.medium
  },
  modalTabs: {
    maxHeight: '100%',
    '& [role="tabpanel"]': {
      display: 'flex',
      justifyContent: 'center',
      maxHeight: '100%'
    }
  }
}))
