import { IAnswerResponse, IFileRecordResponse, IApiResponse, IAnswerRecordRequest, IAnswerRecordRequestCache, IAnswerRecordRequestCacheQueryParams, IFileRecordRequest, IStudentsAnswerByQuestionResponse, IRevisionAllAnswerRequest, IStudentsAnswerByActivityResponse, IAnswerCreateOrUpdateResponse } from 'services/types'
import { USER_ANSWER, FILE_RECORD, GET_FILE_RECORD_BY_UPLOAD_ID, ANSWER_RECORD, ANSWER_RECORD_CACHE, GET_STUDENTS_ANSWER_BY_QUESTION, REVISION_ALL_ANSWER, GET_STUDENTS_ANSWER_BY_ACTIVITY } from './CONSTANTS'
import { request } from 'services/base'

/*
* Como saber se a answer é nova? Vai primeiro na tabela ver se existe answer pra aquela question pra aquele user
* (Acho que podia ser feito logo quando o user entra na atividade essa etapa)
* Se não tiver, vai salvar uma answer nova. cria a asnwer primeiro. depois o registro especializado e depois o registro final.
* Em casos de multipla escolha paginada ele vai salvando os records pra cada avançar
* Nos casos de text ou upload juntos, ele salva no enviar.
* Após cada save no endpoint answer vai rodar uma rotina pra atualizar a activity_status_control
* Em casos de de edição: Como popular os campos com as respostas? Vai ter chamado o endpoint no começo de answer
* Se tiver vai pegar a record com o source_id existente e atualizar os regsitros com os novos. Quais tabelas das três etapas precisa ?
*
*
*/

/*
* Get all answers from an activity. Return array of answers or empty array. Will populate responses in case of edit
*
*/

export const getUserAnswers = async (profileId: number, activityId: number): Promise<IApiResponse<IAnswerResponse[]>> =>
  await request({ url: USER_ANSWER(profileId, activityId) })

/*
* Creates a new amswer with records
*/
export const saveAnswerRecord = async (data: IAnswerRecordRequest): Promise<IApiResponse<IAnswerCreateOrUpdateResponse>> =>
  await request({ url: ANSWER_RECORD(), method: 'post', data: data })

export const saveAnswerRecordCache = async (data: IAnswerRecordRequestCache, { isEditing }: IAnswerRecordRequestCacheQueryParams): Promise<IApiResponse<void>> =>
  await request({ url: ANSWER_RECORD_CACHE({ isEditing }), method: 'post', data: data })

export const editAnswerRecord = async (data: IAnswerRecordRequest): Promise<IApiResponse<IAnswerCreateOrUpdateResponse>> =>
  await request({ url: ANSWER_RECORD(), method: 'put', data: data, params: { stopServiceWorking: true } })

export const deleteFileRecord = async (fileRecordId: number): Promise<IApiResponse<IFileRecordRequest>> =>
  await request({ url: FILE_RECORD(fileRecordId), method: 'delete', data: { id: fileRecordId } })

export const getFileRecordByUploadId = async (uploadId: number): Promise<IApiResponse<IFileRecordResponse[]>> =>
  await request({ url: GET_FILE_RECORD_BY_UPLOAD_ID(uploadId), method: 'get' })

export const getStudentsAnswerByQuestion = async (classId: string | number, activityId: string | number, questionId: string | number): Promise<IApiResponse<IStudentsAnswerByQuestionResponse[]>> =>
  await request({ url: GET_STUDENTS_ANSWER_BY_QUESTION(classId, activityId, questionId), method: 'get' })

export const getStudentsAnswerByActivity = async (classId: string | number, activityId: string | number): Promise<IApiResponse<IStudentsAnswerByActivityResponse[]>> =>
  await request({ url: GET_STUDENTS_ANSWER_BY_ACTIVITY(classId, activityId), method: 'get' })

export const revisionAllAnswers = async (data: IRevisionAllAnswerRequest): Promise<IApiResponse<any>> =>
  await request({ url: REVISION_ALL_ANSWER(data), method: 'put' })

// export const createAnswer = async (answer): Promise<IApiResponse<IAnswerResponse>> =>
//   await request({ url: ANSWER(), method: 'post', data: answer })

// export const saveRecord = async (record): Promise<IApiResponse<IRecordResponse>> =>
//   await request({ url: RECORD(), method: 'post', data: record })

// export const getRecord = async (sourceId: number, sourceTypeId: number): Promise<IApiResponse<IRecordResponse>> =>
//   await request({ url: RECORD(sourceId, sourceTypeId) })
// export const savetextRecord = async (textRecord): Promise<IApiResponse<ITextRecordProps>> =>
//   await request({ url: TEXT_RECORD(), method: 'post', data: textRecord })

// export const savefileRecord = async (choiceRecord): Promise<IApiResponse<IFileRecordProps>> =>
//   await request({ url: CHOICE_RECORD(), method: 'post', data: choiceRecord })

// export const saveChoiceRecord = async (fileRecord): Promise<IApiResponse<IChoiceRecordProps>> =>
//   await request({ url: FILE_RECORD(), method: 'post', data: fileRecord })

// export const updatetextRecord = async (textId: number, text: string): Promise<IApiResponse<ITextRecordProps>> =>
//   await request({ url: TEXT_RECORD(textId), method: 'put', data: { text: text } })

// export const updatefileRecord = async (fileId: number, url: string): Promise<IApiResponse<IFileRecordProps>> =>
//   await request({ url: CHOICE_RECORD(fileId), method: 'put', data: { url: url } })

// export const updateChoiceRecord = async (choiceId: number, alternativeId: number): Promise<IApiResponse<IChoiceRecordProps>> =>
//   await request({ url: FILE_RECORD(choiceId), method: 'put', data: { alternative_id: alternativeId } })

// export const updateAnswer = async (answerId: number, answer): Promise<IApiResponse<IAnswerResponse>> =>
//   await request({ url: ANSWER(answerId), method: 'post', data: answer })
