import { useTranslation } from 'react-i18next'
// lib external components
import { Grid } from '@mui/material'
// global components
import { Header } from './Header'
import { Alert, Button } from 'components/design-system'
// styles
import useStyles from '../styles'
import { useStore } from 'store'
import { IUserSchoolProfileTypeEnum } from 'services/types'

interface IErrorStateTypes {
  dropDown: boolean
  setDropDown: React.Dispatch<React.SetStateAction<boolean>>
  actionTryAgain: () => Promise<void>
}

export const ErrorState = ({ dropDown, setDropDown, actionTryAgain }: IErrorStateTypes) => {
  const { profile } = useStore()
  const profileType = profile?.type
  const classes = useStyles({ profileType: profileType })
  const { t } = useTranslation()
  const buttonDataTestId = profileType === IUserSchoolProfileTypeEnum.teacher ? 'activities_to_correct_reload' : 'activities_to_answer_reload'

  const ActionButton = () => (
    <Button size='small' className={classes.buttonInsideAlert} variant='ghost' onClick={actionTryAgain} data-testid={buttonDataTestId}>
      {t('Recarregar')}
    </Button>
  )

  return (
    <>
      <Header dropDown={dropDown} setDropDown={setDropDown} />
      <Grid flexDirection='row' display='flex' sx={{ alignItems: 'flex-start', justifyContent: 'flex-start', width: 'inherit' }}>
        {dropDown &&
          <div style={{ width: 'inherit', display: 'flex', alignItems: 'center' }}>
            <Alert
              content={t('Não foi possível carregar o conteúdo. Tente novamente.')}
              severity='error'
              action={
                <ActionButton />
              }
              style={{ marginBlock: '20px', width: '100%', flex: 1 }}
            />
          </div>
        }
      </Grid>
    </>
  )
}
