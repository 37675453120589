import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyle from './style'

export interface IWeekDayProps {
  weekDay: string
  day: string
  month: string
  date: Date
  totalEvents: number
  selected: boolean
  isToday: boolean
  onClick?: () => void
  isHover?: boolean
  disabled?: boolean
  beforeSchoolPeriod?: boolean
  afterSchoolPeriod?: boolean
}

export const WeekDay: React.FC<IWeekDayProps> = props => {
  const { weekDay, day, totalEvents, onClick, disabled = false, beforeSchoolPeriod, afterSchoolPeriod } = props
  const [isHover, setIsHover] = useState(false)
  const classes = useStyle({ ...props, isHover })
  const { t } = useTranslation()

  const handleWeekNotices = () => {
    if (beforeSchoolPeriod) {
      return 'Ano anterior'
    } else if (afterSchoolPeriod) {
      return 'Próximo período'
    }

    return `${totalEvents} ${t(totalEvents > 1 ? 'eventos' : 'evento').toUpperCase()}`
  }

  return (
    <a
      className={classes.weekDayWrapper}
      data-testid='schedule_select_day_of_the_week'
      onClick={disabled ? undefined : onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div>
        <span className={classes.weekDayDescription}>
          {t(weekDay)}
        </span>
        <span className={classes.weekDayNumber}>
          {t(day)}
        </span>
      </div>
      <div>
        {
          (totalEvents > 0 || (beforeSchoolPeriod ?? afterSchoolPeriod)) &&
            <>
              <div className={classes.weekDaySeparator} />
              <div className={classes.weekNoticesEvents}>
                {handleWeekNotices()}
              </div>
            </>
        }
      </div>
    </a>
  )
}

export default WeekDay
