import create, { GetState, SetState } from 'zustand'
import { devtools, persist, StoreApiWithPersist, StoreApiWithSubscribeWithSelector, subscribeWithSelector } from 'zustand/middleware'
import { IDisciplineStore } from 'services/types'
import { SessionStoreProps } from './interfaces'

// https://github.com/pmndrs/zustand/blob/9277cff13a0d1b513907b39c1c25b22232e1a83d/tests/middlewareTypes.test.tsx >> ver teste linha 411

const initialParameters = {
  newTab: true,
  loadingDisciplines: false,
  currentDiscipline: null,
  disciplines: [],
  classId: null,
  currentClass: null,
  currentContentUnit: null,
  currentSegment: null,
  currentGrade: null
}

export const useSessionCurricularStore = create(
  devtools(subscribeWithSelector(
    persist<
    SessionStoreProps,
    SetState<SessionStoreProps>,
    GetState<SessionStoreProps>,
    StoreApiWithSubscribeWithSelector<SessionStoreProps> &
    StoreApiWithPersist<SessionStoreProps>
    >(
      (set, _get) => ({
        ...initialParameters,
        setNewTab: () => set({ newTab: false }),
        syncData: set,
        setDisciplines: (disciplines: IDisciplineStore[]) => set({ disciplines, loadingDisciplines: false }),
        setCurrentDiscipline: (discipline: IDisciplineStore | null) => set({ currentDiscipline: discipline, loadingDisciplines: false }),
        reset: () => set(initialParameters)
      }),
      { name: 'cloe::web::curricular', version: 2, getStorage: () => sessionStorage }
    )
  ))
)
