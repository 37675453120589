/* eslint-disable @typescript-eslint/no-unused-vars */
import create, { GetState, SetState } from 'zustand'
import { devtools, persist, StoreApiWithPersist } from 'zustand/middleware'
import {
  ISchoolPeriodResponse
} from 'services/types'

interface ISessionStoreProps {
  schoolPeriod: ISchoolPeriodResponse | null
  setSchoolPeriod: (schoolPeriod: ISchoolPeriodResponse | null) => void
}

export const useSessionStore = create<
ISessionStoreProps,
SetState<ISessionStoreProps>,
GetState<ISessionStoreProps>,
StoreApiWithPersist<ISessionStoreProps>
>(
  persist(
    devtools((set, _get) => ({
      schoolPeriod: null,
      setSchoolPeriod: (schoolPeriod: ISchoolPeriodResponse | null) => set({ schoolPeriod })
    })),
    { name: 'cloe::web::props', version: 1, getStorage: () => sessionStorage }
  )
)
