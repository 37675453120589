import { Fragment } from 'react'
import useStyles from './style'

interface IOps {
  removeImage?: boolean
}

export const Ops: React.FC<IOps> = ({ removeImage, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.containerEmptyDisciplines}>
      <p className={classes.titleEmptyDisciplines}>Ops!</p>
      <div className={classes.image404} style={{ display: removeImage ? 'none' : 'block' }} />
      <Fragment>
        {children}
      </Fragment>
    </div>
  )
}

export default Ops
