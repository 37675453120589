import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '892px',
    width: '100%',
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.md,

    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'grid',
      borderRadius: '0px',
      gridTemplateRows: 'auto 10fr 1fr',
      overflowY: 'auto'
    }
  },
  padding: {
    padding: theme.spacingInset.sm,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInset.xs
    }
  },
  txtToBack: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium
  },
  txtTitle: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium
  },
  txtSubTitle: {
    marginTop: theme.spacingStack.xxxs,
    marginBottom: theme.spacingStack.xxxs,
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium
  },
  txtCountSelected: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xs,
    color: theme.colors.neutral.dark10
  },
  txtLetter: {
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.darkest
  },
  content: {
    width: '100%',
    gap: '24px',
    minHeight: '400px',
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...theme.scroll,

    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px'
    },

    [theme.breakpoints.down('sm')]: {
      overflowY: 'hidden',
      maxHeight: '100%'
    }
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: theme.spacingStack.xxs
  },
  tagDefault: {
    cursor: 'pointer',
    width: 'fit-content',
    height: 'max-content',
    border: `1px solid ${theme.colors.neutral.light10} !important`
  },
  tagSelected: {
    cursor: 'pointer'
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.colors.neutral.light10,
    border: 'none'
  }
}))
