import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    backLink: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.semibold,
      lineHheight: '16px',
      letterSpacing: '0.12px',
      textDecoration: 'none',
      color: theme.colorBrand.medium,
      padding: `${theme.spacingInline.nano} 0px ${theme.spacingInline.nano} 0px`,
      '& svg': {
        width: '14px',
        marginRight: '12px'
      }
    },
    scheduleCard: {
      '& #activityCardItem': {
        border: `1px solid ${theme.colors.neutral.light10}`
      }
    },
    button: {
      marginBlock: `${theme.spacingInline.xxxs} !important`
    }
  })
)
