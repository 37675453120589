import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import dayjs from 'dayjs'
// lib external components
import Grid from '@mui/material/Grid'
import { NavLink, useParams } from 'react-router-dom'
import * as SvgIcons from '@mui/icons-material'
import { useTheme } from '@mui/material'
// global components
import { Tag, Text2 } from 'components/design-system'
import OnlyProfile from 'components/common/OnlyProfile'
// services
import { IScheduleActivitiesPendentResponse } from 'services/types/schedule'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { maxLength } from 'utils/string'
import { useContentUnitLinks } from 'utils/contentUnit/useContentUnitLinks'
// styles
import useStyles from './styles'
import { DaysUntilEnd } from './components/DaysUntilEnd'

export interface IScheduleCardProps {
  scheduleEndDate?: number
  imageUrl?: string
  dropDownState?: boolean
  description?: string
  type: ContentTypeEnum
  classesCount?: number
  expireDate?: number
  startDate?: number
  profileType?: IUserSchoolProfileTypeEnum
  id?: number
  ClassName?: string
  activitiesPendents: IScheduleActivitiesPendentResponse
  onClick?: () => void
}

export const ScheduleCard: React.FC<IScheduleCardProps> = props => {
  const { activitiesPendents, type } = props
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type
  const styleProps = { ...props, profileType }
  const classes = useStyles(styleProps)
  const { t } = useTranslation()
  const theme = useTheme()
  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()
  // string + format
  dayjs.extend(customParseFormat)
  const { getExerciceURL } = useContentUnitLinks()

  const contentTypes = {
    reading: {
      icon: SvgIcons.DescriptionOutlined,
      label: t('Conteúdo'),
      tagColor: theme.colors.contentType.default
    },
    activity: {
      icon: SvgIcons.ExtensionOutlined,
      label: t('Atividade'),
      tagColor: theme.colors.contentType.activity
    },
    evaluation: {
      icon: SvgIcons.BallotOutlined,
      label: t('Avaliação'),
      tagColor: theme.colors.contentType.evaluation
    },
    guide: {
      icon: SvgIcons.BorderColorOutlined,
      label: t('Atividade'),
      tagColor: theme.colors.contentType.activity
    }
  }

  const { icon: SvgIcon } = contentTypes[type]

  interface ICardTagging {
    [key: string]: {
      [key: string]: string
    }
  }
  const cardTagging: ICardTagging = {
    student: {
      activity: 'activities_to_answer_open_activity',
      nonActivity: 'activities_to_answer_open_assessment'
    },
    teacher: {
      activity: 'activities_to_correct_open_activity',
      nonActivity: 'activities_to_correct_open_assessment'
    }
  }

  const linkHrefContentUnit = getExerciceURL({
    currentDisciplineCode: activitiesPendents.disciplineCode,
    gradeTypeCode,
    gradeCode,
    classId,
    contentUnitId: String(activitiesPendents.contentUnitId),
    activityId: String(activitiesPendents.id),
    suggestedApplicationType: activitiesPendents.suggestedApplicationType
  })

  return (
    <NavLink
      to={linkHrefContentUnit}
      className={`ui-common-schedule_card ${classes.scheduleCardContainer}`}
      data-testid={cardTagging[String(profileType)][type === 'activity' ? 'activity' : 'nonActivity']}
      onClick={props.onClick}
    >
      <div id='activityCardItem' className={classes.activityCardItem}>
        <div className={classes.disciplineContainer}>
          <Tag key={activitiesPendents.disciplineId} variant={activitiesPendents.disciplineCode}>
            {profileType === 'student'
              ? maxLength(t(activitiesPendents.discipline), 14)
              : t(activitiesPendents.discipline)
            }
          </Tag>
          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
            <DaysUntilEnd endDate={activitiesPendents.scheduleEndDate ?? ''} />
          </OnlyProfile>
        </div>
        {/* body */}
        <Grid item >
          <div className={classes.title}>
            <Text2 fontSize='sm' fontWeight='bold' lineHeight='xxs' mobile='sm' colorbrand='medium'>
              {maxLength(activitiesPendents.title, 50)}
            </Text2>
          </div>
          <div className={classes.descriptionContainer}>
            <SvgIcon className={classes.contentLinkIcon} />
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs' neutral='dark20' sx={{ textTransform: 'uppercase' }}>
              {maxLength(activitiesPendents.description, 30)}
            </Text2>
          </div>
        </Grid>
        {/* footer */}
        <Grid item className={classes.footerContainer}>
          <Grid item className={classes.contentLinkTags}>
            <Tag color='white' background={contentTypes[type].tagColor}>
              {contentTypes[type].label}
            </Tag>
          </Grid>
          <Grid item className={classes.footerActions}></Grid>
        </Grid>
      </div>
    </NavLink >
  )
}

export default ScheduleCard
