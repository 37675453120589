import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import avatarTermNotFound from 'assets/avatar-term-not-found-student.svg'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.font.fontSize.lg,
      color: theme.colorBrand.medium,
      fontWeight: theme.font.fontWeight.medium,
      textAlign: 'center',
      width: '500px',
      marginBottom: `${theme.spacingStack.xxs} !important`,

      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    subTitle: {
      fontSize: theme.font.fontSize.sm,
      color: theme.colors.neutral.dark30,
      fontWeight: theme.font.fontWeight.medium,
      textAlign: 'center',
      width: '500px',

      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    image: {
      backgroundImage: `url(${avatarTermNotFound})`,
      backgroundSize: '100%',
      width: '14.125rem',
      height: '14.938rem',
      backgroundRepeat: 'no-repeat',
      marginBottom: `${theme.spacingStack.xxs} !important`,
      marginTop: `${theme.spacingInset.md}`,

      [theme.breakpoints.down('sm')]: {
        width: '13.75rem',
        height: '14.188rem',
        marginTop: `${theme.spacingInset.xxs}`
      }
    }
  })
)
