import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    width: '54vw',
    padding: '1rem',
    backgroundColor: theme.colorBrand.darkest,
    color: '#fff',
    // mobile
    [theme.breakpoints.down('sm')]: {
      '&': {
        width: '92vw'
      }
    }
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
  },
  title: {
    fontSize: theme.font.fontSize.md,
    fontWeight: 'bold'
  },
  iconClose: {
    cursor: 'pointer'
  },
  button: {
    '&.MuiButton-root': {
      backgroundColor: theme.colorBrand.darkest,
      padding: '4px 16px',
      textTransform: 'initial',
      fontSize: '0.9rem',
      fontWeight: 'normal',
      fontFamily: theme.font.fontFamily,
      '&:hover': {
        backgroundColor: theme.colorBrand.darkest
      }
    }
  }
}))
