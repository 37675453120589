import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useStore } from 'store'

import { Grid } from '@mui/material'
import { Button } from 'components/design-system'

import { TAUGHT_DISCIPLINES } from 'navigation/CONSTANTS'

import useStyle from './styles'

export const ConfigurationErrorState = () => {
  const { profile } = useStore()

  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid className={classes.containerEmptyState}>
      <Grid className={classes.boxTitles}>
        <div className={classes.emptyStateTitle}>
          {t('Você ainda não configurou a')} {profile?.school?.name}.
        </div>
        <div className={classes.emptyStateSubTitle}>
          {t('Para usar a CLOE nesta escola, você precisa configurá-la.')}
        </div>
      </Grid>
      <Grid className={classes.imageEmptyState} />
      <Grid className={classes.buttonContainer} mt={3}>
        <NavLink to={TAUGHT_DISCIPLINES} style={{ textDecoration: 'none' }} data-testid='set_up_another_school'>
          <Button variant='primary'>
            {t('Vamos lá')}
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  )
}
