import { DisciplineCodeEnum, ISectionResponse } from 'services/types'
import { Grid } from '@mui/material'
import ContentLink from 'components/common/ContentLink'
import { useParams } from 'react-router-dom'
import { ContentTypeEnum } from 'services/types/content'
import { Resource, Text } from 'components/common'
import React from 'react'
import { useContents } from '../../../useContents'

import useStyle from './style'

interface IClassSectionProps {
  section: ISectionResponse
}

export const ClassSection: React.FC<IClassSectionProps> = ({ section }) => {
  const { gradeTypeCode, gradeCode, classId, contentId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string }>()
  const classes = useStyle()

  const { content } = useContents()
  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {section.title}
      </div>

      <div className={classes.contentContainer}>
        <Grid container spacing={2} className={classes.sectionContainer}>
          {
            section.resources?.map(resource =>
              <Grid key={resource.id} item xs={12}>
                <Resource
                  resource={resource}
                />
              </Grid>)
          }
        </Grid>
        <Grid container className={classes.sectionContainer}>
          <Text content={section.text} />
          {/* <div
            className={classes.sectionContainerText}
            dangerouslySetInnerHTML={{
              __html: section.text ?? ''
            }}
          /> */}
          {
            section.contents.map(content =>
              <Grid container item key={content.id}>
                <Grid item xs={12} className={classes.title}>{content.title}</Grid>
                <Grid item xs={12}
                  className={classes.sectionContainerText}
                  // dangerouslySetInnerHTML={{
                  //   __html: content.text ?? ''
                  // }}
                >
                  <Text content={content.text} />
                </Grid>
              </Grid>)
          }
        </Grid>
        <Grid container spacing={1} className={classes.sectionContainer}>
          {
            section.activities.map(activity =>
              <Grid className={classes.activityItem} item xs={12} sm={12} md={6} lg={6} key={activity.id}>
                <ContentLink
                  id={activity.id?.toString()}
                  activityId={activity.id?.toString()}
                  gradeCode={gradeCode}
                  gradeTypeCode={gradeTypeCode}
                  classId={classId}
                  contentId={contentId}
                  title={activity.title ?? ''}
                  type={activity.suggested_application_type === 'activity' ? ContentTypeEnum.activity : ContentTypeEnum.evaluation}
                  suggestedApplicationType={activity.suggested_application_type}
                  useCloeExpandTranslation={isCloeExpand}
                />
              </Grid>)
          }
        </Grid>
      </div>
    </div>
  )
}

export default ClassSection
