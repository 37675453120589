import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    // wrapper: {
    //   backgroundColor: theme.colors.neutral.lightBase,
    //   padding: '0 2rem',
    //   // mobile
    //   [theme.breakpoints.down('sm')]: {
    //     padding: '0 16px'
    //   }
    // },
    // backToContent: {
    //   marginTop: theme.spacingInline.xxxs,
    //   fontWeight: theme.font.fontWeight.semibold,
    //   fontSize: theme.font.fontSize.xxs,
    //   color: theme.colorBrand.medium,
    //   lineHeight: theme.font.lineHeight.xxs,
    //   '& a': {
    //     textDecoration: 'none'
    //   }
    // },
    // title: {
    //   color: theme.colorBrand.medium,
    //   fontWeight: theme.font.fontWeight.medium,
    //   fontSize: theme.font.fontSize.xl,
    //   letterSpacing: theme.font.spacing.distant,
    //   lineHeight: '40px',
    //   margin: '24px 0 40px 0',
    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: theme.font.fontSize.lg,
    //     lineHeight: theme.font.lineHeight.sm,
    //     marginBottom: theme.spacingStack.xxs
    //   }
    // },
    // uteranceTitle: {
    //   color: theme.colors.neutral.dark20,
    //   fontWeight: theme.font.fontWeight.semibold,
    //   fontSize: theme.font.fontSize.xs,
    //   lineHeight: '17px',
    //   width: '100%'
    // },
    // utteranceText: {
    //   color: theme.colors.neutral.dark30,
    //   fontWeight: theme.font.fontWeight.regular,
    //   fontSize: theme.font.fontSize.sm,
    //   lineHeight: '19px',
    //   width: '100%',
    //   '& table': {
    //     borderSpacing: 0,
    //     '& th, td': {
    //       border: '1px solid #ddd',
    //       padding: `${theme.spacingStack.nano} ${theme.spacingStack.xxxs}`,
    //       fontWeight: theme.font.fontWeight.semibold,
    //       fontSize: theme.font.fontSize.xs,
    //       [theme.breakpoints.down('sm')]: {
    //         border: '1px solid #ddd',
    //         padding: '5px',
    //         fontSize: '9px'
    //       }
    //     }
    //   }
    // },
    // text: {
    //   color: theme.colors.neutral.dark30,
    //   fontFamily: theme.font.fontFamily,
    //   fontWeight: theme.font.fontWeight.regular,
    //   fontSize: theme.font.fontSize.sm,
    //   lineHeight: theme.font.lineHeight.sm,
    //   paddingTop: 0,
    //   marginTop: 0,
    //   '& > a': {
    //     color: theme.colorBrand.medium,
    //     fontWeight: theme.font.fontWeight.bold
    //   }
    // },
    nextButton: {
      '&.outlined': {
        padding: '12px 24px',
        '&:first-child': {
          marginRight: theme.spacingInline.xs
        },
        // mobile
        [theme.breakpoints.down('sm')]: {
          minWidth: 'unset',
          paddingInline: '11.5px'
        }
      },
      '& > svg': {
        marginLeft: '14px',
        fontSize: '16px'
      },
      '& span': {
        '&.only-desktop': {
          // mobile
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        },
        '&.only-mobile': {
          display: 'none',
          // mobile
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        paddingBlock: '10px'
      }
    }
    // enunciate: {
    //   '& img': {
    //     maxWidth: '100%'
    //   }
    // }
  })
)
