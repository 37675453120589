import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    background: 'rgba(230, 230, 230, 0.32)',
    padding: theme.spacingInset.xs,
    borderRadius: theme.borderRadius.md,
    color: `${theme.colors.neutral.dark20} !important`,
    fontWeight: theme.font.fontWeight.medium
  },
  questionsSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacingInline.xxxs,
    marginTop: theme.spacingInline.nano,
    flexWrap: 'wrap'
  },
  modalContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      width: '100vw !important',
      height: '100vh !important',
      maxWidth: '100vw !important',
      maxHeight: '100vh !important',
      bottom: 'inherit'
    }
  }
}))
