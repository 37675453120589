import { AssignmentLateOutlined, AssignmentTurnedInOutlined, PendingActionsOutlined } from '@mui/icons-material'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'

export const getDeliveryStatus = (deliveryStatus: string) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  switch (deliveryStatus) {
    case 'Entregue':
      return (
        <Grid container alignItems='center' justifyContent={isMobile ? 'flex-end' : 'center'} columnSpacing={1}>
          <Grid item>
            <AssignmentTurnedInOutlined fontSize='small' sx={{ color: `${theme.colors.information.feedbackMedium}!important`, marginTop: '4px' }} />
          </Grid>
          <Grid item>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Entregue')}
            </Text2>
          </Grid>
        </Grid>
      )
    case 'Não Entregue':
      return (
        <Grid container alignItems='center' justifyContent={isMobile ? 'flex-end' : 'center'} columnSpacing={1}>
          <Grid item>
            <AssignmentLateOutlined fontSize='small' sx={{ color: `${theme.colors.negative.feedbackMedium}!important`, marginTop: '4px' }} />
          </Grid>
          <Grid item>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Não entregue')}
            </Text2>
          </Grid>
        </Grid>
      )
    case 'Pendente':
      return (
        <Grid container alignItems='center' justifyContent={isMobile ? 'flex-end' : 'center'} columnSpacing={1}>
          <Grid item>
            <PendingActionsOutlined fontSize='small' sx={{ color: `${theme.colors.warning.feedbackDark}!important`, marginTop: '4px' }} />
          </Grid>
          <Grid item>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Pendente')}
            </Text2>
          </Grid>
        </Grid>
      )
  }
}
