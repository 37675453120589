import { Grid, Skeleton, Theme, useTheme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      border: `1px solid ${theme.colors.neutral.light10}`,
      borderRadius: theme.borderRadius.sm
    }
  })
)

export const Loading: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Grid
      container
      item
      direction='row'
      mb={3}
      spacing={1}
      ml={0}
      justifyContent='flex-start'
      alignItems='stretch'
      className={classes.cardContainer}
    >
      <Grid
        item
        xs={12}
        lg={3}
        justifyContent='flex-start'
        alignItems='flex-start'
        container
      >
        <Grid item xs lg alignItems='flex-start' mt={0}>
          <Skeleton animation='wave' variant='rectangular' height={180} />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={3}>
        <Grid
          item
          container
          direction='row'
          gap={theme.spacingInline.nano}
          justifyContent='flex-start'>
          <Skeleton animation='wave' height={40} width={70} />
          <Skeleton animation='wave' height={40} width={70} />
        </Grid>
        <Skeleton animation='wave' width={250} height={40} />

        <Skeleton animation='wave' width={170} height={40} />
        <Skeleton animation='wave' width={100} height={40} />
      </Grid>

      <Grid item xs={12} lg={3}>
        <Skeleton animation='wave' height={27} width={140} />
        <Skeleton animation='wave' height={27} />
        <Skeleton animation='wave' height={27} />
        <Skeleton animation='wave' height={27} />
        <Skeleton animation='wave' height={27} />
        <Skeleton animation='wave' height={27} />
      </Grid>

      <Grid item xs={12} lg={3}>
        <Grid
          item
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='stretch'
        >
          <Grid item xs={12} lg={12} mb={2}>
            <Skeleton animation='wave' width={150} height={30} />
          </Grid>

          <Grid item xs={12} lg={12} container justifyContent='flex-start'>
            <Grid item xs={4} lg={4} mb={2}>
              <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
            </Grid>

            <Grid item xs={4} lg={4} mb={2}>
              <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
            </Grid>

            <Grid item xs={4} lg={4} mb={2}>
              <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
            </Grid>

            <Grid item xs={4} lg={4} mb={2}>
              <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
            </Grid>

            <Grid item xs={4} lg={4} mb={2}>
              <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
            </Grid>

            <Grid item xs={4} lg={4} mb={2}>
              <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Loading
