import { Grid, useTheme } from '@mui/material'
import { Button, TabPanel } from 'components/design-system'
import { useAtom } from 'jotai'
import { BusinessError } from 'navigation/BusinessError'
import { ROOT } from 'navigation/CONSTANTS'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useStore } from 'store'
import { isCloseHeaderAtom, tabIndexAtom, teacherContentErrorAtom } from './atomStore'
import Contents from './Contents'
import Header from './Header'
import { ScheduleTeacher } from './Schedule'
import useStyle from './style'
import Timeline from './Timeline'
import { ReportsTab } from './ReportsTab'
import { ISubscriptionsSessionResponse } from 'services/types'

const DashboardTeacher = () => {
  // atoms
  const [contentError, setContentError] = useAtom(teacherContentErrorAtom)
  const [isCloseHeader] = useAtom(isCloseHeaderAtom)

  const { t } = useTranslation()
  const { classId } = useParams<{ classId: string }>()
  const [tabIndex] = useAtom(tabIndexAtom)
  const { session } = useStore()

  const classes = useStyle()
  const theme = useTheme()

  const classSelected = session?.subscriptions
    .filter((cur: ISubscriptionsSessionResponse) => cur.class)
    .find(sub => sub?.class?.id?.toString() === classId)?.class

  if (!classSelected) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <BusinessError error={t('Nenhuma turma selecionada')}>
          <br />
          <NavLink to={ROOT} style={{ textDecoration: 'none' }}>
            <Button>{t('Voltar ao Minhas Turmas')}</Button>
          </NavLink>
        </BusinessError>
      </Grid>
    )
  }

  if (contentError) {
    return (
      <Grid container justifyContent='center' alignItems='center'>
        <BusinessError
          error={t('Não conseguimos carregar o conteúdo para você')}
        >
          <br />
          <div className={classes.buttonContainer}>
            <NavLink
              to={ROOT}
              style={{ textDecoration: 'none' }}
              className={classes.button}
            >
              <Button variant='outlined'>
                {t('Voltar para Página Inicial')}
              </Button>
            </NavLink>
            <Button
              onClick={() => [
                window.location.reload(),
                setContentError(!contentError)
              ]}
            >
              {t('Tentar novamente')}
            </Button>
          </div>
        </BusinessError>
      </Grid>
    )
  }

  const showClassReport = !classSelected?.grades[0]?.code?.includes('EI_')

  return (
    <Grid container sx={{
      padding: {
        sm: theme.spacingInset.sm
      },
      paddingTop: '0 !important'
    }}>
      {!isCloseHeader && (
        <Header
          theClassName={classSelected.name}
          gradeName={classSelected.grades[0].name ?? ''}
        />
      )}
      <Grid container mt={4} className={classes.tabsPanelWrapper}>
        <TabPanel value={tabIndex} index={0}>
          <ScheduleTeacher />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Contents />
        </TabPanel>
        {showClassReport && (
          <TabPanel value={tabIndex} index={2}>
            <ReportsTab classSelectedName={classSelected.name} />
          </TabPanel>
        )}
        <TabPanel value={tabIndex} index={showClassReport ? 3 : 2}>
          <Timeline />
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default DashboardTeacher
