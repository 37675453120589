import { CustomTheme } from 'styles/types/CustomTheme'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.MuiTabs-root': {
      minHeight: 'auto!important',
      '& .MuiTabs-indicator': {
        backgroundColor: theme.colorBrand.medium
      },
      '& .MuiButtonBase-root': {
        paddingBottom: '16px!important'
      }
    }
  }
}))
