import { useEffect, useState, createRef } from 'react'
import dayjs from 'dayjs'
import { ptBR } from 'date-fns/locale'

import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField as TextFieldMUI, TextFieldProps } from '@mui/material'
import { toast } from 'components/design-system/Toast/manager'

import useStyles from './style'

interface IInputDateProps extends Partial<DatePickerProps<Date, Date>> {
  label?: string
  initialDate?: Date | null
  minDate?: Date
  maxDate?: Date
  mask?: string
  inputFormat?: string
  onChangeDate?: (newDate: Date, keyboardInputValue?: string) => void
  textFieldProps?: TextFieldProps
  error?: boolean
  dataTestid?: string
  updateHoursMinutes?: boolean
  forceDateRefresh?: boolean
}

export const InputDate = ({
  label,
  initialDate,
  onChangeDate,
  dataTestid,
  forceDateRefresh = false,
  onError = () => {
    // This is intentional
  },
  mask = '__/__',
  inputFormat = 'dd/MM',
  ...props
}: IInputDateProps) => {
  const [inputDate, setInputDate] = useState<Date | null | undefined>(initialDate)
  const [minDate] = useState(props?.minDate)
  const [maxDate] = useState(props?.maxDate)
  const [openCalendar, setOpenCalendar] = useState(false)

  const inputRef = createRef<HTMLAnchorElement>()

  const updateHoursMinutes = props?.updateHoursMinutes ?? true

  const classes = useStyles()

  useEffect(() => {
    if (!inputDate || forceDateRefresh) {
      setInputDate(initialDate)
    }
  }, [initialDate])

  const handleChange = (newDate: Date, keyboardInputValue?: string) => {
    let updatedDate = dayjs(newDate).toDate()

    if (updateHoursMinutes) {
      updatedDate = dayjs(newDate).startOf('day').toDate()
    }

    if (onChangeDate) {
      try {
        onChangeDate(updatedDate, keyboardInputValue)
        setInputDate(updatedDate)
      } catch (err: any) {
        toast.handler({
          content: err.message,
          duration: 10000,
          severity: 'error'
        })
      }
    }
  }

  const handleDataTaging = () => {
    inputRef?.current?.click()
  }

  return (
    <>
      <a ref={inputRef} data-testid={dataTestid} />
      <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={inputDate as Date | null}
          minDate={minDate}
          maxDate={maxDate}
          mask={mask}
          open={openCalendar}
          onClose={() => setOpenCalendar(false)}
          inputFormat={inputFormat}
          onChange={(newValue, keyboardInputValue) => {
            handleChange(newValue as Date, keyboardInputValue)
          }}
          onError={onError}
          renderInput={(params) => (
            <TextFieldMUI
              {...params}
              {...props.textFieldProps}
              onKeyDown={(e) => e.preventDefault()}
              onClick={() => [handleDataTaging(), setOpenCalendar(true)]}
              error={!!props.error || params.error}
              autoComplete='off'
              className={classes.selector}
            />
          )}
          DialogProps={{
            sx: { '& .MuiPickersToolbar-penIconButton': { display: 'none' } }
          }}
          {...props}
        />
      </LocalizationProvider>
    </>
  )
}
