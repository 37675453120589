import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  helpOptionLink: {
    display: 'flex',
    justifyContent: 'center',
    color: `${theme.colors.support.colorSupport02}!important`,
    textDecorationColor: `${theme.colors.support.colorSupport02}!important`,
    cursor: 'pointer',
    fontWeight: theme.font.fontWeight.medium
  }
}))
