import dayjs from 'dayjs'

import { TMilestoneRecordsByTime } from '..'
import { MonthLabel } from '../components'

interface IRenderLeftMonthLabels {
  milestoneRecords: TMilestoneRecordsByTime[]
  milestoneDateStart: string
}

export const renderLeftMonthLabels = ({ milestoneRecords, milestoneDateStart }: IRenderLeftMonthLabels) => {
  const recordsNumber = milestoneRecords.length
  const lastElementMonth = milestoneRecords[recordsNumber - 1].monthNumber

  const numMonthsLeft = lastElementMonth - dayjs(milestoneDateStart).month()
  const monthsLeft = Array.from({ length: numMonthsLeft }, (_, index) => lastElementMonth - (index + 1))

  return monthsLeft.map(monthNumber => <MonthLabel monthNumber={monthNumber} />)
}
