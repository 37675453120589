import { IExpeditionCached, OfflineTableExpeditionCachedStatus } from '../'
import { ExpeditionSize } from '../dtos'

export class ExpeditionCachedTable implements IExpeditionCached {
  id?: number
  expeditionId: number
  status: OfflineTableExpeditionCachedStatus
  successUrls: string[]
  errorUrls: string[]
  createdAt: Date
  cachedAt: Date
  embeddedUrls: string[]
  size: ExpeditionSize

  constructor(data: IExpeditionCached) {
    this.expeditionId = data.expeditionId
    this.status = data.status
    this.successUrls = data.successUrls
    this.errorUrls = data.errorUrls
    this.createdAt = data.createdAt
    this.cachedAt = data.cachedAt
    this.embeddedUrls = data.embeddedUrls
    this.size = data.size

    if (data.id) this.id = data.id
  }
}
