import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  quoteContainer: {
    position: 'relative',
    padding: theme.spacingInset.xs,
    paddingLeft: theme.spacingInset.md,
    background: 'rgba(230, 230, 230, 0.40)',
    borderRadius: theme.borderRadius.sm,
    width: '100%',
    maxWidth: '600px',
    [theme.breakpoints.up('xl')]: {
      width: '70%',
      maxWidth: '70%'
    }
  },
  quote: {
    fontStyle: 'italic',
    fontSize: '18px', // theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.regular,
    lineHeight: '32px',
    letterSpacing: '0.16px',
    '& > p': {
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  fakeBorder: {
    position: 'absolute',
    width: '8px',
    background: theme.colors.support.colorSupport02,
    top: 0,
    bottom: 0,
    left: 0,
    borderTopLeftRadius: theme.borderRadius.md,
    borderBottomLeftRadius: theme.borderRadius.md
  }
}))
