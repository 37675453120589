import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  cloeAvatarContent: {
    marginBottom: theme.spacingInset.lg,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '&.MuiGrid-root': {
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start'
      }
    }
  },
  wormBackground1: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: -1,
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  wormBackground2: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: -1,
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  wormBackground3: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: -1,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  wormBackground4: {
    position: 'absolute',
    top: 62,
    right: 0,
    zIndex: -1,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  welcomeTextContainer: {
    '&.MuiGrid-root': {
      maxWidth: 431,
      display: 'flex',
      marginLeft: theme.spacingInset.md,
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacingInset.xs
      }
    }
  },
  welcomeText: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${theme.font.fontSize.lg} !important`
    }
  },
  whatDoYouWant: {
    [theme.breakpoints.down('sm')]: {
      fontSize: `${theme.font.fontSize.sm} !important`
    }
  },
  shortcutCardContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      '&.MuiGrid-root': {
        flexDirection: 'column'
      }
    }
  }
}))
