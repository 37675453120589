import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal } from 'components/design-system'
import useStyles from './style'
import { ROOT } from 'navigation/CONSTANTS'
import { useTranslation } from 'react-i18next'
import BaseAuthLayout from 'pages/BaseAuthLayout'
import { PhoneOrEmail, SuccessfullySent } from 'pages/Recover/components'

interface RecoverPropsComponent {
  recoverType: string
}

export const Recover: React.FC<RecoverPropsComponent> = ({ recoverType }) => {
  const history = useHistory()
  const styles = useStyles()
  const { t } = useTranslation()

  // states
  const [step, setStep] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)
  const [recoverContentType, setRecoverContentType] = useState<string>('')
  const handleOpen = () => setOpen(true)
  const [emailOrPhone, setEmailOrPhone] = useState<string>('')
  const handleClose = () => setOpen(false)

  const renderStep = () => {
    switch (step) {
      case 0:
        return <PhoneOrEmail
          nextStep={() => setStep(step + 1)}
          onCancel={handleOpen}
          recoverType={recoverType}
          setRecoverContentType={setRecoverContentType}
          setEmailOrPhone={setEmailOrPhone}
        />
      case 1:
        return <SuccessfullySent
          email={emailOrPhone}
          phone={emailOrPhone}
          recoverContentType={recoverContentType}
          recoverType={recoverType} />
    }
  }

  return (
    <BaseAuthLayout>
      {renderStep()}
      <Modal
        size='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <div>
          <div className={styles.modalTitle} id='modal-title'>
            {t('Você realmente deseja cancelar?')}
          </div>
          <div className={styles.modalDescription} id='modal-description'>
            {t(
              'Ao confirmar, a ação será cancelada e todas as informações serão perdidas.'
            )}
          </div>
          <div className={styles.modalButtons}>
            <Button
              className={styles.submitButton}
              variant='primary'
              onClick={() => history.replace(ROOT)}
              type='button'
            >
              {t('Continuar')}
            </Button>
            <Button
              className={styles.cancelButton}
              variant='ghost'
              onClick={handleClose}
              type='button'
            >
              {t('Cancelar')}
            </Button>
          </div>
        </div>
      </Modal>
    </BaseAuthLayout>
  )
}

export default Recover
