import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  knowMoreButton: {
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs} !important`,
    fontSize: `${theme.font.fontSize.xxs} !important`,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  }
}))
