import { Alert, Tag } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import { NavLink } from 'react-router-dom'
import { IDisciplineResponse } from 'services/types/discipline'
import { CONTENT_UNIT_STUDENT } from 'navigation/CONSTANTS'

import { DownloadButton, IAnswerCachedStatus } from 'contentCacheManager'
import OnlyAnswered from 'contentCacheManager/components/OnlyAnswered'
import OnlyOffline from 'contentCacheManager/components/OnlyOffline'
import { ContentUnitStatus } from 'pages/Dashboard/teacher/Contents/components/ListContentUnit/components/ContentCardLong/components/Card/components/ContentUnitStatus'

export interface IContentCardProps {
  title: string
  disciplines: IDisciplineResponse[]
  imageUrl?: string
  classesCount?: number
  expireDate?: number
  startDate?: number
  id: number
  classId?: string
  gradeTypeCode?: string
  gradeCode?: string
  dataTestid?: string
  suggestedPosition?: number
}

export const ContentCard: React.FC<IContentCardProps> = props => {
  const { title, disciplines, expireDate, startDate, id, classId, dataTestid, suggestedPosition } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  const renderDate = () => {
    if (startDate) {
      return (
        <Tag className={classes.dataTag} >
          {`${startDate} - ${expireDate as number}`}
        </Tag>
      )
    }
  }

  const contentLink = CONTENT_UNIT_STUDENT(id?.toString())

  const handlePreventClickInterceptors = (e: React.MouseEvent<HTMLElement>) => {
    const { type } = (e.target as any)
    if (type && type === 'button') {
      e.preventDefault()
    }
  }

  return (
    <NavLink
      to={contentLink}
      style={{ textDecoration: 'none' }}
      data-testid={dataTestid ?? ''}
      onClick={handlePreventClickInterceptors}
      className='ui-common-content_card'
    >
      <Grid item xs={12}
        sx={{ flexDirection: 'column', alignItems: 'space-between' }}
        className={classes.backgroundImage}
      >
        <div className={classes.boxCover}>
          <div className={classes.disciplineContainer}>
            {
              disciplines?.length
                ? disciplines.map(d => <Tag key={d.id} variant={d.code} className={classes.tagContent}>{t(d.name)}</Tag>)
                : (`${t('Erro ao listar disciplinas CARD')}`)
            }
            {renderDate()}
            <div className={classes.number}>{suggestedPosition}</div>
          </div>
        </div>
        <div className={classes.expeditionContainer}>
          <Grid width='max-content'>
            <ContentUnitStatus contentUnitId={id} classId={classId ?? 0} />
          </Grid>
          <div className={classes.titleContainer}>
            {title + ''}
          </div>
          <OnlyOffline forceMobile>
            <OnlyAnswered content_unit={String(id)} render={(data) => {
              if (data.find(answer => answer.status === IAnswerCachedStatus.SENT_ERROR)) {
                return <Alert content={t('Ocorreu um erro no envio das respostas.')} severity='error' style={{ margin: '0px 10px 20px 10px' }} />
              }
              if (data.find(answer => answer.status === IAnswerCachedStatus.SENT_SUCCESS)) {
                return <Alert content={t('Respostas enviadas com sucesso')} severity='success' style={{ margin: '0px 10px 20px 10px' }} />
              }

              return <Alert content={t('Suas respostas salvas serão enviadas assim que a internet voltar.')} severity='info' style={{ margin: '0px 10px 20px 10px' }} />
            }}>
            </OnlyAnswered>
          </OnlyOffline>
          <DownloadButton expeditionId={id} dataTestid='student_home_content_download' />

        </div>
      </Grid>
    </NavLink>
  )
}

export default ContentCard
