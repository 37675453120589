import { ActivityControlStatusEnum, IActivityResponse } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'
import ContentLink from '../ContentLink'
import dayjs from 'dayjs'

interface ILinkProps {
  lessonId: number
  contentId: number
  classId: number
  gradeTypeCode: string
  gradeCode: string
}

interface IDataTestidProps {
  activity: {
    available: string
    to_correct: string
    finished: string
  }
  evaluation: {
    available: string
    to_correct: string
    finished: string
  }
}

export interface ActivityCardProps {
  activity: IActivityResponse
  useCloeExpandTranslation: boolean
  link: ILinkProps
  newTab?: boolean
  statusDataTestid: IDataTestidProps
  isExpeditionView?: boolean
}
// TODO: add state
// TODO: state por profile?
export const ActivityCard: React.FC<ActivityCardProps> = (
  props: ActivityCardProps
) => {
  const { activity, statusDataTestid, link, newTab } = props
  const today = dayjs()

  const dataTest = statusDataTestid[activity.suggested_application_type]

  let dataTestid = dataTest.available

  // const notAnswered = undefined // TODO: aluno
  const isPending =
    activity.activity_status?.status === ActivityControlStatusEnum.PENDING
  let statusClosed
  let statusReleased
  let awaitingNote
  let isAvailable = true
  let yellowTags: string[] = []

  if (activity?.schedule?.start_date && activity?.schedule?.end_date) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { start_date, end_date } = activity?.schedule
    statusClosed = dayjs(end_date).isBefore(today)
    if (statusClosed) {
      dataTestid = dataTest.to_correct
    }
    statusReleased =
      dayjs(today).isAfter(start_date) &&
      activity.activity_status?.status === ActivityControlStatusEnum.CLOSED
    if (statusReleased) {
      statusClosed = false
      dataTestid = dataTest.finished
    }
    awaitingNote = dayjs(start_date).isAfter(today) && activity.has_answer
    isAvailable = false
    if (!statusClosed && !awaitingNote && !statusReleased) {
      yellowTags = [dayjs(end_date).format('DD/MM/YYYY')]
    }
  }

  /**
   * Removento todos os status do card até encontrarmos um meio de trazer mais informação do back-end
   */
  statusClosed = false
  statusReleased = false
  awaitingNote = false
  yellowTags = []

  const type = (): ContentTypeEnum => {
    let t = ContentTypeEnum.reading

    if (activity.suggested_application_type === 'activity') {
      t = ContentTypeEnum.activity
    }

    if (activity.suggested_application_type === 'evaluation') {
      t = ContentTypeEnum.evaluation
    }

    return t
  }

  return (
    <ContentLink
      id={link.contentId.toString()} // TODO: não deveria sei o contentID, porem a regra do link no contentlink pede isso
      activityId={activity.id?.toString()}
      contentId={link.contentId.toString()}
      classId={link.classId.toString()}
      title={activity.title ?? activity.activity_name}
      type={type()}
      useType={activity.use_type}
      gradeTypeCode={link.gradeTypeCode}
      gradeCode={link.gradeCode}
      hasNotification={activity.hasNotification}
      suggestedApplicationType={activity.suggested_application_type}
      statusClosed={statusClosed}
      showTypeTag
      statusReleased={statusReleased}
      isPending={isPending}
      awaitingNote={awaitingNote}
      isAvailable={isAvailable}
      newTab={newTab}
      dataTestid={dataTestid}
      yellowTags={yellowTags}
      isExpeditionView={props.isExpeditionView}
      useCloeExpandTranslation={props.useCloeExpandTranslation}
    />
  )
}

export default ActivityCard
