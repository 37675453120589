import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, useTheme } from '@mui/material'
import { DropdownSelector } from 'components/common'
import { toast } from 'components/design-system/Toast/manager'

import { IContentUnitStatus } from 'services/types'

import useStyle from './styles'
import { getContentUnitProgress, updateContentUnitProgress } from 'services/content-unit'

interface IStatusSelectorProps {
  contentUnitId: number | string
  classId: number | string
}

export const StatusSelector = ({ contentUnitId, classId }: IStatusSelectorProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const initialStatus = 'IDLE'

  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<IContentUnitStatus | 'LOADING'>('LOADING')

  const classes = useStyle()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const response = await getContentUnitProgress(Number(contentUnitId), Number(classId))

      if (!response.success && response.status !== 400) {
        throw new Error(t('Ocorreu um erro ao tentar resgatar o seu progresso nessa unidade de conteúdo'))
      }

      setSelected(response.data?.status || initialStatus)
    } catch (err: any) {
      toast.handler({
        content: err.message,
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const setContentUnitStatus = async (status: IContentUnitStatus) => {
    try {
      await updateContentUnitProgress(contentUnitId, classId, status)
    } catch (err) {
      toast.handler({
        content: t('Ocorreu um erro ao tentar modificar o status da unidade de conteúdo. Tente novamente.'),
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const statusOptions = {
    LOADING: {
      title: t('Carregando...')
    },
    IDLE: {
      title: t('Não iniciada')
    },
    INPROGRESS: {
      title: t('Em andamento')
    },
    DONE: {
      title: t('Concluída')
    }
  }

  const handleSelect = (selected: IContentUnitStatus) => {
    setSelected(selected)
    setContentUnitStatus(selected)
    setIsSelectorOpen(false)
  }

  const handleBackSelector = () => {
    setIsSelectorOpen(oldIsSelectorOpen => !oldIsSelectorOpen)
  }

  const renderOptions = () => {
    let options = statusOptions
    if (selected !== 'LOADING') {
      const { LOADING: __, ...newObj } = options
      options = newObj as any
    }

    return Object.entries(options).map(([action, { title }], index) => {
      const isActive = action === selected

      return (
        <a
          key={index}
          data-testid={'home_content_select_status'}
          className={`${classes.optionContainer} ${isActive && 'active'} ${action}`}
          onClick={() => handleSelect(action as IContentUnitStatus)}
        >
          {title}
        </a>
      )
    })
  }

  return (
    <DropdownSelector
      placeholder={(
        <>
          <span className={`${classes.labelText} ${selected}`}>{t('Situação')}</span>
          <span className={`${classes.titleText} ${selected}`}>{statusOptions[selected].title}</span>
        </>
      )}
      colorTheme={theme.colors.neutral.lightBase}
      isOpen={isSelectorOpen}
      className={classes.dropdownContainer}
      setIsOpen={setIsSelectorOpen}
      backButtonLabel={t('Situação')}
      handleSelectorClick={handleBackSelector}
      selectedTextClassName={classes.selectedText}
      selectedClassName={`${classes.selectedContainer} ${selected}`}
      drawerContainerClassName={classes.drawerContainerClassName}
      dataTestId={'home_content_status_content_unit'}
    >
      <Grid className={classes.container}>
        {renderOptions()}
      </Grid>
    </DropdownSelector >
  )
}
