import { Button, Tag } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import { DisciplineCodeEnum } from 'services/types/discipline'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { IDisciplinesResponse } from 'services/types/schedule'
import { IMediaResponse } from 'services/types'
import { useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export interface ICoverProps {
  title?: string
  discipline?: IDisciplinesResponse
  imageUrl?: IMediaResponse
  handleCloseModal?: () => void
  dataTestIdClose?: string
}

export const Cover: React.FC<ICoverProps> = props => {
  const { title, discipline, handleCloseModal, dataTestIdClose } = props

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const classes = useStyles(props)
  const { t } = useTranslation()

  const CloseReturnButton = () => (
    <div className={isMobile ? classes.iconWrapper : classes.exitbuttonWrapper}>
      <Button style={{
        background: 'transparent',
        padding: '4px',
        minWidth: 'unset'
      }}
      onClick={handleCloseModal}
      >
        {isMobile
          ? <KeyboardArrowLeftIcon data-testid={dataTestIdClose ?? 'event_close'} style={{ fontSize: '32px', color: 'white' }} />
          : <CloseIcon data-testid={dataTestIdClose ?? 'event_close'} style={{ fontSize: '28px', color: 'white' }} />}
      </Button>
    </div>
  )

  return (
    <>
      <Grid item xs={12} className={classes.backgroundImage}>
        <div className={classes.boxCover}>
          <CloseReturnButton />

          <div className={classes.disciplineContainer}>
            {
              discipline &&
              <Tag key={discipline.id} variant={discipline.code as DisciplineCodeEnum}>
                {t(discipline.name)}
              </Tag>
            }
          </div>

          {
            !isMobile && (
              <div className={classes.titleContainer}>
                {title}
              </div>
            )
          }
        </div>
      </Grid>
    </>
  )
}

export default Cover
