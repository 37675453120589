import { Text } from 'components/design-system'
import Ops from 'components/common/Ops'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyle = makeStyles((theme: Theme) => createStyles({
  contentUnitWrapper: {
    width: 'calc(100% - 40px)',
    margin: '0 auto',
    minHeight: 'calc(100% - 90px)',
    marginTop: 10,
    zIndex: -1,
    overflow: 'hidden',
    background: theme.colors.neutral.lightBase,
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,
    boxShadow: theme.shadowLevels.level5,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      boxShadow: 'none',
      paddingTop: '100px',
      '&.fullScreen': {
        height: 'auto'
      }
    }
  },
  bold: {
    fontWeight: 'bold'
  }
}))

export const UserProfileError: React.FC = () => {
  const classes = useStyle()
  return (
    <div className={classes.contentUnitWrapper}>
      <Ops>
        <Text type='heading' className={classes.bold}>Erro ao acessar a turma!</Text>
        <br />
        <Text type='heading'>Entre em contato com a sua escola</Text>
        <br />
        <Text type='heading'>para resolver esse problema</Text>
      </Ops>
    </div>
  )
}
