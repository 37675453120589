import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { OpenDirectionType } from '.'

interface IDropdownSelectorProps {
  colorTheme: string
  openDirection: OpenDirectionType
}

const COMPONENT_HEIGHT = '54px'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'relative',
    color: (props: IDropdownSelectorProps) => {
      return `${props.colorTheme ?? theme.colorBrand.medium}`
    },
    textDecoration: 'none',

    '& .iconGoBackLink': {
      display: 'none',
      alignItems: 'center',
      marginTop: theme.spacingInline.xxxs,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm,
      cursor: 'pointer'
    },

    [theme.breakpoints.down('sm')]: {
      '&.focused': {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: +5,
        width: '100%',
        height: '100%',
        background: theme.colors.neutral.lightBase,

        '& .iconGoBackLink': {
          display: 'flex'
        },

        '& .selectedContainer': {
          display: 'none'
        }
      }
    }
  },

  /**
   * VIEW SELECTED
   **/
  selectedContainer: {
    cursor: 'pointer',
    border: '2px solid transparent',
    boxSizing: 'border-box',
    borderRadius: theme.borderRadius.sm,
    padding: `${theme.spacingInline.nano} ${theme.spacingInline.quarck} ${theme.spacingInline.nano} ${theme.spacingInline.nano}`,
    backgroundColor: 'rgba(230, 230, 230, 0.32)',
    height: COMPONENT_HEIGHT,
    overflow: 'hidden',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacingInline.nano,

    '&.focused': {
      border: (props: IDropdownSelectorProps) => {
        return `2px solid ${props.colorTheme ?? theme.colorBrand.medium}`
      }
    },
    '&.disabled': {
      border: 'none',
      backgroundColor: 'rgba(230, 230, 230, 0.32);'
    },
    '&:hover': {
      transition: 'all ease-in-out 55ms',
      border: (props: IDropdownSelectorProps) => {
        return `2px solid ${props.colorTheme ?? theme.colorBrand.medium}`
      },
      backgroundColor: 'rgba(230, 230, 230, 0.32);',
      '&.disabled': {
        border: 'none',
        backgroundColor: 'rgba(230, 230, 230, 0.32);'
      }
    }
  },
  selectedLabel: {
    [theme.breakpoints.up('sm')]: {
      background: (props: IDropdownSelectorProps) => {
        return `${props.colorTheme ?? theme.colorBrand.medium}`
      },
      padding: `${theme.spacingInline.quarck} ${theme.spacingInline.nano}`,
      borderRadius: theme.borderRadius.xs,
      color: theme.colors.neutral.lightBase,
      fontWeight: `${theme.font.fontWeight.medium} !important`,
      display: 'flex'
    },
    display: 'none'
  },
  placeholderGroup: {
    display: 'flex',
    flexDirection: 'column !important' as 'column'
  },
  auxPlaceholder: {
    color: theme.colorBrand.medium,
    fontWeight: `${theme.font.fontWeight.medium} !important`
  },
  selectedText: {
    color: (props: IDropdownSelectorProps) => {
      return `${props.colorTheme ?? theme.colorBrand.medium}`
    },
    fontWeight: `${theme.font.fontWeight.bold} !important`,
    marginRight: 'auto',
    fontSize: `${theme.font.fontSize.xs} !important`,
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis'
  },

  /**
   * SELECTOR DRAWER
   **/
  '@keyframes overflowAnimation': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 0
    }
  },
  drawerContainer: (props: IDropdownSelectorProps) => ({
    display: 'flex',
    maxHeight: 0,
    position: 'absolute',
    zIndex: +10,
    overflow: 'hidden',

    ...(props.openDirection === 'top-down'
      ? {}
      : {
        bottom: `calc(${COMPONENT_HEIGHT} + ${theme.spacingStack.nano})`
      }),

    width: '100%',
    flexDirection: 'column !important' as 'column',
    background: theme.colors.neutral.lightBase,
    boxShadow: '0px 8px 16px rgba(20, 20, 20, 0.16)',

    transition: 'max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    borderRadius: theme.borderRadius.sm,

    '& .drawerBody': {
      flex: 1,
      overflowY: 'auto',
      opacity: 0,

      ...theme.scroll
    },

    '&.active': {
      maxHeight: '50vh',

      [theme.breakpoints.down('md')]: {
        maxHeight: 'unset !important',
        height: '100% !important',
        position: 'relative',
        bottom: 'unset'
      },

      '& .drawerBody': {
        opacity: 1,
        animation: '$overflowAnimation 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      }
    }
  }),
  drawerFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacingInline.xxxs} 0`,

    background: '#F7F7F7', // TO ASK: cor n existe no design system, devemos colocar ou substituir?

    '& button': {
      [theme.breakpoints.up('sm')]: {
        fontSize: `${theme.font.fontSize.xs} !important`,
        padding: `${theme.spacingInline.nano} ${theme.spacingInline.xxxs} !important`,
        width: 'auto',
        marginBottom: 0
      },
      marginBottom: '40px',
      width: '90%'
    }
  }
}))
