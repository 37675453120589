
const localCookie = {
  setItem: (name: string, value: string) => {
    const expDays = 30
    const domain = process.env.REACT_APP_COOKIE_DOMAIN as string
    const env = process.env.REACT_APP_ENV as string
    const date = new Date()
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
    const security = env === 'local' ? '' : 'SameSite=None; Secure'
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/; domain=${domain}; ${security}`
  },
  getItem: (name: string) => {
    const cName = name + '='
    const cDecoded = decodeURIComponent(document.cookie)
    const cArr = cDecoded.split('; ')
    let res
    cArr.forEach(val => {
      if (val.indexOf(cName) === 0) res = val.substring(cName.length)
    })
    return res
  },
  removeItem: (name: string) => {
    const domain = process.env.REACT_APP_COOKIE_DOMAIN as string
    document.cookie = `${name}=''; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${domain};`
  }
}

const Cookies = {
  token: {
    set: (value: string) => localCookie.setItem(`cloe-web::token-jwt::${process.env.REACT_APP_ENV as string}`, value),
    get: () => localCookie.getItem(`cloe-web::token-jwt::${process.env.REACT_APP_ENV as string}`) ?? '',
    remove: () => localCookie.removeItem(`cloe-web::token-jwt::${process.env.REACT_APP_ENV as string}`)
  }
}

export default Cookies
