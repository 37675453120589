import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Icon, Search } from 'components/design-system'
import useStyles from './style'

import WebOutlinedIcon from '@mui/icons-material/WebOutlined'
import { IContentUnitResponse, statusSortActionsEnum } from 'services/types'
import { useCurricularStore, useStore } from 'store'
import {
  ButtonFilter,
  getExpeditions,
  useOnlineStatus,
  ExpeditionCachedTable,
  sleep
} from 'contentCacheManager'
import emptyStateFigureDownloaded from 'assets/empty-state-screen-content-unit-list-only-downloaded.svg'
import ContentUnits from './components/ContentUnits'
import { cloneObject } from 'utils/array'
import Analytics from 'utils/analytics'
import { getContentUnitByClassDisciplinev2 } from 'services/content-unit'
import { sortContentUnits } from 'pages/Dashboard/teacher/Contents/components/ListContentUnit/helpers/sortContentUnits'
import Loading from './components/Loading'
import NotFoundSearchContentUnits from '../NotFoundSearchContentUnits'
import { StatusSelector } from 'components/common'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shoetest = require('shoetest')

enum StateListContentUnitEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  CONTENT_UNITS = 'CONTENT_UNITS',
  NOT_FOUND_CONTENT_UNITS = 'NOT_FOUND_CONTENT_UNITS',
  NOT_FOUND_SEARCHED_CONTENT_UNITS = 'NOT_FOUND_SEARCHED_CONTENT_UNITS'
}

export const ContentUnitsList: React.FC = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { currentDiscipline, classId } = useCurricularStore()
  const { profile } = useStore()
  const isOnline = useOnlineStatus()
  const searchRef = createRef<any>()

  const [state, setState] = useState<StateListContentUnitEnum>(StateListContentUnitEnum.LOADING)
  const [onlyExpeditionsDownloaded, setOnlyExpeditionsDownloaded] = useState(false)
  const [expeditionsCached, setExpeditionsCached] = useState<ExpeditionCachedTable[]>([])
  const [searchPhrase, setSearchPhrase] = useState<string>('')

  const [contentUnits, setContentUnits] = useState<IContentUnitResponse[]>([])
  const [deltaContentUnits, setDeltaContentUnits] = useState<IContentUnitResponse[]>([])

  const NotFoundContentComponent = useCallback(() => {
    if (onlyExpeditionsDownloaded || !isOnline) {
      return (
        <div id='pages-contents-content_unit_list-not_found_content' className={classes.emptyStateDownloaded}>
          <img src={emptyStateFigureDownloaded} />
          <p>
            {t(`Você ainda não baixou nenhum conteúdo.
Quando um conteúdo for baixado, ele irá aparecer aqui.`)}
          </p>
        </div>
      )
    }

    return (
      <p className={classes.subTitleContent}>
        {t('Nenhhum conteúdo encontrado')}
      </p>
    )
  }, [onlyExpeditionsDownloaded, isOnline])

  const TitleContentComponent = () => {
    return (
      <div className={classes.titleContent}>
        <div className={classes.itemTag}>
          <Icon titleAccess={t('Unidades de conteúdo')} size='medium'>
            <WebOutlinedIcon />
          </Icon>
        </div>
        <p>{t('Unidades de conteúdo')}</p>
      </div>
    )
  }

  const NotSelectedDisciplineComponent = () => {
    return (
      <div className={classes.containerEmptyDisciplines}>
        <div className={classes.image} />
        <p>
          {t('Selecione um componente curricular acima')}
        </p>
      </div>
    )
  }

  const getContentUnits = async () => {
    setContentUnits([])
    searchRef.current?.clear()
    setState(StateListContentUnitEnum.LOADING)
    setSearchPhrase('')

    if (currentDiscipline?.id) {
      const response = await getContentUnitByClassDisciplinev2(
        classId ?? 0,
        parseInt(currentDiscipline.id.toString())
      )
      if (response.success) {
        const sortedContentUnits = sortContentUnits('ASC', cloneObject(response.data))
        setContentUnits(sortedContentUnits)
        setDeltaContentUnits(cloneObject(response.data))
        setState(StateListContentUnitEnum.CONTENT_UNITS)
      } else {
        setState(StateListContentUnitEnum.NOT_FOUND_SEARCHED_CONTENT_UNITS)
      }
    }
  }

  const handleContentUnitsSort = async (sortOption: statusSortActionsEnum) => {
    setState(StateListContentUnitEnum.LOADING)
    const sortedContentUnits = sortContentUnits(sortOption, contentUnits)
    setDeltaContentUnits(sortedContentUnits)
    setContentUnits(sortedContentUnits)
    await sleep(300)
    setState(StateListContentUnitEnum.CONTENT_UNITS)
  }

  const search = (pattern: string) => {
    let analyticsTerm = 'search_term_found'

    setState(StateListContentUnitEnum.LOADING)
    setSearchPhrase(pattern)

    const newContentsUnits = cloneObject(deltaContentUnits).filter(
      (cur: IContentUnitResponse) => {
        if (shoetest.test(pattern, cur.title)) return true
        if (
          (cur?.key_words ?? []).some(keyWord =>
            shoetest.test(pattern, keyWord)
          )
        ) {
          return true
        }

        if (
          (cur?.bnccSkill ?? []).some(bnccSkill =>
            shoetest.test(pattern, bnccSkill.code) || shoetest.test(pattern, bnccSkill.name)
          )
        ) {
          return true
        }

        if (
          (cur?.skills ?? []).some(skills =>
            shoetest.test(pattern, skills.code) || shoetest.test(pattern, skills.name)
          )
        ) {
          return true
        }

        return false
      }
    )

    setContentUnits(newContentsUnits)
    setState(StateListContentUnitEnum.CONTENT_UNITS)
    if (!newContentsUnits.length) {
      analyticsTerm = 'search_term_not_found'
      setState(StateListContentUnitEnum.NOT_FOUND_SEARCHED_CONTENT_UNITS)
    }

    Analytics.recordEventClick({
      name: analyticsTerm,
      attributes: {
        ...profile?.analytics,
        class_id: classId,
        search_term: pattern
      }
    })
  }

  const clear = () => {
    setSearchPhrase('')
    setState(StateListContentUnitEnum.LOADING)
    setContentUnits(deltaContentUnits)
    setState(StateListContentUnitEnum.CONTENT_UNITS)
  }

  const handleFilterExpedition = () => {
    setOnlyExpeditionsDownloaded(!onlyExpeditionsDownloaded)
  }

  const contentUnitsFiltered = useMemo(() => {
    if (onlyExpeditionsDownloaded || !isOnline) {
      return contentUnits?.filter(content => expeditionsCached.find(expedition => expedition.expeditionId === content.id))
    }

    return contentUnits
  }, [
    contentUnits,
    onlyExpeditionsDownloaded,
    isOnline,
    expeditionsCached
  ])

  useEffect(() => {
    void getContentUnits()
  }, [currentDiscipline])

  useEffect(() => {
    if (contentUnits?.length) {
      const loadExpeditions = async () => {
        const expeditions = await getExpeditions(contentUnits.map(content => content.id))
        setExpeditionsCached(expeditions)
      }
      loadExpeditions()
    }
  }, [
    contentUnits,
    onlyExpeditionsDownloaded
  ])

  const render: { [key in StateListContentUnitEnum]: any } = {
    IDLE: <Loading />,
    LOADING: <Loading />,
    CONTENT_UNITS: (
      <ContentUnits contentUnits={contentUnitsFiltered} highlight={searchPhrase} />
    ),
    NOT_FOUND_CONTENT_UNITS: (
      <NotFoundContentComponent />
    ),
    NOT_FOUND_SEARCHED_CONTENT_UNITS: <NotFoundSearchContentUnits term={searchPhrase} />
  }

  return (
    <div className={classes.contentScroll}>
      <Grid container className={classes.searchContainer}>
        <Search
          placeholder='Localizar na página'
          onSubmit={search}
          onClear={clear}
          dataTestId={{
            clear: 'home_content_search_topic_result_found',
            submit: 'home_content_search_topic_localize'
          }}
          ref={searchRef}
          showSubmitButton={false}
        />
        <StatusSelector onChange={async (sortOption: statusSortActionsEnum) => await handleContentUnitsSort(sortOption)} dataTestId={'home_content_order_content_unit'} />
      </Grid>
      {
        !currentDiscipline
          ? <NotSelectedDisciplineComponent />
          : <React.Fragment>
            <TitleContentComponent />
            <ButtonFilter id='btn-contents-content_unit_list' onClick={handleFilterExpedition} filterEnable={onlyExpeditionsDownloaded} />

            {render[state]}
          </React.Fragment>
      }
    </div>
  )
}

export default ContentUnitsList
