import React, { useState, useEffect } from 'react'
import { IContentUnitResponse } from 'services/types'
import { getActivityByClass } from 'services/activity'
import { useTranslation } from 'react-i18next'
import Loading from 'components/common/Loading'
import { useParams } from 'react-router'
import { useAtom } from 'jotai'
import { useStore } from 'store'
import { BusinessError } from 'navigation/BusinessError'
import { answersAtom, isAnsweredAtom, activityStatusControlAtom, activityCurrentAtom } from '../atomStore'
import { classActivityStatusesAtom, loadingRequestAtom, selectedStudentProfileAtom, activitySuggestedApplicationTypeAtom, showModalConfirmationAtom, reloadingActivityAtom } from './atomStoreOlder'
import { updateScheduleTimeAtom } from 'pages/ContentUnit/atomStore'
import { MY_CLOE_CONTEXT, myCloeContextAtom } from 'navigation/components/MyCloe/atomStore'
import TeacherActivityNew from './TeacherActivityNew'

interface ITeacherActivityProps {
  content: IContentUnitResponse
}

type StateTeacherActivity = 'idle' | 'loading' | 'renderContainer' | 'notFoundActivity' | 'notFoundprofile'

export const TeacherActivityNewContainer: React.FC<ITeacherActivityProps> = ({ content }) => {
  // state
  const [state, setState] = useState<StateTeacherActivity>('idle')

  // atom
  const [activityCurrent, setActivityCurrent] = useAtom(activityCurrentAtom)
  const [, setActivitySuggestedApplicationType] = useAtom(activitySuggestedApplicationTypeAtom)
  const [, setSelectedStudentProfile] = useAtom(selectedStudentProfileAtom)
  const [, setClassActivityStatuses] = useAtom(classActivityStatusesAtom)
  const [, setShowModal] = useAtom(showModalConfirmationAtom)
  const [loadingRequest] = useAtom(loadingRequestAtom)
  const [, setReloadingActivity] = useAtom(reloadingActivityAtom)
  const [, setAnswers] = useAtom(answersAtom)
  const [, setIsAnswered] = useAtom(isAnsweredAtom)
  const [, setActivityStatusControl] = useAtom(activityStatusControlAtom)
  const [, setMyCloeContext] = useAtom(myCloeContextAtom)
  const [updateScheduleTime, setUpdateScheduleTime] = useAtom(updateScheduleTimeAtom)

  // store
  const { profile } = useStore()
  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, contentId: string, activityId: string, evaluationId: string }>()
  // consts
  const { t } = useTranslation()

  const getDataActivity = async () => {
    setState('loading')
    setActivityCurrent(null)
    const response = await getActivityByClass(classId, activityId ?? evaluationId)
    if (response.success) {
      const activityRes = response.data

      if (!activityRes) {
        setState('notFoundActivity')
      }

      setActivityCurrent(activityRes)
      setActivitySuggestedApplicationType(activityRes.suggested_application_type)
      setActivityStatusControl(activityRes.activity_status?.status ?? 0)
    }
    setIsAnswered(true)
    setState('renderContainer')
  }

  const loadNewActivity = async () => {
    setReloadingActivity(true)
    setActivityCurrent(null)
    setClassActivityStatuses(null)
    setAnswers([])
    setSelectedStudentProfile(0)
    await getDataActivity()
    setReloadingActivity(false)
  }

  useEffect(() => {
    if (!loadingRequest) {
      setClassActivityStatuses(null)
      setAnswers([])
      setSelectedStudentProfile(0)
      void getDataActivity()
    }
  }, [loadingRequest])

  useEffect(() => {
    if (updateScheduleTime) {
      getDataActivity()
      setUpdateScheduleTime(false)
    }
  }, [updateScheduleTime])

  useEffect(() => {
    loadNewActivity()
  }, [activityId, evaluationId])

  useEffect(() => {
    setShowModal(false)
    setMyCloeContext(MY_CLOE_CONTEXT.ACTIVITY)

    return () => {
      setMyCloeContext(MY_CLOE_CONTEXT.HOME)
    }
  }, [])

  if (!profile && state !== 'notFoundprofile') {
    setState('notFoundprofile')
  }

  const render: { [key in StateTeacherActivity]: any } = {
    idle: <div style={{ width: '100%' }}><Loading type='linear' /></div>,
    loading: <div style={{ width: '100%' }}><Loading type='linear' /></div>,
    notFoundActivity: <BusinessError error={t('Erro com o cadastro da atividade')} />,
    notFoundprofile: <BusinessError error={t('Houve um erro com o teu perfi atual')} />,
    renderContainer: activityCurrent && <TeacherActivityNew content={content} activity={activityCurrent} />
  }

  return render[state]
}

export default TeacherActivityNewContainer
