import {
  PipeParams,
  makeRequestOptions,
  CachedContentData,
  makeUrlWithParams
} from '../../'

type MakeEachPromisesProps<T> = {
  pipeParams: PipeParams
  addRequestToCache: (request: Request) => Promise<CachedContentData<T>>
}

export const makeEachPromises = <T>({ addRequestToCache, pipeParams }: MakeEachPromisesProps<T>) => {
  const { url, params, headers, onCompletedEach, onErrorEach } = pipeParams
  if (params) {
    const options = makeRequestOptions(headers)
    const [paramKey, paramValues] = Object.entries(params)[0]
    if (Array.isArray(paramValues)) {
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      return paramValues.map((paramValue, index) => {
        const othersParamsKeys = Object.keys(params).filter(key => key !== paramKey).reduce((acc, next) => Object.assign(acc, { [next]: params[next][index] }), {})
        const newPromise = new Promise<CachedContentData<T>>((resolve, reject) => {
          const requestEach = new Request(makeUrlWithParams(url, { [paramKey]: paramValue, ...othersParamsKeys }), options)
          addRequestToCache(requestEach).then((res) => {
            onCompletedEach?.(res, pipeParams, requestEach)
            resolve(res)
          }).catch((err) => {
            onErrorEach?.(err, pipeParams, requestEach)
            reject(err)
          })
        })
        return newPromise
      })
    }
  }
  throw new Error('params not found')
}
