import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { ISubscriptionsSessionResponse, IProfilesSessionResponse } from 'services/types'

export const changeClassMenuStateSubscriptionAtom = atom<ISubscriptionsSessionResponse | null>(null)
export const changeschoolMenuStateSchoolsAtom = atom<IProfilesSessionResponse | null>(null)
export const activeChangeClassAtom = atom<boolean>(false)
export const activeHeaderConfigsAtom = atom<boolean>(false)
export const activeMenuSidebarAtom = atom<boolean>(false)
export const activeModalConfirmationAtom = atom<boolean>(false)
export const activeModalVinculateAtom = atom<boolean>(false)
export const isBlockedNavigationAtom = atom<boolean>(false)
export const hideMainNavigationAtom = atom<boolean>(false)

export const windowLoadingAtom = atom<boolean>(true)

export const redirectLinkAtom = atomWithStorage<string>('redirectLink', '')

export const selectorClassOpenAtom = atom<boolean>(false)

export const cloeProURLParamsAtom = atom<string>('')
export const isCloeProActiveAtom = atom<boolean>(false)
