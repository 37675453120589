import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IDisciplineResponse, IDisciplineStore } from 'services/types'

import { useStore } from 'store'

import Analytics from 'utils/analytics'

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { Tag, Icon } from 'components/design-system'
import { LoadingWrapper } from 'components/common'

import useStyles from './style'

interface IDisciplines {
  disciplines: IDisciplineResponse[]
  currentDiscipline: IDisciplineStore | null
  changeDiscipline: (discipline: IDisciplineResponse) => void
  title?: string
  dataTestid?: string
}

export const Disciplines: React.FC<IDisciplines> = ({
  disciplines,
  currentDiscipline,
  changeDiscipline,
  title,
  dataTestid
}) => {
  const [dropDown, setDropDown] = useState<boolean>(true)

  const { subscription } = useStore()

  const { t } = useTranslation()

  const classes = useStyles()

  const handleEventAnalytics = (discipline: IDisciplineResponse) => {
    if (subscription) {
      Analytics.recordEventClick({
        name: 'curricular_component',
        attributes: {
          ...subscription?.analytics,
          discipline_id: discipline?.id,
          discipline_title: discipline?.name
        }
      })
    }
  }

  return (
    <LoadingWrapper type='linear' loading={(disciplines === null)}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {title}
        </div>
        <div
          className={classes.titleDrop}
          onClick={() => setDropDown(!dropDown)}
          id='see_all_mobile'
        >
          <p>{t('Ver todos')}</p>
          { dropDown
            ? <Icon titleAccess={t('Ver todos')} size='medium'>
              <KeyboardArrowUpOutlinedIcon />
            </Icon>
            : <Icon titleAccess={t('Recolher todos')} size='medium'>
              <KeyboardArrowDownOutlinedIcon />
            </Icon>
          }
        </div>
      </div>
      <div id='curricular_component' data-testid={dataTestid} className={`${classes.tagsContainer}  ${!dropDown && 'active'}`}>
        {
          disciplines?.map(discipline =>
            <Tag
              key={discipline.code ?? discipline.id}
              variant={currentDiscipline?.id === discipline.id ? discipline.code : undefined}
              className={`${classes.tag}`}
              onClick={() => [changeDiscipline(discipline), handleEventAnalytics(discipline)]}
            >
              {t(discipline.name)}
            </Tag>
          )
        }
      </div>
    </LoadingWrapper>
  )
}
