import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    awnserQuestions: {
      paddingTop: '20px',
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs,
      lineHeight: '17px',

      '&#selected-question': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacingInset.xs,
        alignItems: 'center'
      }
    }
  })
)
