import { useTranslation, Trans as TransComponent } from 'react-i18next'

interface IUseTranslationNamespaceProps {
  ns?: string[]
  activeIndex?: number
}

interface ITransProps {
  i18nKey: string
  useDefault?: boolean
  children: React.ReactNode
}

const defaultNamespace = 'translation'

export const useTranslationNamespace = ({ ns = [defaultNamespace], activeIndex = 0 }: IUseTranslationNamespaceProps) => {
  const { t: translation } = useTranslation()

  if (activeIndex < 0 || activeIndex > ns.length - 1) {
    throw new Error('Invalid activeNamespaceIndex')
  }

  const t = (key: string, useDefault = false) => translation(key, { ns: useDefault ? defaultNamespace : ns[activeIndex] })

  const Trans = ({ i18nKey, useDefault = false, children }: ITransProps) => {
    return (
      <TransComponent i18nKey={i18nKey} ns={useDefault ? defaultNamespace : ns[activeIndex]}>
        {children}
      </TransComponent>
    )
  }
  return {
    t,
    Trans
  }
}
