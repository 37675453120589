import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

const styleDefault: (theme: CustomTheme) => CSSProperties = theme => ({
  '& .MuiPaginationItem-root': {
    background: 'none',
    border: `solid ${theme.borderThickness.hairline}`,
    borderColor: theme.colorBrand.medium,
    color: theme.colorBrand.medium,
    margin: `0 ${theme.spacingStack.xxs} 0 0`,
    '&:hover': {
      background: theme.colorBrand.lightest
    },
    '&.Mui-selected': {
      background: theme.colorBrand.medium,
      color: theme.colors.neutral.lightBase
    }
  }
})

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.outlined': {
      ...styleDefault(theme)
    }
  }
}))
