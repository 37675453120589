import { Alert, Button } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from '../../style'
import { Grid } from '@mui/material'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { NavigateBefore } from '@mui/icons-material'

interface IConfirmationSchoolDataProps {
  onCancel: () => void
  previousStep: () => void
  schoolName: string
  token: string
  type: IUserSchoolProfileTypeEnum | undefined
}

export const ConfirmationSchoolData: React.FC<IConfirmationSchoolDataProps> = ({ onCancel, previousStep, schoolName, token, type }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const profileMessages = {
    admin: 'administrador(a)',
    coordinator: 'coordenador(a)',
    student: 'estudante',
    teacher: 'professor(a)'
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div onClick={previousStep} className={styles.returnLink} data-testid='autonomous_registration_school_info_back'>
          <NavigateBefore fontSize='small' />
          <span>{t('Voltar')}</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.purpleTitle}>
          <AdminPanelSettingsOutlinedIcon sx={{ marginRight: '8px' }} /> {t('Cadastro autônomo')}
        </div>
      </Grid>
      <Grid container item xs={12} columnSpacing={0.5}>
        <Grid item xs='auto'>
          <div className={styles.description} style={{ fontWeight: 600 }}>
            {t('Código')}:
          </div>
        </Grid>
        <Grid item xs='auto' className={styles.token}>
          <div className={styles.description}>
            {token}
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} columnSpacing={0.5}>
        <Grid item xs='auto'>
          <div className={styles.description} style={{ fontWeight: 600 }}>
            {t('Escola')}:
          </div>
        </Grid>
        <Grid item xs='auto'>
          <div className={styles.description}>
            {schoolName}
          </div>
        </Grid>
      </Grid>
      {type && (
        <Grid container item xs={12} columnSpacing={0.5}>
          <Grid item xs='auto'>
            <div className={styles.description} style={{ fontWeight: 600 }}>
              {t('Perfil')}:
            </div>
          </Grid>
          <Grid item xs='auto'>
            <div className={styles.description}>
              {profileMessages[type]}
            </div>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Alert
          content={t('Caso as informações não estejam corretas, verifique o código digitado ou entre em contato com o responsável da sua escola.')}
          severity='info'
          className={styles.alertInfo}
          style={{ marginTop: '12px' }}
          outlined
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={`active ${styles.submitButton}`}
          variant='primary'
          type='submit'
          data-testid='autonomous_registration_school_info_advance'
        >
          {t('Continuar')}
          <ArrowForwardIcon sx={{ marginLeft: '14px' }} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={styles.cancelButton}
          variant='ghost'
          onClick={onCancel}
          type='button'
          data-testid='autonomous_registration_school_info_close'
        >
          <CloseIcon />
          {t('Fechar cadastro autônomo')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ConfirmationSchoolData
