import { Grid, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import useStyles from './style'

interface ICheckSelector {
  isLabelActive: boolean
  isCheckActive: boolean
  label: string
  handleCheck: () => void
  handleLabelClick: () => void
}

export const CheckSelector = ({ isLabelActive, isCheckActive, label, handleCheck, handleLabelClick }: ICheckSelector) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Grid className={classes.checkContainer}>
      <div onClick={handleCheck} className={`${classes.circularCheck} ${classes.circular} ${isLabelActive && 'active'} ${isCheckActive && 'checked'}`}>
        {isCheckActive && <CheckIcon sx={{ fontSize: '18px', color: theme.colors.neutral.lightBase }} />}
      </div>
      <div onClick={handleLabelClick} className={`${classes.labelContainer} ${classes.circular} ${isLabelActive && 'active'} ${isCheckActive && 'checked'}`}>
        {label}
      </div>
    </Grid>
  )
}
