import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import SearchIcon from '@mui/icons-material/Search'
import {
  contentUnitCardAtom,
  expeditionModalSelectorAtom,
  scheduleAtom,
  updateContentSelectAtom
} from '../atomStore'
import useStyles from './style'
import { useAtom } from 'jotai'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Button, Tag } from 'components/design-system'
import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { IDisciplineResponse, IUserSchoolProfileTypeEnum, IMediaResponse, IDidacticContentResponse } from 'services/types'
import { getContentUnitSchedule } from 'services/schedule'
import { NavLink, useParams } from 'react-router-dom'
import { LoadingWrapper } from 'components/common'
import {
  CONTENT_UNIT_STUDENT,
  CONTENT_UNIT_TEACHER
} from 'navigation/CONSTANTS'
import { useStore, useCurricularStore } from 'store'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { IContentUnit, IDisciplinesResponse } from 'services/types/schedule'
import BackToTop from 'components/common/BackToTop'
import { IGradeResponse } from 'services/types/taught-disciplines'
interface IEventCreatorProps {
  discipline?: IDisciplineResponse[]
}

export const ExpeditionModal = ({ discipline }: IEventCreatorProps) => {
  //  atoms
  const [expeditionModal, setExpeditionModalSelector] = useAtom(
    expeditionModalSelectorAtom
  )
  const [, setContentUnitCard] = useAtom(contentUnitCardAtom)
  const [schedule, setSchedule] = useAtom(scheduleAtom)
  const [, updateContentSelect] = useAtom(updateContentSelectAtom)
  //  state
  const [selectedDisciplineId, setSelectedDisciplineId] = useState<string | null>(discipline ? discipline[0]?.id?.toString() : '')
  const [filteredSearchContents, setFilteredSearchContents] = useState<IContentUnit[] | [] | null>([])
  const [searchTitle, setSearchTitle] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [contents, setContents] = useState<IContentUnit[] | [] | null>([])
  const handleClose = () => setExpeditionModalSelector(false)
  const { currentDiscipline } = useCurricularStore()

  // profile
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()

  const theme = useTheme()

  const modalId = 'modalId'

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const contentLink = (id: number) => {
    if (profileType === IUserSchoolProfileTypeEnum.teacher) {
      return CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, id?.toString())
    } else {
      return CONTENT_UNIT_STUDENT(id?.toString())
    }
  }

  const setEventContentUnit = (
    contenUnit: number,
    title: string,
    cover: IMediaResponse,
    disciplines: IDisciplinesResponse[],
    coverMobile?: IMediaResponse,
    suggestedPosition?: number,
    grade?: IGradeResponse,
    didactic_content?: IDidacticContentResponse
  ) => {
    setContentUnitCard({
      contenUnit,
      title,
      cover,
      disciplines,
      suggested_position: suggestedPosition,
      coverMobile: coverMobile as IMediaResponse,
      grade,
      didactic_content
    })
    setExpeditionModalSelector(false)
  }

  const populateSearch = async (currentDisciplineId: string) => {
    setLoading(true)
    try {
      const response = await getContentUnitSchedule(
        classId,
        currentDisciplineId ?? ''
      )

      if (response.success) {
        setContents(response.data)
      } else {
        setContents([])
      }
    } catch (ex) {
      console.log('error')
    }
    setLoading(false)
  }

  let filteredContents: IContentUnit[] | [] | null = []
  const filterSearchContents = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTitle(e.target.value)
    filteredContents = []
    if (contents?.length) {
      filteredContents = contents.filter((c) =>
        c.title.toLowerCase().includes(e.target.value.toLowerCase())
      )
      setFilteredSearchContents(filteredContents)
    }
  }

  const clearSearchField = () => {
    setSearchTitle('')
  }

  const updateContent = (
    contentUnit: number,
    title: string,
    expeditionDisciplines: IDisciplinesResponse[],
    cover: IMediaResponse,
    coverMobile?: IMediaResponse,
    suggestedPosition?: number,
    grade?: IGradeResponse,
    didactic_content?: IDidacticContentResponse
  ) => {
    setEventContentUnit(contentUnit, title, cover, expeditionDisciplines, coverMobile, suggestedPosition, grade, didactic_content)
    setSchedule({
      ...schedule,
      content_unit: contentUnit,
      disciplineId: Number(selectedDisciplineId),
      didatic_section: null,
      activity: null
    })
    updateContentSelect(null)
  }

  useEffect(() => {
    if (currentDiscipline?.id) {
      setSelectedDisciplineId(currentDiscipline?.id?.toString())
    }
  }, [currentDiscipline])

  useEffect(() => {
    setFilteredSearchContents(null)
    if (selectedDisciplineId && expeditionModal) {
      void clearSearchField()
      void populateSearch(selectedDisciplineId)
    }
  }, [expeditionModal, selectedDisciplineId])

  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={expeditionModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        sx={{ zIndex: 2000 }}
      >
        <Fade in={expeditionModal}>
          <Box className={classes.modalWrapper}>
            <Grid container className={classes.selector}>
              <div className={classes.selectorTitle} data-testid='event_with_expedition_link_an_expedition' onClick={() => isMobile && setExpeditionModalSelector(false)}>
                {
                  isMobile
                    ? <KeyboardArrowLeftIcon style={{ fontSize: '28px' }} />
                    : ''
                }
                {t('Vincular Conteúdo')}
              </div>
              <div className={classes.inputWrapper}>
                <span className={classes.iconWrapper}>
                  <SearchIcon style={{ fontSize: '32px' }} />
                </span>
                <input
                  id='searchField'
                  onChange={filterSearchContents}
                  value={searchTitle}
                  className={classes.input}
                  placeholder='Pesquisar'
                />
              </div>
              <span className={classes.selectorSubtitle}>
                {t('Filtrar por componente')}
              </span>
              <Grid
                item
                sx={{
                  display: 'flex',
                  gap: '16px',
                  flexWrap: 'wrap'
                }}
                data-testid='link_expedition_to_event_filter_by_curriculum_component'
              >
                {discipline?.length
                  ? discipline.map((item) => (
                    <Tag
                      key={item.id}
                      disabled={contents === null || loading}
                      variant={
                        selectedDisciplineId === item.id?.toString()
                          ? item.code
                          : undefined
                      }
                      onClick={() =>
                        setSelectedDisciplineId(item.id?.toString())
                      }
                    >
                      {t(item.name)}
                    </Tag>
                  ))
                  : t('Não foi encontrado o programa para a turma')}
              </Grid>
            </Grid>
            <Grid item sx={{
              height: '100%'
            }}>
              <Grid id={modalId} container className={classes.contentWrapper}>
                <Grid className={classes.content}>
                  <LoadingWrapper loading={loading}>
                    {filteredSearchContents
                      ? filteredSearchContents?.filter(c => (c.disciplines as IDisciplinesResponse[]).length > 0).map((c) => (
                        <Box
                          key={c.id}
                          component='div'
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: 'auto auto auto',
                            width: '100%',
                            height: 'max-content',
                            [theme.breakpoints.down('sm')]: {
                              display: 'contents'
                            }
                          }}
                        >
                          <div className={classes.contentTitle}>{c.title}</div>
                          <div className={classes.buttonsWrapper}>
                            <Button variant='ghost'>
                              <NavLink
                                className={classes.button}
                                style={{ textDecoration: 'none', color: 'unset', padding: '0px 0px' }}
                                target='_blank'
                                to={() => contentLink(c.id)}
                              >
                                {t('Abrir em nova aba')}
                              </NavLink>
                            </Button>
                            <Button
                              onClick={() => {
                                updateContent(
                                  c.id,
                                  c.title,
                                  c.disciplines as IDisciplinesResponse[],
                                  c.cover as IMediaResponse,
                                  c.cover_mobile as IMediaResponse,
                                  c?.suggested_position,
                                  c?.grade,
                                  c?.didactic_content
                                )
                              }}
                              startIcon={<AddIcon />}
                              variant='ghost'
                            >
                              {t('Vincular')}
                            </Button>
                          </div>
                        </Box>
                      ))
                      : contents?.filter(c => (c.disciplines as IDisciplinesResponse[]).length > 0).map((c) => (
                        <Box
                          key={c.id}
                          component='div'
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: 'auto auto auto',
                            width: '100%',
                            height: 'max-content',
                            [theme.breakpoints.down('sm')]: {
                              display: 'contents'
                            }
                          }}
                        >
                          <div className={classes.contentTitle}>
                            {c.title}
                          </div>
                          <div className={classes.buttonsWrapper}>
                            <Button variant='ghost'>
                              <NavLink
                                className={classes.button}
                                style={{ textDecoration: 'none', color: 'unset', padding: '0px 0px' }}
                                target='_blank'
                                data-testid='link_expedition_to_event_open_expedition_in_new_tab'
                                to={() => contentLink(c.id)}
                              >
                                {t('Abrir em nova aba')}
                              </NavLink>
                            </Button>
                            <Button
                              onClick={() => {
                                updateContent(
                                  c.id,
                                  c.title,
                                  c.disciplines as IDisciplinesResponse[],
                                  c.cover as IMediaResponse,
                                  c.cover_mobile as IMediaResponse,
                                  c?.suggested_position,
                                  c?.grade,
                                  c?.didactic_content
                                )
                              }}
                              startIcon={<AddIcon />}
                              variant='ghost'
                              data-testid='link_expedition_to_event_link_expedition_to_event'
                            >
                              {t('Vincular')}
                            </Button>
                          </div>
                        </Box>
                      ))}
                  </LoadingWrapper>
                </Grid>
              </Grid>
            </Grid>
            <BackToTop e={modalId} staryHeight='-47%' left={100} />
          </Box>
        </Fade>
      </Modal>
      <div>
      </div>
    </div>
  )
}
