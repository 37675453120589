import { IQuestionResponse } from 'services/types'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import InputTypeComponent from '../InputTypeComponent'
import { ExpeditionQuestionNavigation } from '../ExpeditionQuestionNavigation'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'
import SendAnswerButton from 'pages/ContentUnit/components/Activity/common/SendAnswerButton'

interface IQuestionsProps {
  question: IQuestionResponse
  index: number
}

export const StudentQuestionView: React.FC<IQuestionsProps> = ({ question, index }) => {
  const { isExpeditionView } = useStudentAnswer()
  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          {InputTypeComponent({ question })}
        </Grid>
        {isExpeditionView && <SendAnswerButton />}
        {isExpeditionView && <ExpeditionQuestionNavigation index={index} />}
      </Grid>
      {isExpeditionView && <Divider sx={{ margin: '32px 0 24px' }} />}
    </>
  )
}

export default StudentQuestionView
