import { IApiResponse, IUserLoginResponse } from 'services/types'
import { request } from 'services/base'
import { LOGIN_WITH_JWT } from './CONSTANTS'

export const loginWithJwt = async (token: string): Promise<IApiResponse<IUserLoginResponse>> => await request({
  url: LOGIN_WITH_JWT(),
  method: 'POST',
  data: { token },
  disableToast: true
})
