import React, { useEffect, useRef, useState } from 'react'
import { Brand, Avatar } from 'components/design-system'
import * as SvgIcon from '@mui/icons-material'
import useStyle from './style'
import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useAtom } from 'jotai'
import { activeMenuSidebarAtom, isBlockedNavigationAtom, activeHeaderConfigsAtom, activeModalVinculateAtom, hideMainNavigationAtom } from '../../atomStore'
import { useAuth } from 'navigation/ProvideAuth'
import { useStore } from 'store'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { VinculateModal } from './VinculateModal'
import { ProfileManagerMenu } from './components/ProfileManagerMenu'
import { IUserSchoolProfileTypeEnum } from 'services/types'

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const Header: React.FC = () => {
  const [activeModalVinculate, setActiveModalVinculate] = useAtom(activeModalVinculateAtom)
  const [activeMenuSidebar, setActiveMenuSidebar] = useAtom(activeMenuSidebarAtom)
  const [isBlockedNavigation] = useAtom(isBlockedNavigationAtom)
  const [activeHeaderConfigs, setActiveHeaderConfigs] = useAtom(activeHeaderConfigsAtom)
  const [hideMainNavigation] = useAtom(hideMainNavigationAtom)
  const auth = useAuth()
  const [visible, setVisible] = useState<boolean>(true)
  const [pageYOffset, setPageYOffset] = useState<number>(0)
  const previousPageYOffset = usePrevious(pageYOffset) as number
  const classes = useStyle()
  const theme = useTheme<Theme>()
  const { session, subscription } = useStore()
  const { t } = useTranslation()

  const handleScroll = () => {
    const isScrollToDown = pageYOffset > previousPageYOffset

    if (!isScrollToDown !== visible) {
      setVisible(!isScrollToDown)
    }
  }

  if (document.body.clientWidth < theme.breakpoints.values.sm) {
    document.removeEventListener('scroll', () => setPageYOffset(window.pageYOffset))
    document.addEventListener('scroll', () => setPageYOffset(window.pageYOffset))
  }

  useEffect(() => {
    if (!hideMainNavigation) {
      handleScroll()
    }
  }, [pageYOffset, subscription])

  useEffect(() => {
    setVisible(!hideMainNavigation)
  }, [hideMainNavigation])

  const exit = async () => {
    try {
      const lastUsedProfileIsStudent = !!session?.profiles[0].lastUsed && session?.profiles[0].type === IUserSchoolProfileTypeEnum.student
      const nonStudentProfile = session?.profiles.find(p => p.type !== IUserSchoolProfileTypeEnum.student)
      if (lastUsedProfileIsStudent && nonStudentProfile) {
        await auth?.updateLastUsedProfile(nonStudentProfile.id)
      }
    } catch (err: any) {
      console.log(err?.message ?? '')
    }
    auth?.signout(true)
  }

  const showVinculateModal = () => {
    setActiveModalVinculate(!activeModalVinculate)
  }

  return (
    <header id='ui-header' className={`${classes.header} ${!visible && 'hidden'}`} data-testid='main_menu_home' >
      <section className={classes.headerWrapper} >
        <Grid item className={classes.headerContent}>
          {isBlockedNavigation
            ? ''
            : <div className={classes.iconMenuContainer} onClick={() => setActiveMenuSidebar(!activeMenuSidebar)}>
              <SvgIcon.Menu className={classes.iconMenuSvg} />
            </div>
          }
          <NavLink to={isBlockedNavigation ? '#' : '/'} className={classes.brandHeaderContainer}>
            <Brand type='logo' />
          </NavLink>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item className={classes.userLinkProfile} >
            <a onClick={() => showVinculateModal()}
              data-testid='menu_my_profile_link_profile_class_school'
            >
              <SvgIcon.AddLink className={classes.iconSvg} />
            </a>
          </Grid>
          <VinculateModal />
          <Grid item className={classes.classContainer}>
            <ProfileManagerMenu />
          </Grid>
          <Grid item className={classes.configsContainer}>
            <Grid item className={classes.avatar} onClick={() => setActiveHeaderConfigs(!activeHeaderConfigs)}>
              <Avatar
                userName={auth?.user?.username as string}
                className='avatarItem'
                size='medium'
              />
            </Grid>
            <Grid item className={`${classes.userInfo} ${activeHeaderConfigs && 'opened'}`}>
              <Grid item className={classes.userInfoName}>
                {t('Olá Navegante!')}
              </Grid>
              <Grid
                item
                className={classes.userInfoLogout}
                onClick={async () => await exit()}
                data-testid='menu_my_profile_leave_the_platform'
              >
                {t('Sair')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </header >
  )
}
