import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    tagReleased: {
      backgroundColor: theme.colors.positive.feedbackLightest,
      '& > .MuiSvgIcon-root': {
        backgroundColor: theme.colors.positive.feedbackDark,
        color: theme.colors.neutral.lightBase,
        borderRadius: theme.borderRadius.circular,
        verticalAlign: '-4px',
        fontSize: 20,
        marginRight: 4,
        padding: 4
      }
    }
  })
)
