import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  iFrameContainer: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: '56.25%',
    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%'
    }
  },
  errorMessage: {
    textAlign: 'center',
    width: '100%'
  }
}))
