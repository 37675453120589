import { Skeleton, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({

  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano
  }
}))

export const Idle: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <Skeleton className={`${classes.tag}`} animation='wave' width={500} height={50} />
    </>
  )
}
