import { AccountCircleOutlined } from '@mui/icons-material'
import { Loading } from 'components/design-system'
import { IClassResponse, IScheduleSubscriptionsResponse } from 'services/types'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { truncateExtension } from 'utils/string'

interface ISubscriptionInfo {
  class: IClassResponse
  subscriptions?: IScheduleSubscriptionsResponse[]
}

type StatesType = 'idle' | 'class' | 'subscriptions'

export const SubscriptionInfo: React.FC<ISubscriptionInfo> = props => {
  const { subscriptions } = props
  const classes = useStyles()
  const { t } = useTranslation()

  let currentState: StatesType = 'idle'

  if (subscriptions?.length) {
    currentState = 'subscriptions'
  } else {
    currentState = 'class'
  }

  const renderClass = (props: ISubscriptionInfo) => {
    return (
      <>
        <AccountCircleOutlined />
        <span>{truncateExtension(props?.class?.name ?? '', 30)}</span>
      </>
    )
  }

  const renderSubscriptions = (props: ISubscriptionInfo) => {
    const count = subscriptions?.length ?? 0
    return (
      <>
        <AccountCircleOutlined />
        <span>{`${count} ${count > 1 ? t('estudantes') : t('estudante')}`}</span>
      </>
    )
  }

  const render = {
    idle: (props: ISubscriptionInfo) => <Loading type='linear' />,
    class: renderClass,
    subscriptions: renderSubscriptions
  }

  return <div className={classes.container}>{currentState in render ? render[currentState](props) : ''}</div>
}
