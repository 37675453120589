import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import emptyStateTeacherConfigSchool from 'assets/empty-state-teacher-config-school.svg'

export default makeStyles((theme: Theme) =>
  createStyles({
    containerEmptyState: {
      background: theme.colors.neutral.lightBase,
      borderRadius: theme.borderRadius.md,
      boxShadow: theme.shadowLevels.level5,
      marginInline: 'auto',
      marginBlock: theme.spacingStack.lg,
      padding: theme.spacingInset.sm,
      // mobile
      [theme.breakpoints.down('sm')]: {
        marginBlock: 'unset',
        boxShadow: 'unset',
        padding: 'unset',
        paddingTop: '64px' // header height
      }
    },

    boxTitles: {
      padding: '16px'
    },

    emptyStateTitle: {
      fontSize: theme.font.fontSize.xl,
      fontWeight: theme.font.fontWeight.medium,
      color: theme.colorBrand.medium,
      textAlign: 'center',
      maxWidth: '620px',
      margin: '0 auto'
    },
    emptyStateSubTitle: {
      fontSize: theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.medium,
      color: theme.colors.neutral.dark30,
      textAlign: 'center',
      paddingTop: theme.spacingInset.xs,
      maxWidth: '495px',
      margin: '0 auto',
      // mobile
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacingInset.sm,
        fontWeight: theme.font.fontWeight.regular
      }
    },
    imageEmptyState: {
      backgroundImage: `url(${emptyStateTeacherConfigSchool})`,
      backgroundSize: '100%',
      maxWidth: '20rem',
      height: '20rem',
      width: '100%',
      maxHeight: '30rem',
      margin: 'auto',
      minHeight: 'auto',
      backgroundRepeat: 'no-repeat',
      marginTop: theme.spacingStack.xs
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacingInset.sm
      }
    }
  })
)
