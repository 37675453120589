import { useTranslation } from 'react-i18next'

import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { useTheme } from '@mui/material'

import { IRecordTimelineResponse } from 'services/types'

import { parseKaTex } from 'utils/katex'

import useStyles from './style'
export interface IAlternativeProps {
  alternative: IRecordTimelineResponse
}

export const Alternative: React.FC<IAlternativeProps> = ({ alternative }) => {
  const classes = useStyles({ alternative })
  const { t } = useTranslation()
  const alternativeParsed = parseKaTex(alternative.choiceAlternative) ?? ''
  const questionParsed = parseKaTex(alternative.questionText) ?? ''
  const matchLink = questionParsed.match(/\[\[(.*?)\]/)?.[1]
  const theme = useTheme()

  return (
    <div>
      <div className={classes.questionText}>
        <div dangerouslySetInnerHTML={{ __html: questionParsed }} />
        <img src={matchLink} className={classes.image} />
      </div>
      <FormControlLabel
        value={alternative.id}
        className={classes.question}
        sx={{
          marginLeft: 0,
          width: '100%',
          '&:checked': {
            backgroundColor: 'pink'
          }
        }}
        control={
          <Radio
            checked={true}
            sx={{
              color: classes.colorButtonDefault,
              '&.Mui-checked': {
                color: theme.colorBrand.medium
              }
            }}
          />
        }
        label={
          <div className={classes.alternativeText}>
            <div dangerouslySetInnerHTML={{ __html: alternativeParsed }} />
            {alternative.alternativaResources &&
              <img src={alternative.alternativaResources[0].link} className={classes.image} />
            }
            <div className={classes.correctText}>
              {alternative.levelAlternative === 100 ? `${t('Resposta correta')}` : ''}
            </div>
          </div>
        }
      />
    </div>
  )
}
