import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  indexContainer: {
    backgroundColor: theme.colors.bgCurricularComponents.DEFAULT,
    padding: theme.spacingInline.xxxs,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInline.nano
    }
  },
  indexContent: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
      paddingLeft: theme.spacingInline.xxxs,
      paddingRight: theme.spacingInline.xxxs
    }
  },
  indexOption: {
    cursor: 'pointer',
    color: theme.colorBrand.darkest,
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacingInline.xxxs,
    borderRadius: 8,
    gap: '1rem',
    fontWeight: theme.font.fontWeight.semibold,

    '&.active': {
      backgroundColor: 'rgba(144, 121, 179, 0.16)'
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInline.nano,

      '& span': {
        display: 'none'
      },

      '&.active': {
        '& span': {
          display: 'initial'
        }
      }
    }
  },
  indexPaginationContent: {
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: 8,
    width: '12%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  indexPaginationIcon: {
    // backgroundColor: 'red',
    cursor: 'pointer',
    width: '40%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.withColor': {
      '& svg': {
        fill: theme.colorBrand.medium
      }
    },

    '&.disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  },
  indexBar: {
    width: '1px',
    height: '40%',
    backgroundColor: theme.colors.neutral.light10
  }
}))
