
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  inverted: {
    '& svg': {
      transform: 'scaleX(-1)'
    }
  },
  situationContainer: {
    display: 'flex',
    gap: theme.spacingInset.xxs,
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'normal',
      flex: 'initial',
      width: '8.5rem !important'
    }
  },
  situationText: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold
  }
}))
