import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingInline: theme.spacingInline.xxxs,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      paddingInline: '0px'
    }
  },
  classContainer: {
    display: 'flex',
    paddingInline: theme.spacingInline.xxxs,
    borderRight: `1px solid ${theme.colors.neutral.light10}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      paddingInline: '0px'
    }
  },
  classContainerTitles: {
    alignSelf: 'center',
    paddingInline: theme.spacingInline.nano,
    [theme.breakpoints.down('sm')]: {
      paddingInline: '0px'
    }
  },
  classInforamtionTitle: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  classInforamtionText: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colors.neutral.dark30,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  inforamtionType: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.xs,
    overflow: 'hidden',
    color: theme.colors.neutral.dark30,
    transition: 'all ease-in-out .2s'
  },
  classChange: {
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    paddingInline: theme.spacingStack.nano,
    [theme.breakpoints.down('sm')]: {
      paddingInline: 'none'
    }
  },
  iconChangeClass: {
    display: 'flex',
    cursor: 'pointer',
    padding: theme.spacingInset.xxs,
    [theme.breakpoints.down('sm')]: {
      paddingBlock: '0px',
      paddingInline: '0px'
    }
  },
  iconSvg: {
    fontSize: theme.font.fontSize.lg,
    color: theme.colorBrand.medium,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.lg
    }
  },
  classChangeTitle: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.xs,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  avatar: {
    paddingLeft: theme.spacingInline.xxxs,
    paddingRight: theme.spacingInline.nano,
    [theme.breakpoints.down('sm')]: {
      paddingInline: theme.spacingInline.nano
    }
  },
  userInfo: {
    maxWidth: 0,
    maxHeight: '40px',
    overflow: 'hidden',
    transition: 'all ease-in-out .2s',
    '&.opened': {
      paddingRight: theme.spacingInline.nano,
      maxWidth: '300px'
    }
  },
  userInfoName: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.xs,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colors.neutral.dark40,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  userInfoLogout: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: '100%',
    color: theme.colorBrand.medium,
    cursor: 'pointer'
  }
}))
