import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  icon: {
    backgroundColor: `${theme.colorBrand.medium}!important`,
    height: '32px!important',
    width: '32px!important'
  }
}))
