import React from 'react'
import { Grid, Skeleton } from '@mui/material'

export const ReportsListHeaderLoading: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton variant='text' width={354.13} height={60} animation='wave' />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text' height={100} animation='wave' />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant='text' width={102.05} height={60} animation='wave' />
      </Grid>
      <Grid container item xs={12} columnSpacing={3}>
        <Grid item xs={12} md={9}>
          <Skeleton animation='wave' height={80} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton animation='wave' height={80} />
        </Grid>
      </Grid>
    </Grid>
  )
}
