import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    boxShadow: theme.shadowLevels.level5,
    height: 'auto',
    padding: `${theme.spacingStack.xs} ${theme.spacingStack.xxs}`,

    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      paddingTop: '64px'
    }
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium,
    cursor: 'pointer',
    padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.nano}`,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: '18px',
    // larger screens
    [theme.breakpoints.up('sm')]: {
      padding: 0
    },
    '& svg': {
      marginRight: theme.spacingInline.nano
    }
  },
  backButton: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colorBrand.medium
  },
  headerContainer: {
    alignItems: 'center',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    '&.containerAcordeon': {
      borderBlock: 'none'
    }
  },
  titleAccordion: {
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark30,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm
    }
  },
  subtitleAccordion: {
    alignItems: 'center',
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    color: theme.colors.neutral.dark30,
    lineHeight: theme.font.lineHeight.sm
  },
  stackContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacingStack.xxxs,
    [theme.breakpoints.up('sm')]: {
      width: 'initial',
      flexDirection: 'row !important',
      justifyContent: 'flex-start',
      marginRight: theme.spacingStack.xxxs
    }
  },
  textField: {
    paddingTop: theme.spacingStack.xs,
    '& div': { width: '100%' },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacingStack.xxs
    }
  },
  helpTextTitle: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.xxs,
    color: theme.colors.neutral.dark30,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 0,
    '&.limit': {
      color: theme.colors.negative.feedbackDark
    }
  },
  helpTextDescription: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.xxs,
    color: theme.colors.neutral.dark30,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 0,
    '&.limit': {
      color: theme.colors.negative.feedbackDark
    }
  },
  text: {
    color: theme.colors.neutral.dark30,
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.xs,
    paddingTop: 0,
    marginTop: 20,
    marginBottom: 0
  },
  classButton: {
    alignItems: 'center',
    display: 'flex',
    paddingBlock: theme.spacingStack.xs,
    width: '100%',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      paddingBlock: theme.spacingStack.nano,
      justifyContent: 'space-around'
    }
  },
  disciplineContainer: {
    padding: `${theme.spacingInset.xxs} 0px ${theme.spacingInset.xxs} 0px`
  }
}))
