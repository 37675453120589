import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    outline: 'none',
    position: 'absolute',
    top: '38%',
    minHeight: '240px',
    maxHeight: 'calc(100vh - 140px)',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInline.xxs,

    width: '90%',
    maxWidth: '90%',
    transform: 'translate(5%, -40%)',
    left: '0',

    [theme.breakpoints.up('sm')]: {
      width: '55%',
      maxWidth: '55%',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      padding: theme.spacingInline.sm
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%',
      maxWidth: '90%',
      transform: 'translate(-50%, -50%)',
      left: '50%'
    }
  },
  txtTitle: {
    fontSize: theme.font.fontSize.xl,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.dark,
    textAlign: 'center',
    marginBottom: theme.spacingStack.xxs,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.md
    }
  },
  txtDescription: {
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark30,
    textAlign: 'center',
    marginBottom: theme.spacingStack.xs,
    marginInline: theme.spacingInline.xl,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xs,
      marginInline: 0
    }
  }
}))
