import { useRef, useEffect } from 'react'

export const useClickOutside = (callBack: () => void): React.MutableRefObject<HTMLElement | undefined> => {
  const componentNode = useRef<HTMLElement>()

  useEffect(() => {
    const onMouseDown = (event: MouseEvent) => {
      if (componentNode?.current && !componentNode.current.contains(event.target as HTMLElement)) {
        callBack()
      }
    }

    document.addEventListener('mousedown', onMouseDown)

    return () => {
      document.removeEventListener('mousedown', onMouseDown)
    }
  }, [componentNode])

  return componentNode
}
