import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useCurricularStore, useStore } from 'store'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { Loading } from 'components/design-system'
import { Disciplines } from 'components/store/Disciplines'

import { BusinessError } from 'navigation/BusinessError'
import Ready from './components/Ready'

import { StudentPendingActivitiesAtom } from './atomStore'
import PendingActivities from '../../PendingActivities'

type state = 'idle' | 'loading' | 'ready' | 'pendingActivities' | 'error'

export const DashboardStudent: React.FC = () => {
  const [localState, setLocalState] = useState<state>('idle')
  const { currentDiscipline, disciplines, loadingDisciplines } = useCurricularStore()
  const { subscription } = useStore()
  const [studentPendingActivitiesAtom, setStudentPendingActivitiesAtom] = useAtom(StudentPendingActivitiesAtom)

  const { t } = useTranslation()

  if (!subscription) {
    return <BusinessError error={t('Erro ao selecionar turma')} />
  }

  useEffect(() => {
    setLocalState('loading')
    setStudentPendingActivitiesAtom(null)
  }, [])

  useEffect(() => {
    setLocalState('loading')

    if (currentDiscipline && disciplines?.length && !loadingDisciplines) {
      if (localState === 'pendingActivities') {
        setLocalState('pendingActivities')
      } else {
        setLocalState('ready')
      }
    }
  }, [currentDiscipline, disciplines, loadingDisciplines])

  useEffect(() => {
    if (studentPendingActivitiesAtom) {
      setLocalState(studentPendingActivitiesAtom)
    }
  }, [studentPendingActivitiesAtom])

  const states = {
    idle: '',
    loading: <Loading type='linear' />,
    ready: <Ready />,
    pendingActivities: <PendingActivities />,
    error: 'error...'
  }

  const render = () => {
    if (localState in states) {
      return states[localState]
    }
    return states.error
  }

  return (
    <Grid container padding={3}>
      <Disciplines showAll />
      {render()}
    </Grid>
  )
}

export default DashboardStudent
