import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  pill: {
    borderRadius: `${theme.borderRadius.pill}!important`
  },
  rounded: {
    borderRadius: `${theme.borderRadius.md}!important`
  },
  skeletonAccordion: {
    backgroundColor: `${theme.colors.neutral.light10}52`,
    borderRadius: theme.borderRadius.sm,
    padding: '24px 36px',
    width: '100%'
  }
}))
