/* eslint-disable @typescript-eslint/naming-convention */
import { AnswerCacheTable } from 'contentCacheManager/localDatabaseManager'
import { IAnswersRequest } from 'services/types'

export const convertOfflineAnswers = (answersOffline: AnswerCacheTable): IAnswersRequest[] => answersOffline.requestAnswer.answers.map(answer => {
  const fileRecords = answersOffline.filesOffline.find(({ question_id }) => question_id === Number(answer.question_id))?.files

  const convertedFileRecords = fileRecords?.map(({ file: fileBlob }) => {
    const urlCreator = window.URL || window.webkitURL
    const fileUrl = urlCreator.createObjectURL(fileBlob)

    return {
      upload_id: null,
      url: fileUrl
    }
  })

  const records = answer.records.map(record => ({
    ...record,
    file_records: convertedFileRecords
  }))

  return {
    ...answer,
    records
  }
})
