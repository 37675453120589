import { useTranslation } from 'react-i18next'
import { Alert } from 'components/design-system'
import OnlyOffline from 'contentCacheManager/components/OnlyOffline'

export const OfflineActivityWarning = () => {
  const { t } = useTranslation()

  return (
    <OnlyOffline forceMobile>
      <Alert title={t('Modo offline')} content={t('As questões respondidas ficarão salvas no seu dispositivo até que sua conexão volte e você possa enviá-las.')} severity='info' />
    </OnlyOffline>
  )
}
