import { Theme, CircularProgress, LinearProgress, useTheme } from '@mui/material'
import { linearProgressClasses } from '@mui/material/LinearProgress'
import { createStyles, makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'

const useStyle = makeStyles((theme: Theme) => createStyles({
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  root: {
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.colorBrand.medium
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.colorBrand.lightest
    }
  }
}))

export interface ILoadingProps {
  type?: 'circular' | 'linear'
}

export const Loading: React.FC<ILoadingProps> = ({ type = 'circular' }) => {
  const theme = useTheme()
  const classes = useStyle()
  return (
    type === 'circular'
      ? <div id='ui-ds-loading_circular' className={classes.loadingWrapper}>
        <CircularProgress sx={{ color: theme.colorBrand.medium }} />
      </div>
      : <Box id='ui-ds-loading_linear-progress' sx={{ width: '100%' }}>
        <LinearProgress className={classes.root} />
      </Box>
  )
}

export default Loading
