import { LOGIN } from 'navigation/CONSTANTS'
import { Button, Text2 } from 'components/design-system'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStyles from './style'

export const NoEnabledSchoolFound = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <div className={classes.containerNoSchoolPeriod}>
        <Text2 className={classes.title} style={{ paddingBottom: '30px' }} fontSize={'xl'} fontWeight={'medium'} lineHeight={'xxs'} mobile={'lg'} colorbrand='medium'>{t('Seu acesso à Cloe foi bloqueado.')}</Text2>
        <Text2 className={classes.title} fontSize={'lg'} fontWeight={'medium'} lineHeight={'xxs'} mobile={'sm'} neutral='dark30'>{t('Por favor entre em contato com a gestão da escola.')}</Text2>
        <div className={classes.image404} />
        <Button onClick={() => history.push(LOGIN)}>
          {t('Voltar para o login')}
        </Button>
      </div>
    </>
  )
}
