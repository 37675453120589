import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { studentLevelsAtom } from '../../../../../atomStore'
import { LoadingWrapper } from 'components/common'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import {
  IActivityResponse,
  ActivityControlStatusEnum,
  IStudentsLevel,
  IUserSchoolProfileTypeEnum
} from 'services/types'

export interface IAverageEvaluation {
  activity: IActivityResponse | undefined
  variant?: 'primary' | 'outlined'
  text?: string | React.ReactNode
  loadingState?: boolean
  profile: IUserSchoolProfileTypeEnum
  flexStart?: boolean
}

export const AverageEvaluation: React.FC<IAverageEvaluation> = (props) => {
  const {
    activity,
    variant = 'primary',
    text,
    loadingState,
    profile
  } = props
  const [studentLevels] = useAtom(studentLevelsAtom)
  const [average, setAverage] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { t } = useTranslation()
  const classes = useStyles(props)

  // TODO: a regra de quando vem a nota para o aluno seria melhor estar no backend ?
  const showAverageStatus = (activityStatus: ActivityControlStatusEnum, profile: IUserSchoolProfileTypeEnum) => {
    if (activityStatus === ActivityControlStatusEnum.AVAILABLE ||
    activityStatus === ActivityControlStatusEnum.PENDING ||
    activityStatus === ActivityControlStatusEnum.DONE) {
      return true
    }
    if (
      profile === IUserSchoolProfileTypeEnum.student &&
      (activityStatus === ActivityControlStatusEnum.CLOSED ||
        activityStatus === ActivityControlStatusEnum.CORRECTED)
    ) {
      return true
    }
    return false
  }

  const roundCloe = (n: number) => {
    const lastNumber = Number(String(n)[String(n).length - 1])
    const exponent = -1
    let type: 'floor' | 'round' = 'floor'
    if (lastNumber > 5) {
      type = 'round'
    }
    let value: any = n?.toString().split('e')
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exponent) : -exponent)))
    // Shift back
    value = value?.toString().split('e')
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exponent) : exponent))
  }

  const init = async (studentLevels: IStudentsLevel[]) => {
    const levelsValue = studentLevels.reduce((acc: number[], studentLevel) => {
      if (studentLevel?.level && studentLevel?.level?.value >= 0) {
        acc.push(studentLevel?.level?.value)
      }
      return acc
    }, [])
    // verifica se tem todas as respostas
    if (levelsValue.length === studentLevels.length) {
      const avg = levelsValue.reduce((acc: number, cur: number) => acc + cur, 0)
      const finalAvg = Number((avg / levelsValue.length / 10).toFixed(2))
      setAverage(roundCloe(finalAvg))
    }
  }

  useEffect(() => {
    setLoading(true)
    setAverage(null)
    if (studentLevels?.length) {
      void init(studentLevels)
    }
    setLoading(false)
  }, [studentLevels, loadingState])

  if (!studentLevels || !studentLevels.length) {
    return null
  }

  if (
    average === null ||
    activity?.suggested_application_type !== 'evaluation' ||
    showAverageStatus(activity?.activity_status?.status as ActivityControlStatusEnum, profile)
  ) {
    return null
  }

  return (
    <div className={classes.container}>
      <LoadingWrapper loading={loading}>
        <div className={`${classes.average} ${variant}`}>{average}</div>
        <div className={classes.text}>
          {text ?? (
            <>
              {t('Média da')}
              <br />
              {t('avaliação')}
            </>
          )}
        </div>
      </LoadingWrapper>
    </div>
  )
}

export default AverageEvaluation
