import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'
import { Button, TextField, Loading, Text } from 'components/design-system'
import BaseAuthLayout from 'pages/BaseAuthLayout'
import { useAuth } from 'navigation/ProvideAuth'
import { GO_TO_ACTIVITY, REGISTER, GO_TO_RESOURCE } from 'navigation/CONSTANTS'
import { ILocation } from 'services/types/routes'
import { redirectLinkAtom, windowLoadingAtom } from 'navigation/atomStore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useStyles from './style'
import { NoticeNeedSignInModal } from './components/NoticeNeedSignInModal'
import { useOnlineStatus } from 'contentCacheManager'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid } from '@mui/material'
import { Alert } from 'components/design-system/Alert'
import { ForgotPasswordButton } from 'pages/Login/components/ForgotPasswordButton'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import accessKeyIcon from 'assets/123.svg'
import { CodeInput } from 'components/common'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { toast } from 'components/design-system/Toast/manager'
import { SSOErrorsAtom, authLoadingAtom } from 'pages/atomStore'
import { useAtom } from 'jotai'

export const Login = (): JSX.Element => {
  // routes hooks
  const location = useLocation<ILocation>()
  const { from } = location.state || { from: { pathname: '/' } }
  const history = useHistory()

  // states
  const [expanded, setExpanded] = useState<'login' | 'accessKey'>('login')
  const [isLoading, setIsLoading] = useAtom(authLoadingAtom)

  // login states
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isShowNoticeNeedSignIn, setIsShowNoticeNeedSignIn] = useState(false)
  const [isLoginBtnEnabled, setIsLoginBtnEnabled] = useState(false)
  const [loginAccordionDataTestId, setLoginAccordionDataTestId] = useState<string>('login_retract_login')
  const [accessKeyAccordionDataTestId, setaccessKeyAccordionDataTestId] = useState<string>('login_expand_access_key')
  const [loginBtnDataTestId, setLoginBtnDataTestId] = useState<string>('login_confirm_login')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [warningMessage, setWarningMessage] = useState<string | null>(null)

  // accessKey states
  const [accessKey, setAccessKey] = useState('------')
  const [accessKeyErrorMessage, setAccessKeyErrorMessage] = useState<string | null>(null)

  const auth = useAuth()
  const isOnline = useOnlineStatus()
  const { t } = useTranslation()
  const styles = useStyles()

  // atoms
  const [SSOErrors, setSSOErrors] = useAtom(SSOErrorsAtom)
  const setWindowLoading = useUpdateAtom(windowLoadingAtom)
  const redirectLink = useAtomValue(redirectLinkAtom)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (SSOErrors.length) {
      for (const SSOError of SSOErrors) {
        toast.handler({
          content: SSOError,
          duration: 10000,
          severity: 'error'
        })
      }
      setSSOErrors([])
    }
  }, [SSOErrors])

  const loginExpandIcon = <ExpandMoreIcon fontSize='large' data-testid={loginAccordionDataTestId} />
  const accessKeyExpandIcon = <ExpandMoreIcon fontSize='large' data-testid={accessKeyAccordionDataTestId} />

  const expandAccessKeyAccordion = () => {
    setLoginAccordionDataTestId('login_expand_login')
    setaccessKeyAccordionDataTestId('login_retract_access_key')
    setLoginBtnDataTestId('login_confirm_login_access_key')
    setExpanded('accessKey')
  }

  const expandLoginAccordion = () => {
    setLoginAccordionDataTestId('login_retract_login')
    setaccessKeyAccordionDataTestId('login_expand_access_key')
    setLoginBtnDataTestId('login_confirm_login')
    setExpanded('login')
  }

  const toggleExpandedAccordion = () => {
    if (expanded === 'login') {
      expandAccessKeyAccordion()
    } else {
      expandLoginAccordion()
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLFormElement> | React.MouseEvent<HTMLFormElement, MouseEvent>) => {
    const capsOn = e.getModifierState('CapsLock') && (e.target as any).id === 'password'

    if (capsOn) {
      setErrorMessage(null)
      setWarningMessage(t('O caps lock está ativado.'))
    } else {
      setWarningMessage(null)
    }
  }

  const validateLoginBeforeSubmit = () => {
    if (username.length === 0) {
      setErrorMessage(t('Login incorreto'))
    }

    if (password.length === 0) {
      setErrorMessage(t('Campo obrigatório'))
    }

    if (!isOnline) {
      setErrorMessage(t('Você está sem internet'))
    }
  }

  const loginSubmit = async () => {
    validateLoginBeforeSubmit()
    setIsLoading(true)

    const response = await auth?.signin(username.trim(), password)
    if (!response?.success) {
      setWarningMessage(null)
      setErrorMessage(t('Login ou senha inválidos'))
      setIsLoginBtnEnabled(false)
    }
    setIsLoading(false)
  }

  const accessKeySubmit = async () => {
    if (accessKey.length < 6) {
      setErrorMessage(t('Campo obrigatório'))
    } else {
      if (!isOnline) {
        setErrorMessage(t('Você está sem internet'))
        return
      }
      setIsLoading(true)
      const response = await auth?.temporaryAccess(accessKey)
      if (!response?.success) {
        setAccessKeyErrorMessage(
          t('Chave de acesso inválida')
        )
      }

      setIsLoading(false)
    }
  }

  const onSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()

    if (expanded === 'login') {
      loginSubmit()
    } else {
      accessKeySubmit()
    }
  }

  useEffect(() => {
    setWindowLoading(false)
    if (redirectLink) {
      setIsShowNoticeNeedSignIn(true)
    }
  }, [])

  useEffect(() => {
    const isLoginEnabled = (expanded === 'login' && !!username.length && !!password.length)
    const isAccessKeyEnabled = (expanded === 'accessKey' && accessKey.length === 6 && !accessKey.includes('-'))

    const enableLogin = isLoginEnabled || isAccessKeyEnabled
    setIsLoginBtnEnabled(enableLogin)

    if (expanded !== 'accessKey' || !enableLogin) {
      return
    }
    accessKeySubmit()
  }, [username, password, accessKey, expanded])

  const dataTestIdFromPath: {
    [key: string]: string
  } = {
    [GO_TO_RESOURCE]: 'media_workbook_logged_out_user',
    [GO_TO_ACTIVITY]: 'qr_code_logged_out_user'
  }

  return (
    <BaseAuthLayout>
      <form
        onSubmit={onSubmit}
        tabIndex={0}
        onKeyUp={(e) => handleKeyUp(e)}
        onMouseDown={(e) => handleKeyUp(e)}
      >
        <Grid container spacing={3} sx={{ position: 'relative' }}>
          {isLoading && (
            <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.64)', zIndex: 9999 }}>
              <Loading />
            </Grid>
          )}
          <Grid item xs={12}>
            <Accordion className={styles.accordion} expanded={expanded === 'login'} onChange={toggleExpandedAccordion} data-testid={loginAccordionDataTestId}>
              <AccordionSummary expandIcon={loginExpandIcon} data-testid={loginAccordionDataTestId}>
                <Text type='body' size='medium' className={styles.title}>
                  <AccountCircleOutlinedIcon /> {t('Login')}
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id='username'
                      variant='outlined'
                      label={t('Nome de usuário')}
                      className={styles.input}
                      value={username}
                      error={errorMessage !== null}
                      onChange={(e) => {
                        setUsername(e.target.value)
                        setErrorMessage(null)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='password'
                      variant='outlined'
                      className={styles.input}
                      password
                      label={t('Senha')}
                      error={
                        errorMessage !== null &&
                        warningMessage === null
                      }
                      warning={
                        errorMessage === null &&
                          warningMessage !== null
                          ? true
                          : undefined
                      }
                      assistiveText={warningMessage !== null
                        ? warningMessage
                        : ''
                      }
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                        setErrorMessage(null)
                      }}
                    />
                  </Grid>
                  {errorMessage &&
                    <Grid item xs={12}>
                      <Alert
                        className={styles.alertError}
                        content={t('Usuário ou senha inválidos. Veja “Esqueci a senha” para soluções.')}
                        severity='error'
                      />
                    </Grid>}
                  <Grid item xs={12}>
                    <Button
                      data-testid={loginBtnDataTestId}
                      className={`${styles.submitButton} ${isLoading || !isLoginBtnEnabled ? '' : 'active'}`}
                      variant='primary'
                      disabled={isLoading || !isLoginBtnEnabled}
                      type='submit'
                    >
                      <RocketLaunchOutlinedIcon sx={{ marginRight: '14px' }} /> {t('Entrar')}
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Divider className={styles.divider}>{t('OU')}</Divider>
          </Grid>
          <Grid item xs={12}>
            <Accordion className={styles.accordion} expanded={expanded === 'accessKey'} onChange={toggleExpandedAccordion} data-testid={accessKeyAccordionDataTestId}>
              <AccordionSummary expandIcon={accessKeyExpandIcon} data-testid={accessKeyAccordionDataTestId}>
                <Text type='body' size='medium' className={styles.title}>
                  <img src={accessKeyIcon} className={styles.accessKeyIcon} /> {t('Chave de acesso')}
                </Text>
              </AccordionSummary>
              <AccordionDetails data-testid='accessKeyAccordionDetails'>
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item xs='auto' className={styles.accessKeyContainer}>
                    <CodeInput
                      name='accessKey'
                      type='text'
                      value={accessKey}
                      inputMode='verbatim'
                      autoFocus={false}
                      fields={6}
                      isValid={accessKeyErrorMessage === null}
                      onChange={(value: string) => {
                        setAccessKey(value.trim())
                        setAccessKeyErrorMessage(null)
                      }}
                    />
                  </Grid>
                  {accessKeyErrorMessage && (
                    <Grid item xs={12}>
                      <Alert
                        className={styles.alertError}
                        content={t('Chave inválida. Verifique o código digitado ou veja “Esqueci a senha” para soluções.')}
                        severity='error'
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <ForgotPasswordButton />
          </Grid>
          <Grid container item justifyContent='center' rowSpacing={1} xs={12}>
            <Grid item xs='auto'>
              <Text type='body' size='medium' className={styles.title}>
                {t('Você faz parte da equipe da escola?')}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={styles.autonomousRegistrationButton}
                variant='ghost'
                onClick={() => history.push(REGISTER)}
                type='button'
                data-testid='login_open_autonomous_registration'
              >
                <AdminPanelSettingsOutlinedIcon />
                {t('Cadastro autônomo')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <NoticeNeedSignInModal
        isOpen={isShowNoticeNeedSignIn}
        setIsOpen={setIsShowNoticeNeedSignIn}
        confirmDataTestId={from.pathname in dataTestIdFromPath ? dataTestIdFromPath[from.pathname] : ''}
      />
    </BaseAuthLayout>
  )
}

export default Login
