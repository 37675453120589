import React, { useEffect } from 'react'
import { Modal, Backdrop, Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { CONTENT_UNIT_RESOURCES } from 'navigation/CONSTANTS'

import useStyles from './style'

export const GoToResource: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const query = new URLSearchParams(useLocation().search)
  const resourceIds = query.get('resource_ids') ?? ''
  const contentUnitId = query.get('content_unit_id') ?? ''

  useEffect(() => {
    history.push(CONTENT_UNIT_RESOURCES(contentUnitId, resourceIds))
  }, [])

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Grid
          className={classes.container}
        >
          {t('Redirecionando para recursos')}
        </Grid>
      </Modal>
    </div>
  )
}
