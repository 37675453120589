import { ReactComponent as CloeProNotchClose } from 'assets/cloe-pro-head-iframe-close.svg'
import { ReactComponent as CloeProNotchOpen } from 'assets/cloe-pro-head-iframe-open.svg'
import { CurrentClass, Loading, StatusClassHandle } from 'components/common'
import { useCurricularStore, useStore } from 'store'
import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useToggleOpenMyCloeAtom } from 'navigation/components/MyCloe/atomStore'
import { activeChangeClassAtom, activeModalConfirmationAtom, cloeProURLParamsAtom, hideMainNavigationAtom, isCloeProActiveAtom, selectorClassOpenAtom } from 'navigation/atomStore'
import { toast } from 'components/design-system/Toast/manager'
import { useAtomValue } from 'jotai/utils'
import Analytics from 'utils/analytics'

import useStyle from './style'
import { getCloeProURL } from 'services/cloe-pro/cloeProService'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

export const CloeProChat: React.FC = () => {
  const { currentDiscipline, classId } = useCurricularStore()
  const { profile, showCloePro, subscription } = useStore()
  const { t } = useTranslation()

  const { toggleOpenMyCloeAtom } = useToggleOpenMyCloeAtom()
  const openMyCloe = useAtomValue(toggleOpenMyCloeAtom)
  const activeMenuClass = useAtomValue(activeChangeClassAtom)
  const activeModalConfirmation = useAtomValue(activeModalConfirmationAtom)
  const isSelectorClassOpen = useAtomValue(selectorClassOpenAtom)
  const [cloeProParams, setCloeProParams] = useAtom(cloeProURLParamsAtom)
  const [, setIsCloeProActive] = useAtom(isCloeProActiveAtom)

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRequestingUrl, setIsRequestingUrl] = useState(false)
  const [cloeProURL, setCloeProURL] = useState('')
  const [hideMainNavigation] = useAtom(hideMainNavigationAtom)

  const classes = useStyle()

  const handleOpenCloseButton = () => {
    setIsOpen(prevState => {
      Analytics.recordEventClick({
        name: !prevState ? 'cloe_pro_generate_show' : 'cloe_pro_generate_hide',
        attributes: {
          ...subscription?.analytics
        }
      })
      return !prevState
    })
  }

  const getCloeProURl = async () => {
    try {
      if (!profile?.id || !classId || !profile.type) {
        throw new Error()
      }
      setIsRequestingUrl(true)
      setIsLoading(true)
      const response = await getCloeProURL(profile?.id, classId, Number(currentDiscipline?.id ?? 0), profile?.type)

      if (response?.success) {
        setIsCloeProActive(true)
        setCloeProURL(response?.data?.headers?.Location ?? '')
      } else {
        errorMessageToast()
      }
    } catch (err: any) {
      if (showCloePro) {
        errorMessageToast()
      }
    } finally {
      setIsRequestingUrl(false)
      setIsLoading(false)
    }
  }

  const errorMessageToast = () => toast.handler({
    content: t('A conexão com a Cloe Pro não pôde ser estabelecida. Tente mais tarde.'),
    duration: 10000,
    severity: 'error'
  })

  useEffect(() => {
    setIsCloeProActive(false)
    const newCloeProParams = `${profile?.id}_${classId}_${profile?.type}`
    if (showCloePro && cloeProParams !== newCloeProParams) {
      setCloeProParams(newCloeProParams)
      getCloeProURl()
    }
  }, [showCloePro, profile, classId])

  const handleIframeOnload = () => {
    if (isRequestingUrl) return
    setIsLoading(false)
  }

  if (!showCloePro || !profile?.id || !classId || !currentDiscipline?.code || !profile?.type || !cloeProURL || hideMainNavigation) {
    return <></>
  }

  return (
    <>
      <StatusClassHandle>
        <CurrentClass>
          <Grid container className={`${classes.chatContainer} ${openMyCloe && 'openMyCloe'} ${activeMenuClass && 'activeMenuClass'} ${activeModalConfirmation && 'activeMenuClassConfirmation'} ${isSelectorClassOpen && 'isSelectorClassOpen'} ${isOpen ? 'open' : 'closed'}`}>
            <Grid onClick={handleOpenCloseButton} item className={`${classes.openCloseButton} ${isOpen ? 'open' : 'closed'} ${openMyCloe && 'openMyCloe'}`}>
              {
                isOpen
                  ? <CloeProNotchClose className={classes.cloeProNotch} />
                  : <CloeProNotchOpen className={classes.cloeProNotch} />
              }
            </Grid>
            <Box className={`${classes.iframeContainer} ${isOpen ? 'open' : 'closed'}`}>
              {isLoading && <Loading />}
              <iframe
                title='Cloe Pro'
                src={cloeProURL}
                onLoad={handleIframeOnload}
                allow='clipboard-read; clipboard-write'
                width='100%'
                height='100%'
                style={{ border: 0 }}
                allowFullScreen
              />
            </Box>
          </Grid>
          <Grid className={`${classes.darkBackground} ${isOpen ? 'open' : 'closed'}`} ></Grid>
        </CurrentClass>
      </StatusClassHandle>
    </>
  )
}
