import Pagination from './components/Pagination'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Cards, Wrapper, ExpandArea } from './style'
import { ISectionResponse, ActivityControlStatusEnum } from 'services/types'
import Card from './components/Card'
import React, { useEffect, useRef } from 'react'
import { useContents } from '../useContents'
import { useAtom } from 'jotai'
import {
  activityIndexSelectedAtom,
  allActivities
} from 'pages/ContentUnit/atomStore'
import LockOutlined from '@mui/icons-material/LockOutlined'
import LockOpenOutlined from '@mui/icons-material/LockOpenOutlined'
import { useHistory, useParams } from 'react-router'
import { CONTENT_UNIT_STUDENT_EXPEDITION } from 'navigation/CONSTANTS'

interface IIndexProps {
  sections?: ISectionResponse[]
}

export const Index: React.FC<IIndexProps> = ({ sections }) => {
  const history = useHistory()
  const { contentId } = useParams<{ contentId?: string }>()
  const scrollContainerRef = useRef<HTMLDivElement | null>()
  const { indexSelected, setIndexSelected } = useContents()
  const [activities] = useAtom(allActivities)
  const [activityIndexSelected, setActivityIndexSelected] = useAtom(
    activityIndexSelectedAtom
  )

  const scrollToSelected = (element: HTMLDivElement | null) => {
    setTimeout(() => {
      if (element) {
        element.scrollLeft =
          (document.getElementById('card-selected')?.offsetLeft ?? 0) - 100
      }
    }, 300)
  }

  useEffect(() => {
    scrollToSelected(scrollContainerRef.current as HTMLDivElement)
  }, [indexSelected, activityIndexSelected])

  const handleSetActivityIndexSelected = (index: number) => {
    setActivityIndexSelected(index)
    if (activities) {
      history.push(
        CONTENT_UNIT_STUDENT_EXPEDITION(
          contentId,
          activities[index].id?.toString()
        )
      )
    }
  }

  return (
    <Wrapper>
      <ExpandArea>
        <ScrollContainer
          style={{ scrollBehavior: 'smooth', borderRadius: '16px' }}
          innerRef={el => {
            scrollToSelected(el as HTMLDivElement)
            scrollContainerRef.current = el as HTMLDivElement
          }}
          className='scroll-container'
        >
          <Cards key='cards'>
            {sections
              ? sections?.map((section, index) => (
                <Card
                  key={section.id}
                  idSection={section.id}
                  section={section}
                  position={index + 1}
                  title={section.title}
                  subtitles={section.contents.map(
                    content => content.title as string
                  )}
                  dataTestid='content_sections_select_item'
                />
              ))
              : activities?.map(activity => (
                <Card
                  key={activity.id}
                  iconType={activity.suggested_application_type}
                  idActivity={activity.id}
                  title={activity.title ?? activity.activity_name ?? ''}
                  dataTestid='content_sections_select_item'
                  endAdornment={
                    activity.activity_status?.status === ActivityControlStatusEnum.AVAILABLE
                      ? (
                        <LockOutlined />)
                      : (
                        <LockOpenOutlined />
                      )
                  }
                />
              ))}
          </Cards>
        </ScrollContainer>
        {(activities ?? sections) && (
          <Pagination
            indexSelected={sections ? indexSelected : activityIndexSelected}
            maxLength={sections ? sections?.length : activities?.length ?? 0}
            setIndexSelected={
              sections ? setIndexSelected : handleSetActivityIndexSelected
            }
            dataTestid={{
              before: 'content_sections_paginate',
              next: 'content_sections_paginate'
            }}
          />
        )}
      </ExpandArea>
    </Wrapper>
  )
}

export default Index
