import { useTranslation } from 'react-i18next'

import { Modal, Box, Grid, Paper } from '@mui/material'
import { Text, Button } from 'components/design-system'

import useStyle from './style'

interface IConfirmationModal {
  onConfirmModal: () => void
  onCloseModal: () => void
  isOpen: boolean
  highLightedText?: string | null
  title?: string
  description?: string
  confirmLabel?: string
  cancelLabel?: string
  confirmDatatestId?: string
  cancelDatatestId?: string
}

export const ConfirmationModal: React.FC<IConfirmationModal> = ({ onConfirmModal, highLightedText, isOpen, onCloseModal, title, description, confirmLabel, cancelLabel, confirmDatatestId = '', cancelDatatestId = '' }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const handleCloseModal = () => {
    void onCloseModal()
  }

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box className={classes.boxModal}>
        {
          title && (
            <Grid item sx={{ pt: 8, pb: 2, pl: 2, pr: 2 }}>
              <Text className={classes.modalTitle}>
                {title}
              </Text>
            </Grid>
          )
        }
        {
          highLightedText && (
            <Grid item sx={{ pl: 2, pr: 2 }}>
              <Paper elevation={0} className={classes.paperItem}>
                <Text>{highLightedText}</Text>
              </Paper>
            </Grid>
          )
        }
        {
          description && (
            <Grid item sx={{ pt: 2, pb: 8, pl: 2, pr: 2 }}>
              <Text className={classes.modalDescription}>
                {description}
              </Text>
            </Grid>
          )
        }

        <Grid item className={classes.classButton}>
          <Button
            variant='outlined'
            onClick={onConfirmModal}
            data-testid={confirmDatatestId}
          >
            {confirmLabel ?? t('Confirmar')}
          </Button>
          <Button
            variant='primary'
            onClick={handleCloseModal}
            data-testid={cancelDatatestId}
          >
            {cancelLabel ?? t('Cancelar')}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
