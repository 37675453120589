import { atom } from 'jotai'
import { IActivityResponse, IContentSkillResponse, IContentUnitResponse, IDidacticContentResponse, ISkillResponse } from 'services/types'
import { IContentUnitHeaderTabsTeacher } from '../components/Header/types'

type Nullable<T> = T | null

export enum SubTabsMenusTeacher {
  ORIGINALS = 0,
  LIBERATED = 1,
  CORRECTION = 2,
  FINISHED = 3
}

export enum SubTabsMenusStudent {
  ORIGINALS = 3,
  LIBERATED = 0,
  ANSWERED = 1,
  CORRECTED = 2
}

export const allAwnseredActivities = atom<Nullable<IActivityResponse[]>>([])
export const allCorrectedActivities = atom<Nullable<IActivityResponse[]>>([])
export const allNewsActivities = atom<Nullable<IActivityResponse[]>>([])
export const allAwnseredEvaluations = atom<Nullable<IActivityResponse[]>>([])
export const allCorrectedEvaluations = atom<Nullable<IActivityResponse[]>>([])
export const allNewsEvaluations = atom<Nullable<IActivityResponse[]>>([])
export const allActivities = atom<Nullable<IActivityResponse[]>>([])
export const activityIndexSelectedAtom = atom<number>(0)
export const sectionIdAtom = atom<string | null | undefined>(null)
export const contentUnitAtom = atom<IContentUnitResponse | null>(null)
export const didaticContentAtom = atom<IDidacticContentResponse | null>(null)
export const showMyCloeInContentUnitAtom = atom(false)
export const updateScheduleTimeAtom = atom(false)
export const lastContentIdAtom = atom<number>(0)
export const contentUnitHeaderTabsTeacherAtom = atom<IContentUnitHeaderTabsTeacher>('ponto-de-partida')

export const skillsAtom = atom<ISkillResponse[]>([])
export const contentSkillsAtom = atom<IContentSkillResponse[]>([])
export const hasResponseErrorAtom = atom<boolean>(false)
