import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    tag: {
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        marginRight: theme.spacingInline.nano
      }
    },
    fixed: {
      position: 'sticky',
      top: 0
    },
    fixedQuestions: {
      width: '100%',
      paddingLeft: '22px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        background: theme.colors.neutral.lightBase,
        position: 'sticky',
        top: '50px',
        zIndex: 3
      }
    }
  }
})
