import Storage from 'utils/storage'
import { ISubscriptionsSessionResponse, IUserSchoolProps } from '../'

export const getSubscription = () => {
  return Storage.props.getSubscription() as ISubscriptionsSessionResponse
}

export const getProfile = () => {
  return Storage.props.getProfile() as IUserSchoolProps
}
