import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IStyleProps {
  gradesLength: number
}

export default makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      background: theme.colors.neutral.lightBase,
      borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0`,
      padding: theme.spacingInset.sm,
      height: 'auto',
      overflow: 'hidden',
      // mobile
      [theme.breakpoints.down('sm')]: {
        boxShadow: 'unset',
        paddingInline: theme.spacingInline.xxxs,
        paddingTop: '64px' // header height
      },
      [theme.breakpoints.up('md')]: {
        height: ({ gradesLength }: IStyleProps) => gradesLength <= 1 && '100vh'
      }
    },
    selectorsContainer: {
      display: 'flex',
      gap: theme.spacingInline.xxs,

      '& .selectorContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacingInline.nano,
        width: '100%',

        [theme.breakpoints.up('sm')]: {
          width: 'auto'
        },

        '& .selectorLabel': {
          fontWeight: theme.font.fontWeight.medium,
          fontSize: theme.font.fontSize.md,
          color: theme.colors.neutral.dark30,
          minWidth: '8.5rem'
        }
      }
    },
    notFoundContainer: {
      width: '100%',
      textAlign: 'center',
      color: theme.colors.contentType.default
    }
  })
)
