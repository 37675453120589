import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import NaturalCompare from 'string-natural-compare'

import EventNoteIcon from '@mui/icons-material/EventNote'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'

import { IProfilesSessionResponse, ISubscriptionsSessionResponse, IClassSubscriptionProfile, IUserSchoolProfileTypeEnum } from 'services/types'
interface IClassesGroup {
  id: string
  title: string
  icon: JSX.Element
  subgroups: IClassSubscriptionProfile[][]
  className: string
}

export const useClassesGroups = () => {
  const { session } = useStore()
  const { t } = useTranslation()

  const classesAvailable: {
    [key: string]: IClassSubscriptionProfile[]
  } = {}

  session?.subscriptions?.forEach((sub: ISubscriptionsSessionResponse) => {
    const isStudentSubs = sub.class && sub.user_school_profile.type === IUserSchoolProfileTypeEnum.student

    if (!isStudentSubs || !session.profiles.length) return

    const actualProfile = session.profiles.find(profile => profile.id === sub?.user_school_profile.id) as IProfilesSessionResponse
    const actualSchoolPeriod = actualProfile?.school?.school_periods?.find(schoolPeriod => schoolPeriod.id === sub?.class?.school_period)

    if (!actualSchoolPeriod) return

    const previousOptions = classesAvailable[actualSchoolPeriod.id] ?? []
    const options: IClassSubscriptionProfile[] = [
      ...previousOptions,
      {
        ...sub,
        schoolPeriod: actualSchoolPeriod,
        actualProfile
      }
    ]

    classesAvailable[actualSchoolPeriod.id] = options
  })

  const groupschoolPeriods = Object.values(classesAvailable ?? {})
  const classesAvailableSorted = groupschoolPeriods.map(subgroup => (
    subgroup.sort((a, b) => NaturalCompare(a.class.name, b.class.name))
  ))

  const classesList: IClassesGroup[] = []

  const initialClasses = classesAvailableSorted.filter((classObjArr) => classObjArr[0].schoolPeriod.current) ?? []
  const previousClasses = classesAvailableSorted.filter((classObjArr) => !classObjArr[0].schoolPeriod.current) ?? []

  if (initialClasses.length) {
    classesList.push(
      {
        id: 'currentClasses',
        title: t('Turmas atuais'),
        icon: <EventNoteIcon />,
        subgroups: initialClasses,
        className: 'currentClassesContainer'
      }
    )
  }

  if (previousClasses.length) {
    classesList.push(
      {
        id: 'currentClasses',
        title: t('Turmas anteriores'),
        icon: <EventRepeatIcon />,
        subgroups: previousClasses,
        className: 'previousClasses'
      }
    )
  }

  return { classesGroups: classesList }
}
