/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { Pagination, useTheme, useMediaQuery, Grid, PaginationItem } from '@mui/material'
import { PrintOutlined, DownloadOutlined, PictureAsPdfOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Alert } from 'components/design-system/Alert'
import { getFileExtension, isAudioFile } from 'utils/files'

import useStyles from './style'
import { AudioPlayer } from '../AudioPlayer'

interface IGalleryProps {
  medias: string[]
  activeIndex?: number | null
  setActiveIndex: (index: number | null) => void
  paginationDataTestId?: string
}

export const Gallery: React.FC<IGalleryProps> = ({ medias, activeIndex = 0, setActiveIndex, paginationDataTestId }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isSafari = /apple/i.test(navigator.vendor)
  const { t } = useTranslation()

  const getFileName = (): string => {
    if (activeIndex === null) return ''
    const extension = getFileExtension(medias[activeIndex] ?? '')
    if (!medias[activeIndex]) return 'sem nome'
    const fileName = medias[activeIndex].split('/').pop() ?? ''
    return isMobile && fileName.length > 25 ? `${fileName.substring(0, 25).replace(`/${extension}/ig`, '')}...${extension}` : fileName
  }

  const openPdfMobile = (url: string) => {
    window.open(url, '_blank')
  }

  const renderFile = () => {
    if (activeIndex === null) return
    const extension = getFileExtension(medias[activeIndex] ?? '')
    if (extension === 'pdf') {
      const pdfStyle = {
        minWidth: isMobile ? '100%' : '1000px',
        maxWidth: isMobile ? '100%' : '1000px',
        minHeight: '30rem',
        objectfit: 'cover'
      }
      if (!isMobileOrTablet) return <iframe className={classes.mediaContent} src={`${medias[activeIndex]}#view=fit`} style={pdfStyle} />
      return (
        <>
          <Alert
            content={t('Caso não consiga visualizar, baixe o arquivo ou tente trocar de navegador.')}
            severity='warning'
            className={classes.movWarning}
            style={{ maxWidth: '1000px' }}
          />
          <Grid className={classes.contentDownload}>
            <span>{getFileName()}</span>
            <DownloadOutlined onClick={() => openPdfMobile(medias[activeIndex])} />
          </Grid>
          <Grid
            zIndex={1}
            top={0}
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
            position='relative'
            bgcolor={theme.colors.neutral.light10}
          >
            <iframe className={classes.mediaContent} src={`${medias[activeIndex]}#view=fit`} style={pdfStyle} />
            <Grid position='absolute' ><PictureAsPdfOutlined fontSize='large' /></Grid>
          </Grid>
        </>
      )
    }

    if (['mp4', 'mov', 'qt'].includes(extension)) return <video className={classes.mediaContent} src={`${medias[activeIndex]}#t=0.001`} controls={true} style={{ maxWidth: '1000px' }} />

    if (['jpg', 'png', 'jpeg', 'webp', 'gif'].includes(extension)) return <img className={classes.mediaContent} style={{ maxWidth: '1000px' }} src={medias[activeIndex]} />

    if (isAudioFile(medias[activeIndex] ?? '')) {
      return <div className={`${classes.audioContainer} ${classes.fullWidth}`}><AudioPlayer url={medias[activeIndex]} /></div>
    }

    return <object className={classes.mediaContent} data={medias[activeIndex]} style={{ maxWidth: '1000px' }} />
  }

  const download = async () => {
    try {
      if (activeIndex === null) return
      const requestFile = await fetch(medias[activeIndex], {
        mode: 'no-cors'
      })
      const fileBlob = await requestFile.blob()
      const fileURL = URL.createObjectURL(fileBlob)

      const link = document.createElement('a')
      link.href = fileURL
      link.download = getFileName()
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      console.log(err)
    }
  }

  const print = () => {
    if (activeIndex === null) return
    const a = window.open('', '', 'height=500, width=500')
    a?.document.write('<html>')
    a?.document.write('<body>')
    a?.document.write(
      `<div style="width: 100%; height: auto; display: flex; justify-content: center;">
        <div style="width: 80%; height: auto; display: flex; justify-content: center;">
          <img src="${medias[activeIndex]}" width="100%" height="auto" />
        </div>
      </div>`
    )
    a?.document.write('</body></html>')
    a?.document.close()

    setTimeout(() => {
      a?.print()
    }, 1000)
  }

  if (activeIndex === null) return <></>

  return (
    <div className={`${classes.galleryContainer} ${isAudioFile(medias[activeIndex] ?? '') && classes.fullWidth}`}>
      {(getFileExtension(medias[activeIndex] ?? '') !== 'pdf' && !isAudioFile(medias[activeIndex] ?? '')) && (
        <div className={classes.galleryToolbarContent}>
          <span>{getFileName()}</span>
          <div className={classes.groupIcons}>
            {['jpeg', 'jpg', 'png', 'webp'].includes(getFileExtension(medias[activeIndex]) ?? '') && <PrintOutlined onClick={() => print()} />}
            <DownloadOutlined onClick={async () => await download()} />
          </div>
        </div>
      )}
      <div className={classes.galleryContent}>
        {(getFileExtension(medias[activeIndex] ?? '') === 'mov' && !isSafari) &&
          <>
            <br />
            <Alert
              content={t('Este vídeo é um arquivo .MOV, caso esteja usando o navegador Chrome e Firefox pode ser que o vídeo não reproduza. Tente trocar de navegador ou baixe o arquivo para conseguir reproduzir o vídeo.')}
              severity='warning'
              className={classes.movWarning}
              style={{ maxWidth: '1000px' }}
            />
          </>
        }
        {renderFile()}
      </div>
      <Pagination
        className={classes.galleryPagination}
        count={medias.length}
        page={activeIndex + 1}
        onChange={(_, value: number) => {
          setActiveIndex(value - 1)
        }}
        renderItem={(item) => (
          <PaginationItem
            data-testid={paginationDataTestId}
            {...item}
          />
        )}
      />
    </div>
  )
}
