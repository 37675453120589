import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, useTheme } from '@mui/material'
import { Text2 } from 'components/design-system'
import { Empty } from './components/Empty'
import { ShowCards } from './components'

import useMyCloeSchedule from 'navigation/hooks/useMyCloeSchedule'

import { IScheduleResponse } from 'services/types'

import useStyle from './style'

enum StateMachineEnum {
  IDLE = 'IDLE',
  EMPTY = 'EMPTY',
  SHOW_CARDS = 'SHOW_CARDS'
}

interface IReadyProps {
  schedules: IScheduleResponse[]
}

export const Ready: React.FC<IReadyProps> = ({ schedules }) => {
  const theme = useTheme()
  const classes = useStyle()
  const { t } = useTranslation()

  const [currentState, setCurrentState] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  const { createExistingContentScheduleHandler } = useMyCloeSchedule()

  useEffect(() => {
    setCurrentState(StateMachineEnum.IDLE)

    if (!schedules.length) {
      setCurrentState(StateMachineEnum.EMPTY)
      return
    }

    setCurrentState(StateMachineEnum.SHOW_CARDS)
  }, [schedules])

  const addNewMaterial = async () => {
    createExistingContentScheduleHandler()
  }

  const stateMachine = {
    IDLE: <></>,
    EMPTY: <Empty addNewMaterial={addNewMaterial} />,
    SHOW_CARDS: <ShowCards addNewMaterial={addNewMaterial} schedules={schedules} />
  }

  return (
    <>
      <h1 className={classes.title}>
        {t('Meus materiais')}
      </h1>
      <Grid item >
        <Text2
          fontSize='sm'
          fontWeight='medium'
          lineHeight='xs'
          mobile='sm'
          customColor={theme.colors.neutral.dark20}
        >
          {t('Insira arquivos relacionados ao conteúdo desta unidade para compartilhá-los com os estudantes.')}
        </Text2>
      </Grid>
      <Grid item>
        {
          stateMachine[currentState]
        }
      </Grid>
    </>
  )
}
