import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Search, Select, Text2 } from 'components/design-system'
import { useAtom } from 'jotai'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { getDetailedPerformance } from 'services/cloe-reports'
import { useCurricularStore, useStore } from 'store'
import { DetailedPerformanceList } from '..'
import { currentStudentPerformanceAtom, searchAtom, sortByAtom } from 'pages/Dashboard/teacher/ReportsTab/atomStore'
import { IUnitStudentDetailedPerformance } from 'contentCacheManager'
import { truncateExtension } from 'utils/string'
import Analytics from 'utils/analytics'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

interface IDetailedPerformance {
  currentDisciplineId: string | number
  isLoading: boolean
}

interface IOrderByOption {
  id: number
  label: string
  value: string
  dataTestId?: string
}

export const DetailedPerformance: React.FC<IDetailedPerformance> = ({ currentDisciplineId, isLoading }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { classId } = useParams<{ classId?: string }>()
  const { profile, subscription, schoolPeriod } = useStore()
  const { currentDiscipline, currentSegment, currentGrade, currentClass } = useCurricularStore()

  const PAGE_SIZE = 8

  // atoms
  const [search, setSearch] = useAtom(searchAtom)
  const resetSearch = useResetAtom(searchAtom)
  const [sortBy, setSortBy] = useAtom(sortByAtom)
  const resetSortBy = useResetAtom(sortByAtom)
  const currentStudentPerformance = useAtomValue(currentStudentPerformanceAtom)

  // states
  const [detailedPerformanceList, setDetailedPerformanceList] = useState<IUnitStudentDetailedPerformance[]>([])
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false)
  const [limit, setLimit] = useState(PAGE_SIZE)
  const [offset, setOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [showMorePages, setShowMorePages] = useState<boolean>(false)
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  const orderByOptions: IOrderByOption[] = [
    { id: 1, label: 'Ordem crescente', value: 'asc' },
    { id: 2, label: 'Ordem decrescente', value: 'desc' }
  ]

  const options: IOrderByOption[] = orderByOptions.map(op => ({ ...op, dataTestId: 'followup_individual_student_select_order' }))

  const resetPage = () => {
    setLimit(PAGE_SIZE)
    setOffset(0)
    setCurrentPage(0)
  }

  useEffect(() => {
    setStateMachine(
      (isLoadingList ?? isLoading)
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading, isLoadingList])

  useEffect(() => {
    if (currentDisciplineId && Number(currentDisciplineId) !== 0) {
      resetPage()
      resetSearch()
      resetSortBy()
      setDetailedPerformanceList([])
      fetchDetailedPerformance('', 'desc')
    }
  }, [currentDisciplineId])

  const handleRecordEventClick = (eventName: string, searchParam: string) => {
    Analytics.recordEventClick({
      name: eventName,
      attributes: {
        ...subscription?.analytics,
        school_name: profile?.school?.name,
        class_id: classId,
        class_name: currentClass?.name,
        grade_id: currentGrade?.id,
        grade_code: currentGrade?.code,
        segment_id: currentSegment?.id,
        segment_name: currentSegment?.name,
        discipline_id: currentDiscipline?.id,
        discipline_title: currentDiscipline?.name,
        period_status: schoolPeriod?.current,
        period_title: schoolPeriod?.title,
        search_term: searchParam
      }
    })
  }

  const fetchDetailedPerformance = async (searchParam: string, sortParam: 'desc' | 'asc' | 'situation' | undefined, isLoadMore?: boolean) => {
    if (profile?.school?.id && classId && currentDisciplineId && currentStudentPerformance?.userSchoolProfile?.id) {
      setIsLoadingList(true)

      let nextLimit = limit
      let nextOffset = offset
      let nextPage = currentPage

      if (isLoadMore) {
        nextPage = currentPage + 1
        nextOffset = nextPage * PAGE_SIZE
        nextLimit = PAGE_SIZE

        setOffset(nextOffset)
        setLimit(nextLimit)
        setCurrentPage(nextPage)
      }

      const resDetailedPerformance = await getDetailedPerformance({
        classId: Number(classId),
        disciplineId: Number(currentDisciplineId),
        schoolId: profile?.school.id,
        studentProfileId: currentStudentPerformance.userSchoolProfile.id,
        limit: nextLimit,
        offset: nextOffset,
        search: searchParam,
        sortBy: sortParam
      })

      if (resDetailedPerformance?.data?.units?.length) {
        const newList = isLoadMore
          ? [...(detailedPerformanceList ?? []), ...(resDetailedPerformance?.data.units ?? [])]
          : resDetailedPerformance?.data.units

        setDetailedPerformanceList(newList)

        // Quando o termo pesquisado for encontrado
        if (searchParam !== '') {
          handleRecordEventClick('followup_individual_student_search_term_found', searchParam)
        }
      } else {
        resetPage()
        setShowMorePages(false)
        setDetailedPerformanceList([])

        // Quando o termo pesquisado não for encontrado
        if (searchParam !== '') {
          handleRecordEventClick('followup_individual_student_search_term_not_found', searchParam)
        }
      }

      if (!resDetailedPerformance?.data.hasMore) {
        resetPage()
      }

      setShowMorePages(resDetailedPerformance?.data.hasMore)
      setIsLoadingList(false)
    }
  }

  const handleSearch = (pattern: string) => {
    setSearch(pattern)
    setDetailedPerformanceList([])
    fetchDetailedPerformance(pattern, sortBy)
  }

  const handleSortBy = (e: ChangeEvent<HTMLInputElement>) => {
    const _sort = e.target.value === 'asc' ? 'asc' : 'desc'
    setSortBy(_sort)
    resetPage()
    setDetailedPerformanceList([])
    fetchDetailedPerformance(search, _sort)
  }

  const loadMore = async () => await fetchDetailedPerformance(search, sortBy, true)

  const states = {
    IDLE: <></>,
    LOADING: (
      <Grid item xs={12}>
        <DetailedPerformanceList
          detailedPerformanceList={detailedPerformanceList}
          isLoading={isLoadingList ?? isLoading}
          loadMore={loadMore}
          showMorePages={showMorePages}
        />
      </Grid>
    ),
    READY: (
      <Grid item xs={12}>
        <DetailedPerformanceList
          detailedPerformanceList={detailedPerformanceList}
          isLoading={isLoadingList ?? isLoading}
          loadMore={loadMore}
          showMorePages={showMorePages}
        />
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      <Grid container marginTop={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colorBrand.medium}
          >
            {t('Desempenho detalhado de {{studentName}}', { studentName: truncateExtension(currentStudentPerformance?.user?.name ?? '', 20) })}
          </Text2>
        </Grid>
        <Grid container item direction={isMobile ? 'column-reverse' : 'row'} columnSpacing={3} rowSpacing={2}>
          <Grid item xs={isMobile ? 'auto' : 12} md={9}>
            <Search
              placeholder={t('Pesquisar unidade')}
              onSubmit={handleSearch}
              onClear={() => handleSearch('')}
              dataTestId={{
                submit: 'followup_individual_student_search_unit',
                clear: 'followup_individual_student_clear_search'
              }}
            />
          </Grid>
          <Grid item xs={isMobile ? 'auto' : 12} md={3}>
            <Select
              dataTestId='followup_individual_student_order_by'
              id='order-by'
              label={t('Ordenar por')}
              options={options}
              value={sortBy}
              onChange={handleSortBy}
            />
          </Grid>
        </Grid>
        {renderStateMachine()}
      </Grid>
    </>
  )
}
