import dayjs from 'dayjs'

export const isValidEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isValidNumber = (number: string) => {
  return /^\d+$/.test(number)
}

export const isValidCpf = (cpf: string) => {
  cpf = cpf.replace('.', '').replace('.', '').replace('-', '')

  let soma = 0
  let resto

  if (cpf === '00000000000') {
    return false
  }

  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)

  resto = (soma * 10) % 11

  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  soma = 0

  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)

  resto = (soma * 10) % 11

  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export const isValidCnpj = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g, '')
  cnpj = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '')

  if (cnpj === '') return false
  else if (cnpj.length !== 14) return false
  else if (cnpj === '00000000000000' || cnpj === '11111111111111' ||
           cnpj === '22222222222222' || cnpj === '33333333333333' ||
           cnpj === '44444444444444' || cnpj === '55555555555555' ||
           cnpj === '66666666666666' || cnpj === '77777777777777' ||
           cnpj === '88888888888888' || cnpj === '99999999999999') return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

  if (resultado !== parseInt(digitos.charAt(0))) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

  if (resultado !== parseInt(digitos.charAt(1))) return false

  return true
}

export const isValidDate = (date: string | null | undefined, format: string) =>
  dayjs(date, format).format(format) === date
