import React, { useEffect, useCallback, useState } from 'react'
import { Box, Skeleton, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'

import { Resource, resourceTypeResolver } from 'components/common'
import { Text2 } from 'components/design-system'

import { IResourceResponse } from 'services/types'
import { getResource } from 'services/resources'

import useStyles from './style'

import Analytics from 'utils/analytics'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

interface ResourceItemProps {
  resourceId: string | number
  contentId: string | number
}

export const ResourceItem: React.FC<ResourceItemProps> = ({ resourceId, contentId }) => {
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [resource, setResource] = useState<IResourceResponse | null>(null)

  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { profile } = useStore()

  useEffect(() => {
    void getData()
  }, [resourceId])

  useEffect(() => {
    fireGAnalyticsEvent()
  }, [resource])

  const fireGAnalyticsEvent = () => {
    if (profile && resource) {
      Analytics.recordEventClick({
        name: 'activity_workbook_resource',
        attributes: {
          ...profile?.analytics,
          content_id: contentId,
          resource_id: resource.id,
          resource_type: resource.type
        }
      })
    }
  }

  const getData = useCallback(async () => {
    if (!resourceId) {
      return
    }

    setStateMachine(StateMachineEnum.LOADING)
    const response = await getResource(String(resourceId))

    if (response.success) {
      setResource(response.data)
    } else {
      setResource(null)
    }

    setStateMachine(StateMachineEnum.READY)
  }, [resourceId])

  const ResourceType = ({ resource }: { resource: IResourceResponse }) => (
    <Box className={classes.resourceType}>
      <Text2 fontSize='xs' fontWeight='regular' lineHeight='xxs' mobile='xs' customColor={theme.colors.neutral.lightBase}>
        {resourceTypeResolver(resource.type)}
      </Text2>
    </Box>
  )

  const Ready = () => {
    if (!resource) {
      return <></>
    }

    return (
      <Box className={classes.collumnFlex} key={resource.id}>
        <Box className={classes.collumnFlex}>
          <Text2 fontSize='xs' fontWeight='medium' lineHeight='xxs' mobile='xs' customColor={theme.colors.neutral.dark40}>
            {t('Recurso didático')}
          </Text2>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='sm' mobile='lg' customColor={theme.colorBrand.medium}>
            {resource.title}
          </Text2>
          <ResourceType resource={resource} />
        </Box>
        <Box className={classes.resourceContainer}>
          <Resource resource={resource} />
        </Box>
      </Box>
    )
  }

  const ResourceSkeleton = () => (
    <Box className={classes.collumnFlex}>
      <Box className={classes.collumnFlex}>
        <Text2 fontSize='xs' fontWeight='medium' lineHeight='xxs' mobile='xs' customColor={theme.colors.neutral.dark40}>
          <Skeleton variant='text' width={100} height={40} animation='wave' />
        </Text2>
        <Text2 fontSize='xl' fontWeight='medium' lineHeight='sm' mobile='xl' customColor={theme.colorBrand.medium}>
          <Skeleton variant='text' width={300} height={60} animation='wave' />
        </Text2>
        <Skeleton variant='text' width={70} height={50} animation='wave' />
      </Box>
      <Box className={classes.resourceContainer}>
        <Skeleton variant='rectangular' width={500} height={300} animation='wave' />
        <br />
        <Skeleton variant='rectangular' width={300} height={40} animation='wave' />
      </Box>
    </Box>
  )

  const states = {
    IDLE: '',
    LOADING: <ResourceSkeleton />,
    READY: <Ready />,
    ERROR: ''
  }

  const renderMachineState = () => {
    return states[stateMachine]
  }

  return (
    <>
      {renderMachineState()}
    </>
  )
}
