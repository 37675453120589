import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { Text2 } from 'components/design-system'
import { CircularProgress } from 'components/common/Charts/CircularProgress'
import useStyle from './styles'

interface IBigNumberItemProps {
  title: string
  percentage?: number
  valueText?: string
  subtitle?: string
}

export const BigNumberItem: React.FC<IBigNumberItemProps> = ({ title, percentage, valueText, subtitle }) => {
  const classes = useStyle()
  const theme = useTheme()

  return (
    <Grid className={`${classes.bigNumbersItem} BigNumber${title.replace(/\s/g, '')}`}>
      {
        typeof percentage !== 'undefined' && !isNaN(percentage)
          ? <CircularProgress
            value={percentage}
            showPercentageSymbol
            customText={percentage === null && !valueText ? '-' : valueText}
            circleSize={'4rem'}
            textFontSize={'1rem'}
            theme={theme}
          />
          : <div className={classes.bigNumbersCircleContainer} style={{ height: '4rem', borderRadius: '2rem' }}>
            <Text2
              fontSize='sm'
              fontWeight='bold'
              lineHeight='xs'
              mobile='sm'
              customColor={theme.colorBrand.dark}
            >
              {valueText ?? '-'}
            </Text2>
          </div>
      }
      <Grid item display='flex' flexDirection='column'>
        <Text2
          fontSize='sm'
          fontWeight='semibold'
          lineHeight='xs'
          mobile='sm'
          noWrap
          className={classes.boxTitle}
          customColor={theme.colorBrand.dark}
        >
          {title}
        </Text2>

        <Text2
          fontSize='xxs'
          fontWeight='medium'
          lineHeight='xxs'
          mobile='xxs'
          noWrap
          className={classes.boxSubTitle}
          customColor={theme.colorBrand.dark}
        >
          {subtitle ?? <>&nbsp;</>}
        </Text2>
      </Grid>
    </Grid>
  )
}
