import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Text2 } from 'components/design-system'
import { ModalWrapper } from 'components/common'

interface IAccessTokenButtonProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  getTemporaryToken: () => void
  studentName: string
  studentUsername: string
}

export const AccessTokenModal: React.FC<IAccessTokenButtonProps> = ({ open, setOpen, getTemporaryToken, studentName, studentUsername }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const handleConfirm = () => {
    getTemporaryToken()
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <ModalWrapper
      open={open}
      onCancel={handleCancel}
      cancelDatatestId='confirm_generate_provisional_access_cancel'
      confirmDatatestId='confirm_generate_provisional_access_confirm'
      closeDatatestId='confirm_generate_provisional_access_close'
      onConfirm={handleConfirm}
      footer={{ background: 'rgba(230, 230, 230, 0.32)' }}
    >
      <Text2
        fontSize='lg'
        fontWeight='medium'
        lineHeight='xs'
        mobile='lg'
        sx={{ textAlign: 'center' }}
        customColor={theme.colorBrand.dark}
      >
        {t('Chave de acesso')}
      </Text2>
      <Text2
        fontSize='sm'
        fontWeight='medium'
        lineHeight='xs'
        mobile='sm'
        sx={{ textAlign: 'center' }}
        customColor={theme.colors.neutral.dark10}
      >
        {t('A chave de acesso é usada para login na plataforma sem nome de usuário e senha e é válida por 24 horas.')}
      </Text2>
      <Text2
        fontSize='sm'
        fontWeight='medium'
        lineHeight='xs'
        mobile='sm'
        sx={{ textAlign: 'center' }}
        customColor={theme.colors.neutral.dark10}
      >
        {t('Caso a senha tenha sido esquecida, o estudante ou responsável deverá redefini-la através do link Esqueci a senha da tela de login.')}
      </Text2>
      <Text2
        fontSize='sm'
        fontWeight='semibold'
        lineHeight='xs'
        mobile='sm'
        sx={{ textAlign: 'center' }}
        customColor={theme.colors.neutral.dark10}
      >
        {t('Deseja gerar uma chave de acesso para o estudante studentName?', { studentName: studentName })}
      </Text2>
    </ModalWrapper>
  )
}
