import { IApiResponse, IActivityResponse, IRecordStatusControlRequest, ActivityControlStatusEnum, ILevelResponse, ICorrectAnswerRequest, IRevisionAnswerRequest, ISchoolsClassResponse, IRevisionAnswerRequestv2, ICorrectAnswerRequestV2 } from 'services/types'
import { ACTIVITY, ACTIVITIES_FROM_CONTENT_ID, ACTIVITIES_FROM_CONTENT_ID_ALL_TYPES, ACTIVITY_STATUS_CONTROL_UPDATE, ACTIVITY_STATUS_CONTROL_BY_CLASS, ACTIVITY_BY_CLASS, ACTIVITIES_SCHOOLS_CLASSES, ACTIVITY_BY_CONTENT_UNIT, ACTIVITY_CLONE } from './CONSTANTS'
import { request } from 'services/base'

// TODO:  ainda não temos o conceito de pendente, logo todas que não tem status são pendendentes
export const getActivitiesByContent = async (classId: number, contentId: string | number, activityType: string, status?: ActivityControlStatusEnum | ActivityControlStatusEnum[] | null): Promise<IApiResponse<IActivityResponse[]>> =>
  await request({ url: ACTIVITIES_FROM_CONTENT_ID(classId, contentId, activityType, status ?? null) })

export const getAllActivitiesByContent = async (classId: number, contentId: string | number): Promise<IApiResponse<IActivityResponse[]>> =>
  await request({ url: ACTIVITIES_FROM_CONTENT_ID_ALL_TYPES(classId, contentId) })

export const getNewsActivities = async (classId: number, contentId: string | number, activityType: string): Promise<IApiResponse<IActivityResponse[]>> =>
  await request({ url: ACTIVITIES_FROM_CONTENT_ID(classId, contentId, activityType, null) })

export const getCorrectedActivities = async (classId: number, contentId: string | number, activityType: string): Promise<IApiResponse<IActivityResponse[]>> =>
  await request({ url: ACTIVITIES_FROM_CONTENT_ID(classId, contentId, activityType, ActivityControlStatusEnum.CORRECTED) })

export const getAllAnswered = async (classId: number, contentId: string | number, activityType: string): Promise<IApiResponse<IActivityResponse[]>> =>
  await request({ url: ACTIVITIES_FROM_CONTENT_ID(classId, contentId, activityType, ActivityControlStatusEnum.DONE) })

export const getActivity = async (id: string | number): Promise<IApiResponse<IActivityResponse>> =>
  await request({ url: ACTIVITY(id) })

export const getActivityStatusControl = async (activityId: string | number): Promise<IApiResponse<IActivityResponse[]>> =>
  await request({ url: ACTIVITY(activityId) })

export const getActivityByClass = async (classId: string | number, id: string | number, disableToast: boolean = false): Promise<IApiResponse<IActivityResponse>> =>
  await request({ url: ACTIVITY_BY_CLASS(classId, id), disableToast })

export const getStatusControl = async (id: string | number): Promise<IApiResponse<IActivityResponse>> =>
  await request({ url: ACTIVITY_STATUS_CONTROL_BY_CLASS(id) })

export const editStatusControl = async (id: string | number, data: IRecordStatusControlRequest): Promise<IApiResponse<IActivityResponse>> =>
  await request({ url: ACTIVITY_STATUS_CONTROL_UPDATE(id), method: 'POST', data: data })

// TODO: adicionar const
export const getLevels = async (): Promise<IApiResponse<ILevelResponse[]>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/levels` })

export const saveCorrectQuestion = async (data: ICorrectAnswerRequest): Promise<IApiResponse<any>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/answers/${data.anwserId}/correct/question/${data.questionId}`, method: 'post', data: data })

export const correctQuestionV2 = async (data: ICorrectAnswerRequestV2): Promise<IApiResponse<any>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/v1/class/${data.classId}/activity/${data.activityId}/question/${data.questionId}/answers/${data.anwserId}/correct`, method: 'post', data: { level: data.level.id } })

export const revisionQuestion = async (data: IRevisionAnswerRequest): Promise<IApiResponse<any>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/answers/${data.anwserId}/revised/question/${data.questionId}`, method: 'post', data: data })

export const revisionQuestionV2 = async (data: IRevisionAnswerRequestv2): Promise<IApiResponse<any>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/v1/class/${data.classId}/activities/${data.activityId}/question/${data.questionId}/answers/${data.anwserId}/revise`, method: 'post' })

export const getSchoolsClasses = async (activityId: string | number, contentUnit: string | number, gradeCode: string | number, schoolPeriod: string | number): Promise<IApiResponse<ISchoolsClassResponse>> =>
  await request({ url: ACTIVITIES_SCHOOLS_CLASSES(activityId, contentUnit, gradeCode, schoolPeriod) })

export const getActivitiesByContentUnit = async (classId: string | number, contentUnit: string | number): Promise<IApiResponse<IActivityResponse[]>> =>
  await request({ url: ACTIVITY_BY_CONTENT_UNIT(classId, contentUnit) })

interface ICloneActivityProps {
  activityId: string | number
  classId: string | number
  contentUnitId: string | number
  removedQuestions: String[] | Number[]
  newTitle: string
}

export const cloneActivity = async ({ activityId, classId, contentUnitId, removedQuestions, newTitle }: ICloneActivityProps): Promise<IApiResponse<IActivityResponse>> => {
  const removedQuestionsIds = removedQuestions.map(el => Number(el))

  return await request({
    url: ACTIVITY_CLONE(activityId),
    method: 'POST',
    data: {
      class_id: classId,
      content_unit: contentUnitId,
      new_title: newTitle,
      removed_questions: removedQuestionsIds
    }
  })
}

export const deleteCloneActivity = async (activityId: number): Promise<IApiResponse<boolean>> =>
  await request({
    url: ACTIVITY_CLONE(activityId),
    method: 'DELETE',
    disableToast: true
  })
