import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    studentContainer: {
      padding: `${theme.spacingInset.xxs} 0px`,
      borderBottom: `1px solid ${theme.colors.neutral.light10}`
    },
    containerAcordeon: {
      marginTop: theme.spacingStack.nano,
      width: '100%',
      '&.MuiAccordion-root': {
        boxShadow: 'none'
      }
    },
    accordionDetails: {
      padding: '0px',
      '&.MuiAccordionDetails-root': {
        padding: '0 0 20px 0',
        display: 'flex',
        flexWrap: 'wrap'
      }
    },
    acordeonSummary: {
      '&.acordeonSummary-content': {
        margin: 0
      }
    },
    titleAcordeon: {
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.medium,
      color: theme.colors.neutral.dark30,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.sm
      }
    },
    avatarCard: {
      [theme.breakpoints.down('sm')]: {
        overflow: 'hidden'
      },
      backgroundColor: theme.colors.neutral.lightBase,
      display: 'flex',
      height: '32px !important',
      cursor: 'pointer',
      marginBlock: theme.spacingStack.quarck,
      marginRight: theme.spacingStack.nano,
      padding: theme.spacingStack.quarck,
      border: '2px solid #E6E6E6',
      borderRadius: theme.spacingStack.quarck,
      alignItems: 'center',
      '&.is-active': {
        background: theme.colorBrand.medium,
        color: theme.colors.neutral.lightBase,
        border: `2px solid ${theme.colorBrand.medium}`
      },
      '& .MuiAvatar-root': {
        width: theme.spacingStack.xxs,
        height: theme.spacingStack.xxs,
        fontSize: theme.font.fontSize.xxs
      }
    },
    avatarCardName: {
      fontSize: theme.font.fontSize.xs,
      paddingInline: theme.spacingStack.nano,
      fontWeight: theme.font.fontWeight.medium,
      margin: 'auto'
    },
    disciplines: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacingStack.xxs
      }
    },
    navButton: {
      textDecoration: 'none',
      [theme.breakpoints.down('md')]: {
        '& > button': {
          marginBlock: theme.spacingStack.nano
        }
      }
    },
    buttonNoContentContainer: {
      display: 'inline-grid'
    },
    buttonBackToHomePage: {
      marginBlock: theme.spacingStack.xs
    },
    disciplineContainer: {
      padding: `${theme.spacingInset.md} 0px`,
      borderBottom: `1px solid ${theme.colors.neutral.light10}`,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    buttonContainer: {
      borderTop: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        minWidth: '410px'
      },
      [theme.breakpoints.up('sm')]: {
        padding: `30px ${theme.spacingStack.xxxs} 0px ${theme.spacingStack.xxxs}`,
        marginTop: '24px',
        gap: theme.spacingInset.xxs
      }
    },
    headerContainer: {
      '&.MuiGrid-root': {
        marginBottom: `${theme.spacingStack.xxs}`,
        [theme.breakpoints.up('md')]: {
          borderBottom: [
            [
              theme.borderThickness.hairline,
              'solid',
              theme.colors.neutral.light10
            ]
          ]
        }
      }
    },
    avatarContainer: {
      paddingTop: theme.spacingInset.xs,
      display: 'flex',
      alignItems: 'center',
      borderTop: `${theme.borderThickness.hairline} solid ${theme.colors.neutral.light10}`,
      [theme.breakpoints.up('md')]: {
        display: 'none !important',
        [theme.breakpoints.up('md')]: {
          borderTop: 'none'
        }
      }
    },
    avatarContainerDesktop: {
      marginTop: theme.spacingInset.sm,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'start',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    avatar: {
      width: '70px !important',
      height: '70px !important'
    },
    avatarName: {
      fontSize: theme.font.fontSize.md,
      paddingInline: theme.spacingStack.xxxs,
      fontWeight: theme.font.fontWeight.semibold,
      color: theme.colorBrand.medium,
      lineHeight: theme.font.lineHeight.xs
    }
  })
)
