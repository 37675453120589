import MultiChoice from './components/MultiChoice'
import Record from './components/Record'
import { IQuestionResponse } from 'services/types'

const inputTypes = {
  essay: (question: IQuestionResponse) => <Record question={question} />,
  record: (question: IQuestionResponse) => <Record question={question} />,
  multi_choice: (question: IQuestionResponse) => <MultiChoice question={question} />
}

const InputTypeComponent = ({ question }: { question: IQuestionResponse }) => {
  if (question.input_type in inputTypes) {
    return <>{inputTypes[question.input_type](question)}</>
  } else {
    console.warn(`Not found component input type ${question.input_type}`)
    return <Record question={question} />
  }
}

export default InputTypeComponent
