import { Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurricularStore } from 'store'
import { useAtom } from 'jotai'

import {
  Content,
  Activities,
  Activity,
  Evaluations,
  OverviewProjectViewMode,
  SubTabsMenus
} from '../components'
import { MyCloe } from 'navigation/components/MyCloe'
import { StatusClassHandle, CurrentClass, Loading } from 'components/common'
import { BusinessError } from 'navigation/BusinessError'
import { ScheduleCreator } from 'navigation/components/MyCloe/components'

import {
  CONTENT_UNIT_TEACHER,
  CONTENT_UNIT_TEACHER_ACTIVITIES,
  CONTENT_UNIT_TEACHER_ACTIVITY,
  CONTENT_UNIT_TEACHER_EVALUATION,
  CONTENT_UNIT_TEACHER_EVALUATIONS,
  CONTENT_UNIT_TEACHER_CONTENT,
  CONTENT_UNIT_TEACHER_EXPEDITION
} from 'navigation/CONSTANTS'

import { sectionIdAtom, contentUnitHeaderTabsTeacherAtom } from '../atomStore'
import { activityCurrentAtom } from '../components/Activity/atomStore'
import { contentUnitCardAtom, updateContentSelectAtom } from 'navigation/components/MyCloe/components/ScheduleCreator/atomStore'
import { MY_CLOE_CONTEXT, myCloeContextAtom } from 'navigation/components/MyCloe/atomStore'

import SectionContext from '../provider/SectionProvider'
import { IContentUnitResponse, IDisciplineResponse, IDidacticContentResponse, IMediaResponse } from 'services/types'
import { filterByCommonDisciplines } from './utils/showMyCloe'
import { useEffect } from 'react'
import useStudentAnswer from '../components/Activity/hooks/useStudentAnswer'
import { currentSubMenuTabAtom } from '../components/SideMenu/components'

interface IContentUnitStudentProps {
  contentUnit?: IContentUnitResponse
  didacticContent?: IDidacticContentResponse | null
}

export const ContentUnitTeacher: React.FC<IContentUnitStudentProps> = ({ contentUnit, didacticContent = null }) => {
  const [sectionId, setSectionId] = useAtom(sectionIdAtom)
  const [activityCurrent] = useAtom(activityCurrentAtom)
  const [contentUnitHeaderTabsTeacher] = useAtom(contentUnitHeaderTabsTeacherAtom)
  const [, setContentUnitCard] = useAtom(contentUnitCardAtom)
  const [myCloeContext, setMyCloeContext] = useAtom(myCloeContextAtom)
  const [updateContentSelect, setUpdateContentSelect] = useAtom(updateContentSelectAtom)
  const { isVerifyingRoutes } = useStudentAnswer()
  const [currentSubMenuTab] = useAtom(currentSubMenuTabAtom)

  const { t } = useTranslation()
  const dataWithoutDidacticContent = JSON.parse(JSON.stringify(contentUnit))
  delete dataWithoutDidacticContent.didactic_content

  const { currentDiscipline, disciplines, setCurrentDiscipline } = useCurricularStore()

  if (!contentUnit || isVerifyingRoutes) {
    return (
      <Loading type='linear' />
    )
  }

  if (!contentUnit.instructional_contents) {
    return <BusinessError error={t('Falha ao carregar Conteúdo Instrucional')} />
  }

  const handleCreateContentUnitSchedule = () => {
    setContentUnitCard({
      contenUnit: contentUnit.id,
      title: contentUnit.title,
      cover: contentUnit.cover as IMediaResponse,
      coverMobile: contentUnit.cover as IMediaResponse,
      suggested_position: contentUnit.suggested_position,
      disciplines: contentUnit.disciplines,
      // @ts-expect-error:next-line
      grade: contentUnit.grade,
      didactic_content: contentUnit?.didactic_content
    })
    setMyCloeContext(MY_CLOE_CONTEXT.CONTENT_UNIT)
    // updateScheduleContentUnit(contentUnit.id)
  }

  useEffect(() => {
    if (activityCurrent?.schedule?.discipline) {
      setCurrentDiscipline(activityCurrent.schedule.discipline)
    }

    if (activityCurrent) {
      const content = { ...activityCurrent, type: null, ...(updateContentSelect?.[0] ? { ...updateContentSelect?.[0] } : {}) }
      setUpdateContentSelect([content])
    }
  }, [activityCurrent])

  useEffect(() => {
    return () => {
      setMyCloeContext(MY_CLOE_CONTEXT.HOME)
    }
  }, [])

  useEffect(() => {
    if (
      contentUnit &&
      contentUnitHeaderTabsTeacher === 'ponto-de-partida' &&
      currentSubMenuTab === SubTabsMenus.MY_MATERIALS
    ) handleCreateContentUnitSchedule()
  }, [contentUnit, contentUnitHeaderTabsTeacher, currentSubMenuTab])

  const ShowMyCloe = () => {
    // TODO: se o retorno do filtro for vazio [] mostraremos as disciplinas lecionadas
    // TODO: isso ocorre devido as disciplinas de intinerario nao estarem na unidedade de conteudo - vamos ajustar
    let filteredDisciplines = filterByCommonDisciplines({ left: contentUnit.disciplines, right: disciplines ?? [] })

    if (!filteredDisciplines.length) {
      filteredDisciplines = disciplines as IDisciplineResponse[]
    }

    if (activityCurrent && myCloeContext === MY_CLOE_CONTEXT.ACTIVITY) {
      return (
        <MyCloe>
          <ScheduleCreator
            discipline={activityCurrent?.schedule?.discipline ? [activityCurrent?.schedule?.discipline] : filteredDisciplines}
            currentDisciplineId={activityCurrent?.schedule?.discipline ? String(activityCurrent?.schedule?.discipline?.id) : String(currentDiscipline?.id)}
          />
        </MyCloe>
      )
    }

    if (
      contentUnitHeaderTabsTeacher === 'ponto-de-partida' &&
      currentSubMenuTab === SubTabsMenus.MY_MATERIALS
    ) {
      return (
        <MyCloe>
          <ScheduleCreator
            type='original_content'
            discipline={filteredDisciplines}
            currentDisciplineId={String(currentDiscipline?.id)}
          />
        </MyCloe>
      )
    }
  }

  return (
    <>
      <StatusClassHandle>
        <CurrentClass>
          {ShowMyCloe()}
        </CurrentClass>
      </StatusClassHandle>
      <Switch>
        <SectionContext.Provider value={{ sectionId, setSectionId }}>
          <Route exact path={CONTENT_UNIT_TEACHER(':gradeTypeCode', ':gradeCode')}>
            <OverviewProjectViewMode content={contentUnit} />
          </Route>
          {
            // para infantil quebra em duas abas o contéudo
            !contentUnit?.grade?.code?.includes('EI_')
              ? <>
                <Route exact path={CONTENT_UNIT_TEACHER_CONTENT(':gradeTypeCode', ':gradeCode')}>
                  <Content content={contentUnit} didacticContent={didacticContent} />
                </Route>
              </>
              : <>
                <Route exact path={CONTENT_UNIT_TEACHER_EXPEDITION(':gradeTypeCode', ':gradeCode')}>
                  <Content content={dataWithoutDidacticContent} didacticContent={null} />
                </Route>
                <Route exact path={CONTENT_UNIT_TEACHER_CONTENT(':gradeTypeCode', ':gradeCode')}>
                  <Content content={contentUnit} didacticContent={didacticContent} />
                </Route>
              </>
          }
          <Route exact path={CONTENT_UNIT_TEACHER_ACTIVITIES(':gradeTypeCode', ':gradeCode')}>
            <Activities />
          </Route>
          <Route exact path={CONTENT_UNIT_TEACHER_EVALUATIONS(':gradeTypeCode', ':gradeCode')}>
            <Evaluations />
          </Route>
          <Route exact path={CONTENT_UNIT_TEACHER_ACTIVITY(':gradeTypeCode', ':gradeCode')}>
            <Activity content={contentUnit} />
          </Route>
          <Route exact path={CONTENT_UNIT_TEACHER_EVALUATION(':gradeTypeCode', ':gradeCode')}>
            <Activity content={contentUnit} />
          </Route>
        </SectionContext.Provider>
      </Switch>
    </>
  )
}

export default ContentUnitTeacher
