import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Loading } from 'components/design-system'
import { Disciplines } from 'components/store/Disciplines'
import { HeaderTabs } from '../Header'
import Ready from './components/Ready'
import { useCurricularStore } from 'store'

type state = 'idle' | 'loading' | 'ready' | 'error'

export const ScheduleTeacher: React.FC = () => {
  const [localState, setLocalState] = useState<state>('idle')

  const { currentDiscipline, disciplines, loadingDisciplines } = useCurricularStore()

  useEffect(() => {
    setLocalState('loading')
  }, [])

  useEffect(() => {
    setLocalState('loading')

    if (currentDiscipline && disciplines?.length && !loadingDisciplines) {
      setLocalState('ready')
    }
  }, [currentDiscipline, disciplines, loadingDisciplines])

  const states = {
    idle: '',
    loading: <Loading type='linear' />,
    ready: <Ready />,
    error: 'error...'
  }

  const render = () => {
    if (localState in states) {
      return states[localState]
    }
    return states.error
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderTabs className='only-mobile' />
      </Grid>
      <Disciplines dataTestid='schedule_curricular_component' showAll />
      {render()}
    </Grid>
  )
}

export default ScheduleTeacher
