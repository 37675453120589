import { Button } from 'components/design-system'
import React from 'react'
import useStyles from './style'
import { useAtom } from 'jotai'
import { questionIndexAtom, studentLevelsAtom } from '../../../../../atomStore'
import { IActivityResponse, IQuestionResponse } from 'services/types'
import { Grid } from '@mui/material'
import { reviseObjectAtom, selectedStudentProfileAtom } from '../../../../atomStoreOlder'

interface IPaginationProps {
  questions: IQuestionResponse[]
  activity?: IActivityResponse
  dataTestid?: string
}

export const Pagination: React.FC<IPaginationProps> = ({ questions, activity, dataTestid }) => {
  const [questionIndex, setQuestionIndex] = useAtom(questionIndexAtom)
  const [studentLevels] = useAtom(studentLevelsAtom)
  const [selectedStudentProfile] = useAtom(selectedStudentProfileAtom)
  const [revises] = useAtom(reviseObjectAtom)
  const classes = useStyles()

  return (
    <Grid container margin={0} sx={{ '&.MuiGrid-root': { margin: '0', paddingLeft: '0px', width: 'max-content' } }}>
      <ul className={classes.list}>
        {
          questions
            .map((question, index) => {
              let isCorrected = false
              let isRevised = false
              if (studentLevels) {
                isCorrected = studentLevels?.filter(a => question.id?.toString() === a.question_id?.toString() && a?.level && selectedStudentProfile).length > 0 ?? false
              }
              if (revises) {
                isRevised = revises?.filter(a => question.id?.toString() === a.questionId?.toString() && a?.revision && selectedStudentProfile).length > 0 ?? false
              }
              return (
                <li key={index} onClick={() => setQuestionIndex(index)}>
                  <Button data-testid={dataTestid} variant='outlined' className={`${(questionIndex === index) && 'selected'} ${((isCorrected || isRevised) && 'answered')}`}>
                    {index + 1}
                  </Button>
                </li>
              )
            })
        }
      </ul>
    </Grid>
  )
}
