import katex from 'katex'
import { decode } from 'html-entities'

export const parseKaTex = (str: string | null) => {
  if (typeof str !== 'string') return str

  const re1 = /<code>.*?<\/code>/g
  const re2 = /<code>|<\/code>/g

  return str.replace(re1, (result) => {
    let katexWithoutTags = result
      .replace(re2, '')
      .trim()

    katexWithoutTags = decode(katexWithoutTags)

    return katex.renderToString(katexWithoutTags, {
      throwOnError: false
    })
  })
}
