import { request } from 'services/base'
import { IApiResponse, IUserLoginResponse, IUserTokenResponse } from 'services/types'
import { GET_TEMPORARY_ACCESS, LOGIN_WITH_TEMPORARY_ACCESS } from './CONSTANT'

// TODO tipar responses
export const getTemporaryAccess = async (
  userSchoolProfile: string | number
): Promise<IApiResponse<IUserTokenResponse>> => {
  const json = { type: 'student', user_school_profile: userSchoolProfile }

  return await request({
    url: GET_TEMPORARY_ACCESS(),
    method: 'post',
    data: json
  })
}

export const loginWithTemporaryAccess = async (token: string): Promise<IApiResponse<IUserLoginResponse>> => await request({
  url: LOGIN_WITH_TEMPORARY_ACCESS(),
  method: 'post',
  data: { token }
})
