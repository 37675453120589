import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    padding: theme.spacingInline.xxxs,
    gap: 8,

    '& .currentPeriodsContainer': {
      color: theme.colorBrand.medium
    },
    '& .previousPeriods': {
      color: theme.colors.contentType.default
    }
  },
  drawerContainerClassName: {
    backgroundColor: 'rgba(246, 246, 246, 0.32) !important',
    backdropFilter: 'blur(3px)',
    borderRadius: theme.borderRadius.sm
  },
  dropdownContainer: {
    minWidth: '50px',
    width: '100%',

    '& .iconGoBackLink': {
      '& svg': {
        fill: `${theme.colorBrand.medium} !important`,
        fontSize: `${theme.font.fontSize.xl} !important`
      }
    },

    '& .selectedText': {
      fontSize: `${theme.font.fontSize.sm} !important`
    },

    [theme.breakpoints.up('md')]: {
      flex: 'initial',
      width: '250px',
      maxHeight: '48px'
    }
  },

  selectedContainer: {
    width: '100%',
    height: '100%',
    borderRadius: theme.borderRadius.sm,
    border: '2px solid transparent',
    padding: `${theme.spacingInline.quarck} ${theme.spacingInline.xxxs}`,

    '& svg': {
      fontSize: `${theme.font.fontSize.xxl} !important`
    },

    '&.focused': {
      border: '2px solid transparent'
    },

    '&.IDLE': {
      color: `${theme.colors.neutral.lightBase} !important`,
      backgroundColor: theme.colors.neutral.light40
    },

    '&.INPROGRESS': {
      color: `${theme.colors.information.feedbackDarkest} !important`,
      backgroundColor: theme.colors.information.feedbackLight
    },

    '&.DONE': {
      backgroundColor: theme.colors.positive.feedbackLight,
      color: `${theme.colors.positive.feedbackDarkest} !important`
    },

    // HOVER
    '&:hover': {
      '&.IDLE': {
        backgroundColor: `${theme.colors.neutral.light30} !important`,
        border: `2px solid ${theme.colors.neutral.light10}`
      },

      '&.INPROGRESS': {
        backgroundColor: `${theme.colors.information.feedbackLightest} !important`,
        border: `2px solid ${theme.colors.information.feedbackDarkest}`
      },

      '&.DONE': {
        backgroundColor: `${theme.colors.positive.feedbackLightest} !important`,
        border: `2px solid ${theme.colors.positive.feedbackDarkest}`
      }
    }
  },

  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '2px solid transparent',

    cursor: 'pointer',
    fontWeight: `${theme.font.fontWeight.semibold} !important`,
    fontSize: theme.font.fontSize.sm,
    color: `${theme.colors.neutral.lightBase} !important`,

    boxSizing: 'content-box',
    borderRadius: theme.borderRadius.sm,
    padding: '10px 16px',

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',

    [theme.breakpoints.up('md')]: {
      maxHeight: '48px'
    },

    '&.LOADING': {
      color: `${theme.colors.neutral.lightBase} !important`,
      backgroundColor: theme.colors.neutral.light40
    },

    '&.IDLE': {
      color: `${theme.colors.neutral.lightBase} !important`,
      backgroundColor: theme.colors.neutral.light40
    },

    '&.INPROGRESS': {
      color: `${theme.colors.information.feedbackDarkest} !important`,
      backgroundColor: theme.colors.information.feedbackLight
    },

    '&.DONE': {
      backgroundColor: `${theme.colors.positive.feedbackLight} !important`,
      color: `${theme.colors.positive.feedbackDarkest} !important`
    },

    '&.active': {
      '&.IDLE': {
        border: `2px solid ${theme.colors.neutral.light10}`
      },

      '&.INPROGRESS': {
        border: `2px solid ${theme.colors.information.feedbackDarkest}`
      },

      '&.DONE': {
        border: `2px solid ${theme.colors.positive.feedbackDarkest}`
      }
    },

    // HOVER
    '&:hover': {
      '&.IDLE': {
        backgroundColor: `${theme.colors.neutral.light30} !important`,
        border: `2px solid ${theme.colors.neutral.light10}`
      },

      '&.INPROGRESS': {
        backgroundColor: `${theme.colors.information.feedbackLightest} !important`,
        border: `2px solid ${theme.colors.information.feedbackDarkest}`
      },

      '&.DONE': {
        backgroundColor: `${theme.colors.positive.feedbackLightest} !important`,
        border: `2px solid ${theme.colors.positive.feedbackDarkest}`
      }
    }
  },
  selectedText: {
    color: `${theme.colors.neutral.lightBase} !important`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  labelText: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: theme.font.lineHeight.xxs,

    '&.IDLE': {
      color: `${theme.colors.neutral.lightBase} !important`
    },

    '&.INPROGRESS': {
      color: `${theme.colors.information.feedbackDarkest} !important`
    },

    '&.DONE': {
      color: `${theme.colors.positive.feedbackDarkest} !important`
    }
  },
  titleText: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.xs,

    '&.IDLE': {
      color: `${theme.colors.neutral.lightBase} !important`
    },

    '&.INPROGRESS': {
      color: `${theme.colors.information.feedbackDarkest} !important`
    },

    '&.DONE': {
      color: `${theme.colors.positive.feedbackDarkest} !important`
    }
  }
}))
