import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  subsection: {
    width: '100%',
    color: theme.colors.neutral.dark10,
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.lg,
    lineHeight: '32px',
    letterSpacing: theme.font.spacing.distant,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.md,
      lineHeight: theme.spacingStack.xxs,
      margin: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    overflowWrap: 'anywhere',
    color: theme.colors.neutral.dark10,
    fontSize: '18px',
    fontWeight: theme.font.fontWeight.regular,
    lineHeight: '32px',
    letterSpacing: '0.16px',
    [theme.breakpoints.down('sm')]: {
      color: theme.colors.neutral.dark20,
      fontSize: '18px' // theme.font.fontSize.sm
    },
    '& > p': {
      margin: 0,
      fontSize: '18px',
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: '32px',
      color: theme.colors.neutral.dark10,
      letterSpacing: '0.16px',

      '& > span': {
        fontSize: '18px',
        fontWeight: theme.font.fontWeight.regular,
        lineHeight: '32px',
        color: theme.colors.neutral.dark10,
        letterSpacing: '0.16px'
      }
    },
    '& table': {
      border: '0px !important',
      borderCollapse: 'collapse',

      '& td, th, tr': {
        border: '0px',
        padding: '16px',
        textAlign: 'center',

        '& li': {
          textAlign: 'left'
        }
      },

      '& tr': {
        borderCollapse: 'collapse'
      },

      '& th': {
        color: theme.colors.support.colorSupport02,
        borderBottom: `1px solid ${theme.colors.support.colorSupport02}`
      },

      '& td': {
        color: theme.colors.neutral.dark10
      },

      '& tr:not(:last-child)': {
        borderBottom: `1px solid ${theme.colors.support.colorSupport02}`
      },

      '&  th:not(:last-child), td:not(:last-child)': {
        borderRight: `1px solid ${theme.colors.support.colorSupport02}`
      }
    }
  }
}))
