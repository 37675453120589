import React from 'react'
import { OnlyProfile } from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { hideMainNavigationAtom, isBlockedNavigationAtom } from '../../atomStore'
import { useAtom } from 'jotai'
import { SidebarStudent } from './student'
import { SidebarTeacher } from './teacher'

export const Sidebar: React.FC = () => {
  const [isBlockedNavigation] = useAtom(isBlockedNavigationAtom)
  const [hideMainNavigation] = useAtom(hideMainNavigationAtom)

  if (isBlockedNavigation || hideMainNavigation) {
    return <React.Fragment />
  }

  return (
    <>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]} >
        <SidebarStudent />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]} >
        <SidebarTeacher />
      </OnlyProfile>
    </>
  )
}
