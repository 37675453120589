import React from 'react'
import { Grid, Theme, Button as ButtonMUI } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'components/common/Tooltip'

import useStyles from '../style'

export const CustomContent = ({ children, border, theme }: { children: React.ReactNode, border: string, theme: Theme }) => (
  <Grid
    container
    sx={{
      minHeight: '56px',
      border,
      borderRadius: theme.borderRadius.sm,
      paddingInline: theme.spacingInline.nano
    }}
    display='flex'
    gap='8px'
    justifyContent='center'
    alignSelf='center'
  >
    {children}
  </Grid>
)

export const CustomButton = ({ children, onClick, dataTestid }: { children: React.ReactNode, onClick: () => void, dataTestid?: string | null }) => {
  const inputRef = React.createRef<HTMLDivElement>()

  const handleDataTaging = () => {
    // solução para funcionar o tagamento
    inputRef?.current?.click()
  }

  const handleClick = () => {
    onClick()
    handleDataTaging()
  }

  return (
    <>
      <div ref={inputRef} data-testid={dataTestid}></div>
      <ButtonMUI
        sx={{
          textTransform: 'none',
          padding: '0px',
          textAlign: 'left',
          gap: '8px',
          '&:disabled': {
            filter: 'grayscale(100%)'
          }
        }}
        data-testid='asdasdasdas'
        onClick={handleClick}
        fullWidth
      >
        {children}
      </ButtonMUI>
    </>
  )
}

export const GridInfo = ({ theme }: { theme: Theme }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid
        item
        sx={{
          display: 'grid',
          alignContent: 'center',
          gridTemplateColumns: 'auto auto',
          columnGap: theme.spacingInset.xs
        }}
      >
        <span className={classes.infoText}>
          {`${t('Selecione os estudantes que receberão esta instrução')}:`}
        </span>
        <Tooltip
          component={
            <Grid
              item
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                '& svg': {
                  color: theme.colorBrand.medium
                }
              }}
            >
              <InfoOutlined
                style={{
                  alignSelf: 'center'
                }}
              />
            </Grid>
          }
          text={t('Os destinatários são opcionais, caso o evento esteja sendo criado para toda a turma, por padrão será enviado para a mesma.  Mas caso queira enviar para estudantes específicos, essa seleção pode ser feita através do botão Destinatários.')}
        />
      </Grid>
    </>
  )
}
