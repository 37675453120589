import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { CircularProgress, Grid, Modal, Box } from '@mui/material'
import { Text, Button } from 'components/design-system'

import { openModalConfirmationTimelineAtom } from '.././atomStore'

import useStyle from './style'

export interface IConfirmationModalProps {
  title: string
  description?: string
  cancelLabel?: string
  confirmLabel?: string
  onCancel: Function
  onConfirm: Function
  dataTestidCancel?: string
  dataTestidConfirm?: string
}

export const ConfirmationModal = (props: IConfirmationModalProps) => {
  const [openConfirmation, setOpenConfirmation] = useAtom(openModalConfirmationTimelineAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const classes = useStyle()

  const handleConfirm = async () => {
    setLoading(true)

    await props.onConfirm()

    setLoading(false)
  }

  const handleCancel = () => {
    setOpenConfirmation(!openConfirmation)
    props.onCancel()
  }

  return (
    <Modal open={openConfirmation} onClose={handleCancel}>
      <Box className={classes.boxModal}>
        <Grid display='grid'>
          <Text className={classes.classesItensText} type='body' size='large' style={{ fontWeight: 600 }} >
            {props.title}
          </Text>
          <Text className={classes.classesItensText} type='body' size='medium' style={{ fontWeight: 500, paddingTop: 8 }} >
            {props?.description}
          </Text>
        </Grid>
        <Grid className={classes.classButton}>
          <Button
            className={classes.classCancelButton}
            variant='ghost'
            onClick={handleCancel}
            data-testid={props.dataTestidCancel}
          >
            {props.cancelLabel ?? t('Cancelar')}
          </Button>
          <Button
            className={classes.classDeleteButton}
            onClick={handleConfirm}
            data-testid={props.dataTestidConfirm}
          >
            {
              !loading
                ? <>
                  {props.confirmLabel ?? t('Confirmar')}
                </>
                : <CircularProgress className={classes.circularProgress} />
            }
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
