import { CalendarTodayOutlined, ShareOutlined } from '@mui/icons-material'
import { Button } from 'components/design-system'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import Appcues from 'utils/appcues'
import { useMediaQuery, useTheme } from '@mui/material'

interface IActivityRelease {
  onClick?: () => void
  active?: boolean
  startDate?: string
  endDate?: string
  applicationType?: 'activity' | 'evaluation'
  isActivityCorrected: boolean
}

interface IButton {
  onClick?: () => void
  variant?: 'primary' | 'outlined'
  applicationType?: 'activity' | 'evaluation'
}

const ShareButton: React.FC<IButton> = ({ onClick, variant, applicationType }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const buttonText = applicationType === 'activity' ? t('Liberar atividade') : t('Liberar avaliação')
  const buttonTag = applicationType === 'activity'
    ? 'release_assessment_activity_within_the_content_release_activity'
    : 'release_assessment_activity_within_the_content_release_assessment'
  const handleEventTracking = () => {
    const events = {
      activity: () => Appcues.track('prof_liberar_atividade'),
      evaluation: () => Appcues.track('prof_liberar_avaliacao')
    }
    if (applicationType) {
      events[applicationType]()
    }
  }
  return (
    <Button
      variant={variant}
      className={classes.button}
      aria-label={buttonText}
      onClick={() => {
        if (onClick) {
          onClick()
        }
        handleEventTracking()
      }}
      startIcon={<ShareOutlined />}
      data-testid={buttonTag}
    >
      {!isMobile ? buttonText : ''}
    </Button>
  )
}

const EditButton: React.FC<IButton> = ({ onClick, variant, applicationType }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()
  const buttonTag = applicationType === 'activity'
    ? 'release_assessment_activity_within_the_content_edit_activity'
    : 'release_assessment_activity_within_the_content_edit_assessment'
  return (
    <Button
      variant={variant}
      className={classes.button}
      aria-label={t('Editar evento')}
      onClick={onClick}
      startIcon={<CalendarTodayOutlined />}
      data-testid={buttonTag}
    >
      {!isMobile ? t('Editar evento') : ''}
    </Button>
  )
}

const ErrorButton: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return <Button variant='primary' className={classes.button} disabled={true} aria-label={t('Falha ao carregar botão')} >{t('Falha ao carregar botão')}</Button>
}

type StateRender = 'share' | 'edit' | 'disable' | 'error'

export const ActivityRelease: React.FC<IActivityRelease> = ({ startDate, endDate, onClick, active, applicationType, isActivityCorrected }) => {
  const startDateJs = startDate ? dayjs(startDate) : undefined
  const endDateJs = endDate ? dayjs(endDate) : undefined
  const today = dayjs()
  const variant: 'primary' | 'outlined' = !active ? 'outlined' : 'primary'

  let currentState: StateRender = 'share'

  if (startDateJs) currentState = 'edit'
  if (endDateJs && today.isAfter(endDateJs) && isActivityCorrected) currentState = 'disable'

  const render: { [key in StateRender]: React.FC | React.ReactFragment } = {
    error: <ErrorButton />,
    share: <ShareButton onClick={onClick} variant={variant} applicationType={applicationType} />,
    edit: <EditButton onClick={onClick} variant={variant} applicationType={applicationType} />,
    disable: <></>
  }

  return (
    <>
      {currentState in render ? render[currentState] : render.error}
    </>
  )
}

export default ActivityRelease
