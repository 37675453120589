/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { useCurricularStore, useStore } from 'store'

import { Grid } from '@mui/material'
import { LoadingWrapper } from 'components/common'
import { Button, Text } from 'components/design-system'
import ErrorWrapper from 'components/common/ErrorWrapper'
import { CustomizedTimeline } from 'components/common/CustomizedTimeline'
import { Disciplines } from 'components/store/Disciplines'
import { FilterByDateRange } from 'components/common/FilterByDateRange'

import { BusinessError } from 'navigation/BusinessError'
import { ROOT } from 'navigation/CONSTANTS'

import { DisciplineCodeEnum, IFindStudentTimelineResponse } from 'services/types'
import { findstudentTimeline } from 'services/timeline'

import { filterByDateRangeAtom } from 'components/common/FilterByDateRange/atomStore'

import { convertToLocaleUTC } from 'utils/date'

import useStyle from './style'

export const StudentTimeline: React.FC = () => {
  const [loadingInitial, setLoadingInitial] = useState(true)
  const [hasResponseError, setHasResponseError] = useState<boolean>(false)
  const [contentError, setContentError] = useState<boolean>(false)
  const [loadingTimelineData, setLoadingTimelineData] = useState<boolean>(false)
  const [timelineData, setTimelineData] = useState<IFindStudentTimelineResponse[]>([])
  const [updateTimelineDataByFilter, setUpdateTimelineDataByFilter] = useState<boolean>(false)

  const [filterByDateRange] = useAtom(filterByDateRangeAtom)

  const { currentDiscipline, disciplines, setCurrentDiscipline } = useCurricularStore()
  const { subscription } = useStore()
  const classId = subscription?.class.id
  const userSchoolProfileId = subscription?.user_school_profile.id

  const { t } = useTranslation()

  const classes = useStyle()

  if (disciplines?.length && (!currentDiscipline || currentDiscipline?.code === DisciplineCodeEnum.ALL)) {
    setCurrentDiscipline(disciplines[0])
  }

  const getTimelineData = async () => {
    try {
      if (classId && userSchoolProfileId && currentDiscipline) {
        const response = await findstudentTimeline(classId, currentDiscipline.id, userSchoolProfileId, {
          startDate: filterByDateRange?.startDate && convertToLocaleUTC(filterByDateRange.startDate),
          endDate: filterByDateRange?.endDate && convertToLocaleUTC(filterByDateRange.endDate)
        })

        if (response.success) {
          setTimelineData(response.data)
        }

        setLoadingTimelineData(false)

        if (updateTimelineDataByFilter) {
          setUpdateTimelineDataByFilter(false)
        }
      }
    } catch {
      setContentError(true)
    } finally {
      if (loadingInitial) {
        setLoadingInitial(false)
      }
    }
  }

  useEffect(() => {
    if (!!classId && userSchoolProfileId) {
      setLoadingTimelineData(true)

      void getTimelineData()
    }
  }, [classId, currentDiscipline, userSchoolProfileId])

  useEffect(() => {
    if (updateTimelineDataByFilter) {
      setLoadingTimelineData(true)

      void getTimelineData()
    }
  }, [updateTimelineDataByFilter])

  const handleUpdateTimelineDataByFilter = (filterActive: boolean) => setUpdateTimelineDataByFilter(filterActive)

  const HasNoContent = () => (
    <Grid container justifyContent='center' alignItems='center'>
      <BusinessError error={t('Não conseguimos carregar o conteúdo para você')}>
        <br />
        <div className={classes.buttonNoContentContainer}>
          <NavLink to={ROOT} style={{ textDecoration: 'none' }} className={classes.buttonBackToHomePage}>
            <Button variant='outlined'>
              {t('Voltar para Página Inicial')}
            </Button>
          </NavLink>
          <Button onClick={() => window.location.reload()}>
            {t('Tentar novamente')}
          </Button>
        </div>
      </BusinessError>
    </Grid>
  )

  const HasContent = () => (
    <>
      <Grid item xs={12}>
        <Text type='heading' size='small' color='medium' style={{ fontWeight: 600 }}>{t('Meus registros')}</Text>
      </Grid>
      <Grid item xs={12} className={classes.disciplineContainer}>
        <Disciplines title={t('Exibir por componente curricular')} dataTestid='class_registry_curricular_component' />
      </Grid>
      <Grid item xs={12} className={classes.filterContainer}>
        <FilterByDateRange xs={12} md={7} lg={6} justify='start' onCallback={handleUpdateTimelineDataByFilter} />
      </Grid>
      <LoadingWrapper loading={loadingTimelineData} type='linear'>
        <CustomizedTimeline timelineData={timelineData} />
      </LoadingWrapper>
    </>
  )

  return (
    <div className={classes.container}>
      <Grid container sx={{ p: '32px' }} justifyContent={hasResponseError ? 'center' : 'initial'}>
        <ErrorWrapper hasError={hasResponseError} errorMessage={t('Houve um erro ao tentar buscar os dados, tente novamente')}>
          <LoadingWrapper loading={loadingInitial} type='linear'>
            {contentError
              ? <HasNoContent />
              : <HasContent />
            }
          </LoadingWrapper>
        </ErrorWrapper>
      </Grid>
    </div>
  )
}

export default StudentTimeline
