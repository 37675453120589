import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import useStyles from './style'
import { Button } from 'components/design-system'

import { IContentAreaProps, StateTypes } from './types'

import { TabsDefault } from './components/TabsDefault'
import { TabsEI } from './components/TabsEI'
import { TabsEF1 } from './components/TabsEF1'
import { TabsEMIT } from './components/TabsEM_IT'

export const ContentArea = ({
  state, title, value, isBtnDisabled, content,
  setValue, updateContentSelected
}: IContentAreaProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  const states = {
    [StateTypes.DEFAULT]: <TabsDefault {...{ title, value, content, setValue }} />,
    [StateTypes.EI]: <TabsEI {...{ title, value, content, setValue }} />,
    [StateTypes.EF1]: <TabsEF1 {...{ title, value, content, setValue }} />,
    [StateTypes.EM_IT]: <TabsEMIT {...{ title, value, content, setValue }} />
  }

  return (
    <>
      {states[state]}
      <div className={classes.selectArea}>
        <Button
          onClick={updateContentSelected}
          disabled={isBtnDisabled}
          data-testid='select_content_activity_or_evaluation_select'
          style={{
            width: `${useMediaQuery(theme.breakpoints.down('sm')) ? '100%' : 'unset'}`
          }}
        >
          {t('Selecionar')}
        </Button>
      </div>
    </>
  )
}
