import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

interface ICardFileHoverStylesProps {
  isHover: boolean
  isMobile: boolean
  isActive: boolean
}

export default makeStyles((theme: Theme) => createStyles({
  cardFileHoverContainer: {
    width: '47%',
    marginRight: 0,
    position: 'relative',
    marginBottom: theme.spacingStack.xxxs,
    [theme.breakpoints.up('sm')]: {
      width: '12rem',
      maxHeight: '8rem',
      minHeight: '8rem',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
      marginRight: theme.spacingInline.xxs,
      marginBottom: theme.spacingStack.xxs
    },
    [theme.breakpoints.down('sm')]: {
      height: '8rem'
    }
  },
  cardFileHoverContent: {
    border: 'none',
    borderRadius: theme.borderRadius.sm,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    objectFit: 'cover'
  },
  cardFileHoverInfoContent: {
    display: ({ isHover, isActive }: ICardFileHoverStylesProps) => {
      if (isHover || isActive) return 'inherit'
      return 'none'
    },
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    borderRadius: theme.borderRadius.sm,
    fontSize: theme.font.fontSize.xs,
    overflowWrap: 'anywhere',
    padding: theme.spacingInline.xxs,
    // opacity 64%
    backgroundColor: `${theme.colorBrand.dark}A3`,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInline.xxxs
    },

    '& svg': {
      color: theme.colors.neutral.lightBase,
      fontSize: theme.font.fontSize.xl
    }
  },
  cardFileHoverInfoContentMobile: {
    display: ({ isMobile }: ICardFileHoverStylesProps) => {
      if (isMobile) return 'inherit'
      return 'none'
    },
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  svgIconContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.light20,
    borderRadius: theme.borderRadius.sm
  },
  volumeUpContainer: {
    width: '79px',
    height: '78px',
    borderRadius: theme.borderRadius.circular,
    background: theme.colorBrand.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
