import { IAnswersRequest, getLevelIdByValue, IQuestionResponse, IUserSchoolProfileTypeEnum, ActivityControlStatusEnum, IAlternativeResponse } from 'services/types'
import RadioGroup from '@mui/material/RadioGroup'
import { Alternative } from './Alternative'
import { useAtom } from 'jotai'
import { answersAtom, isAnsweredAtom, activityStatusControlAtom, isStudentViewAtom } from '../../../../../../../../../../atomStore'
import OnlyProfile from 'components/common/OnlyProfile'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'

interface IMultiChoiceProps {
  question: IQuestionResponse
}

export const MultiChoice: React.FC<IMultiChoiceProps> = ({ question }) => {
  const [answers, setAnswers] = useAtom(answersAtom)
  const [isAnswered] = useAtom(isAnsweredAtom)
  const [activityStatusControl] = useAtom(activityStatusControlAtom)
  const [isStudentView] = useAtom(isStudentViewAtom)
  const { isActivitySuggestedType } = useStudentAnswer()

  const answer = answers.find(a => a.question_id === question.id?.toString())
  const record = answer?.records[0]
  const currentAlternative = record?.choice_record
  const currentAlternativeId = parseInt(currentAlternative?.alternative_id ?? '0')
  const alternatives = question.alternatives.filter(cur => cur.type === 'choice')

  const setAlternative = (alternativeId: string, alternatives: IAlternativeResponse[]) => {
    const selectedAlternative = alternatives.find(a => a.id?.toString() === alternativeId)
    const levelValue = selectedAlternative?.level ? selectedAlternative.level?.toString() : '0'
    const oldAnswers = answers.filter(a => a !== answer)
    const newAnswer: IAnswersRequest = {
      id: answer?.id?.toString(),
      question_id: question.id?.toString(),
      records: [{
        source_type: record?.source_type,
        id: record?.id,
        choice_record: {
          id: record?.choice_record?.id,
          alternative_id: alternativeId,
          level_id: getLevelIdByValue(levelValue)?.toString()
        }
      }]
    }

    setAnswers([...oldAnswers, newAnswer])
  }

  const renderAlternative = (alternative: IAlternativeResponse, idx: number) => {
    if (ActivityControlStatusEnum.RELEASED === activityStatusControl) {
      return (
        <Alternative
          key={idx}
          alternative={alternative}
          isActive={alternative.id === currentAlternativeId}
          isWrong={isAnswered && alternative.id === currentAlternativeId && alternative.level === 0}
          isCorrect={isAnswered && currentAlternative && alternative.level === 100}
          disabled={isAnswered}
          dataTestId={isActivitySuggestedType ? 'respond_activities_select_answer ' : 'respond_assessments_select_answer'} />
      )
    }

    return (
      <Alternative
        key={idx}
        alternative={alternative}
        isActive={alternative.id === currentAlternativeId}
        disabled={isAnswered}
        dataTestId={isActivitySuggestedType ? 'respond_activities_select_answer' : 'respond_assessments_select_answer'}
      />
    )
  }

  return (
    <RadioGroup
      aria-label='alternative'
      name='radio-buttons-group'
      sx={{ width: '100%' }}
      onChange={event => setAlternative(event.target.value, alternatives)}
    >
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]} >
        {
          alternatives.map(renderAlternative)
        }
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]} >
        {
          alternatives.map((alternative, idx) =>
            <Alternative
              key={idx}
              alternative={alternative}
              isActive={alternative.id === currentAlternativeId}
              isWrong={isAnswered && alternative.id === currentAlternativeId && alternative.level === 0}
              isCorrect={isAnswered && currentAlternative && alternative.level === 100}
              disabled={isAnswered}
              isStudentView={isStudentView}
            />)
        }
      </OnlyProfile>
    </RadioGroup>
  )
}

export default MultiChoice
