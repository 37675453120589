import { useEffect, useState } from 'react'
import { Ready } from './components'
import { Grid } from '@mui/material'
import { BusinessError } from 'navigation/BusinessError'
import { getClassSchedulesByContentUnit } from 'services/schedule'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { IScheduleResponse } from 'services/types'
import { Loading } from 'components/common'
import { reloadScheduleAtom } from 'navigation/components/MyCloe/components/ScheduleCreator/atomStore'
import { useAtom } from 'jotai'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

export const MyMaterialsTeacher: React.FC = () => {
  const { t } = useTranslation()

  const [reloadSchedule, setReloadSchedule] = useAtom(reloadScheduleAtom)

  const { contentId, classId } = useParams<{ contentId: string, classId: string }>()

  const [currentState, setCurrentState] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [schedules, setSchedules] = useState<IScheduleResponse[]>([])

  useEffect(() => {
    fetchMaterials()
  }, [contentId, classId])

  useEffect(() => {
    if (reloadSchedule) {
      fetchMaterials()
    }
  }, [reloadSchedule])

  const fetchMaterials = async () => {
    setCurrentState(StateMachineEnum.LOADING)
    if (!classId || !contentId) {
      return
    }

    const response = await getClassSchedulesByContentUnit({ classId, contentUnitId: contentId, type: 'original_content' })
    if (!response.success || !response.data) {
      setCurrentState(StateMachineEnum.ERROR)
      return
    }

    setSchedules(response.data)
    setCurrentState(StateMachineEnum.READY)
    setReloadSchedule(false)
  }

  const stateMachine = {
    IDLE: <></>,
    LOADING: <Loading type='linear' />,
    READY: <Ready schedules={schedules} />,
    ERROR: <BusinessError error={t('Falha ao carregar materiais')} />
  }

  return (
    <Grid container flexDirection={'column'}>
      {stateMachine[currentState]}
    </Grid>
  )
}
