import BaseScreen from 'pages/BaseScreen'
import ErrorBoundary from 'components/common/ErrorBoundary'
import AppMiddleware from 'navigation/AppMiddleware'
import { CloeProChat } from 'components/common'

export const PrivateRouteGroupWrapper: React.FC = ({ children }) => {
  return (
    <ErrorBoundary>
      <AppMiddleware>
        <BaseScreen>
          {children}
          <CloeProChat />
        </BaseScreen>
      </AppMiddleware>
    </ErrorBoundary>
  )
}
