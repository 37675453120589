export const ROOT = '/'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const RECOVER_LOGIN = '/recover-login'
export const RECOVER_PASSWORD = '/recover-password'
export const CHANGE_DEFAULT_PASSWORD = '/change-default-password'
export const RESET_PASSWORD = '/reset-password'
export const CONTENTS_STUDENT = '/contents'
export const TERMS_ACCEPTANCE = '/termsAcceptance'
export const TERMS_EXPANDED = '/terms/expanded'
export const STUDENT_TIMELINE = '/my-records'
export const STUDENT_SCHEDULE = '/schedule'
export const NO_PROFILE_FOUND = '/no-profile-found'
export const NO_ENABLED_SCHOOL_FOUND = '/no-enabled-school-found'
export const NO_SCHOOL_PERIOD_FOUND = '/no-school-period-found'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESULTS_TRACKING = '/results-tracking'

export const CONTENT_UNIT_STUDENT = (contentId?: string) =>
  `/content-unit/${contentId ?? ':contentId'}`

export const CONTENT_UNIT_STUDENT_CONTENT = (contentId?: string, sectionId?: string) =>
  `${CONTENT_UNIT_STUDENT(contentId)}/content/${sectionId ?? ':sectionId'}`

export const CONTENT_UNIT_STUDENT_ACTIVITIES = (contentId?: string) =>
  `${CONTENT_UNIT_STUDENT(contentId)}/activities`

export const CONTENT_UNIT_STUDENT_ACTIVITY = (contentId?: string, activityId?: string) =>
  `${CONTENT_UNIT_STUDENT_ACTIVITIES(contentId)}/${activityId ?? ':activityId'}`

export const CONTENT_UNIT_STUDENT_EXPEDITIONS = (contentId?: string) =>
  `${CONTENT_UNIT_STUDENT(contentId)}/expeditions`

export const CONTENT_UNIT_STUDENT_EXPEDITION = (contentId?: string, activityId?: string) =>
  `${CONTENT_UNIT_STUDENT_EXPEDITIONS(contentId)}/${activityId ?? ':activityId'}`

export const CONTENT_UNIT_STUDENT_EVALUATIONS = (contentId?: string) =>
  `${CONTENT_UNIT_STUDENT(contentId)}/evaluations`

export const CONTENT_UNIT_STUDENT_EVALUATION = (contentId?: string, evaluationId?: string) =>
  `${CONTENT_UNIT_STUDENT_EVALUATIONS(contentId)}/${evaluationId ?? ':evaluationId'}`

export const CONTENT_UNIT_TEACHER = (gradeTypeCode: string, gradeCode: string, classId?: string, contentId?: string) =>
  `/segment/${gradeTypeCode ?? ':gradeTypeCode'}/grade/${gradeCode ?? ':gradeCode'}/class/${classId ?? ':classId'}/content-unit/${contentId ?? ':contentId'}`

export const CONTENT_UNIT_TEACHER_CONTENT = (gradeTypeCode: string, gradeCode: string, classId?: string, contentId?: string, sectionId?: string) =>
  `${CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, contentId)}/content/${sectionId ?? ':sectionId'}`

export const CONTENT_UNIT_TEACHER_EXPEDITION_ONE = (gradeTypeCode: string, gradeCode: string, classId: string, contentId: string, activityId: string) =>
  `${CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, contentId)}/expeditions/${activityId ?? ':activityId'}`

export const CONTENT_UNIT_TEACHER_EXPEDITION = (gradeTypeCode: string, gradeCode: string, classId?: string, contentId?: string) =>
  `${CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, contentId)}/expedition`

export const CONTENT_UNIT_TEACHER_ACTIVITIES = (gradeTypeCode: string, gradeCode: string, classId?: string, contentId?: string) =>
  `${CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, contentId)}/activities`

export const CONTENT_UNIT_TEACHER_ACTIVITY = (gradeTypeCode: string, gradeCode: string, classId?: string, contentId?: string, activityId?: string) =>
  `${CONTENT_UNIT_TEACHER_ACTIVITIES(gradeTypeCode, gradeCode, classId, contentId)}/${activityId ?? ':activityId'}`

export const CONTENT_UNIT_TEACHER_EVALUATIONS = (gradeTypeCode: string, gradeCode: string, classId?: string, contentId?: string) =>
  `${CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, contentId)}/evaluations`

export const CONTENT_UNIT_TEACHER_EVALUATION = (gradeTypeCode: string, gradeCode: string, classId?: string, contentId?: string, evaluationId?: string) =>
  `${CONTENT_UNIT_TEACHER_EVALUATIONS(gradeTypeCode, gradeCode, classId, contentId)}/${evaluationId ?? ':evaluationId'}`

export const CONTENT_UNIT_RESOURCES = (contentId?: string, resourcesIds?: string) =>
  `/resource/${resourcesIds ?? ':resourcesIds'}/content-unit/${contentId ?? ':contentId'}`

export const LIBRARY_LEGACY = (postId?: string) =>
  `/u/library/${postId ?? ':postId'}`

export const TAUGHT_DISCIPLINES = '/taught-disciplines'
export const DASHBOARD_TEACHER = (gradeTypeCode: string, gradeCode: string, classId?: string) => `/segment/${gradeTypeCode ?? ':gradeTypeCode'}/grade/${gradeCode ?? ':gradeCode'}/class/${classId ?? ':classId'}`

export const SINGLE_RECORD = (gradeTypeCode: string, gradeCode: string, classId?: string) => `/segment/${gradeTypeCode ?? ':gradeTypeCode'}/grade/${gradeCode ?? ':gradeCode'}/class/${classId ?? ':classId'}/single-record`
export const EDIT_SINGLE_RECORD = (gradeTypeCode: string, gradeCode: string, classId?: string, classRecordId?: string | number) => `${SINGLE_RECORD(gradeTypeCode, gradeCode, classId)}/edit/${classRecordId ?? ':classRecordId'}`

export const MILESTONE = (gradeTypeCode: string, gradeCode: string, classId?: string) => `/segment/${gradeTypeCode ?? ':gradeTypeCode'}/grade/${gradeCode ?? ':gradeCode'}/class/${classId ?? ':classId'}/milestone`
export const MILESTONES_BY_ID = (gradeTypeCode: string, gradeCode: string, classId?: string, milestoneId?: string) => `/segment/${gradeTypeCode ?? ':gradeTypeCode'}/grade/${gradeCode ?? ':gradeCode'}/class/${classId ?? ':classId'}/milestone/${milestoneId ?? ':milestoneId'}`

export const CLOE_ADMIN = process.env.REACT_APP_ADMIN_PROJECT as string

export const PENDING_ACTIVITIES = (gradeTypeCode?: string, gradeCode?: string, classId?: string) => `/segment/${gradeTypeCode ?? ':gradeTypeCode'}/grade/${gradeCode ?? ':gradeCode'}/class/${classId ?? ':classId'}/pending-activities`

export const PARENTAL_MESSAGES = (disciplineId?: string) =>
  `/parental-messages/discipline/${disciplineId ?? ':disciplineId'}`

export const SSO = '/sso/:jwt'
export const SSO_V2 = '/v2/sso'

export const GO_TO_ACTIVITY = '/go_to_activity'
export const GO_TO_RESOURCE = '/go_to_resource'

export const PROGRAM_CONTENT = '/program_content'

export const ENABLE_FEATURE_TOGGLE = (featureToggle?: string) => `/enable-feature-toggle/${featureToggle ?? ':featureToggle'}`
