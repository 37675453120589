import { convertArrToGETParams } from 'utils/url'

export const SCHEDULES_TOTALS_BY_RANGE = (
  classId: string,
  startDate: string,
  endDate: string,
  disciplineId: string | number | null | undefined
) => {
  const discipline = disciplineId ? `&discipline=${disciplineId}` : ''
  return `${process.env.REACT_APP_BACKEND_URL as string}/classes/${classId}/schedules/totals-by-range?startDate=${startDate}&endDate=${endDate}${discipline}`
}

export const SCHEDULES_BY_DAY = (
  classId: string,
  date: string,
  disciplineId: string | number | null | undefined,
  utcOffset: string
) => {
  const discipline = disciplineId ? `&discipline=${disciplineId}` : ''
  return `${process.env.REACT_APP_BACKEND_URL as string}/classes/${classId}/schedules/by-day/${date}?${discipline}&utcOffset=${utcOffset}`
}

export const CONTENT_UNIT_SCHEDULE = (
  classId: string,
  disciplineId: string,
  title?: string
) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/classes/${classId}/schedules/content-units?discipline=${disciplineId}`

export const CONTENT_UNITS_SCHEDULE = (classId: string, contentUnitId: string) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/classes/${classId}/schedules/content-units/${contentUnitId}`

export const SCHEDULE_BY_ID = (scheduleId: string) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/schedules/${scheduleId}`

export const CREATE_SCHEDULE_EXISTING_CONTENT = () =>
  `${process.env.REACT_APP_BACKEND_URL as string}/schedules/existing-content`

export const CREATE_SCHEDULE_ORIGINAL_CONTENT = () =>
  `${process.env.REACT_APP_BACKEND_URL as string}/schedules/original-content`

export const UPDATE_SCHEDULE_EXISTING_CONTENT = (scheduleId: string) =>
  `${process.env.REACT_APP_BACKEND_URL as string
  }/schedules/existing-content/${scheduleId}`

export const UPDATE_SCHEDULE_ORIGINAL_CONTENT = (scheduleId: string) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/schedules/original-content/${scheduleId}`

export const GET_CLASS_SCHEDULES_BY_CONTENT_UNIT = (classId: string | number, contentUnitId: string | number, type?: 'existing_content' | 'original_content', limit?: number) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/classes/${classId}/content-units/${contentUnitId}/schedules`
  const paramsObj = { type, limit }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const GET_PENDENT_ACTIVITIES_TEACHER = (
  classId: string | number,
  disciplineId?: string | number,
  offset?: string | number,
  limit?: string | number
) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/activities/teacher/class/${classId}`
  const paramsObj = { disciplineId, offset, limit }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const GET_PENDENT_ACTIVITIES_STUDENT = (
  classId: string | number,
  disciplineId?: string | number,
  offset?: string | number,
  limit?: string | number
) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/activities/student/class/${classId}`
  const paramsObj = { disciplineId, offset, limit }

  return convertArrToGETParams({ rawURL, paramsObj })
}
