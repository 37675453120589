import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from './App'
import CssBaseline from '@mui/material/CssBaseline'
import './locales/i18n'
import './index.css'

if (process?.env?.REACT_APP_GOOGLE_ANALITYCS && process?.env?.REACT_APP_GOOGLE_ANALITYCS4) {
  ReactGA.initialize(
    [{ trackingId: process?.env?.REACT_APP_GOOGLE_ANALITYCS }, { trackingId: process?.env?.REACT_APP_GOOGLE_ANALITYCS4 }],
    { debug: false }
  )
  ReactGA.pageview(window.location.pathname + window.location.search)
}

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
