import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    returnLink: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: theme.colors.support.colorSupport02,
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.semibold,
      cursor: 'pointer',
      '& span': {
        marginLeft: theme.spacingInline.nano
      }
    },
    alertError: {
      color: `${theme.colors.negative.feedbackDarkest}!important`,
      fontSize: `${theme.font.fontSize.xxs}!important`,
      fontWeight: `${theme.font.fontWeight.semibold}!important`,
      padding: '0px 8px!important',
      textAlign: 'left',
      '& .MuiAlert-icon': {
        margin: '0px 8px'
      },
      '& div': {
        lineHeight: `${theme.spacingInline.xxxs}!important`
      }
    },
    alertInfo: {
      fontSize: `${theme.font.fontSize.xxs}!important`,
      fontWeight: `${theme.font.fontWeight.semibold}!important`,
      color: `${theme.colors.information.feedbackDarkest}!important`,
      padding: '0px 8px!important',
      textAlign: 'left',
      '& .MuiAlert-icon': {
        marginRight: '6px'
      },
      '& div': {
        lineHeight: `${theme.spacingInline.xxxs}!important`
      }
    },
    alertSuccess: {
      color: `${theme.colors.positive.feedbackDarkest}!important`,
      fontSize: `${theme.font.fontSize.xxs}!important`,
      fontWeight: `${theme.font.fontWeight.semibold}!important`,
      padding: '8px 6px!important',
      textAlign: 'left',
      '& .MuiAlertTitle-root': {
        fontWeight: `${theme.font.fontWeight.medium}!important`
      },
      '& .MuiAlert-message span': {
        fontWeight: `${theme.font.fontWeight.semibold}!important`
      },
      '& .MuiAlert-icon': {
        marginRight: '8px'
      },
      '& div': {
        lineHeight: `${theme.spacingInline.xxxs}!important`
      }
    },
    backButton: {
      color: theme.colors.support.colorSupport03,
      '& svg': {
        marginRight: '8px'
      }
    },
    brand: {
      paddingBottom: theme.spacingStack.nano
    },
    buttonsContainer: {
      marginTop: theme.spacingInline.xs,

      '& button:not(:last-child)': {
        marginBottom: '16px'
      }
    },
    cancelButton: {
      width: '100%',
      color: theme.colors.support.colorSupport02,
      fontSize: theme.font.fontSize.xs,
      '&.ghost': {
        color: theme.colors.support.colorSupport02,
        '&:hover': {
          background: theme.colorBrand.lightest
        }
      },
      '& svg': {
        marginRight: '8px'
      }
    },
    copyButton: {
      color: theme.colors.support.colorSupport01,
      '& svg': {
        marginRight: '8px'
      }
    },
    enterButton: {
      width: '100%',
      background: `${theme.colors.support.colorSupport01} !important`,
      border: `1px solid ${theme.colors.support.colorSupport01} !important`,
      '&:hover': {
        background: '#602680 !important',
        border: '1px solid #602680 !important'
      }
    },
    formIconBack: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacingStack.xxs,
      color: theme.colors.neutral.light40,
      cursor: 'pointer',
      fontSize: theme.font.fontSize.sm
    },
    formText: {
      margin: `${theme.spacingInline.xxxs} auto 0 auto`
    },
    formTitle: {
      color: theme.colors.neutral.dark40,
      width: '295px',
      margin: '0 auto'
    },
    grayBox: {
      background: '#F7F7F7',
      borderRadius: theme.borderRadius.md,
      padding: '0px 16px 16px 16px'
    },
    input: {
      width: '100%',
      textAlign: 'left'
    },
    modalCancelButton: {
      color: theme.colors.warning.feedbackDarkest,
      width: '100%',
      '&.outlined': {
        borderColor: theme.colors.warning.feedbackDarkest,
        color: theme.colors.warning.feedbackDarkest,
        '&:hover': {
          background: theme.colors.warning.feedbackLightest
        }
      }
    },
    modalDescription: {
      color: theme.colors.warning.feedbackDarkest,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: theme.font.lineHeight.xs,
      textAlign: 'center'
    },
    modalSubmitButton: {
      width: '100%',
      background: theme.colors.warning.feedbackDark,
      '&.primary': {
        background: theme.colors.warning.feedbackDark,
        '&:hover': {
          background: theme.colors.warning.feedbackDarkest
        }
      }
    },
    modalTitle: {
      alignItems: 'center',
      color: theme.colors.warning.feedbackDark,
      display: 'flex',
      fontSize: theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.medium,
      letterSpacing: '0.01em',
      lineHeight: theme.font.lineHeight.xs,
      textAlign: 'center'
    },
    messageError: {
      color: 'red',
      fontSize: theme.font.fontSize.xs
    },
    purpleTitle: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold,
      color: theme.colors.support.colorSupport03,
      '& svg': {
        color: theme.colors.support.colorSupport01,
        fontSize: theme.font.fontSize.sm
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.xs,
        '& svg': {
          fontSize: theme.font.fontSize.xs
        }
      }
    },
    description: {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.support.colorSupport03,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.xs
      }
    },
    readOnlyInput: {
      width: '100%',
      textAlign: 'left',
      '& .MuiInputLabel-root': {
        color: `${theme.colors.support.colorSupport03}!important`,
        fontWeight: `${theme.font.fontWeight.medium}!important`
      },
      '& .MuiInputBase-input': {
        color: `${theme.colors.support.colorSupport03}!important`
      },
      '& fieldset': {
        background: 'rgba(128, 51, 170, 0.12)',
        border: '2px solid rgba(128, 51, 170, 0.4)!important'
      }
    },
    submitButton: {
      width: '100%',
      '&.active': {
        background: `${theme.colors.support.colorSupport01} !important`,
        border: `1px solid ${theme.colors.support.colorSupport01} !important`
      },
      '&:hover': {
        background: '#602680 !important',
        border: '1px solid #602680 !important'
      }
    },
    title: {
      lineHeight: theme.font.lineHeight.sm
    },
    token: {
      letterSpacing: '0.2em'
    }
  }
})
