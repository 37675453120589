/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { ActivityControlStatusEnum } from 'services/types'

export const ACTIVITY = (id: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/activities/${id}`

export const ACTIVITY_CLONE = (id: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/activities/${id}/clone`

export const ACTIVITY_BY_CLASS = (classId: string | number, id: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/v1/class/${classId}/activities/${id}`

export const ACTIVITIES_FROM_CONTENT_ID_ALL_TYPES = (classId: string | number, contentId: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/activities/${classId}/content/${contentId}`

export const ACTIVITIES_FROM_CONTENT_ID = (classId: string | number, contentId: string | number, activityType: string | number, activityStatus: ActivityControlStatusEnum | ActivityControlStatusEnum[] | null) => {
  let activityStatusControlQuery: string = ''
  if (activityStatus) {
    if (activityStatus instanceof Array) {
      activityStatusControlQuery = '?'
      activityStatus.forEach(s => {
        activityStatusControlQuery = `${activityStatusControlQuery}activity_status_control=${s?.toString() as string}&`
      })
    } else {
      activityStatusControlQuery = `&activity_status_control=${activityStatus?.toString()}`
    }
  }

  // return `${process.env.REACT_APP_BACKEND_URL as string}/activities?content_unit.id=${contentId}&suggested_application_type=${activityType}`
  return `${process.env.REACT_APP_BACKEND_URL as string}/activities/${classId}/content/${contentId}?suggested_application_type=${activityType}${activityStatusControlQuery}`
}

export const ACTIVITY_STATUS_CONTROL_BY_CLASS = (id: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/classes/${id}/activity-status-controls`

export const ACTIVITY_STATUS_CONTROL_UPDATE = (id: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/classes/${id}/activity-status-controls`

export const ACTIVITIES_SCHOOLS_CLASSES = (activityId: string | number, contentUnit: string | number, gradeCode: string | number, schoolPeriod: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/activities/activities-schools-classes/${activityId}/content/${contentUnit}/grade/${gradeCode}/school_period/${schoolPeriod}`

export const ACTIVITY_BY_CONTENT_UNIT = (classId: string | number, contentUnit: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/v1/activities/${classId}/content/${contentUnit}`
