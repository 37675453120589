import Grid from '@mui/material/Grid'
import Index from 'pages/ContentUnit/components/Content/SectionsIndex'
import IndexMobile from 'pages/ContentUnit/components/Content/SectionsIndexMobile'
import { OfflineActivityWarning } from '../../OfflineActivityWarning'

export const ExpeditionHeader = () => {
  return (
    <>
      <Grid item xs={12}>
        <Index />
      </Grid>
      <Grid item xs={12}>
        <IndexMobile />
      </Grid>
      <OfflineActivityWarning />
    </>
  )
}

export default ExpeditionHeader
