import { ActivityControlStatusEnum, IActivityResponse } from 'services/types'

export const getActivitiesFilteredByStatusForTeacher = (activities: IActivityResponse[]) => ({
  available: filterActivitiesByStatus(activities, [ActivityControlStatusEnum.AVAILABLE]),
  inProgress: filterActivitiesByStatus(activities, [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.DONE]),
  correction: filterActivitiesByStatus(activities, [ActivityControlStatusEnum.CLOSED]),
  completed: filterActivitiesByStatus(activities, [ActivityControlStatusEnum.RELEASED])
})

export const getActivitiesFilteredByStatusForStudent = (activities: IActivityResponse[]) => ({
  available: filterActivitiesByStatus(activities, [ActivityControlStatusEnum.AVAILABLE]),
  pending: filterActivitiesByStatus(activities?.filter(a => !a.activity_status?.has_answer), [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.CLOSED, ActivityControlStatusEnum.RELEASED]),
  answered: filterActivitiesByStatus(activities?.filter(a => a.activity_status?.has_answer), [ActivityControlStatusEnum.DONE, ActivityControlStatusEnum.CLOSED]),
  corrected: filterActivitiesByStatus(activities?.filter(a => a.activity_status?.has_answer), [ActivityControlStatusEnum.RELEASED])
})

export const filterActivitiesByStatus = (activities: IActivityResponse[], status: ActivityControlStatusEnum[]) => {
  return activities.filter(a => filterActivityByStatus(a, status)) ?? []
}

export const filterActivityByStatus = (activity: IActivityResponse, status: ActivityControlStatusEnum[]) => {
  if (!!activity.activity_status && status.includes(activity.activity_status?.status)) {
    return true
  }
  return false
}
