import * as SvgIcons from '@mui/icons-material'
import { Button, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import React from 'react'
import { MY_CLOE_CONTEXT, myCloeContextAtom } from '../../../atomStore'
import useStyles from './style'

import { IContentSelectProps, ContentEnum } from './types'

export const ContentSelect = ({ eventObject, contentSelect, status, description, handleOpenModal, isShowItems, setIsShowItems }: IContentSelectProps) => {
  const inputRef = React.createRef<HTMLDivElement>()
  const inputRefExpand = React.createRef<HTMLDivElement>()
  const inputRefRetract = React.createRef<HTMLDivElement>()
  const theme = useTheme()
  const classes = useStyles()
  const [myCloeContext] = useAtom(myCloeContextAtom)
  const { t } = useTranslation()

  const isButtonDisabled = !eventObject?.content_unit || myCloeContext !== MY_CLOE_CONTEXT.HOME || eventObject?.type === 'existing_content'

  const contentIcon = {
    reading: (
      <SvgIcons.DescriptionOutlined
        style={{
          fontSize: theme.font.fontSize.xxl,
          color: theme.colorBrand.medium
        }}
      />
    ),
    activity: (
      <SvgIcons.ExtensionOutlined
        style={{
          fontSize: theme.font.fontSize.xxl,
          color: theme.colorBrand.medium
        }}
      />
    ),
    evaluation: (
      <SvgIcons.BallotOutlined
        style={{
          fontSize: theme.font.fontSize.xxl,
          color: theme.colorBrand.medium
        }}
      />
    ),
    guide: (
      <SvgIcons.BorderColorOutlined
        style={{
          fontSize: theme.font.fontSize.xxl,
          color: theme.colorBrand.medium
        }}
      />
    )
  }

  const onClick = () => {
    handleOpenModal()
    inputRef?.current?.click()
  }

  return (
    <>
      <div ref={inputRefExpand} data-testid='event_with_expedition_expand_list' />
      <div ref={inputRefRetract} data-testid='event_with_expedition_retract_list' />

      {(contentSelect ?? []).length > 1 && (
        <Grid item
          display='flex'
          flexDirection='row'
          gap={0.5}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            if (isShowItems) {
              inputRefRetract?.current?.click()
            } else {
              inputRefExpand?.current?.click()
            }
            setIsShowItems(!isShowItems)
          }}>
          <span className={classes.txtTitle}>Artigos, atividades e avaliações</span>
          <div className={classes.badge}>{(contentSelect ?? []).length}</div>
          {isShowItems ? <SvgIcons.KeyboardArrowUp /> : <SvgIcons.KeyboardArrowDown />}
        </Grid>
      )}

      {(contentSelect ?? []).length === 0 && (
        <>
          <div ref={inputRef} data-testid='event_with_expedition_select_content_activity_or_evaluation' />
          <Button
            sx={{
              textTransform: 'none',
              padding: '0px',
              textAlign: 'left',
              gap: '8px'
            }}
            onClick={onClick}
            fullWidth
            disabled={isButtonDisabled}
          >
            <Grid
              container
              sx={{
                minHeight: '56px',
                border:
                  status === ContentEnum.filled
                    ? `solid 2px ${theme.colorBrand.medium}`
                    : `solid 2px ${theme.colors.neutral.light30}`,
                borderRadius: theme.borderRadius.sm,
                display: 'grid'
              }}
              gap={'8px'}
              alignContent={'center'}
              alignSelf={'center'}
              gridTemplateColumns={'auto 1fr'}
            >
              <Grid
                item
                alignSelf={'center'}
                display={'flex'}
                paddingLeft={theme.spacingInline.nano}
              >
                <SvgIcons.DescriptionOutlined
                  style={{
                    fontSize: theme.font.fontSize.xxl,
                    color: !eventObject?.content_unit ? theme.colors.neutral.light30 : theme.colorBrand.medium
                  }}
                />
              </Grid>
              <Grid
                item
                alignSelf='center'
                paddingY={theme.spacingInline.nano}
                sx={{
                  overflow: 'hidden',
                  marginRight: '16px'
                }}
              >
                <span className={`${classes.text} ${!eventObject?.content_unit && 'disabled'}`}>
                  {description ?? t('Selecionar conteúdo, atividade ou avaliação')}
                </span>
              </Grid>
            </Grid>
          </Button>
        </>
      )}

      {isShowItems && (contentSelect ?? []).map((content, index) => (
        <Button
          sx={{
            textTransform: 'none',
            padding: '0px',
            textAlign: 'left',
            gap: '8px'
          }}
          onClick={onClick}
          fullWidth
          key={index}
          disabled={isButtonDisabled}
        >
          <Grid
            container
            sx={{
              minHeight: '56px',
              border:
                status === ContentEnum.filled
                  ? `solid 2px ${theme.colorBrand.medium}`
                  : `solid 2px ${theme.colors.neutral.light30}`,
              borderRadius: theme.borderRadius.sm,
              display: 'grid'
            }}
            gap={'8px'}
            alignContent={'center'}
            alignSelf={'center'}
            gridTemplateColumns={'auto 1fr'}
          >
            <Grid
              item
              alignSelf={'center'}
              display={'flex'}
              paddingLeft={theme.spacingInline.nano}
            >
              {content?.type && contentIcon[content.type]}
            </Grid>
            <Grid
              item
              alignSelf='center'
              paddingY={theme.spacingInline.nano}
              sx={{
                overflow: 'hidden',
                marginRight: '16px'
              }}
            >
              <span className={`${classes.text} ${!eventObject?.content_unit && 'disabled'}`}>
                {content.title}
              </span>
            </Grid>
          </Grid>
        </Button>
      ))}
    </>
  )
}
