import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    outline: 'none',
    position: 'absolute',
    top: '46%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '55%',
    minHeight: '240px',
    maxHeight: 'calc(100vh - 140px)',
    width: '55%',
    display: 'grid',
    gridTemplateRows: 'auto 10fr 1fr',
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.sm,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 10fr 1fr',
      transform: 'unset',
      top: '0',
      borderRadius: '0px',
      left: '0',
      maxWidth: 'unset',
      maxHeight: '100vh'
    }
  },
  content: {
    padding: theme.spacingInline.xxs,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInline.xxxs
    }
  },
  contentStep: {
    width: '100%',
    minHeight: '15rem',
    height: 'auto',
    paddingRight: theme.spacingInline.nano,
    // ...theme.scroll,

    [theme.breakpoints.down('sm')]: {
      minHeight: '90%',
      height: '90%'
    }
  },
  txtTitle: {
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold
    }
  },
  txtTitleConfirmation: {
    color: theme.colorBrand.dark,
    fontSize: theme.font.fontSize.xl,
    fontWeight: theme.font.fontWeight.medium,
    textAlign: 'center',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.lg
    }
  },
  customButton: {
    marginTop: `${theme.spacingStack.xxs} !important`,
    width: '100%',
    border: `1px solid ${theme.colors.neutral.light20}`,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInline.xxxs,
    cursor: 'pointer',

    '& span': {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.bold,
      color: theme.colors.neutral.dark30
    }
  },
  customButtonSelected: {
    marginTop: `${theme.spacingStack.xxs} !important`,
    width: '100%',
    border: `1px solid ${theme.colorBrand.medium}`,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInline.xxxs,
    cursor: 'pointer',

    '& span': {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.bold,
      color: theme.colorBrand.medium
    }
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.colors.neutral.light10}`,
    padding: theme.spacingInline.xxs,

    [theme.breakpoints.down('sm')]: {
      borderTop: 0,
      paddingInline: theme.spacingInline.xxxs,
      position: 'absolute',
      bottom: '4rem'
    }
  },
  infoCountStep: {
    top: theme.spacingInline.nano,
    right: theme.spacingInline.xxs,
    position: 'absolute',

    '& span': {
      color: theme.colors.neutral.light30,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium
    }
  },
  infoCountStepMobile: {
    padding: theme.spacingInline.xxs,
    paddingBottom: 0,
    paddingLeft: theme.spacingInline.nano,
    color: theme.colorBrand.dark,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,

    '& span': {
      color: theme.colors.neutral.light30,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium
    }
  },
  infoClassName: {
    marginBottom: `${theme.spacingStack.sm} !important`,
    '& span': {
      color: theme.colorBrand.medium,
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.semibold
    },
    '& svg': {
      fontSize: theme.font.fontSize.xl,
      color: theme.colorBrand.medium
    }
  },
  backgroundImage: {
    backgroundSize: 'contain',
    maxWidth: '19rem',
    maxHeight: '30rem',
    height: '19rem',
    width: '100%',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      width: '70%',
      height: '14rem'
    }
  }
}))
