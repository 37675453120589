import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    marginTop: '64px', // mobile header
    padding: theme.spacingInset.sm,
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    height: 'auto',
    display: 'flex',

    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      boxShadow: theme.shadowLevels.level5
    }
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium,
    padding: `${theme.spacingInline.xxxs} 0px ${theme.spacingInline.xxxs} 0px`,
    fontSize: theme.font.fontSize.sm,
    '& svg': {
      marginRight: theme.spacingInline.nano
    }
  },
  button: {
    marginBlock: `${theme.spacingInline.xxxs} !important`
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    width: '100%'
  }
}))
