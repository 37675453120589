import React from 'react'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import { BallotOutlined, DescriptionOutlined } from '@mui/icons-material'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import NaturalCompare from 'string-natural-compare'

import { ContentTypeEnum } from 'services/types/content'
import { TabPanel } from 'components/design-system'
import { BusinessError } from 'navigation/BusinessError'

import useStyles, { TabsList } from '../style'

import { DisciplineCodeEnum } from 'services/types'
import { ITabsProps } from '../types'

import { CustomCardSelector, CustomTitle, RenderedList } from '.'

export const TabsEMIT = ({
  title, value, content, setValue
}: ITabsProps) => {
  const classes = useStyles()
  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)

  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const navLinks = [
    {
      id: 'aulas',
      component: <span><DescriptionOutlined /> <span>{t('Aulas')}</span></span>,
      filteredDidatic: content.didactic_content.sections
    },
    {
      id: 'avaliacoes',
      component: <span><BallotOutlined /> <span>{t('Exercícios')}</span></span>,
      filteredEvaluation: (content.activities ?? []).filter(a => a.suggested_application_type === 'evaluation').map(cur => {
        cur.title = cur.title ?? cur.activity_name
        cur.title = cur.title.trim()
        return cur
      }).sort((a, b) => NaturalCompare(a.title, b.title, { caseInsensitive: true }))
    }
  ]

  return (
    <TabsUnstyled defaultValue={0}>
      <TabsList className={classes.tab} >
        {navLinks.map((navLink, i) => (
          <div
            key={i}
            className={`tab-link ${classes.tabLink} ${value - 1 === i && 'is-before'} ${value + 1 === i && 'is-after'} ${value === i && 'selected'}`}
            onClick={() => setValue(i)}
          >
            {navLink.component}
          </div>
        ))}
      </TabsList>
      {
        navLinks.map((navLink, teacheri) =>
          <React.Fragment key={teacheri}>
            {navLink.filteredDidatic && (
              <TabPanel value={value} index={0}>
                <div className={classes.renderedList}>
                  <CustomTitle title={title} description={t('Selecione qual seção você deseja compartilhar com a turma', true)} />
                  {navLink.filteredDidatic?.map((reading, i) => <CustomCardSelector key={i} type={ContentTypeEnum.reading} title={reading.title ?? reading.text ?? ''} cardId={reading.id?.toString()} showTypeTag />)}
                </div>
              </TabPanel>
            )}
            {navLink.filteredEvaluation?.length && (
              <TabPanel value={value} index={navLinks.length > 2 ? 2 : 1}>
                {
                  !navLink.filteredEvaluation?.length
                    ? <div className={classes.errorWrapper}><BusinessError error={t('Opa, parece que não existem conteúdos cadastrados nessa seção!', true)} /></div>
                    : <RenderedList filtered={navLink.filteredEvaluation ?? []} title={title} description={t('Selecione qual avaliação você deseja compartilhar com a turma', true)} showTypeTag={false} />
                }
              </TabPanel>
            )}
          </React.Fragment>)
      }
    </TabsUnstyled>
  )
}
