import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'

import { Grid, useTheme } from '@mui/material'
import EventNoteIcon from '@mui/icons-material/EventNote'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'

import { Text, Icon } from 'components/design-system'
import { DropdownSelector, ConfirmationModal } from 'components/common'

import { ISchoolPeriodResponse } from 'services/types'

import useStyle from './styles'

export const SchoolPeriodSelector = () => {
  const { profile, session, schoolPeriod, setSchoolPeriod, isQuickAccessOpen } = useStore()

  const [selectedSchoolPeriod, setSelectedSchoolPeriod] = useState<ISchoolPeriodResponse | null>(schoolPeriod)
  const [activeModalConfirmation, setActiveModalConfirmation] = useState<boolean>(false)
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false)

  const theme = useTheme()
  const { t } = useTranslation()

  const classes = useStyle()

  const selectedSamePeriods = selectedSchoolPeriod?.id === schoolPeriod?.id

  const filterInactivePeriodsWithClasses = (period: ISchoolPeriodResponse) => {
    if (period.current) return true

    return session?.subscriptions.some((subscription) => subscription.class.school_period === period.id)
  }
  const availableSchoolPeriods = profile?.school.school_periods?.filter(filterInactivePeriodsWithClasses)

  const handleSelectPeriod = (selectedPeriod: ISchoolPeriodResponse) => {
    setSelectedSchoolPeriod(selectedPeriod)
  }

  const handleConfirmSelector = () => {
    if (!selectedSchoolPeriod?.title) return

    if (schoolPeriod?.current && !selectedSchoolPeriod.current) {
      setActiveModalConfirmation(true)
      return
    }

    handleModalConfirm()
  }

  const handleCloseModal = () => {
    setActiveModalConfirmation(false)
  }

  const handleBackSelector = () => {
    setIsSelectorOpen(oldIsSelectorOpen => !oldIsSelectorOpen)
    if (!selectedSamePeriods) {
      setSelectedSchoolPeriod(schoolPeriod)
    }
  }

  const handleModalConfirm = () => {
    setActiveModalConfirmation(false)
    setIsSelectorOpen(false)

    if (!selectedSchoolPeriod) return

    setSchoolPeriod(selectedSchoolPeriod)
  }

  const renderGroups = useCallback(() => {
    if (!availableSchoolPeriods) return <></>

    const schoolPeriodsGroups = [
      {
        title: t('Ano Atual'),
        icon: <EventNoteIcon />,
        periods: availableSchoolPeriods.filter(period => period.current),
        className: 'currentPeriodsContainer'
      }
    ]

    const previousPeriods = availableSchoolPeriods.filter(period => !period.current)

    if (previousPeriods.length) {
      schoolPeriodsGroups.push(
        {
          title: t('Anos anteriores'),
          icon: <EventRepeatIcon />,
          periods: previousPeriods,
          className: 'previousPeriods'
        }
      )
    }

    return schoolPeriodsGroups.map(({ periods, ...group }, index) => (
      <Grid key={index} className={`${classes.groupContainer} ${group.className}`}>
        <Grid className={'groupHeader'}>
          <Icon size='medium'>
            {group.icon}
          </Icon>
          <Text className={classes.groupTitle}>{group.title}</Text>
        </Grid>

        <Grid>
          {
            periods.map((period) => {
              const isActive = schoolPeriod?.id === period.id
              const isFocused = selectedSchoolPeriod?.id === period.id

              return (
                <a
                  key={`SchoolPeriod-${period.id}`}
                  className={`${classes.optionContainer} ${group.className} ${isActive && 'active'} ${isFocused && 'focused'}`}
                  onClick={() => handleSelectPeriod(period)}
                  data-testid='my_classes_select_year'
                >
                  {period.title}
                </a>
              )
            })
          }
        </Grid>
      </Grid>
    ))
  }, [profile, selectedSchoolPeriod, schoolPeriod, isQuickAccessOpen])

  return (
    <>
      <DropdownSelector
        placeholder={schoolPeriod?.title ?? t('Selecione o período escolar')}
        onConfirmLabel={availableSchoolPeriods?.length !== 1 ? t('Confirmar troca') : undefined}
        onConfirm={handleConfirmSelector}
        disableOnConfirm={selectedSamePeriods}
        colorTheme={schoolPeriod?.current === false ? theme.colors.contentType.default : theme.colorBrand.medium}
        label={schoolPeriod?.current ? t('atual') : t('anterior')}
        isOpen={isSelectorOpen}
        className={classes.dropdownContainer}
        setIsOpen={setIsSelectorOpen}
        dataTestId={isSelectorOpen ? 'my_classes_retract_year_selection' : 'my_classes_expand_year_selection'}
        confirmDataTestId={'my_classes_confirm_year_change'}
        backButtonLabel={t('Ano escolar')}
        handleSelectorClick={handleBackSelector}
      >
        <Grid className={classes.container}>
          {renderGroups()}
        </Grid>
      </DropdownSelector >

      <ConfirmationModal
        onConfirmModal={handleModalConfirm}
        onCloseModal={handleCloseModal}
        isOpen={activeModalConfirmation}
        highLightedText={selectedSchoolPeriod?.title ?? ''}
        title={t('Deseja ser redirecionado para o ano escolar abaixo?')}
        description={t('O modo de visualização de anos escolares anteriores não permite a edição de nenhum item.')}
        confirmLabel={t('Ir para ano')}
        confirmDatatestId={'confirm_year_change_confirm'}
        cancelDatatestId={'confirm_year_change_cancel'}
      />
    </>
  )
}
