import Storage from 'utils/storage'
import { PipeHeaders } from '../../'

export const makeRequestOptions = (pipeHeaders?: PipeHeaders | null, hasContentImages?: boolean) => {
  const options = {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${Storage.token.get()}`
    })
  }

  if (!hasContentImages) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { user_school_profile } = Storage.props.getSubscription()
    options.headers.append('userschoolprofile', user_school_profile.id)
    options.headers.append('Host', location.origin)
  }

  if (pipeHeaders) {
    for (const [headerKey, headerValue] of Object.entries(pipeHeaders)) {
      options.headers.set(headerKey, headerValue)
    }
  }

  return options
}
