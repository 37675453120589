import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'

import { IStudentResponse } from 'services/types'
import { getClassStudents } from 'services/class'
import { normalizeString } from 'utils/regex'

import { selectedStudentsModalAtom } from '../atomStore'
import { isStudentSelected, removeStudent } from '../utils'
import SearchAndSelect from './SearchAndSelect'

const SearchAndSelectContainer = () => {
  const [selectedStudents, setSelectedStudents] = useAtom(selectedStudentsModalAtom)

  const [students, setStudents] = useState<IStudentResponse[]>([])
  const [displayedStudents, setDisplayedStudents] = useState<IStudentResponse[]>([])
  const [search, setSearch] = useState('')

  const { classId } = useParams<{ classId: string }>()

  const getStudents = async () => {
    const { data } = await getClassStudents(classId)
    setStudents(data)
  }

  const filterStudents = () => {
    const normalizedSearch: string = normalizeString(search)
    const filteredStudents = students.filter(student => {
      return new RegExp(`${normalizedSearch}`, 'i').test(normalizeString(`${student.user_id.name} ${student.user_id.surname}`))
    })
    setDisplayedStudents(filteredStudents)
  }

  useEffect(() => {
    getStudents()
  }, [])

  useEffect(() => {
    if (search?.length >= 3) {
      filterStudents()
    } else {
      setDisplayedStudents([])
    }
  }, [search])

  return (
    <SearchAndSelect
      students={displayedStudents}
      isStudentSelected={isStudentSelected}
      selectedStudents={selectedStudents}
      setSelectedStudents={setSelectedStudents}
      removeStudent={removeStudent}
      search={search}
      setSearch={setSearch}
    />
  )
}

export default SearchAndSelectContainer
