import { } from './dtos/IExpeditionCached'
import Dexie from 'dexie'

import { ExpeditionCachedTable, AnswerCacheTable } from '.'

class DatabaseInstance extends Dexie {
  expeditionsCached!: Dexie.Table<ExpeditionCachedTable, number>
  answersCached!: Dexie.Table<AnswerCacheTable, number>

  constructor () {
    super('cloe-db')
    this.version(1).stores({
      expeditionsCached: 'id++, expeditionId, status, successUrls, errorUrls, size, embeddedUrls, createdAt, cachedAt',
      answersCached: 'id++, requestAnswer, status, filesOffline, errorReason'
    })
  }
}

export const makeDatabaseInstanceOffline = () => {
  const database = new DatabaseInstance()
  database.expeditionsCached.mapToClass(ExpeditionCachedTable)
  return database
}
