import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bigNumbesReportsContainer: {
    display: 'grid !important',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacingInset.xs}`,
    color: theme.colors.neutral.dark40,
    gridTemplateRows: '2fr 1fr 1fr 1fr 0.5fr',
    gridTemplateColumns: '1fr',
    gap: theme.spacingInset.xxs,

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1.5fr 1fr 1fr 1fr 0.5fr',
      gridTemplateRows: '1fr',
      gap: theme.spacingInset.sm,
      justifyContent: 'flex-end'
    }
  },
  reportsTableHeaderItem: {
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'

    }
  },
  reportsRow: {
    border: `2px solid ${theme.colors.neutral.light10}`,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingInset.xs

  },
  knowMoreButton: {
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs} !important`,
    fontSize: `${theme.font.fontSize.xxs} !important`
  },
  listHeaderText: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,

    [theme.breakpoints.up('md')]: {
      color: theme.colors.neutral.dark20
    }
  },
  reportsListContainer: {
    gap: theme.spacingStack.xs
  },
  mobileOnly: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  reportIcon: {
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  individualReportButton: {
    borderRadius: '0px 0px 8px 8px!important',
    margin: '0px -17px -17px -17px!important',
    width: 'calc(100% + 34px)',

    [theme.breakpoints.up('md')]: {
      borderRadius: '0px 8px 8px 0px!important',
      height: '86px!important',
      margin: '-16px -17px -34px 0px!important',
      minWidth: 'auto!important',
      padding: '16px 12px!important',
      top: '-9px!important',
      width: 'auto!important'
    }
  }
}))
