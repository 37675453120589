import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import axios from 'axios'

import { Grid } from '@mui/material'
import { Tag } from 'components/design-system'
import KeyWords from './components/Keywords'
import Skills from './components/Skills'
import LoadingSkillTaxonomies from './components/LoadingSkillTaxonomies'
import ErrorSkillTaxonomies from './components/ErrorSkillTaxonomies'

import { CONTENT_UNIT_TEACHER } from 'navigation/CONSTANTS'

import { IContentUnitResponse, IContentUnitSkillTaxonomiesResponse } from 'services/types'
import { getContentSkillTaxonomies } from 'services/content-unit'
import Analytics from 'utils/analytics'

import { searchContentUnitSkillsAtom } from '../../../../atomStore'

import useStyles from './style'
import { ContentUnitStatus } from './components/ContentUnitStatus'
import { ClassesCount } from './components/ClassesCount'
import { Bookmark } from './components/Bookmark'
import { useCurricularStore, useStore } from 'store'

export interface ICardProps {
  index: number
  contentUnit: IContentUnitResponse
  navigation: {
    gradeCode: string
    gradeTypeCode: string
    classId: number
  }
  dataTestid?: string
  highlight?: string
}

export const Card: React.FC<ICardProps> = ({
  index,
  dataTestid,
  navigation,
  contentUnit,
  highlight
}) => {
  const classes = useStyles(contentUnit)
  const { t } = useTranslation()
  const { currentDiscipline } = useCurricularStore()
  const { profile } = useStore()

  type IStateContent = 'WithKeywords' | 'WithBnccSkill' | 'WithCaminoSkill' | 'LoadingSkillTaxonomies' | 'ErrorSkillTaxonomies'
  const [stateContent, setStateContent] = useState<IStateContent[]>([])
  const [skillTaxonomies, setSkillTaxonomies] = useState<IContentUnitSkillTaxonomiesResponse | undefined>(undefined)
  const [loadingSkillTaxonomies, setLoadingSkillTaxonomies] = useState(false)
  const [errorSkillTaxonomies, setErrorSkillTaxonomies] = useState(false)

  const [searchContentUnitSkills, setSearchContentUnitSkills] = useAtom(searchContentUnitSkillsAtom)

  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const { classId } = useParams<{ classId: string }>()

  const handlePreventClickInterceptors = (e: React.MouseEvent<HTMLElement>) => {
    const { type } = e.target as any
    if (type && type === 'button') {
      e.preventDefault()
    }
  }

  const analyticsOnExpeditionClick = () => {
    if (profile) {
      Analytics.recordEventClick({
        name: 'expedition',
        attributes: {
          ...profile?.analytics,
          discipline_id: currentDiscipline?.id,
          discipline_title: currentDiscipline?.name,
          expedition_id: contentUnit?.id,
          expedition_title: contentUnit?.title
        }
      })
    }
  }

  const handleCardClick = (e: React.MouseEvent<HTMLElement>) => {
    handlePreventClickInterceptors(e)
    analyticsOnExpeditionClick()
  }

  const getSkillTaxonomies = async () => {
    try {
      setLoadingSkillTaxonomies(true)

      if (errorSkillTaxonomies) setErrorSkillTaxonomies(false)

      const response = await getContentSkillTaxonomies(contentUnit.id, source.token)

      if (response.success) {
        setSkillTaxonomies(response.data)
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const cur in searchContentUnitSkills) {
          if (searchContentUnitSkills[cur].id === contentUnit.id) {
            searchContentUnitSkills[cur].bnccSkill = response.data.bnccSkill
            searchContentUnitSkills[cur].skills = response.data.skills

            setSearchContentUnitSkills(searchContentUnitSkills)
          }
        }
      } else {
        setErrorSkillTaxonomies(true)
      }
    } catch {
      setErrorSkillTaxonomies(true)
    } finally {
      setLoadingSkillTaxonomies(false)
    }
  }

  const contentMap: { [key in IStateContent]: any } = {
    WithKeywords: (
      <KeyWords keyWords={contentUnit?.key_words} contentUnit={contentUnit} />
    ),
    WithBnccSkill: (
      <Skills
        skills={skillTaxonomies ? skillTaxonomies.bnccSkill : []}
        contentUnit={contentUnit}
        highlight={highlight}
        isBncc
      />
    ),
    WithCaminoSkill: (
      <Skills
        skills={skillTaxonomies ? skillTaxonomies.skills : []}
        contentUnit={contentUnit}
        highlight={highlight}
      />
    ),
    LoadingSkillTaxonomies: (
      <LoadingSkillTaxonomies />
    ),
    ErrorSkillTaxonomies: (
      <ErrorSkillTaxonomies tryAgainCallback={getSkillTaxonomies} />
    )
  }

  const RenderDisciplines = () => (
    <div className={classes.disciplineContainer}>
      {contentUnit?.disciplines?.length
        ? contentUnit.disciplines.slice(0, 2).map(d => (
          <Tag key={d.id} variant={d.code}>
            {t(d.name)}
          </Tag>
        ))
        : `${t('Erro ao listar disciplinas CARD')}`}
    </div>
  )

  const buildContent: IStateContent[] = []

  useEffect(() => {
    const loadingSkillTaxonomies = async () => {
      await getSkillTaxonomies()
    }

    loadingSkillTaxonomies()

    return () => {
      source.cancel()
    }
  }, [highlight])

  useEffect(() => {
    // Iremos retornar as keywords no futuro, está passando por um processo de revisão por Conteudo.
    // if (contentUnit?.key_words?.length) buildContent.push('WithKeywords')
    if (skillTaxonomies?.bnccSkill?.length) buildContent.push('WithBnccSkill')
    if (skillTaxonomies?.skills?.length) buildContent.push('WithCaminoSkill')
    if (loadingSkillTaxonomies) buildContent.push('LoadingSkillTaxonomies')

    if (errorSkillTaxonomies) {
      buildContent.push('ErrorSkillTaxonomies')
    }

    setStateContent(buildContent)
  }, [skillTaxonomies, loadingSkillTaxonomies, errorSkillTaxonomies])

  return (
    <Grid
      container
      item
      direction='row'
      mb={3}
      spacing={1}
      ml={0}
      justifyContent='flex-start'
      alignItems='stretch'
      className={classes.cardContainer}
    >
      <Grid
        item
        justifyContent='flex-start'
        alignItems='flex-start'
        className={classes.coverContainer}
      >
        <Grid item xs lg alignItems='flex-start' mt={0} data-testid={dataTestid ?? ''}>
          <NavLink
            to={CONTENT_UNIT_TEACHER(
              navigation.gradeTypeCode,
              navigation.gradeCode,
              String(navigation.classId),
              String(contentUnit.id)
            )}
            style={{ textDecoration: 'none' }}
            data-testid={dataTestid ?? ''}
            onClick={handleCardClick}
            className='ui-common-content_card'
          >
            <h1 hidden>{contentUnit.title + ''}</h1>

            <div className={classes.boxCover}>
              <div className={classes.number}>{index}</div>
              <ContentUnitStatus contentUnitId={contentUnit?.id} classId={classId} />
            </div>
          </NavLink>
        </Grid>
      </Grid>

      <Grid item xs={12} lg className={classes.gridInfoSection}>
        <RenderDisciplines />

        <NavLink
          to={CONTENT_UNIT_TEACHER(
            navigation.gradeTypeCode,
            navigation.gradeCode,
            String(navigation.classId),
            String(contentUnit.id)
          )}
          style={{ textDecoration: 'none' }}
          data-testid={dataTestid ?? ''}
          onClick={handleCardClick}
          className='ui-common-content_card'
        >
          <div className={classes.titleContainer}>{contentUnit.title + ''}</div>
        </NavLink>

        <Grid container className={classes.contentUnitInfoContainer}>
          <ClassesCount count={contentUnit?.classes_count} />
          <Bookmark data={contentUnit?.book_marker} />
        </Grid>

      </Grid>
      {stateContent.slice(0, 2).map(cur => contentMap[cur])}
    </Grid>
  )
}

export default Card
