import { Button, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import useStyles from './style'
import {
  contentUnitCardAtom,
  expeditionModalSelectorAtom,
  scheduleAtom
} from '../atomStore'
import { MY_CLOE_CONTEXT, myCloeContextAtom } from '../../../atomStore'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'

enum EventStatusEnum {
  empty = 'emtpy',
  filled = 'filled'
}

interface IContentUnitSelectProps {
  expeditionName?: string
  expeditionCover?: string
  state?: EventStatusEnum
}

export const ContentUnitSelect = ({
  state
}: IContentUnitSelectProps) => {
  const [status, setStatus] = useState<EventStatusEnum>(state ?? EventStatusEnum.empty)
  const [title, setExpeditionTitle] = useState<string | null>()
  const [, setExpeditionSelectorModal] = useAtom(expeditionModalSelectorAtom)
  const [contentUnitCard] = useAtom(contentUnitCardAtom)
  const [myCloeContext] = useAtom(myCloeContextAtom)
  const schedule = useAtomValue(scheduleAtom)
  const inputRef = React.createRef<HTMLDivElement>()

  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const isButtonDisabled = myCloeContext !== MY_CLOE_CONTEXT.HOME || schedule?.type === 'existing_content'

  const getCardTitle = () => {
    if (contentUnitCard?.title) {
      setExpeditionTitle(contentUnitCard.title)
      setStatus(EventStatusEnum.filled)
    } if (contentUnitCard === null) {
      setStatus(EventStatusEnum.empty)
      setExpeditionTitle(t('Vincular um conteúdo'))
    }
  }

  const onClick = () => {
    setExpeditionSelectorModal(true)
    inputRef?.current?.click()
  }

  useEffect(() => {
    getCardTitle()
  }, [contentUnitCard])

  return (
    <>
      <div ref={inputRef} data-testid='event_with_expedition_link_an_expedition'></div>
      <Button
        sx={{
          textTransform: 'none',
          padding: '0px',
          textAlign: 'left',
          gap: '8px',
          '&:disabled': {
            filter: 'grayscale(100%)'
          }
        }}
        onClick={onClick}
        disabled={isButtonDisabled}
        fullWidth
      >
        <Grid
          container
          sx={{
            minHeight: '56px',
            border:
              status === EventStatusEnum.filled
                ? `solid 2px ${theme.colorBrand.medium}`
                : `solid 2px ${theme.colors.neutral.light30}`,
            borderRadius: theme.borderRadius.sm,
            display: 'grid'
          }}
          gap='8px'
          alignContent='center'
          alignSelf='center'
          gridTemplateColumns='auto 1fr'
        >
          {status === EventStatusEnum.filled &&
            (contentUnitCard?.cover ?? contentUnitCard?.coverMobile)
            ? (
              <img
                className={classes.image}
                src={
                  status === EventStatusEnum.filled
                    ? contentUnitCard?.cover?.formats?.small?.url ?? contentUnitCard?.coverMobile?.formats?.small?.url ?? ''
                    : ''
                }
              ></img>
            )
            : (
              <Grid
                item
                alignSelf='center'
                display='flex'
                paddingLeft={theme.spacingInline.nano}
              >
                {' '}
                <MapOutlinedIcon
                  style={{
                    strokeWidth: '0.5px',
                    stroke: 'white',
                    fontSize: theme.font.fontSize.xxl,
                    color: theme.colorBrand.medium
                  }}
                />{' '}
              </Grid>
            )}
          <Grid item alignSelf='center' paddingY={theme.spacingInline.nano} sx={{
            '& .MuiGrid-item': {
              overflow: 'hidden',
              marginRight: '16px'
            }
          }}>
            <span className={classes.text}>
              {status === EventStatusEnum.filled
                ? title
                : t('Vincular um conteúdo')}
            </span>
          </Grid>
        </Grid>
      </Button>
    </>
  )
}
