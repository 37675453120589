import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { Button } from 'components/design-system'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useHistory } from 'react-router'
import useStyles from './style'
import { useTranslation } from 'react-i18next'

interface IRouterPromptProps {
  when: boolean
  btnCancelDataTestId?: string
  btnLeaveDataTestId?: string
}

export const RouterPrompt: React.FC<IRouterPromptProps> = ({ when, children, btnCancelDataTestId, btnLeaveDataTestId }) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [currentPath, setCurrentPath] = useState<string>('')
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        setShowPrompt(true)
        return 'true'
      })
    } else {
      history.block(() => {
        // This is intentional
      })
    }

    return () => {
      history.block(() => {
        // This is intentional
      })
    }
  }, [history, when])

  const onLeave = () => {
    history.block(() => {
      // This is intentional
    })
    history.push(currentPath)
    setShowPrompt(false)
  }

  const onCancel = () => {
    setShowPrompt(false)
  }

  return (
    <Modal
      open={showPrompt}
      onClose={onCancel}
      className={classes.container}
    >
      <Box className={classes.boxContainer}>
        <Grid container className={classes.content}>
          {children}
          <Grid item container xs={12} justifyContent='center' alignItems='center' className={classes.boxButtons}>
            <Grid item xs={6}>
              <Button variant='outlined' aria-label='cancelar' size='medium' onClick={onCancel} data-testid={btnCancelDataTestId}>
                {t('Cancelar')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant='primary' aria-label='confirmar' size='medium' onClick={onLeave} data-testid={btnLeaveDataTestId}>
                {t('Sair')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

export default RouterPrompt
