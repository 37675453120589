import React from 'react'
import { IQuestionResponse, IResourceResponse } from 'services/types'
import { Text, Resource } from 'components/common'

import useStyles from 'pages/ContentUnit/components/Activity/common/ActivityBody/components/ActivityEnunciate/style'

interface IQuestionResourceProps {
  question: IQuestionResponse
  isShowResources?: boolean
}

const splitMulti = (str: any, tokens: string[]) => {
  const tempChar = tokens[0]
  for (let i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar)
  }
  str = str.split(tempChar)
  return str
}

const renderStatementHTMLWithResources = (statement = '', resources: IResourceResponse[] = [], isShowResources: boolean) => {
  if (!statement) return <Text content={statement} />
  // before parsing/replacing, remove wrapping <p> tag from [[resource]] tags
  const cleanStatement = statement
    .replace(/<p>\[\[/g, '[[')
    .replace(/\]\]<\/p>/g, ']]')

  const match = cleanStatement.match(/\[\[(.+)\]\]/g)
  if (match?.length && isShowResources) {
    // tem [[resource]] no enunciado, let the magic begin pra substituir por componente de resource real no lugar da string
    const statementSplited = splitMulti(cleanStatement, match)
    const statementCompleted: any[] = []
    const foundedResources: string[] = []

    statementSplited.forEach((cur: string, index: number) => {
      statementCompleted.push(<Text content={cur} />)
      if (match?.[index]) {
        const link = match[index].replace('[[', '').replace(']]', '')
        const resource = resources.find(res => res.link === link)
        if (resource) {
          foundedResources.push(resource.link)
          statementCompleted.push(<Resource resource={resource} />)
        }
      }
    })

    return (statementCompleted)
  }

  // se não tiver recursos no enunciado, retorna direto (só existe em EM)
  return <Text content={cleanStatement} />
}

const filterQuestionResources = (statement = '', resources: IResourceResponse[] = []) => {
  if (!statement) return resources
  // before parsing/replacing, remove wrapping <p> tag from [[resource]] tags
  const cleanStatement = statement
    .replace(/<p>\[\[/g, '[[')
    .replace(/\]\]<\/p>/g, ']]')

  const match = cleanStatement.match(/\[\[(.+)\]\]/g)
  if (match?.length) {
    const links = match.map(cur => cur.replace('[[', '').replace(']]', ''))
    return resources.filter(res => !links.includes(res.link))
  }

  return resources
}

export const QuestionResource: React.FC<IQuestionResourceProps> = ({ question, isShowResources = true }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={classes.utteranceText}>
        {renderStatementHTMLWithResources(question.text, question.resources, isShowResources)}
      </div>
      {
        isShowResources && filterQuestionResources(question.text, question.resources).map(resource =>
          <React.Fragment key={resource.id}>
            <Resource key={`resource-${resource.id}`} resource={resource} />
          </React.Fragment>)
      }
    </React.Fragment>
  )
}

export default QuestionResource
