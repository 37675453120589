import { useTranslation } from 'react-i18next'

export const Error: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      {t('Houve uma falha ao buscar as atividades da unidade de conteudo. Será necessário recarregar a pagina.')}
    </>
  )
}
