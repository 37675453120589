import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    helpText50: {
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.xxs,
      color: theme.colors.neutral.dark30,
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 0,
      position: 'relative',
      bottom: '44px',
      '&.limit': {
        color: theme.colors.negative.feedbackDark
      }
    }
  })
)
