import { IContentUnitResponse } from 'services/types/content-unit'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      border: `1px solid ${theme.colors.neutral.light10}`,
      borderRadius: theme.borderRadius.sm,
      padding: `${theme.spacingInset.xs}`,

      '& .MuiGrid-item': {
        paddingLeft: '0 !important'
      }
    },
    coverContainer: {
      padding: '0 !important',
      width: '100%',

      [theme.breakpoints.up('lg')]: {
        height: '186px',
        width: '186px',
        marginRight: `${theme.spacingInline.xxxs} !important`
      }
    },
    contentUnitInfoContainer: {
      flexDirection: 'column !important' as 'column',
      justifyContent: 'flex-start',
      gap: theme.spacingStack.nano
    },
    number: {
      backgroundColor: theme.colorBrand.lightest,
      width: '32px',
      aspectRatio: '1',
      color: theme.colorBrand.darkest,
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.bold,
      borderRadius: theme.borderRadius.circular,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1px 0 0 1px',
      border: `${theme.borderThickness.thin} solid ${theme.colorBrand.darkest}`
    },
    boxCover: {
      backgroundColor: theme.colors.neutral.light10,
      backgroundImage: (contentUnit: IContentUnitResponse) => {
        const { cover } = contentUnit
        const imageUrl =
          cover?.formats?.small?.url ??
          cover?.formats?.medium?.url ??
          cover?.formats?.large?.url ??
          cover?.formats?.thumbnail?.url ??
          cover?.url ??
          ''
        return imageUrl ? `url(${imageUrl})` : 'none'
      },
      backgroundSize: 'cover',
      height: '186px',
      padding: theme.spacingInset.xs,
      display: 'flex',
      borderRadius: theme.borderRadius.sm,
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    disciplineContainer: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',

      '& label': {
        marginRight: theme.spacingInline.nano
      }
    },
    titleContainer: {
      color: theme.colorBrand.medium,
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.bold,
      lineHeight: theme.font.lineHeight.sm,
      flexWrap: 'wrap',
      overflow: 'hidden',
      width: '100%',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box'
    },
    subTitle: {
      color: theme.colors.neutral.dark20,
      fontSize: theme.font.fontSize.xxs,
      lineHeight: theme.font.lineHeight.xxs,
      fontWeight: theme.font.fontWeight.bold,
      width: '100%',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.font.lineHeight.xxs
      }
    },
    listContainer: {
      ...theme.scroll,
      [theme.breakpoints.down('sm')]: {
        overflow: 'initial',
        height: 'auto'
      }
    },
    gridInfoSection: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column !important' as 'column',
      paddingTop: `${theme.spacingStack.xxxs} !important`,
      paddingBottom: `${theme.spacingStack.xxxs} !important`,
      gap: theme.spacingStack.nano,

      [theme.breakpoints.up('lg')]: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important'
      }
    },
    gridTheme: {
      position: 'relative'
    },
    listTheme: {
      color: theme.colors.neutral.dark30,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.xs,
      paddingLeft: '1.5rem',
      marginBlockStart: theme.spacingStack.nano,
      marginBlockEnd: theme.spacingStack.nano,
      height: '100%',
      '& li': {
        lineHeight: theme.font.lineHeight.xs,
        marginBottom: '0.5rem'
      }
    },
    tagsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacingStack.nano,
      padding: `${theme.spacingInline.xxs} 0`
    },
    tag: {
      backgroundColor: 'rgba(191, 178, 211, 0.32)',
      color: theme.colorBrand.darkest,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xxs,

      '&.highlight': {
        backgroundColor: `${theme.colors.warning.feedbackLight} !important`
      }
    },
    skillContainer: {
      paddingTop: `${theme.spacingStack.xxxs} !important`
    }
  })
)
