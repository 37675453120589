import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiAlert-root': {
      padding: theme.spacingSquish.nano,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0'
      },
      '& .MuiAlert-action': {
        padding: `0 0 0 ${theme.spacingInline.nano}`
      },
      '& .MuiAlert-icon': {
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: theme.font.fontSize.lg,
        marginRight: theme.spacingInline.nano
      },
      '& .MuiAlert-message': {
        display: 'flex',
        flexDirection: 'column',
        fontSize: theme.font.fontSize.xxs,
        fontWeight: theme.font.fontWeight.semibold,
        lineHeight: theme.font.lineHeight.xxs,
        justifyContent: 'center',
        padding: `${theme.spacingInline.nano} 0`,
        width: '100%'
      },
      '& .MuiAlertTitle-root': {
        fontFamily: theme.font.fontFamily,
        fontSize: theme.font.fontSize.sm,
        lineHeight: '100%',
        marginBottom: theme.spacingInline.quarck
      },

      // filled styles
      ' &.MuiAlert-filledError': {
        color: theme.colors.negative.feedbackDarkest,
        backgroundColor: theme.colors.negative.feedbackLightest,
        '& .MuiAlert-icon, & .actionButton': {
          color: theme.colors.negative.feedbackDarkest
        },
        '& .actionButton:hover': {
          background: 'rgba(223, 113, 113, 0.5)'
        }
      },
      ' &.MuiAlert-filledWarning': {
        color: theme.colors.warning.feedbackDarkest,
        backgroundColor: theme.colors.warning.feedbackLightest,
        '& .MuiAlert-icon, & .actionButton': {
          color: theme.colors.warning.feedbackDarkest
        },
        '& .actionButton:hover': {
          background: 'rgba(255, 212, 102, 0.5)'
        }
      },
      ' &.MuiAlert-filledInfo': {
        color: theme.colors.information.feedbackDarkest,
        backgroundColor: theme.colors.information.feedbackLight,
        '& .MuiAlert-icon, & .actionButton': {
          color: theme.colors.information.feedbackDarkest
        },
        '& .actionButton:hover': {
          background: 'rgba(66, 164, 255, 0.5)'
        }
      },
      ' &.MuiAlert-filledSuccess': {
        color: theme.colors.positive.feedbackDarkest,
        backgroundColor: theme.colors.positive.feedbackLight,
        '& .MuiAlert-icon, & .actionButton': {
          color: theme.colors.positive.feedbackDarkest
        },
        '& .actionButton:hover': {
          background: 'rgba(77, 200, 170), 0.5)'
        }
      },

      // outlined styles
      ' &.MuiAlert-outlinedError': {
        color: theme.colors.negative.feedbackMedium,
        borderColor: theme.colors.negative.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .actionButton:hover': {
          backgroundColor: theme.colors.negative.feedbackLightest,
          opacity: 0.8
        },
        '& .MuiAlert-icon, & .actionButton': {
          color: theme.colors.negative.feedbackMedium
        }
      },
      ' &.MuiAlert-outlinedWarning': {
        color: theme.colors.warning.feedbackDarkest,
        borderColor: theme.colors.warning.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .actionButton:hover': {
          backgroundColor: theme.colors.warning.feedbackLightest,
          color: theme.colors.warning.feedbackMedium,
          opacity: 0.8
        },
        '& .MuiAlert-icon': {
          fontSize: '2rem',
          color: theme.colors.warning.feedbackMedium
        }
      },
      ' &.MuiAlert-outlinedInfo': {
        color: theme.colors.information.feedbackDarkest,
        borderColor: theme.colors.information.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .actionButton:hover': {
          backgroundColor: theme.colors.information.feedbackLightest,
          opacity: 0.8
        },
        '& .MuiAlert-icon, & .actionButton': {
          color: theme.colors.information.feedbackMedium
        }
      },
      ' &.MuiAlert-outlinedSuccess': {
        color: theme.colors.positive.feedbackDarkest,
        borderColor: theme.colors.positive.feedbackMedium,
        fontWeight: theme.font.fontWeight.semibold,
        '& .actionButton:hover': {
          backgroundColor: theme.colors.positive.feedbackLightest,
          opacity: 0.8
        },
        '& .MuiAlert-icon, & .actionButton': {
          color: theme.colors.positive.feedbackMedium
        }
      }
    }
  }
}))
