import { useAtom } from 'jotai'
import { NavigateNextSharp, NavigateBeforeSharp } from '@mui/icons-material'
import { tabIndexModalTimelineAtom } from '../atomStore'
import { IRecordTimelineResponse } from 'services/types'
import useStyles from './style'

interface IPaginationProps {
  index?: []
  content?: IRecordTimelineResponse[]
  dataTestid?: string
}

const Pagination: React.FC<IPaginationProps> = ({
  index,
  content,
  dataTestid
}) => {
  // const { content, indexSelected, setIndexSelected } = useContents()
  const maxLength = content?.length ?? 0
  const [indexSelected, setIndexSelected] = useAtom(tabIndexModalTimelineAtom)
  const classes = useStyles()

  return (
    <div className={classes.paginantionWrapper}>
      <div className={classes.paginationControl}>
        <button
          disabled={indexSelected === 0}
          className={classes.paginantionButton}
          onClick={() => setIndexSelected(indexSelected - 1)}
        >
          <NavigateBeforeSharp data-testid={dataTestid} />
        </button>
        {content?.map((i, index) => (
          <button
            key={i?.id}
            data-testid={dataTestid}
            onClick={() => setIndexSelected(index)}
            className={`${classes.paginationIndex} ${
              index === indexSelected && 'selected'
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          disabled={indexSelected === maxLength - 1}
          className={classes.paginantionButton}
          onClick={() => setIndexSelected(indexSelected + 1)}
        >
          <NavigateNextSharp data-testid={dataTestid} />
        </button>
      </div>
    </div>
  )
}

export default Pagination
