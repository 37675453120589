import { useState, useEffect } from 'react'
import { useStore } from 'store'
import { Expanded, Closed } from './components'
import { useMediaQuery, useTheme } from '@mui/material'

enum StateMachineEnum {
  IDLE = 'IDLE',
  EXPANDED = 'EXPANDED',
  CLOSED = 'CLOSED'
}

export const QuickAccessHeader = () => {
  const { isQuickAccessOpen, setIsQuickAccessOpen } = useStore()
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (isMobile && !isQuickAccessOpen) {
      setIsQuickAccessOpen(true)
    }
  }, [isMobile])

  useEffect(() => {
    const isOpen = isQuickAccessOpen === false ? StateMachineEnum.CLOSED : StateMachineEnum.EXPANDED
    setStateMachine(isOpen)
  }, [isQuickAccessOpen])

  const handleHeaderExpand = () => {
    setIsQuickAccessOpen(!isQuickAccessOpen)
  }

  const states = {
    IDLE: '',
    EXPANDED: <Expanded handleHeaderExpand={handleHeaderExpand} />,
    CLOSED: <Closed handleHeaderExpand={handleHeaderExpand} />
  }

  const renderMachineState = () => {
    return states[stateMachine]
  }

  return (
    <>
      {renderMachineState()}
    </>
  )
}
