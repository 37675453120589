import { PipeRequestParams } from '../../'

export const makeUrlWithParams = (url: string, params?: PipeRequestParams) => {
  let cloneUrl = url
  if (params) {
    for (const [paramKey, paramValue] of Object.entries(params)) {
      cloneUrl = cloneUrl.replace(`:${paramKey}`, paramValue)
    }
  }
  return cloneUrl
}
