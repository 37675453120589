import { parseKaTex } from 'utils/katex'
import useStyles from './style'
import { HistoryEduOutlined } from '@mui/icons-material'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { useContents } from 'pages/ContentUnit/components/Content/useContents'
import { DisciplineCodeEnum } from 'services/types'

interface IContentProps {
  content: string | null
}

export const Glossary: React.FC<IContentProps> = ({ content }) => {
  const { content: content_unit } = useContents()
  const isCloeExpand = content_unit.disciplines?.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const classes = useStyles()

  content = parseKaTex(content)

  return (
    <div className={classes.glossary}>
      <div className={classes.title}>
        <HistoryEduOutlined /> {t('Glossário')}
      </div>
      <div dangerouslySetInnerHTML={{
        __html: content ?? ''
      }} className={classes.dangerouslyContent} />
      <div className={classes.fakeBorder} />
    </div>
  )
}
