import { Button } from 'components/design-system'
import useStyles from './style'
import { useAtom } from 'jotai'
import { answersAtom, questionIndexAtom } from '../../atomStore'
import { IQuestionResponse } from 'services/types'
import { Grid, useTheme, useMediaQuery } from '@mui/material'

import { inUploadingProcess } from 'pages/ContentUnit/components/Activity/atomStore'
interface IPaginationProps {
  questions: IQuestionResponse[]
  isActivitySuggestedType?: boolean
}

export const Pagination: React.FC<IPaginationProps> = ({ questions, isActivitySuggestedType }) => {
  const [questionIndex, setQuestionIndex] = useAtom(questionIndexAtom)
  const [answers] = useAtom(answersAtom)
  const [isUploading] = useAtom(inUploadingProcess)
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const changeQuestion = (index: number) => {
    if (isUploading) return
    setQuestionIndex(index)
  }

  return (
    <Grid container item={!isMobile} margin={0} xs={12} sx={{ margin: '0', padding: isMobile ? 0 : null }} justifyContent={isMobile ? 'center' : 'start'}>
      <ul className={classes.list}>
        {
          questions
            .map((cur, index) => {
              const isAnswered = answers.filter(a => {
                const hasFileRecord = (a?.records?.[0]?.file_records?.length ?? 0) > 0
                const hasTextRecord = !!a.records[0].text_record?.text
                const hasChoiceRecord = a.records[0].choice_record?.alternative_id
                return questions[index].id?.toString() === a.question_id && (hasFileRecord || hasTextRecord || hasChoiceRecord)
              }).length > 0
              return (
                <li key={index}>
                  <Button data-testid={isActivitySuggestedType ? 'respond_activities_select_question' : 'respond_assessments_select_question' } onClick={() => changeQuestion(index)} variant='outlined' className={`${(questionIndex === index) && 'selected'} ${isAnswered && 'answered'}`}>
                    {index + 1}
                  </Button>
                </li>
              )
            })
        }
      </ul>
    </Grid>
  )
}
