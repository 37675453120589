import { ChangeSchool, ConfirmationModal, HeaderTeacher } from './components'

export const TeacherProfileManagerMenu: React.FC = () => {
  return (
    <>
      <HeaderTeacher />
      <ChangeSchool />
      <ConfirmationModal />
    </>
  )
}
