import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bigNumbersItem: {
    background: '#E9E5F0', // cor não está nos padrões cloe, TODO: FALAR COM UX
    borderRadius: theme.borderRadius.lg,
    boxShadow: '0px 16px 56px rgba(20, 20, 20, 0.16)',

    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'flex-start !important',
    gap: theme.spacingInset.xs,

    padding: theme.spacingInset.sm,

    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: `calc(50% - ${theme.spacingInset.sm})`
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(25% - ${theme.spacingInset.sm})`,
      flexDirection: 'column !important' as 'column',
      justifyContent: 'center !important',
      alignItems: 'flex-start !important'
    }
  },
  bigNumbersItemLoading: {
    background: theme.colors.neutral.light10,
    boxShadow: 'none',
    height: '10rem'
  }
}))
