import { useEffect } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { Grid, Theme } from '@mui/material'
import Mark from 'mark.js'

import { IContentUnitResponse } from 'services/types'
import Analytics from 'utils/analytics'
import ContentCard from 'components/common/ContentCard'
import { useCurricularStore, useStore } from 'store'

interface IContentUnitsProps {
  contentUnits: IContentUnitResponse[]
  highlight?: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardContainer: {
    width: '100%',
    margin: `${theme.spacingInline.nano} 0px`,
    display: 'inline-block',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      marginInline: theme.spacingInline.nano,
      margin: theme.spacingInline.nano
    }
  }
}))

export const ContentUnits: React.FC<IContentUnitsProps> = ({
  contentUnits,
  highlight
}) => {
  const classes = useStyles()
  const { subscription } = useStore()
  const { currentDiscipline, classId } = useCurricularStore()

  const setMark = () => {
    const domSelectedToHighlight = document?.querySelectorAll('#page-contents-content_unit_list_wrapper') ?? ''
    const markInstance = new Mark(domSelectedToHighlight)
    markInstance.unmark({
      done: () => {
        const text = highlight ?? ''
        markInstance.mark(text)
      }
    })
  }

  useEffect(() => {
    setMark()
  }, [classId, highlight])

  const analyticsOnExpeditionClick = (contentUnit: IContentUnitResponse) => {
    if (subscription) {
      Analytics.recordEventClick({
        name: 'expedition',
        attributes: {
          ...subscription?.analytics,
          discipline_id: currentDiscipline?.id,
          discipline_title: currentDiscipline?.name,
          expedition_id: contentUnit?.id,
          expedition_title: contentUnit?.title
        }
      })
    }
  }

  return (
    <Grid id='page-contents-content_unit_list_wrapper'>
      {
        contentUnits.map((content, i) => (
          <div key={content.id} data-testid='all_contents' className={classes.cardContainer} onClick={() => analyticsOnExpeditionClick(content)}>
            <ContentCard
              classId={`${classId}`}
              title={content.title}
              disciplines={content.disciplines}
              suggestedPosition={content.suggested_position}
              imageUrl={
                content.cover?.formats?.large?.url ??
                content.cover?.formats?.medium?.url ??
                content.cover?.formats?.small?.url ??
                content.cover?.formats?.thumbnail?.url ??
                content.cover?.url ?? ''
              }
              id={content.id}
              dataTestid='home_content_select_expedition' />
          </div>))
      }
    </Grid>
  )
}

export default ContentUnits
