/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Avatar, Text2, Text, Icon } from 'components/design-system'
import { Grid, Skeleton, useTheme, Box } from '@mui/material'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

import { getTemporaryAccess } from 'services/temporary-access'
import useStyle from './styles'
import { Student } from 'pages/Dashboard/teacher/components/types/students'
import { toast } from 'components/design-system/Toast/manager'
import { StatusClassHandle, CurrentClass } from 'components/common'
import { AccessTokenModal } from './components'
import { truncateExtension } from 'utils/string'

interface StudentCardProps extends Student {
  userSchoolProfileId?: string | number
}

const StudentCardItem: React.FC<StudentCardProps> = ({
  studentName,
  studentNick,
  userSchoolProfileId
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [token, setToken] = useState<string | null>(null)
  const { t } = useTranslation()
  const classes = useStyle()
  const theme = useTheme()

  const handleModal = useCallback(() => {
    setOpenModal(!openModal)
  }, [openModal])

  const getTemporaryStudentAccess = useCallback(async () => {
    try {
      if (userSchoolProfileId) {
        const { data } = await getTemporaryAccess(userSchoolProfileId)

        setToken(data?.token)

        handleModal()

        toast.handler({
          content: t(
            'Chave de acesso gerada com sucesso'
          ),
          duration: 10000,
          severity: 'success'
        })
      }
    } catch (error) {
      handleModal()

      toast.handler({
        content: t('Não foi possível criar uma senha temporária para o aluno'),
        duration: 10000,
        severity: 'error'
      })
    }
  }, [handleModal, toast])

  const copyTokenToClipboard = () => {
    if (!token) {
      return
    }

    navigator.clipboard.writeText(token)
    toast.handler({
      content: t(
        'Chave de acesso copiada para a área de transferência'
      ),
      duration: 10000,
      severity: 'success'
    })
  }

  const Token = () => {
    if (!token) {
      return (
        <Button
          onClick={handleModal}
          className={classes.accessKeyButton}
          variant='ghost'
          endIcon={<LockOpenOutlinedIcon />}
          data-testid='followup_gererate_provisional_access'
        >
          {t('Chave de acesso')}
        </Button>
      )
    }
    return (
      <Box className={classes.accessKeyContainer} onClick={copyTokenToClipboard}>
        <Text className={classes.accessKeyText} >
          {token}
        </Text>
        <Icon size='small' >
          <ContentCopyOutlinedIcon />
        </Icon>
      </Box>
    )
  }

  return (
    <>
      <Grid container className={classes.studentCardContainer}>
        <Avatar userName={studentName} size='large' className={classes.customAvatar} />
        <Grid item className={classes.studentCardNameKey}>
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xxs'
            mobile='sm'
            customColor={theme.colors.neutral.dark40}
            className={classes.studentName}
          >
            {truncateExtension(studentName ?? t('Sem Nome'), 20)}
          </Text2>
          {/* <CustomText
          fontSize='xs'
          fontWeight='regular'
          lineHeight='sm'
          mobile='xs'
          customColor='#727272'
        >
          {studentNick ?? t('Sem Apelido')}
        </CustomText> */}
          <StatusClassHandle>
            <CurrentClass>
              <Token />
            </CurrentClass>
          </StatusClassHandle>
        </Grid>
      </Grid>

      <AccessTokenModal
        open={openModal}
        setOpen={setOpenModal}
        getTemporaryToken={getTemporaryStudentAccess}
        studentName={studentName ?? t('Sem nome')}
        studentUsername={studentNick ?? t('Sem nome')}
      />
    </>
  )
}

const StudentCardSkeleton: React.FC = () => {
  const classes = useStyle()
  return (
    <Grid container className={classes.studentCardContainer}>
      <Skeleton variant='circular' width={100} height={100} animation='wave' />
      <Grid item className={classes.studentCardNameKey}>
        <Skeleton variant='text' width={70} height={40} animation='wave' />
        {/* <Skeleton variant='text' width={160} height={60} animation='wave' /> */}

        <StatusClassHandle>
          <CurrentClass>
            <Skeleton variant='text' width={50} height={40} animation='wave' />
          </CurrentClass>
        </StatusClassHandle>
      </Grid>
    </Grid>

  )
}

export const StudentCard = {
  Item: StudentCardItem,
  Skeleton: StudentCardSkeleton
}
