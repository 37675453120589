import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    solutionContainer: {
      backgroundColor: theme.colors.information.feedbackLightest,
      borderRadius: theme.borderRadius.sm,
      padding: `${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`,
      justifyContent: 'space-between',
      width: '100%'
    },
    solutionContent: {
      backgroundColor: 'rgba(198, 228, 255, 0.32)',
      borderRadius: theme.borderRadius.sm,
      padding: `${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`,
      border: `1px solid ${theme.colors.information.feedbackLight}`
    },
    contentLevel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    levelButton: {
      maxWidth: '40px',
      minWidth: '40px',
      maxHeight: '40px',
      minHeight: '40px',
      fontSize: `${theme.font.fontSize.xs} !important`,
      fontFamily: theme.font.fontFamily,
      fontWeight: `${theme.font.fontWeight.semibold} !important`,
      border: `1px solid ${theme.colorBrand.dark}`,
      borderLeft: `1px solid ${theme.colors.information.feedbackDarkest} !important`,
      borderRadius: theme.borderRadius.pill,
      borderTopLeftRadius: '50% !important',
      borderTopRightRadius: '50% !important',
      borderBottomLeftRadius: '50% !important',
      borderBottomRightRadius: '50% !important',

      '&.Mui-disabled': {
        border: `1px solid ${theme.colors.information.feedbackDarkest} !important`,
        color: `${theme.colors.information.feedbackDarkest} !important`
      }
    },
    contentLevelText: {
      width: '100%',
      marginTop: 0,
      marginLeft: theme.spacingInline.xxs,
      fontSize: '18px', // theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: '32px',
      color: theme.colors.information.feedbackDarkest
    },
    tag: {
      transition: 'all ease-in-out 0.1s',
      color: theme.colors.neutral.darkBase,
      background: theme.colors.neutral.lightBase,
      borderColor: theme.colors.neutral.light10,
      border: '2px solid',
      padding: '7px',
      minWidth: 'fit-content',
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.xxs,
      borderRadius: theme.borderRadius.xs,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center'
    },
    solutionAlternative: {
      color: 'red'
    }
  })
)
