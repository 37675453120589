import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardStudentAnswerContainer: {
      backgroundColor: theme.colors.neutral.lightBase,
      padding: theme.spacingInline.xxs,
      border: `1px solid ${theme.colors.neutral.light20}`,
      borderRadius: theme.borderRadius.md,
      marginTop: theme.spacingStack.xxs
    },
    cardStudentAnswerTextName: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs,
      marginRight: theme.spacingInset.xxs,
      textTransform: 'capitalize',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word'
    },
    cardStudentAnswerTextDate: {
      color: theme.colors.neutral.light40,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xs
    },
    cardStudentAnswerTextInfo: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs
    },
    cardStudentAnswerIconAnswerPending: {
      color: '#b38100'
    },
    cardStudentAnswerIconNoAnswer: {
      color: theme.colors.negative.feedbackLight
    },
    cardStudentAnswerIconAnswer: {
      color: theme.colors.information.feedbackMedium
    }
  })
)
