import MUiTabs from '@mui/material/Tabs'
import { TabsProps } from '@mui/material'
import makeStyles from './style'

export const SimpleTabs: React.FC<TabsProps> = ({ ...props }) => {
  const classes = makeStyles()
  return (
    <MUiTabs
      {...props}
      className={`${props.className as string} ${classes.root}`}
    />
  )
}
