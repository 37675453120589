import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { useTheme } from '@mui/material'
import { Button, Avatar, Text } from 'components/design-system'
import { CurrentClass, DropDown, StatusClassHandle } from 'components/common'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useAuth } from 'navigation/ProvideAuth'
import { readComment } from 'services/comments'

import { ActivityControlStatusEnum } from 'services/types'
import { activityStatusControlAtom } from '../../../../../../atomStore'

import useStyles from './style'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

interface ICommentsProps {
  id: string
  comment: String
  commentOwner: any
  deleteComment: Function
  editComment: Function
  cancelEditComment: Function
  isEdited: boolean
  isRead: boolean
}

export const Comments: React.FC<ICommentsProps> = ({ id, comment, commentOwner, deleteComment, editComment, cancelEditComment, isEdited, isRead }) => {
  const classes = useStyles()
  const theme = useTheme()
  const auth = useAuth()

  const { t } = useTranslation()

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const [activityStatusControl] = useAtom(activityStatusControlAtom)
  const [activityCurrent] = useAtom(activityCurrentAtom)

  const isActivityCorrection = [
    ActivityControlStatusEnum.CLOSED,
    ActivityControlStatusEnum.PENDING,
    ActivityControlStatusEnum.DONE,
    ActivityControlStatusEnum.CORRECTED
  ].includes(parseInt(activityStatusControl as string))

  const isActivitySuggestedType = activityCurrent?.suggested_application_type === 'activity'

  useEffect(() => {
    const readComments = async () => {
      if (auth?.user?.id !== commentOwner.id && !isRead) {
        await readComment(id)
      }
    }

    void readComments()
  }, [id])

  const deleteCommentAndCloseTab = async () => {
    const response = await deleteComment(id)

    if (response) {
      setShowConfirmDelete(false)
    }
  }

  return (
    <Grid container spacing={1} justifyContent='center' >
      <Grid item xs={12} lg={12} display='flex'
        sx={{
          gridArea: 'interact',
          display: 'flex',
          marginBottom: '20px'
        }}
      >
        <Grid item xs={10} lg={10} className={classes.avatar} gap={1}
          sx={{
            gridArea: 'interact',
            display: 'flex',
            alignItems: 'center'
          }}>
          <Avatar userName={commentOwner.username} size='medium' />
          <Grid item className={classes.userInfoName}>
            {`${commentOwner.name as string} ${commentOwner.surname as string}`}
          </Grid>
        </Grid>
        {auth?.user?.id !== commentOwner.id && !isRead &&
          <Grid item xs={2} lg={2} display='flex' gap={2}
            sx={{
              gridArea: 'interact',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              justifyItems: 'center'
            }}>
            <div className={classes.badgeRead}></div>
          </Grid>
        }
        <StatusClassHandle>
          <CurrentClass>
            {auth?.user?.id === commentOwner.id &&
              <Grid item xs={2} lg={2} display='flex' gap={2}
                sx={{
                  gridArea: 'interact',
                  display: 'flex',
                  justifyContent: 'right',
                  marginBottom: '20px'
                }}>
                <DropDown dataTestid={isActivityCorrection
                  ? isActivitySuggestedType
                    ? 'correction_activity_exercise_comment_assessment'
                    : 'correction_assessment_comment_assessment'
                  : ''}>
                  {
                    showConfirmDelete
                      ? <div className={classes.dropdownWrapper}>
                        <div className={classes.dropdownTitle}>
                          {t('Você está prestes a excluir esse evento da agenda. Gostaria de prosseguir?')}
                        </div>
                        <div className={classes.dropdownButtons}>
                          <Button
                            variant='ghost'
                            onClick={() => setShowConfirmDelete(false)}
                            sx={{
                              height: '32px !important',
                              fontSize: '12px !important'
                            }}
                            data-testid={isActivityCorrection
                              ? isActivitySuggestedType
                                ? 'delete_activity_comment_not_delete'
                                : 'delete_assessment_comment_not_delete'
                              : ''}
                          >
                            {t('Cancelar')}
                          </Button>
                          <Button
                            onClick={deleteCommentAndCloseTab}
                            sx={{
                              height: '32px !important',
                              fontSize: '12px !important'
                            }}
                            data-testid={isActivityCorrection
                              ? isActivitySuggestedType
                                ? 'delete_activity_comment_delete'
                                : 'delete_assessment_comment_delete'
                              : ''}
                          >
                            {t('Excluir')}
                          </Button>
                        </div>
                      </div>
                      : <Grid className={classes.dropdownWrapperButtons}>
                        <MenuItem className={classes.dropdownWrapperButtons}
                          data-testid={isActivityCorrection
                            ? isActivitySuggestedType
                              ? 'activity_comments_delete_comments'
                              : 'assessment_comments_delete_comments'
                            : ''}
                          onClick={e => { e.stopPropagation(); cancelEditComment(); setShowConfirmDelete(true) }}
                        >
                          <div className={classes.navationButton}>
                            <DeleteOutline /> {t('Excluir')}
                          </div>
                        </MenuItem>
                        <MenuItem className={classes.dropdownWrapperButtons}
                          data-testid={isActivityCorrection
                            ? isActivitySuggestedType
                              ? 'activity_comments_edit_comments'
                              : 'assessment_comments_edit_comments'
                            : ''}
                          onClick={() => editComment(id, comment)}
                        >
                          <div className={classes.navationButton}>
                            <EditOutlined /> {t('Editar')}
                          </div>
                        </MenuItem>
                      </Grid>
                  }
                </DropDown>
              </Grid>}
          </CurrentClass>
        </StatusClassHandle>

      </Grid>
      <Grid item xs={12} lg={12} display='flex' ml={3} gap={1}
        sx={{
          gridArea: 'interact',
          display: 'flex',
          marginBottom: '20px',
          paddingLeft: theme.spacingInline.xxxs,
          paddingRight: theme.spacingInline.nano
        }}
      >
        <Text style={{ lineBreak: 'anywhere' }}>
          {comment} {isEdited ? <Text className={classes.editedText}>(editado)</Text> : ''}
        </Text>
      </Grid>
    </Grid>
  )
}

export default Comments
