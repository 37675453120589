import { OnlyProfile } from 'components/common'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { StudentProfileManagerMenu } from './student'
import { TeacherProfileManagerMenu } from './teacher'

export const ProfileManagerMenu: React.FC = () => (
  <>
    <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
      <StudentProfileManagerMenu />
    </OnlyProfile>
    <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
      <TeacherProfileManagerMenu />
    </OnlyProfile>
  </>
)
