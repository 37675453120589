import { createRef, useEffect, useState } from 'react'
import { Provider, useAtom } from 'jotai'
import { Upload } from './Upload'
import Preview from './Preview'
import { IQuestionResponse } from 'services/types'
import { UploadApiService } from 'services/upload'
import { IFileUploadResponse } from 'services/types/upload'

import { SxProps } from '@mui/system'
import { Theme } from '@mui/material'

import { IPreviewState, percentageAtom, previewsAtom, emptyStateAtom, disabledAtom } from './atomStore'
import { useParams } from 'react-router-dom'
import { updateAnswerOffline } from 'contentCacheManager/localDatabaseManager/data/answers/updateAnswerOffline'
import { getAnswerOffline, getSubscription, useOnlineStatus } from 'contentCacheManager'
import { useTranslation } from 'react-i18next'
import { toast } from 'components/design-system/Toast/manager'

interface IInputFilePreviewProps {
  onChange: (previews: IPreviewState[]) => void
  onProgress: (progress: number) => void
  reset?: boolean
  initialPreviews?: IPreviewState[]
  availableArchivesTypes?: string
  description?: string
  maxFileSize?: number
  descriptionStyle?: SxProps<Theme>
  titleStyle?: SxProps<Theme>
  maxFileName?: number
  currentQuestion?: IQuestionResponse
  multiple?: boolean
  dataTestid?: string | null
  metaData?: { [key: string]: string | number }
}

const InputFilePreviewContent: React.FC<IInputFilePreviewProps> = (props: IInputFilePreviewProps) => {
  const {
    onChange,
    onProgress,
    reset,
    initialPreviews,
    description,
    maxFileSize,
    descriptionStyle,
    titleStyle,
    maxFileName,
    currentQuestion,
    multiple = true,
    dataTestid,
    metaData
  } = props

  const [previews, setPreviews] = useAtom(previewsAtom)
  const [percentage, setPercentage] = useAtom(percentageAtom)
  const [emptyState, setEmptyState] = useAtom(emptyStateAtom)
  const { contentId, activityId } = useParams<{ contentId: string, activityId: string, evaluationId: string }>()
  const [fileOfflineCount, setFileOfflineCount] = useState(0)
  const isOnline = useOnlineStatus()
  const [disabled, setDisabled] = useAtom(disabledAtom)
  const inputRef = createRef<HTMLDivElement>()
  const { t } = useTranslation()

  useEffect(() => {
    if (!emptyState) {
      return
    }

    if ((initialPreviews ?? []).length > 0) {
      setEmptyState(false)
      setPreviews(initialPreviews ?? [])
    }
  }, [initialPreviews])

  useEffect(() => {
    onChange(previews)

    if (!multiple && previews.length === 0) {
      setDisabled(false)
    } else if (!multiple && previews.length > 0) {
      setDisabled(true)
    }
  }, [previews])

  useEffect(() => {
    onProgress(percentage)
  }, [percentage])

  useEffect(() => {
    if (reset) {
      void resetData()
    }
  }, [reset])

  const resetData = () => {
    setPreviews([])
    setPercentage(0)
    setEmptyState(true)
  }

  const setPreview = (data: IFileUploadResponse[]) => {
    const newPreviews: IPreviewState[] = data.map(file => ({
      transaction_id: file.transaction_id,
      id: file.id,
      url: file.url,
      name: file.name,
      thumb: file.url,
      ext: file.ext,
      maxFileName: maxFileName
    }))
    setPreviews([...previews, ...newPreviews])
  }

  const saveOfflineFile = async (uploadedFile: any) => {
    const files = uploadedFile as File[]
    const createBlob = () => files.map(file => new Blob([file], { type: file.type }))

    const blobs = createBlob()
    const { class: studentClass } = getSubscription()
    const currentAnswer = await getAnswerOffline({
      activity: activityId,
      class: String(studentClass.id),
      content_unit: contentId
    })

    const questionOfflineFileOfflineList = currentAnswer?.filesOffline ?? []

    const questionOfflineFileList = questionOfflineFileOfflineList.find(fileList => fileList.question_id === Number(currentQuestion?.id))

    const questionFileList = questionOfflineFileList?.files ?? []

    updateAnswerOffline({
      activity: activityId,
      class: String(studentClass.id),
      content_unit: contentId
    }, {
      filesOffline: questionOfflineFileOfflineList.filter(fileList => fileList.question_id !== Number(currentQuestion?.id)).concat([
        {
          activity: activityId,
          content_unit: contentId,
          question_id: Number(currentQuestion?.id),
          files: questionFileList.concat(blobs.map((blob, index) => ({
            file: blob,
            fileName: files[index].name + '-' + new Date().getTime().toString()
          })))
        }
      ])
    }).then((current) => {
      setFileOfflineCount(prevCount => prevCount + Number(current?.filesOffline?.length) ?? 1)
    })
  }

  const processUpload = async (uploadedFiles: any) => {
    if (!isOnline) {
      saveOfflineFile(uploadedFiles)
      return
    }
    if (!multiple && uploadedFiles.length > 0) {
      setDisabled(true)
    }
    setPercentage(1)
    setEmptyState(false)

    const uploadApiService = new UploadApiService(uploadedFiles, setPercentage, metaData)
    const fileUploadResponse = await uploadApiService.uploadMultipleFiles()
    setPreview(fileUploadResponse)

    // verifica se a quantidade de arquivos enviados com sucesso é menor que a total de arquivos a serem enviados
    if (fileUploadResponse.length < uploadedFiles.length) {
      // seta a porcentagem para não ficar o loading infinito
      setPercentage(100)
      toast.handler({
        content: t('Não foi possível enviar todos os arquivos. Lembre-se você pode enviar o novo registro e fazer edição posteriormente com os arquivos que não foram enviados.'),
        duration: 60000,
        severity: 'error'
      })
    }
  }

  const handleDataTaging = () => {
    // solução para funcionar o tagamento
    inputRef?.current?.click()
  }

  return (
    <div onClick={handleDataTaging}>
      <div ref={inputRef} data-testid={dataTestid} />
      <Upload
        description={description as string}
        onUpload={processUpload}
        availableArchivesTypes={props.availableArchivesTypes as string}
        maxFileSize={maxFileSize as number}
        descriptionStyle={descriptionStyle as SxProps<Theme>}
        titleStyle={titleStyle as SxProps<Theme>}
        multiple={multiple}
        disabled={disabled || (percentage > 1 && percentage < 100)}
        dataTestid={dataTestid}
        percentage={percentage}
      />
      {
        ((!emptyState && !(previews.length < 1 && percentage < 100)) || !isOnline) &&
        <Preview currentQuestion={currentQuestion} fileOfflineCount={fileOfflineCount} />
      }
    </div>
  )
}

export const InputFilePreview: React.FC<IInputFilePreviewProps> = (props: IInputFilePreviewProps) => {
  return (
    <Provider>
      <InputFilePreviewContent {...props} />
    </Provider>
  )
}

export default InputFilePreview
