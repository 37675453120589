import { convertArrToGETParams } from 'utils/url'

export const CLASS_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number) =>
  `${process.env.REACT_APP_SERVICES as string}/data-integration/performance/school/${schoolId}/class/${classId}/discipline/${disciplineId}`

export const STUDENTS_LIST_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number, offset: number, limit: number, search?: string, sortBy?: string) => {
  const rawURL = `${process.env.REACT_APP_SERVICES as string}/data-integration/performance/school/${schoolId}/class/${classId}/students`
  const paramsObj = { offset, limit, disciplineId, search, sortBy }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const STUDENT_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number, studentId: number) =>
  `${process.env.REACT_APP_SERVICES as string}/data-integration/performance/school/${schoolId}/class/${classId}/discipline/${disciplineId}/student/${studentId}`

export const DETAILED_PERFORMANCE = (schoolId: number, classId: number, disciplineId: number, studentId: number, limit?: number, offset?: number, search?: string, sortBy?: 'asc' | 'desc' | 'situation') => {
  const rawURL = `${process.env.REACT_APP_SERVICES as string}/data-integration/performance/activity/school/${schoolId}/class/${classId}/discipline/${disciplineId}/student/${studentId}`
  const paramsObj = { limit, offset, search, sortBy }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const LAST_UPDATED_DATE = () => `${process.env.REACT_APP_SERVICES as string}/data-integration/datasets/getHistory`
