import OnlyProfile from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { OnlyStudentCurrentSchoolPeriod } from './student'
import { OnlyTeacherCurrentSchoolPeriod } from './teacher'

export const OnlyCurrentSchoolPeriod: React.FC = ({ children }) => {
  return (
    <>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <OnlyStudentCurrentSchoolPeriod children={children} />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <OnlyTeacherCurrentSchoolPeriod children={children} />
      </OnlyProfile>
    </>
  )
}

export default OnlyCurrentSchoolPeriod
