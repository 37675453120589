import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
// import pt from 'i18n/pt.json'
// import en from 'i18n/en.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//   // en: {
//   //   translation: { ...en }
//   // },
//   'pt-BR': {
//     translation: { ...pt }
//   }
// }

const backendOptions = {
  // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
  queryStringParams: { v: new Date().toISOString().split('T')[0] }
}

const detectionOptions = {
  order: ['querystring', 'htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain']
}

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // resources,
    fallbackLng: 'pt-BR',
    debug: false,
    detection: detectionOptions,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: backendOptions,
    keySeparator: false,
    nsSeparator: false,
    ns: ['translation', 'cloe_expand'],
    defaultNS: 'translation',
    react: {
      useSuspense: false
    }
  })

export default i18n
