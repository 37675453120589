import { Field, FieldProps, FormikErrors } from 'formik'
import { Button, TextField } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from '../../style'
import { Grid } from '@mui/material'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { NavigateBefore } from '@mui/icons-material'

interface ICompleteRegistrationProps {
  dirty: boolean
  onCancel: () => void
  onSubmit: () => void
  previousStep: () => void
  errors?: FormikErrors<any>
}

interface FormValues {
  cpf: string
  name: string
  surname: string
  birthDate: string
  email: string
  phone: string
}

export const CompleteRegistration: React.FC<ICompleteRegistrationProps> = ({ dirty, onCancel, previousStep, onSubmit, errors }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const isTherevalidationErrors = !!errors?.cpf || !!errors?.name || !!errors?.surname || !!errors?.email || !!errors?.birthDate
  const buttonDisabled = !dirty || isTherevalidationErrors

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div onClick={previousStep} className={styles.returnLink} data-testid='autonomous_registration_personal_info_page_back'>
          <NavigateBefore fontSize='small' />
          <span>{t('Voltar')}</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.purpleTitle}>
          <AdminPanelSettingsOutlinedIcon sx={{ marginRight: '8px' }} /> {t('Cadastro autônomo')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.description} style={{ fontSize: '14px' }}>
          {t('* Campo obrigatório')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Field name='cpf'>
          {({
            field,
            form: { isSubmitting },
            meta: { error }
          }: FieldProps<FormValues>) => (
            <TextField
              {...field}
              id='cpf'
              mask='999.999.999-99'
              variant='outlined'
              label={'CPF*'}
              error={!!error}
              assistiveText={error}
              className={styles.input}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name='name'>
          {({
            field,
            form: { isSubmitting },
            meta: { error, touched }
          }: FieldProps<FormValues>) => (
            <TextField
              {...field}
              id='name'
              variant='outlined'
              label={`${t('Nome*')}`}
              error={!!error && !!touched}
              assistiveText={touched ? error : ''}
              className={styles.input}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name='surname'>
          {({
            field,
            form: { isSubmitting },
            meta: { error, touched }
          }: FieldProps<FormValues>) => (
            <TextField
              {...field}
              id='surname'
              variant='outlined'
              label={`${t('Sobrenome*')}`}
              error={!!error && !!touched}
              assistiveText={touched ? error : ''}
              className={styles.input}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name='birthDate'>
          {({
            field,
            form: { isSubmitting },
            meta: { error, touched }
          }: FieldProps<FormValues>) => (
            <TextField
              {...field}
              id='birthDate'
              mask='99/99/9999'
              variant='outlined'
              label={`${t('Data de nascimento*')}`}
              error={!!error && !!touched}
              assistiveText={touched ? error : ''}
              className={styles.input}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name='email'>
          {({
            field,
            form: { isSubmitting },
            meta: { error, touched }
          }: FieldProps<FormValues>) => (
            <TextField
              {...field}
              id='email'
              variant='outlined'
              label={`${t('E-mail*')}`}
              error={!!error && !!touched}
              assistiveText={touched ? error : ''}
              className={styles.input}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field name='phone'>
          {({
            field,
            form: { isSubmitting },
            meta: { error, touched }
          }: FieldProps<FormValues>) => (
            <TextField
              {...field}
              id='phone'
              mask='(99) 99999-9999'
              variant='outlined'
              label={`${t('Celular')}`}
              error={!!error && !!touched}
              assistiveText={touched ? error : ''}
              className={styles.input}
            />
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={`${!buttonDisabled && 'active'} ${styles.submitButton}`}
          variant='primary'
          onClick={onSubmit}
          type='submit'
          data-testid='autonomous_registration_personal_info_page_register'
          disabled={buttonDisabled}
        >
          {t('Cadastrar')}
          <DoneIcon sx={{ marginLeft: '14px' }} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={styles.cancelButton}
          variant='ghost'
          onClick={onCancel}
          type='button'
          data-testid='autonomous_registration_personal_info_page_close'
        >
          <CloseIcon />
          {t('Fechar cadastro autônomo')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default CompleteRegistration
