import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    lineHeight: theme.font.lineHeight.xs,
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    gap: 8,
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.darkest,

    '& svg': {
      fontSize: theme.font.fontSize.sm
    }
  }
}))
