import { IExpeditionCached, ExpeditionCachedTable } from './../../'
import { makeDatabaseInstanceOffline } from './../../databaseInstance'

export const createExpeditionCached = async (data: IExpeditionCached) => {
  const database = makeDatabaseInstanceOffline()
  await database.expeditionsCached.add(data)
  const expedition = await database.expeditionsCached.where('expeditionId').equals(data.expeditionId).first()

  return expedition as ExpeditionCachedTable
}
