import emptyStateProfessor from 'assets/empty-state-screen-professor.svg'
import { styled, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

interface TaughtDisciplinesStyleProps {
  hasTaughtDisciplines: boolean
}

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      background: theme.colors.neutral.lightBase,
      borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0`,
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0px'
      }
    },
    cardWrapper: {
      display: 'flex'
    },
    yearTitle: {
      marginBottom: '16px',
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.sm,
      color: theme.colors.neutral.darkBase,
      fontWeight: theme.font.fontWeight.medium
    },
    descriptionContent: {
      background: 'rgba(191, 178, 211, 0.16)',
      borderTopLeftRadius: theme.borderRadius.md,
      borderBottomLeftRadius: theme.borderRadius.md,
      paddingInline: theme.spacingInline.xxs,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        paddingBlock: theme.spacingInline.xxxs
      }
    },
    descriptionTitle: {
      marginTop: '70px',
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.lg,
      color: theme.colorBrand.medium,
      paddingBottom: theme.spacingInset.xs,
      [theme.breakpoints.up('lg')]: {
        marginTop: ({ hasTaughtDisciplines }: TaughtDisciplinesStyleProps) => !hasTaughtDisciplines ? '0px' : 'default',
        maxWidth: '264px',
        fontSize: theme.font.fontSize.xl,
        lineHeight: '40px'
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.font.fontSize.xl,
        marginTop: '0'
      }
    },
    descriptionSubtitle: {
      marginTop: '0px',
      paddingBottom: theme.spacingInset.xxs,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.md,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark40,
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacingInset.sm,
        fontSize: theme.font.fontSize.md
      }
    },
    gradeTypeTag: {
      borderRadius: '4px',
      cursor: 'pointer'
    },
    containerEmptyDisciplines: {
      textAlign: 'center',
      paddingBottom: theme.spacingInset.lg
    },
    titleEmptyDisciplines: {
      fontSize: theme.font.fontSize.xxxxl,
      fontWeight: theme.font.fontWeight.semibold,
      color: theme.colors.neutral.dark30,
      fontFamily: theme.font.fontFamily
    },
    subtitleEmptyDisciplines: {
      display: 'block'
    },
    image404: {
      backgroundImage: 'url(\'/images/cat-offline.png\')',
      backgroundSize: '100%',
      maxWidth: '20rem',
      height: '20rem',
      width: '100%',
      maxHeight: '30rem',
      margin: 'auto',
      minHeight: 'auto',
      backgroundRepeat: 'no-repeat'
    },
    image: {
      backgroundImage: `url(${emptyStateProfessor})`,
      marginBlock: theme.spacingInline.md,
      backgroundSize: '100%',
      maxWidth: '20rem',
      height: '20rem',
      width: '100%',
      maxHeight: '30rem',
      margin: 'auto',
      minHeight: 'auto',
      backgroundRepeat: 'no-repeat'
    },
    button: {
      marginBlock: `${theme.spacingInline.xxxs} !important`
    },
    boxButton: {
      display: 'inline-grid'
    },
    schoolTitle: {
      fontSize: theme.font.fontSize.lg,
      lineHeight: theme.font.lineHeight.sm,
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.medium,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.xs,
        gap: theme.spacingInline.xxs,
        lineHeight: theme.font.lineHeight.xs,
        width: 'inherit'
      }
    },
    subTitle: {
      padding: `${theme.spacingInset.md} 0 ${theme.spacingInset.xs}`,
      fontSize: theme.font.fontSize.md,
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.medium,
      gap: theme.spacingInline.xxs,
      lineHeight: theme.font.lineHeight.xs,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.font.fontSize.md,
        lineHeight: theme.font.lineHeight.sm
      }
    },
    schoolName: {
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      fontWeight: theme.font.fontWeight.bold,
      color: theme.colors.neutral.dark30
    },
    boxFooterButtons: {
      paddingTop: theme.spacingInset.md,
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button': {
        flex: '0 1 auto'
      }
    },
    notFoundContainer: {
      width: '100%',
      textAlign: 'center',
      color: theme.colors.contentType.default
    }
  }))

export const MobileContent = styled('div')(({ theme }) => ({
  display: 'block',
  background: 'white',
  [theme.breakpoints.up('sm')]: {
    visibility: 'hidden',
    width: 0,
    height: 0
  }
}))

export const DesktopContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    visibility: 'hidden',
    width: 0,
    height: 0
  }
}))
