import { Grid, Skeleton } from '@mui/material'

import useStyles from './style'

export const Loading: React.FC = () => {
  const classes = useStyles()
  const fakeList = [0, 1]

  return (
    <Grid container xs={12} display='flex' flexDirection='row' justifyContent='space-between'>
      {fakeList.map(row => (
        <Grid container lg={5.9} xs={12} display='flex' flexDirection='column' justifyContent='space-between' className={classes.cardStudentAnswerContainer}>
          <Grid item display='flex' flexDirection='row' justifyContent='space-between' className={classes.cardStudentAnswerContentInfo}>
            <Grid item display='flex' gap={1} alignItems='flex-start'>
              <Skeleton animation='wave' width='40px' height='40px' />

              <Grid item display='flex' flexDirection='column'>
                <Skeleton animation='wave' width='60px' height='15px' />
                <Skeleton animation='wave' width='60px' height='15px' />
              </Grid>
            </Grid>

            <Skeleton animation='wave' width='80px' height='40px' />
          </Grid>
          <Grid item gap={1.2} display='flex' flexDirection='row' justifyContent='flex-start' className={classes.cardStudentAnswerContentLevel}>
            <Grid item lg={2.2} xs={3.7} gap='4px' display='flex' alignItems='center'>
              <Skeleton animation='wave' width='50px' height='32px' />
              <Skeleton animation='wave' width='50px' height='32px' />
              <Skeleton animation='wave' width='50px' height='32px' />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default Loading
