import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IScheduleCardProps } from '.'
import { IUserSchoolProfileTypeEnum } from 'services/types'

export default makeStyles((theme: Theme) =>
  createStyles({
    scheduleCardContainer: {
      textDecoration: 'none'
    },
    activityCardItem: {
      flexDirection: 'column',
      contain: 'content',
      backgroundColor: theme.colors.neutral.lightBase,
      width: '300px',
      position: 'relative',
      height: '230px',
      padding: theme.spacingStack.xxxs,
      display: 'inline-block',
      borderRadius: theme.spacingStack.nano,
      cursor: 'pointer',
      '& *': { cursor: 'pointer !important' },

      '&:hover': {
        boxShadow: theme.shadowLevels.level3
      },
      '&:active': {
        boxShadow: theme.shadowLevels.level3
      }
    },
    disciplineContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacingStack.xxxs,
      '& label': {
        marginRight: theme.spacingInline.nano
      }
    },
    dataTag: {
      backgroundColor: `${theme.colorBrand.medium}57`,
      color: theme.colorBrand.darkest,
      '&.active': {
        backgroundColor: theme.colors.negative.feedbackMedium,
        color: theme.colors.neutral.lightBase,
        fontWeight: theme.font.fontWeight.regular
      }
    },
    title: {
      minHeight: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      fontWeight: theme.font.fontWeight.bold,
      color: theme.colorBrand.medium
    },
    descriptionContainer: {
      fontSize: theme.font.fontSize.sm,
      textOverflow: 'ellipsis',
      lineHeight: '100%',
      display: 'flex',
      alignItens: 'center',
      '& svg': {
        marginRight: theme.spacingStack.xxxs
      },
      marginTop: theme.spacingStack.xxxs,
      [theme.breakpoints.up('sm')]: {
        lineHeight: theme.font.lineHeight.xs,
        overflow: 'hidden',
        alignItems: 'center',
        width: '100%',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        display: 'flex',
        fontSize: ({ profileType }: IScheduleCardProps) =>
          profileType === IUserSchoolProfileTypeEnum.teacher
            ? theme.font.fontSize.md
            : theme.font.fontSize.xs
      }
    },
    contentLinkIcon: {
      fontSize: '26px',
      color: theme?.colors.neutral.dark20,
      '&.MuiSvgIcon-root': {
        fontSize: '42px'
      }
    },
    footerContainer: {
      display: 'flex',
      width: '265px',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      justifyContent: 'space-between',
      paddingBottom: '16px'
    },
    contentLinkTags: {
      display: 'flex',
      gap: theme.spacingStack.nano
    },
    footerActions: {
      display: 'flex',
      textDecoration: 'none',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.colorBrand.medium
    }
  })
)
