/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to
 * keep your app working for UI changes and
 * make it independent of network requirements.
 *
 * They need to be categorized and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 *
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

// Actual endpoints. Uncomment below section to use actual data.
// export const GET_ALL_USERS = () => `https://jsonplaceholder.typicode.com/users`
export const USER_CPF = (cpf: number) => `${process.env.REACT_APP_BACKEND_URL as string}/users/cpf/${cpf}`
export const USER_EMAIL = (email: string) => `${process.env.REACT_APP_BACKEND_URL as string}/users/email/${email}`
export const USER_LOGIN = () => `${process.env.REACT_APP_BACKEND_URL as string}/auth/local`
export const USER_ME = () => `${process.env.REACT_APP_BACKEND_URL as string}/users/me`
export const USER_PHONE = (phone: number) => `${process.env.REACT_APP_BACKEND_URL as string}/users/phone/${phone}`
export const USER_PROFILE = (userId?: number) => `${process.env.REACT_APP_BACKEND_URL as string}/user-profiles`
// after data alter this query because user_profile is not directly associate to user_me
export const USER_REGISTER = () => `${process.env.REACT_APP_BACKEND_URL as string}/auth/local/register`
export const USER_USERNAME_IDENTIFIER = (identifier: string) => `${process.env.REACT_APP_BACKEND_URL as string}/v1/users/get-usernames-by-identifier/${identifier}`
export const USER_TOKEN_VALIDATION = (token: string) => `${process.env.REACT_APP_BACKEND_URL as string}/registration-tokens/validate/${token}`
export const USER_FORGOT_PASSWORD = () => `${process.env.REACT_APP_BACKEND_URL as string}/auth/forgot-password`
export const USER_RESET_PASSWORD = () => `${process.env.REACT_APP_BACKEND_URL as string}/auth/reset-password`
export const USER_CHANGE_DEFAULT_PASSWORD = () => `${process.env.REACT_APP_BACKEND_URL as string}/auth/updatePassword`
export const USER_RESET_PASSWORD_TOKEN_VALIDATION = (token: string) => `${process.env.REACT_APP_BACKEND_URL as string}/auth/reset-password/${token}/check`
// Local endpoints. Uncomment below section to use dummy local data.
// export const GET_ALL_USERS = () => `/data/users`
// export const GET_USER_DETAILS = (id: BigInteger) => `/data/user`
export const USER_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/users`
export const USER_GET_BY_CPF = (userId: number) => `${USER_BASE()}?cpf=${userId}`
export const USER_GET_BY_ID = (userId: number) => `${USER_BASE()}/${userId}`
export const USER_UPDATE = (userId: number) => `${USER_BASE()}/${userId}`

// User session data
export const SESSION = () => '/users/session'
