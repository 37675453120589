export enum OfflineTableExpeditionCachedStatus {
  FULL_DOWNLOADED = 'FULL_DOWNLOADED',
  INITIAL = 'INITIAL',
  PARTIAL_DOWNLOADED = 'PARTIAL_DOWNLOADED',
  DOWNLOADING = 'DOWNLOADING',
  WAIT_INTERNET = 'WAIT_INTERNET',
  WAIT_REOPEN = 'WAIT_REOPEN',
  ERROR = 'ERROR',
  REMOVING = 'REMOVING'
}

export interface ExpeditionSize {
  content: number
  files: number
}

export interface IExpeditionCached {
  id?: number
  expeditionId: number
  status: OfflineTableExpeditionCachedStatus
  successUrls: string[]
  errorUrls: string[]
  embeddedUrls: string[]
  size: ExpeditionSize
  createdAt: Date
  cachedAt: Date
}
