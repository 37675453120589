import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Grid } from '@mui/material'
import { AddOutlined, WatchLaterOutlined } from '@mui/icons-material'
import { StatusClassHandle, CurrentClass, LoadingWrapper } from 'components/common'
import { Button } from 'components/design-system'
import { toast } from 'components/design-system/Toast/manager'
import BackToTopWindow from 'components/common/BackToTopWindow'
import { MyCloe } from 'navigation/components/MyCloe'
import { ScheduleCreator } from 'navigation/components/MyCloe/components'
import { useToggleOpenMyCloeAtom } from 'navigation/components/MyCloe/atomStore'
import { reloadScheduleAtom } from 'navigation/components/MyCloe/components/ScheduleCreator/atomStore'
import { IDisciplineResponse, IDisciplineStore, ActivityControlStatusEnum } from 'services/types'
import { getSchedulesByDay } from 'services/schedule'
import { IScheduleByDayResponse, IScheduleResponse } from 'services/types/schedule'
import { useCurricularStore } from 'store'
import { ScheduleCard, Calendar, ScheduleOriginalContent, EmptyStateComponent, PendingActivitiesBox } from '../../../../common'
import useStyle from './style'
import { useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { currentSubMenuTabAtom } from 'pages/ContentUnit/components/SideMenu/components/atomStore'
import { SubTabsMenusTeacher } from 'pages/ContentUnit/atomStore'

export const Ready: React.FC = () => {
  dayjs.extend(customParseFormat)
  const { currentDiscipline, disciplines } = useCurricularStore()
  const { classId } = useParams<{ classId: string }>()
  const { t } = useTranslation()
  const classes = useStyle()

  // states
  const [schedules, setSchedules] = useState<IScheduleByDayResponse[] | null>(null)
  const [currentDate, setCurrentDate] = useState<Date>()
  const [, setHasResponseError] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentSchedule, setCurrentSchedule] = useState<IScheduleResponse>()
  const [isLoadingSchedules, setIsLoadingSchedules] = useState<boolean>(true)

  // atoms
  const [reloadSchedule, setReloadSchedule] = useAtom(reloadScheduleAtom)
  const { toggleOpenMyCloeAtom } = useToggleOpenMyCloeAtom()
  const setOpenMyCloe = useUpdateAtom(toggleOpenMyCloeAtom)
  const [, setCurrentSubMenuTabTeacher] = useAtom(currentSubMenuTabAtom)

  const getSchedulesData = async () => {
    if (!currentDiscipline) return false
    setHasResponseError(false)
    try {
      const day = dayjs(currentDate?.toLocaleString('en-US'))
      const utcOffset = day.format('Z')
      const response = await getSchedulesByDay(classId, day.format('YYYY-MM-DD HH:mm:ssZ'), currentDiscipline?.id, utcOffset)
      if (response.success) {
        setSchedules(response.data)
      } else {
        setSchedules([])
      }
    } catch (ex) {
      setHasResponseError(true)
    } finally {
      setReloadSchedule(false)
      setIsLoadingSchedules(false)
    }
  }

  useEffect(() => {
    if (reloadSchedule) {
      setIsLoadingSchedules(true)
      getSchedulesData()
    }
  }, [reloadSchedule])

  useEffect(() => {
    if (currentDate) {
      setIsLoadingSchedules(true)
      getSchedulesData()
    }
  }, [currentDate, currentDiscipline])

  const handleSubMenuTabsContentUnit = (schedule: IScheduleResponse) => {
    const activitiesAvailable = schedule.activity?.activity_status?.status === ActivityControlStatusEnum.AVAILABLE && schedule.activity?.custom_type !== 'clone'
    const activitiesInProgress = schedule.activity?.activity_status?.status && [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.DONE].includes(schedule.activity?.activity_status?.status)
    const activitiesCorrection = schedule.activity?.activity_status?.status && [ActivityControlStatusEnum.CLOSED].includes(schedule.activity?.activity_status?.status)
    const activitiesCompleted = Number(schedule.activity?.activity_status?.status) === ActivityControlStatusEnum.RELEASED

    if (activitiesAvailable) setCurrentSubMenuTabTeacher(SubTabsMenusTeacher.ORIGINALS)
    if (activitiesInProgress) setCurrentSubMenuTabTeacher(SubTabsMenusTeacher.LIBERATED)
    if (activitiesCorrection) setCurrentSubMenuTabTeacher(SubTabsMenusTeacher.CORRECTION)
    if (activitiesCompleted) setCurrentSubMenuTabTeacher(SubTabsMenusTeacher.FINISHED)
  }

  const errorMessage = (currentDiscipline?: IDisciplineStore | null) => {
    return `${t('Você ainda não associou nenhum conteúdo a esta data.\nQue tal organizar a rotina da sala de aula criando um evento com o conteúdo que será aplicado?')}`
  }

  return (
    <>
      <StatusClassHandle>
        <CurrentClass>
          <PendingActivitiesBox />
        </CurrentClass>
      </StatusClassHandle>
      <Grid item xs={12}>
        <Calendar
          classId={classId}
          disciplineId={currentDiscipline?.id}
          onSelectDate={setCurrentDate}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: { sm: 1, md: 4 } }}>
        <LoadingWrapper loading={isLoadingSchedules}>
          {
            schedules?.length
              ? schedules.map((item, i) =>
                <Grid key={i} item xs={12}>
                  <Grid item xs={12}>
                    <div className={classes.schedulesTime}>
                      <WatchLaterOutlined /> {dayjs(item.date).format('HH:mm')}
                    </div>
                  </Grid>
                  <Grid item xs={12} mt={3} mb={4} display='flex' flexWrap='wrap' gap={2}>
                    {
                      item.schedules.map(schedule =>
                        <ScheduleCard
                          key={schedule.id}
                          coverUrl={
                            schedule.content_unit?.cover_mobile?.formats?.small?.url ??
                            schedule.content_unit?.cover_mobile?.formats?.medium?.url ??
                            schedule.content_unit?.cover_mobile?.formats?.large?.url ??
                            schedule.content_unit?.cover_mobile?.formats?.thumbnail?.url ??
                            schedule.content_unit?.cover_mobile?.url ?? ''
                          }
                          onDelete={() => {
                            toast.handler({
                              content: t('Evento excluído!'),
                              duration: 10000,
                              severity: 'success'
                            })
                          }}
                          onClick={() => {
                            if (schedule.type === 'original_content') {
                              setCurrentSchedule(schedule)
                              setIsOpen(true)
                            }
                            handleSubMenuTabsContentUnit(schedule)
                          }}
                          {...schedule}
                        />
                      )
                    }
                  </Grid>
                </Grid>)
              : (
                <EmptyStateComponent errorMessage={errorMessage(currentDiscipline)}>
                  <StatusClassHandle>
                    <CurrentClass>
                      <Grid item xs={12} className={classes.emptyStateTitle}>
                        <div className={classes.emptyStateTitle}>
                          <Button
                            className={classes.createEventButton}
                            onClick={() => setOpenMyCloe(true)}
                            data-testid='schedule_create_new_event'
                            startIcon={<AddOutlined />}
                          >
                            {t('Criar evento')}
                          </Button>
                        </div>
                      </Grid>
                    </CurrentClass>
                  </StatusClassHandle>
                </EmptyStateComponent>
              )
          }
        </LoadingWrapper>
      </Grid>
      <BackToTopWindow />
      {
        currentSchedule &&
        <ScheduleOriginalContent
          {...currentSchedule}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(!isOpen)
            setCurrentSchedule(undefined)
          }}
        />
      }
      <StatusClassHandle>
        <CurrentClass>
          <MyCloe>
            <ScheduleCreator
              discipline={disciplines as IDisciplineResponse[]}
              currentDisciplineId={currentDiscipline?.id as string}
            />
          </MyCloe>
        </CurrentClass>
      </StatusClassHandle>
    </>
  )
}

export default Ready
