/* eslint-disable no-unreachable-loop */
import {
  addRequestToCache,
  makeRequestOptions,
  makeCacheEmbedded,
  makeUrlWithParams,
  makeEachPromises,
  removeRequestFromCache,
  CachedFileResponse,
  PipeParams,
  CachedContentData,
  CacheManagerException,
  CachedContentResponse,
  sleep,
  SLEEP_TIME_BETWEEN_REQUEST
} from './'

export const contentCachePipe = <T = unknown>(data: PipeParams): CachedContentResponse<T> => {
  const cachedEmbeddedItems: string[] = []
  const contentSize = {
    files: 0,
    content: 0
  }

  const onCachedImage = (response: CachedFileResponse) => {
    const { onCompletedFile } = data
    Object.assign(contentSize, {
      files: contentSize.files + response.size
    })
    cachedEmbeddedItems.push(response.data as string)
    onCompletedFile?.(response.data as string)
  }

  const makeAddRequestToCache = async (request: Request) => {
    const { hasEmbeddedContent, throwsIfEmbeddedContentNotFound } = data
    const cacheData: CachedContentData<T> = {
      cachedEmbedded: null,
      cachedResponse: null
    }
    // TODO: colocar sleep aqui para diminuir a carga do back-end, requests simultâneas
    await sleep(SLEEP_TIME_BETWEEN_REQUEST)
    const response = await addRequestToCache(request)
    if (response instanceof CacheManagerException) {
      throw response
    }
    Object.assign(contentSize, {
      content: contentSize.content + response.size
    })
    Object.assign(cacheData, { cachedResponse: response.data })

    if (hasEmbeddedContent) {
      try {
        await makeCacheEmbedded(response, onCachedImage)
        Object.assign(cacheData, { cachedEmbedded: cachedEmbeddedItems })
      } catch (error) {
        if (throwsIfEmbeddedContentNotFound) {
          removeRequestFromCache(request)
          throw error
        }
        if (error instanceof CacheManagerException) {
          console.error(error.error)
        }
      }
    }

    return cacheData
  }

  const fetchEach = async (): Promise<Array<CachedContentData<T>>> => {
    const { onCompleted, onError } = data
    try {
      const values = await Promise.all(makeEachPromises({
        pipeParams: data,
        addRequestToCache: makeAddRequestToCache
      }))
      onCompleted?.(values, data)
      return values
    } catch (error) {
      onError?.(error, data)
      throw error
    }
  }

  const fetchUnique = async (): Promise<CachedContentData<T>> => {
    const { url, headers, params, onCompleted, onError } = data
    try {
      const options = makeRequestOptions(headers)

      const value = await makeAddRequestToCache(new Request(makeUrlWithParams(url, params), options))
      onCompleted?.(value, data)
      return value
    } catch (error) {
      onError?.(error, data)
      throw error
    }
  }

  return { contentSize, fetchUnique, fetchEach }
}
