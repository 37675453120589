import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    height: '100%',

    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      padding: '0px',
      borderRadius: 'none',
      height: 'auto'
    }
  },
  buttonContainer: {
    display: 'inline-grid'
  },
  tabsPanelWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px !important',
      paddingInline: theme.spacingInline.xxxs
    },
    '& [role=tabpanel]': {
      width: '100%'
    }
  },
  button: {
    marginBlock: theme.spacingStack.xs
  }
}))
