import React, { useEffect, useState } from 'react'
import { BigNumbersLoading, BigNumbersReady, IBigNumbersData } from 'components/common/reports'
import { useCurricularStore, useStore } from 'store'
import { IClassPerformance } from 'services/types'
import { getClassPerformance } from 'services/cloe-reports'
import { useTranslation } from 'react-i18next'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

export const BigNumbers: React.FC = () => {
  const BigNumberInitialState: IBigNumbersData = {
    data: null,
    error: null
  }

  const { profile } = useStore()
  const { t } = useTranslation()
  const { currentDiscipline, classId } = useCurricularStore()
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [bigNumbersData, setBigNumbersData] = useState<IBigNumbersData>(BigNumberInitialState)

  useEffect(() => {
    fetchClassReports()
  }, [currentDiscipline])

  const tryAgain = async () => {
    await fetchClassReports()
  }

  const fetchClassReports = async () => {
    setStateMachine(StateMachineEnum.LOADING)
    try {
      if (!currentDiscipline?.id || !classId || !profile?.school?.id) {
        throw new Error('Page info was not found')
      }

      const response = await getClassPerformance({ schoolId: profile?.school?.id, classId, disciplineId: Number(currentDiscipline.id) })

      if (!response.success) {
        if (!response.status || ![400, 404].includes(response.status)) { // TODO: CHANGE THIS 400 to only 404
          throw new Error(response.message)
        }
        response.data = null as unknown as IClassPerformance
      }

      const newClassData: IClassPerformance | null = response.data
      setBigNumbersData({ data: newClassData, error: null })
    } catch (err: any) {
      setBigNumbersData({ data: null, error: t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página') })
    } finally {
      setStateMachine(StateMachineEnum.READY)
    }
  }

  const states = {
    IDLE: <></>,
    LOADING: <BigNumbersLoading />,
    READY: <BigNumbersReady bigNumbersData={bigNumbersData} tryAgainCallback={tryAgain} />
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
