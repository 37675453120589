import { useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'
import { useTheme, useMediaQuery } from '@mui/material'
import { Button } from 'components/design-system'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'

export const ExpeditionQuestionNavigation: React.FC<{ index: number }> = ({ index }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { questionIndex, setQuestionIndex, activity } = useStudentAnswer()
  const questionsNumber = activity?.questions.length ?? 0

  const tagginRefBackButton = useRef<HTMLDivElement>(null)
  const tagginRefNextButton = useRef<HTMLDivElement>(null)

  const scrollToSelected = () => {
    setTimeout(() => {
      const element = document.getElementById('selected-question')
      if (element) {
        if (isMobile) {
          const offset = 176
          const bodyRect = document.body.getBoundingClientRect().top
          const elementRect = element.getBoundingClientRect().top
          const elementPosition = elementRect - bodyRect
          const offsetPosition = elementPosition - offset

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          })
        } else {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, 300)
  }

  useEffect(() => {
    scrollToSelected()
  }, [questionIndex])

  const handleQuestionNavigation = (index: number) => {
    setQuestionIndex(index)
    scrollToSelected()
  }

  return (
    <>
      <div ref={tagginRefBackButton} data-testid='respond_activities_back_question' hidden />
      <div ref={tagginRefNextButton} data-testid='respond_activities_next_question' hidden />
      {!isMobile &&
        <Grid
          container
          justifyContent={index !== 0 ? 'space-between' : 'flex-end'}
        >
          {
            index !== 0 &&
            <Grid item>
              <Button
                variant='ghost'
                sx={{ alignSelf: 'end', gap: theme.spacingInline.xxxs }}
                onClick={() => {
                  tagginRefBackButton?.current?.click()
                  handleQuestionNavigation(index - 1)
                }}
              >
                <ArrowUpwardIcon />
                {t('Questão anterior')}
              </Button>
            </Grid>
          }
          {
            !(index === questionsNumber - 1) &&
            <Grid item>
              <Button
                variant='ghost'
                sx={{ alignSelf: 'end', gap: theme.spacingInline.xxxs }}
                onClick={() => {
                  tagginRefNextButton?.current?.click()
                  handleQuestionNavigation(index + 1)
                }}
              >
                <ArrowDownwardIcon />
                {t('Próxima questão')}
              </Button>
            </Grid>
          }
        </Grid>
      }
      {
        !(index === questionsNumber - 1) && <Divider sx={{ margin: '32px 0 24px' }} />
      }
    </>
  )
}
