import {
  IApiResponse,
  IProfileResponse,
  IUpdateLastUsedProfileRequest
} from 'services/types'
import { request } from 'services/base'
import { UPDATE_LAST_USED_PROFILE } from './CONSTANTS'

export const updateLastUsedProfile = async (updateLastUsedProfileRequest: IUpdateLastUsedProfileRequest): Promise<IApiResponse<IProfileResponse>> =>
  await request({ url: UPDATE_LAST_USED_PROFILE(), method: 'PATCH', data: updateLastUsedProfileRequest })
