import { Text, Button, Tag } from 'components/design-system'
import Modal from '@mui/material/Modal'
import { useAtom } from 'jotai'
import {
  changeschoolMenuStateSchoolsAtom,
  activeChangeClassAtom,
  activeModalConfirmationAtom
} from 'navigation/atomStore'
import useStyle from './style'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import Grid from '@mui/material/Grid'
import * as SvgIcon from '@mui/icons-material'

export const ChangeSchool: React.FC = () => {
  const { profile, session } = useStore()
  const [activeModalConfirmation, setActiveModalConfirmation] = useAtom(activeModalConfirmationAtom)
  const [activeMenuClass, setActiveMenuClass] = useAtom(activeChangeClassAtom)
  const [localProfile, setLocalProfile] = useAtom(changeschoolMenuStateSchoolsAtom)

  // school is enabled
  const enabledProfiles = session?.profiles.filter(pro => pro.school.enabled) ?? []

  const { t } = useTranslation()
  const classes = useStyle()

  const handleActiveMenu = () => {
    setActiveMenuClass(!activeMenuClass)
    setLocalProfile(null)
  }

  const selectSchools = () => {
    if (localProfile?.id === profile?.id || localProfile === null) {
      return null
    } else {
      setActiveModalConfirmation(!activeModalConfirmation)
      setActiveMenuClass(!activeMenuClass)
    }
  }

  return (
    <Modal className={classes.modalContainer} open={activeMenuClass} onClose={handleActiveMenu}>
      <Box className={classes.boxModal}>
        <div className={classes.classContainer}>
          <Grid item>
            <a className={classes.iconChangeClass} onClick={handleActiveMenu} data-testid='change_profile_back_my_classes'>
              <SvgIcon.KeyboardArrowLeftRounded className={classes.iconSvg} />
              <Text type='body' color='medium' className={classes.classChangeTitle}>{t('Trocar escola')}</Text>
            </a>
          </Grid>
          <Grid item className={classes.containerInforamtion}>
            <div className={classes.classInforamtionTitle}>
              <Text type='body' size='small'>
                {t('Escola atual')}
              </Text>
            </div>
            <div className={classes.classInforamtionText}>
              {profile?.school?.name}
            </div>
          </Grid>
        </div>
        <Divider className={classes.classesDivider} />
        <Grid container className={classes.container}>
          <Grid className={classes.classesContainer}>
            {enabledProfiles.map((item) => {
              return (
                <Grid
                  className={`${classes.classesItens} ${localProfile
                    ? localProfile.id === item.id && 'selected'
                    : profile?.id === item.id && 'selected'
                  }`}
                  key={item.id}
                  onClick={() => setLocalProfile(item)}
                  data-testid='change_profile_profile_list'
                >
                  <Text className={`${classes.classesItensText} ${localProfile
                    ? localProfile.id === item.id && 'selected'
                    : profile?.id === item.id && 'selected'
                  }`} type='body'>
                    {item.school?.name}
                  </Text>
                  <Grid className={classes.tagsContainer}>
                    <Tag className={classes.tag}>
                      {t(item.type)}
                    </Tag>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
          <Grid className={classes.classButton}>
            <Button
              className={classes.classButtonItem}
              variant='primary'
              onClick={selectSchools}
              data-testid='change_profile_select'
            >
              {t('Selecionar')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
