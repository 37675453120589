import { Grid, Skeleton } from '@mui/material'

const LoadingSkillTaxonomies: React.FC = () => {
  return (
    <Grid item xs={12} lg={3}>
      <Grid
        item
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
      >
        <Grid item xs={12} lg={12} mb={2}>
          <Skeleton animation='wave' width={150} height={30} />
        </Grid>

        <Grid item xs={12} lg={12} container justifyContent='flex-start'>
          <Grid item xs={4} lg={4} mb={2}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
          </Grid>

          <Grid item xs={4} lg={4} mb={2}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
          </Grid>

          <Grid item xs={4} lg={4} mb={2}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
          </Grid>

          <Grid item xs={4} lg={4} mb={2}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
          </Grid>

          <Grid item xs={4} lg={4} mb={2}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
          </Grid>

          <Grid item xs={4} lg={4} mb={2}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
          </Grid>

          <Grid item xs={4} lg={4} mb={2}>
            <Skeleton animation='wave' variant='rectangular' width={90} height={30} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LoadingSkillTaxonomies
