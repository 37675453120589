import { AnalyticsProvider, AnalyticsGoogle, AnalyticsAmplify } from './providers'
import { IAnalytics } from './interfaces'

const google = new AnalyticsGoogle()

const providers: IAnalytics[] = [google]
if (process?.env?.REACT_APP_AMPLIFY_IDENTITY_POOL_ID && process?.env?.REACT_APP_AMPLIFY_APP_ID && process?.env?.REACT_APP_AMPLIFY_REGION) {
  const amplify = new AnalyticsAmplify(`${process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID}`, `${process.env.REACT_APP_AMPLIFY_APP_ID}`, `${process.env.REACT_APP_AMPLIFY_REGION}`)
  providers.push(amplify)
}

export const Analytics = new AnalyticsProvider(providers)

export default Analytics
