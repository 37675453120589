import { Grid, Skeleton } from '@mui/material'

import useStyles from './style'

export const Loading: React.FC = () => {
  const classes = useStyles()
  const fakeList = [0, 1]

  return (
    <>
      {fakeList.map(row => (
        <Grid key={row} container display='flex' flexDirection='column' justifyContent='space-between' className={classes.cardLoadStudentAnswerContainer}>
          <Grid item display='flex' flexDirection='row' justifyContent='space-between' >
            <Grid item display='flex' gap={1} alignItems='center'>
              <Skeleton animation='wave' width='50px' height='80px' />
              <Grid item display='flex' flexDirection='column' alignItems='center'>
                <Skeleton animation='wave' width='100px' />
                <Skeleton animation='wave' width='100px' />
              </Grid>
            </Grid>

            <Skeleton animation='wave' width='30px' height='50px' />
          </Grid>

          <Grid item width='100%' display='flex'>
            <Skeleton animation='wave' width='100%' height='120px' />
          </Grid>

          <Grid item display='flex' gap={2}>
            <Skeleton animation='wave' width='80px' height='50px' />
            <Skeleton animation='wave' width='80px' height='50px' />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default Loading
