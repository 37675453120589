import React from 'react'
import {
  InsertDriveFileOutlined,
  PlayCircleOutlined,
  PictureAsPdf,
  VolumeUpOutlined
} from '@mui/icons-material'
import { AudioPlayer } from 'components/common/AudioPlayer'
import useStyles from './style'

export interface IAnsweredFileProps {
  src: string
  modalOpen?: boolean
  description?: string | null
}

const AnsweredFile = (props: IAnsweredFileProps) => {
  const { src, modalOpen, description } = props
  const classes = useStyles(props)

  // Adicionamos um query string na url dos aquivos, afim de ganhar performance(o front esta adicionando manualmente)
  // Porem, nem todos os aquivos irão conter essa query
  const urlParts = src.split('?')
  const urlWithoutQueryString = urlParts[0]
  const urlExtension = urlWithoutQueryString.split('.')[src.split('.').length - 1]

  const renderFormat = () => {
    switch (urlExtension) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'webm':
        return (
          <LinkWrapper link={modalOpen ? src : ''} className={classes.linkWrapper}>
            <div className={classes.fileWrapper}>
              <video muted>
                <source src={src} type={`video/${urlExtension}`} />
              </video>
              <PlayCircleOutlined />
            </div>
          </LinkWrapper>
        )
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'png':
      case 'jpeg':
        return (
          modalOpen
            ? <img src={src} alt={description ?? ''} className={classes.imageModal} />
            : <div className={classes.fileWrapper}>
            </div>
        )
      case 'pdf':
        return (
          <LinkWrapper link={modalOpen ? src : ''} className={classes.linkWrapper}>
            <div className={classes.fileWrapper}>
              <PictureAsPdf />
            </div>
          </LinkWrapper>
        )
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ogg':
        return (
          modalOpen
            ? <div className={classes.audioContainer}>
              <AudioPlayer url={src} />
            </div>
            : <div className={classes.fileWrapper}>
              <VolumeUpOutlined />
            </div>
        )
      default:
        return (
          <LinkWrapper link={modalOpen ? src : ''} className={classes.linkWrapper}>
            <div className={classes.fileWrapper}>
              <InsertDriveFileOutlined />
            </div>
          </LinkWrapper>
        )
    }
  }

  return renderFormat()
}

interface ILinkWrapper {
  link: string
  className: string
}

export const LinkWrapper: React.FC<ILinkWrapper> = ({ link, className, children }) => {
  return (
    link
      ? <a target='_blank' href={link} className={className}>
        <>{children}</>
      </a>
      : <>{children}</>
  )
}

export default AnsweredFile
