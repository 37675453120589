import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    navigationButtonsContainer: {
      width: '100%',
      paddingTop: theme.spacingInline.xs
    }
  })
)
