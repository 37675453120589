import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IStyleProps {
  maxMessageWidth?: string
}

export default makeStyles((theme: Theme) => createStyles({
  errorMessage: ({ maxMessageWidth }: IStyleProps) => ({
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.sm,
    textAlign: 'center',
    color: theme.colors.neutral.dark30,
    width: '759px',
    margin: '0 auto',
    whiteSpace: 'pre-wrap',
    ...(maxMessageWidth ? { maxWidth: maxMessageWidth } : {}),

    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: theme.font.fontSize.md
    }
  }),
  title: {
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium,
    paddingBottom: theme.spacingInset.xs,
    '& span': {
      color: theme.colorBrand.medium
    }
  }
}))
