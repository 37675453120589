import { OnlyProfile } from 'components/common'
import { Text2 } from 'components/design-system'
import { IUserSchoolProfileTypeEnum } from 'contentCacheManager'
import { useTranslation } from 'react-i18next'

export const NotFoundState = () => {
  const { t } = useTranslation()
  return (
    <Text2
      sx={{ width: '100%', margin: '16px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      fontSize='sm'
      mobile='sm'
      fontWeight='medium'
      lineHeight='xxs'
      neutral='dark30'
    >
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        {t('Você respondeu todas as atividades e avaliações.')}
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        {t('Nada para corrigir no momento.')}
      </OnlyProfile>
    </Text2>
  )
}
