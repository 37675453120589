import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bigNumbersItem: {
    background: `${theme.colorBrand.lightest}57`,
    borderRadius: theme.borderRadius.lg,
    boxShadow: '0px 8px 16px rgba(20, 20, 20, 0.16)',

    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'flex-start !important',
    gap: theme.spacingInset.xs,

    padding: theme.spacingInset.sm,

    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: `calc(50% - ${theme.spacingInset.sm})`
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(25% - ${theme.spacingInset.sm})`,
      flexDirection: 'column !important' as 'column',
      justifyContent: 'center !important',
      alignItems: 'flex-start !important'
    }
  },
  bigNumbersCircleContainer: {
    border: `2px solid ${theme.colorBrand.dark}`,
    aspectRatio: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTitle: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.xs,
      'white-space': 'initial !important'
    },
    [theme.breakpoints.up('lg')]: {
      'white-space': 'nowrap !important'
    },
    width: '100%',
    textOverflow: 'ellipsis',
    'white-space': 'nowrap !important'
  },
  boxSubTitle: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.xxs
    },
    textOverflow: 'ellipsis'
  }
}))
