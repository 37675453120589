import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  titleContent: {
    // mobile
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacingInline.xxs
    }
  },
  title: {
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium
  },
  editButton: {
    padding: '8px 16px !important',
    fontSize: '12px !important',
    '& span': {
      marginLeft: '14px',
      // mobile
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    // mobile
    [theme.breakpoints.down('sm')]: {
      '&.primary': {
        width: '32px',
        minWidth: '0',
        height: '32px',
        '& svg': {
          fontSize: '18px'
        }
      }
    }
  }
}))
