import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ContentLinkProps } from '.'

interface IStyleProps extends ContentLinkProps { }

export default makeStyles((theme: Theme) => createStyles({
  contentLink: (props: IStyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '262px',
    minHeight: '144px',
    textDecoration: 'none',
    background: (props?.isLocked ?? props?.clonedTimes === 0) ? 'rgba(230, 230, 230, 0.32)' : theme?.colors.neutral.lightBase,
    borderRadius: theme?.borderRadius.sm,
    border: `1px solid ${theme.colors.neutral.light30}`,
    padding: props.isLink === false ? '0px' : theme?.spacingInset.xs,
    cursor: props.isLocked ? 'default' : 'pointer',
    // eslint-disable-next-line multiline-ternary
    ...(props.isLocked ? {} : {
      '&:hover': {
        transition: props.isLink === false ? 'unset' : 'all ease-in 0.12s',
        transform: props.isLink === false ? 'unset' : 'scale(1.02)'
      }
    }),
    // mobile
    [theme?.breakpoints.down('sm')]: {
      borderColor: theme?.colors.neutral.light30
    }
  }),
  contentLinkHead: {
    position: 'relative',
    display: 'flex',
    minHeight: '50px',
    alignItems: 'center',
    wordBreak: 'break-word',
    '& a': {
      paddingBottom: '0px !important'
    }
  },
  contentLinkIcon: {
    color: ({ isLocked }: IStyleProps) => isLocked ? theme.colors.neutral.dark40 : theme?.colors.neutral.dark20,
    '&.MuiSvgIcon-root': {
      fontSize: activityContent => activityContent ? '32px' : '51px',
      // mobile
      [theme?.breakpoints.down('sm')]: {
        fontSize: '26px'
      }
    }
  },
  contentLinkProgramedIcon: {
    '& path': {
      fill: theme.colors.warning.feedbackMedium
    }
  },
  contentLinkTitle: {
    fontSize: theme?.font.fontSize.xs,
    fontWeight: theme?.font.fontWeight.semibold,
    color: ({ isLocked }: IStyleProps) => isLocked ? theme.colors.neutral.dark40 : theme?.colors.neutral.dark20,
    paddingLeft: theme?.spacingInset.xxs,
    lineHeight: '17.07px',
    textTransform: 'uppercase',
    '& span': {
      lineHeight: theme.font.lineHeight.xs
    }
  },
  contentLinkIsAvailable: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme?.colors.warning.feedbackMedium,
    fontSize: theme.font.fontSize.lg
  },
  contentLinkIsLocked: {
    position: 'absolute',
    top: theme.spacingInset.xxs,
    right: 0,
    color: theme?.colors.neutral.dark40,
    fontSize: theme.font.fontSize.lg
  },
  warningLabels: {
    fontWeight: theme?.font.fontWeight.medium,
    fontSize: theme?.font.fontSize.xs,
    lineHeight: '17px',
    paddingTop: theme.spacingInset.xxs
  },
  contentLinkTags: {
    display: 'flex',
    marginTop: theme?.spacingStack.xxxs,
    gap: theme?.spacingStack.nano
  },
  usedTimes: {
    border: `2px solid ${theme.colorBrand.light}`
  },
  tag: {
    cursor: ({ isLocked }: IStyleProps) => isLocked ? 'default' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacingInline.quarck,
    height: '26px',
    boxSizing: 'border-box',
    fontSize: theme.font.fontSize.xxs
  },
  badgeRead: {
    width: '10px',
    height: '10px',
    background: '#FFB800',
    borderRadius: '9999px'
  },
  tagIsEdited: {
    cursor: 'pointer',
    border: `1px solid ${theme.colorBrand.light} !important`,
    fontSize: theme.font.fontSize.xxs,
    height: '26px'
  },
  quantityQuestions: {
    color: theme.colors.neutral.dark40,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    paddingLeft: theme.spacingInset.lg
  }
}))
