import { Button, Tag } from 'components/design-system'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import { DisciplineCodeEnum, IDisciplineResponse } from 'services/types/discipline'
import { ICoverResponse } from 'services/types/content-unit'
import { NavigateBeforeOutlined } from '@mui/icons-material'
import { NavLink, useParams } from 'react-router-dom'
import { CONTENTS_STUDENT, DASHBOARD_TEACHER } from 'navigation/CONSTANTS'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { useStore } from 'store'
import OnlyProfile from 'components/common/OnlyProfile'
import { DownloadButton } from 'contentCacheManager'
import { Skeleton, useTheme } from '@mui/material'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { StatusSelector } from './components/StatusSelector'

export interface ICoverProps {
  title: string
  disciplines: IDisciplineResponse[]
  suggestedPosition: number
  imageUrl: ICoverResponse
  code?: string
  showReturnButton?: boolean
  showStatusControl?: boolean
}

export const Cover: React.FC<ICoverProps> = props => {
  const { title, disciplines, suggestedPosition, showReturnButton = true, showStatusControl = false } = props
  const { session } = useStore()
  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()
  const classes = useStyles(props)
  const { contentId } = useParams<{ contentId: string, activityId: string, evaluationId: string }>()

  const classSelected = session?.subscriptions.filter(cur => cur.class).find(sub => sub.class?.id?.toString() === classId)?.class

  const isCloeExpand = disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)

  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const ReturnButton = () => (
    <>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <NavLink to={CONTENTS_STUDENT} style={{ textDecoration: 'none' }}>
          <Button variant='outlined' data-testid='content_expedition_return' className={classes.navationButton}>
            <NavigateBeforeOutlined />
            {t('Retornar ao conteúdo', true)}
          </Button>
        </NavLink>
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <NavLink to={DASHBOARD_TEACHER(gradeTypeCode, gradeCode, classId)} style={{ textDecoration: 'none' }}>
          <Button variant='outlined' className={classes.navationButton} data-testid='content_expedition_return'>
            <NavigateBeforeOutlined />
            {`${t('Voltar', true)} ${classSelected?.grades[0].name ?? ''} - ${classSelected?.name ?? ''}`}
          </Button>
        </NavLink>
      </OnlyProfile>
    </>
  )

  return (
    <Grid item xs={12} className={classes.backgroundImage}>
      <div className={classes.boxCover}>
        <Grid width='100%' display='flex' className={classes.headerContent} justifyContent='space-between'>
          {showReturnButton && <ReturnButton />}

          {/* ONLY TEACHER */}
          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
            {showStatusControl && (
              <StatusSelector contentUnitId={contentId} classId={classId} />
            )}
          </OnlyProfile>
        </Grid>

        <div className={classes.disciplineContainer}>
          {
            disciplines.map(d =>
              <Tag key={d.id} variant={d.code}>
                {t(d.name)}
              </Tag>
            )
          }
        </div>
        <div className={classes.expeditionContainer}>
          <p>{t('Unidade')} {suggestedPosition}</p>

          {/* ONLY Student */}
          <DownloadButton expeditionId={Number(contentId)} context='INSIDE' dataTestid='student_menu_content_download' />
        </div>
        <div className={classes.titleContainer}>
          {title}
        </div>
      </div>
    </Grid >
  )
}

export const CoverSkeleton: React.FC = () => {
  const props = { title: null, disciplines: null, suggestedPosition: null, imageUrl: null } as unknown as ICoverProps
  const classes = useStyles(props)
  const theme = useTheme()

  return (
    <Grid item xs={12} className={classes.backgroundImage}>
      <div className={classes.boxCover} style={{ backgroundColor: theme.colors.neutral.light20 }}>
        <div className={classes.disciplineContainer}>
          <Skeleton variant='text' width={60} height={50} animation='wave' />
        </div>
        <div className={classes.expeditionContainer}>
          <Skeleton variant='text' width={100} height={40} animation='wave' />
        </div>
        <div className={classes.titleContainer}>
          <Skeleton variant='text' width={300} height={60} animation='wave' />
        </div>
      </div>
    </Grid>
  )
}

export default Cover
