import create, { GetState, SetState } from 'zustand'
import { devtools, persist, StoreApiWithPersist } from 'zustand/middleware'
import {
  IUserSessionResponse,
  ISubscriptionsSessionResponse,
  IProfilesSessionResponse,
  ISchoolPeriodResponse
} from 'services/types'
import Analytics from 'utils/analytics'

interface ILocalStoreProps {
  session: IUserSessionResponse | null
  profile: IProfilesSessionResponse | null
  subscription: ISubscriptionsSessionResponse | null
  schoolPeriod: ISchoolPeriodResponse | null
  showCloePro: boolean | null
  setUserSession: (user: IUserSessionResponse | null) => void
  setSubscription: (subscription: ISubscriptionsSessionResponse | null) => void
  setProfile: (profile: IProfilesSessionResponse | null) => void
  setSchoolPeriod: (schoolPeriod: ISchoolPeriodResponse | null) => void
  setShowCloePro: (showCloePro: boolean) => void
}

export const useLocalStore = create<
ILocalStoreProps,
SetState<ILocalStoreProps>,
GetState<ILocalStoreProps>,
StoreApiWithPersist<ILocalStoreProps>
>(
  persist(
    devtools((set, get) => ({
      session: null,
      subscription: null,
      profile: null,
      schoolPeriod: null,
      showCloePro: false,
      setUserSession: (session: IUserSessionResponse | null) => set({ session }),
      setSubscription: async (subscription: ISubscriptionsSessionResponse | null) => {
        const profile = get()?.profile
        // so funciona para estudande pq professor não tem contexto com subscription
        Analytics.recordEventClick({
          name: 'set_session',
          attributes: {
            profile_type: profile?.type,
            school_id: profile?.school?.id,
            user_id: profile?.user,
            ...subscription?.analytics
          }
        })
        set({ subscription })
      },
      setProfile: (profile: IProfilesSessionResponse | null) => set({ profile }),
      setSchoolPeriod: (schoolPeriod: ISchoolPeriodResponse | null) => set({ schoolPeriod }),
      setShowCloePro: (showCloePro: boolean | null) => set({ showCloePro })
    })),
    { name: 'cloe::web::props', version: 1, getStorage: () => localStorage }
  )
)
