import { Loading } from 'components/design-system'
import { LOGIN, ROOT } from 'navigation/CONSTANTS'
import { useAuth } from 'navigation/ProvideAuth'
import { useEffect } from 'react'
import Storage from 'utils/storage'
import { useUpdateAtom } from 'jotai/utils'
import { SSOErrorsAtom } from 'pages/atomStore'

export const AuthSSO_V2 = () => {
  const auth = useAuth()
  const setSSOErrors = useUpdateAtom(SSOErrorsAtom)

  const token = Storage.token.get()

  const signIn = async () => {
    try {
      if (!token) {
        throw new Error('No token found')
      }

      const response = await auth?.registerSignin(token)
      if (!response?.success) {
        throw new Error('Invalid Token')
      }

      window.location.href = ROOT
    } catch (e: any) {
      window.location.href = LOGIN

      setSSOErrors([e.message])
    }
  }

  useEffect(() => {
    signIn()
  }, [token])

  return <Loading />
}

export default AuthSSO_V2
