import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  infoText: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium
  },
  dateLabel: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colorBrand.medium
  },
  inputDate: {
    '& .MuiOutlinedInput-input': {
      fontWeight: `${theme.font.fontWeight.regular} !important`
    }
  },
  nowButton: {
    position: 'absolute',
    bottom: '5px',
    right: '16px',
    // width: '30px',
    color: theme.colorBrand.darkest,
    fontWeight: theme.font.fontWeight.medium,
    fontSie: theme.font.fontSize.xs,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))
