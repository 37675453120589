import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const styles = (props: { studentsLength: number }) => makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'relative',
    padding: theme.spacingInset.sm,
    paddingBottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInset.xs
    }
  },
  searchInput: {
    width: '100%',
    '& label.Mui-focused': {
      color: theme.colorBrand.medium
    },
    '& .MuiOutlinedInput-root': {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colorBrand.medium} !important`
      },
      '& input.MuiOutlinedInput-input:focus + .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colorBrand.medium} !important`
      },
      '&.Mui-focused fieldset': {
        borderColor: `${theme.colorBrand.medium} !important`
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 2,
      borderColor: theme.colors.neutral.light10,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: props.studentsLength > 0 ? 0 : 10,
      borderBottomLeftRadius: props.studentsLength > 0 ? 0 : 10,
      transition: 'all 0.2s'
    }
  },
  inputLabelNoShrink: {
    color: theme.colors.neutral.darkBase,
    marginLeft: '3.5%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '8.5%'
    }
  },
  icon: {
    color: theme.colorBrand.medium
  },
  studentsLisContainer: {
    top: 0,
    position: 'absolute',
    transform: 'translateY(80px)',
    zIndex: 1000,
    maxWidth: '94.6%',
    border: `2px solid ${theme.colorBrand.medium}`,
    borderTop: 0,
    padding: theme.spacingInline.xxxs,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.colors.neutral.lightBase,

    [theme.breakpoints.down('md')]: {
      maxWidth: '91%',
      transform: 'translateY(72px)'
    }
  },
  tagDefault: {
    cursor: 'pointer',
    width: 'fit-content',
    height: 'max-content',
    border: `1px solid ${theme.colors.neutral.light10} !important`
  },
  tagSelected: {
    cursor: 'pointer'
  },
  txtClear: {
    width: '20%',
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xxs,
    color: theme.colorBrand.light,
    textAlign: 'end',
    cursor: 'pointer'
  }
}))()

export default styles
