import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    boxModal: {
      position: 'initial',
      textAlign: 'center',
      marginTop: '7%',
      marginInline: 'auto',
      width: '40%',
      backgroundColor: theme.colors.neutral.lightBase,
      borderRadius: theme.borderRadius.sm,
      [theme.breakpoints.down('md')]: {
        position: 'initial',
        marginInline: 'auto',
        marginTop: theme.spacingStack.lg,
        width: 'calc(100% - 40px)'
      }
    },
    modalTitle: {
      color: theme.colors.support.colorSupport01,
      fontSize: theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.semibold,
      lineHeight: '32px',
      letterSpacing: '0.24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.md
      }
    },
    modalDescription: {
      color: theme.colors.neutral.dark10,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: '24px',
      letterSpacing: '0.16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.xxs
      }
    },
    classesItensText: {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.regular,
      color: theme.colors.neutral.dark30,
      [theme.breakpoints.up('sm')]: {
        fontWeight: theme.font.fontWeight.semibold,
        fontSize: theme.font.fontSize.md,
        lineHeight: theme.font.lineHeight.xs
      },
      '&.selected': {
        color: theme.colorBrand.medium
      }
    },
    classButton: {
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      padding: theme.spacingStack.xxxs,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& > button:nth-of-type(1)': {
        marginRight: theme.spacingInset.xs
      },
      '& button': {
        fontSize: theme.font.fontSize.sm,
        minWidth: '0 !important',
        width: 'auto !important'
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column !important',
        alignItems: 'normal',
        '& button': {
          '&.outlined': {
            marginRight: '0',
            marginBottom: theme.spacingStack.xxxs
          }
        }
      }
    },
    paperItem: {
      padding: theme.spacingInset.xs,
      border: `2px solid ${theme.colors.neutral.light10}`,
      borderRadius: theme.borderRadius.sm,
      '& > span': {
        color: theme.colorBrand.medium,
        fontSize: theme.font.fontSize.sm,
        lineHeight: theme.font.lineHeight.xs,
        fontWeight: theme.font.fontWeight.bold
      }
    }
  })
)
