import React, { useEffect } from 'react'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { Grid, useTheme } from '@mui/material'
import { studentLevelsAtom } from '../atomStore'
import { updateScheduleTimeAtom } from 'pages/ContentUnit/atomStore'
import { MY_CLOE_CONTEXT, myCloeContextAtom } from 'navigation/components/MyCloe/atomStore'
import { CurrentClass, Loading, StatusClassHandle } from 'components/common'
import useMyCloeSchedule from 'navigation/hooks/useMyCloeSchedule'
import {
  IActivityResponse,
  IContentUnitResponse,
  ActivityControlStatusEnum,
  IUserSchoolProfileTypeEnum,
  DisciplineCodeEnum
} from 'services/types'
import { ActivityTitle, BackToAll } from '../common'
import useStyles from './style'
import ActivityRelease from './components/ActivityRelease'
// import ActivityStatusMessage from './components/ActivityStatusMessage'
import TeacherActivityQuestionsView from './components/QuestionsView'
import TeacherActivityAnswersView from './components/AnswersView'
import TeacherActivityResultsView from './components/ResultsView'
import { reloadingActivityAtom } from './atomStoreOlder'
import PrintButton from '../../PrintButton'
import { currentSubMenuTabAtom, subMenuTabsAtom } from '../../SideMenu/components'
import { GenerateIcon } from 'utils/icon'
import { RESET, useResetAtom } from 'jotai/utils'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'

interface ITeacherActivityProps {
  content: IContentUnitResponse
  activity: IActivityResponse
}

export enum SubTabsMenusActivitiy {
  QUESTIONS = 0,
  ANSWERS = 1,
  RESULTS = 2
}

export const TeacherActivityNew: React.FC<ITeacherActivityProps> = ({ content, activity }) => {
  const isCloeExpand = content?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI) ?? false
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const isTemplate = activity?.custom_type !== 'clone'

  const classes = useStyles({ isTemplate })
  const theme = useTheme()

  const isActivitySuggestedType = activity?.suggested_application_type === 'activity'
  const isActivityCorrection = [
    ActivityControlStatusEnum.CLOSED,
    ActivityControlStatusEnum.PENDING,
    ActivityControlStatusEnum.DONE,
    ActivityControlStatusEnum.CORRECTED
  ].includes(Number(activity?.activity_status?.status))

  const { handleCreateOrEditActivitySchedule, isMyCloeOpen } = useMyCloeSchedule()

  // params
  const { contentId, activityId, evaluationId } = useParams<{ classId: string, contentId: string, activityId: string, evaluationId: string }>()

  // atom
  const [, setStudentLevels] = useAtom(studentLevelsAtom)
  const [, setMyCloeContext] = useAtom(myCloeContextAtom)
  const [updateScheduleTime] = useAtom(updateScheduleTimeAtom)
  const [reloadingActivity] = useAtom(reloadingActivityAtom)
  const [currentSubMenuTab, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [, setSubMenuTabs] = useAtom(subMenuTabsAtom)

  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)

  const subMenuTabsDefault = [
    {
      title: t('Questões'),
      iconComponent: GenerateIcon('ListAlt'),
      dataTestid: isActivitySuggestedType ? 'correction_activity_menu_questions' : 'correction_assessment_menu_questions',
      disabled: false
    }
  ]

  useEffect(() => {
    if (!reloadingActivity) return

    const newSubMenuTabs = []
    if (activity?.schedule) {
      newSubMenuTabs.push({
        title: t('Respostas'),
        iconComponent: GenerateIcon('AssignmentTurnedInOutlined'),
        dataTestId: isActivitySuggestedType ? 'correction_activity_menu_answers' : 'correction_assessment_menu_answers',
        disabled: false
      })

      if (activity?.suggested_application_type === 'evaluation') {
        newSubMenuTabs.push({
          title: t('Resultados'),
          iconComponent: GenerateIcon('StarBorderOutlined'),
          dataTestId: 'correction_assessment_menu_results',
          disabled: false
        })
      }
    }
    setSubMenuTabs([
      ...subMenuTabsDefault,
      ...newSubMenuTabs
    ])

    setStudentLevels([])
  }, [activity, reloadingActivity])

  useEffect(() => {
    // cleaning this atom
    setMyCloeContext(MY_CLOE_CONTEXT.ACTIVITY)
    setCurrentSubMenuTab(SubTabsMenusActivitiy.QUESTIONS)

    return () => {
      setMyCloeContext(MY_CLOE_CONTEXT.HOME)

      setCurrentSubMenuTab(RESET)
      resetSubMenuTabs()
    }
  }, [])

  if (reloadingActivity) {
    return <div style={{ width: '100%' }}><Loading type='linear' /></div>
  }

  return (
    <Grid container paddingY={2} className={classes.wrapper}>
      <Grid container item xs={12} lg={3} justifyContent={'space-between'}>
        <BackToAll
          activity={activity}
          contentId={contentId}
          isActivitySuggestedType={isActivitySuggestedType}
          dataTestid={isActivitySuggestedType
            ? 'correction_activity_menu_questions_back_to_all_activities'
            : 'correction_assessment_menu_assessments_back_to_all_activities'}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container m={0} marginTop={theme.spacingStack.xxs}>
          <Grid item xs={6}>
            <ActivityTitle
              title={activity.title || activity.activity_name}
              customType={activity.custom_type}
            />
          </Grid>
          <Grid item xs={6} display='flex' justifyContent='flex-end' gap={{ xs: '16px', md: '24px' }}>
            {
              currentSubMenuTab === SubTabsMenusActivitiy.QUESTIONS && (
                <PrintButton
                  id={isActivitySuggestedType ? 'print_expedition_tab_activities' : 'print_expedition_tab_assessments'}
                  dataTestId={isActivityCorrection
                    ? isActivitySuggestedType
                      ? 'correction_activity_exercise_print_activity'
                      : 'correction_assessment_print_assessment'
                    : ''}
                  content={contentId}
                  activity={isActivitySuggestedType ? activityId : evaluationId}
                  mode={IUserSchoolProfileTypeEnum.teacher}
                  lng={content.language}
                  printType='activity'
                />)
            }
            <StatusClassHandle>
              <CurrentClass>
                <ActivityRelease
                  startDate={activity.schedule?.startDate}
                  endDate={activity.schedule?.endDate}
                  active={isMyCloeOpen && !updateScheduleTime}
                  onClick={handleCreateOrEditActivitySchedule}
                  applicationType={activity.suggested_application_type}
                  isActivityCorrected={[ActivityControlStatusEnum.CORRECTED, ActivityControlStatusEnum.RELEASED].includes(parseInt(activity?.activity_status?.status as unknown as string))}
                />
              </CurrentClass>
            </StatusClassHandle>
          </Grid>
        </Grid>
      </Grid>
      {currentSubMenuTab === SubTabsMenusActivitiy.QUESTIONS && <TeacherActivityQuestionsView content={content} />}
      {currentSubMenuTab === SubTabsMenusActivitiy.ANSWERS && <TeacherActivityAnswersView />}
      {currentSubMenuTab === SubTabsMenusActivitiy.RESULTS && <TeacherActivityResultsView />}
    </Grid>
  )
}

export default TeacherActivityNew
