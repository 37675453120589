import { IApiResponse } from 'services/types'
import { IPrintResponse } from 'services/types/print'
import { request } from 'services/base'

export const printContent = async (content: string, section: string | null = null, mode: string | null = null, lng: string | null = null): Promise<IApiResponse<IPrintResponse>> => {
  let url: string = `${process.env.REACT_APP_PRINT_SERVICE as string}/v3/content-units/${content}?`
  if (section) url += `&section=${section}`
  if (mode) url += `&mode=${mode}`
  if (lng) url += `&lng=${lng}`
  return await request({ url })
}

export const printActivity = async (content: string, activity: string, mode: string | null = null, lng: string | null = null): Promise<IApiResponse<IPrintResponse>> => {
  let url: string = `${process.env.REACT_APP_PRINT_SERVICE as string}/v3/content-units/${content}/activities/${activity}?`
  if (mode) url += `&mode=${mode}`
  if (lng) url += `&lng=${lng}`
  return await request({ url })
}

export const printInstructions = async (content: string, mode: string | null = null, lng: string | null = null): Promise<IApiResponse<IPrintResponse>> => {
  let url: string = `${process.env.REACT_APP_PRINT_SERVICE as string}/v3/content-units/${content}/instructions?`
  if (mode) url += `&mode=${mode}`
  if (lng) url += `&lng=${lng}`
  return await request({ url })
}
