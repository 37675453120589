import { createRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import NaturalCompare from 'string-natural-compare'

import ErrorBoundary from 'components/common/ErrorBoundary'
import { LoadingWrapper } from 'components/common'
import ErrorWrapper from 'components/common/ErrorWrapper'

import { SubTabsMenusTeacher, contentUnitAtom } from '../../../atomStore'
// import Analytics from 'utils/analytics'

import { getActivitiesByContent } from 'services/activity'
import { ContentTypeEnum } from 'services/types/content'
import { DisciplineCodeEnum, IActivityResponse } from 'services/types'

import isProjectViewModeRule from 'rules/isProjectViewModeRule'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { ReactComponent as CloeCIcon } from 'assets/cloe-c-icon-teacher.svg'
import { toast } from 'components/design-system/Toast/manager'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import { Badge } from '@mui/material'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import { Correction, Finished, Liberated, Originals } from './components'
import { cloneObject } from 'utils/array'

import useStyle from './style'
import { subMenuTabsAtom, currentSubMenuTabAtom, previousSubMenuTabAtom } from '../../SideMenu/components/atomStore'
import { useResetAtom } from 'jotai/utils'
import { getActivitiesFilteredByStatusForTeacher } from 'rules/activitiesByStatus'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shoetest = require('shoetest')

interface IProcessedActivities {
  activitiesAvailable: IActivityResponse[]
  activitiesInProgress: IActivityResponse[]
  activitiesCorrection: IActivityResponse[]
  activitiesCompleted: IActivityResponse[]
}

const EMPTY_PROCESSED_ACTIVITIES = {
  activitiesAvailable: [],
  activitiesInProgress: [],
  activitiesCorrection: [],
  activitiesCompleted: []
}

export const TeacherActivities: React.FC = () => {
  const { gradeTypeCode, gradeCode, classId, contentId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string }>()

  const [contentUnit] = useAtom(contentUnitAtom)
  const [previousSubMenuTab] = useAtom(previousSubMenuTabAtom)
  const resetPreviousSubMenuTab = useResetAtom(previousSubMenuTabAtom)
  const [currentSubMenuTab, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [, setSubMenuTabs] = useAtom(subMenuTabsAtom)

  const isCloeExpand = contentUnit?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI) ?? false
  const isProjectViewMode = isProjectViewModeRule(contentUnit?.grade?.grade_type ?? 0, contentUnit?.disciplines?.map(d => d.code) ?? [])

  const [loading, setLoading] = useState<boolean>(true)
  const [activities, setActivities] = useState<IActivityResponse[]>()
  const [hasResponseError, setHasResponseError] = useState<boolean>(false)
  const [storedActivities, setStoredActivities] = useState<IProcessedActivities>(EMPTY_PROCESSED_ACTIVITIES)
  const [processedActivities, setProcessedActivities] = useState<IProcessedActivities>(EMPTY_PROCESSED_ACTIVITIES)
  const [searchPhrase, setSearchPhrase] = useState<string>('')

  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)

  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const searchRef = createRef<any>()

  const classes = useStyle({ currentSubMenuTab })

  const subMenuTabsDefault = [
    {
      title: t('Originais'),
      iconComponent: <CloeCIcon />,
      dataTestid: ''
    }
  ]

  useEffect(() => {
    clear()
  }, [currentSubMenuTab])

  useEffect(() => {
    handleTabsAppearance(activities)

    if (previousSubMenuTab) {
      setCurrentSubMenuTab(previousSubMenuTab)
      resetPreviousSubMenuTab()
    }

    return () => {
      resetSubMenuTabs()
    }
  }, [activities])

  const currentTabIndex = (currentSubMenuTab ?? 0) as unknown as SubTabsMenusTeacher

  const search = (pattern: string) => {
    const activitiesTab = {
      [SubTabsMenusTeacher.ORIGINALS]: processedActivities.activitiesAvailable,
      [SubTabsMenusTeacher.LIBERATED]: processedActivities.activitiesInProgress,
      [SubTabsMenusTeacher.CORRECTION]: processedActivities.activitiesCorrection,
      [SubTabsMenusTeacher.FINISHED]: processedActivities.activitiesCompleted
    }
    const activitiesTabKey = {
      [SubTabsMenusTeacher.ORIGINALS]: 'activitiesAvailable',
      [SubTabsMenusTeacher.LIBERATED]: 'activitiesInProgress',
      [SubTabsMenusTeacher.CORRECTION]: 'activitiesCorrection',
      [SubTabsMenusTeacher.FINISHED]: 'activitiesCompleted'
    }

    setSearchPhrase(pattern)

    const selectedActivitiesTab = activitiesTab[currentTabIndex]

    const searchedActivities = cloneObject(selectedActivitiesTab).filter(
      (cur: IActivityResponse) => {
        if (shoetest.test(pattern, cur.title)) return true

        return false
      }
    )

    setProcessedActivities(old => ({
      ...old,
      [activitiesTabKey[currentTabIndex]]: searchedActivities
    }))
  }

  const clear = () => {
    setProcessedActivities(storedActivities)
    setSearchPhrase('')
  }

  if (!contentId) {
    return (
      <>
        {t('Not Found', true)}
      </>
    )
  }

  const getData = async () => {
    setLoading(true)
    const response = await getActivitiesByContent(parseInt(classId), contentId, ContentTypeEnum.activity)
    if (response.success) {
      setActivities(response.data)
    } else {
      setHasResponseError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    void getData()
  }, [])

  const handleTabsAppearance = async (activities: IActivityResponse[] | undefined) => {
    try {
      const activitiesOrdered = activities?.map(cur => {
        // making the title just one to put in alphabetically order
        cur.title = cur.title ?? cur.activity_name
        cur.title = cur.title.trim()
        return cur
      }).sort((a, b) => NaturalCompare(a.title, b.title, { caseInsensitive: true }))

      const activitiesByStatus = getActivitiesFilteredByStatusForTeacher(activitiesOrdered ?? [])

      setProcessedActivities({
        activitiesAvailable: activitiesByStatus.available,
        activitiesInProgress: activitiesByStatus.inProgress,
        activitiesCorrection: activitiesByStatus.correction,
        activitiesCompleted: activitiesByStatus.completed
      })
      setStoredActivities({
        activitiesAvailable: activitiesByStatus.available,
        activitiesInProgress: activitiesByStatus.inProgress,
        activitiesCorrection: activitiesByStatus.correction,
        activitiesCompleted: activitiesByStatus.completed
      })

      setSubMenuTabs([
        ...subMenuTabsDefault,
        {
          title: t('Liberadas'),
          iconComponent: <ShareOutlinedIcon />,
          dataTestid: '',
          disabled: !activitiesByStatus.inProgress.length
        },
        {
          title: t('Para correção'),
          iconComponent:
            <Badge
              badgeContent={activitiesByStatus.correction.length}
              max={99}
              className={classes.badge}
            >
              <AssignmentTurnedInOutlinedIcon />
            </Badge>,
          dataTestid: '',
          disabled: !activitiesByStatus.correction.length
        },
        {
          title: t('Finalizadas'),
          iconComponent: <VerifiedOutlinedIcon />,
          dataTestid: '',
          disabled: !activitiesByStatus.completed.length
        }
      ])
    } catch (err) {
      toast.handler({
        content: 'Ocorreu um erro ao buscar por atividades',
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const defaultProps = {
    isProjectViewMode,
    isCloeExpand,
    handleSearch: search,
    handleClear: clear,
    searchRef,
    searchPhrase,
    contentId,
    gradeCode,
    gradeTypeCode,
    classId
  }

  const tabs = {
    [SubTabsMenusTeacher.ORIGINALS]: <Originals activitiesAvailable={processedActivities.activitiesAvailable} {...defaultProps} />,
    [SubTabsMenusTeacher.LIBERATED]: <Liberated activitiesInProgress={processedActivities.activitiesInProgress} {...defaultProps} />,
    [SubTabsMenusTeacher.CORRECTION]: <Correction activitiesCorrection={processedActivities.activitiesCorrection} {...defaultProps} />,
    [SubTabsMenusTeacher.FINISHED]: <Finished activitiesCompleted={processedActivities.activitiesCompleted} {...defaultProps} />
  }

  return (
    <div className={classes.activitiesContainer}>
      <ErrorBoundary>
        <LoadingWrapper type={'linear'} loading={loading}>
          <ErrorWrapper hasError={hasResponseError} errorMessage={t('Não foi possível encontrar as atividades', true)?.toString()}>
            {tabs[currentTabIndex]}
          </ErrorWrapper>
        </LoadingWrapper>
      </ErrorBoundary>
    </div>
  )
}

export default TeacherActivities
