/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { Box, Grid, Modal, Fade, Backdrop, useTheme, useMediaQuery } from '@mui/material'
import { Close, KeyboardArrowLeft } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import BackToTop from 'components/common/BackToTop'
import { Tag, Button, CheckBox } from 'components/design-system'
import SearchAndSelect from '../SearchAndSelect'

import { IRecipientsModalProps } from './types'

import useStyles from './style'
import { truncateExtension } from 'utils/string'

export const RecipientsModal = ({
  recipientsModal, classSelected, selectedStudents, groupStudentsByFirstLetter,
  isStudentSelected, setRecipientsModalSelector, setSelectedStudents, removeStudent, checkAllSelected,
  onSelect, onChangeSelectAll
}: IRecipientsModalProps) => {
  const inputRef = React.createRef<HTMLDivElement>()
  // const
  const modalId = 'modalId'
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const closeModal = () => {
    setRecipientsModalSelector(false)
    inputRef?.current?.click()
  }

  return (
    <div>
      <div ref={inputRef} data-testid='recipients_close'></div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={recipientsModal}
        onClose={() => setRecipientsModalSelector(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={recipientsModal}>
          <Box className={classes.modalWrapper}>
            {isMobile && (
              <Grid container gap={1} display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'
                onClick={closeModal}
                className={classes.padding}
                sx={{
                  paddingX: 0,
                  cursor: 'pointer',
                  '& svg': {
                    fill: theme.colorBrand.medium,
                    fontSize: theme.font.fontSize.xl
                  }
                }}
              >
                <KeyboardArrowLeft />
                <span className={classes.txtToBack}>Voltar</span>
              </Grid>
            )}
            <Grid container gap={1} display='flex' flexDirection='row' justifyContent='space-between' className={classes.padding} style={{ paddingBottom: 0 }}>
              <span className={classes.txtTitle}>
                Destinatários
              </span>
              {!isMobile && (
                <Grid item onClick={closeModal} sx={{
                  cursor: 'pointer',
                  '& svg': {
                    fill: theme.colors.neutral.light40,
                    fontSize: theme.font.fontSize.xl
                  }
                }}>
                  <Close />
                </Grid>
              )}
            </Grid>
            <SearchAndSelect />
            <Grid container
              gap={1} display='flex'
              className={classes.padding}
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                flexDirection: 'row',
                justifyContent: 'space-between',

                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  paddingBottom: theme.spacingStack.xs
                }
              }}
            >
              <span className={classes.txtSubTitle}>
                Lista de estudantes da Turma {truncateExtension(classSelected?.name ?? '', 25)}
              </span>
              <Grid
                gap={1}
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems={isMobile ? 'flex-start' : 'flex-end'}
              >
                <CheckBox
                  label={t('Selecionar todos')}
                  size='small'
                  checked={checkAllSelected()}
                  dataTestid='recipients_select_all_students'
                  onClick={() => onChangeSelectAll()}
                />
                {(selectedStudents ?? []).length === 0 ? '' : <span className={classes.txtCountSelected}>{(selectedStudents ?? []).length} estudantes selecionados</span>}
              </Grid>
            </Grid>
            <Grid id={modalId} container className={classes.padding} style={{ paddingTop: 0 }}>
              <Grid item className={classes.content} display='flex' flexDirection='column'>
                {Object.keys(groupStudentsByFirstLetter).map((letter, index) => (
                  <React.Fragment key={index}>
                    <Grid item display='flex' flexDirection='row' alignItems='center' gap={2}>
                      <span className={classes.txtLetter}>{letter}</span>
                      <hr className={classes.divider} />
                    </Grid>
                    <Grid item display='flex' flexDirection='row' alignItems='center' gap={2} flexWrap='wrap'>
                      {groupStudentsByFirstLetter[letter].map((student, i) => (
                        <React.Fragment key={i}>
                          {isStudentSelected(student.id)
                            ? (
                              <Tag
                                color={theme.colors.neutral.lightBase}
                                background={theme.colorBrand.medium}
                                className={classes.tagSelected}
                                onClick={() => removeStudent(student.id)}
                                dataTestid='recipients_select_students'
                              >
                                {`${student.user_id?.name} ${student.user_id.surname}`}
                              </Tag>
                            )
                            : (
                              <Tag
                                background={theme.colors.neutral.lightBase}
                                className={classes.tagDefault}
                                dataTestid='recipients_select_students'
                                onClick={() => {
                                  setSelectedStudents([...(selectedStudents ?? []), { name: `${student.user_id?.name} ${student.user_id.surname}`, id: student.id }])
                                }}
                              >
                                {`${student.user_id?.name} ${student.user_id.surname}`}
                              </Tag>
                            )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </React.Fragment>
                ))}
                {Object.keys(groupStudentsByFirstLetter).length === 0 && t('Nenhum aluno encontrado para a turma')}
              </Grid>
              <Grid item className={classes.footer} gap={5}>
                <Button
                  variant='ghost'
                  sx={{
                    padding: '8px 12px !important',
                    gap: '8px',
                    fontWeight: `${theme.font.fontWeight.semibold} !important`
                  }}
                  data-testid='recipients_cancel_student_selection'
                  onClick={() => setRecipientsModalSelector(false)}
                >
                  {t('Cancelar')}
                </Button>

                <Button
                  disabled={selectedStudents.length === 0}
                  sx={{
                    padding: '8px 12px !important',
                    gap: '8px',
                    fontWeight: `${theme.font.fontWeight.semibold} !important`
                  }}
                  data-testid='recipients_confirm_student_selection'
                  onClick={() => onSelect()}
                >
                  {t('Confirmar')}
                </Button>
              </Grid>
            </Grid>
            <BackToTop e={modalId} staryHeight='-47%' left={100} />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
