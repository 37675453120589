import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  calendarMonth: {
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark20,
    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  calendarTodayButton: {
    margin: '0px 20px',
    padding: '8px 16px',
    fontSize: theme.font.fontSize.xxs
  },
  calendarWeekDays: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0px',
    overflowX: 'auto',
    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  calendarControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0px',
    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  calendarActions: {
    display: 'flex',
    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between'
    }
  },
  changeWeekDesktop: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacingInline.xxs,
    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  calendarControlsDescription: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colorBrand.medium,
    lineHeight: '24px',
    letterSpacing: '0.14px',
    margin: '0px 5px'
  },
  changeDayMobile: {
    display: 'none',
    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  calendarDayMobileDescription: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: theme.font.lineHeight.xxs,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  btnToday: {
    minWidth: '0 !important',
    padding: `${theme.spacingSquish.nano} !important`,
    fontSize: `${theme.font.fontSize.xxs} !important`
  }
}))
