import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Text, Button } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyle from './style'
import {
  useContentCached,
  ExpeditionRemoveData,
  getExpeditionCached,
  removeAllUrlsFromCache,
  deleteExpeditionCached,
  setExpeditionCachedData,
  OfflineTableExpeditionCachedStatus,
  setCurrentWorking,
  removeCurrentWorking
} from 'contentCacheManager'

export const RemoveExpeditionModal: React.FC<ExpeditionRemoveData> = ({ expeditionId, onRemove }) => {
  const modalIsOpen = !!expeditionId
  const { t } = useTranslation()
  const classes = useStyle()
  const { closeRemoveModal } = useContentCached()

  const handleOnRemove = async () => {
    setCurrentWorking('expeditionId', String(expeditionId))
    const expeditionInCache = await getExpeditionCached(expeditionId)
    if (expeditionInCache) {
      setExpeditionCachedData({
        status: OfflineTableExpeditionCachedStatus.REMOVING
      }, expeditionInCache)
      await removeAllUrlsFromCache(expeditionInCache.successUrls)
      await deleteExpeditionCached(expeditionId)
      removeCurrentWorking('expeditionId')
      onRemove?.()
      closeRemoveModal()
    }
  }

  return (
    <Modal open={modalIsOpen}>
      <Box className={classes.boxModal}>
        <Grid>
          <Text className={classes.modalTitle} type='body'>
            {t('Quer mesmo remover esse conteúdo baixado?')}
          </Text>

          <Text className={classes.modalInfo} type='body'>
            {t('Você não poderá acessar esse conteúdo quando estiver offline.')}
          </Text>
        </Grid>
        <Grid className={classes.classButton}>
          <Button
            sx={{ marginTop: '10px' }}
            variant='outlined'
            onClick={closeRemoveModal}
          >
            {t('Cancelar')}
          </Button>
          <Button
            sx={{ marginTop: '10px' }}
            variant='primary'
            onClick={handleOnRemove}
          >
            {t('Remover')}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
