import React, { useState, useRef } from 'react'
import { Icon, Tag } from 'components/design-system'
import ContentLink from 'components/common/ContentLink'
import { LoadingWrapper } from 'components/common'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import { NavLink, useParams } from 'react-router-dom'
import { DisciplineCodeEnum, DisciplineTypeEnum, IUserSchoolProfileTypeEnum } from 'services/types'
import {
  CONTENT_UNIT_STUDENT,
  CONTENT_UNIT_STUDENT_ACTIVITY,
  CONTENT_UNIT_STUDENT_CONTENT,
  CONTENT_UNIT_STUDENT_EVALUATION,
  CONTENT_UNIT_TEACHER,
  CONTENT_UNIT_TEACHER_ACTIVITY,
  CONTENT_UNIT_TEACHER_CONTENT,
  CONTENT_UNIT_TEACHER_EVALUATION
} from 'navigation/CONSTANTS'
import { useStore } from 'store'
import Analytics from 'utils/analytics'
import { ContentTypeEnum } from 'services/types/content'

import { useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { IScheduleResponse } from 'services/types/schedule'

import { SubscriptionInfo } from './components/SubscriptionInfo'
import { truncateExtension } from 'utils/string'
import { EditDeleteNavigationButton } from './components'

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

export interface IScheduleCardProps extends IScheduleResponse {
  coverUrl?: string
  profileType?: IUserSchoolProfileTypeEnum
  onDelete?: () => void
  onClick?: (id: number) => void
  dataTestid?: string
}

export const ScheduleCard: React.FC<IScheduleCardProps> = props => {
  dayjs.extend(customParseFormat)
  // props
  const {
    id,
    title,
    content,
    type,
    discipline,
    end_date: endDate,
    content_unit: contentUnit,
    class: theClass,
    activity,
    didactic_section: didacticSection,
    has_updated: hasUpdated,
    onClick,
    subscriptions,
    dataTestid
  } = props

  const classId = theClass.id ?? ''

  const { gradeTypeCode, gradeCode } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string }>()

  // state
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // store
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type
  const styleProps = { ...props, profileType }
  const classes = useStyles(styleProps)
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const disciplines = contentUnit?.disciplines ?? [discipline]

  const isOriginalContentView = props.type === 'original_content'

  const tagginRef = useRef<HTMLDivElement | null>(null)

  const handleClick = () => {
    Analytics.recordEventClick({
      name: 'schedule_select_item'
    })
    onClick?.(id)

    tagginRef?.current?.click()
  }

  const getContentLink = () => {
    const _classId = classId?.toString()
    const contentUnitId = contentUnit?.id?.toString() ?? ''

    if (profileType === IUserSchoolProfileTypeEnum.student) {
      if (activity) {
        if (activity.suggested_application_type === 'activity') {
          return CONTENT_UNIT_STUDENT_ACTIVITY(contentUnitId, activity.id?.toString())
        } else {
          return CONTENT_UNIT_STUDENT_EVALUATION(contentUnitId, activity.id?.toString())
        }
      } else if (didacticSection) {
        return CONTENT_UNIT_STUDENT_CONTENT(contentUnitId, didacticSection.id?.toString())
      } else {
        return CONTENT_UNIT_STUDENT(contentUnitId)
      }
    } else if (profileType === IUserSchoolProfileTypeEnum.teacher) {
      if (activity) {
        if (activity.suggested_application_type === 'activity') {
          return CONTENT_UNIT_TEACHER_ACTIVITY(gradeTypeCode, gradeCode, _classId, contentUnitId, activity.id?.toString())
        } else {
          return CONTENT_UNIT_TEACHER_EVALUATION(gradeTypeCode, gradeCode, _classId, contentUnitId, activity.id?.toString())
        }
      } else if (didacticSection) {
        return CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, _classId, contentUnitId, didacticSection.id?.toString())
      } else {
        return CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, _classId, contentUnitId)
      }
    }
    return ''
  }

  const OriginalContentView = () => (
    <>
      <SubscriptionInfo class={theClass} subscriptions={subscriptions} />

      <div className={classes.expeditionContainer}>
        <div className={classes.titleContainer}>
          {contentUnit?.title ?? title}
        </div>
        <div className={classes.hasUpdate}>
          {props?.has_updated && t('Editado')}
        </div>
      </div>
      {
        !contentUnit && content &&
        <div className={classes.textContainer}>
          {content}
        </div>
      }
      {
        contentUnit && title &&
        <div className={classes.textIconContainer}>
          <Icon size='medium' iconColor={theme.colors.neutral.dark20}>
            <DescriptionOutlinedIcon />
          </Icon>
          <div className={classes.textContainer}>
            {title}
          </div>
        </div>
      }
      {
        contentUnit && (
          <Tag color={theme.colors.neutral.darkBase} background={theme.colors.neutral.light10} className={classes.tag}>
            {t('Material do professor')}
          </Tag>
        )
      }
      {
        props?.files?.length
          ? <Tag color={theme.colors.neutral.darkBase} background={theme.colors.neutral.light10} className={classes.tag}>
            {props?.files?.length} {t(`Anexo${props?.files?.length > 1 ? 's' : ''}`)}
          </Tag>
          : ''
      }
      {/* {
        props?.has_updated
          ? <Tag color={theme.colorBrand.light} className={classes.tagIsEdited} background={theme.colors.neutral.lightBase}>
            {t('Editado')}
          </Tag>
          : ''
      } */}
    </>
  )

  const ExistingContentView = () => (
    <>

      <div className={classes.expeditionContainer}>
        <div className={classes.titleContainer}>
          {contentUnit?.title ?? title}
        </div>
      </div>
      {
        props?.files?.length
          ? <Tag color={theme.colors.neutral.darkBase} background={theme.colors.neutral.light10} className={classes.tag}>
            {props?.files?.length} {t(`Anexo${props?.files?.length > 1 ? 's' : ''}`)}
          </Tag>
          : ''
      }
      {
        activity &&
        <div className={classes.contentWrapper}>
          <ContentLink
            id={contentUnit?.id?.toString() ?? ''}
            contentId={contentUnit?.id?.toString() ?? ''}
            activityId={activity.id?.toString()}
            gradeCode={gradeCode}
            gradeTypeCode={gradeTypeCode}
            classId={classId?.toString()}
            type={activity.suggested_application_type === 'activity' ? ContentTypeEnum.activity : ContentTypeEnum.evaluation}
            title={activity.title ?? activity.activity_name ?? ''}
            showTypeTag
            isLink={false}
            isEdited={!!hasUpdated}
            suggestedApplicationType={activity.suggested_application_type}
          />
        </div>
      }
      {
        didacticSection && parseInt(didacticSection?.id?.toString() ?? '') > 0 &&
        <div className={classes.contentWrapper}>
          <ContentLink
            id={contentUnit?.id?.toString() ?? ''}
            contentId={contentUnit?.id?.toString() ?? ''}
            sectionId={didacticSection.id?.toString()}
            gradeCode={gradeCode}
            gradeTypeCode={gradeTypeCode}
            classId={classId?.toString()}
            type={ContentTypeEnum.reading}
            title={didacticSection.title ?? ''}
            showTypeTag
            isLink={false}
            isEdited={!!hasUpdated}
          />
        </div>
      }
    </>
  )

  return (
    <NavLink
      to={type === 'existing_content' ? getContentLink() : '#'}
      onClick={handleClick}
      style={{
        textDecoration: 'none',
        width: isMobile ? '100%' : '303px'
      }}
    >
      <Grid item xs={12} className={classes.container} ref={(el) => { tagginRef.current = el }} data-testid={dataTestid ?? 'schedule_select_event'}>
        <LoadingWrapper loading={isLoading}>
          <div className={classes.boxCover}>
            <div className={classes.itineraryContainer}>
              {discipline?.type === DisciplineTypeEnum.ITINERARY && (
                <Tag variant={DisciplineCodeEnum.IT_CUF} className={classes.tag}>
                  {truncateExtension(t(discipline?.name), 30)}
                </Tag>
              )}
            </div>
            <div className={classes.disciplineContainer}>
              {
                disciplines?.length
                  ? disciplines.map(d =>
                    <Tag key={d.id} variant={d.code as DisciplineCodeEnum} className={classes.tag}>
                      {t(d.name)}
                    </Tag>)
                  : t('Erro ao listar disciplinas')
              }
              {
                endDate &&
                <Tag className={`${classes.dataTag} ${classes.tag}`} >
                  {dayjs(endDate).format('DD/MM HH:mm')}
                </Tag>
              }
            </div>
            <div className={classes.boxFade} />
            <EditDeleteNavigationButton
              setIsLoading={setIsLoading}
              scheduleProps={props}
            />
          </div>
          <Grid container gap='8px' padding='16px' position='relative' height='208px'>
            {
              isOriginalContentView
                ? <OriginalContentView />
                : <ExistingContentView />
            }
          </Grid>
        </LoadingWrapper>
      </Grid>
    </NavLink>
  )
}

export default ScheduleCard
