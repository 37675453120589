/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { format, isAfter, parseISO } from 'date-fns'
import { useAtom } from 'jotai'
import { StarBorder } from '@mui/icons-material'

import useStyles from './style'
import { Avatar, Tag } from 'components/design-system'
import { IStudentsAnswerByActivityResponse, IAnswerResponse } from 'contentCacheManager'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

interface ICardStudentAnswerProps {
  studentAnswer?: IStudentsAnswerByActivityResponse
}

export const CardStudentAnswer: React.FC<ICardStudentAnswerProps> = ({ studentAnswer }) => {
  const classes = useStyles()
  const theme = useTheme()

  // atom
  const [activity] = useAtom(activityCurrentAtom)

  const isClosedSubmissions = activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate))
  const isAllAnswered = (studentAnswer?.questions ?? []).every(question => question?.answer)
  const isAllCorrect = (studentAnswer?.questions ?? []).every(question => question?.answer?.level)

  const calcutateStudentLevel = () => {
    if (!isAllAnswered) return 0
    if (isAllAnswered && !isAllCorrect) return '-'

    let level = 0
    for (const question of (studentAnswer?.questions ?? [])) {
      // @ts-expect-error
      level += question?.answer?.level?.value ?? 0
    }

    const result = (level / (studentAnswer?.questions ?? []).length / 10).toFixed(1)
    return `${result ?? 0}`.replace('.', ',')
  }

  const validateShowActiveAverageInfo = () => {
    if (isAllAnswered && !isAllCorrect) return false
    return true
  }

  const validateShowLevelForQuestion = (questionAnswer?: IAnswerResponse) => {
    if (isClosedSubmissions && !questionAnswer) return 0
    return '-'
  }

  return (
    <Grid container lg={5.9} xs={12} display='flex' flexDirection='column' justifyContent='space-between' className={classes.cardStudentAnswerContainer}>
      <Grid item display='flex' flexDirection='row' justifyContent='space-between' className={classes.cardStudentAnswerContentInfo}>
        <Grid item display='flex' gap={1}>
          <Avatar userName={`${studentAnswer?.user_name} ${studentAnswer?.user_surname || ''}`} sx={{ width: 24, height: 24 }} />
          <Grid item display='flex' flexDirection='column'>
            <span className={classes.cardStudentAnswerTextName}>{`${studentAnswer?.user_name} ${studentAnswer?.user_surname || ''}`}</span>
            <span className={classes.cardStudentAnswerTextDate}>{studentAnswer?.questions?.[0]?.answer?.created_at ? `Resposta enviada em ${format(parseISO(studentAnswer?.questions?.[0]?.answer?.created_at), 'dd/MM/yyyy')}` : 'Resposta não enviada'}</span>
          </Grid>
        </Grid>
        <Tag background={validateShowActiveAverageInfo() ? theme.colors.information.feedbackLight : theme.colors.neutral.light10 } className={classes.cardStudentAnswerContentTag}>
          <StarBorder style={{ color: validateShowActiveAverageInfo() ? theme.colors.information.feedbackDark : theme.colors.neutral.dark40 }} />
          <span style={{ color: validateShowActiveAverageInfo() ? theme.colors.information.feedbackDark : theme.colors.neutral.dark40 }}>Nota: </span><span style={{ fontWeight: theme.font.fontWeight.semibold }}>{calcutateStudentLevel()}</span>
        </Tag>
      </Grid>
      <Grid container item spacing={1} display='flex' flexDirection='row' justifyContent='flex-start' className={classes.cardStudentAnswerContentLevel}>
        {studentAnswer?.questions?.map((question, index) => (
          <Grid item xs={4} xl={3} gap='4px' display='flex' alignItems='center'>
            <span className={classes.cardStudentAnswerContentLevelValue}>{index + 1}</span>
            <StarBorder />
            <span className={classes.cardStudentAnswerTxtLevelValue}>
              {
                // @ts-expect-error
                question?.answer?.level ? `${(question.answer?.level?.value ?? 0) / 10}`.replace('.', ',') : validateShowLevelForQuestion(question.answer)
              }
            </span>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default CardStudentAnswer
