import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import baseScreenBackgroundImage from 'assets/base-screen-background.svg'

// interface IBaseScreen {
//   openMyCloe: boolean
// }

export default makeStyles((theme: Theme) => createStyles({
  baseScreenContainer: {
    position: 'relative',
    zIndex: 1,
    height: 'auto',
    minHeight: '100%',
    backgroundImage: `url(${baseScreenBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',

    // mobile
    [theme.breakpoints.down('sm')]: {
      overflow: 'unset',
      height: 'auto',
      background: theme.colors.neutral.lightBase,
      width: '100vw'
    }
  },
  baseScreenWrapper: {
    transition: 'all ease-in-out 0.2s',
    // width: ({ openMyCloe }: IBaseScreen) => openMyCloe ? 'calc(100% - 360px)' : 'calc(100% - 40px)',
    paddingLeft: 70,
    margin: '0 8px',
    height: 'auto',
    minHeight: 'calc(100% - 87px)',
    marginTop: 10,
    zIndex: -1,

    // tablet
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    },

    // mobile
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      margin: 0,
      padding: 0
    }
  },
  baseScreenContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.lightBase,
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,
    boxShadow: theme.shadowLevels.level5,
    marginTop: theme.spacingStack.xxxs,
    position: 'relative',

    // tablet
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      overflow: 'hidden'
    },

    // mobile
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacingStack.xxxs
    }
  },
  '@keyframes slideAnimationBarInfo': {
    from: {
      transform: 'translateY(-15%)',
      opacity: 0
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1
    }
  },
  barInfoPreviousSchoolContainer: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    backgroundColor: theme.colors.contentType.default,
    padding: theme.spacingInline.xxxs,
    height: 'fit-content',
    color: theme.colors.neutral.lightBase,
    animation: '$slideAnimationBarInfo 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transition: 'all 0.3s ease'
  },
  barInfoPreviousSchoolContent: {
    fontSize: theme.font.fontSize.sm
  },
  barInfoPreviousSchoolButton: {
    borderRadius: '20px',
    padding: theme.spacingInline.nano,
    border: `2px solid ${theme.colors.neutral.lightBase}`,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    marginTop: `${theme.spacingInline.nano} !important`,
    cursor: 'pointer'
  },
  barInfoPreviousSchoolSwitchButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacingInline.xxxs,
    cursor: 'pointer'
  }
}))
