import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  parentalMessageBox: {
    display: 'flex',
    border: `${theme.borderThickness.hairline} solid ${theme.colors.neutral.light20}`,
    borderRadius: theme.borderRadius.md,
    padding: theme.spacingInset.sm,
    gap: theme.spacingStack.xxxs,
    width: '100%'
  },
  studentTextName: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    textAlign: 'left',
    WebkitLineClamp: 2,
    wordBreak: 'break-word'
  },
  avatarContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiAvatar-root': {
      height: '45px',
      width: '45px'
    }
  },
  customAvatar: {
    marginRight: theme.spacingInline.nano
  },
  studentHeaderInfo: {
    justifyContent: 'center'
  },
  studentName: {
    textAlign: 'left'

  },
  messageInnerBox: {
    backgroundColor: 'rgba(231, 210, 230, 0.16)',
    color: theme.colorBrand.darkest,
    padding: theme.spacingInset.xxs,
    whiteSpace: 'pre-wrap',
    fontWeight: theme.font.fontWeight.medium,
    borderRadius: theme.borderRadius.sm,
    width: '100%'
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  hideButton: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.colorBrand.medium
  }
}))
