import { Grid, Skeleton, Theme, useMediaQuery, useTheme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backgroundImage: {
      border: `1px solid ${theme.colors.neutral.light10}`,
      width: '320px',
      minHeight: '418px',
      position: 'relative',
      height: 'fit-content',
      borderRadius: theme.borderRadius.sm,
      display: 'inline-block',
      overflow: 'hidden',

      margin: `${theme.spacingInline.nano} 0px`,

      [theme.breakpoints.up('sm')]: {
        marginInline: theme.spacingInline.nano,
        margin: theme.spacingInline.nano
      },

      // mobile
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.borderRadius.sm,
        width: '100%'
      }
    },
    expeditionContainer: {
      width: 'auto',
      height: '118px',
      display: 'flex',
      'flex-direction': 'column !important',
      margin: theme.spacingStack.xxxs,
      color: theme.colors.neutral.dark20,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.semibold,

      // mobile
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.sm
      }
    }
  })
)

export const Loading: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid className={classes.backgroundImage}>
      <Skeleton animation='wave' variant='rectangular' height={300} />
      <Grid container className={classes.expeditionContainer}>
        <Skeleton animation='wave' variant='rectangular' width={150} height={25} />
        <Skeleton style={{ marginTop: '10px' }} animation='wave' variant='rectangular' width={220} height={20} />

        {isMobile && <Skeleton style={{ marginTop: '15px', borderRadius: '15px' }} animation='wave' variant='rectangular' width={290} height={40} />}
      </Grid>
    </Grid>
  )
}

export default Loading
