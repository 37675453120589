import useStyles from './style'
import axios from 'axios'
import { useState, useEffect } from 'react'
import InnerHTML from 'dangerously-set-html-content'

const convertInfogram = async (input: string) => {
  try {
    const embed = await axios.get(`https://infogram.com/oembed?url=${input}`)
    return embed.data
  } catch (e) {
    console.log(e)
  }
}

interface VideoPlayerProps {
  url: string
}

export const Infogram: React.FC<VideoPlayerProps> = ({ url }) => {
  const [data, dataSet] = useState<any>(null)
  const classes = useStyles()

  useEffect(() => {
    async function getInfogram() {
      try {
        const response = await convertInfogram(url)
        dataSet(response)
      } catch (e) {
        console.log(e)
      }
    }

    void getInfogram()
  }, [])

  return <>
    {
      data
        ? <div className={classes.infogramContainer}>
          <InnerHTML html={data.html ?? ''} />
        </div>
        : ''
    }
  </>
}
