import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IModalClassesDisciplinesStyleProps {
  loading: boolean
}

export default makeStyles((theme: Theme) =>
  createStyles({
    boxModalContainer: {
      padding: `${theme.spacingInset.sm} ${theme.spacingInset.xs} 0px`,
      height: ({ loading }: IModalClassesDisciplinesStyleProps) => loading ? '262px' : 'auto',
      display: ({ loading }: IModalClassesDisciplinesStyleProps) => loading ? 'flex' : 'initial'
    },
    itemContainer: {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      ...theme.scroll,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        height: '100%',
        paddingInline: '5px',
        flexWrap: 'wrap',
        paddingBottom: '90px'
      }
    }
  }))
