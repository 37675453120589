import { CONTENT_UNIT_STUDENT_ACTIVITY, CONTENT_UNIT_STUDENT_EVALUATION, CONTENT_UNIT_TEACHER_ACTIVITY, CONTENT_UNIT_TEACHER_EVALUATION } from 'navigation/CONSTANTS'
import { DisciplineCodeEnum, DisciplineCodeEnumKeys } from 'services/types'
import { useStore } from 'store'

interface IGetExerciceURLParams {
  currentDisciplineCode: DisciplineCodeEnum | DisciplineCodeEnumKeys | undefined
  gradeTypeCode: string
  gradeCode: string
  classId: string
  activityId: string
  contentUnitId: string
  suggestedApplicationType: string
}

export const useContentUnitLinks = () => {
  const { subscription, profile } = useStore()

  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  const getExerciceURL = ({ currentDisciplineCode, gradeTypeCode, gradeCode, classId, activityId, contentUnitId, suggestedApplicationType }: IGetExerciceURLParams) => {
    const STUDENT_ACTIVITY_PATH = CONTENT_UNIT_STUDENT_ACTIVITY
    const STUDENT_EVALUATION_PATH = CONTENT_UNIT_STUDENT_EVALUATION

    const contentLink: { [key: string]: any } = {
      teacher: {
        activity: CONTENT_UNIT_TEACHER_ACTIVITY(gradeTypeCode, gradeCode, classId, String(contentUnitId), String(activityId)),
        evaluation: CONTENT_UNIT_TEACHER_EVALUATION(gradeTypeCode, gradeCode, classId, String(contentUnitId), String(activityId))
      },
      student: {
        activity: STUDENT_ACTIVITY_PATH(String(contentUnitId), String(activityId)),
        evaluation: STUDENT_EVALUATION_PATH(String(contentUnitId), String(activityId))
      }
    }

    return contentLink[String(profileType)][suggestedApplicationType]
  }

  return {
    getExerciceURL
  }
}
