import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik, Form, Field, FieldProps } from 'formik'
import * as Yup from 'yup'
import { Button, Loading, TextField, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { LOGIN, ROOT } from 'navigation/CONSTANTS'
import BaseAuthLayout from 'pages/BaseAuthLayout'
import { IMessageErrors } from 'services/types'
import { useAuth } from 'navigation/ProvideAuth'
import { ILocation } from 'services/types/routes'
import { useProvideAuth } from 'navigation/useProvideAuth'
import CloseIcon from '@mui/icons-material/Close'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid } from '@mui/material'
import { useAtom } from 'jotai'
import { redirectLinkAtom } from 'navigation/atomStore'

interface FormValues {
  password: string
  passwordConfirmation: string
}

export const ChangeDefaultPassword: React.FC = () => {
  const auth = useAuth()
  const provideAuth = useProvideAuth()

  const history = useHistory()
  const { t } = useTranslation()
  const styles = useStyles()
  const location = useLocation<ILocation>()
  const { from } = location.state || { from: { pathname: '/' } }

  const [redirectLink, setRedirectLink] = useAtom(redirectLinkAtom)

  const initialValues = {
    password: '',
    passwordConfirmation: ''
  }

  const [errorMessages, setErrorMessages] = useState<IMessageErrors[] | undefined>([])

  const ChangeDefaultPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Deve conter no mínimo 8 caracteres')
      .required('Campo obrigatório'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
      .min(8, 'Deve conter no mínimo 8 caracteres')
      .required('Campo obrigatório')
  })

  const handleChangeDefaultPassword = async (values: typeof initialValues) => {
    const authResponse = await auth?.currentUser()

    if (values?.password === values?.passwordConfirmation && authResponse?.data?.id) {
      const response = await auth?.changeDefaultPassword(authResponse.data.id, values.password)
      if (response?.success) {
        if (redirectLink) {
          window.location.href = redirectLink
          setRedirectLink('')
        } else {
          history.replace(from.pathname !== LOGIN ? from : { pathname: ROOT })
        }
      } else {
        setErrorMessages(response ? response.errors : [t('Erro ao alterar senha')])
      }
    }
  }

  const handleDefinePasswordLater = async () => {
    await provideAuth.currentUser()
    if (redirectLink) {
      window.location.href = redirectLink
      setRedirectLink('')
    } else {
      window.location.href = ROOT
    }
  }

  const handleLogout = async () => {
    await auth?.signout()
  }

  return (
    <BaseAuthLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={ChangeDefaultPasswordSchema}
        onSubmit={handleChangeDefaultPassword}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Text2 fontSize='sm' mobile='xs' lineHeight='xs' fontWeight='semibold' support='colorSupport01'>
                  <KeyOutlinedIcon sx={{ marginRight: '8px' }} />
                  {`${t('Definição de senha')}`}
                </Text2>
              </Grid>
              <div className={styles.messageError}>
                {errorMessages?.map((e: IMessageErrors) =>
                  <div key={e.id}>{t(e.message)}</div>
                )}
              </div>
              <Grid item xs={12}>
                <Field name='password'>
                  {({
                    field,
                    meta
                  }: FieldProps<FormValues>) => (
                    <TextField
                      {...field}
                      id='password'
                      variant='outlined'
                      className={styles.input}
                      password
                      label={t('Digite sua nova senha')}
                      error={Boolean(meta.error)}
                      assistiveText={meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name='passwordConfirmation'>
                  {({
                    field,
                    meta
                  }: FieldProps<FormValues>) => (
                    <TextField
                      {...field}
                      id='passwordConfirmation'
                      variant='outlined'
                      className={styles.input}
                      password
                      label={t('Confirme sua nova senha')}
                      error={Boolean(meta.error)}
                      assistiveText={meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Button
                    className={styles.backButton}
                    onClick={handleLogout}
                    variant='ghost'
                    data-testid='set_new_password_back'
                  >
                    <ArrowBackIcon />
                    {t('Voltar')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={`${styles.submitButton} ${!dirty || isSubmitting ? '' : 'active'}`}
                    variant='primary'
                    disabled={!dirty || isSubmitting}
                    type='submit'
                    data-testid='set_new_password_confirm'
                  >
                    {!isSubmitting
                      ? <>{t('Confirmar')}<CheckOutlinedIcon sx={{ marginLeft: '14px' }} /></>
                      : <Loading />}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={styles.defineLater}
                    variant='ghost'
                    type='button'
                    onClick={handleDefinePasswordLater}
                    data-testid='set_new_password_set_later'
                  >
                    <CloseIcon />
                    {t('Definir depois')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </BaseAuthLayout>
  )
}

export default ChangeDefaultPassword
