import React from 'react'
import Ops from 'components/common/Ops'

interface ErrorBoundaryState {
  hasError: boolean
  error: string
}

export default class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  state = { hasError: false, error: '' }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: error?.toString()
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Ops>
          <div>
            <h2>App Crashed</h2>
            {this.state.error}
          </div>
        </Ops>
      )
    }

    // If there is no error just render the children component.
    return this.props.children
  }
}
