import { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { IContentUnitResponse, IDidacticContentResponse } from 'services/types/content-unit'
import {
  Content,
  Activities,
  Activity,
  Evaluations,
  Expeditions,
  OverviewProjectViewMode
} from '../components'
import Loading from 'components/common/Loading'
import SectionContext from '../provider/SectionProvider'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import { BusinessError } from 'navigation/BusinessError'
import {
  CONTENT_UNIT_STUDENT,
  CONTENT_UNIT_STUDENT_ACTIVITIES,
  CONTENT_UNIT_STUDENT_ACTIVITY,
  CONTENT_UNIT_STUDENT_EVALUATION,
  CONTENT_UNIT_STUDENT_EVALUATIONS,
  CONTENT_UNIT_STUDENT_CONTENT,
  CONTENT_UNIT_STUDENT_EXPEDITION,
  CONTENT_UNIT_STUDENT_EXPEDITIONS
} from 'navigation/CONSTANTS'
import { activityIndexSelectedAtom, allActivities, lastContentIdAtom, sectionIdAtom } from '../atomStore'
import { useAtom } from 'jotai'
import { useParams } from 'react-router'
import { getAllActivitiesByContent } from 'services/activity'
import NaturalCompare from 'string-natural-compare'
import useStudentAnswer from '../components/Activity/hooks/useStudentAnswer'

interface IContentUnitStudentProps {
  data?: IContentUnitResponse
  didacticContent: IDidacticContentResponse | null
}

export const ContentUnitStudent: React.FC<IContentUnitStudentProps> = ({ data, didacticContent }) => {
  const { subscription } = useStore()
  const { t } = useTranslation()
  const { contentId } = useParams<{ contentId: string }>()
  const { isVerifyingRoutes } = useStudentAnswer()

  // atoms
  const [sectionId, setSectionId] = useAtom(sectionIdAtom)
  const [activities, setActivities] = useAtom(allActivities)
  const [activityIndexSelected, setActivityIndexSelected] = useAtom(activityIndexSelectedAtom)
  const [lastContentId, setLastContentId] = useAtom(lastContentIdAtom)

  const isNotFirstPage = activityIndexSelected > 0
  const isNotLastPage = activities ? activityIndexSelected < activities?.length - 1 : false

  const getData = async () => {
    setLastContentId(Number(contentId))
    const hasNoActivities = !activities?.length
    const isNewContent = lastContentId !== Number(contentId)

    if (contentId && subscription && (hasNoActivities || isNewContent)) {
      const response = await getAllActivitiesByContent(subscription?.class?.id, contentId)
      if (response?.success) {
        let activitiesOrdered = response.data?.map((cur) => {
          // making the title just one to put in alphabetically order
          cur.title = cur.title ?? cur.activity_name
          cur.title = cur.title.trim()
          return cur
        }).sort((a, b) => NaturalCompare(a.title, b.title, { caseInsensitive: true }))

        let activityPosition = 0

        activitiesOrdered = activitiesOrdered.map((cur) => {
          if (cur.suggested_application_type === 'activity' && (!cur.title.toLowerCase().includes('diagn') ?? !cur.activity_name.toLowerCase().includes('diagn'))) {
            cur.position = ++activityPosition
          }
          return cur
        })

        setActivities(activitiesOrdered)
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  document.onkeydown = function (e) {
    switch (e.key) {
      case 'ArrowLeft':
        isNotFirstPage && setActivityIndexSelected(activityIndexSelected - 1)
        break
      case 'ArrowRight':
        isNotLastPage && setActivityIndexSelected(activityIndexSelected + 1)
    }
  }

  if (!subscription || subscription.class?.id === undefined) {
    return <BusinessError error={t('Erro ao selecionar turma')} />
  }

  // TODO: colocar validação descente, da para usar o middleware novo??
  if (!subscription) {
    return <BusinessError error={t('Erro ao selecionar turma')} />
  }

  if (!data || isVerifyingRoutes) {
    return (
      <Loading type='linear' />
    )
  }

  return (
    <Switch>
      <SectionContext.Provider value={{ sectionId, setSectionId }}>
        <Route exact path={CONTENT_UNIT_STUDENT()}>
          <OverviewProjectViewMode content={data} />
        </Route>
        <Route exact path={CONTENT_UNIT_STUDENT_CONTENT()}>
          <Content content={data} didacticContent={didacticContent} />
        </Route>
        <Route exact path={CONTENT_UNIT_STUDENT_ACTIVITIES()}>
          <Activities />
        </Route>
        <Route exact path={CONTENT_UNIT_STUDENT_EVALUATIONS()}>
          <Evaluations />
        </Route>
        <Route exact path={CONTENT_UNIT_STUDENT_EXPEDITIONS()}>
          <Expeditions content={data} />
        </Route>
        <Route exact path={CONTENT_UNIT_STUDENT_ACTIVITY()}>
          <Activity
            key={`${data.id}-activities`}
            content={data}
          />
        </Route>
        <Route exact path={CONTENT_UNIT_STUDENT_EVALUATION()}>
          <Activity
            key={`${data.id}-activities`}
            content={data}
          />
        </Route>
        <Route path={CONTENT_UNIT_STUDENT_EXPEDITION()}>
          <Expeditions content={data} />
        </Route>
      </SectionContext.Provider>
    </Switch>
  )
}

export default ContentUnitStudent
