import { Theme } from '@mui/material'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import PermMediaIcon from '@mui/icons-material/PermMedia'

import { IRecordTimelineResponse } from 'services/types'
import { parseKaTex } from 'utils/katex'

export const getTimelineContentTypes = (theme: Theme) => ({
  activity: {
    icon: <ExtensionOutlinedIcon />,
    backgroundColor: theme.colors.timelineRecordsItems.activity
  },
  evaluation: {
    icon: <BallotOutlinedIcon />,
    backgroundColor: theme.colors.timelineRecordsItems.evaluation
  },
  marco: {
    icon: <PermMediaIcon />,
    backgroundColor: theme.colors.timelineRecordsItems.marco
  },
  class_record: {
    icon: <FormatQuoteOutlinedIcon />,
    backgroundColor: theme.colors.timelineRecordsItems.classRecord
  }
})

const htmlSubstr = (str: string, count: number) => {
  const div = document.createElement('div')
  div.innerHTML = str

  walk(div, track)

  function track(el: { data: string | string[], substringData: (arg0: number, arg1: any) => any }) {
    if (!count) return

    const len = el.data.length
    count -= len

    if (count > 0) return

    let textFormated: string = el.substringData(0, el.data.length + count)

    if (textFormated.includes(' ')) {
      textFormated = textFormated.slice(0, Math.min(textFormated.length, textFormated.lastIndexOf(' ')))
    }
    el.data = `${textFormated}...`
  }

  function walk(el: ChildNode, fn: Function) {
    let node: ChildNode | null | undefined = el.firstChild
    do {
      if (node?.nodeType === 3) {
        fn(node)
      } else if (node?.nodeType === 1 && node?.childNodes && node?.childNodes[0]) {
        walk(node, fn)
      }
      node = node?.nextSibling
    } while (node)
  }
  return div.innerHTML
}

const isMathText = (text: string) => text.includes('katex-mathml')

const sliceDom = (elements: HTMLCollection, start: number, end: number) =>
  Array.prototype.slice.call(elements, start, end)

export const alternativeParsed = (record: IRecordTimelineResponse): string => {
  const formattedText = parseKaTex(record.choiceAlternative ?? '') as string

  if (!isMathText(formattedText)) return htmlSubstr(formattedText, 50)

  const katexParent = document.createElement('div')
  katexParent.innerHTML = formattedText

  const mathParent = katexParent.getElementsByClassName('katex-html')[0]
  const mathElements = mathParent.getElementsByClassName('base')

  const fragment = document.createDocumentFragment()
  sliceDom(mathElements, 0, 3).forEach(el => fragment.appendChild(el))

  mathParent.innerHTML = ''
  mathParent.append(fragment)

  return katexParent.innerHTML
}

export const parseQuestion = (record: IRecordTimelineResponse) =>
  parseKaTex(record?.questionText)?.match(/\[\[(.*?)\]/)?.[1]
