import { Text } from 'components/design-system'
import { monthsTitle } from 'utils/date'

import useStyles from './style'

interface IMonthLabel {
  monthNumber: number
}

export const MonthLabel = ({ monthNumber }: IMonthLabel) => {
  const classes = useStyles()

  return (
    <div className={classes.cardMonth} style={{ margin: '1em 0' }}>
      <Text type='body' color='dark'>
        {monthsTitle[monthNumber]}
      </Text>
    </div>
  )
}
