import { IPreviewState } from 'components/common/InputFilePreview/atomStore'
import { atom } from 'jotai'
import { IMediaResponse, IQuestionResponse, IDidacticContentResponse, ActivityControlStatusEnum } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'
import { IDisciplinesResponse, ISubscriptions } from 'services/types/schedule'
import { IGradeResponse } from 'services/types/taught-disciplines'
import { SubscriptionSelected } from '../RecipientsModal/types'

/* pensamento da madrugada: posso criar somente um atom gigante que tenha tudo
e depois ir escutando e alterando ele onde eu preciso, isso vai ser bom pra editar
TODO: alterar tudo isso pra um atom grande e criar os listener de cada parte do atom
*/

export interface IScheduleAtom {
  id?: number
  activity?: number | null
  activityTitle?: string | null
  ask_for_records?: boolean
  class?: number
  content_unit?: number | null
  didatic_section?: string | null
  disciplineId?: number | null
  start_date?: Date | 'now'
  end_date?: Date
  room_link?: string
  title?: string | null
  type?: string
  content?: string | null
  file_records?: IPreviewState[] | null
  subscriptions?: ISubscriptions[] | null
  has_updated?: boolean
}

export interface IContentUnitCardAtom {
  contenUnit: number
  title: string
  cover: IMediaResponse
  coverMobile?: IMediaResponse
  suggested_position?: number
  disciplines?: IDisciplinesResponse[]
  grade?: IGradeResponse
  didactic_content?: IDidacticContentResponse
}

export interface IContentSelectAtom {
  id?: number | string
  type: ContentTypeEnum | null
  title: string | null
  selected?: boolean
  activity_status?: {
    status: number
    has_answer: boolean
  }
  questions?: IQuestionResponse[]
  suggestedApplicationType?: 'activity' | 'evaluation'
  origin?: {
    id: number
    title: string
    questions: IQuestionResponse[]
  }
}

export const scheduleAtom = atom<IScheduleAtom | null>(null)

export const inUploadingProcess = atom<boolean>(false)
export const activitySelectTitleAtom = atom<string | null>(null)
export const activityRemovedQuestionsAtom = atom<string[]>([])
//  contentSelectAtom -> atom for populate contentSelectCard
export const contentSelectAtom = atom<IContentSelectAtom[] | null>(null)

// update atoms contentSelect
export const updateContentSelectAtom = atom(
  (get) => get(contentSelectAtom),
  (get, set, newContent: IContentSelectAtom[] | null | null) => {
    // clean selected questions

    // not able to select multiple activities
    const defaultTitle = newContent?.length ? `Cópia de ${newContent[0]?.title}` : null

    const title = newContent?.[0]?.title?.length && !!newContent?.[0]?.activity_status?.status && newContent?.[0]?.activity_status?.status !== ActivityControlStatusEnum.AVAILABLE ? newContent?.[0]?.title : defaultTitle

    set(activityRemovedQuestionsAtom, [])
    set(activitySelectTitleAtom, title ?? null)

    set(contentSelectAtom, newContent)
  }
)

// update atoms Schedule
export const updateScheduleAtom = atom(
  null,
  (_get, set, newScheduleObject: IScheduleAtom | null) => {
    set(scheduleAtom, newScheduleObject)
  }
)

export const updateScheduleContentAtom = atom(
  (get) => get(scheduleAtom)?.content,
  (get, set, newContent: string | null) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      content: newContent
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

// update scheduleTitle
export const updateScheduleTitleAtom = atom(
  (get) => get(scheduleAtom)?.title,
  (get, set, newTitle: string | null) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      title: newTitle
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

export const updateScheduleContentUnitAtom = atom(
  (get) => get(scheduleAtom)?.content_unit,
  (get, set, newContentUnit: number | null) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      content_unit: newContentUnit
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

// update scheduleDisciplineId
export const updateScheduleDisciplineIdAtom = atom(
  (get) => get(scheduleAtom)?.disciplineId,
  (get, set, newDisciplineId: number | null) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      disciplineId: newDisciplineId
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

// update scheduleDisciplineId
export const updateScheduleFileRecordsAtom = atom(
  (get) => get(scheduleAtom)?.file_records,
  (get, set, newFileRecords: IPreviewState[] | null) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      file_records: newFileRecords
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

// scheduleActivity get atom
export const scheduleActivityIdAtom = atom((get) => get(scheduleAtom)?.activity)

// scheduleDidaticSection get atom
export const scheduleDidaticSectionIdAtom = atom((get) => get(scheduleAtom)?.didatic_section)

export const updateActivityIdScheduleAtom = atom(
  null,
  (get, set, activityId: number | null) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      activity: activityId
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

export const updateDidaticSectionIdScheduleAtom = atom(
  null,
  (get, set, didaticSectionId: string | null) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      didatic_section: didaticSectionId
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

export const updateScheduleDisciplinesAtom = atom(
  null,
  (get, set, newDisciplines: IDisciplinesResponse[]) => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      discipline: newDisciplines
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

export const startDateAtom = atom((get) => get(scheduleAtom)?.start_date)

export const isHoursAndMinutesNowAtom = atom(true)

export const isInvalidStartDateAtom = atom(false)
export const isInvalidEndDateAtom = atom(false)

export const updateStartDateAtom = atom(
  null,
  (get, set, newStartDate: Date | undefined | 'now') => {
    const prev = get(scheduleAtom)
    const newScheduleObjectAtom = {
      ...prev,
      start_date: newStartDate
    }
    set(scheduleAtom, newScheduleObjectAtom)
  }
)

export const endDateAtom = atom((get) => get(scheduleAtom)?.end_date)

export const updateEndDateAtom = atom(null, (get, set, newEndDate: Date | undefined) => {
  const prev = get(scheduleAtom)
  const newScheduleObjectAtom = {
    ...prev,
    end_date: newEndDate
  }
  set(scheduleAtom, newScheduleObjectAtom)
})

export const subscriptionsAtom = atom<ISubscriptions[] | null>([])
export const subscriptionsAllSelectedAtom = atom<boolean>(false)

export const changingScheduleAtom = atom<boolean>(false)
export const reloadScheduleAtom = atom<boolean>(false)

export const contentUnitCardAtom = atom<IContentUnitCardAtom | null>(null)
export const expeditionModalSelectorAtom = atom<boolean>(false)
export const expeditionDisciplinesAtom = atom<IDisciplinesResponse[] | null>(null)
export const openContentUnitModalAtom = atom<boolean>(false)
export const openActivityQuestionsSelectModalAtom = atom<boolean>(false)
export const selectedStudentsModalAtom = atom<SubscriptionSelected[]>([])

export const recipientsModalSelectorAtom = atom<boolean>(false)
