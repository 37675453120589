import { IApiResponse, IClassPerformance, ILastUpdatedResponse, IStudentDetailedPerformance, IStudentPerformance, IStudentsListPerformance } from 'services/types'
import { request } from 'services/base'
import { CLASS_PERFORMANCE, DETAILED_PERFORMANCE, LAST_UPDATED_DATE, STUDENTS_LIST_PERFORMANCE, STUDENT_PERFORMANCE } from './CONSTANTS'

interface IGetClassPerformanceProps {
  schoolId: number
  classId: number
  disciplineId: number
}

interface IRequestPagination {
  limit: number
  offset: number
}

interface IGetClassPerformanceProps {
  schoolId: number
  classId: number
  disciplineId: number
  sortBy?: string
  search?: string
}
interface IGetPerformanceProps {
  classId: number
  disciplineId: number
  schoolId: number
  studentProfileId: number
  limit?: number
  offset?: number
  search?: string
  sortBy?: 'asc' | 'desc' | 'situation'
}

export const getClassPerformance = async ({ schoolId, classId, disciplineId }: IGetClassPerformanceProps): Promise<IApiResponse<IClassPerformance>> =>
  await request({ url: CLASS_PERFORMANCE(schoolId, classId, disciplineId) })

export const getStudentsListPerformance = async ({ schoolId, classId, disciplineId, limit, offset, search, sortBy }: IGetClassPerformanceProps & IRequestPagination): Promise<IApiResponse<IStudentsListPerformance>> =>
  await request({ url: STUDENTS_LIST_PERFORMANCE(schoolId, classId, disciplineId, offset, limit, search, sortBy) })

export const getStudentPerformance = async ({ schoolId, classId, disciplineId, studentProfileId }: IGetPerformanceProps): Promise<IApiResponse<IStudentPerformance>> =>
  await request({ url: STUDENT_PERFORMANCE(schoolId, classId, disciplineId, studentProfileId) })

export const getDetailedPerformance = async ({ schoolId, classId, disciplineId, studentProfileId, limit, offset, search, sortBy }: IGetPerformanceProps): Promise<IApiResponse<IStudentDetailedPerformance>> =>
  await request({ url: DETAILED_PERFORMANCE(schoolId, classId, disciplineId, studentProfileId, limit, offset, search, sortBy) })

export const getLastUpdatedDate = async (): Promise<IApiResponse<ILastUpdatedResponse>> =>
  await request({ url: LAST_UPDATED_DATE() })
