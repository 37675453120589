import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export const heading = makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.display': {
      fontSize: theme.font.fontSize.display,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.xxxl,
      fontWeight: theme.font.fontWeight.medium
    },
    '&.xLarge': {
      fontSize: theme.font.fontSize.xxxl,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.xl,
      fontWeight: theme.font.fontWeight.medium
    },
    '&.large': {
      fontSize: theme.font.fontSize.xxl,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.lg,
      fontWeight: theme.font.fontWeight.medium
    },
    '&.medium': {
      fontSize: theme.font.fontSize.xl,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.xxxl,
      fontWeight: theme.font.fontWeight.medium
    },
    '&.small': {
      fontSize: theme.font.fontSize.lg,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.sm,
      fontWeight: theme.font.fontWeight.medium
    }
  }
}))

export const subtitle = makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.small': {
      fontSize: theme.font.fontSize.lg,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.md,
      fontWeight: theme.font.fontWeight.medium
    },
    '&.default': {
      fontSize: theme.font.fontSize.lg,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.sm,
      fontWeight: theme.font.fontWeight.medium
    }
  }
}))

export const body = makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.large': {
      fontSize: theme.font.fontSize.md,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.sm,
      fontWeight: theme.font.fontWeight.regular
    },
    '&.medium': {
      fontSize: theme.font.fontSize.sm,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.xs,
      fontWeight: theme.font.fontWeight.regular
    },
    '&.small': {
      fontSize: theme.font.fontSize.xs,
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: theme.font.lineHeight.xs,
      fontWeight: theme.font.fontWeight.regular
    }
  }
}))

export const colors = makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.darkestColor': {
      color: theme.colorBrand.medium
    },
    '&.darkColor': {
      color: theme.colorBrand.dark
    },
    '&.mediumColor': {
      color: theme.colorBrand.medium
    },
    '&.lightColor': {
      color: theme.colorBrand.light
    },
    '&.lightestColor': {
      color: theme.colorBrand.lightest
    }
  }
}))
