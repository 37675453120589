import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& .MuiBox-root': {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.colors.neutral.lightBase,
      borderRadius: theme.spacingInline.xxxs,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
      padding: theme.spacingStack.xxs,

      // mobile
      [theme.breakpoints.down('sm')]: {
        width: `calc(100% - ${theme.spacingInline.xs})`
      }
    },
    '& .size-sm': { width: '328px' },
    '& .size-md': { width: '656px' }
  }
}))
