import * as Yup from 'yup'
import dayjs from 'dayjs'
import { cpf } from 'cpf-cnpj-validator'
import {
  checkCpf,
  checkEmail,
  checkPhone,
  userTokenValidation
} from 'services/users'
import { regexPhone } from 'utils/regex'
import { isValidDate, isValidEmail } from 'utils/validators'

// form validation schema
export const RegisterSchema = [
  // SchoolToken
  Yup.object().shape({
    token: Yup.string()
      .required('Campo obrigatório')
      .test(
        'token-validation',
        async (value: string | null | undefined, testContext: Yup.TestContext) => {
          if (value && typeof value === 'string' && value !== '------' && value.length === 6) {
            const response = await userTokenValidation(value)
            if (response.success) {
              return true
            } else if (response.message) {
              return testContext.createError({
                message: response.message === 'Expired token' ? 'Token expirado' : 'Token inválido',
                path: 'token'
              })
            }
          }

          return true
        }
      )
  }),
  // ConfirmationSchoolData - this step don't have fields to validate
  Yup.object().shape({}),
  // CompleteRegistration
  Yup.object().shape({
    cpf: Yup.string()
      .strict(true) // não transforma o valor do campo nas validações de trim e lowercase (impedindo o usuário de saber que digitou errado)
      .trim('Campo não deve conter espaços no início ou no final')
      .required('Campo obrigatório')
      .test(
        'cpf-validation',
        'CPF inválido',
        (value: string | null | undefined) => value ? cpf.isValid(value) : false
      )
      .test(
        'duplicate-cpf-validation',
        'CPF já cadastrado',
        async (value: string | undefined) => {
          const valueParse = value ? value?.replace(/\D/g, '') : ''
          if (value && typeof value === 'string' && valueParse.length === 11) {
            const response = await checkCpf(Number(valueParse))
            return !response.data
          }
          return true
        }
      ),
    name: Yup.string()
      .strict(true) // não transforma o valor do campo nas validações de trim e lowercase (impedindo o usuário de saber que digitou errado)
      .trim('Campo não deve conter espaços no início ou no final')
      .required('Campo obrigatório'),
    surname: Yup.string()
      .strict(true) // não transforma o valor do campo nas validações de trim e lowercase (impedindo o usuário de saber que digitou errado)
      .trim('Campo não deve conter espaços no início ou no final')
      .required('Campo obrigatório'),
    birthDate: Yup.string()
      .required('Campo obrigatório')
      .test(
        'invalid-date',
        'Data inválida',
        (value: string | null | undefined) => isValidDate(value, 'DD/MM/YYYY')
      )
      .test(
        'very-early-date',
        'Data inválida',
        (value: string | null | undefined) => dayjs(
          dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        ).isAfter(dayjs('01/01/1901', 'DD/MM/YYYY'))
      )
      .test(
        'future-birth-date-validation',
        'Não pode ser uma data futura',
        (value: string | null | undefined) => dayjs(
          dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        ).isBefore(dayjs())
      )
      .test(
        'below-one-year-birth-date-validation',
        'Data inválida',
        (value: string | null | undefined) => dayjs(
          dayjs(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        ).isBefore(dayjs().subtract(1, 'year'))
      ),
    email: Yup.string()
      .strict(true) // não transforma o valor do campo nas validações de trim e lowercase (impedindo o usuário de saber que digitou errado)
      .trim('Campo não deve conter espaços no início ou no final')
      .email('E-mail inválido')
      .required('Campo obrigatório')
      .test(
        'duplicate-email-validation',
        'E-mail já cadastrado',
        async (value: string | undefined) => {
          if (value && typeof value === 'string' && isValidEmail(value)) {
            const response = await checkEmail(value)
            return !response.data
          }
          return true
        }
      ),
    phone: Yup.string()
      .strict(true) // não transforma o valor do campo nas validações de trim e lowercase (impedindo o usuário de saber que digitou errado)
      .trim('Campo não deve conter espaços no início ou no final')
      .matches(
        regexPhone,
        'Número de celular inválido'
      )
      .test(
        'duplicate-cell-phone-validation',
        'Celular já cadastrado',
        async (value: string | undefined) => {
          if (value && typeof value === 'string' && value.length === 10) {
            const response = await checkPhone(Number(value?.replace(/\D/g, '')))
            return !response.data
          }
          return true
        }
      )
  })
]
