import { FormControlLabel, Radio, RadioGroup, useTheme } from '@mui/material'
import useStyle from '../style'

interface IProps {
  usernames: string[]
  onSelect: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

export const UsernameRadioGroup = (props: IProps) => {
  const { usernames, onSelect } = props
  const styles = useStyle()
  const theme = useTheme()

  const bindUsername = (username: string) => {
    if (username.length < 5) {
      return username.slice(0, 2) + username.slice(3).replace(/./g, '*')
    } else {
      return username.slice(0, 4) + username.slice(4).replace(/./g, '*')
    }
  }

  return (
    <RadioGroup
      aria-label='alternative'
      name='radio-buttons-group'
      sx={{ width: '100%' }}
      onChange={onSelect}
    >
      {
        usernames.map((username, i) => (
          <FormControlLabel
            key={i}
            value={username}
            sx={{
              marginLeft: 0,
              width: '100%',
              '&:checked': {
                backgroundColor: 'pink'
              }
            }}
            control={
              <Radio
                sx={{
                  color: theme.colors.support.colorSupport01,
                  '&.Mui-checked': {
                    color: theme.colors.support.colorSupport01
                  }
                }}
              />
            }
            label={<div className={styles.description} style={{ paddingTop: 0 }}>{bindUsername(username)}</div>}
          />
        ))
      }
    </RadioGroup>
  )
}
