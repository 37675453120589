import { atom } from 'jotai'

type IFilterByDateRangeAtom = {
  startDate?: Date | null
  endDate?: Date | null
}

export const filterByDateRangeAtom = atom<IFilterByDateRangeAtom | null>(null)
export const startDateIsValidAtom = atom<boolean>(false)
export const endDateIsValidAtom = atom<boolean>(false)
export const showStartDateErrorMessageAtom = atom<boolean>(false)
export const showEndDateErrorMessageAtom = atom<boolean>(false)

export const clearDateFilterAtom = atom<boolean>(false)

export const updateStartDateAtom = atom(
  null,
  (get, set, newStartDate: Date | null) => {
    const prev = get(filterByDateRangeAtom)
    const newDateObjectAtom = {
      ...prev,
      startDate: newStartDate
    }
    set(filterByDateRangeAtom, newDateObjectAtom)
  }
)

export const updateEndDateAtom = atom(
  null,
  (get, set, newEndDate: Date | null) => {
    const prev = get(filterByDateRangeAtom)
    const newDateObjectAtom = {
      ...prev,
      endDate: newEndDate
    }
    set(filterByDateRangeAtom, newDateObjectAtom)
  }
)
