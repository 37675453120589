import { bytesToMegaBytes } from '.'

export const cacheEstimate = async () => {
  try {
    const { quota, usage } = await navigator.storage.estimate()
    const percentUsage = ((usage ?? 0) / (quota ?? 0) * 100).toFixed(2)
    return {
      quota: bytesToMegaBytes(quota ?? 0),
      usage: bytesToMegaBytes(usage ?? 0),
      percentUsage
    }
  } catch {
    return {
      quota: 100,
      usage: 20,
      percentUsage: 20
    }
  }
}
