import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  circularProgress: {
    color: `${theme.colorBrand.medium} !important`
  },
  circularProgressCaption: {
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,
    color: `${theme.colorBrand.dark} !important`
  }
}))
