import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano,
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'not-allowed'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacingInset.md,
      alignItems: 'center',
      display: 'flex'
    }
  },
  containerTag: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px'
  },
  before: {
    paddingTop: '110px'
  },
  containerCards: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      width: '100%'
    },
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacingStack.xxxs
  },
  boxCard: {
    margin: '8px 0',
    display: 'inline',
    // mobile
    [theme.breakpoints.down('sm')]: {
      with: '100%'
    }
  },
  menu: {
    position: 'relative',
    background: 'transparent',
    top: '0px',
    width: '100%'
  },
  menuFixed: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: '64px',
      zIndex: 9,
      background: theme.colors.neutral.lightBase,
      paddingInline: theme.spacingInline.xxxs,
      borderBottom: `1px solid ${theme.colors.neutral.light10}`,
      marginTop: '-8px'
    }
  },
  containerContent: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginInline: '0px',
      justifyContent: 'center'
    }
  },
  backtoTop: {
    transition: 'all ease-in 0.2s',
    position: 'relative',
    bottom: '-100px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
    [theme.breakpoints.up('sm')]: {
      visibility: 'hidden',
      width: 0,
      height: 0
    }
  },
  searchTextInfo: {
    marginBottom: theme.spacingStack.xxxs,
    color: theme.colors.neutral.dark20,
    fontWeight: theme.font.fontWeight.medium,

    '& span': {
      fontWeight: `${theme.font.fontWeight.semibold} !important`,
      color: `${theme.colorBrand.dark} !important`
    }
  },
  explanatoryTextContainer: {
    paddingTop: theme.spacingInset.sm,
    paddingBottom: theme.spacingInset.sm,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0
    },
    '& span': {
      paddingBottom: theme.spacingInset.xxs,
      color: `${theme.colors.neutral.dark10} !important`
    }
  },
  goTotrainingAreaBtn: {
    width: '100%',
    height: '32px !important',
    fontSize: `${theme.font.fontSize.xxs} !important`,
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs} !important`,
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  searchContainer: {
    flexDirection: 'column-reverse !important' as 'column-reverse',
    marginBottom: theme.spacingInline.xxxs,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important' as 'row',
      marginBottom: 0
    }
  }
}))
