
export const setCurrentWorking = (key: string, value: string) => {
  localStorage.setItem(`@ContentCacheManager:currentWorking:${key}`, value)
}

export const removeCurrentWorking = (key: string) => {
  localStorage.removeItem(`@ContentCacheManager:currentWorking:${key}`)
}

export const getCurrentWorking = (key: string) => {
  return localStorage.getItem(`@ContentCacheManager:currentWorking:${key}`)
}
