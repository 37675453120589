import { makeStyles, createStyles } from '@mui/styles'
import { Theme, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'

export const Tab = styled('div')`
  background:  ${props => props.theme.colorBrand.medium};
  color: ${props => props.theme.colors.neutral.lightBase};
  height: auto;
  display: flex;
  top: -20px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding-inline: 22px;
  font-weight:  ${props => props.theme.font.fontWeight.bold};
  text-decoration: none;
  flex: 1;
  float: right;

&:hover {
  background-color: ${props => props.theme.colors.neutral.dark40};
}

&.selected {
  background-color: ${props => props.theme.colorBrand.lightest};
  color: ${props => props.theme.colorBrand.dark};
}

`

export const TabsList = styled('div')`
  display: flex;
  width: 100%;
  height: ${props => useMediaQuery(props.theme.breakpoints.down('sm')) ? '40px' : '64px'};
  margin-top: -20px;
  justify-content: space-between;
  border-radius: 16px 16px 0 0;
`

export default makeStyles((theme: Theme) => createStyles({
  header: {
    display: 'flex'
  },
  selectTitle: {
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.sm,
    color: theme.colorBrand.medium,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark20
    }
  },
  mobileExpeditionTitle: {
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.sm,
    color: theme.colorBrand.medium,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  renderedList: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '90%',
    overflowY: 'scroll',
    alignContent: 'flex-start',
    background: theme.colors.neutral.lightBase,
    gap: '24px',
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    },
    ...theme.scroll
  },
  errorWrapper: {
    height: '90%',
    overflowY: 'scroll',
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    }
  },
  selectArea: {
    padding: theme.spacingInline.xxs,
    background: theme.colors.neutral.lightBase,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    borderRadius: theme.borderRadius.sm
  },
  tabLink: {
    transition: 'all ease-in-out 0.2s',
    cursor: 'pointer',
    background: theme.colorBrand.medium,
    color: theme.colors.neutral.lightBase,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    paddingInline: '22px',
    fontWeight: theme.font.fontWeight.bold,
    textDecoration: 'none',
    flex: 1,
    float: 'right',

    '&.is-before': {
      borderTopRightRadius: '16px'
    },

    '&.is-after': {
      borderTopLeftRadius: '16px'
    },

    '&.selected': {
      background: theme.colorBrand.lightest,
      color: theme.colorBrand.dark,

      // desktop only
      [theme.breakpoints.up('sm')]: {
        borderTopRightRadius: '16px !important',
        borderTopLeftRadius: '16px !important'
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: '8px !important',
        borderTopLeftRadius: '8px !important'
      },

      '& ~ span': {
        borderTopRightRadius: '0'
      },
      '& + span': {
        borderTopLeftRadius: '16px',
        // mobile
        [theme.breakpoints.down('sm')]: {
          borderTopLeftRadius: '8px'
        }
      }
    },

    '&:first-child': {
      // desktop only
      [theme.breakpoints.up('sm')]: {
        borderTopLeftRadius: '16px !important'
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        borderTopLeftRadius: '8px !important'
      }
    },

    '&:last-child': {
      // // desktop only
      [theme.breakpoints.up('sm')]: {
        borderTopRightRadius: '16px !important'
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        borderTopRightRadius: '8px !important'
      }
    },

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      paddingBlock: theme.spacingInset.sm,
      lineHeight: '15px'
    },

    // mobile
    [theme.breakpoints.down('sm')]: {
      paddingInline: '12px',
      fontSize: theme.font.fontSize.xs,

      '& > span': {
        paddingBlock: '12px'
      },

      // to show only icon
      '& span > span': {
        display: 'none'
      },

      // to show title
      '&.selected': {
        flex: 4,

        '& span > span': {
          display: 'block',
          wordBreak: 'keep-all'
        }
      }
    }
  },
  tab: {
    [theme.breakpoints.down('sm')]: {
      height: '40px'
    }
  }
}))
