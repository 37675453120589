import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import { AddScheduleCard } from './components'
import { CurrentClass, StatusClassHandle } from 'components/common'
import { toast } from 'components/design-system/Toast/manager'
import { ScheduleCard, ScheduleOriginalContent } from 'pages/Dashboard/common'

import { IScheduleResponse } from 'services/types'

interface IReadyProps {
  schedules: IScheduleResponse[]
  addNewMaterial: () => void
}

export const ShowCards: React.FC<IReadyProps> = ({ schedules, addNewMaterial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentSchedule, setCurrentSchedule] = useState<IScheduleResponse>()

  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={12} mt={3} mb={4} display='flex' flexWrap='wrap' gap={2}>
        {schedules.map((schedule, i) =>
          <ScheduleCard
            key={schedule.id}
            dataTestid='teacher_materials_open'
            coverUrl={
              schedule.content_unit?.cover_mobile?.formats?.small?.url ??
              schedule.content_unit?.cover_mobile?.formats?.medium?.url ??
              schedule.content_unit?.cover_mobile?.formats?.large?.url ??
              schedule.content_unit?.cover_mobile?.formats?.thumbnail?.url ??
              schedule.content_unit?.cover_mobile?.url ?? ''
            }
            onDelete={() => {
              toast.handler({
                content: t('Evento excluído!'),
                duration: 10000,
                severity: 'success'
              })
            }}
            onClick={() => {
              if (schedule.type === 'original_content') {
                setCurrentSchedule(schedule)
                setIsOpen(true)
              }
            }}
            {...schedule}
          />
        )
        }
        <StatusClassHandle>
          <CurrentClass>
            <AddScheduleCard add={addNewMaterial} dataTestid='teacher_materials_add' />
          </CurrentClass>
        </StatusClassHandle>
      </Grid>
      {
        currentSchedule &&
        <ScheduleOriginalContent
          {...currentSchedule}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(!isOpen)
            setCurrentSchedule(undefined)
          }}
          dataTestIdClose='preview_teacher_materials_close'
          dataTestIdOpenAttachment='preview_teacher_materials_open_attachment'
        />
      }
    </>
  )
}
