import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCurricularStore, useStore } from 'store'

import { Alert, Button } from 'components/design-system'

type IStateRender = 'idle' | 'maxAttempts' | 'tryAgain'

export const Error: React.FC = () => {
  const [attempts, setAttempts] = useState<number>(0)
  const [state, setState] = useState<IStateRender>('idle')
  const { t } = useTranslation()

  const { setStoreDisciplines } = useCurricularStore()
  const { subscription } = useStore()

  const { classId } = useParams<{ classId: string }>()

  useEffect(() => {
    setState('tryAgain')
  }, [])

  const newAttempt = () => {
    setState('tryAgain')
    setAttempts(attempts + 1)

    if (attempts > 4 || !(classId || subscription)) {
      setState('maxAttempts')
      return
    }
    let theClass: number | null = null
    if (classId) theClass = parseInt(classId)
    if (subscription?.class?.id) theClass = subscription?.class?.id
    setStoreDisciplines({ theClass })
  }

  const ActionButton = () => (
    <Button
      className='actionButton'
      variant='ghost'
      size='small'
      data-testid='followup_reload'
      onClick={newAttempt}
    >
      {t('Recarregar')}
    </Button>
  )

  const idle = () => ''

  const tryAgain = () => <Alert content={t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')} outlined={true} severity='error' actionButton={<ActionButton />} sx={{ alignSelf: 'flex-start' }} />

  const maxAttempts = () => <Alert content={t('Houve uma falha ao buscar as disciplinas da escola. Será necessário fazer o login novamente.')} outlined={true} severity='error' sx={{ alignSelf: 'flex-start' }} />

  const stateRender = {
    idle,
    tryAgain,
    maxAttempts
  }

  return (
    <>
      {stateRender[state]()}
    </>
  )
}
