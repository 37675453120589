import Cookies from 'utils/cookies'

export const Storage = {
  token: {
    set: (value: string) => {
      Cookies.token.set(value)
    },
    get: () => {
      return Cookies.token.get() ?? ''
    },
    remove: () => {
      Cookies.token.remove()
    }
  },
  props: {
    get: () => localStorage.getItem('cloe::web::props') ? JSON.parse(localStorage.getItem('cloe::web::props') as string).state : false,
    getSubscription: () => localStorage.getItem('cloe::web::props') ? JSON.parse(localStorage.getItem('cloe::web::props') as string).state.subscription : false,
    getProfile: () => localStorage.getItem('cloe::web::props') ? JSON.parse(localStorage.getItem('cloe::web::props') as string).state.profile : false,
    remove: () => localStorage.removeItem('cloe::web::props')
  },
  disciplines: {
    remove: () => localStorage.removeItem('cloe::web::disciplines')
  }
}

export default Storage
