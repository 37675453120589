import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { BallotOutlined, DescriptionOutlined, ExtensionOutlined, RocketLaunchOutlined } from '@mui/icons-material'
import { RESET, useResetAtom } from 'jotai/utils'

import {
  CONTENT_UNIT_STUDENT,
  CONTENT_UNIT_STUDENT_ACTIVITIES,
  CONTENT_UNIT_STUDENT_EVALUATIONS,
  CONTENT_UNIT_STUDENT_CONTENT,
  CONTENT_UNIT_STUDENT_EXPEDITIONS
} from 'navigation/CONSTANTS'
import { DisciplineCodeEnum } from 'services/types'
import { IGradeTypeEnum } from 'services/types/grade'
import setupMenuEvaluations from '../setup-menu-evaluations.json'

import useStyles from '../style'

import { IHeaderProps, INavLinks } from '../types'

import { disableEvaluations } from '../utils'
import Appcues from 'utils/appcues'
import { useStore } from 'store'
import dayjs from 'dayjs'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { currentSubMenuTabAtom, previousSubMenuTabAtom, subMenuTabsAtom, subMenuTabsLoadingAtom } from '../../SideMenu/components/atomStore'
import { useAtom } from 'jotai'

export const ContentUnitHeaderStudentWithProjectViewMode: React.FC<IHeaderProps> = ({ content }) => {
  const [linkBefore, setLinkBefore] = useState(0)
  const { schoolPeriod } = useStore()
  const classes = useStyles()
  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const hasDidacticContent = (!!content?.didactic_content?.id) && content?.didactic_content?.sections.length
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const [, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [subMenuTabsLoading] = useAtom(subMenuTabsLoadingAtom)
  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)
  const resetPreviousSubMenuTab = useResetAtom(previousSubMenuTabAtom)

  useEffect(() => {
    const htmlNavLinks = document.getElementsByClassName('tab-link')

    for (let i = 0; i < htmlNavLinks.length; i++) {
      if (i > 0 && htmlNavLinks.item(i)?.className.includes('is-active')) {
        setLinkBefore(i - 1)
      }
    }
  }, [])

  const handleTabNavigation = () => {
    setCurrentSubMenuTab(RESET)
  }

  // TODO: refatorar toda essa treta de menus pra quais segmentos e perfis (fazer um getTabsForProfileSegment?)
  const STARTING_POINT_TABS = {
    default: {
      id: 'ponto-de-partida',
      gtmTagId: 'expedition-tab-what-we-will-do',
      component: <span><RocketLaunchOutlined /> <span>{t('Ponto de partida')}</span></span>,
      to: CONTENT_UNIT_STUDENT(content.id?.toString()),
      isActive: undefined,
      exact: true,
      datatestid: 'content_expedition_menu_section',
      onClick: () => Appcues.track('est_ponto_partida')
    },
    summary: {
      id: 'ponto-de-partida',
      gtmTagId: 'expedition-tab-what-we-will-do',
      component: <span><RocketLaunchOutlined /> <span>{t('Ponto de partida')}</span></span>,
      to: CONTENT_UNIT_STUDENT(content.id?.toString()),
      isActive: undefined,
      exact: true,
      datatestid: 'content_expedition_menu_section',
      onClick: () => Appcues.track('est_ponto_partida')
    }
  }

  const CONTENT_TABS = {
    default: {
      id: 'conteudo',
      gtmTagId: 'expedition_tab_content',
      component: <span><DescriptionOutlined /> <span>{t('Conteúdo')}</span></span>,
      to: CONTENT_UNIT_STUDENT_CONTENT(content.id?.toString(), content?.didactic_content?.sections[0]?.id?.toString()),
      isActive: () => window.location.pathname.includes(`${CONTENT_UNIT_STUDENT(content.id?.toString())}/content/`),
      exact: false,
      datatestid: 'content_expedition_menu_section'
    },
    EI: {
      id: 'conteudo',
      gtmTagId: '',
      component: <span><DescriptionOutlined /> <span>{t('Almanaque')}</span></span>,
      to: CONTENT_UNIT_STUDENT_CONTENT(content.id?.toString(), content?.didactic_content?.sections[0]?.id?.toString()),
      isActive: () => window.location.pathname.includes(`${CONTENT_UNIT_STUDENT(content.id?.toString())}/content/`),
      exact: false,
      datatestid: 'content_expedition_menu_section'
    }
  }

  const ACTIVITIES_TABS = {
    default: {
      id: 'atividades',
      gtmTagId: 'expedition_tab_activities',
      component: <span><ExtensionOutlined /> <span>{t('Atividades')}</span></span>,
      to: CONTENT_UNIT_STUDENT_ACTIVITIES(content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section'
    },
    content: {
      id: 'atividades',
      gtmTagId: 'expedition_tab_content',
      component: <span><DescriptionOutlined /> <span>{t('Conteúdo')}</span></span>,
      to: CONTENT_UNIT_STUDENT_ACTIVITIES(content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section'
    }
  }

  const EVALUATIONS_TABS = {
    default: {
      id: 'avaliacoes',
      gtmTagId: 'expedition_tab_assessments',
      component: <span><BallotOutlined /> <span>{t('Avaliações')}</span></span>,
      to: CONTENT_UNIT_STUDENT_EVALUATIONS(content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section'
    },
    exercises: {
      id: 'avaliacoes',
      gtmTagId: '',
      component: <span><BallotOutlined /> <span>{t('Exercícios')}</span></span>,
      to: CONTENT_UNIT_STUDENT_EVALUATIONS(content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section'
    }
  }

  const EXPEDITIONS_TABS = {
    default: {
      id: 'expedicoes',
      component: <span><DescriptionOutlined /> <span>{t('Conteúdo')}</span></span>,
      to: CONTENT_UNIT_STUDENT_EXPEDITIONS(content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section'
    },
    unity: {
      id: 'expedicoes',
      component: <span><DescriptionOutlined /> <span>{t('Unidade')}</span></span>,
      to: CONTENT_UNIT_STUDENT_EXPEDITIONS(content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section'
    }
  }

  let studentNavLinks: INavLinks[] = [
    STARTING_POINT_TABS.default,
    CONTENT_TABS.default,
    ACTIVITIES_TABS.default,
    EVALUATIONS_TABS.default
  ]

  const ruleCloeExpand = () => {
    if (Number(content?.grade?.grade_type) === IGradeTypeEnum.EI) {
      studentNavLinks = [
        STARTING_POINT_TABS.default,
        CONTENT_TABS.EI,
        ACTIVITIES_TABS.default
      ]
      return
    }

    return null
  }

  const ruleCloeRegular = () => {
    if (!hasDidacticContent) {
      studentNavLinks = [
        STARTING_POINT_TABS.default,
        EXPEDITIONS_TABS.default
      ]
    }

    if (Number(content?.grade?.grade_type) === IGradeTypeEnum.EI) {
      studentNavLinks = [
        STARTING_POINT_TABS.default,
        CONTENT_TABS.EI
      ]

      // We remove activities because there's no longer support in EI segment for it
      // To ensure compatibility with older periods before 2023 (when the rule starts) we add here
      if (!schoolPeriod?.current && !schoolPeriod?.next && schoolPeriod?.start_date !== undefined && dayjs(schoolPeriod?.start_date).isBefore(dayjs('2022-12-31'))) {
        studentNavLinks.push(ACTIVITIES_TABS.default)
      }

      if (isCloeExpand) {
        ruleCloeExpand()
      }
    }

    if (Number(content?.grade?.grade_type) === IGradeTypeEnum.EM) {
      studentNavLinks = [
        STARTING_POINT_TABS.default,
        CONTENT_TABS.default,
        EVALUATIONS_TABS.exercises
      ]
    }

    if (Number(content?.grade?.grade_type) === IGradeTypeEnum.EM_IT) {
      studentNavLinks = [
        STARTING_POINT_TABS.default,
        CONTENT_TABS.default,
        EVALUATIONS_TABS.exercises
      ]
    }
  }

  ruleCloeRegular()

  // filtrar avaliações no caso de grade e disciplinas do json
  studentNavLinks = studentNavLinks.filter(cur => disableEvaluations(setupMenuEvaluations, content?.disciplines, content?.grade?.code, cur?.id))

  return (
    <>
      {
        studentNavLinks.map((nav, i) =>
          <NavLink
            key={i}
            to={nav.to}
            className={`tab-link ${classes.tabLink} ${linkBefore === i && 'is-before'}`}
            activeClassName='is-active'
            exact={nav.exact}
            isActive={nav.isActive}
            aria-disabled={subMenuTabsLoading}
            onClick={(e) => {
              if (subMenuTabsLoading) {
                e.preventDefault()
              }
              setLinkBefore(i - 1)
              if (nav?.onClick) {
                nav.onClick()
              }
              handleTabNavigation()

              setCurrentSubMenuTab(RESET)
              resetSubMenuTabs()
              resetPreviousSubMenuTab()
            }}
            id={nav.gtmTagId}
            data-testid={nav.datatestid}
          >
            {nav.component}
          </NavLink>)
      }
    </>
  )
}
