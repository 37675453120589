import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    boxShadow: theme.shadowLevels.level5,
    height: 'auto',
    padding: `${theme.spacingStack.xs} ${theme.spacingStack.xxs}`,

    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      paddingTop: '64px'
    }
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium,
    padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.nano}`,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: '18px',
    // larger screens
    [theme.breakpoints.up('sm')]: {
      padding: 0
    },
    '& svg': {
      marginRight: theme.spacingInline.nano
    }
  },
  backButton: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colorBrand.medium
  },
  headerContainer: {
    alignItems: 'center',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    '&.containerAcordeon': {
      borderBlock: 'none'
    }
  },
  titleAccordion: {
    alignItems: 'center',
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.md,
    color: theme.colors.neutral.dark30,
    lineHeight: theme.font.lineHeight.xs
  },
  subtitleAccordion: {
    alignItems: 'center',
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    color: theme.colors.neutral.dark30,
    lineHeight: theme.font.lineHeight.sm
  },
  stackContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: theme.colorBrand.medium
    },
    '& .MuiInputLabel-root': {
      color: `${theme.colorBrand.medium} !important`,
      fontWeight: `${theme.font.fontWeight.regular} !important`
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.colorBrand.medium} !important`
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important',
      justifyContent: 'flex-start',
      '& div:nth-of-type(1)': {
        marginRight: theme.spacingInset.xxs
      },
      '& div:nth-of-type(2)': {
        margin: [[0, theme.spacingInset.md, 0, theme.spacingInset.xxs]]
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  textField: {
    paddingTop: theme.spacingStack.xxs,
    '& div': { width: '100%' },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacingStack.xs
    }
  },
  helpTextTitle: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.xxs,
    color: theme.colors.neutral.dark30,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 0,
    '&.limit': {
      color: theme.colors.negative.feedbackDark
    }
  },
  helpTextDescription: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.xxs,
    color: theme.colors.neutral.dark30,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 0,
    '&.limit': {
      color: theme.colors.negative.feedbackDark
    }
  },
  text: {
    color: theme.colors.neutral.dark30,
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.xs,
    paddingTop: 0,
    marginTop: 20,
    marginBottom: 0
  },
  classButton: {
    alignItems: 'center',
    display: 'flex',
    paddingBlock: theme.spacingStack.nano,
    justifyContent: 'space-around',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingBlock: theme.spacingStack.xs,
      justifyContent: 'end'
    }
  },
  disciplineContainer: {
    padding: `${theme.spacingInset.xxs} 0px ${theme.spacingInset.md} 0px`
  }
}))
