/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useState } from 'react'
import { Grid, MenuItem } from '@mui/material'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { useAtom } from 'jotai'

import { ICommentsResponse } from 'services/types/comments'
import { Button } from 'components/design-system'

import { useAuth } from 'navigation/ProvideAuth'
import { DropDown } from 'components/common'

import useStyles from './style'
import { deleteCommentById } from 'services/comments'
import { getCommentsByAnswerAtom } from '../../../../../../../../atomStore'

interface ICardStudentAnswerCommentContentActionsProps {
  comment: ICommentsResponse
  handleEdit?: () => void
}

export const CardStudentAnswerCommentContentActions: React.FC<ICardStudentAnswerCommentContentActionsProps> = ({ comment, handleEdit }) => {
  const auth = useAuth()
  const classes = useStyles()

  // state
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  // atom
  const [, getCommentsByAnswer] = useAtom(getCommentsByAnswerAtom)

  const deleteComment = async (id: number) => {
    const response = await deleteCommentById(`${id}`)

    if (response.success) {
      await getCommentsByAnswer({ userSchoolProfile: Number(comment.user_school_profile.id ?? '0'), activityId: comment.activity.id, classId: comment.class.id, questionId: comment.questionId })
    }

    return response.success
  }

  return (
    <Grid>
      {auth?.user?.id !== comment.comment_owner.id && !comment.isRead &&
        <Grid className={classes.cardStudentAnswerCommentContentActionsBadgeRead} />
      }
      {auth?.user?.id === comment.comment_owner.id &&
        <Grid item xs={2} lg={2} display='flex' gap={2}>
          <DropDown handleClose={() => setShowConfirmDelete(false)} dataTestid={ comment?.activity?.suggested_application_type === 'activity' ? 'correction_activity_menu_answers_edit_delete_comment' : 'correction_assessment_menu_answers_edit_delete_comment' }>
            {
              showConfirmDelete
                ? (
                  <Grid className={classes.cardStudentAnswerCommentContentActionsDropdown} gap={1}>
                    <Grid>
                      Você está prestes a excluir esse comentário. Gostaria de prosseguir?
                    </Grid>
                    <Grid>
                      <Button
                        data-testid={comment?.activity?.suggested_application_type === 'activity' ? 'correction_activity_menu_answers_not_delete_comment' : 'correction_assessment_menu_answers_not_delete_comment'}
                        variant='ghost'
                        onClick={() => setShowConfirmDelete(false)}
                        sx={{
                          height: '32px !important',
                          fontSize: '12px !important'
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        data-testid={comment?.activity?.suggested_application_type === 'activity' ? 'correction_activity_menu_answers_confirm_delete_comment' : 'correction_assessment_menu_answers_confirm_delete_comment'}
                        onClick={async () => await deleteComment(comment.id)}
                        sx={{
                          height: '32px !important',
                          fontSize: '12px !important'
                        }}
                      >
                        Excluir
                      </Button>
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid paddingX={2}>
                    <MenuItem
                      onClick={e => { e.stopPropagation(); setShowConfirmDelete(true) }}
                      data-testid={comment?.activity?.suggested_application_type === 'activity' ? 'correction_activity_menu_answers_delete_comment' : 'correction_assessment_menu_answers_delete_comment'}
                    >
                      <Grid mb={1} gap='16px' display='flex' alignItems='center' className={classes.cardStudentAnswerCommentContentActionsMenuOptions}>
                        <DeleteOutline /> Excluir
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      onClick={() => { handleEdit?.(); setShowConfirmDelete(false) }}
                      data-testid={comment?.activity?.suggested_application_type === 'activity' ? 'correction_activity_menu_answers_edit_comment' : 'correction_assessment_menu_answers_edit_comment'}
                    >
                      <Grid gap='16px' display='flex' alignItems='center' className={classes.cardStudentAnswerCommentContentActionsMenuOptions}>
                        <EditOutlined /> Editar
                      </Grid>
                    </MenuItem>
                  </Grid>
                )
            }
          </DropDown>
        </Grid>
      }
    </Grid>
  )
}

export default CardStudentAnswerCommentContentActions
