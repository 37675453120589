import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const rootClass = {
  display: 'flex',
  color: '#851F82',
  alignItems: 'center',
  padding: '8px 16px!important',
  fontSize: '16px',
  lineHeight: '12px',
  outline: 'none',
  background: 'none',
  '& .MuiCircularProgress-root': {
    maxWidth: '16px',
    maxHeight: '16px'
  },
  '& .MuiButton-startIcon': {
    marginRight: '16px',
    marginLeft: '4px',
    pointerEvents: 'none'
  }
}

export default makeStyles((theme: Theme) => createStyles({
  root: {
    ...rootClass,
    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  error: {
    color: `${theme.colors.negative.feedbackMedium}!important`,
    borderColor: `${theme.colors.negative.feedbackMedium}!important`
  },
  downloaded: {
    color: `${theme.colors.positive.feedbackDark}!important`,
    borderColor: `${theme.colors.positive.feedbackDark}!important`
  },
  rootInside: {
    ...rootClass,
    borderColor: 'white!important',
    color: 'white!important'
  },
  errorInside: {
    color: `${theme.colors.negative.feedbackDark}!important`,
    backgroundColor: `${theme.colors.negative.feedbackLightest}!important`,
    borderColor: 'transparent!important'
  },
  downloadedInside: {
    color: `${theme.colors.positive.feedbackDark}!important`,
    backgroundColor: `${theme.colors.positive.feedbackLightest}!important`,
    borderColor: 'transparent!important'
  },
  common: {
  },
  commonInside: {
    color: 'white!important',
    fill: 'white!important'
  }
}))
