/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { createStyles, makeStyles } from '@mui/styles'
import { Theme, alpha } from '@mui/material'
import { IWeekDayProps } from '.'

export default makeStyles((theme: Theme) => createStyles({
  weekDayWrapper: {
    minWidth: '108px',
    minHeight: '108px',
    marginInline: '10px',
    borderRadius: theme.borderRadius.sm,
    background: ({ selected, isHover, disabled }: IWeekDayProps) => {
      if (selected) return theme.colorBrand.medium
      if (isHover && !disabled) return alpha(theme.colorBrand.light, 0.2)
      return theme.colors.neutral.lightBase
    },
    textAlign: 'center',
    padding: theme.spacingStack.nano,
    cursor: ({ disabled }: IWeekDayProps) => (disabled ? 'default' : 'pointer'),
    transform: ({ isHover, disabled }: IWeekDayProps) => {
      if (isHover && !disabled) return 'scale(1.02)'
      return 'none'
    },
    border: ({ isToday, selected }: IWeekDayProps) => {
      if (isToday && !selected) return `1px solid ${theme.colorBrand.medium}`
      return `1px solid ${theme.colors.neutral.light10}`
    }
  },
  weekDaySeparator: {
    height: '1px',
    background: ({ selected, isToday, isHover, disabled }: IWeekDayProps) => {
      if (isToday || selected || !disabled) return theme.colorBrand.light
      return theme.colorBrand.light
    }
  },
  weekDayDescription: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    display: 'block',
    lineHeight: theme.font.lineHeight.xs,
    color: ({ selected, isToday, isHover, disabled }: IWeekDayProps) => {
      if (selected) return theme.colors.neutral.lightBase
      return theme.colorBrand.light
    }
  },
  weekDayNumber: {
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.semibold,
    display: 'block',
    lineHeight: theme.font.lineHeight.xs,
    color: ({ selected, isToday, isHover, disabled }: IWeekDayProps) => {
      if (selected) return theme.colors.neutral.lightBase
      return theme.colorBrand.dark
    }
  },
  weekNoticesEvents: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xxs,
    padding: theme.spacingStack.nano,
    color: ({ selected, isToday, isHover, disabled }: IWeekDayProps) => {
      if (selected) return theme.colors.neutral.lightBase
      return theme.colorBrand.medium
    }
  }
}))
