import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ptBR } from 'date-fns/locale'
import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import { useStore } from 'store'

import {
  Grid,
  GridProps,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Button } from 'components/design-system'
import { InputDate } from 'components/common'

import {
  filterByDateRangeAtom,
  updateStartDateAtom,
  updateEndDateAtom,
  clearDateFilterAtom,
  startDateIsValidAtom,
  endDateIsValidAtom,
  showStartDateErrorMessageAtom,
  showEndDateErrorMessageAtom
} from './atomStore'

import filterBasedSchoolPeriodRange from './utils/filterBasedSchoolPeriodRange'

import useStyle from './style'

type JustifyPosition = 'center' | 'end' | 'flex-end' | 'flex-start' | 'start'
type JustifyDistribution = 'space-around' | 'space-between' | 'space-evenly' | 'stretch'

export interface IFilterByDateRangeProps extends Partial<GridProps> {
  justify: JustifyPosition | JustifyDistribution
  onCallback: (active: boolean) => void
}

export const FilterByDateRange: React.FC<IFilterByDateRangeProps> = ({ justify, onCallback, children, ...props }) => {
  const [filterData] = useAtom(filterByDateRangeAtom)

  const [initialDate, setInitialDate] = useState<Date | null>(filterData?.startDate ?? null)
  const [finalDate, setFinalDate] = useState<Date | null>(filterData?.endDate ?? null)
  const [cleanFilter, setCleanFilter] = useAtom(clearDateFilterAtom)
  const [, updateStartDate] = useAtom(updateStartDateAtom)
  const [, updateEndDate] = useAtom(updateEndDateAtom)
  const [startDateIsValid, setStartDateIsValid] = useAtom(startDateIsValidAtom)
  const [endDateIsValid, setEndDateIsValid] = useAtom(endDateIsValidAtom)
  const [showStartDateErrorMessage, setShowStartDateErrorMessage] = useAtom(showStartDateErrorMessageAtom)
  const [showEndDateErrorMessage, setShowEndDateErrorMessage] = useAtom(showEndDateErrorMessageAtom)

  const { schoolPeriod } = useStore()

  const classes = useStyle({ justify })
  const { t } = useTranslation()

  const { deadline, yearFirstDay } = filterBasedSchoolPeriodRange(schoolPeriod)
  const inputInitialDate = filterBasedSchoolPeriodRange(schoolPeriod, 'initialDate')
  const inputFinalDate = filterBasedSchoolPeriodRange(schoolPeriod, 'finalDate')

  const handleChangeStartDate = (chosenDate: Date | null, keyboardInputValue?: string) => {
    const date = dayjs(chosenDate).startOf('day').toDate()

    if (!dayjs(date).isValid() || dayjs(date).isAfter(deadline) || dayjs(date).isBefore(yearFirstDay) || dayjs(date).isAfter(finalDate)) {
      setShowStartDateErrorMessage(true)
      setStartDateIsValid(false)
    } else {
      if (showStartDateErrorMessage) {
        setShowStartDateErrorMessage(false)
      }

      if (dayjs(finalDate).isValid() && !dayjs(finalDate).isAfter(deadline) && showEndDateErrorMessage) {
        setShowEndDateErrorMessage(false)
        setEndDateIsValid(true)
      }

      if (keyboardInputValue) {
        setStartDateIsValid(keyboardInputValue.length >= 3)
      }

      setStartDateIsValid(true)
    }

    if (cleanFilter) {
      setCleanFilter(false)
    }

    setInitialDate(date)
  }

  const handleChangeEndDate = (chosenDate: Date | null, keyboardInputValue?: string) => {
    const date = dayjs(chosenDate).startOf('day').toDate()

    if (!dayjs(date).isValid() || dayjs(date).isAfter(deadline) || dayjs(date).isBefore(yearFirstDay) || dayjs(date).isBefore(initialDate)) {
      setShowEndDateErrorMessage(true)
      setEndDateIsValid(false)
    } else {
      if (showEndDateErrorMessage) {
        setShowEndDateErrorMessage(false)
      }

      if (dayjs(initialDate).isValid() && !dayjs(initialDate).isBefore(yearFirstDay) && !dayjs(initialDate).isAfter(deadline) && showStartDateErrorMessage) {
        setShowStartDateErrorMessage(false)
        setStartDateIsValid(true)
      }

      if (keyboardInputValue) {
        setEndDateIsValid(keyboardInputValue.length >= 3)
      }

      setEndDateIsValid(true)
    }

    if (cleanFilter) {
      setCleanFilter(false)
    }

    setFinalDate(date)
  }

  const handleSearchByDate = () => {
    setCleanFilter(true)

    updateStartDate(initialDate)
    updateEndDate(finalDate)

    onCallback(true)
  }

  const handleClearFilter = () => {
    updateStartDate(null)
    updateEndDate(null)
    setCleanFilter(false)
    setStartDateIsValid(false)
    setEndDateIsValid(false)
    setShowStartDateErrorMessage(false)
    setShowEndDateErrorMessage(false)

    onCallback(true)
  }

  return (
    <Grid item {...props}>
      <Accordion defaultExpanded className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{ p: 0, m: 0 }}
        >
          <p className={classes.filterTitle}>{t('Filtrar por período')}</p>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {children}
          <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
            <Grid container spacing={1} className={classes.gridTemplate}>
              <Grid item className={classes.filterTitleDesktop} sx={{ gridArea: 'title' }}>
                <p>{t('Filtrar por período')}</p>
              </Grid>
              <Grid item className={classes.inputDateContainer} sx={{ gridArea: 'datainicial' }}>
                <InputDate
                  label={t('Data inicial')}
                  initialDate={initialDate}
                  minDate={inputInitialDate.yearFirstDay}
                  maxDate={inputInitialDate.deadline}
                  mask='__/__'
                  onChangeDate={handleChangeStartDate}
                  error={showStartDateErrorMessage}
                  textFieldProps={{ helperText: showStartDateErrorMessage ? 'Data inválida' : '' }}
                  dataTestid='filter_register_select_start_date'
                  defaultCalendarMonth={inputInitialDate.calendarMonth}
                />
              </Grid>
              <Grid item className={classes.inputDateContainer} sx={{ gridArea: 'datafinal' }}>
                <InputDate
                  label={t('Data final')}
                  initialDate={finalDate}
                  minDate={inputFinalDate.yearFirstDay}
                  maxDate={inputFinalDate.deadline}
                  mask='__/__'
                  onChangeDate={handleChangeEndDate}
                  error={showEndDateErrorMessage}
                  textFieldProps={{ helperText: showEndDateErrorMessage ? 'Data inválida' : '' }}
                  dataTestid='filter_register_select_end_date'
                  defaultCalendarMonth={inputFinalDate.calendarMonth}
                />
              </Grid>
              <Grid item sx={{ gridArea: 'button' }}>
                {cleanFilter
                  ? <Button
                    startIcon={<CloseOutlinedIcon data-testid='filter_register_clean_filter_period' sx={{ marginRight: '0px !important' }} />}
                    className={`${classes.buttonDatePicker} cleanFilter`}
                    onClick={handleClearFilter}
                    data-testid='filter_register_clean_filter_period'
                  >
                    {t('Limpar')}
                  </Button>
                  : <Button
                    startIcon={<FilterListIcon data-testid='filter_register_filter_period' sx={{ marginRight: '0px !important' }} />}
                    className={`${classes.buttonDatePicker} ${(startDateIsValid && endDateIsValid && !showStartDateErrorMessage && !showEndDateErrorMessage) ? 'selected' : ''}`}
                    disabled={!startDateIsValid || !endDateIsValid || showStartDateErrorMessage || showEndDateErrorMessage}
                    onClick={handleSearchByDate}
                    data-testid='filter_register_filter_period'
                  >
                    {t('Filtrar')}
                  </Button>
                }
              </Grid>
            </Grid>
          </LocalizationProvider>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
