import OnlyProfile from 'components/common/OnlyProfile'
import { DisciplineCodeEnum, IContentUnitResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import StartPoint from './components/StartPoint'
import WhatWeWillDo from './components/WhatWeWillDo'
import { getUserSections } from '../../utils/getUserSections'
import { currentSubMenuTabAtom } from 'pages/ContentUnit/components/SideMenu/components'
import { SubTabsMenus } from '../..'
import { useAtom } from 'jotai'
import { Button } from 'components/design-system'
import { useStore } from 'store'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import useStyles from './style'
import { DownloadButton } from 'contentCacheManager'

interface IStartingPointProps {
  content: IContentUnitResponse
  isMyMaterialsEnabled: boolean
}

export const StartingPoint: React.FC<IStartingPointProps> = ({ content, isMyMaterialsEnabled = false }) => {
  const stylesProps = {
    imageUrl: content.cover
  }

  const classes = useStyles(stylesProps)

  const { profile } = useStore()
  const [, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)

  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const profileType = profile?.type

  const instructionalExpedition = content?.instructional_contents.find(cur => cur.type === 'expedition')

  // o que iremos fazer para estudante traz na ordem: Triade, Ponto de partida, Abertura (Caso EM)
  const whatWeWillDo = getUserSections({
    content,
    userType: IUserSchoolProfileTypeEnum.student
  })

  // ponto de partida do professor traz na ordem: Triade, Caderno de Atividade, Ponto de partida, Abertura do professor (Caso EM), Abertura conteúdo
  const startPoint = getUserSections({
    content,
    userType: IUserSchoolProfileTypeEnum.teacher
  })

  const handleGoToMyMaterialsTab = () => {
    setCurrentSubMenuTab(SubTabsMenus.MY_MATERIALS)
  }

  const GoToMyMaterialsButton = () => {
    const isStudent = profileType === IUserSchoolProfileTypeEnum.student
    if (isStudent && !isMyMaterialsEnabled) {
      return <></>
    }

    const buttonText = isStudent ? t('Ver materiais do professor') : t('Ir para meus materiais')

    return (
      <Button
        startIcon={<BookOutlinedIcon />}
        onClick={handleGoToMyMaterialsTab}
      >
        {buttonText}
      </Button>
    )
  }
  return (
    <>
      <div className={classes.contentUnitCover} />
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <DownloadButton
          expeditionId={content.id}
          context='INSIDE'
          dataTestid='student_menu_content_download'
          customClassName={classes.downloadButton}
        />

        <WhatWeWillDo
          content={content}
          sections={whatWeWillDo}
          language={content.language}
        />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        {instructionalExpedition
          ? <StartPoint
            key={0}
            language={content.language}
            sections={startPoint}
            isCloeExpand={isCloeExpand}
          />
          : <></>
        }
      </OnlyProfile>
      <GoToMyMaterialsButton />
    </>
  )
}
