import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Button } from 'components/design-system'
import SendIcon from '@mui/icons-material/Send'
import ReplayIcon from '@mui/icons-material/Replay'
import { useTranslation } from 'react-i18next'
import { Loading } from 'components/common'
import { ActivityControlStatusEnum } from 'services/types'
import { ActivityStateMachineEnum } from '../../hooks/atomsStore'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'
import DisabledButtonMessage from './components/DisabledButtonMessage'
import { useOnlineStatus } from 'contentCacheManager'

import Appcues from 'utils/appcues'
import StatusClassHandle, { InactiveClassInputDisabled } from 'components/common/StatusClassHandle'

type ButtonProps = {
  disabled?: boolean
}

const SendAnswerButton = () => {
  const { t } = useTranslation()
  const { isAnswered, disableSend, sendLoading, sendAnswerRecord, isUploading, editAnsweredQuestion, isExpeditionView, activity, isEditing, activityStateMachine, isActivitySuggestedType } = useStudentAnswer()
  const theme = useTheme()
  const isOnline = useOnlineStatus()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // const editButtonDesktopMessage = isExpeditionView ? 'Editar registros' : 'Editar resposta'

  const desktopFund1Message = isOnline ? t('Enviar') : t('Salvar')
  const mobileMessage = isOnline ? t('Enviar') : t('Salvar')
  const sendButtonMessage = isMobile || !isExpeditionView ? mobileMessage : desktopFund1Message

  const EditButton = (props: ButtonProps) => {
    return (
      <Button
        {...props}
        variant={isExpeditionView ? 'outlined' : 'primary'}
        onClick={editAnsweredQuestion} fullWidth={isExpeditionView}
        endIcon={isExpeditionView ? <ReplayIcon /> : null}
        data-testid={isActivitySuggestedType ? 'respond_activities_edit_answer' : 'respond_assessments_edit_answer'}
      >
        {t('Editar resposta')}
      </Button>
    )
  }

  const handleEventTracking = () => {
    const events = {
      activity: () => {
        Appcues.track('est_responder_atividade')
      },
      evaluation: () => {
        Appcues.track('est_responder_avaliacao')
      }
    }

    events[isActivitySuggestedType ? 'activity' : 'evaluation']()
  }

  const SendButton = (props: ButtonProps) => {
    let dataTestId = ''

    if (isEditing) {
      dataTestId = isActivitySuggestedType ? 'respond_activities_send_edit_answer' : 'respond_assessments_send_edit_answer'
    } else {
      dataTestId = isActivitySuggestedType ? 'respond_activities_send_register' : 'respond_assessments_send_register'
    }

    return (
      <Button
        variant={'primary'}
        disabled={disableSend || sendLoading || isUploading || props?.disabled}
        onClick={() => {
          sendAnswerRecord()
          handleEventTracking()
        }}
        fullWidth={isExpeditionView}
        endIcon={sendLoading ? <Loading type='circular' /> : <SendIcon />}
        data-testid={dataTestId}
      >
        {
          <>
            {t(sendLoading ? 'Enviando...' : sendButtonMessage)}
          </>
        }
      </Button>
    )
  }

  const EditOrSendAnswerButton = isAnswered ? EditButton : SendButton
  if (!activity?.activity_status?.status) return null
  return (
    <>
      {[ActivityControlStatusEnum.DONE, ActivityControlStatusEnum.PENDING].includes(activity?.activity_status?.status) &&
        <Grid xs={!isExpeditionView ? false : 12} item justifyContent='flex-end' alignItems='flex-end'>
          <StatusClassHandle>
            <InactiveClassInputDisabled>
              <EditOrSendAnswerButton />
            </InactiveClassInputDisabled>
          </StatusClassHandle>
        </Grid>
      }
      {disableSend && !isAnswered && isExpeditionView && activityStateMachine !== ActivityStateMachineEnum.ACTIVITY_UNAVAILABLE_FUND1 &&
        <DisabledButtonMessage />
      }
    </>
  )
}

export default SendAnswerButton
