import { createContext } from 'react'

interface ISectionContext {
  sectionId: string | null | undefined
  setSectionId: (value: string | null| undefined) => void
}

const SectionContext = createContext<ISectionContext>({
  sectionId: null,
  setSectionId: () => {
    // This is intentional
  }
})

export default SectionContext
