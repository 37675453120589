import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  containerAcordeon: {
    marginTop: theme.spacingStack.nano,
    width: '100%',
    '&.MuiAccordion-root': {
      boxShadow: 'none'
    }
  },
  accordeonSummary: {
    '&.acordeonSummary-content': {
      margin: 0,
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  titleAcordeon: {
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark30,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm
    }
  },
  checkboxContainer: {
    paddingBottom: theme.spacingInset.xs,
    display: 'flex',
    justifyContent: 'start',
    '& .MuiCheckbox-root .Mui-checked': {
      color: theme.colorBrand.medium
    },
    '& .MuiFormControlLabel-label': {
      color: [[theme.colorBrand.medium], '!important'],
      fontWeight: [[theme.font.fontWeight.semibold], '!important']
    },
    '& > button': {
      background: 'none',
      border: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end',
      paddingBottom: 0
    }
  },
  accordionDetails: {
    padding: '0px',
    '&.MuiAccordionDetails-root': {
      padding: '0 0 20px 0',
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  avatarCard: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden'
    },
    display: 'flex',
    height: '32px !important',
    cursor: 'pointer',
    marginBlock: theme.spacingStack.quarck,
    marginRight: theme.spacingStack.nano,
    padding: theme.spacingStack.quarck,
    border: '2px solid #E6E6E6',
    borderRadius: theme.spacingStack.quarck,
    alignItems: 'center',
    background: theme.colors.neutral.lightBase,
    '&.is-active': {
      background: theme.colorBrand.medium,
      color: theme.colors.neutral.lightBase,
      border: `2px solid ${theme.colorBrand.medium}`
    },
    '& .MuiAvatar-root': {
      width: theme.spacingStack.xxs,
      height: theme.spacingStack.xxs,
      fontSize: theme.font.fontSize.xxs
    }
  },
  avatarName: {
    fontSize: theme.font.fontSize.xs,
    paddingInline: theme.spacingStack.nano,
    fontWeight: theme.font.fontWeight.medium,
    margin: 'auto'
  }
}))
