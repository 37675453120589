import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  selector: {
    position: 'relative',
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: theme.colors.neutral.dark40
    },
    '& .MuiInputBase-input': {
      fontFamily: theme.font.fontFamily,
      color: theme.colors.neutral.darkBase,

      '&::placeholder': {
        color: 'transparent'
      }
    },
    '& .MuiFormHelperText-root': {
      color: theme.colors.neutral.dark40,
      fontFamily: theme.font.fontFamily,

      '&.Mui-error': {
        color: `${theme.colors.warning.feedbackDarkest} !important`
      }
    },
    '& legend': {
      top: '50px !important',
      right: '50px !important'
    },
    '& .MuiFilledInput-input': {
      borderRadius: theme.borderRadius.xs,
      fontWeight: theme.font.fontWeight.regular,
      fontFamily: theme.font.fontFamily,
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: theme.font.fontSize.sm,
      textAlign: 'start',
      color: theme.colors.neutral.darkBase,
      '&.Mui-focused': {
        '&.MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colors.neutral.light30}`
        }
      }
    },
    '& .MuiOutlinedInput-root': {
      maxWidth: 'fit-content',
      minWidth: 'fit-content',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid  ${theme.colors.neutral.light30}`
        }
      },
      '&.Mui-focused': {
        color: theme.colorBrand.medium,
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colors.neutral.light30} !important`
        }
      },
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.colors.warning.feedbackMedium} !important`
        }
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid  ${theme.colors.neutral.light30}`,
      borderRadius: theme.borderRadius.sm
    },
    ':focus': {
      border: `2px solid  ${theme.colors.neutral.light30}`,
      borderRadius: theme.borderRadius.sm
    },
    '& .MuiInputAdornment-root': {
      marginTop: '0px !important',
      justifyContent: 'end'
    },
    '& .MuiInputLabel-root': {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.regular,
      fontFamily: theme.font.fontFamily,
      color: theme.colors.neutral.darkBase,
      textAlign: 'start',
      '&.MuiInputLabel-shrink': {
        color: theme.colorBrand.medium,
        transform: 'translate(16px, -9px) scale(0.75)'
      },
      '&.Mui-focused': {
        color: theme.colorBrand.medium,
        '&.MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colors.neutral.light30}`
        }
      },
      '&.Mui-error': {
        color: `${theme.colors.warning.feedbackDarkest} !important`
      }
    },
    '&.MuiFormControl-root': {
      display: 'grid',
      width: '100%'
    },
    '& .MuiFilledInput-root': {
      background: 'transparent',
      color: theme.colorBrand.medium,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      minHeight: '56px',
      paddingLeft: '0px',
      justifyContent: 'center',
      border: `solid 2px ${theme.colors.neutral.light30}`,
      borderRadius: theme.borderRadius.sm,
      '&:after': {
        borderBottom: 'unset !important'
      },
      '&:before': {
        borderBottom: 'unset !important'
      },
      '&:hover': {
        background: 'transparent',
        color: theme.colorBrand.medium,
        justifyContent: 'center',
        border: `solid 2px ${theme.colors.neutral.light30}`,
        borderRadius: theme.borderRadius.sm
      },
      '&.Mui-focused': {
        background: 'transparent',
        color: theme.colorBrand.medium,
        justifyContent: 'center',
        border: `solid 2px ${theme.colors.neutral.light30}`,
        borderRadius: theme.borderRadius.sm
      },
      '& .MuiIconButton-root': {
        color: theme.colorBrand.medium
      },
      '& .MuiInputAdornment-root': {
        marginLeft: '0px'
      }
    },
    '& .MuiOutlinedInput-input': {
      border: 'none',
      color: theme.colors.neutral.darkBase,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.regular,
      background: 'transparent',
      width: '50px',
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.colors.warning.feedbackMedium} !important`
        }
      }
    }
  }
}))
