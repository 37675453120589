import GhostContentAPI from '@tryghost/content-api'

const url = 'https://ghost.cloeapp.com'
const key = process.env.REACT_APP_GHOST_KEY ?? ''

const ghostApi = new GhostContentAPI({
  url,
  key,
  version: 'v3'
})

export default ghostApi
