import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore, useCurricularStore } from 'store'
import { useAtom } from 'jotai'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { WatchLaterOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { LoadingWrapper, ErrorWrapper } from 'components/common'

import { Calendar, ScheduleCard, EmptyStateComponent, ScheduleOriginalContent, PendingActivitiesBox } from '../../../common'
import { BusinessError } from 'navigation/BusinessError'

import { IScheduleByDayResponse, IScheduleResponse } from 'services/types/schedule'
import { getSchedulesByDay } from 'services/schedule'
import { IDisciplineStore, ActivityControlStatusEnum } from 'services/types'
import { SubTabsMenusStudent } from 'pages/ContentUnit/atomStore'

import Appcues from 'utils/appcues'

import useStyle from './style'
import { OnlyNotPWA } from 'contentCacheManager/components/OnlyNotPWA'
import { currentSubMenuTabAtom } from 'pages/ContentUnit/components/SideMenu/components/atomStore'

export const Ready: React.FC = () => {
  // string + format
  dayjs.extend(customParseFormat)
  // states
  const [schedules, setSchedules] = useState<IScheduleByDayResponse[] | null>(null)
  const [currentDate, setCurrentDate] = useState<Date>()
  const [hasResponseError, setHasResponseError] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentSchedule, setCurrentSchedule] = useState<IScheduleResponse>()

  const [, setCurrentSubMenuTabStudent] = useAtom(currentSubMenuTabAtom)

  // store
  const { subscription } = useStore()
  const { currentDiscipline } = useCurricularStore()

  // TODO: pq dever ser 0??
  const classId = subscription?.class.id?.toString() ?? '0'
  // locale
  const { t } = useTranslation()
  // style
  const classes = useStyle()

  if (!subscription) {
    return <BusinessError error={t('Erro ao selecionar turma')} />
  }

  const getSchedulesData = async () => {
    setSchedules(null)
    setHasResponseError(false)
    try {
      const day = dayjs(currentDate?.toLocaleString('en-US'))
      const utcOffset = day.format('Z')
      const response = await getSchedulesByDay(classId, day.format('YYYY-MM-DD HH:mm:ssZ'), currentDiscipline?.id ?? '', utcOffset)

      if (response.success) {
        setSchedules(response?.data)
      } else {
        setSchedules([])
      }
    } catch (ex) {
      setHasResponseError(true)
    }
  }

  useEffect(() => {
    if (currentDate) {
      void getSchedulesData()
    }
  }, [currentDate, currentDiscipline])

  const handleSubMenuTabsContentUnit = (schedule: IScheduleResponse) => {
    const activitiesAvailable = schedule.activity?.activity_status?.status === ActivityControlStatusEnum.AVAILABLE
    const activitiesPending = !schedule.activity?.activity_status?.has_answer && [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.CLOSED, ActivityControlStatusEnum.RELEASED].includes(schedule.activity?.activity_status?.status ?? 0)
    const activitiesAnswered = schedule.activity?.activity_status?.has_answer && [ActivityControlStatusEnum.DONE, ActivityControlStatusEnum.CLOSED].includes(schedule.activity.activity_status?.status)
    const activitiesCorrected = schedule.activity?.activity_status?.has_answer && schedule.activity?.activity_status?.status === ActivityControlStatusEnum.RELEASED

    if (activitiesAvailable) setCurrentSubMenuTabStudent(SubTabsMenusStudent.ORIGINALS)
    if (activitiesPending) setCurrentSubMenuTabStudent(SubTabsMenusStudent.LIBERATED)
    if (activitiesAnswered) setCurrentSubMenuTabStudent(SubTabsMenusStudent.ANSWERED)
    if (activitiesCorrected) setCurrentSubMenuTabStudent(SubTabsMenusStudent.CORRECTED)
  }

  const errorMensage = (currentDiscipline?: IDisciplineStore | null) => {
    return t('Poxa, não existe conteúdo associado a esta data.\nAssim que sua professora definir um conteúdo, ele aparecerá aqui. Fique de olho!')
  }

  return (
    <Grid container>
      <ErrorWrapper hasError={hasResponseError} errorMessage={t('Houve um erro ao tentar buscar os dados, tente novamente')}>
        <OnlyNotPWA>
          <PendingActivitiesBox />
        </OnlyNotPWA>
        <Grid item xs={12}>
          <Calendar
            classId={classId}
            disciplineId={currentDiscipline?.id}
            onSelectDate={setCurrentDate}
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <LoadingWrapper loading={schedules === null}>
            {
              schedules?.length
                ? schedules.map((item, i) =>
                  <Grid key={i} item xs={12}>
                    <Grid item xs={12}>
                      <div className={classes.schedulesTime}>
                        <WatchLaterOutlined /> {dayjs(item.date).format('HH:mm')}
                      </div>
                    </Grid>
                    <Grid item xs={12} mt={3} mb={4} display='flex' flexWrap='wrap' gap={2}>
                      {
                        item.schedules.map(schedule =>
                          <ScheduleCard
                            key={schedule.id}
                            coverUrl={
                              schedule.content_unit?.cover_mobile?.formats?.small?.url ??
                              schedule.content_unit?.cover_mobile?.formats?.medium?.url ??
                              schedule.content_unit?.cover_mobile?.formats?.large?.url ??
                              schedule.content_unit?.cover_mobile?.formats?.thumbnail?.url ??
                              schedule.content_unit?.cover_mobile?.url ?? ''
                            }
                            onClick={() => {
                              if (schedule.type === 'original_content') {
                                setCurrentSchedule(schedule)
                                setIsOpen(true)
                              }
                              handleSubMenuTabsContentUnit(schedule)
                              Appcues.track('est_evento_agenda')
                            }}
                            {...schedule}
                          />
                        )
                      }
                    </Grid>
                  </Grid>)
                : <EmptyStateComponent errorMessage={errorMensage(currentDiscipline)} />
            }
            {
              currentSchedule &&
              <ScheduleOriginalContent
                {...currentSchedule}
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(!isOpen)
                  setCurrentSchedule(undefined)
                }}
              />
            }
          </LoadingWrapper>
        </Grid>
      </ErrorWrapper>
    </Grid>
  )
}

export default Ready
