import { IDisciplineResponse, IDisciplineStore } from 'services/types'

interface IFilterByCommonDisciplines {
  left: IDisciplineResponse[]
  right: IDisciplineStore[]
}

export const filterByCommonDisciplines = ({ left, right }: IFilterByCommonDisciplines) => {
  const filteredDisciplines: IDisciplineResponse[] = []

  for (const leftDiscipline of left) {
    for (const rightDiscipline of right) {
      if (rightDiscipline.id === leftDiscipline.id) {
        filteredDisciplines.push(leftDiscipline)
      }
    }
  }

  return filteredDisciplines
}
