import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  checkContainer: {
    display: 'flex',
    flexDirection: 'row !important' as 'row',
    border: `1px solid ${theme.colorBrand.medium}`,
    borderRadius: theme.borderRadius.pill,
    maxWidth: 'fit-content',
    alignItems: 'center'
  },
  circular: {
    aspectRatio: '1',
    border: `1px solid ${theme.colorBrand.medium}`,
    borderRadius: theme.borderRadius.circular,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circularCheck: {
    height: '24px',
    aspectRatio: '1',

    backgroundColor: 'transparent',
    padding: theme.spacingInset.quarck,
    margin: `0 ${theme.spacingInline.quarck}`,

    cursor: 'pointer',

    '&.checked': {
      borderColor: theme.colorBrand.medium,
      background: theme.colorBrand.medium
    }
  },
  labelContainer: {
    height: '31.5px',
    backgroundColor: 'transparent',
    margin: '-1px -1px -1px 0',

    cursor: 'pointer',

    '&.active': {
      background: 'rgba(191, 178, 211, 0.64)',
      color: theme.colorBrand.medium
    }
  }
}))
