import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  title: {
    color: theme.colors.neutral.dark10,
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.lg,
    lineHeight: theme.font.lineHeight.sm,
    letterSpacing: theme.font.spacing.distant,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.spacingStack.xxs,
      margin: `${theme.spacingStack.xxs} 0 ${theme.spacingStack.xxxs} 0`
    }
  },
  withActivitiesContainer: {
    marginBottom: '50px'
  }
}))
