import { makeStyles } from '@mui/styles'
import { Theme, styled } from '@mui/material'

import imgBackground from 'assets/base-screen-background.svg'

export const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}))

export default makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'left'
  },
  relatedIcons: {
    color: theme.colors.support.colorSupport01,
    marginRight: theme.spacingInset.xxs
  },
  submitButton: {
    width: '100%',
    '&.active': {
      background: `${theme.colors.support.colorSupport01}!important`,
      color: `${theme.colors.neutral.lightBase}!important`
    }
  },
  cancelButton: {
    width: '100%',
    fontSize: `${theme.font.fontSize.xs}!important`,
    color: theme.colors.support.colorSupport01,
    '&.ghost': {
      color: theme.colors.support.colorSupport01,
      '&:hover': {
        background: theme.colorBrand.lightest
      }
    },
    '& svg': {
      marginRight: '8px'
    }
  },
  distance: {
    paddingTop: '32px',
    paddingBottom: '24px'
  },
  links: {
    color: `${theme.colors.support.colorSupport01}!important`,
    textDecorationColor: `${theme.colors.support.colorSupport01}!important`,
    '-webkit-text-decoration-color': `${theme.colors.support.colorSupport01}!important`
  },
  root: {
    '&.card-terms': {
      '&.MuiPaper-root': {
        border: 'none!important'
      },
      maxHeight: '65px',
      overflowY: 'scroll',
      background: 'rgba(230, 230, 230, 0.32)',
      '&::-webkit-scrollbar': {
        width: '28px'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#C4C4C4',
        borderRadius: '40px',
        height: '50px',
        backgroundClip: 'content-box',
        border: 'solid 10px transparent'
      }
    }
  },
  expandedContainer: {
    width: '100%',
    height: '100%',
    minHeight: '900px',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
      '& button[id="scrollDown"]': {
        bottom: '60px',
        left: '100%',
        zIndex: 1
      },
      '& button[id="scrollTop"]': {
        bottom: '60px',
        left: '100%',
        zIndex: 1
      }
    }
  },
  wrapper: {
    position: 'relative',
    height: '100%',
    backgroundImage: `url(${imgBackground})`,
    backgroundSize: 'cover',
    overflow: 'auto'
  },
  expandedCard: {
    background: '#FFF',
    borderRadius: '16px 16px 0 0',
    margin: '36px',
    padding: '8px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '170px',
      margin: '0',
      borderRadius: '0'
    }
  },
  logo: {
    margin: '8px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      margin: '0',
      background: '#FFF',
      position: 'fixed',
      width: '100%',
      boxShadow: '0 16px 56px rgba(20, 20, 20, 0.16)',
      borderRadius: '0 0 16px 16px',
      zIndex: 2
    }
  },
  cardSummary: {
    position: 'sticky',
    float: 'right',
    backgroundColor: '#BFB2D3A3',
    borderRadius: '8px',
    width: '100%'
  },
  cardSummaryHeader: {
    backgroundColor: theme.colorBrand.medium,
    color: '#FFF',
    borderRadius: '8px 8px 0 0',
    paddingLeft: '16px',
    alignItems: 'center'
  },
  cardSummaryBody: {
    padding: '8px',
    '& p, li, a': {
      color: theme.colorBrand.dark
    }
  },
  cardBottomWrapper: {
    borderRadius: '16px 16px 0px 0px',
    position: 'fixed',
    width: '100%',
    zIndex: 2,
    bottom: '0'
  },
  cardBottomHeader: {
    height: '48px',
    width: '100%',
    position: 'sticky',
    borderRadius: '16px 16px 0px 0px',
    borderBottom: `1px solid ${theme.colorBrand.dark ?? '#000'}`,
    backgroundColor: theme.colorBrand.lightest,
    color: theme.colorBrand.dark,
    padding: '8px',
    '&.hide': {
      position: 'relative'
    },
    '& .left': {
      float: 'left'
    },
    '& .right': {
      float: 'right'
    }
  },
  cardBottomSummaryBody: {
    backgroundColor: theme.colorBrand.lightest,
    '&.hide': {
      transition: 'max-height 0.25s ease-out',
      maxHeight: '0',
      overflow: 'hidden'
    },
    '&.show': {
      maxHeight: '500px',
      transition: 'max-height 0.25s ease-in',
      overflow: 'scroll'
    },
    '& ol': {
      paddingInlineStart: '0'
    },
    '& p, li': {
      padding: '8px',
      color: theme.colorBrand.dark,
      borderBottom: `1px solid ${theme.colorBrand.dark ?? '#000'}`
    },
    '& p, li, a': {
      color: theme.colorBrand.dark
    }
  },
  gobackContainer: {
    width: '100%',
    padding: `${theme.spacingInset.lg} 0px ${theme.spacingInset.xs}`,
    position: 'fixed',
    top: '48px',
    background: theme.colors.neutral.lightBase,
    zIndex: 1
  },
  goBackBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.sm,
    '& svg': {
      marginRight: theme.spacingInline.nano
    }
  }
}))
