import { useTranslation } from 'react-i18next'

import { Grid, useTheme } from '@mui/material'
import { Tooltip } from '../Tooltip'

import { IContentUnitResponse, IBnccSkill } from 'services/types'

import useStyles from '../../style'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shoetest = require('shoetest')

export interface ISkillsProps {
  skills: IBnccSkill[]
  contentUnit: IContentUnitResponse
  isBncc?: boolean
  highlight?: string
}

export const Skills: React.FC<ISkillsProps> = ({ skills, contentUnit, isBncc = false, highlight }) => {
  const classes = useStyles(contentUnit)
  const { t } = useTranslation()
  const theme = useTheme()

  const validate = (skill: IBnccSkill) => {
    if (shoetest.test(highlight, skill.name)) {
      return 'highlight'
    }

    return ''
  }

  return (
    <Grid item xs={12} lg={3} className={classes.skillContainer}>
      <Grid
        item
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
      >
        <Grid item mb={2}>
          <div className={classes.subTitle}>{isBncc ? t('Habilidades BNCC') : t('Habilidades Camino')}</div>
        </Grid>
        <Grid item container gap={theme.spacingInline.nano} rowGap={theme.spacingInline.xxxs}>
          {skills?.map(item => (
            <Tooltip
              key={item.code}
              className={`${classes.tag} ${validate(item)}`}
              label={item.code}
              text={item.name}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Skills
