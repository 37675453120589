/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Text2 } from 'components/design-system'
import { Grid, Skeleton, useTheme } from '@mui/material'

import useStyle from './styles'

interface TeacherCardProps {
  teacherName: string
}

const TeacherCardItem: React.FC<TeacherCardProps> = ({
  teacherName
}) => {
  const { t } = useTranslation()
  const classes = useStyle()
  const theme = useTheme()

  return (
    <>
      <Grid container className={classes.teacherCardContainer}>
        <Avatar userName={teacherName} size='large' className={classes.customAvatar} />
        <Grid item className={classes.teacherCardNameKey}>
          <Text2
            fontSize='xxs'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xxs'
            customColor={theme.colorBrand.medium}
          >
            {t('Professor(a)')}
          </Text2>
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xxs'
            mobile='sm'
            customColor={theme.colors.neutral.dark40}
            className={classes.teacherName}
          >
            {teacherName ?? t('Sem Nome')}
          </Text2>
        </Grid>
      </Grid>
    </>
  )
}

const TeacherCardSkeleton: React.FC = () => {
  const classes = useStyle()
  return (
    <Grid container className={classes.teacherCardContainer}>
      <Skeleton variant='circular' width={70} height={70} animation='wave' />
      <Grid item className={classes.teacherCardNameKey} sx={{ gap: 0 }}>
        <Skeleton variant='text' width={90} height={30} animation='wave' sx={{ marginBottom: '-8px' }} />
        <Skeleton variant='text' width={160} height={50} animation='wave' />
      </Grid>
    </Grid>
  )
}

export const TeacherCard = {
  Item: TeacherCardItem,
  Skeleton: TeacherCardSkeleton
}
