import { createRef } from 'react'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import useStyles from './style'

export interface ICheckbox extends CheckboxProps {
  label?: string
  checked: boolean
  size: 'medium' | 'small'
  dataTestid?: string
  onClick?: () => void
}

export const CheckBox: React.FC<ICheckbox> = ({ size = 'small', dataTestid, ...props }) => {
  const classes = useStyles()
  const inputRef = createRef<HTMLDivElement>()

  const handleClick = () => {
    inputRef?.current?.click()
    props?.onClick?.()
  }

  return (
    <>
      <div ref={inputRef} data-testid={dataTestid}></div>
      <FormControlLabel
        className={`${classes.root}`}
        control={
          <Checkbox
            checked={props.checked}
            size={size}
            className={`${classes.root}`}
            onClick={handleClick}
          />}
        label={props.label ?? props.children}
      />
    </>
  )
}
