/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-base-to-string */
import { useState } from 'react'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import {
  helperTextStyles,
  formStyles,
  FilledInputStyles,
  InputLabelStyles,
  OutlinedInputStyles,
  inputAdornmentStyles
} from './style'
import OutlinedInput from '@mui/material/OutlinedInput'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { Grid, InputBaseProps } from '@mui/material'
import InputMask from 'react-input-mask'
import { useTranslation } from 'react-i18next'

interface ITextFieldState {
  password: string
  showPassword: boolean
}

export interface ITextField extends InputBaseProps {
  id: string
  variant: 'outlined' | 'filled'
  assistiveText?: string
  error?: boolean
  warning?: boolean
  success?: boolean
  mandatory?: boolean
  label: string
  password?: boolean
  mask?: string
  name?: string
  maxLength?: number
  numberOfLines?: number
  value?: string | unknown
  showLengthCounter?: boolean
}

function changeType(error?: boolean, warning?: boolean, success?: boolean, mandatory?: boolean): string {
  if (error) {
    return 'error'
  }
  if (warning) {
    return 'warning'
  }
  if (success) {
    return 'success'
  }
  if (mandatory) {
    return 'mandatory'
  }
  return 'default'
}

export const TextField = (props: ITextField) => {
  const { t } = useTranslation()

  const { label, password, variant, assistiveText, maxLength, mandatory, ...propsDown } = props
  const [values, setValues] = useState<ITextFieldState>({
    showPassword: false,
    password: ''
  })
  const handleChange = (prop: keyof ITextFieldState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
    if (props.onChange) {
      props.onChange(event)
    }
  }
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const type = changeType(props.error, props.warning, props.success, mandatory)
  const FilledInputClasses = FilledInputStyles(props)
  const InputLabelClasses = InputLabelStyles(props)
  const HelperTextClasses = helperTextStyles(props)
  const OutlinedInputClasses = OutlinedInputStyles(props)
  const InputAdornmentClasses = inputAdornmentStyles(props)
  const FormClasses = formStyles(props)
  if (variant === 'filled') {
    return (
      <>
        <FormControl className={`${FormClasses.root} ${type} ${props.className}`} variant={variant}>
          <InputLabel variant={variant} className={`${InputLabelClasses.root} ${type} ${props.className}`} htmlFor={props.id}>
            {label}
          </InputLabel>
          {password
            ? <FilledInput
              {...propsDown}
              className={`${FilledInputClasses.root} ${type} ${props.className}`}
              id={props.id}
              inputProps={{ maxLength: maxLength }}
              disableUnderline={true}
              onChange={handleChange('password')}
              type={values.showPassword ? 'text' : 'password'}
              name={props.name ?? ''}
              endAdornment={
                <InputAdornment position='end' className={`${InputAdornmentClasses.root} ${type}`}>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {values.showPassword
                      ? <VisibilityOffOutlinedIcon />
                      : <VisibilityOutlinedIcon />
                    }
                  </IconButton>
                </InputAdornment>
              }
            />
            : props.mask
              ? <InputMask
                mask={props.mask}
                value={props.value as string}
                onChange={props.onChange}
              >
                {() => <FilledInput
                  className={`${FilledInputClasses.root} ${type} ${props.className}`}
                  id={props.id}
                  name={props.name ?? ''}
                  disableUnderline={true}
                />
                }
              </InputMask>
              : <FilledInput
                {...propsDown}
                inputProps={{ maxLength: maxLength }}
                className={`${FilledInputClasses.root} ${type} ${props.className}`}
                id={props.id}
                maxRows={props.numberOfLines}
                disableUnderline={true}
              />
          }
          {assistiveText && (
            <FormHelperText variant={variant} className={`${HelperTextClasses.root} ${type} ${props.className}`} id={props.id}>
              {assistiveText}
            </FormHelperText>
          )}
        </FormControl>
      </>
    )
  }
  return (
    <>
      <FormControl className={`${FormClasses.root} ${type} ${props.className}`} variant='outlined'>
        <InputLabel className={`${InputLabelClasses.root} ${type} ${props.className}`} htmlFor={props.id}>
          {label}
        </InputLabel>
        {password
          ? <OutlinedInput
            {...propsDown}
            id={props.id}
            inputProps={{ maxLength: maxLength }}
            className={`${OutlinedInputClasses.root} ${type} ${props.className}`}
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            name={props.name ?? ''}
            endAdornment={
              <InputAdornment position='end' className={`${InputAdornmentClasses.root} ${type}`}>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {values.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
          />
          : props.mask
            ? <InputMask
              mask={props.mask}
              value={props.value as string}
              onChange={props.onChange}
            >
              {() => <OutlinedInput
                id={props.id}
                className={`${OutlinedInputClasses.root} ${type} ${props.className}`}
                name={props.name ?? ''}
                label={label}
              />
              }
            </InputMask>
            : <OutlinedInput
              {...propsDown}
              id={props.id}
              inputProps={{ maxLength: maxLength }}
              className={`${OutlinedInputClasses.root} ${type} ${props.className}`}
              label={label}
            />
        }
        <Grid className={HelperTextClasses.container}>
          {assistiveText && (
            <FormHelperText variant={variant} className={`${HelperTextClasses.root} ${type} ${props.className}`} id={props.id}>
              {assistiveText}
            </FormHelperText>
          )}
          {
            props.showLengthCounter && (
              <FormHelperText
                variant={variant}
                className={`${HelperTextClasses.root} maxCounter ${String(props.value)?.length === props?.inputProps?.maxLength && 'limit'}`}
                id={props.id}
              >
                {t('HelpCharacterCounter', { current_count: `${String(props.value)?.length}`, of: maxLength ?? 50 })}
              </FormHelperText>
            )
          }
        </Grid>
      </FormControl>
    </>
  )
}

export default TextField
