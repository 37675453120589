import { useMediaQuery, useTheme } from '@mui/material'
import { useAtom } from 'jotai'
import { isAfter } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/design-system'

import useStyles from '../style'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { SubTabsMenusActivitiy } from 'pages/ContentUnit/components/Activity/teacher/TeacherActivityNew'
import { currentSubMenuTabAtom } from 'pages/ContentUnit/components/SideMenu/components'

const ButtonShowResults: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // atom
  const [activity] = useAtom(activityCurrentAtom)
  const [, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)

  const isExistsNotRevised = activity?.questions?.some(question => !question.all_revised_or_corrected)

  if (activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate)) && !isExistsNotRevised) {
    return (
      <Button
        data-testid='correction_assessment_menu_answers_go_to_results'
        size='small'
        variant='primary'
        fullWidth={isMobile}
        className={classes.questionSelectorButtonsHandles}
        onClick={() => setCurrentSubMenuTab(SubTabsMenusActivitiy.RESULTS)}
      >
        {t('Ir para resultados')}
      </Button>
    )
  }

  return (
    <Button
      size='small'
      variant='outlined'
      fullWidth={isMobile}
      className={classes.questionSelectorButtonsHandles}
      disabled
    >
      {t('Ir para resultados')}
    </Button>
  )
}

export default ButtonShowResults
