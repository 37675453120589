import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import { ReactComponent as WhatsappIconImage } from 'assets/whatsapp-icon.svg'

import Grid from '@mui/material/Grid'
import * as SvgIcon from '@mui/icons-material'
import { Brand, Icon, Text } from 'components/design-system'
import { NavLink, useLocation } from 'react-router-dom'
import { useStore } from 'store'
import { activeChangeClassAtom, activeMenuSidebarAtom } from '../../../atomStore'
import { useAtom } from 'jotai'
import { ROOT, PROGRAM_CONTENT } from 'navigation/CONSTANTS'
import { ILocation } from 'services/types/routes'
import { useLmsPlatform } from '../../../hooks/useLmsPlatform/useLmsPlatform'
import Appcues from 'utils/appcues'
import { toast } from 'components/design-system/Toast/manager'
import { timeout } from 'utils/timeout'
import FeatureToggle, { FeatureFlags, Off, On } from 'components/feature-toggle'

enum ComponentStatesEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY'
}

export const SidebarTeacher: React.FC = () => {
  const [activeMenuClass, setActiveMenuClass] = useAtom(activeChangeClassAtom)
  const [activeMenuSidebar, setActiveMenuSidebar] = useAtom(activeMenuSidebarAtom)
  const { t } = useTranslation()
  const classes = useStyles()
  const { session, profile } = useStore()
  const location = useLocation<ILocation>()
  const { getLink } = useLmsPlatform(t, profile)
  const [state, setState] = useState(ComponentStatesEnum.IDLE)

  const isLoading = state === ComponentStatesEnum.LOADING

  const loadTips = async () => {
    if (isLoading) {
      return
    }
    setState(ComponentStatesEnum.LOADING)

    toast.handler({
      content: t('Carregando dicas'),
      severity: 'info',
      id: 'tips_menu_cloe_teacher_module'
    })
    Appcues.track('prof_dicas')

    await timeout(2)

    toast.destroy('tips_menu_cloe_teacher_module')

    await timeout(0.5)

    setState(ComponentStatesEnum.READY)
  }

  const UpperMenu = () => (
    <Grid className={`${classes.box} ${classes.upperBox} ${!activeMenuSidebar && classes.beforeMobile}`}>
      <div className={classes.top} />
      <Grid className={classes.inLineBrandMobile}>
        <Icon titleAccess='CloseIcon' onClick={() => setActiveMenuSidebar(!activeMenuSidebar)} size='large'>
          <CloseOutlinedIcon />
        </Icon>
        <Brand size='sm' type='logo' variant='white' />
      </Grid>
      <Grid item className={classes.classChange} onClick={() => (session?.profiles?.length === 1 || !session?.profiles?.length) ? null : [setActiveMenuClass(!activeMenuClass), setActiveMenuSidebar(!activeMenuSidebar)]} id='change_group'>
        <Grid className={classes.classInforamtionText}>
          {profile?.school?.name}
        </Grid>
        {session?.profiles?.length === 1 || !session?.profiles?.length
          ? null
          : <Grid item className={classes.iconChangeClass}>
            <SvgIcon.AutorenewRounded className={classes.iconSvg} />
            <Text type='body' color='medium' className={classes.classChangeTitle}>{t('Trocar')}</Text>
          </Grid>
        }
      </Grid>
      <NavLink data-testid='menu_home_my_classes' className={classes.navlink} to='/'>
        <Grid className={`navButton ${location.pathname === ROOT && 'is-active'}`}>
          <Icon titleAccess={t('Minhas turmas')} size='medium'>
            <SchoolOutlinedIcon />
          </Icon>
          <p>{t('Minhas turmas')}</p>
        </Grid>
      </NavLink>
      <NavLink className={classes.navlink} data-testid='menu_home_program_content' to={PROGRAM_CONTENT}>
        <Grid className={`navButton ${location.pathname === PROGRAM_CONTENT && 'is-active'}`}>
          <FeatureToggle feature={FeatureFlags.cloeProgram}>
            <On>
              <Icon titleAccess={t('Programa Cloe')} size='medium'>
                <RouteOutlinedIcon />
              </Icon>
            </On>
            <Off>
              <Icon titleAccess={t('Conteúdo programático')} size='medium'>
                <RouteOutlinedIcon />
              </Icon>
            </Off>
          </FeatureToggle>
          <FeatureToggle feature={FeatureFlags.cloeProgram}>
            <On>
              <p>{t('Programa Cloe')}</p>
            </On>
            <Off>
              <p>{t('Conteúdo programático')}</p>
            </Off>
          </FeatureToggle>
        </Grid>
      </NavLink>
      <NavLink className={classes.navlink} data-testid='menu_home_courses' to='' onClick={async () => await getLink()}>
        <Grid className={'navButton'}>
          <Icon titleAccess={t('Formação')} size='medium'>
            <LocalLibraryOutlinedIcon />
          </Icon>
          <p>{t('Formação')}</p>
        </Grid>
      </NavLink>
      <div className={classes.bottom} />
    </Grid>
  )

  const LowerMenu = () => (
    <Grid className={`${classes.box} ${classes.downBox} ${!activeMenuSidebar && classes.beforeMobile}`} style={{ cursor: isLoading ? 'wait' : 'pointer', pointerEvents: isLoading ? 'none' : 'initial' }}>
      <div className={`${classes.top} topBevel`} />
      <FeatureToggle feature={FeatureFlags.newLateralTipsButton}>
        <On>
          <a className={classes.navlink} data-testid='menu_help_center_tips' onClick={async () => await loadTips()}>
            <Grid className={'navButton'}>
              <Icon titleAccess={t('Dicas')} size='medium'>
                <TipsAndUpdatesOutlinedIcon />
              </Icon>
              <p>{t('Dicas')}</p>
            </Grid>
          </a>
        </On>
      </FeatureToggle>
      <a className={classes.navlink} data-testid='menu_help_center_faq' href={'https://centraldoconhecimento.cloeapp.com/perguntas-frequentes'} target='_blank'>
        <Grid className={'navButton'}>
          <Icon titleAccess={t('FAQ')} size='medium'>
            <HelpOutlineOutlinedIcon />
          </Icon>
          <p>{t('FAQ')}</p>
        </Grid>
      </a>
      <a className={classes.navlink} data-testid='menu_help_center_whatsapp' href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_SUPPORT_NUMBER ?? '5511986588112'}&text&type=phone_number&app_absent=0`} target='_blank'>
        <Grid className={'navButton'}>
          <WhatsappIconImage className={classes.svgCustomIcon} />
          <p>{t('Whatsapp')}</p>
        </Grid>
      </a>
    </Grid>
  )

  return (
    <Grid
      id='ui-sidebar_teacher'
      container
      onClick={() => setActiveMenuSidebar(!activeMenuSidebar)}
      className={`${classes.contentBox} ${!activeMenuSidebar && classes.beforeMobile}`}
      data-testid='main_menu'
    >
      <UpperMenu />
      <LowerMenu />
    </Grid>
  )
}
