import { IAlternativeResponse } from 'services/types/content-unit'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { useTheme } from '@mui/material'
import { parseKaTex } from 'utils/katex'
import { Resource } from 'components/common'
import { useRef } from 'react'

import useStyles from './style'

export interface IAlternativeProps {
  alternative: IAlternativeResponse
  isActive: boolean
  isWrong?: boolean
  isCorrect?: boolean
  disabled?: boolean
  isStudentView?: boolean
  dataTestId?: string
  isSolutionView?: boolean
}

export const Alternative: React.FC<IAlternativeProps> = ({ alternative, isActive, isWrong, isCorrect, disabled, isStudentView = false, dataTestId, isSolutionView = false }) => {
  const classes = useStyles({ alternative, isActive, isWrong, isCorrect, disabled, isStudentView, isSolutionView })
  const alternativeParsed = parseKaTex(alternative.text) ?? ''

  const theme = useTheme()
  const tagginRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={tagginRef} data-testid={dataTestId} hidden />
      <FormControlLabel
        onChange={() => tagginRef?.current?.click()}
        value={alternative.id}
        disabled={disabled}
        className={classes.question}
        sx={{
          marginLeft: 0,
          width: '100%',
          '&:checked': {
            backgroundColor: 'pink'
          }
        }}
        control={
          <Radio
            checked={!isStudentView && isActive}
            sx={{
              color: classes.colorButtonDefault,
              '&.Mui-checked': {
                color: theme.colorBrand.medium
              }
            }}
          />
        }
        label={
          <div className={classes.alternativeText}>
            <div dangerouslySetInnerHTML={{ __html: alternativeParsed }} />
            {
              alternative?.resources?.length
                ? alternative.resources.map(resource =>
                  <div className={classes.alternativeText} key={resource.id}>
                    <Resource resource={resource} />
                    <br />
                  </div>)
                : ''
            }
            <div className={classes.correctText}>
              {!isStudentView && isCorrect ? 'Resposta correta' : ''}
            </div>
          </div>
        }
      />
    </>
  )
}
