import { Grid } from '@mui/material'
import useStyle from './styles'

export const NotFoundSearchContentUnits: React.FC = () => {
  const classes = useStyle()

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Grid item>
        <p className={classes.text}>
          Hum.... Não encontramos o termo buscado nos conteúdos. Você pode
          tentar novamente. :D
        </p>
      </Grid>
      <Grid item>
        <div className={classes.image}></div>
      </Grid>
    </Grid>
  )
}

export default NotFoundSearchContentUnits
