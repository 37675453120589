import React from 'react'
import { ISectionResponse, IContentResponse, IUserSchoolProfileTypeEnum, DisciplineCodeEnum, IContentUnitResponse } from 'services/types'
import useStyles from '../../../../style'
import { useParams } from 'react-router-dom'
import { Content, Resource, Text } from 'components/common'
import { Grid } from '@mui/material'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import Curriculum from '../Curriculum'
import PrintButton from 'pages/ContentUnit/components/PrintButton'

interface IWhatWeWillDoProps {
  content: IContentUnitResponse
  sections: ISectionResponse[]
  language: string
}

export const WhatWeWillDo: React.FC<IWhatWeWillDoProps> = ({ content, sections, language }) => {
  const classes = useStyles()
  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const { contentId } = useParams<{ contentId: string }>()

  return (
    <div className={classes.readingContainer}>
      {
        sections
          .map((section, index) =>
            <div key={section.id} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {
                index === 0
                  ? <Grid container m={0} justifyContent='space-between'>
                    <Grid item xs={8} sm={6}>
                      <h1 className={classes.title}>
                        {t(section.title)}
                      </h1>
                    </Grid>
                    <Grid item xs={4} sm={6} textAlign='right'>
                      <PrintButton
                        dataTestId='starting_point_print_starting_point'
                        id='print_expedition_tab_what_we_will_do'
                        content={contentId}
                        mode={IUserSchoolProfileTypeEnum.student}
                        lng={language}
                      />
                    </Grid>
                  </Grid>
                  : <h1 className={classes.title}>
                    {t(section.title)}
                  </h1>
              }
              {
                section.text !== null && section.text &&
                <Text key={section.id} content={section.text ?? ''} />
              }
              {
                section?.resources &&
                section.resources.map((cur, i) =>
                  <React.Fragment key={i}>
                    <Resource key={cur.id} resource={cur} />
                    <br />
                  </React.Fragment>)
              }
              {
                section.contents.map((content: IContentResponse) => {
                  return <Content content={content} key={content.id} type={content.content_type} />
                })
              }
            </div>)
      }
      <Curriculum
        contentId={contentId}
        isCloeExpand={isCloeExpand}
      />
    </div>
  )
}

export default WhatWeWillDo
