import { ThemeProvider } from '@mui/material'
import { ThemeProvider as LegacyThemeProvider } from '@mui/styles'
import { theme } from '../src/styles/theme'
import { ProvideAuth } from 'navigation/ProvideAuth'
import { Routes } from 'navigation/routes'
import { BrowserRouter } from 'react-router-dom'
import { useStore } from 'store'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { ContentCacheProvider } from 'contentCacheManager'

const App: React.FC = () => {
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type ?? IUserSchoolProfileTypeEnum.student

  return (
    <LegacyThemeProvider theme={theme(profileType)}>
      <ThemeProvider theme={theme(profileType)}>
        <BrowserRouter getUserConfirmation={() => {
          // This is intentional
        }}>
          <ProvideAuth>
            <ContentCacheProvider>
              <Routes />
            </ContentCacheProvider>
          </ProvideAuth>
        </BrowserRouter>
      </ThemeProvider>
    </LegacyThemeProvider>
  )
}

export default App
