import { useTranslation } from 'react-i18next'

import { Loading } from 'components/design-system'

export const LoadingState = () => {
  const { t } = useTranslation()
  return (
    <div style={{ width: 'inherit', textAlign: 'center' }}>
      <Loading type={'circular'} />
      {t('Carregando...')}
    </div>
  )
}
