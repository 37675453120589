import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    schedulesTime: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacingInline.nano,
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.md,
      lineHeight: theme.font.lineHeight.sm,

      // mobile
      [theme.breakpoints.down('sm')]: {
        width: 'fit-content',
        background: theme.colorBrand.medium,
        color: theme.colors.neutral.lightBase,
        borderRadius: '0px 100px 100px 0px',
        marginLeft: '-16px',
        fontSize: theme.font.fontSize.xxs,
        padding: '12px',
        lineHeight: '1',
        '& > svg': {
          display: 'none'
        }
      }
    }
  })
)
