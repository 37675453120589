import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  captionContainer: {
    textAlign: 'center'
  },
  source: {
    textTransform: 'uppercase',
    marginLeft: theme.spacingInline.nano
  },
  caption: {
    '& span p': {
      display: 'inline'
    },
    color: theme.colors.neutral.dark40,
    width: '100%',
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '24px',
    letterSpacing: '0.14px',
    margin: `${theme.spacingInset.xxs} auto 0 auto`,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      fontSize: theme.font.fontSize.xxxs,
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: '16px'
    }
  }
}))
