
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bookmarkContainer: {
    display: 'flex',
    gap: theme.spacingInset.nano,
    flex: 1,
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap !important' as 'nowrap'
  },
  bookmarkTitle: {
    fontSize: theme.font.fontSize.xs,
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box'
  }
}))
