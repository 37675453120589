import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import {
  contentSelectAtom,
  openContentUnitModalAtom,
  contentUnitCardAtom
} from '../../../atomStore'
import { BusinessError } from 'navigation/BusinessError'
import { IGradeTypeEnum } from 'services/types/grade'

import { IContentArea, StateTypes } from './types'

import { ContentArea } from './Content'

export const ContentAreaContainer = ({ content }: IContentArea) => {
  const [state, setState] = useState<StateTypes>(StateTypes.DEFAULT)
  const [value, setValue] = useState(0)
  const [contentSelect] = useAtom(contentSelectAtom)
  const [contentCardUnit] = useAtom(contentUnitCardAtom)
  const [, setOpenContentUnitModal] = useAtom(openContentUnitModalAtom)

  const hasDidacticContent = (!!content?.didactic_content?.id) && content?.didactic_content?.sections.length

  const { t } = useTranslation()

  useEffect(() => {
    if (content?.grade?.code?.includes('EI_')) {
      setState(StateTypes.EI)
      return
    }

    if (content?.grade?.grade_type === IGradeTypeEnum.EF1 && !hasDidacticContent) {
      setState(StateTypes.EF1)
      return
    }

    if (content?.grade?.code?.includes('EM_') || content?.grade?.code?.includes('IT_')) {
      setState(StateTypes.EM_IT)
    }
  }, [])

  const updateContentSelected = () => {
    if ((contentSelect ?? []).length > 0) {
      setOpenContentUnitModal(false)
    }
  }

  if (!contentCardUnit) {
    return <BusinessError error={t('Falha ao carregar as seções do conteúdo')} />
  }

  const props = {
    state,
    value,
    content,
    title: contentCardUnit?.title,
    isBtnDisabled: (contentSelect ?? []).length === 0,
    setValue,
    updateContentSelected
  }

  return <ContentArea {...props} />
}
