import { Text2 } from 'components/design-system'
import { useAtom, atom } from 'jotai'
import { ITeacherSubscription, isActiveModalAtom, subscrisptionAtom } from '../../atomStore'
import useStyle from './style'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { ClassDisciplines } from '../ClassDisciplines'
import { getDisciplineByGrade, saveSubscriptionTeacher, getDisciplineMultigradeByClass } from 'services/taught-disciplines'
import { useEffect, useMemo } from 'react'
import { LoadingWrapper, ModalWrapper } from 'components/common'
import BackToTopWindow from 'components/common/BackToTopWindow'
import { selectedGradeAtom, updatedTaughtAtom } from 'pages/TaughtDisciplines'
import { IClassDisciplinesGradeResponse, IGradeResponse } from 'services/types/taught-disciplines'
import { toast } from 'components/design-system/Toast/manager'
import { BusinessError } from 'navigation/BusinessError'
import { useAuth } from 'navigation/ProvideAuth'
import { useStore, useCurricularStore } from 'store'

const stateTagAtom = atom<'add' | 'edit'>('add')
const classesAtom = atom<IClassDisciplinesGradeResponse[] | null>(null)
const sendLoadingAtom = atom<boolean>(false)
export const subscrisptionLocalAtom = atom<ITeacherSubscription[] | null>(null)

export const ModalClassesDisciplines: React.FC = () => {
  const { t } = useTranslation()

  const [stateTag, setStateTag] = useAtom(stateTagAtom)
  const [isActiveModal, setIsActiveModal] = useAtom(isActiveModalAtom)
  const [selectedGrade] = useAtom(selectedGradeAtom)
  const [gradeClasses, setGradeClasses] = useAtom(classesAtom)
  const [teacherSubscription, setTeacherSubscription] = useAtom(subscrisptionAtom)
  const [teacherLocalSubscription, setLocalTeacherSubscription] = useAtom(subscrisptionLocalAtom)
  const [sendLoading, setSendLoading] = useAtom(sendLoadingAtom)
  const [, setUpdateTaught] = useAtom(updatedTaughtAtom)
  const { profile } = useStore()
  const { reset: resetStoreDiscipline } = useCurricularStore()
  const auth = useAuth()

  const classes = useStyle({ loading: gradeClasses === null })
  const schoolPeriod = profile?.school?.school_periods.find(period => period?.current)

  const compareTeacherSubscription = (teacherSubscription: ITeacherSubscription[] | undefined | null, teacherLocalSubscription: ITeacherSubscription[] | undefined | null): boolean => {
    if (teacherSubscription && teacherLocalSubscription) {
      // soma os ids das matriculas para comparar
      const countCur = teacherSubscription.reduce((acc, cur) => acc + ~~cur.disciplines.reduce((a, c) => a + ~~c.id, 0), 0)
      const countLocal = teacherLocalSubscription.reduce((acc, cur) => acc + ~~cur.disciplines.reduce((a, c) => a + ~~c.id, 0), 0)
      return countLocal === countCur
    } else {
      return typeof teacherSubscription === typeof teacherLocalSubscription
    }
  }

  const fillTeacherSubscription = (classDisciplines: IClassDisciplinesGradeResponse[]) => {
    const classesTeacherSubscription = classDisciplines.reduce((acc: ITeacherSubscription[], cur: IClassDisciplinesGradeResponse) => {
      if (cur.disciplines !== undefined && cur.disciplines !== null) {
        const disciplines = cur.disciplines.filter(discipline => discipline.taught_discipline).map(discipline => ({
          id: discipline.id,
          name: discipline.name
        }))
        if (disciplines?.length) {
          acc.push({
            class_id: cur.class.id,
            disciplines
          })
        }
      }
      return acc
    }, [])

    if (classesTeacherSubscription.length) {
      setStateTag('edit')
    }

    setTeacherSubscription(classesTeacherSubscription)
    setLocalTeacherSubscription(classesTeacherSubscription)
  }

  const showGradeName = useMemo(() => {
    if (selectedGrade?.code === 'multigrade') return ''
    if (selectedGrade?.name) return selectedGrade?.name

    return ''
  }, [selectedGrade])

  const getDisciplinesByGrade = async (grade: IGradeResponse | null) => {
    if (grade !== null) {
      let response
      if (grade.code === 'multigrade') {
        response = await getDisciplineMultigradeByClass(grade.id, schoolPeriod?.id, true)
      } else {
        response = await getDisciplineByGrade(grade.id, schoolPeriod?.id, true)
      }
      if (response.success) {
        // necessarios quando sucesso
        setGradeClasses(response.data)
        void fillTeacherSubscription(response.data)
      } else {
        // deixo array vazio para tratar no front
        setGradeClasses([])
        setLocalTeacherSubscription(null)
        // TODO: colocar um toast????
      }
    } else {
      // se abrir o modal sem um grade na store deve mostrar um erro
      // deixo array vazio para tratar no front
      setGradeClasses([])
      setLocalTeacherSubscription(null)
    }
  }

  const onSave = async () => {
    setSendLoading(true)
    if (teacherLocalSubscription) {
      const request = await saveSubscriptionTeacher(teacherLocalSubscription)
      if (request) {
        // atualiza session
        await auth?.setSession(false)

        setIsActiveModal(!isActiveModal)
        setTeacherSubscription(teacherLocalSubscription)
        setUpdateTaught(true)
        resetStoreDiscipline()
      } else {
        toast.handler({
          content: t('Falhar salvar request'),
          duration: 10000,
          severity: 'error'
        })
      }
    } else {
      toast.handler({
        content: t('Falhar salvar local'),
        duration: 10000,
        severity: 'error'
      })
    }
    setSendLoading(false)
  }

  const closeModal = () => {
    setIsActiveModal(!isActiveModal)
  }

  if (isActiveModal && selectedGrade === null) {
    // não pode carregar o componente se não existir uma grade selecionada na store
    setGradeClasses([])
    setLocalTeacherSubscription(null)
    return <></>
  }

  useEffect(() => {
    setGradeClasses(null)
    setLocalTeacherSubscription(null)
    setStateTag('add')
    if (isActiveModal && selectedGrade) {
      void getDisciplinesByGrade(selectedGrade)
    }
  }, [isActiveModal])

  return (
    <>
      <ModalWrapper
        maxWidth='lg'
        open={isActiveModal}
        onConfirm={onSave}
        confirmDisabled={compareTeacherSubscription(teacherSubscription, teacherLocalSubscription) || teacherSubscription === null || sendLoading}
        confirmLabel='Salvar'
        confirmDatatestId={`${stateTag}_curriculum_component_save_selection`}
        confirmLoading={sendLoading}
        onCancel={closeModal}
        cancelDatatestId={`${stateTag}_curriculum_component_cancel_selection`}
        showCloseIcon={false}
        footer={{ border: true }}
      >
        <Box className={classes.boxModalContainer}>
          <LoadingWrapper type='circular' loading={gradeClasses === null}>
            {gradeClasses?.length
              ? <>
                <Grid container textAlign='start' rowSpacing={1}>
                  <Grid item xs={12}>
                    <Text2 fontSize='lg' fontWeight='semibold' lineHeight='xs' mobile='md' neutral='dark20'>
                      {showGradeName}
                    </Text2>
                  </Grid>
                  <Grid item xs={12}>
                    <Text2 fontSize='lg' fontWeight='medium' lineHeight='xs' mobile='md' neutral='dark20'>
                      {t('Selecione os componentes curriculares')}
                    </Text2>
                  </Grid>
                  <Grid item>
                    <Text2 fontSize='md' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark40'>
                      {t('Verifique as suas turmas e selecione corretamente os componentes curriculares que você leciona.')}
                    </Text2>
                  </Grid>
                </Grid>
                <div className={classes.itemContainer}>
                  {gradeClasses?.map((item, idx) => {
                    return (
                      <ClassDisciplines
                        gradeClass={item}
                        dataTestId={`${stateTag}_curriculum_component_select_component`}
                        key={idx} />
                    )
                  })}
                </div>
                <BackToTopWindow />
              </>
              : <BusinessError error={`${t('Ainda não foram liberadas turmas para o')} ${selectedGrade?.name ? selectedGrade.name : ''}`} />
            }
          </LoadingWrapper>
        </Box>
      </ModalWrapper>
    </>
  )
}
