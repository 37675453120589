import { IApiResponse, IChatGptPromptResponse } from 'services/types'
import { CHAT_AI_GPT_PROMPT } from './CONSTANTS'
import { request } from 'services/base'

export const generateChatAIPromptCompletion = async (prompt: string): Promise<IApiResponse<IChatGptPromptResponse[]>> =>
  await request({
    url: CHAT_AI_GPT_PROMPT(),
    method: 'POST',
    data: { prompt }
  })

export type IChatAIMessageContextType = 'parental_notice'
