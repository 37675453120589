import React from 'react'
import { Grid, useTheme, useMediaQuery } from '@mui/material'
import { useStore } from 'store'

import useStyles from '../../style'
import { StepErrorProps } from '../../types'

import { CustomImage } from '../CustomImage'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { truncateExtension } from 'utils/string'

export const StepError: React.FC<StepErrorProps> = ({ grade }: StepErrorProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { profile } = useStore()

  return (
    <Grid container>
      <span className={classes.txtTitleConfirmation}>Você não está {profile?.type === IUserSchoolProfileTypeEnum.student ? 'matriculado' : 'cadastrado' } em nenhuma turma do {truncateExtension(grade?.name, 20)}.</span>
      <Grid item display='flex' flexDirection='column' className={classes.contentStep} sx={{ paddingRight: 0, marginTop: isMobile ? theme.spacingInline.xxs : theme.spacingInline.sm }}>
        <CustomImage />
      </Grid>
    </Grid>
  )
}

export default StepError
