import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useStyles from '../../style'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Button } from 'components/design-system'
import { AccountCircleOutlined } from '@mui/icons-material'
import IdentifierField from './IdentifierField'

interface IRegistrationCompletedProps {
  onCancel: () => void
  username: string
  tempPassword: string
}

export const RegistrationCompleted: React.FC<IRegistrationCompletedProps> = ({ onCancel, username, tempPassword }) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={styles.purpleTitle}>
          <AdminPanelSettingsOutlinedIcon sx={{ marginRight: '8px' }} /> {t('Cadastro autônomo')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Alert
          title={t('Cadastro concluído!')}
          content={t('As informações foram enviadas por e-mail.')}
          severity='success'
          className={styles.alertSuccess}
          style={{ marginTop: '12px' }}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <div className={styles.purpleTitle}>
          <AccountCircleOutlined sx={{ marginRight: '8px' }} /> {t('Dados de login')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.description}>
          {t('Anote seus dados e guarde-os em um lugar seguro. Você poderá definir uma nova senha ao entrar na plataforma.')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.description} style={{ fontWeight: 600 }}>
          {t('Nome de usuário:')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <IdentifierField
          value={username}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={styles.description} style={{ fontWeight: 600 }}>
          {t('Senha provisória:')}
        </div>
      </Grid>
      <Grid item xs={12}>
        <IdentifierField
          value={tempPassword}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          className={styles.enterButton}
          variant='primary'
          type='submit'
          data-testid='autonomous_registration_completed_page_login'
        >
          {t('Entrar')}
          <RocketLaunchOutlinedIcon sx={{ marginLeft: '14px' }} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={styles.cancelButton}
          variant='ghost'
          onClick={onCancel}
          type='button'
          data-testid='autonomous_registration_completed_page_close'
        >
          <CloseIcon />
          {t('Fechar cadastro autônomo')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default RegistrationCompleted
