import { useState } from 'react'
import { Formik, Form, Field, FieldProps } from 'formik'
import * as Yup from 'yup'
import { Button, TextField, Text, Loading } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from '../../style'
import { regexEmail } from 'utils/regex'
import { IMessageErrors } from 'services/types'
import { useAuth } from 'navigation/ProvideAuth'
import { usernameRecovery } from '../../atomStore'
import { useAtom } from 'jotai'

interface PhoneOrEmailPropsComponent {
  nextStep: () => void
  onCancel: () => void
  recoverType: string
  setRecoverContentType: (recoverContentType: string) => void
  setEmailOrPhone: (emailOrPhone: string) => void
}

interface FormValues {
  phoneOrEmail: string
}

export const PhoneOrEmail: React.FC<PhoneOrEmailPropsComponent> = ({ nextStep, onCancel, recoverType, setRecoverContentType, setEmailOrPhone }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const [username, setUsernameRecovery] = useAtom(usernameRecovery)
  const [errorMessages, setErrorMessages] = useState<IMessageErrors[] | undefined>([])
  const initialValues = {
    phoneOrEmail: ''
  }

  const phoneOrEmailSchema = Yup.object().shape({
    phoneOrEmail: Yup.string()
      .required('Campo obrigatório')
  })

  const checkRecoverContentType = (phoneOrEmail: string) => {
    if (phoneOrEmail.match(regexEmail)) {
      return 'email'
    }
    const phone = phoneOrEmail.replace(/\D/g, '')
    if (phone.length >= 9 && phone.length <= 11) {
      return 'phone'
    }
    return ''
  }

  const handleRequestResponse = (email?: string, phone?: string, username?: string | null) => {
    setLoading(true)

    const unmaskedPhone = phone ? phone.replace(/\D/g, '') : undefined
    auth?.forgotPassword(email, unmaskedPhone, username).then(data => {
      if (data.success) {
        nextStep()
        setUsernameRecovery(null)
      } else {
        setErrorMessages(data.errors ? data.errors : [t('Erro na solicitação')])
      }
    }).catch(err => {
      setErrorMessages([t('Erro na solicitação')])
      console.log(err)
    })

    setLoading(false)
  }

  const handleSubmit = (values: any) => {
    const phoneOrEmail = values?.phoneOrEmail?.trim()

    setEmailOrPhone(phoneOrEmail)

    if (checkRecoverContentType(phoneOrEmail) === 'phone') {
      handleRequestResponse(undefined, phoneOrEmail, username)
    } else {
      handleRequestResponse(phoneOrEmail, undefined, username)
    }

    setRecoverContentType(checkRecoverContentType(phoneOrEmail))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={phoneOrEmailSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ dirty }) => (
        <Form className={styles.form}>
          <div className={styles.formTitle}>
            <Text type='body' size='large' className={styles.title}>
              {recoverType === 'login' ? t('Esqueceu o login?') : t('Esqueceu a senha?')}
            </Text>
          </div>
          <div>
            <Text type='body' size='small' className={styles.title}>
              {t('Informe seu e-mail cadastrado ou número de telefone que nós iremos te enviar as instruções para recuperação')}
            </Text>
          </div>
          <div className={styles.messageError}>
            {errorMessages?.map((e: IMessageErrors) =>
              <div key={e.id}>{t(e.message)}</div>
            )}
          </div>
          <div className={styles.formInput}>
            <Field name='phoneOrEmail'>
              {({
                field,
                form: { isSubmitting },
                meta
              }: FieldProps<FormValues>) => (
                <TextField
                  {...field}
                  id='phoneOrEmail'
                  variant='filled'
                  label={t('Número de telefone ou e-mail')}
                  className={styles.input}
                  error={Boolean(meta.error)}
                  assistiveText={meta.error}
                />
              )}
            </Field>
          </div>
          <Button className={styles.submitButton} variant='primary' disabled={!dirty} type='submit'>
            {!loading ? t('Continuar') : <Loading />}
          </Button>
          <Button className={styles.submitButton} variant='ghost' type='button' onClick={onCancel}>
            {t('Cancelar')}
          </Button>
          {/* <div className={styles.linkBlock}>
            <Link style={{ textDecoration: 'none', color: '#4B61AA' }} variant='primary'>{t('Recuperar acesso com ajuda da Cloe')}</Link>
          </div> */}
        </Form>
      )}
    </Formik>
  )
}

export default PhoneOrEmail
