import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  cardWrapper: {
    display: 'flex',
    textDecoration: 'none',
    borderTop: '1px solid rgba(194, 143, 193, 0.32)'
  },
  wrapperDefault: {
    background: theme.colorBrand.lightest,
    border: 'none',
    color: theme.colorBrand.medium,
    cursor: 'pointer',
    flexWrap: 'wrap',
    paddingBlock: theme.spacingInline.xxxs,
    transition: 'all ease-in-out 0.3s'
  },
  wrapperDefaultSelected: {
    background: '#CFA7CE',
    border: 'none',
    color: theme.colorBrand.darkest,
    cursor: 'pointer',
    flexWrap: 'wrap',
    paddingBlock: theme.spacingInline.xxxs,
    transition: 'all ease-in-out 0.3s',
    '& $position': {
      transition: 'all ease-in-out 0.2s',
      background: theme.colorBrand.medium,
      color: '#fff',
      border: '1px solid transparent'
    }
  },
  position: {
    background: 'transparent',
    border: `1px solid ${theme.colorBrand.darkest}`,
    borderRadius: '50%',
    color: theme.colorBrand.darkest,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    height: '32px',
    lineHeight: theme.font.lineHeight.sm,
    textAlign: 'center',
    width: '32px',
    '&:active': {
      background: theme.colorBrand.medium,
      border: '1px solid transparent',
      color: theme.colorBrand.lightest
    }
  },
  title: {
    color: theme.colorBrand.medium,
    flexWrap: 'wrap',
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs
  },
  icon: {
    '& svg': {
      marginTop: theme.spacingInline.quarck
    }
  }
}))
