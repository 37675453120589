import { atom } from 'jotai'
import { IDisciplineStore, IClassSubscriptionResponse } from 'services/types'

export const tabIndexAtom = atom<number>(0)
export const teacherContentErrorAtom = atom<boolean>(false)
export const teacherContentError = atom<boolean>(false)

export const inUploadingProcess = atom<boolean>(false)
export const reloadTimelineAtom = atom<boolean>(false)
export const currentDisciplineTimelineAtom = atom<IDisciplineStore | null>(null)
export const currentStudentsTimelineAtom = atom<IClassSubscriptionResponse[]>([])

export const isCloseHeaderAtom = atom<boolean>(false)
