import { Theme } from '@mui/material'

import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&.MuiButton-root': {
        padding: theme.spacingSquish.nano,
        height: theme.spacingStack.md,

        [theme.breakpoints.down('md')]: {
          height: '50px !important',
          padding: '12px !important',
          fontSize: '12px !important',
          minWidth: '50px !important',
          '& span': {
            margin: '0 !important'
          }
        }
      }
    }
  })
)
