import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  selector: {
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: `${theme.colors.neutral.dark40} !important`
    },
    '& .MuiInputBase-input': {
      fontFamily: theme.font.fontFamily,
      color: theme.colors.neutral.darkBase,
      cursor: 'pointer',
      caretColor: 'transparent',

      '&::placeholder': {
        color: 'transparent'
      }
    },
    '& .MuiFormHelperText-root': {
      color: theme.colors.neutral.dark40,
      fontFamily: theme.font.fontFamily,

      '&.Mui-error': {
        color: `${theme.colors.warning.feedbackDarkest} !important`
      }
    },
    '& .MuiInputLabel-root': {
      fontWeight: `${theme.font.fontWeight.regular} !important`,
      fontFamily: theme.font.fontFamily,
      color: theme.colors.neutral.darkBase,

      '&.MuiInputLabel-shrink': {
        color: theme.colorBrand.medium
      },
      '&.Mui-focused': {
        color: theme.colorBrand.medium
      },
      '&.Mui-error': {
        color: `${theme.colors.warning.feedbackDarkest} !important`
      }
    },
    '& .MuiFilledInput-root': {
      boxSizing: 'border-box',
      borderRadius: theme.borderRadius.sm,
      border: '2px solid transparent',
      '&.Mui-focused, &:hover': {
        border: `2px solid ${theme.colorBrand.medium}`
      },
      '&.Mui-disabled': {
        border: 'none',
        backgroundColor: 'rgba(230, 230, 230, 0.32);'
      },
      '&:before, &:after': {
        borderBottom: 'none !important'
      },
      '&.Mui-error': {
        borderRadius: theme.borderRadius.sm,
        border: `2px solid  ${theme.colors.warning.feedbackMedium} !important`,
        '&.Mui-focused': {
          border: `2px solid  ${theme.colors.warning.feedbackMedium} !important`
        }
      }
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.neutral.light30} !important`,
        borderRadius: theme.borderRadius.sm
      },
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.colors.warning.feedbackMedium} !important`
        }
      }
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      '& div:nth-of-type(1)': {
        marginRight: theme.spacingInset.xxs
      },
      '& div:nth-of-type(2)': {
        margin: [[0, theme.spacingInset.md, 0, theme.spacingInset.xxs]]
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))
