// pattern dd/mm/yyyy
export const regexDate = /^([0-2]\d|(3)[0-1])(\/)(((0)\d)|((1)[0-2]))(\/)\d{4}$/

export const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// pattern (99) 99999-9999
export const regexPhone = /^\((?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\) (?:[2-8]|9[1-9])\d{3}-\d{4}$/

// removes special characters from string ex: gonçalves => goncalves
export const normalizeString = (string: string = ''): string => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
