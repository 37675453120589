/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Grid, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/design-system'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import OnlyProfile from 'components/common/OnlyProfile'

import { CustomFooterProps } from '../../types'
import useStyles from '../../style'

export const CustomFooter = ({ isDisable, currentStep, maxStep, onSubmit, onCancel, isConfirmation, isError }: CustomFooterProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const validateJustifyContent = () => {
    if (isConfirmation && !isMobile) {
      return 'center !important'
    }

    if (isError) {
      return 'center !important'
    }

    if (isMobile) {
      return 'space-between !important'
    }

    return 'flex-end !important'
  }

  const validateButtonIdConfirm = () => {
    if (currentStep > maxStep) {
      return 'qr_code_access_activity'
    }

    if (currentStep === 1) {
      return 'qr_code_confirm_school_selection'
    }

    if (currentStep === 2) {
      return 'qr_code_confirm_class_selection'
    }

    return ''
  }

  const validateButtonIdCancel = () => {
    if (currentStep > maxStep) return 'qr_code_back_to_class_selection'

    if (currentStep === 1) return 'qr_code_cancel_school_selection'

    if (currentStep === 2) return 'qr_code_cancel_class_selection'

    return ''
  }

  const validateTextButton = () => {
    if (currentStep === 1) {
      return t('Cancelar')
    }

    return t('Voltar')
  }

  return (
    <Grid
      gap={3}
      className={classes.footer}
      sx={{
        justifyContent: validateJustifyContent(),
        ...((isConfirmation || isError) && { borderTop: '0 !important' })
      }}
    >
      {isError && (
        <>
          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
            <Button
              data-testid='qr_code_back_to_class'
              disabled={isDisable}
              sx={{
                padding: '8px 12px !important',
                gap: '8px'
              }}
              onClick={onSubmit}
            >
              {t('Voltar para a turma')}
            </Button>
          </OnlyProfile>

          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
            <Button
              data-testid='qr_code_back_to_schedule'
              disabled={isDisable}
              sx={{
                padding: '8px 12px !important',
                gap: '8px'
              }}
              onClick={onSubmit}
            >
              {t('Voltar para a agenda')}
            </Button>
          </OnlyProfile>
        </>
      )}

      {!isError && (
        <>
          {maxStep > 0 && (
            <Button
              data-testid={validateButtonIdCancel()}
              variant='ghost'
              sx={{
                padding: '8px 12px !important',
                gap: '8px'
              }}
              onClick={onCancel}
            >
              {validateTextButton()}
            </Button>
          )}

          <Button
            data-testid={validateButtonIdConfirm()}
            disabled={isDisable}
            sx={{
              padding: '8px 12px !important',
              gap: '8px'
            }}
            onClick={onSubmit}
          >
            {currentStep <= maxStep && t('Avançar')}
            {currentStep > maxStep && t('Vamos lá!')}
          </Button>
        </>
      )}
    </Grid>
  )
}
