import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import emptyStateScreenStudents from 'assets/empty-state-screen-students.svg'

export default makeStyles((theme: Theme) => createStyles({
  postContainer: {
    width: '100%',
    // paddingBlock: theme.spacingInline.md,
    height: '100%',
    overflow: 'hidden',
    background: theme.colors.neutral.lightBase,
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,
    marginBottom: theme.spacingInline.xxxs,
    boxShadow: theme.shadowLevels.level5,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      boxShadow: 'none',
      paddingTop: '100px',
      '&.fullScreen': {
        height: 'auto'
      }
    }
  },
  title: {
    margin: '0',
    fontSize: '32px',
    lineHeight: '40px',
    textAlign: 'center',
    color: 'white'
  },
  header: {
    padding: '32px 24px',
    background: theme.colorBrand.medium,
    '-webkit-print-color-adjust': 'exact'
  },
  contentScroll: {
    height: '100%',
    overflowX: 'auto',
    padding: theme.spacingInline.xxs,
    ...theme.scroll
  },
  postContent: {
    margin: '0 auto',
    maxWidth: '720px',
    '& img': {
      maxWidth: '100%'
    },
    '& blockquote': {
      padding: '0px 0px 0px 24px',
      borderLeft: '4px solid rgb(192, 192, 192)',
      fontStyle: 'italic'
    },
    padding: theme.spacingInset.md
  },
  // title: {
  //   fontSize: theme.font.fontSize.sm,
  //   fontWeight: theme.font.fontWeight.semibold,
  //   [theme.breakpoints.down('sm')]: {
  //     fontSize: theme.font.fontSize.xxs
  //   },
  //   '& > p': {
  //     margin: 0
  //   }
  // },
  image: {
    backgroundImage: `url(${emptyStateScreenStudents})`,
    marginBlock: theme.spacingInline.md,
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat'
  },
  button: {
    marginBlock: `${theme.spacingInline.xxxs} !important`
  },
  boxButton: {
    display: 'inline-grid'
  },
  image404: {
    backgroundImage: 'url(\'/images/cat-offline.png\')',
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat'
  },
  titleEmptyDisciplines: {
    fontSize: theme.font.fontSize.xxxxl,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    fontFamily: theme.font.fontFamily
  },
  subtitleEmptyDisciplines: {
    display: 'block'
  },
  containerEmptyDisciplines: {
    textAlign: 'center',
    paddingBottom: theme.spacingInset.lg
  }
}))
