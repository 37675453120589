import ErrorBoundary from 'components/common/ErrorBoundary'
import OnlyProfile from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import StudentActivities from './student'
import TeacherActivities from './teacher'
import BackToTopWindow from 'components/common/BackToTopWindow'
import { useTheme, useMediaQuery } from '@mui/material'

export const Activities: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ErrorBoundary>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <StudentActivities />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <TeacherActivities />
      </OnlyProfile>
      {isMobile && <BackToTopWindow />}
    </ErrorBoundary>
  )
}

export default Activities
