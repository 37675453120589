import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    width: '100%',
    background: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.sm
  }
}))
