import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IMyCloe {
  openMyCloe: boolean
}

export default makeStyles((theme: Theme) => createStyles({
  content: {
    transition: 'all ease-in-out 0.2s',
    zIndex: 1000,
    backgroundColor: theme.colors.neutral.lightBase,
    height: '100vh',
    width: ({ openMyCloe }: IMyCloe) => openMyCloe ? '334px' : '1px',
    right: '0',
    top: '77px',
    marginRight: '0',
    position: 'fixed'
  },
  top: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    position: 'absolute',
    height: '30px',
    opacity: ({ openMyCloe }: IMyCloe) => openMyCloe ? '100%' : '0',
    width: '30px',
    overflow: 'hidden',
    transition: 'all ease-in-out 0.2s',
    top: '-28px',
    right: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      height: '200%',
      width: '200%',
      borderRadius: '100%',
      boxShadow: `10px 10px 5px 100px ${theme.colorBrand.medium}`,
      zIndex: 0,
      top: '-100%'
    }
  },
  bottom: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    position: 'relative',
    height: '12px',
    width: '30px',
    overflow: 'hidden',
    transition: 'all ease-in-out 0.2s',
    right: 14,
    '&::before': {
      content: '""',
      position: 'absolute',
      right: 14,
      height: '185%',
      width: '105%',
      borderRadius: '100%',
      boxShadow: `10px 10px 5px 100px ${theme.colorBrand.medium}`,
      zIndex: 0
    }
  },
  box: {
    position: 'fixed',
    zIndex: 10,
    left: 0,
    height: 'auto',
    width: 67,
    backgroundColor: theme.colorBrand.medium,
    borderRadius: '0 32px 32px 0',
    textAlign: 'center',
    color: theme.colors.neutral.lightBase,
    transition: 'all ease-in-out 0.2s'
  },
  wrapper: {
    width: ({ openMyCloe }: IMyCloe) => openMyCloe ? 'auto' : '0px',
    height: '0px',
    display: 'flex',
    flexWrap: 'nowrap'
  },
  buttonWrapper: {
    position: 'relative',
    background: theme.colorBrand.medium,
    right: '-2px'
  },
  childrenWrapper: {
    background: 'rgba(191, 178, 211, 0.16)',
    boxShadow: '0px 16px 56px rgba(20, 20, 20, 0.16)',
    opacity: ({ openMyCloe }: IMyCloe) => openMyCloe ? '100%' : '0',
    overflowY: 'scroll',
    height: 'calc(100% - 160px)'
  },
  header: {
    transition: 'all ease-in-out 0.2s',
    background: theme.colorBrand.medium,
    width: 'auto',
    height: 'max-content',
    opacity: ({ openMyCloe }: IMyCloe) => openMyCloe ? '100%' : '0',
    display: 'flex',
    flexWrap: 'nowrap',
    fontFamily: theme.font.fontFamily,
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.md,
    overflow: 'hidden',
    padding: theme.spacingInline.xxxs,
    color: theme.colors.neutral.lightBase,
    zIndex: 999
  },
  button: {
    position: 'relative',
    right: '20px',
    borderRadius: theme.borderRadius.sm,
    background: theme.colorBrand.medium,
    color: theme.colors.neutral.lightBase,
    border: 'unset',
    cursor: 'pointer',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottomRightRadius: '0',
    // mobile
    [theme.breakpoints.down('sm')]: {
      borderLeft: '1px solid white'
    }
  }
}))
