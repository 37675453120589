import { useTranslation } from 'react-i18next'
import { Grid, useTheme } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { Text, Icon } from 'components/design-system'

import useStyles from './style'

export interface IClassesCountProps {
  count?: number
}

export const ClassesCount: React.FC<IClassesCountProps> = ({
  count
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  if (count === undefined) {
    return <></>
  }

  return (
    <Grid container className={classes.countContainer}>
      <Icon
        titleAccess={t('ìcone de número de aulas')}
        size='medium'
        iconColor={theme.colors.neutral.dark30}
      >
        <DescriptionOutlinedIcon />
      </Icon>

      <Text
        className={classes.countText}
      >
        {t('ClassesCountText', { count: count })}
      </Text>
    </Grid>
  )
}
