import { ReactNode, cloneElement, Children, isValidElement } from 'react'

interface IRenderChildrenWithProps {
  children: ReactNode
  props?: {
    [propName: string]: any
  }
}

export function renderChildrenWithProps<T extends IRenderChildrenWithProps>({ children, props }: T) {
  return Children.map(children, (child: ReactNode) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...props })
    }
  })
}
