import { IApiResponse } from 'services/types'
import { IVinculateResponse, IRegistrationTokenUpdate, IRegistrationTokenResponse } from 'services/types/registration-token'
import { request } from 'services/base'
import {
  REGISTRATION_TOKEN_GET_DATA,
  REGISTRATION_TOKEN_UPDATE,
  GET_PRE_REGISTER_TOKEN_FROM_USER
} from './CONSTANTS'

export const postVinculateToken = async (token: string): Promise<IApiResponse<IVinculateResponse>> =>
  await request({ url: `${process.env.REACT_APP_BACKEND_URL as string}/users/vinculate-registration-token/${token}`, method: 'post', data: null })

export const getRegistrationTokenData = async (token: string): Promise<IApiResponse<IRegistrationTokenResponse[]>> =>
  await request({ url: REGISTRATION_TOKEN_GET_DATA(token), isLogin: true })

export const updateRegistrationToken = async (classId: number, data: IRegistrationTokenUpdate): Promise<IApiResponse<IRegistrationTokenResponse>> =>
  await request({ url: REGISTRATION_TOKEN_UPDATE(classId), method: 'put', data: data, isLogin: true })

export const getPreRegisterTokenFromUser = async (id: string): Promise<IApiResponse<IRegistrationTokenResponse[]>> =>
  await request({ url: GET_PRE_REGISTER_TOKEN_FROM_USER(id) })
