import { Grid } from '@mui/material'
import useStyle from './styles'

export const SearchTermNotFound = ({ term = '' }: { term?: string }) => {
  const classes = useStyle()

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>
        <div className={classes.image}></div>
      </Grid>
      <Grid item className={classes.title}>
        Parece que <strong>{term}</strong> não foi encontrado.
      </Grid>
      <Grid item className={classes.subTitle}>
        Você pode tentar outros termos ou verificar se houve algum erro de digitação.
      </Grid>
    </Grid>
  )
}

export default SearchTermNotFound
