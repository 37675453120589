import Grid from '@mui/material/Grid'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import { Tag } from 'components/design-system'
import { useTheme } from '@mui/material'
import makeStyles from '../../style'
import {
  DisciplineCodeEnum,
  IActivityResponse,
  ActivityTypeDescription,
  IContentUnitResponse
} from 'services/types'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'

export const ActivityTypeTag: React.FC<{ activity: IActivityResponse | null, content?: IContentUnitResponse }> = ({ activity, content }) => {
  const isCloeExpand = content?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const classes = makeStyles()
  const theme = useTheme()

  function setIcon() {
    let type
    if (activity) {
      const title = activity.title ?? activity.activity_name
      type = title.toLowerCase().includes('diagn') ? 'diagnostic' : activity?.suggested_application_type
    }
    switch (type) {
      case 'diagnostic':
        return <BookmarkBorderIcon />
      case 'evaluation':
        return <BallotOutlinedIcon />
      default:
        return <ExtensionOutlinedIcon />
    }
  }
  if (!activity) return null
  return (
    <Grid item>
      <Tag
        className={classes.tag}
        color={activity?.suggested_application_type ? theme.colors.neutral.lightBase : undefined}
        background={activity?.suggested_application_type ? theme.colors.contentType[activity?.suggested_application_type] : undefined}
      >
        {setIcon()}
        {t(ActivityTypeDescription[activity?.type])}
      </Tag>
    </Grid>
  )
}
