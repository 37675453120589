import { useHistory } from 'react-router-dom'
import { Button, Text } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from '../../style'
import { ROOT } from 'navigation/CONSTANTS'

interface SuccessfullySentPropsComponent {
  email?: string
  phone?: string
  recoverContentType: string
  recoverType: string
}

export const SuccessfullySent: React.FC<SuccessfullySentPropsComponent> = ({ email, phone, recoverContentType, recoverType }) => {
// routes hooks
  const history = useHistory()

  // tranlation
  const { t } = useTranslation()

  // classes name styles
  const styles = useStyles()

  return (
    <div className={styles.form}>
      <div className={styles.formTitle}>
        <Text type='body' size='large' className={styles.title}>
          {recoverType === 'login' ? t('Esqueceu o login?') : t('Esqueceu a senha?')}
        </Text>
      </div>
      <div className={styles.description}>
        <Text type='body' size='small'>
          {recoverContentType === 'email'
            ? (
              <>
                {`${t('As informações para recuperar a sua conta foram enviadas para o e-mail')} `}
                <span className={styles.highlight}>{email ?? ''}</span>.
                <div>
                  {` ${t('Por favor, confira em sua caixa de entrada e caixa de spam.')}`}
                </div>
              </>
            )
            : (
              <>
                {`${t('As informações do seu login foram enviadas para o número')} `}
                <span className={styles.highlight}>{phone ?? ''}</span>.
                <div>
                  {` ${t('Por favor, confira sua mensagens.')}`}
                </div>
              </>
            )
          }
        </Text>
      </div>
      <Button className={styles.submitButton} variant='primary' onClick={() => history.replace(ROOT)} type='button'>
        {t('Entendi')}
      </Button>
    </div>
  )
}

export default SuccessfullySent
