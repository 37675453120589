import { IUserMeResponse } from 'services/types'
import Storage from 'utils/storage'

declare global {
  interface Window {
    Appcues?: {
      identify: (userId: number, obj: {firstName: string | undefined, email: string | undefined, role: string | undefined }) => void
      page: () => void
      anonymous: () => void
      show: (id: string) => void
      track: (eventName: string) => void
    }
  }
}

class Appcues {
  public identify(user?: IUserMeResponse | null) {
    if (window.Appcues) {
      window.Appcues.page()
      if (user?.id) {
        const userId = user.id
        const obj = {
          firstName: user?.username,
          email: user?.email,
          role: Storage.props.getProfile()?.type
        }
        window.Appcues.identify(userId, obj)
      }
    }
  }

  public track(eventName: string) {
    if (window.Appcues) {
      window.Appcues.track(eventName)
    }
  }
}

const appcues = new Appcues()

export default appcues
