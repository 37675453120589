import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ICoverProps } from './'

export default makeStyles((theme: Theme) => createStyles({
  backgroundImage: {
    backgroundImage:
    ({ imageUrl }: ICoverProps) =>
      imageUrl?.formats?.large
        ? `url(${imageUrl?.formats?.large?.url ?? ''})`
        : `url(${imageUrl?.formats?.medium?.url as string})`,
    backgroundSize: 'cover',
    width: '100%',
    maxHeight: 'fit-content',
    borderRadius: '16px 16px 0px 0px',

    // mobile
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      maxHeight: '149px',
      backgroundImage:
      ({ imageUrl }: ICoverProps) =>
        imageUrl?.formats?.small?.url ??
          `url(${imageUrl?.formats?.medium?.url as string})`
    }
  },
  boxCover: {
    backgroundColor: 'rgba(20, 20, 20, 0.7)',
    justifyContent: 'space-between',
    padding: theme.spacingInset.sm,
    paddingBottom: theme.spacingInline.md,
    borderRadius: '16px 16px 0px 0px',

    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      borderRadius: '0px'
    }
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  navationButton: {
    '&.MuiButton-root': {
      borderColor: 'white',
      color: 'white',
      padding: '8px 16px',
      fontSize: '12px',
      '& svg': {
        marginRight: '15px'
      },
      '&:hover': {
        background: 'none'
      }
    }
  },
  disciplineContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px'
    },

    '& label': {
      marginRight: theme.spacingInline.nano
    }
  },
  exitbuttonWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  expeditionContainer: {
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,
    lineHeight: theme.spacingInline.sm,
    marginTop: theme.spacingStack.nano,

    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.sm,
      lineHeight: '100%',
      marginTop: theme.spacingStack.xxxs
    }
  },
  titleContainer: {
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.xl,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.spacingInline.sm,

    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.md,
      lineHeight: theme.spacingInline.xs,
      marginTop: '8px'
    }
  }
}))
