import React, { createRef, useState } from 'react'
import { Grid, useTheme, ToggleButton, useMediaQuery } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { useTranslation } from 'react-i18next'
import { IAlternativeResponse, IQuestionResponse } from 'services/types'
import { Resource } from 'components/common'
import { Icon, Text } from 'components/design-system'
import { parseKaTex } from 'utils/katex'

import { useStyle } from './style'
import { Alternative } from '../InputTypeComponent/components/MultiChoice/Alternative'

interface ISolutionProps {
  questions: IQuestionResponse[]
  questionIndex: number
  dataTestidShow?: string
  dataTestidHide?: string
}

export const Solution: React.FC<ISolutionProps> = ({ questions, questionIndex, dataTestidShow, dataTestidHide }) => {
  // state
  const [isDropDown, setIsDropDown] = useState<boolean>(false)

  // const
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyle()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const inputRef = createRef<HTMLDivElement>()

  const guides = (question: IQuestionResponse) => {
    const getGuides = question.alternatives.filter(alternative => alternative.type === 'guide')
    if (!getGuides?.length) return []
    return getGuides.map(alternative => ({
      level: alternative.level,
      text: alternative.text
    }))
  }

  const multiChoice = (question: IQuestionResponse) => {
    const getMultiChoice = question.alternatives.find(alternative => alternative.level === 100)
    return getMultiChoice as IAlternativeResponse
  }

  const render = () => {
    if (questions[questionIndex].input_type === 'multi_choice') {
      if (!multiChoice(questions[questionIndex])) {
        return <></>
      }
      return <Alternative alternative={multiChoice(questions[questionIndex])} isActive disabled isSolutionView />
    }

    const alternativeTextOnly = () => (
      guides(questions[questionIndex])?.length && (questions[questionIndex]?.alternatives[0]?.resources ?? []).length === 0
        ? (
          <Grid item xs={12} lg={12} ml={0} mt={2} className={classes.solutionContent}>
            {guides(questions[questionIndex]).map((guide, row) => (
              <div className={classes.contentLevel} key={row}>
                {typeof guide.level !== 'number' ? '' : <ToggleButton className={classes.levelButton} disabled value={guide.level}>{guide.level}</ToggleButton>}
                <div className={classes.contentLevelText}>
                  <span dangerouslySetInnerHTML={{ __html: parseKaTex(guide.text) ?? '' }} />
                </div>
              </div>
            ))}
          </Grid>
        )
        : ''
    )

    const alternativeWithImageAndText = () => (
      (questions[questionIndex]?.alternatives[0]?.resources ?? []).length > 0
        ? (
          <Grid item xs={12} lg={12} ml={0} mt={2} pt={isMobile ? 0 : 3} pb={isMobile ? 0 : 3} className={classes.solutionContent}>
            {(questions[questionIndex]?.alternatives[0]?.resources ?? []).map((resource, i) => (
              <Resource resource={resource} key={i} />
            ))}

            <Grid item mt={2}>
              {guides(questions[questionIndex]).map((guide, row) => (
                <div className={classes.contentLevel} key={row}>
                  {typeof guide.level !== 'number' ? '' : <ToggleButton className={classes.levelButton} disabled value={guide.level}>{guide.level}</ToggleButton>}
                  <div className={classes.contentLevelText}>
                    <span dangerouslySetInnerHTML={{ __html: parseKaTex(guide.text) ?? '' }} />
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        )
        : ''
    )

    return (
      <>
        {alternativeTextOnly()}
        {alternativeWithImageAndText()}
      </>
    )
  }

  if ((guides(questions[questionIndex]) || []).length === 0 && questions[questionIndex].input_type !== 'multi_choice') {
    return <></>
  }

  return (
    <>
      <div ref={inputRef} hidden data-testid={isDropDown ? dataTestidHide : dataTestidShow} />
      <Grid
        item
        display='flex'
        gap={2.4}
        marginTop={theme.spacingStack.xxxs}
        className={classes.solutionContainer}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          inputRef?.current?.click()
          setIsDropDown(!isDropDown)
        }}
      >
        <Grid
          item
          display='flex'
          gap='16px'
          alignItems='center'
        >
          <ToggleButton className={classes.levelButton} disabled value={0}>S</ToggleButton>
          <Text style={{ color: theme.colors.information.feedbackDarkest, fontWeight: theme.font.fontWeight.medium }} type='body' size='medium' >
            {t('Solução')}
          </Text>
        </Grid>

        <Grid
          item
          display='flex'
          alignItems='center'
        >
          {isDropDown
            ? <Icon titleAccess={t('Recolher Todos')} size='medium'>
              <KeyboardArrowUpOutlinedIcon />
            </Icon>
            : <Icon titleAccess={t('')} size='medium'>
              <KeyboardArrowDownOutlinedIcon />
            </Icon>
          }
        </Grid>
      </Grid>

      {isDropDown && (
        <>
          {render()}
        </>
      )}
    </>
  )
}

export default Solution
