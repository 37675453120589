import { useRef } from 'react'
import { useAtom } from 'jotai'

import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined'
import { Icon, Avatar } from 'components/design-system'

import { previewsAtom } from '../atomStore'
import { truncateExtension } from 'utils/string'
import { useOnlineStatus } from 'contentCacheManager'
import { getFileExtension } from 'utils/files'

import { deleteFile } from 'services/upload'
import { deleteFileRecord, getFileRecordByUploadId } from 'services/answer'

import { Wrapper, Title, Close, VolumeUpContainer } from './style'

interface IPreviewCardProps {
  title: string
  thumb: string
  ext?: string
  id: number | null
  transactionId?: string | null
  maxFileName?: number
  handleDeleteOffline?: (fileName: string) => void
  fileRecordId?: number
}

const PreviewCard = ({ title, thumb, ext, id, maxFileName, handleDeleteOffline, fileRecordId, transactionId }: IPreviewCardProps) => {
  const [previews, setPreviews] = useAtom(previewsAtom)
  const isOnline = useOnlineStatus()
  const cardRef = useRef<HTMLDivElement>(null)

  const deleteImage = async () => {
    if (!isOnline) {
      handleDeleteOffline?.(title)
      window.URL.revokeObjectURL(thumb)
      cardRef?.current?.remove()
      return
    }

    try {
      if (fileRecordId) {
        await deleteFileRecord(fileRecordId)
      } else if (!fileRecordId && id) {
        const response = await getFileRecordByUploadId(id)
        if (response.success) {
          await deleteFileRecord(Number(response.data[0].id))
        }
      }
      if (id) await deleteFile(id)
    } catch (ex) {
      console.error(ex)
    }
    setPreviews(previews.filter(file => file?.id?.toString() !== id?.toString() || file?.transaction_id !== transactionId))
  }

  const handleThumb = () => {
    const fileExtension = getFileExtension(thumb)

    switch (fileExtension) {
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ogg':
        return (
          <VolumeUpContainer>
            <Icon size='large' iconColor='#fff'>
              <VolumeUpOutlinedIcon />
            </Icon>
          </VolumeUpContainer>
        )
      default:
        return <Avatar userName={ext as string} sx={{ width: 24, height: 24 }} src={thumb} />
    }
  }

  return (
    <Wrapper ref={cardRef}>
      {handleThumb()}
      <Title>{maxFileName ? truncateExtension(title, maxFileName) : title}</Title>
      <Close>
        <IconButton sx={{ padding: '4px' }} onClick={deleteImage}>
          <Icon size='small' iconColor='#fff'>
            <CloseIcon />
          </Icon>
        </IconButton>
      </Close>
    </Wrapper>
  )
}

export default PreviewCard
