import React from 'react'
import { SubscriptionSelected } from './RecipientsModal/types'

export const isStudentSelected = (selectedStudents: SubscriptionSelected[], id: number): boolean => {
  if ((selectedStudents ?? []).length === 0) return false

  return (selectedStudents ?? []).some(subscription => subscription.id === id)
}

export const removeStudent = (setSelectedStudents: React.Dispatch<SubscriptionSelected[]>, selectedStudents: SubscriptionSelected[], id: number): void => {
  setSelectedStudents((selectedStudents ?? []).filter(subscription => subscription.id !== id))
}
