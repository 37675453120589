import { Fragment, ReactNode, cloneElement, Children, isValidElement } from 'react'
import { useStore } from 'store'
import { renderChildrenWithProps } from 'utils/wrappers'

type IStatusClass = 'active' | 'inactive'

export const StatusClassHandle: React.FC<any> = ({ children, ...props }) => {
  let status: IStatusClass | null | undefined = null

  const { schoolPeriod } = useStore()

  if (schoolPeriod?.current != null && typeof schoolPeriod?.current !== 'undefined') {
    status = schoolPeriod.current ? 'active' : 'inactive'
  }

  const renderChildren = Children.map(children, (child: ReactNode) => {
    if (isValidElement(child)) {
      return cloneElement(child, { status, props })
    }
  })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

interface IStatusComponent {
  children: ReactNode
  status?: IStatusClass
  props?: {
    [propName: string]: any
  }
}

export const CurrentClass: React.FC<IStatusComponent> = ({ children, status, props }) => {
  if (status !== 'active') {
    return <></>
  }

  const renderChildren = renderChildrenWithProps<IStatusComponent>({ children, props })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

export const InactiveClass: React.FC<IStatusComponent> = ({ children, status, props }) => {
  if (status !== 'inactive') {
    return <></>
  }

  const renderChildren = renderChildrenWithProps<IStatusComponent>({ children, props })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

export const InactiveClassInputDisabled: React.FC<IStatusComponent> = ({ children, status, props }) => {
  const newProps = {
    ...props,
    disabled: status === 'inactive'
  }

  const renderChildren = renderChildrenWithProps<IStatusComponent>({ children, props: newProps })

  return (
    <Fragment>
      {renderChildren}
    </Fragment>
  )
}

export default StatusClassHandle
