import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'fixed',
    paddingInline: theme.spacingInline.xxs,
    marginInline: '25%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '17%',
      marginInline: 0
    }
  },
  boxContainer: {
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: '8px',
    marginTop: '15%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingSquish.nano
    }
  },
  content: {
    display: 'grid',
    justifyContent: 'center',
    padding: theme.spacingInline.xxs
  },
  contentText: {
    textAlign: 'center',
    marginBottom: theme.spacingStack.nano,
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium
  },
  boxButtons: {
    marginInline: '30%',
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      marginInline: 0,
      display: 'grid'
    },
    '& button': {
      fontSize: theme.font.fontSize.xs,
      width: '200px',
      margin: 'auto',
      display: 'block'
    }
  }
}))
