import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export interface IStyleProps {
  hasNavigationTabs: boolean
}

export default makeStyles((theme: Theme) => createStyles({
  selectorWrapper: (props: IStyleProps) => ({
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    width: '100%',
    maxHeight: '100%',
    gap: theme.spacingStack.nano,

    // alignItems: 'center',
    flex: 1,
    flexWrap: 'nowrap !important' as 'nowrap',

    ...(props.hasNavigationTabs
      ? {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'auto'
      }
      : {

      }
    )
  }),
  paginationButton: {
    alignSelf: 'center',
    display: 'flex',
    width: '32px',
    height: '32px !important',

    justifyContent: 'center',
    alignItems: 'center',

    boxSizing: 'border-box',

    border: 'none',
    background: 'none',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.circular,
    padding: '0 !important',
    color: theme.colorBrand.medium,

    '& svg': {
      height: '32px !important',
      color: theme.colorBrand.dark

    },

    '&:hover': {
      background: theme.colorBrand.lightest
    }

  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    gap: theme.spacingStack.nano
  }
}))
