import { IApiResponse } from 'services/types'
import { IMilestonesResponse, IMilestonesRecordRequest } from 'services/types/milestones'
import { CREATE_MILESTONE, MILESTONES_BY_ID } from './CONSTANTS'
import { request } from 'services/base'

export const saveMilestone = async (data: IMilestonesRecordRequest): Promise<IApiResponse<IMilestonesResponse>> =>
  await request({ url: CREATE_MILESTONE(), method: 'post', data: data })

export const deleteMilestone = async (id: string): Promise<IApiResponse<IMilestonesResponse>> =>
  await request({ url: MILESTONES_BY_ID(id), method: 'delete' })

export const updateMilestone = async (id: string, data: IMilestonesRecordRequest): Promise<IApiResponse<IMilestonesResponse>> =>
  await request({ url: MILESTONES_BY_ID(id), method: 'put', data: data })

export const getMilestoneById = async (id: string): Promise<IApiResponse<IMilestonesResponse>> =>
  await request({ url: MILESTONES_BY_ID(id), method: 'get' })
