import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const styleDefault: (theme: Theme) => CSSProperties = theme => ({
  '&.MuiCard-root': {
    borderRadius: theme.borderRadius.sm,
    '&.clickable': {
      cursor: 'pointer',
      '&.selected': {
        background: theme.colorBrand.medium,
        color: theme.colors.neutral.lightBase
      },
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(20, 20, 20, 0.16)'
      },
      '&:focus-within': {
        borderColor: theme.colors.support.colorSupport02,
        borderWidth: '3px',
        borderStyle: 'solid'
      }
    }
  }
})

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.vertical': {
      ...styleDefault(theme)
    },
    '&.horizontal': {
      ...styleDefault(theme)
    },
    '&.horizontalMax': {
      ...styleDefault(theme)
    }
  }
}))
