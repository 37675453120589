import Grid from '@mui/material/Grid'
import {
  IUserSchoolProfileTypeEnum,
  IContentUnitResponse
} from 'services/types'
import { BackToAll } from './components/BackToAll'
import { ActivityTitle } from './components/ActivityTitle'
import { AverageEvaluation } from './components/AverageEvaluation'
import { OfflineActivityWarning } from '../../OfflineActivityWarning'
import useStudentAnswer from '../../../hooks/useStudentAnswer'
import { useStore } from 'store'

interface IActivityHeaderProps {
  content: IContentUnitResponse
}

export const DefaultHeader: React.FC<IActivityHeaderProps> = ({ content }) => {
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  const { activity, isActivitySuggestedType } = useStudentAnswer()
  if (!activity) return null

  return (
    <>
      <Grid container item xs={12} marginBottom={2.5}>
        <Grid item xs>
          <BackToAll
            activity={activity}
            contentId={`${content.id}`}
            isActivitySuggestedType={isActivitySuggestedType}
            dataTestid={isActivitySuggestedType ? 'respond_activities_back_activities' : 'respond_assessments_back_assessments'}
          />
        </Grid>
        <AverageEvaluation activity={activity} profile={IUserSchoolProfileTypeEnum.student} />
      </Grid>
      <OfflineActivityWarning />
      <Grid item xs={12}>
        <ActivityTitle title={activity?.title ?? activity?.activity_name} customType={activity.custom_type} profileType={profileType} />
      </Grid>
    </>
  )
}

export default DefaultHeader
