// constants and types
import EnableFeatureToggle from 'navigation/components/EnableFeatureToggle'
import {
  LIBRARY_LEGACY,
  GO_TO_ACTIVITY,
  GO_TO_RESOURCE,
  ENABLE_FEATURE_TOGGLE,
  CONTENT_UNIT_RESOURCES
} from 'navigation/CONSTANTS'

import {
  Library,
  GoToActivity,
  GoToResource,
  Resources
} from 'pages'

// local imports
import { IRoutesGroupProps } from '..'

export const commonPrivateRoutes: IRoutesGroupProps = {
  routes: [
    {
      path: LIBRARY_LEGACY(),
      component: Library,
      isPrivate: true
    },
    {
      path: GO_TO_ACTIVITY,
      component: GoToActivity,
      isPrivate: true
    },
    {
      path: GO_TO_RESOURCE,
      component: GoToResource,
      isPrivate: true
    },
    {
      path: ENABLE_FEATURE_TOGGLE(),
      component: EnableFeatureToggle,
      isPrivate: true
    },
    {
      path: CONTENT_UNIT_RESOURCES(),
      component: Resources
    }
  ]
}
