import { Typography, TypographyProps } from '@mui/material'
import makeStyles from './style'

export interface IFontProps extends TypographyProps {
  className?: string
  //          10      12      14     16     20     24     32      40      56        64
  fontSize: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'display'
  fontWeight: 'extralight' | 'light' | 'regular' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black'
  lineHeight: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
  neutral?: 'darkBase' | 'dark10' | 'dark20' | 'dark30' | 'dark40' | 'lightBase' | 'light10' | 'light20' | 'light30' | 'light40'
  positive?: 'feedbackDarkest' | 'feedbackDark' | 'feedbackMedium' | 'feedbackLight' | 'feedbackLightest'
  negative?: 'feedbackDarkest' | 'feedbackDark' | 'feedbackMedium' | 'feedbackLight' | 'feedbackLightest'
  warning?: 'feedbackDarkest' | 'feedbackDark' | 'feedbackMedium' | 'feedbackLight' | 'feedbackLightest'
  information?: 'feedbackDarkest' | 'feedbackDark' | 'feedbackMedium' | 'feedbackLight' | 'feedbackLightest'
  support?: 'colorSupport01' | 'colorSupport02' | 'colorSupport03' | 'colorSupport04'
  colorbrand?: 'darkest' | 'dark' | 'medium' | 'light' | 'lightest'
  customColor?: string
  mobile: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'display'
  mobilelineheight?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
  paddingLeft?: 'quarck' | 'nano' | 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'huge' | 'giant'
  onClick?: any
  iconscolor?: boolean
  noWrap?: boolean
  cursorpointer?: boolean
}

export const Text2: React.FC<IFontProps> = ({ children, customColor, iconscolor, cursorpointer, ...props }) => {
  const classes = makeStyles({ ...props, customColor, iconscolor, cursorpointer })
  return (
    <Typography component='span' {...props} className={`${classes.root} ${props.className ?? ''}`}>
      {children}
    </Typography>
  )
}
