import { Grid } from '@mui/material'
import { IContentUnitResponse } from 'services/types'
import ContentCardLong from '../ContentCardLong'
import { useEffect } from 'react'

import Mark from 'mark.js'

interface IContentUnitsProps {
  contentUnits: IContentUnitResponse[]
  navigation: {
    gradeCode: string
    gradeTypeCode: string
    classId: number
  }
  highlight?: string
}

export const ContentUnits: React.FC<IContentUnitsProps> = ({
  contentUnits,
  navigation,
  highlight
}) => {
  const setMark = () => {
    const domSelectedToHighlight = document?.querySelectorAll('#Dashboard-Teacher-Contents-TeacherNew-ListContentUnit-ContentUnits, [role="tooltip"]') ?? ''
    const markInstance = new Mark(domSelectedToHighlight)
    markInstance.unmark({
      done: () => {
        const text = highlight ?? ''
        markInstance.mark(text)
      }
    })
  }

  window.addEventListener('DOMNodeInserted', (event: any) => {
    if (event.target?.innerHTML?.indexOf('tooltip') > -1) {
      setMark()
    }
  })

  useEffect(() => {
    setMark()
  }, [highlight])

  return (
    <Grid
      item
      xs={12}
      lg={12}
      container
      mt={1}
      id={'Dashboard-Teacher-Contents-TeacherNew-ListContentUnit-ContentUnits'}
    >
      {contentUnits.map((content: IContentUnitResponse) => (
        <ContentCardLong
          index={content.suggested_position}
          contentUnit={content}
          dataTestid='home_content_select_expedition'
          navigation={navigation}
          highlight={highlight}
        />
      ))}
    </Grid>
  )
}

export default ContentUnits
