import { useTheme } from '@mui/material'

export const useChart = () => {
  const theme = useTheme()

  const getThemeByPerformance = (performance: number) => {
    if (performance === 0 || performance < 6) {
      return theme.colors.negative
    } else if (performance >= 6 && performance < 9) {
      return theme.colors.warning
    } else if (performance >= 9) {
      return theme.colors.positive
    }
    return theme.colors.information
  }

  const getThemeBySituation = (situation: number) => {
    switch (situation) {
      case 2:
        return theme.colors.warning
      case 3:
        return theme.colors.positive
      case -1:
      case 1:
      default:
        return theme.colors.negative
    }
  }

  return {
    getThemeByPerformance,
    getThemeBySituation
  }
}
