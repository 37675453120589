import { EmptyStateComponent } from 'components/common'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { Button } from 'components/design-system'
import { Grid, useTheme } from '@mui/material'
import { StatusClassHandle, CurrentClass } from 'components/common/'

interface IEmptyProps {
  addNewMaterial: () => void
}

export const Empty: React.FC<IEmptyProps> = ({ addNewMaterial }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Grid container paddingBottom={theme.spacingStack.xxs}>
      <EmptyStateComponent errorMessage={'Você ainda não possui materiais relacionados a esta unidade.\nQue tal compartilhá-los com a turma para apoiar o processo de aprendizado?'}>
        <StatusClassHandle>
          <CurrentClass>
            <Grid container justifyContent={'center'} marginTop={3}>
              <Button
                variant='primary'
                startIcon={<AddIcon />}
                onClick={addNewMaterial}
                data-testid={'teacher_materials_add'}
                sx={{ padding: `${theme.spacingSquish.nano} !important` }}
              >
                {t('Adicionar materiais')}
              </Button>
            </Grid>
          </CurrentClass>
        </StatusClassHandle>
      </EmptyStateComponent>
    </Grid>
  )
}
