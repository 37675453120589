/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState } from 'react'
import { IGradeTypeEnum } from 'services/types/grade'
import { contentSelectAtom, scheduleAtom, openContentUnitModalAtom, changingScheduleAtom, contentUnitCardAtom } from '../atomStore'
import { ContentEnum } from './types'
import { ContentSelect } from './ContentSelect'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'

export const ContentSelectContainer = () => {
  // states
  const [status, setStatus] = useState<ContentEnum>(ContentEnum.empty)
  const [description, setDescription] = useState<string | null>(null)
  const [isShowItems, setIsShowItems] = useState(false)

  // atoms
  const eventObject = useAtomValue(scheduleAtom)
  const contentSelect = useAtomValue(contentSelectAtom)
  const changingSchedule = useAtomValue(changingScheduleAtom)
  const contentUnitCard = useAtomValue(contentUnitCardAtom)
  const setOpenContentUnitModal = useUpdateAtom(openContentUnitModalAtom)

  const hasDidacticContent = (!!contentUnitCard?.didactic_content?.id) && contentUnitCard?.didactic_content?.sections.length

  const handleOpenModal = () => {
    setOpenContentUnitModal(true)
  }

  const setInitialState = () => {
    setStatus(ContentEnum.empty)
    setDescription(null)
  }

  // clear after send
  useEffect(() => {
    if (changingSchedule) {
      setInitialState()
    }
  }, [changingSchedule])

  useEffect(() => {
    if ((contentSelect ?? []).length === 0 || eventObject?.activity || eventObject?.didatic_section) {
      setIsShowItems(true)
    }

    if ((contentSelect ?? []).length > 1) {
      setIsShowItems(false)
    }

    if (contentUnitCard?.grade?.grade_type === IGradeTypeEnum.EF1 && !hasDidacticContent) {
      setDescription('Selecionar atividade ou avaliação')
      setStatus(ContentEnum.empty)
      return
    }

    setInitialState()
  }, [contentSelect])

  const props = {
    eventObject,
    contentSelect,
    status,
    description,
    handleOpenModal,
    isShowItems,
    setIsShowItems
  }

  return <ContentSelect {...props} />
}
