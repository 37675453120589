import React, { useState } from 'react'
import useStyles from './style'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ActivityControlStatusEnum, ISectionResponse } from 'services/types'
import { useTranslation } from 'react-i18next'
import { Global } from '@emotion/react'
import { styled } from '@mui/material/styles'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined'
import { grey } from '@mui/material/colors'
import Card from './components/Card'
import ExpandButton from './components/ExpandButton'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useAtom } from 'jotai'
import { allActivities } from 'pages/ContentUnit/atomStore'
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material'

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800]
}))

interface IIndexMobileProps {
  sections?: ISectionResponse[]
}

export const IndexMobile: React.FC<IIndexMobileProps> = ({ sections }) => {
  const [open, setOpen] = useState(false)
  const [activities] = useAtom(allActivities)
  const { t } = useTranslation()
  const isIOS = iOS()
  const classes = useStyles(isIOS)
  const drawerBleeding = isIOS ? 58 : 48

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const container = window.document.body

  return (
    <div className={classes.wrapper}>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            maxHeight: `calc(70% - ${drawerBleeding}px)`,
            overflow: 'visible'
          }
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor='bottom'
        onClick={() => setOpen(prev => !prev)}
        open={open}
        disableBackdropTransition={!isIOS}
        disableDiscovery={isIOS}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: 990
          }}
        >
          <div className={classes.titleArea}>
            <div className={classes.titleWrapper}>
              <SortOutlinedIcon />
              {t('Índice')}
            </div>
            <ExpandButton open={open} />
          </div>
        </StyledBox>
        <ScrollContainer className={classes.scroll} hideScrollbars={false}>
          {
            sections
              ? sections.map((section, index) =>
                <Card
                  key={section.id}
                  idSection={section.id}
                  section={section}
                  position={index + 1}
                  title={section.title}
                  onClick={() => setOpen(prev => !prev)}
                  subtitles={section.contents.map((content) => content.title as string)}
                  dataTestid='content_sections_select_item'
                />)
              : activities?.map(activity =>
                <Card
                  key={activity.id}
                  iconType={activity.suggested_application_type}
                  idActivity={activity.id}
                  title={activity.title ?? activity.activity_name ?? ''}
                  onClick={() => setOpen(prev => !prev)}
                  dataTestid='content_sections_select_item'
                  endAdornment={activity.activity_status?.status === ActivityControlStatusEnum.AVAILABLE
                    ? <LockOutlined />
                    : <LockOpenOutlined />
                  }
                />
              )
          }
        </ScrollContainer>
      </SwipeableDrawer>
    </div>
  )
}

export default IndexMobile
