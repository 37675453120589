import dayjs from 'dayjs'
import { Tag } from 'components/design-system'

import useStyles from './styles'

interface IDaysUntilEnd {
  endDate: string
}

export const DaysUntilEnd = ({ endDate }: IDaysUntilEnd) => {
  const hoursLeft = dayjs(endDate).diff(dayjs(), 'hours')
  // const isLastDay = dayjs(endDate).date() === dayjs().date()
  const classes = useStyles()

  const dateText = dayjs(endDate).format('DD/MM HH:mm')
  let isActive = false

  if (hoursLeft < 24) {
    isActive = true
  }

  return (
    <Tag className={`${classes.dataTag} ${isActive && 'active'}`} >
      {dateText}
    </Tag>
  )
}
