import OnlyProfile from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { MyMaterialsStudent, MyMaterialsTeacher } from './components'

export const MyMaterials: React.FC = () => {
  return (
    <>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <MyMaterialsStudent />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <MyMaterialsTeacher />
      </OnlyProfile>
    </>
  )
}
