/* eslint-disable no-useless-computed-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  contentBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      transition: 'all ease-in-out 0.2s',
      backgroundColor: 'rgba(20, 20, 20, 0.64)',
      zIndex: 10,
      left: 0,
      top: '0',
      flexDirection: 'column !important' as 'column'
    }
  },
  box: {
    position: 'fixed',
    zIndex: 10,
    left: 0,
    height: 'auto',
    width: 67,
    textAlign: 'center',
    transition: 'all ease-in-out 0.2s',
    borderTopRightRadius: '32px',
    borderBottomRightRadius: '32px',
    '& p': {
      position: 'fixed',
      color: 'transparent',
      transition: 'all ease-in-out 0.1s',
      left: '-50px',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        position: 'relative',
        left: '30px'
      }
    },
    '&:hover': {
      [theme.breakpoints.up('sm')]: {
        width: '238px',
        '& p': {
          left: '58px',
          opacity: '1',
          visibility: 'visible'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      height: 'auto',

      width: '75%',
      // height: '100%',
      '&:selected': {
        left: -310
      }
    }
  },
  upperBox: {
    color: theme.colors.neutral.lightBase,
    backgroundColor: theme.colorBrand.light,

    '& p': {
      [theme.breakpoints.down('sm')]: {
        color: theme.colors.neutral.lightBase
      }
    },
    '&:hover': {
      '& p': {
        color: theme.colors.neutral.lightBase
      },
      '& div.is-active': {
        '& p': {
          color: theme.colorBrand.medium
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.colorBrand.light
    },
    '& .navButton': {
      color: theme.colors.neutral.lightBase,
      '&:hover': {
        backgroundColor: theme.colors.neutral.lightBase,
        color: theme.colorBrand.medium,
        '& > p, span': {
          color: theme.colorBrand.medium
        }
      },
      '&.is-active': {
        backgroundColor: theme.colors.neutral.lightBase,
        color: theme.colorBrand.medium,
        [theme.breakpoints.down('sm')]: {
          color: theme.colors.neutral.lightBase,
          backgroundColor: 'transparent'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      borderBottomRightRadius: '0'
    }
  },
  downBox: {
    bottom: 0,
    backgroundColor: theme.colors.neutral.light20,
    color: `${theme.colors.neutral.dark20} !important`,

    '& div.topBevel': {
      '&::before': {
        boxShadow: `10px 10px 5px 100px ${theme.colors.neutral.light20} !important`
      }
    },
    '& p': {
      [theme.breakpoints.down('sm')]: {
        color: theme.colors.neutral.dark20
      }
    },
    '&:hover': {
      '& p': {
        color: theme.colors.neutral.dark20
      }
    },
    '& .navButton': {
      color: theme.colors.neutral.dark20,
      '&:hover': {
        backgroundColor: theme.colors.neutral.lightBase,
        '& > p, span': {
          color: theme.colors.neutral.dark20
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
      borderTopRightRadius: '0'
    }
  },
  svgCustomIcon: {
    height: '24px',
    width: '24px',
    padding: '2px',
    color: `${theme.colors.neutral.dark20} !important`
  },
  beforeMobile: {
    [theme.breakpoints.down('sm')]: {
      left: '-100%',
      backgroundColor: 'transparent'
    }
  },
  top: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    position: 'absolute',
    height: '30px',
    width: '30px',
    overflow: 'hidden',
    transition: 'all ease-in-out 0.2s',
    top: '-28px',
    left: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      height: '200%',
      width: '200%',
      borderRadius: '100%',
      boxShadow: `10px 10px 5px 100px ${theme.colorBrand.light}`,
      zIndex: 0,
      top: '-100%'
    }
  },
  inLineBrandMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '14px 14px 24px 14px',
      columnGap: '24px',
      cursor: 'pointer'
    }
  },
  navlink: {
    textDecoration: 'none',
    '&.is-active': {
      backgroundColor: theme.colors.neutral.lightBase
    },

    '& .navButton': {
      gap: theme.spacingStack.nano,
      cursor: 'pointer',
      margin: theme.spacingStack.xxxs,
      display: 'flex',
      padding: '6px 6px',
      rowGap: theme.spacingStack.xxxs,
      fontSize: theme.font.fontSize.xs,
      textAlign: 'start',
      transition: 'all ease-in 0.1s',
      alignItems: 'center',
      fontWeight: theme.font.fontWeight.black,
      lineHeight: theme.font.lineHeight.md,
      borderRadius: theme.borderRadius.pill,
      letterSpacing: theme.font.spacing.distant,
      justifyContent: 'flex-start',
      backgroundColor: 'transparent'
    }
  },
  bottom: {
    position: 'absolute',
    height: '30px',
    width: '30px',
    overflow: 'hidden',
    transition: 'all ease-in-out 0.2s',
    left: 0,
    bottom: '-28px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      height: '200%',
      width: '200%',
      borderRadius: '100%',
      boxShadow: `10px 10px 5px 100px ${theme.colorBrand.light}`,
      zIndex: 0
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  classChange: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignSelf: 'flex-end',
      alignItems: 'center',
      padding: theme.spacingStack.xxxs,
      borderTop: '1px solid rgba(144, 121, 179, 0.32)',
      borderBottom: '1px solid rgba(144, 121, 179, 0.32)',
      justifyContent: 'space-between'
    }
  },
  classInforamtionText: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colors.neutral.dark30,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'initial',
      color: theme.colors.neutral.lightBase
    }
  },
  iconChangeClass: {
    display: 'flex',
    cursor: 'pointer',
    padding: theme.spacingInset.xxs
  },
  classChangeTitle: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.xs,
    cursor: 'pointer'
  },
  iconSvg: {
    color: theme.colorBrand.medium,
    [theme.breakpoints.down('sm')]: {
      marginInline: theme.spacingStack.nano
    }
  }
}))
