import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  cell: {
    textAlign: 'center'
  },
  divider: {
    border: `1px solid ${theme.colors.neutral.light10}`,
    width: '100%'
  },
  tabPanel: {
    padding: '32px 0px 16px 0px',
    // mobile
    [theme.breakpoints.down('sm')]: {
      padding: '32px 16px 16px 16px'
    }
  },
  textAlignRight: {
    textAlign: 'right'
  }
}))
