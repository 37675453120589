import { DisciplineCodeEnum } from 'services/types/discipline'
import { IGradeTypeEnum } from 'services/types/grade'

const instructionalDefaultOpenRule: (gradeTypeId: number, disciplinesCode: string[]) => boolean = (gradeTypeId, disciplinesCode) => {
  const isEI = gradeTypeId === IGradeTypeEnum.EI
  const isBI = disciplinesCode.includes(DisciplineCodeEnum.BI)
  const isENG = disciplinesCode.includes(DisciplineCodeEnum.ING)
  const defaultOpenRule = isEI || isBI || isENG
  return defaultOpenRule
}

export default instructionalDefaultOpenRule
