/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect } from 'react'

import { IStudentsAnswerByQuestionResponse } from 'contentCacheManager'
import { CircularProgress, Grid } from '@mui/material'

import CardStudentAnswerComment from './components/Comment'
import CardStudentAnswerCommentForm from './components/CommentForm'
import { useParams } from 'react-router-dom'
import { activeQuestionAtom, commentsByAnswerAtom, getCommentsByAnswerAtom, isLoadingCommentsByAnswerAtom } from '../../../../atomStore'
import { useAtom } from 'jotai'

interface ICardStudentAnswerCommentsProps {
  studentAnswer?: IStudentsAnswerByQuestionResponse
}

export const CardStudentAnswerComments: React.FC<ICardStudentAnswerCommentsProps> = ({ studentAnswer }) => {
  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, activityId: string, evaluationId: string }>()

  // atom
  const [activeQuestion] = useAtom(activeQuestionAtom)
  const [comments, setComments] = useAtom(commentsByAnswerAtom)
  const [isLoadingCommentsByAnswer] = useAtom(isLoadingCommentsByAnswerAtom)
  const [, getCommentsByAnswer] = useAtom(getCommentsByAnswerAtom)

  const fetchComments = async () => {
    await getCommentsByAnswer({ userSchoolProfile: Number(studentAnswer?.user_school_profile ?? '0'), activityId: Number(activityId ?? evaluationId ?? '0'), classId: Number(classId), questionId: Number(activeQuestion) })
  }

  useEffect(() => {
    void fetchComments()

    return () => {
      setComments([])
    }
  }, [])

  return (
    <Grid container display='flex' flexDirection='column' mt='24px' gap='24px'>
      <CardStudentAnswerCommentForm user_profile={Number(studentAnswer?.user_school_profile ?? '0')} />

      {isLoadingCommentsByAnswer
        ? <CircularProgress />
        : (
          <Grid display='flex' width='100%' flexDirection='column'>
            {(comments ?? []).map(comment => <CardStudentAnswerComment comment={comment} />)}
          </Grid>
        )}
    </Grid>
  )
}

export default CardStudentAnswerComments
