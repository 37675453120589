import useStyles from '../style'
import { DisciplineCodeEnum, IActivityResponse } from 'services/types'
import { Grid, useTheme } from '@mui/material'
import { Fragment } from 'react'
import { ActivityCard } from 'components/common'
import { useContents } from '../../useContents'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'

interface ActivityCardDataTestidProps {
  activity: {
    available: string
    to_correct: string
    finished: string
  }
  evaluation: {
    available: string
    to_correct: string
    finished: string
  }
}
interface IWithActivitiesProps {
  activities?: IActivityResponse[] | null
  lessonId: number
  classId: number
  gradeTypeCode: string
  gradeCode: string
  contentId: number
  activityCardDataTestId: ActivityCardDataTestidProps
}

const Title: React.FC<{ title: string }> = ({ title }) => {
  const classes = useStyles()
  return (
    <h2 className={classes.title}>
      <span
        dangerouslySetInnerHTML={{
          __html: title
        }}
      />
    </h2>
  )
}

export const WithActivities: React.FC<IWithActivitiesProps> = ({
  activities,
  lessonId,
  classId,
  gradeTypeCode,
  gradeCode,
  contentId,
  activityCardDataTestId
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { content } = useContents()
  const { isExpeditionView } = useStudentAnswer()

  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  if (!activities?.length) {
    return <Fragment />
  }

  return (
    <div className={classes.withActivitiesContainer} id='component--shall-we-practise--with-activities-component'>
      <Title title={t('Vamos praticar?')} />
      <Grid
        container
        xs={12}
        gap={theme.spacingInline.xxs}
        display='flex'
        justifyContent={'center'}
        sx={{
          flexWrap: 'wrap',
          background: theme.colors.neutral.lightBase,
          padding: theme.spacingInline.xxs,
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            paddingInline: theme.spacingInline.xxxs
          }
        }}
      >
        {activities?.map((activity: IActivityResponse, i) => (
          <Grid
            item
            sx={{
              maxWidth: '284px',
              minWidth: '284px',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                minWidth: '100%'
              }
            }}
            key={i}
          >
            <ActivityCard
              activity={activity}
              link={{
                lessonId: lessonId,
                contentId: contentId,
                classId: classId,
                gradeTypeCode,
                gradeCode
              }}
              newTab={true}
              statusDataTestid={activityCardDataTestId}
              useCloeExpandTranslation={isCloeExpand}
              isExpeditionView={isExpeditionView}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
