import { useTheme } from '@mui/material'
import { Icon, Tag } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import FolderIcon from '@mui/icons-material/Folder'
import useStyles, { DisciplinesArea, Wrapper, WrapperCardError } from './style'
import { IDisciplineResponse } from 'services/types'

export interface ISubscriptionCardProps {
  classId: number
  className: string
  disciplines: IDisciplineResponse[]
  onClick?: (classId: number) => void
  linkTo?: string
  tabIndex?: number
}

export const SubscriptionCard: React.FC<ISubscriptionCardProps> = ({ classId, disciplines, className, onClick, linkTo, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  if (!disciplines.length) {
    return (
      <WrapperCardError tabIndex={0} >
        {t('Não foi possivel carregar os componentes dessa turma')}
      </WrapperCardError>
    )
  }

  const firstdiscipline = disciplines[0]
  const classes = useStyles(firstdiscipline)
  const firstDisciplines = disciplines.slice(0, 2)
  const lastDisciplines = disciplines.slice(2)

  const WrapperContent = () =>
    <Wrapper firstdiscipline={firstdiscipline} sx={{
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%'
      }
    }}>
      <Icon iconColor={theme?.colors.curricularComponents[firstdiscipline?.code] ?? theme?.colors.curricularComponents.DEFAULT}>
        <FolderIcon />
      </Icon>
      <div tabIndex={props.tabIndex ?? 0} className={classes.title}>
        {className}
      </div>
      <DisciplinesArea>
        {
          firstDisciplines.map((discipline, key) =>
            <Tag key={key} tabIndex={key} className={classes.tag} variant={discipline.code}>
              {t(discipline.name)}
            </Tag>)
        }
        {
          lastDisciplines.length > 0 &&
          <Tag
            tabIndex={0}
            key={disciplines.length + 1}
            background={theme.colorBrand.darkest}
            className={classes.tagLast}
          >
            {`+${t(lastDisciplines.length?.toString())}`}
          </Tag>
        }
      </DisciplinesArea>
    </Wrapper>

  return (
    linkTo
      ? <NavLink
        className={classes.cardLink}
        onClick={() => onClick?.(classId)}
        to={linkTo}
      >
        {WrapperContent()}
      </NavLink>
      : <div
        className={classes.cardContainer}
        onClick={() => onClick?.(classId)}
      >
        {WrapperContent()}
      </div>
  )
}

export default SubscriptionCard
