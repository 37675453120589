import React from 'react'
import MUiAvatar from '@mui/material/Avatar'
import { AvatarProps } from '@mui/material'
import useStyles from './style'

export interface IAvatarProps extends Omit<AvatarProps, 'size'> {
  size?: 'small' | 'medium' | 'large'
  userName?: string
}

function replaceName(name: string) {
  const n = name.split(' ').filter((i) => { return i })

  if (n.length < 2) {
    return (n[0].charAt(0) + n[0].slice(-1)).toUpperCase()
  } else {
    return (n[0].charAt(0) + n[n.length - 1].charAt(0)).toUpperCase()
  }
}

export const Avatar: React.FC<IAvatarProps> = ({ size = 'medium', userName, ...props }) => {
  const { src } = props
  const classes = useStyles(size)
  return (
    <MUiAvatar
      {...props} className={`${classes.root} ${props.className as string ?? ''} ${size}`}
    >
      { !src && userName ? replaceName(userName) : 'CE' }
    </MUiAvatar>
  )
}
