import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  form: {
    background: 'white',
    width: '100%',
    height: 'calc(100% - 193px)',
    padding: `${theme.spacingStack.xxs} ${theme.spacingStack.xxxs}`,
    borderRadius: '25px 25px 0 0',
    boxShadow: theme.shadowLevels.level4,

    // mobile
    [theme.breakpoints.down('sm')]: {
      maxWidth: '360px',
      height: 'auto',
      position: 'absolute',
      bottom: 0
    }
  },
  formTitle: {
    color: theme.colors.neutral.dark40,
    width: '295px',
    margin: '0 auto'
  },
  title: {
    lineHeight: theme.font.lineHeight.sm,
    fontWeight: theme.font.fontWeight.medium
  },
  description: {
    margin: '16px 0 24px 0'
  },
  highlight: {
    color: '#509FD1',
    fontWeight: theme.font.fontWeight.semibold
  },
  formInput: {
    paddingTop: theme.spacingStack.xs,
    paddingBottom: theme.spacingStack.xs
  },
  input: {
    width: '100%',
    textAlign: 'left'
  },
  submitButton: {
    width: '100%'
  },
  linkBlock: {
    paddingTop: theme.spacingStack.nano,
    paddingBottom: theme.spacingStack.xxxs
  },
  cancelButton: {
    width: '100%'
  },
  modalTitle: {
    alignItems: 'center',
    color: '#B38100',
    display: 'flex',
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium,
    letterSpacing: '0.01em',
    lineHeight: theme.font.lineHeight.sm,
    textAlign: 'center'
  },
  modalDescription: {
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.regular,
    lineHeight: theme.font.lineHeight.xs,
    margin: '24px 0',
    textAlign: 'center'
  },
  modalButtons: {
    '& button:not(:last-child)': {
      marginBottom: theme.spacingInline.xxxs
    }
  },
  buttonsContainer: {
    marginTop: theme.spacingInline.xs,

    '& button:not(:last-child)': {
      marginBottom: theme.spacingStack.xxxs
    }
  },
  messageError: {
    color: 'red',
    fontSize: theme.font.fontSize.xs
  }
}))
