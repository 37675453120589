import React from 'react'
import { Grid } from '@mui/material'

import { ISchoolsClassResponse } from 'services/types'
import { StepSchoolSelectProps } from '../../types'
import useStyles from '../../style'

export const StepSchoolSelect: React.FC<StepSchoolSelectProps> = ({ schoolsClassesResponse, schoolSelected, setSchoolSelected }: StepSchoolSelectProps) => {
  const classes = useStyles()

  const removeDuplicates = () => {
    const unique = (schoolsClassesResponse?.schools ?? []).reduce((acc: any, curr: any) => {
      const isExists = acc.some((item: any) => item.id === curr.id)

      if (!isExists && (curr?.classes ?? []).length > 0) {
        acc.push(curr)
      }

      return acc
    }, [])

    return unique as ISchoolsClassResponse['schools']
  }

  return (
    <Grid container display='flex' flexDirection='column' justifyContent='flex-start' sx={{ height: '100%', flexDirection: 'column', flexFlow: 'column' }}>
      <span className={classes.txtTitle}>Selecione a escola que você deseja realizar essa atividade:</span>
      <Grid item display='flex' flexDirection='column' className={classes.contentStep}>
        {removeDuplicates().map(school => (
          <Grid
            item
            key={school.id}
            data-testid='qr_code_select_the_school'
            className={schoolSelected?.id === school.id ? classes.customButtonSelected : classes.customButton}
            onClick={() => setSchoolSelected(school)}
          >
            <span>{school.name}</span>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default StepSchoolSelect
