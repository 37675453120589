import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

type IFooter = {
  background?: string
  border?: boolean
}

export interface IModalWrapperStyleProps {
  footer?: IFooter
}

export default makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      marginTop: theme.spacingStack.lg,
      borderRadius: theme.borderRadius.sm
    },
    modalBody: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacingStack.xxxs,
      backgroundColor: theme.colors.neutral.lightBase,
      padding: `0 ${theme.spacingInset.xs} ${theme.spacingInset.xs} !important`,
      '&.MuiDialogContent-root::-webkit-scrollbar': {
        width: '30px'
      },
      '&.MuiDialogContent-root::-webkit-scrollbar-thumb': {
        background: '#C4C4C4',
        borderRadius: '40px',
        backgroundClip: 'content-box',
        border: 'solid 10px transparent'
      }
    },
    modalHeader: {
      display: 'flex',
      padding: theme.spacingInset.sm
    },
    closeContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer'
    },
    headerText: {
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium
    },
    modalFooter: {
      '&.MuiDialogActions-root': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: `${theme.spacingInset.xs} ${theme.spacingInset.sm}`,
        borderColor: `${theme.colors.neutral.light20} !important`,
        borderStyle: ({ footer }: IModalWrapperStyleProps) => footer?.border ? 'solid !important' : 'hidden',
        borderWidth: '1px !important',
        borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
        borderTop: 'solid',
        backgroundColor: ({ footer }: IModalWrapperStyleProps) => footer?.background ?? theme.colors.neutral.lightBase
      }
    },
    confirmButton: {
      '& .MuiCircularProgress-root': {
        width: '32px !important',
        height: '32px !important'
      }
    }
  })
)
