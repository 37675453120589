import { useTranslation, Trans } from 'react-i18next'
import { useMediaQuery, useTheme, Grid } from '@mui/material'
import { useStore } from 'store'
import { useHistory } from 'react-router'

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'

import { Text2, Icon } from 'components/design-system'
import { QuickAccessCard } from './components'
import { useLmsPlatform } from 'navigation/hooks/useLmsPlatform/useLmsPlatform'

import CloeAvatar from 'assets/cloe-avatar-joinha.svg'
import CloeWorm1 from 'assets/cloe-worm1.svg'
import CloeWorm2 from 'assets/cloe-worm2.svg'
import CloeWorm3 from 'assets/cloe-worm3.svg'
import CloeWorm4 from 'assets/cloe-worm4.svg'

import useStyle from './styles'
import { PROGRAM_CONTENT } from 'navigation/CONSTANTS'
import FeatureToggle, { FeatureFlags, Off, On } from 'components/feature-toggle'

interface IExpandedProps {
  handleHeaderExpand: () => void
}

export const Expanded: React.FC<IExpandedProps> = ({ handleHeaderExpand }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const { profile, session } = useStore()
  const { getLink } = useLmsPlatform(t, profile)
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const redirectTo = (dest: string) => {
    history.push(dest)
  }

  const HideButton = () => (
    <Grid item
      className={classes.hideButton}
      onClick={handleHeaderExpand}
      data-testid={'shortcut_hide'}
    >
      <Text2 customColor={theme.colors.neutral.lightBase} fontSize='xs' cursorpointer fontWeight='semibold' lineHeight='xs' mobile='xs'>
        {t('Esconder')}
      </Text2>
      <Icon size='medium'>
        <KeyboardArrowUpOutlinedIcon />
      </Icon>
    </Grid>
  )

  return (
    <Grid container className={classes.quickAccessContainer}>
      <Grid className={`${classes.contentSeparator} ${classes.welcomeSection}`}>
        <img src={CloeAvatar} className={classes.cloeAvatar} />
        <Grid className={classes.welcomeTextContainer}>
          <Text2 customColor={theme.colors.neutral.lightBase} fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='xl' className={classes.welcomeText}>
            <Trans i18nKey='WelcomeText'>
              Olá, <b>{{ name: session?.name ?? 'Professor(a)' }}</b>!
            </Trans>
          </Text2>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid className={`${classes.contentSeparator} ${classes.interactiveSection}`}>
          <Grid className={`${classes.contentSeparator} ${classes.linksContainerHeader}`}>
            <Text2 customColor={theme.colors.neutral.lightBase} fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs'>
              {t('Acesso rápido')}:
            </Text2>
            <HideButton />
          </Grid>
          <Grid className={`${classes.contentSeparator} ${classes.linksContainer}`}>
            <QuickAccessCard
              onClick={async () => await getLink()}
              IconComponent={LocalLibraryOutlinedIcon}
              title={t('Formação de professores')}
              description={t('Promova a aprendizagem ativa em suas aulas e saiba mais sobre o uso da plataforma')}
              wormImageSrc={CloeWorm1}
              wormImageClassName={classes.cloeWorm1}
              dataTestId={'shortcut_teacher_training'}
            />
            <FeatureToggle feature={FeatureFlags.cloeProgram}>
              <On>
                <QuickAccessCard
                  onClick={() => redirectTo(PROGRAM_CONTENT)}
                  IconComponent={RouteOutlinedIcon}
                  title={t('Programa Cloe')}
                  description={t('Conheça os detalhes do conteúdo de cada unidade e planeje suas aulas')}
                  wormImageSrc={CloeWorm2}
                  wormImageClassName={classes.cloeWorm2}
                  dataTestId={'menu_home_program_content'}
                />
              </On>
              <Off>
                <QuickAccessCard
                  onClick={() => redirectTo(PROGRAM_CONTENT)}
                  IconComponent={RouteOutlinedIcon}
                  title={t('Conteúdo programático')}
                  description={t('Conheça os detalhes do conteúdo de cada unidade e planeje suas aulas')}
                  wormImageSrc={CloeWorm2}
                  wormImageClassName={classes.cloeWorm2}
                  dataTestId={'menu_home_program_content'}
                />
              </Off>
            </FeatureToggle>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <>
          <img src={CloeWorm3} className={classes.cloeWorm3} />
          <img src={CloeWorm4} className={classes.cloeWorm4} />
        </>
      )}
    </Grid>
  )
}
