import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams, withRouter } from 'react-router-dom'
import useStyles from './style'
import { Button } from 'components/design-system'
import ghostApi from 'utils/ghost'
import { LoadingWrapper } from 'components/common'

const LibraryItem: React.FC = () => {
  const { postId } = useParams<{ postId: string }>()

  // store
  const [libraryItem, setLibraryItem] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    void (async () => {
      try {
        const response = await ghostApi.posts.read({ id: postId })
        if (response) {
          setLibraryItem(response)
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    })()
  }, [])

  const PostNotFound = () => {
    return (
      <div className={classes.containerEmptyDisciplines}>
        <p className={classes.titleEmptyDisciplines}>Ops!</p>
        <div className={classes.image404} />
        <p className={classes.subtitleEmptyDisciplines}>
          {t('Não conseguimos carregar o conteúdo para você')}
        </p>
        <div className={classes.boxButton}>
          <NavLink to='/' style={{ textDecoration: 'none' }} >
            <Button className={classes.button} variant='outlined' type='submit' id='error_back_to_home'>
              {t('Voltar para Página Inicial')}
            </Button>
          </NavLink>
        </div>
      </div>
    )
  }

  return (
    <div className={`${classes.postContainer}`}>
      <LoadingWrapper type='linear' loading={(isLoading)}>
        {
          libraryItem
            ? <>
              <div className={`${classes.header}`}>
                <h1 className={`${classes.title}`}>{libraryItem.title}</h1>
              </div>
              <div className={`${classes.postContent}`}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: libraryItem.html ?? ''
                  }}
                />
              </div>
            </>
            : <PostNotFound />
        }
      </LoadingWrapper>
    </div>
  )
}

export default withRouter(LibraryItem)
