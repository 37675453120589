import { Grid, Skeleton } from '@mui/material'
import { Loading as CardLoading } from '../ListContentUnit/components/ContentCardLong/components/loading'

export const Loading: React.FC = () => {
  const listFake = [1, 1, 1, 1]

  return (
    <Grid container>
      <Grid item xs={12} lg={12} spacing={2} container>
        <Grid item xs={1}>
          <Skeleton animation='wave' height={50} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton animation='wave' height={50} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton animation='wave' height={50} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton animation='wave' height={50} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton animation='wave' height={50} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton animation='wave' height={50} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton animation='wave' height={50} />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12} container mt={1}>
        {listFake.map((list, i) => (
          <CardLoading key={i} />
        ))}
      </Grid>
    </Grid>
  )
}

export default Loading
