import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    height: 'auto'
  },
  resourcesContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacingInset.sm} ${theme.spacingInset.md} ${theme.spacingInset.lg}`,
    gap: theme.spacingStack.xxl
  }
}))
