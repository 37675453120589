import { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { IContentUnitResponse, IDidacticContentResponse } from 'services/types'

interface UseProviderContentsProps {
  content: IContentUnitResponse
  didacticContent: IDidacticContentResponse | null
  indexSelected: number
}

function useProvideContents(props: UseProviderContentsProps) {
  const [content, setContent] = useState<IContentUnitResponse>(props.content)
  const [didacticContent, setDidacticContent] = useState<IDidacticContentResponse | null>(props.didacticContent)
  const [indexSelected, setIndexSelected] = useState<number>(props.indexSelected)

  useEffect(() => {
    setContent(props.content)
  }, [props.content])

  useEffect(() => {
    setDidacticContent(props.didacticContent)
  }, [props.didacticContent])

  useEffect(() => {
    const indexSelected = props.indexSelected !== -1 ? props.indexSelected : 0
    setIndexSelected(indexSelected)
  }, [props.indexSelected])

  return {
    content,
    setContent,
    didacticContent,
    setDidacticContent,
    indexSelected,
    setIndexSelected
  }
}

interface IContentsContext extends UseProviderContentsProps {
  setContent: (value: IContentUnitResponse) => void
  setDidacticContent: (value: IDidacticContentResponse) => void
  setIndexSelected: (value: number) => void
}

const contentInitial: IContentUnitResponse = {
  id: 0,
  code: '',
  title: '',
  legacy_id: 0,
  grade: {} as any,
  type: '',
  locale: '',
  language: '',
  suggested_position: 0,
  published_at: '',
  created_at: '',
  updated_at: '',
  sections: [],
  didactic_content: {} as any,
  instructional_contents: {} as any,
  disciplines: [],
  skill_taxonomies: [],
  activities: [],
  cover: {} as any,
  bnccSkill: [],
  skills: [],
  key_words: []
}

const ContentsContext = createContext<IContentsContext>({
  content: contentInitial,
  setContent: () => {
    // This is intentional
  },
  didacticContent: null,
  setDidacticContent: () => {
    // This is intentional
  },
  indexSelected: 0,
  setIndexSelected: () => {
    // This is intentional
  }
})

export const ProvideContents: React.FC<{ content: IContentUnitResponse, didacticContent: IDidacticContentResponse | null }> = ({ content, didacticContent, children }) => {
  const { sectionId } = useParams<{ sectionId: string }>()
  const useProvide = useProvideContents({
    content,
    didacticContent,
    indexSelected: sectionId && content.didactic_content?.sections?.length ? content.didactic_content.sections.findIndex(s => Number(s.id) === Number(sectionId)) : 0
  })

  return (
    <ContentsContext.Provider value={useProvide}>
      {children}
    </ContentsContext.Provider>
  )
}

export function useContents(): IContentsContext {
  return useContext(ContentsContext)
}
