/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAtom } from 'jotai'
import queryString from 'query-string'

import { Grid, useMediaQuery, useTheme } from '@mui/material'
import ListIcon from '@mui/icons-material/List'
import SortIcon from '@mui/icons-material/Sort'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { Brand, Text2, Text } from 'components/design-system'
import { LoadingWrapper } from 'components/common/LoadingWrapper'
import BackToTopWindow from 'components/common/BackToTopWindow'

import { atomTerms } from './atomStore'

import { getTerms } from 'services/terms'
import { ROOT } from 'navigation/CONSTANTS'

import useStyles, { Wrapper } from './styles'

type IFindTerms = 'policy' | 'terms'

export const Expanded: React.FC = () => {
  const [html, setHtml] = useState<string | undefined>()
  const [expandSummary, setExpandSummary] = useState(false)
  const [summary, setSummary] = useState<string | undefined>()
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(true)

  const [apiTerms] = useAtom(atomTerms)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const location = useLocation()

  const styles = useStyles()

  !isMobile ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'initial'

  useEffect(() => {
    const loadingData = async () => {
      const { type } = queryString.parse(location.search)

      await findTerms(type as IFindTerms)
    }

    loadingData()

    setLoading(false)
  }, [location])

  const findTerms = async (termType: IFindTerms) => {
    const types = {
      policy: async () => {
        if (apiTerms) {
          const { privacy_policy, policy_summary } = apiTerms
          setHtml(privacy_policy)
          setSummary(policy_summary)
          setTitle('Política de privacidade')
        } else {
          const response = await getTerms()

          if (response.success) {
            const { privacy_policy, policy_summary } = response.data[0]
            setHtml(privacy_policy)
            setSummary(policy_summary)
            setTitle('Política de privacidade')
          }
        }
      },
      terms: async () => {
        if (apiTerms) {
          const { usage_terms, terms_summary } = apiTerms
          setHtml(usage_terms)
          setSummary(terms_summary)
          setTitle('Termos de uso')
        } else {
          const response = await getTerms()

          if (response.success) {
            const { usage_terms, terms_summary } = response.data[0]
            setHtml(usage_terms)
            setSummary(terms_summary)
            setTitle('Termos de uso')
          }
        }
      }
    }

    await types[termType]()
  }

  return (
    <LoadingWrapper loading={loading} type='circular' fillContainer>
      <div className={styles.expandedContainer}>
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Brand type='logo' />
          </div>
          <Wrapper>
            <Grid item xs={12} md={3} display='flex' alignItems='center' className={styles.gobackContainer}>
              <button className={styles.goBackBtn} onClick={() => { window.location.href = ROOT }}>
                <ArrowBackIosNewOutlined />
                <Text>Voltar</Text>
              </button>
            </Grid>
          </Wrapper>
          <div className={styles.expandedCard}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12}>
                <Text2 fontSize='xl' mobile='lg' fontWeight='medium' mobilelineheight='sm' lineHeight='md' colorbrand='medium'>{title}</Text2>
              </Grid>
              <Grid item lg={8} md={8} sm={12}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: html ?? ''
                  }}
                />
              </Grid>
              {
                !isMobile
                  ? <Grid item lg={4} md={4} sm={4}>
                    <div className={styles.cardSummary}>
                      <div className={styles.cardSummaryHeader}>
                        <Text2 fontSize='sm' mobile='xs' fontWeight='medium' mobilelineheight='sm' lineHeight='md' iconscolor customColor='#FFF'><ListIcon />índice</Text2>
                      </div>
                      <div className={styles.cardSummaryBody}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: summary ?? ''
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  : null
              }
            </Grid>
          </div>
          {
            isMobile
              ? <div className={styles.cardBottomWrapper}>
                <div className={`${styles.cardBottomHeader} ${expandSummary ? 'show' : 'hide'}`} onClick={() => setExpandSummary(!expandSummary)}>
                  <div className='left'>
                    <Text2 fontSize='sm' mobile='sm' fontWeight='medium' mobilelineheight='sm' lineHeight='md' iconscolor customColor={theme.colorBrand.dark}><SortIcon />índice</Text2>
                  </div>
                  <div className='right'>
                    <Text2 fontSize='sm' mobile='sm' fontWeight='medium' mobilelineheight='sm' lineHeight='md' iconscolor customColor={theme.colorBrand.dark}><KeyboardArrowUpIcon /></Text2>
                  </div>
                </div>
                <div className={`${styles.cardBottomSummaryBody} ${expandSummary ? 'show' : 'hide'}`}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: summary ?? ''
                    }}
                  />
                </div>
              </div>
              : null
          }
        </div>
        <BackToTopWindow />
      </div>
    </LoadingWrapper>
  )
}

export default Expanded
