import { makeCacheInstance, CacheManagerException } from '../'

export const removeRequestFromCache = async (request: Request | string) => {
  const appCache = await makeCacheInstance()
  try {
    const requestInCache = await appCache.match(request)
    if (requestInCache) {
      return await appCache.delete(request)
    }
    throw new Error('request does not match')
  } catch (error) {
    return new CacheManagerException(new Request(request), error)
  }
}

export const removeAllUrlsFromCache = async (requests: string[]) => {
  return await Promise.all(requests.map(removeRequestFromCache))
}
