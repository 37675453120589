import { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useUpdateAtom, useResetAtom, RESET } from 'jotai/utils'

import { BallotOutlined, DescriptionOutlined, ExtensionOutlined, RocketLaunchOutlined, MapOutlined } from '@mui/icons-material'

import {
  CONTENT_UNIT_STUDENT,
  CONTENT_UNIT_TEACHER,
  CONTENT_UNIT_TEACHER_CONTENT,
  CONTENT_UNIT_TEACHER_ACTIVITIES,
  CONTENT_UNIT_TEACHER_EVALUATIONS,
  CONTENT_UNIT_TEACHER_EXPEDITION
} from 'navigation/CONSTANTS'
import useStyles from '../style'

import Appcues from 'utils/appcues'

import { IHeaderProps, INavLinks } from '../types'
import { DisciplineCodeEnum } from 'services/types'
import { IGradeTypeEnum } from 'services/types/grade'
import { useStore } from 'store'
import dayjs from 'dayjs'
import isProjectViewModeRule from 'rules/isProjectViewModeRule'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { contentUnitHeaderTabsTeacherAtom } from '../../../atomStore'
import { currentSubMenuTabAtom, previousSubMenuTabAtom, subMenuTabsAtom } from '../../SideMenu/components/atomStore'
import { useAtom } from 'jotai'

export const ContentUnitHeaderTeacherWithProjectViewMode: React.FC<IHeaderProps> = ({ content }) => {
  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()
  const { schoolPeriod } = useStore()
  const [linkBefore, setLinkBefore] = useState(0)
  const classes = useStyles()
  const isProjectViewMode = isProjectViewModeRule(content?.grade?.grade_type ?? 0, content?.disciplines?.map(d => d.code) ?? [])
  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const hasDidacticContent = (!!content?.didactic_content?.id) && content?.didactic_content?.sections.length

  const setContentUnitHeaderTabsTeacher = useUpdateAtom(contentUnitHeaderTabsTeacherAtom)
  const [, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)
  const resetPreviousSubMenuTab = useResetAtom(previousSubMenuTabAtom)

  useEffect(() => {
    const htmlNavLinks = document.getElementsByClassName('tab-link')

    for (let i = 0; i < htmlNavLinks.length; i++) {
      if (i > 0 && htmlNavLinks.item(i)?.className.includes('is-active')) {
        setLinkBefore(i - 1)
      }
    }
  }, [])

  const handleTabNavigation = () => {
    setCurrentSubMenuTab(RESET)
  }

  let teacherNavLinks: INavLinks[] = [
    {
      id: 'ponto-de-partida',
      gtmTagId: 'expedition-tab-what-we-will-do',
      component: <span><RocketLaunchOutlined /> <span>{t('Ponto de partida')}</span></span>,
      to: CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, content.id?.toString()),
      isActive: undefined,
      exact: true,
      datatestid: 'content_expedition_menu_section',
      selectedTab: 'ponto-de-partida'
    },
    {
      id: 'aulas',
      gtmTagId: '',
      component: <span><DescriptionOutlined /> <span>{t('Aulas')}</span></span>,
      to: CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, content.id?.toString(), content?.didactic_content?.sections[0]?.id?.toString()),
      isActive: () => window.location.pathname.includes(`${CONTENT_UNIT_STUDENT(content.id?.toString())}/content/`),
      exact: false,
      datatestid: 'content_expedition_menu_section',
      onClick: () => Appcues.track('prof_aba_aula'),
      selectedTab: 'aulas'
    },
    {
      id: 'atividades',
      gtmTagId: 'expedition_tab_activities',
      component: <span><ExtensionOutlined /> <span>{t('Atividades')}</span></span>,
      to: CONTENT_UNIT_TEACHER_ACTIVITIES(gradeTypeCode, gradeCode, classId, content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section',
      selectedTab: 'atividades'
    },
    {
      id: 'avaliacoes',
      gtmTagId: 'expedition_tab_assessments',
      component: <span><BallotOutlined /> <span>{t('Avaliações')}</span></span>,
      to: CONTENT_UNIT_TEACHER_EVALUATIONS(gradeTypeCode, gradeCode, classId, content.id?.toString()),
      isActive: undefined,
      exact: false,
      datatestid: 'content_expedition_menu_section',
      selectedTab: 'avaliacoes'
    }
  ]

  if (content?.grade?.grade_type === IGradeTypeEnum.EI) {
    teacherNavLinks = [
      {
        id: 'ponto-de-partida',
        gtmTagId: 'expedition-tab-what-we-will-do',
        component: <span><RocketLaunchOutlined /> <span>{t('Ponto de partida')}</span></span>,
        to: CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: true,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'ponto-de-partida'
      },
      {
        id: 'conteudo',
        gtmTagId: '',
        component: <span><MapOutlined /> <span>{t('Conteúdo')}</span></span>,
        to: CONTENT_UNIT_TEACHER_EXPEDITION(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: () => window.location.pathname.includes('/expedition'),
        exact: false,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'conteudo'
      },
      {
        id: 'conteudo',
        gtmTagId: 'expedition_tab_content',
        datatestid: 'content_expedition_menu_section',
        component: <span><DescriptionOutlined /> <span>{t('Almanaque')}</span></span>,
        to: CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, content.id?.toString(), content?.didactic_content?.sections[0]?.id?.toString()),
        isActive: () => window.location.pathname.includes(`${CONTENT_UNIT_STUDENT(content.id?.toString())}/content/`),
        exact: false,
        selectedTab: 'conteudo'
      }
    ]

    const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)

    if (isCloeExpand) {
      teacherNavLinks.push(
        {
          id: 'atividades',
          gtmTagId: 'expedition_tab_activities',
          component: <span><ExtensionOutlined /> <span>{t('Atividades')}</span></span>,
          to: CONTENT_UNIT_TEACHER_ACTIVITIES(gradeTypeCode, gradeCode, classId, content.id?.toString()),
          isActive: undefined,
          exact: false,
          datatestid: 'content_expedition_menu_section',
          selectedTab: 'atividades'
        }
      )
    }

    // We remove activities because there's no longer support in EI segment for it
    // To ensure compatibility with older periods before 2023 (when the rule starts) we add here
    if (!schoolPeriod?.current && !schoolPeriod?.next && schoolPeriod?.start_date !== undefined && dayjs(schoolPeriod?.start_date).isBefore(dayjs('2022-12-31'))) {
      teacherNavLinks.push({
        id: 'atividades',
        gtmTagId: 'expedition_tab_activities',
        component: <span><ExtensionOutlined /> <span>{t('Atividades')}</span></span>,
        to: CONTENT_UNIT_TEACHER_ACTIVITIES(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: false,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'atividades'
      })
    }
  }

  if (content?.grade?.code?.includes('EM_') || content?.grade?.code?.includes('IT_')) {
    teacherNavLinks = [
      {
        id: 'ponto-de-partida',
        gtmTagId: 'expedition-tab-what-we-will-do',
        component: <span><RocketLaunchOutlined /> <span>{t('Ponto de partida')}</span></span>,
        to: CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: true,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'ponto-de-partida'
      },
      {
        id: 'aulas',
        gtmTagId: '',
        component: <span><DescriptionOutlined /> <span>{t('Aulas')}</span></span>,
        to: CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, content.id?.toString(), content?.didactic_content?.sections[0]?.id?.toString()),
        isActive: () => window.location.pathname.includes(`${CONTENT_UNIT_STUDENT(content.id?.toString())}/content/`),
        exact: false,
        datatestid: 'content_expedition_menu_section',
        onClick: () => Appcues.track('prof_aba_aula'),
        selectedTab: 'aulas'
      },
      {
        id: 'avaliacoes',
        gtmTagId: '',
        component: <span><BallotOutlined /> <span>{t('Exercícios')}</span></span>,
        to: CONTENT_UNIT_TEACHER_EVALUATIONS(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: false,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'avaliacoes'
      }
    ]
  }

  if (content?.grade?.grade_type === IGradeTypeEnum.EF1 && hasDidacticContent) {
    teacherNavLinks = [
      {
        id: 'ponto-de-partida',
        gtmTagId: 'expedition-tab-what-we-will-do',
        component: <span><RocketLaunchOutlined /> <span>{t('Ponto de partida')}</span></span>,
        to: CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: true,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'ponto-de-partida'
      },
      {
        id: 'aulas',
        gtmTagId: '',
        component: <span><DescriptionOutlined /> <span>{t('Conteúdo')}</span></span>,
        to: CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, content.id?.toString(), content?.didactic_content?.sections[0]?.id?.toString()),
        isActive: () => window.location.pathname.includes(`${CONTENT_UNIT_STUDENT(content.id?.toString())}/content/`),
        exact: false,
        datatestid: 'content_expedition_menu_section',
        onClick: () => Appcues.track('prof_aba_aula'),
        selectedTab: 'aulas'
      },
      {
        id: 'atividades',
        gtmTagId: 'expedition_tab_activities',
        component: <span><ExtensionOutlined /> <span>{t('Atividades')}</span></span>,
        to: CONTENT_UNIT_TEACHER_ACTIVITIES(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: false,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'atividades'
      },
      {
        id: 'avaliacoes',
        gtmTagId: 'expedition_tab_assessments',
        component: <span><BallotOutlined /> <span>{t('Avaliações')}</span></span>,
        to: CONTENT_UNIT_TEACHER_EVALUATIONS(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: false,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'avaliacoes'
      }
    ]
  }

  if (content?.grade?.grade_type === IGradeTypeEnum.EF2 || isProjectViewMode) {
    teacherNavLinks = [
      {
        id: 'ponto-de-partida',
        gtmTagId: 'expedition-tab-what-we-will-do',
        component: <span><RocketLaunchOutlined /> <span>{t('Ponto de partida')}</span></span>,
        to: CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: true,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'ponto-de-partida'
      },
      {
        id: 'aulas',
        gtmTagId: '',
        component: <span><DescriptionOutlined /> <span>{t('Conteúdo')}</span></span>,
        to: CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, content.id?.toString(), content?.didactic_content?.sections[0]?.id?.toString()),
        isActive: () => window.location.pathname.includes(`${CONTENT_UNIT_STUDENT(content.id?.toString())}/content/`),
        exact: false,
        datatestid: 'content_expedition_menu_section',
        onClick: () => Appcues.track('prof_aba_aula'),
        selectedTab: 'aulas'
      },
      {
        id: 'atividades',
        gtmTagId: 'expedition_tab_activities',
        component: <span><ExtensionOutlined /> <span>{t('Atividades')}</span></span>,
        to: CONTENT_UNIT_TEACHER_ACTIVITIES(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: false,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'atividades'
      },
      {
        id: 'avaliacoes',
        gtmTagId: 'expedition_tab_assessments',
        component: <span><BallotOutlined /> <span>{t('Avaliações')}</span></span>,
        to: CONTENT_UNIT_TEACHER_EVALUATIONS(gradeTypeCode, gradeCode, classId, content.id?.toString()),
        isActive: undefined,
        exact: false,
        datatestid: 'content_expedition_menu_section',
        selectedTab: 'avaliacoes'
      }
    ]
  }

  return (
    <>
      {
        teacherNavLinks.map((nav, i) =>
          <NavLink
            key={i}
            to={nav.to}
            className={`tab-link ${classes.tabLink} ${linkBefore === i && 'is-before'}`}
            activeClassName='is-active'
            exact={nav.exact}
            isActive={nav.isActive}
            onClick={() => {
              setLinkBefore(i - 1)
              if (nav?.onClick) {
                nav.onClick()
              }
              handleTabNavigation()
              setCurrentSubMenuTab(RESET)
              resetSubMenuTabs()
              resetPreviousSubMenuTab()

              if (nav?.selectedTab) {
                setContentUnitHeaderTabsTeacher(nav.selectedTab)
              }
            }}
            id={nav.gtmTagId}
            data-testid={nav?.datatestid}
          >
            {nav.component}
          </NavLink>)
      }
    </>
  )
}
