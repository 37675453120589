import { Grid, Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import { SimpleTab, SimpleTabs, TabPanel, Text2 } from 'components/design-system'
import React, { createRef, useRef, useState } from 'react'
import useStyles from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import { DetailedPerformanceActivity, DetailedPerformanceEvaluation } from '..'
import { IUnitStudentDetailedPerformance } from 'contentCacheManager'

interface IDetailedPerformanceAccordion {
  detailedPerformance: IUnitStudentDetailedPerformance
  expanded?: boolean
}

export const DetailedPerformanceAccordion: React.FC<IDetailedPerformanceAccordion> = ({ detailedPerformance, expanded = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const expandAccordionRef = useRef<HTMLDivElement | null>(null)
  const accordionRef = createRef<HTMLDivElement>()
  const theme = useTheme()

  // states
  const [accordionDataTestId, setAccordionDataTestId] = useState<string>('student_menu_followup_content_unit_expand')
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0)
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded ?? false)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => setCurrentTabIndex(newValue)

  const handleExpanded = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    accordionRef?.current?.click()

    setIsExpanded(!isExpanded)
    const isAccordionExpanded = expandAccordionRef.current?.attributes.getNamedItem('aria-expanded')?.value
    isAccordionExpanded === 'true'
      ? setAccordionDataTestId('student_menu_followup_content_unit_expand')
      : setAccordionDataTestId('student_menu_followup_content_unit_retract')
  }

  const expandIcon = <ExpandMoreIcon fontSize='large' onClick={e => handleExpanded(e)} sx={{ color: `${theme.colorBrand.medium}!important` }} />

  return (
    <>
      <div ref={accordionRef} data-testid={accordionDataTestId} hidden />
      <Accordion className={classes.accordion} expanded={isExpanded}>
        <AccordionSummary
          className={classes.accordionSummary}
          data-testid={accordionDataTestId}
          expandIcon={expandIcon}
          onClick={e => handleExpanded(e)}
          ref={expandAccordionRef}
        >
          <Grid container>
            <Grid item xs={12}>
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='dark40' cursorpointer>
                {detailedPerformance.title}
              </Text2>
            </Grid>
            <Grid item xs={12}>
              <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' colorbrand='medium' cursorpointer>
                {detailedPerformance.description}
              </Text2>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <SimpleTabs
            value={currentTabIndex}
            onChange={handleChange}
          >
            <SimpleTab data-testid='student_menu_followup_menu_evaluations' icon={<BallotOutlinedIcon />} iconPosition='start' label={t('Avaliações')} />
            <SimpleTab data-testid='student_menu_followup_menu_activities' icon={<ExtensionOutlinedIcon />} iconPosition='start' label={t('Atividades')} />
          </SimpleTabs>
          <TabPanel value={currentTabIndex} index={0}>
            <DetailedPerformanceEvaluation
              contentUnit={detailedPerformance}
              evaluations={detailedPerformance.evaluations}
            />
          </TabPanel>
          <TabPanel value={currentTabIndex} index={1}>
            <DetailedPerformanceActivity
              contentUnit={detailedPerformance}
              activities={detailedPerformance.activities}
            />
          </TabPanel>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
