import { DisciplineCodeEnum, IMomentsResponse } from 'services/types'
import { Grid } from '@mui/material'
import ContentLink from 'components/common/ContentLink'
import { useParams } from 'react-router-dom'
import { ContentTypeEnum } from 'services/types/content'
import { Resource, Text } from 'components/common'
import { WatchLaterOutlined } from '@mui/icons-material'
import { parseKaTex } from 'utils/katex'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { useContents } from '../../../useContents'

import useStyle from './style'

interface IClassMomentProps {
  moment: IMomentsResponse
}

export const ClassMoment: React.FC<IClassMomentProps> = ({ moment }) => {
  const classes = useStyle()

  const { gradeTypeCode, gradeCode, classId, contentId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string }>()
  const { content } = useContents()
  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {moment.title}
      </div>
      <div className={classes.contentContainer}>
        <Grid container spacing={2} className={classes.sectionContainer}>
          {/* <div
            className={classes.sectionContainerText}
            dangerouslySetInnerHTML={{
              __html: parseKaTex(moment.text) ?? ''
            }}
          /> */}
          <Text content={parseKaTex(moment.text)} />
          {
            moment.resources.map(resource =>
              <Grid key={resource.id} item xs={12}>
                <Resource resource={resource} />
              </Grid>)
          }
        </Grid>
        <Grid container className={classes.sectionContainer}>
          {
            moment.sections.map(section =>
              // <div
              //   className={classes.sectionContainerText}
              //   key={section.id}
              //   dangerouslySetInnerHTML={{
              //     __html: parseKaTex(section.text) ?? ''
              //   }}
              // />
              <Text content={parseKaTex(section.text)} />
            )
          }
        </Grid>
        <Grid container spacing={1} className={classes.sectionContainer}>
          {
            moment.activities.map(activity =>
              <Grid className={classes.activityItem} item xs={12} sm={12} md={6} lg={6} key={activity.id}>
                <ContentLink
                  id={activity.id?.toString()}
                  activityId={activity.id?.toString()}
                  gradeCode={gradeCode}
                  gradeTypeCode={gradeTypeCode}
                  classId={classId}
                  contentId={contentId}
                  title={activity.title ?? activity.activity_name ?? ''}
                  type={activity.suggested_application_type === 'activity' ? ContentTypeEnum.activity : ContentTypeEnum.evaluation}
                  suggestedApplicationType={activity.suggested_application_type}
                  useCloeExpandTranslation={isCloeExpand}
                />
              </Grid>)
          }
        </Grid>
        {
          moment.duration_value
            ? <div className={classes.duration}>
              <WatchLaterOutlined /> {moment.duration_value} {t(moment.duration_type ?? 'minutos')}
            </div>
            : ''
        }
      </div>
    </div>
  )
}

export default ClassMoment
