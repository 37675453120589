import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardStudentAnswerComment: {
      width: '95%',
      backgroundColor: '#F5F3F8',
      padding: theme.spacingInline.xxxs,
      borderRadius: theme.borderRadius.sm,
      display: 'flex',
      alignSelf: 'flex-end',

      color: theme.colors.neutral.darkBase,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.regular,
      whiteSpace: 'pre-line',
      lineBreak: 'anywhere'
    },
    cardStudentAnswerCommentTextName: {
      color: theme.colors.neutral.dark20,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold
    },
    cardStudentAnswerCommentTextDate: {
      color: theme.colors.neutral.light40,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium
    }
  })
)
