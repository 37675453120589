import { LOGIN } from 'navigation/CONSTANTS'
import { Button } from 'components/design-system'
import { useHistory } from 'react-router-dom'
import { BusinessError } from '../../navigation/BusinessError'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'navigation/ProvideAuth'

export const NoProfileFound = () => {
  const history = useHistory()
  const auth = useAuth()
  const { t } = useTranslation()

  return (
    <div style={{ margin: '0 auto', width: '100%', maxWidth: '700px', borderRadius: '8px', background: 'white', padding: '0 16px' }}>
      <BusinessError
        error={t('Identificamos que o seu acesso à Cloe foi bloqueado. Por favor, entre em contato com a gestão da(s) sua(s) unidade(s) escolar(es). Obrigada!')}>
        <br />
        <Button onClick={() => {
          auth?.clean()
          history.push(LOGIN)
        }}>
          {t('Voltar para o login')}
        </Button>
      </BusinessError>
    </div>
  )
}

export default NoProfileFound
