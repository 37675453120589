import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacingInset.xs
    },
    commentInput: {
      width: '100%',
      textAlign: 'left'
    },
    avatar: {
      paddingLeft: theme.spacingInline.xxxs,
      paddingRight: theme.spacingInline.nano,
      [theme.breakpoints.down('sm')]: {
        paddingInline: theme.spacingInline.nano
      }
    },
    userInfo: {
      maxWidth: 0,
      maxHeight: '40px',
      transition: 'all ease-in-out .2s',
      '&.opened': {
        paddingRight: theme.spacingInline.nano,
        maxWidth: '300px'
      }
    },
    userInfoName: {
      fontWeight: theme.font.fontWeight.bold,
      fontSize: theme.font.fontSize.xs,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark40
    },
    dropdownWrapper: {
      padding: `0 ${theme.spacingInline.nano}`,
      width: '245px'
    },
    dropdownTitle: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xs,
      lineHeight: '17px',
      letterSpacing: '0.01em'
    },
    dropdownWrapperButtons: {
      padding: `0 ${theme.spacingInline.nano}`
    },
    dropdownButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacingInline.nano,
      marginTop: theme.spacingInline.nano
    },
    navationButtonWrapper: {
      position: 'absolute',
      top: theme.spacingInline.nano,
      right: theme.spacingInline.nano
    },
    navationButton: {
      display: 'flex',
      alignItems: 'center',
      color: theme.colorBrand.medium,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs,
      lineHeight: theme.font.lineHeight.xxs,
      '& > svg': {
        marginRight: theme.spacingInline.nano,
        height: theme.spacingStack.xxxs
      }
    }
  })
)
