import { IRecordEventClick, IAnalytics } from '../interfaces'

// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[]
  }
}
export class AnalyticsGoogle implements IAnalytics {
  readonly name = 'google'
  readonly hasSetup = false
  setup(data: any) {
  }

  recordEventClick(data: IRecordEventClick) {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: data.name,
        ...data.attributes
      })
    } else {
      console.warn('AnalyticsGoogle not handling IRecordEventClick')
    }
  }

  async recordEventClickSync(data: IRecordEventClick): Promise<unknown> {
    return await new Promise((resolve, reject) => {
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: data.name,
          ...data.attributes
        })
        resolve({
          event: data.name,
          ...data.attributes
        })
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('AnalyticsGoogle not handling IRecordEventClick')
      }
    })
  }
}
