import { Grid, Box, Theme } from '@mui/material'
import { ExpandableImage } from 'components/common'
import { IMediaResponse } from 'services/types'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    boxGallery: {
      marginBottom: theme.spacingStack.xxxs
    }
  })
)
interface IExpandableImageWrapperProps {
  medias: IMediaResponse[]
}

export const ExpandableImageWrapper: React.FC<IExpandableImageWrapperProps> = ({ medias }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid container spacing={2} className={classes.boxGallery}>
      {medias
        ? medias.map((data, index) => {
          return (
            <Grid item xs={3} key={index}>
              <Box sx={{ height: 150 }} >
                <ExpandableImage
                  key={data.id}
                  src={data.formats?.medium?.url}
                  image={data.formats?.large?.url}
                />
              </Box>
            </Grid>
          )
        })
        : t('Nenhuma item cadastrado na galeria')}
    </Grid>
  )
}
