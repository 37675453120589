/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { useAtom } from 'jotai'

import {
  IContentUnitResponse,
  ActivityTypeDescription
} from 'services/types'
import { Pagination } from './components/Pagination'
import { RelatedContent, QuestionList, OldActivitiyEnunciate } from '../../../common'
// import { useTranslation } from 'react-i18next'
// import { SectionCollapse } from 'components/common'
import { activityCurrentAtom, isStudentViewAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { useTheme } from '@mui/material'

interface ITeacherActivityProps {
  content: IContentUnitResponse
}

export const TeacherActivityQuestionsView: React.FC<ITeacherActivityProps> = ({ content }) => {
  // atom
  const [activity] = useAtom(activityCurrentAtom)
  const [, setIsStudentView] = useAtom(isStudentViewAtom)

  // const { t } = useTranslation()
  const isActivitySuggestedType = activity?.suggested_application_type === 'activity'
  const theme = useTheme()
  const activityType = activity?.type

  useEffect(() => {
    setIsStudentView(true)

    return () => {
      setIsStudentView(false)
    }
  }, [])

  return (
    <Grid container xs={12} lg={12}>
      {activity && (
        <RelatedContent
          activity={activity}
          activityType={(activityType && ActivityTypeDescription[activityType])}
          content={content}
        />
      )}
      <Grid item xs={12} lg={12} pt={0} ml={0}>
        {activity && <OldActivitiyEnunciate activity={activity} />}

        <Grid container spacing={1} ml={0} marginTop={1}>
          <Pagination
            questions={activity?.questions ?? []}
            dataTestid={isActivitySuggestedType
              ? 'correction_activity_menu_questions_question_number'
              : 'correction_assessment_menu_assessments_question_number'}
          />
        </Grid>
        <QuestionList questions={activity?.questions ?? []} />
      </Grid>
    </Grid>
  )
}

export default TeacherActivityQuestionsView
