import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    questionSelectorContainer: {
      backgroundColor: theme.colors.information.feedbackLightest,
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
      borderRadius: theme.borderRadius.sm,
      padding: theme.spacingInline.xxxs,

      '&.bottom-fixed': {
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        zIndex: 5,
        paddingLeft: 110,
        paddingRight: 64,

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacingInline.xxxs
        }
      }
    },
    questionSelectorContentOptions: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between'
      }
    },
    questionSelectorContentOptionsButton: {
      minWidth: '70px !important',
      backgroundColor: `${theme.colors.information.feedbackDark} !important`,
      fontWeight: `${theme.font.fontWeight.semibold} !important`,
      color: `${theme.colors.neutral.lightBase} !important`
    },
    questionSelectorContentOptionsButtonNotActive: {
      minWidth: '70px !important',
      fontWeight: `${theme.font.fontWeight.semibold} !important`
    },
    questionSelectorContentOptionsButtonNotActiveNotCorrect: {
      minWidth: '40px !important',
      fontWeight: `${theme.font.fontWeight.semibold} !important`
    },
    questionSelectorContentHandles: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: `${theme.spacingStack.xxxs} !important`,
        flexDirection: 'column !important'
      }
    },
    questionSelectorButtonsHandles: {
      backgroundColor: `${theme.colors.information.feedbackDark} !important`,

      '&.Mui-disabled': {
        backgroundColor: 'transparent !important'
      }
    },
    questionSelectorButtonOutlinedDisabled: {
      backgroundColor: 'transparent !important',
      color: `${theme.colors.information.feedbackDark} !important`,
      borderColor: `${theme.colors.information.feedbackDark} !important`
    }
  })
)
