import { createRef } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MuiSelect, { SelectProps } from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import { useTranslation } from 'react-i18next'
import makeStyles, { helperTextStyles } from './style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export interface ISelectValue {
  value?: string | number
  label?: string
  dataTestId?: string
}

export interface ISelectProps extends SelectProps {
  id: string
  variant?: 'filled' | 'outlined'
  options?: ISelectValue[]
  showSelectOption?: boolean
  label?: string
  error?: boolean
  warning?: boolean
  success?: boolean
  assistivetext?: string
  onChange?: any
  defaultValue?: any
  value?: any
  dataTestId?: string
}

function changeType(error?: boolean, warning?: boolean, success?: boolean) {
  if (error) {
    return 'error'
  }
  if (warning) {
    return 'warning'
  }
  if (success) {
    return 'success'
  }
  return 'default'
}

export const Select: React.FC<ISelectProps> = ({ id, variant = 'filled', showSelectOption = false, options, label, assistivetext, defaultValue, value, children, dataTestId, ...props }) => {
  const classes = makeStyles({ id, label })
  const { t } = useTranslation()
  const type = changeType(props.error, props.warning, props.success)
  const HelperTextClasses = helperTextStyles()
  const selectRef = createRef<any>()

  const handleDataTaging = () => {
    selectRef?.current?.click()
  }

  return (
    <>
      <span ref={selectRef} data-testid={dataTestId} />
      <FormControl className={`${classes.root} ${type}`} variant={variant} fullWidth id={`form-control-${id}`}>
        {label && <InputLabel id={`id-${id}`} className={`${type}`}>{label}</InputLabel>}
        <MuiSelect
          {...props}
          className={`${type}`}
          labelId={`id-${id}`}
          id={`id-${id}`}
          data-testid={`select-${id}`}
          label={label}
          defaultValue={defaultValue}
          value={value}
          onOpen={handleDataTaging}
          IconComponent={ExpandMoreIcon}
          MenuProps={{ classes: { paper: classes.select } }}
        >
          {
            showSelectOption && <MenuItem value={undefined}>{t('Selecione uma opção')}</MenuItem>
          }
          {
            options
              ? options?.map((option, index) => (
                <MenuItem className={classes.option} data-testid={option.dataTestId} value={option.value} key={index}>
                  {t(option.label ?? ' ')}
                </MenuItem>
              ))
              : children
          }
        </MuiSelect>
        {assistivetext && (
          <FormHelperText variant={variant} className={`${HelperTextClasses.root} ${type}`}>
            {assistivetext}
          </FormHelperText>
        )}
      </FormControl>
    </>
  )
}
