import React from 'react'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import { BallotOutlined, ExtensionOutlined } from '@mui/icons-material'
import NaturalCompare from 'string-natural-compare'

import useStyles, { TabsList } from '../style'
import { TabPanel } from 'components/design-system'
import { BusinessError } from 'navigation/BusinessError'

import { ITabsProps } from '../types'

import { RenderedList } from '.'
import { DisciplineCodeEnum } from 'services/types'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'

export const TabsEF1 = ({
  title, value, content, setValue
}: ITabsProps) => {
  const classes = useStyles()
  // const theme = useTheme()
  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const navLinks = [
    {
      id: 'atividades',
      component: <span><ExtensionOutlined /> <span>{t('Atividades')}</span></span>,
      filteredActivity: (content.activities ?? []).filter(a => a.suggested_application_type === 'activity').map(cur => {
        cur.title = cur.title ?? cur.activity_name
        cur.title = cur.title.trim()
        return cur
      }).sort((a, b) => NaturalCompare(a.title, b.title, { caseInsensitive: true }))
    },
    {
      id: 'avaliacoes',
      component: <span><BallotOutlined /> <span>{t('Avaliações')}</span></span>,
      filteredEvaluation: (content.activities ?? []).filter(a => a.suggested_application_type === 'evaluation').map(cur => {
        cur.title = cur.title ?? cur.activity_name
        cur.title = cur.title.trim()
        return cur
      }).sort((a, b) => NaturalCompare(a.title, b.title, { caseInsensitive: true }))
    }
  ]

  return (
    <TabsUnstyled defaultValue={0}>
      <TabsList className={classes.tab}>
        {navLinks.map((navLink, i) => (
          <div
            key={i}
            className={`tab-link ${classes.tabLink} ${value - 1 === i && 'is-before'} ${value + 1 === i && 'is-after'} ${value === i && 'selected'}`}
            onClick={() => setValue(i)}
          >
            {navLink.component}
          </div>
        ))}
      </TabsList>
      {
        navLinks.map((navLink, teacheri) =>
          <React.Fragment key={teacheri}>
            {
              navLink.filteredActivity && (
                <TabPanel value={value} index={0}>
                  {
                    !navLink.filteredActivity?.length
                      ? <div className={classes.errorWrapper}><BusinessError error={t('Opa, parece que não existem conteúdos cadastrados nessa seção!', true)} /></div>
                      : <RenderedList filtered={navLink.filteredActivity ?? []} title={title} description={t('Selecione qual atividade você deseja compartilhar com a turma', true)} showTypeTag={false} />
                  }
                </TabPanel>
              )}
            {navLink.filteredEvaluation && (
              <TabPanel value={value} index={1}>
                {
                  !navLink.filteredEvaluation?.length
                    ? <div className={classes.errorWrapper}><BusinessError error={t('Opa, parece que não existem conteúdos cadastrados nessa seção!', true)} /></div>
                    : <RenderedList filtered={navLink.filteredEvaluation ?? []} title={title} description={t('Selecione qual avaliação você deseja compartilhar com a turma', true)} showTypeTag={false} />
                }
              </TabPanel>
            )}
          </React.Fragment>)
      }
    </TabsUnstyled>
  )
}
