import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { Text2, Icon } from 'components/design-system'
import useStyles from '../styles'
import { useStore } from 'store'
import { IUserSchoolProfileTypeEnum } from 'services/types'

interface IHeaderTypes {
  dropDown: boolean
  setDropDown: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header = ({ dropDown, setDropDown }: IHeaderTypes) => {
  const { profile } = useStore()
  const profileType = profile?.type
  const classes = useStyles({ profileType: profileType })
  const { t } = useTranslation()

  const dataTestIdOpen = profileType === IUserSchoolProfileTypeEnum.teacher ? 'activities_to_correct_open' : 'activities_to_answer_open'
  const dataTestIdClosed = profileType === IUserSchoolProfileTypeEnum.teacher ? 'activities_to_correct_close' : 'activities_to_answer_close'
  const headerTitle = profileType === IUserSchoolProfileTypeEnum.teacher ? 'Para corrigir' : 'Para responder'

  const OpenCloseButtons = () => (
    <button className={classes.titleDrop} onClick={() => setDropDown(!dropDown)} data-testid={dropDown ? dataTestIdClosed : dataTestIdOpen} id='see_all_mobile'>
      <p>{dropDown ? t('Esconder') : t('Mostrar')}</p>
      {dropDown
        ? <Icon titleAccess={t('Esconder')} size='medium'>
          <KeyboardArrowUpOutlinedIcon />
        </Icon>
        : <Icon titleAccess={t('Mostrar')} size='medium'>
          <KeyboardArrowDownOutlinedIcon />
        </Icon>
      }
    </button>
  )
  return (
    <Grid item className={classes.headerContainer}>
      <Text2
        fontSize={'md'}
        fontWeight={'semibold'}
        lineHeight={'sm'}
        mobile={'sm'}
        mobilelineheight={'xs'}
        neutral={'dark20'}
      >
        {t(headerTitle)}
      </Text2>
      <Grid className={classes.titleDropContainer}>
        <OpenCloseButtons />
      </Grid>
    </Grid>
  )
}
