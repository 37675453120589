import React from 'react'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { useStore } from 'store'

export interface IOnlyProfileProps {
  profileTypes: IUserSchoolProfileTypeEnum[]
}

export const OnlyProfile: React.FC<IOnlyProfileProps> = ({ children, profileTypes }) => {
  const { profile } = useStore()
  const profileType = profile?.type

  if (profileType) {
    if (profileTypes.includes(profileType)) {
      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment />
  )
}

export default OnlyProfile
