import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { useResetAtom } from 'jotai/utils'
import { searchTermReportsAtom, sortByReportsAtom } from '../../atomStore'

import { toast } from 'components/design-system/Toast/manager'
import {
  ReportsListLoading,
  ReportsListReady,
  ReportsListHeader,
  ReportsListHeaderLoading
} from './components'

import { useCurricularStore, useStore } from 'store'

import { IStudentsListPerformance } from 'services/types'
import { getStudentsListPerformance } from 'services/cloe-reports'

enum PageStateMachineEnum {
  IDLE = 'IDLE',
  FETCHING = 'FETCHING',
  LOADING = 'LOADING',
  READY = 'READY',
}

export enum AuxStateMachineEnum {
  IDLE = 'IDLE',
  NOT_FOUND = 'NOT_FOUND',
  SEARCH_NOT_FOUND = 'SEARCH_NOT_FOUND',
  READY_TO_LOAD_MORE = 'READY_TO_LOAD_MORE'
}

export interface IStudentsListData {
  data: IStudentsListPerformance | null
  error: string | null
}

interface IFetchClassReportsProps {
  pageCount: number
  studentsListData: IStudentsListData
  isLoading?: boolean
  search?: string
  orderBy?: string
}

export const ReportsList: React.FC = () => {
  const StudentsListDataInitialState: IStudentsListData = {
    data: null,
    error: null
  }

  const { profile } = useStore()
  const { currentDiscipline, classId } = useCurricularStore()
  const { t } = useTranslation()

  const [pageStateMachine, setPageStateMachine] = useState<PageStateMachineEnum>(PageStateMachineEnum.IDLE)
  const [auxStateMachine, setAuxStateMachine] = useState<AuxStateMachineEnum>(AuxStateMachineEnum.IDLE)
  const [studentsListData, setStudentsListData] = useState<IStudentsListData>(StudentsListDataInitialState)
  const [page, setPage] = useState(0)

  const [searchTermAtom, setSearchTermAtom] = useAtom(searchTermReportsAtom)
  const [sortByAtom, setSortByAtom] = useAtom(sortByReportsAtom)
  const resetSearchTermAtom = useResetAtom(searchTermReportsAtom)
  const resetSortByAtom = useResetAtom(sortByReportsAtom)

  const PAGE_SIZE = 20

  useEffect(() => {
    reset()
  }, [currentDiscipline])

  const tryAgain = async () => {
    await fetchClassReports({ pageCount: page, studentsListData, isLoading: false })
  }

  const reset = async () => {
    setPage(0)
    resetSearchTermAtom()
    resetSortByAtom()
    setStudentsListData(StudentsListDataInitialState)
    await fetchClassReports({ pageCount: 0, studentsListData: StudentsListDataInitialState, isLoading: true })
  }

  const loadMore = async () => {
    await fetchClassReports({ pageCount: page + 1, studentsListData, isLoading: false })
    setPage(oldPage => oldPage + 1)
  }

  const searchCallback = async (search: string) => {
    setSearchTermAtom(search)
    await fetchClassReports({ pageCount: 0, studentsListData: StudentsListDataInitialState, isLoading: false, search })
  }

  const sortByCallback = async (orderBy: string) => {
    setSortByAtom(orderBy)
    await fetchClassReports({ pageCount: 0, studentsListData: StudentsListDataInitialState, isLoading: false, orderBy })
  }

  const fetchClassReports = async ({ pageCount, studentsListData, isLoading, search, orderBy }: IFetchClassReportsProps) => {
    setPageStateMachine(isLoading ? PageStateMachineEnum.LOADING : PageStateMachineEnum.FETCHING)

    try {
      if (!currentDiscipline?.id || !classId || !profile?.school?.id) {
        throw new Error('Page info was not found')
      }

      const offset = pageCount * PAGE_SIZE

      const response = await getStudentsListPerformance({
        schoolId: profile?.school?.id,
        classId,
        disciplineId: Number(currentDiscipline.id),
        limit: PAGE_SIZE + 1,
        offset,
        search: search ?? searchTermAtom,
        sortBy: orderBy ?? sortByAtom
      })

      if (!response.success) {
        if (pageCount === 0) {
          toast.handler({
            content: 'Não foi possível carregar mais dados, tente novamente mais tarde',
            duration: 5000,
            severity: 'warning'
          })
        }

        throw new Error(response.message)
      }

      const newStudentsListData = response.data

      if (newStudentsListData.result.length >= PAGE_SIZE + 1) {
        setAuxStateMachine(AuxStateMachineEnum.READY_TO_LOAD_MORE)
      } else {
        setAuxStateMachine(AuxStateMachineEnum.IDLE)
      }

      if (!newStudentsListData.result.length) {
        if (search ?? searchTermAtom) {
          setAuxStateMachine(AuxStateMachineEnum.SEARCH_NOT_FOUND)
        } else if (pageCount === 0) {
          setAuxStateMachine(AuxStateMachineEnum.NOT_FOUND)
        } else {
          return
        }
      }

      if (newStudentsListData.result.length > PAGE_SIZE) {
        newStudentsListData.result.pop()
      }

      newStudentsListData.result = [...(studentsListData.data?.result ?? []), ...(newStudentsListData?.result ?? [])]

      setStudentsListData({ data: newStudentsListData, error: null })
    } catch (err: any) {
      setStudentsListData({ data: null, error: t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página') })
    } finally {
      setPageStateMachine(PageStateMachineEnum.READY)
    }
  }

  const states = {
    IDLE: <></>,
    FETCHING: (
      <>
        <ReportsListHeader studentsListData={studentsListData} tryAgainCallback={tryAgain} searchCallback={searchCallback} sortByCallback={sortByCallback} />
        <ReportsListLoading />
      </>
    ),
    LOADING: (
      <>
        <ReportsListHeaderLoading />
        <ReportsListLoading />
      </>
    ),
    READY: (
      <>
        <ReportsListHeader studentsListData={studentsListData} tryAgainCallback={tryAgain} searchCallback={searchCallback} sortByCallback={sortByCallback} />
        <ReportsListReady studentsListData={studentsListData} loadMore={loadMore} auxState={auxStateMachine} />
      </>
    )
  }

  const renderStateMachine = () => states[pageStateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
