import { styled } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const Title = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.font.fontSize.md,
    lineHeight: theme.font.lineHeight.xs
  },
  color: theme.colorBrand.medium,
  fontSize: theme.font.fontSize.lg,
  fontWeight: theme.font.fontWeight.medium,
  lineHeight: theme.font.lineHeight.md,
  alignSelf: 'center'
}))

export default makeStyles((theme: Theme) => createStyles({
  colapseTitleArea: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    maxWidth: '600px'
  }
}))
