import { IApiResponse } from 'services/types'
import { GET_MESSAGES, SEND_MESSAGE } from './CONSTANTS'
import { request } from 'services/base'
import { IChatAIMessageContextType } from 'services/chat-ai'
import { IMessageResponse, ISendMessageRequest } from 'services/types/messages'

export const getMessages = async (context: IChatAIMessageContextType, studentId: number, classId: number, disciplineId: number, sort: 'ASC' | 'DESC', limit: number, page: number): Promise<IApiResponse<IMessageResponse>> =>
  await request({
    url: GET_MESSAGES(context, studentId, classId, disciplineId, sort, limit, page),
    method: 'GET'
  })

export const sendMessage = async (context: IChatAIMessageContextType, params: ISendMessageRequest): Promise<IApiResponse<any>> =>
  await request({
    url: SEND_MESSAGE(context),
    method: 'POST',
    data: params
  })
