import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    contentUnitStatus: {
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.medium,
      borderRadius: theme.borderRadius.xs,
      textAlign: 'center',
      padding: `${theme.spacingInline.quarck} ${theme.spacingInline.nano}`,

      '&.IDLE, &.LOADING': {
        backgroundColor: theme.colors.neutral.light40,
        color: theme.colors.neutral.lightBase
      },
      '&.INPROGRESS': {
        backgroundColor: theme.colors.information.feedbackLight,
        color: theme.colors.information.feedbackDarkest
      },
      '&.DONE': {
        backgroundColor: theme.colors.positive.feedbackLight,
        color: theme.colors.positive.feedbackDarkest
      }
    }
  })
)
