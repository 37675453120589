import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import emptyStateScreenStudents from 'assets/empty-state-screen-students.svg'

export default makeStyles((theme: Theme) => createStyles({
  studentsContainer: {
    width: '100%',
    paddingBlock: theme.spacingInline.md,
    height: '100%',
    overflow: 'hidden',
    background: theme.colors.neutral.lightBase,
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,
    marginBottom: theme.spacingInline.xxxs,
    boxShadow: theme.shadowLevels.level5,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      boxShadow: 'none',
      paddingTop: '100px'
    }
  },

  contentScroll: {
    height: '100%',
    overflowX: 'auto',
    paddingBlock: theme.spacingInline.xxs,
    ...theme.scroll
  },

  titleContainer: {
    marginInline: theme.spacingInline.xxs,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBlock: theme.spacingInline.nano
    }
  },

  title: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xxs
    },
    '& > p': {
      margin: 0
    }
  },

  image: {
    backgroundImage: `url(${emptyStateScreenStudents})`,
    marginBlock: theme.spacingInline.md,
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat'
  },

  button: {
    marginBlock: `${theme.spacingInline.xxxs} !important`
  },

  boxButton: {
    display: 'inline-grid'
  },

  image404: {
    backgroundImage: 'url(\'/images/cat-offline.png\')',
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat'
  },

  titleEmptyDisciplines: {
    fontSize: theme.font.fontSize.xxxxl,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    fontFamily: theme.font.fontFamily
  },

  subtitleEmptyDisciplines: {
    display: 'block'
  },

  containerEmptyDisciplines: {
    textAlign: 'center',
    paddingBottom: theme.spacingInset.lg
  },

  titleDrop: {
    display: 'none',
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    '& > p': {
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    }
  },

  icon: {
    [theme.breakpoints.down('sm')]: {
      transition: 'all ease-in-out 0.3s',
      '&.active': {
        transform: 'rotate3d(1, 0, 0, 180deg)',
        marginTop: '-10px'
      }
    }
  },

  tagsContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.xs,
    marginInline: theme.spacingInline.xxs,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontSize: theme.font.fontSize.sm,
      flexFlow: 'inherit',
      overflowX: 'auto',
      transition: 'all ease-in-out 0.2s',
      fontWeight: theme.font.fontWeight.semibold,
      '&.active': {
        flexFlow: 'wrap'
      }
    }
  },

  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano,
    cursor: 'pointer',

    '&.disabled': {
      cursor: 'not-allowed'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacingInset.md,
      alignItems: 'center',
      display: 'flex'
    }
  },

  titleContent: {
    display: 'flex',
    color: theme.colors.neutral.lightBase,
    alignItems: 'center',
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.bold,

    '& > svg': {
      margin: theme.spacingInline.nano
    },
    '& > p': {
      color: theme.colorBrand.medium,
      marginInline: '5px'
    }
  },

  itemTag: {
    backgroundColor: theme.colorBrand.medium,
    margin: theme.spacingStack.nano,
    background: theme.colorBrand.medium,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadius.lg,
    width: theme.spacingStack.xs,
    height: theme.spacingStack.xs,
    display: 'flex',
    position: 'relative'
  },

  subTitleContent: {
    marginInline: theme.spacingInline.xs
  },

  cardContainer: {
    width: '100%',
    margin: `${theme.spacingInline.nano} 0px`,
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      marginInline: theme.spacingInline.nano,
      margin: theme.spacingInline.nano
    }
  },

  emptyStateDownloaded: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      textAlign: 'center',
      fontSize: theme.font.fontSize.sm,
      color: theme.colors.neutral.dark30,
      marginTop: '30px'
    }
  },

  searchContainer: {
    gap: theme.spacingInline.nano,
    flexDirection: 'column-reverse !important' as 'column-reverse',
    marginBottom: theme.spacingInline.xxxs,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important' as 'row',
      marginBottom: 0,
      gap: theme.spacingInline.xxxs
    }
  }
}))
