import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {

    },
    boxModal: {
      position: 'fixed',
      top: '20px',
      right: '140px',
      width: 270,
      backgroundColor: theme.colors.neutral.lightBase,
      borderRadius: theme.borderRadius.sm,
      padding: '16px',
      overflow: 'auto',
      maxHeight: '80%',
      [theme.breakpoints.down('sm')]: {
        position: 'initial',
        top: '0px',
        width: 'auto',
        borderRadius: '0px',
        height: '100%',
        overflow: 'scroll',
        maxHeight: 'none'
      }
    },
    tag: {
      margin: theme.spacingStack.quarck,
      [theme.breakpoints.down('sm')]: {
        maxHeight: theme.spacingInset.md,
        alignItems: 'center',
        display: 'flex'
      }
    },
    tagsContainer: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        fontSize: theme.font.fontSize.sm,
        flexFlow: 'inherit',
        overflowX: 'auto',
        transition: 'all ease-in-out 0.2s',
        fontWeight: theme.font.fontWeight.semibold,
        '&.active': {
          flexFlow: 'wrap'
        }
      }
    },
    classContainer: {
      display: 'block',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    iconChangeClass: {
      display: 'flex',
      cursor: 'pointer',
      paddingBlock: theme.spacingInset.xxs
    },
    classChangeTitle: {
      fontWeight: theme.font.fontWeight.bold,
      fontSize: theme.font.fontSize.sm,
      cursor: 'pointer'
    },
    containerInforamtion: {
      paddingBlock: theme.spacingInset.xs,
      paddingInline: theme.spacingInset.xxs
    },
    iconSvg: {
      fontSize: theme.font.fontSize.lg,
      color: theme.colorBrand.medium,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.lg
      }
    },
    classInforamtionTitle: {
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xxs,
      lineHeight: '100%'
    },
    classInforamtionText: {
      fontWeight: theme.font.fontWeight.bold,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark30
    },
    icon: {
      color: theme.colors.neutral.light40
    },
    classesContainer: {
      justifyContent: 'space-between'
    },
    classesDivider: {
      marginTop: '15px'
    },
    container: {
      display: 'inline-grid !important',
      height: '100%'
    },
    classesItens: {
      border: `solid ${theme.borderThickness.hairline}`,
      cursor: 'pointer',
      display: 'grid',
      marginTop: theme.spacingInset.xs,
      borderColor: theme.colors.neutral.light20,
      borderRadius: theme.borderRadius.sm,
      padding: theme.spacingInset.xs,
      width: '100%',
      overflow: 'hidden',
      '&.selected': {
        borderColor: theme.colorBrand.medium,
        border: `solid ${theme.borderThickness.thin}`
      }
    },
    classesItensTitle: {
      fontSize: theme.font.fontSize.xxs
    },
    classesItensText: {
      fontWeight: 'bold',
      fontSize: theme.font.fontSize.sm,
      color: theme.colors.neutral.dark30,
      '&.selected': {
        color: theme.colorBrand.medium
      }
    },
    classButton: {
      marginBlock: theme.spacingStack.nano,
      alignSelf: 'center'
    },
    classButtonItem: {
      width: '100%',
      '&.primary': {
        padding: '8px',
        marginBlock: theme.spacingStack.xxs
      }
    }
  })
)
