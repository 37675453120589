import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { IAnsweredFileProps } from './index'

export default makeStyles((theme: Theme) => createStyles({
  linkWrapper: {
    width: '100%',
    height: '100%'
  },
  fileWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.font.fontSize.xxxl,
    width: '100%',
    minHeight: (props: IAnsweredFileProps) => props.modalOpen ? '400px' : '150px',
    backgroundImage: ({ src }: { src: string }) => `url(${src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '& video': {
      width: '100%'
    },
    '& svg': {
      position: 'absolute',
      color: (props: IAnsweredFileProps) => props.modalOpen ? theme.colorBrand.medium : theme.colors.neutral.lightBase,
      fontSize: theme.font.fontSize.xxxl
    }
  },
  imageModal: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain'
  },
  audioContainer: {
    width: '100%',
    position: 'absolute',
    top: '45%',
    padding: `0 ${theme.spacingInset.sm}`
  }
}))
