import { useTranslation } from 'react-i18next'
import { Link } from 'components/design-system'
import useStyles from './style'
import { useHistory } from 'react-router-dom'
import { FORGOT_PASSWORD } from 'navigation/CONSTANTS'

export const ForgotPasswordButton = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const history = useHistory()

  const handleGoToForgotPasswordPage = () => {
    history.push(FORGOT_PASSWORD)
  }

  return (
    <Link className={styles.helpOptionLink} onClick={handleGoToForgotPasswordPage} data-testid='login_open_help_options'>
      {t('Esqueci a senha')}
    </Link>
  )
}
