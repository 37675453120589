import React from 'react'
import { Grid, Skeleton } from '@mui/material'

import useStyle from './styles'
import { BigNumber } from '..'

export const BigNumbersLoading: React.FC = () => {
  const classes = useStyle()

  return (
    <>
      <Skeleton variant='text' width={160} height={60} animation='wave' />

      <Grid
        container
        spacing={8}
        alignItems='flex-start'
        justifyContent='space-between'
        marginTop={3.5}
        mx='auto'
        className={classes.bigNumbesReportsContainer}
      >
        <BigNumber.Skeleton />
        <BigNumber.Skeleton />
        <BigNumber.Skeleton />
        <BigNumber.Skeleton />
      </Grid>
    </>
  )
}
