import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { Text } from 'components/design-system'
import { ClassSelector } from './components'

import useStyle from './style'

export const StudentProfileManagerMenu: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid container className={classes.container} id='ProfileManagerMenu-Student'>
      <Text className={classes.selectorSubtitle}> {t('Turma')}</Text>
      <ClassSelector />
    </Grid >
  )
}
