import { request } from '../base'
import { IClassRecordInsertResponse, IClassRecordsPaylodInsert, IClassRecordResponse } from '../types/classRecords'
import { INSERT_CLASS_RECORDS, CLASS_RECORD_ID } from './CONSTANTS'
import { IApiResponse } from '../types'

export const insertClassRecord = async (data: IClassRecordsPaylodInsert): Promise<IApiResponse<IClassRecordInsertResponse[]>> => {
  return await request({
    url: INSERT_CLASS_RECORDS(),
    method: 'post',
    data
  })
}

export const getClassRecordById = async (id: string): Promise<IApiResponse<IClassRecordResponse>> =>
  await request({
    url: CLASS_RECORD_ID(id),
    method: 'get'
  })

export const updateClassRecord = async (id: string, data: IClassRecordsPaylodInsert): Promise<IApiResponse<IClassRecordInsertResponse>> =>
  await request({
    url: CLASS_RECORD_ID(id),
    method: 'put',
    data: data
  })

export const deleteClassRecord = async (classRecordId: number | string): Promise<IApiResponse<boolean>> =>
  await request({
    url: CLASS_RECORD_ID(classRecordId),
    method: 'DELETE'
  })
