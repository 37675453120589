import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    height: 'auto',

    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      padding: '0px',
      paddingTop: '64px'
    }
  },
  buttonNoContentContainer: {
    display: 'inline-grid'
  },
  buttonBackToHomePage: {
    marginBlock: theme.spacingStack.xs
  },
  disciplineContainer: {
    padding: `${theme.spacingInset.md} 0px`,
    borderBottom: `1px solid ${theme.colors.neutral.light10}`
  },
  filterContainer: {
    borderBottom: `${theme.borderThickness.hairline} solid ${theme.colors.neutral.light10}`
  }
}))
