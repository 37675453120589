import { ActivityControlStatusEnum, IActivityResponse } from 'services/types'
import { Intro, Legend } from '../../style'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { Grid, useTheme } from '@mui/material'
import ContentLink from 'components/common/ContentLink'
import { ContentTypeEnum } from 'services/types/content'
import dayjs from 'dayjs'

interface ILiberated {
  evaluationsPending: IActivityResponse[]
  isCloeExpand: boolean
  contentId: string
}

export const Liberated = ({
  evaluationsPending,
  isCloeExpand,
  contentId
}: ILiberated) => {
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const theme = useTheme()
  const now = dayjs()

  return (
    <>
      <Intro>
        {t('Vamos colocar os conhecimentos em prática?')}
        <Grid item sx={{ pt: 2 }}>
          <Legend>
            {t('Aqui você encontra as avaliações liberadas pelo(a) professor(a) e que ainda não foram respondidas.')}
          </Legend>
        </Grid>
      </Intro>
      {
        <Grid container>
          <Grid item gap={theme.spacingInline.xxs} display='flex' xs={12} sx={{
            flexWrap: 'wrap',
            [theme.breakpoints.down('sm')]: {
              justifyItems: 'center'
            }
          }}>
            {
              evaluationsPending.map((e: IActivityResponse, i) => {
                const statusClosed = !e.activity_status?.has_answer && e.activity_status?.status !== ActivityControlStatusEnum.PENDING
                const isPending = e.activity_status?.status === ActivityControlStatusEnum.PENDING &&
                  now.isAfter(e?.schedule?.startDate)
                const isProgramed = e.activity_status?.status === ActivityControlStatusEnum.PENDING &&
                  now.isBefore(e?.schedule?.startDate)
                return (
                  <Grid sx={{
                    maxWidth: '284px',
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '100%',
                      minWidth: '100%'
                    }
                  }} key={i}>
                    <ContentLink
                      id={contentId?.toString()}
                      activityId={e.id?.toString()}
                      title={e.title ?? e.activity_name}
                      type={ContentTypeEnum.evaluation}
                      momentType={e.moment_type}
                      showTypeTag={false}
                      endDate={statusClosed ? undefined : e?.schedule?.endDate}
                      statusClosed={statusClosed}
                      isPending={isPending}
                      isProgramed={isProgramed}
                      isLocked={isProgramed}
                      suggestedApplicationType={e.suggested_application_type}
                      notAnswered={!e.activity_status?.has_answer && e.activity_status?.status !== ActivityControlStatusEnum.PENDING}
                      dataTestid='assessments_select_realeased_assessments'
                      useCloeExpandTranslation={isCloeExpand}
                      quantityQuestions={e.questions.length}
                    />
                  </Grid>)
              })
            }
          </Grid>
        </Grid>
      }
    </>
  )
}
