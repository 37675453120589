import React, { useEffect } from 'react'
import { useStore } from 'store'

export const HideCloeProMiddleware: React.FC = ({ children }) => {
  const { setShowCloePro } = useStore()

  useEffect(() => {
    setShowCloePro(false)
  }, [])

  return <>{children}</>
}
