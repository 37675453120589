import { Grid } from '@mui/material'

import { ReactComponent as CloeAvatar } from 'assets/cloe-avatar-sentada.svg'

import useStyle from './style'

type EmptyStateComponentProps = {
  children?: React.ReactNode
  errorMessage: string
}

export const EmptyStateComponent = ({ children, errorMessage }: EmptyStateComponentProps) => {
  const classes = useStyle()

  return (
    <Grid container>
      <Grid item xs={12} mt={4} mb={4} display='flex' justifyContent='center'>
        <CloeAvatar />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.errorMessage}>
          {errorMessage}
        </div>
      </Grid>
      {children}
    </Grid>
  )
}

export default EmptyStateComponent
