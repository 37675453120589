import MUiModal from '@mui/material/Modal'
import { Box, ModalProps } from '@mui/material'
import useStyles from './style'

export interface IModalProps extends ModalProps {
  size?: 'sm' | 'md'
}

export const Modal: React.FC<IModalProps> = ({ size = 'md', children, ...props }) => {
  const classes = useStyles()

  return (
    <MUiModal
      {...props}
      className={`${props.className as string ?? ''} ${classes.root}`}
    >
      <Box className={`size-${size}`}>
        {children}
      </Box>
    </MUiModal>
  )
}
