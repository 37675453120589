import { useLayoutEffect, useState } from 'react'
import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { IDisciplineResponse, DisciplineCodeEnum } from 'services/types'
import { ContentUnitModal } from './ContentUnitModal'
import {
  contentUnitCardAtom,
  scheduleAtom,
  expeditionDisciplinesAtom,
  updateContentSelectAtom
} from './atomStore'
import { useAtom } from 'jotai'
import { ExpeditionModal } from './ExpeditionModal'
import { ContentUnitSelect } from './ContentUnitSelect'
import { ContentSelectContainer as ContentSelect } from './ContentSelect'
import { DisciplineSelect } from './DisciplineSelect'
import { DateSelect } from './DateSelect'
import { CardInfo } from './CardInfo'
import { SendSchedule } from './SendSchedule'
import { RecipientsSelectContainer as RecipientsSelect } from './RecipientsSelect'
import { RecipientsModalContainer as RecipientsModal } from './RecipientsModal'
import { useParams } from 'react-router-dom'
import { IGradeTypeCodeEnum } from 'services/types/grade'
import { MY_CLOE_CONTEXT, myCloeContextAtom } from '../../atomStore'
import { ActivityInfoSelect } from './ActivityInfoSelect'
import { ContentTypeEnum } from 'services/types/content'
import { ActivityQuestionsSelectModal } from './ActivityQuestionsSelectModal'

interface IScheduleCreatorProps {
  discipline: IDisciplineResponse[]
  currentDisciplineId: string
  type?: 'original_content' | 'existing_content'
}

export const ScheduleCreator = ({ discipline, type }: IScheduleCreatorProps) => {
  const [selectedDiscipline, setSelectedDiscipline] = useState<IDisciplineResponse | undefined>()

  // atom
  const [schedule, setSchedule] = useAtom(scheduleAtom)
  const [, setContentUnitCard] = useAtom(contentUnitCardAtom)
  const [contentSelect, updateContentSelect] = useAtom(updateContentSelectAtom)
  const [, setExpeditionDisciplines] = useAtom(expeditionDisciplinesAtom)
  const { gradeTypeCode } = useParams<{ gradeTypeCode: string }>()
  const [myCloeContext] = useAtom(myCloeContextAtom)

  // styles
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const isMaterialVision = schedule?.type === 'original_content' && !!schedule.content_unit

  const handleSelectedDisciplineCallback = (selectedDiscipline: IDisciplineResponse | undefined) => {
    setSelectedDiscipline(selectedDiscipline)
  }

  const isCloeExpand = selectedDiscipline?.code.includes(DisciplineCodeEnum.BI)

  const isActivityRelated = !!(schedule?.activity && schedule?.id) || (contentSelect ?? []).some(content => content.type && [ContentTypeEnum.activity, ContentTypeEnum.evaluation].includes(content.type))

  const showContentSelectComponent = () => {
    if ((gradeTypeCode !== IGradeTypeCodeEnum.EI) ||
      (gradeTypeCode === IGradeTypeCodeEnum.EI && isCloeExpand)
    ) {
      return <ContentSelect />
    }
  }

  useLayoutEffect(() => {
    return () => {
      if (myCloeContext === MY_CLOE_CONTEXT.HOME) {
        setContentUnitCard(null)
      }
      setSchedule(null)
      updateContentSelect(null)
      setExpeditionDisciplines(null)
    }
  }, [])

  return (
    <>
      <ActivityQuestionsSelectModal />
      <ExpeditionModal discipline={discipline} />
      <ContentUnitModal />
      <RecipientsModal />
      <Grid
        container
        sx={{
          padding: theme.spacingSquish.nano,
          display: 'flex',
          gridTemplateColumns: '1fr',
          rowGap: '16px'
        }}
      >
        <span className={classes.title}>
          {t('Evento')}
        </span>
        <ContentUnitSelect />
        {
          schedule?.content_unit && type !== 'original_content' && schedule?.type !== 'existing_content' && showContentSelectComponent()
        }
        <DisciplineSelect disciplines={discipline} onCallback={handleSelectedDisciplineCallback} />
      </Grid>
      {
        isActivityRelated && <ActivityInfoSelect />
      }
      <DateSelect isMaterialVision={isMaterialVision} />
      {
        !isActivityRelated && (
          <>
            <RecipientsSelect />
            <CardInfo type={type} isMaterialVision={isMaterialVision} />
          </>
        )
      }
      <SendSchedule isMaterialVision={isMaterialVision} />
    </>
  )
}
