import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  readingContainer: {
    gap: theme.spacingInline.xxs,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacingInline.xxs,
    paddingTop: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflowWrap: 'anywhere',
      padding: 0
    }
  },
  sectionIndex: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '24px',
    letterSpacing: '0.14px'
  },
  title: {
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxl,
    letterSpacing: theme.font.spacing.distant,
    lineHeight: '48px',
    margin: '0 0 24px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.lg,
      lineHeight: '32px',
      letterSpacing: '0.24px',
      margin: 0
    }
  },
  printButton: {
    border: `1px solid ${theme.colorBrand.medium}`,
    borderRadius: `${theme.borderRadius.circular} !important`,
    height: '40px !important',
    width: '40px !important',
    boxSizing: 'border-box',
    padding: '0 !important',
    minWidth: '0 !important',
    fontSize: `${theme.font.fontSize.xxs} !important`,
    '& span': {
      marginLeft: '14px',
      // mobile
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    // mobile
    [theme.breakpoints.down('md')]: {
      '&.primary': {
        width: '32px',
        minWidth: '0',
        height: '32px',
        '& svg': {
          fontSize: '18px'
        }
      }
    }
  },
  contentButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacingInline.xxs,
    paddingBottom: 0,

    '&.first': {
      justifyContent: 'flex-end'
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px',
      gap: theme.spacingInline.xxxs
    }
  },
  contentButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: `${theme.font.fontSize.xs} !important`,
      padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs} !important`
    }
  },
  expandButton: {
    width: 'max-content',
    background: theme.colorBrand.medium,
    color: theme.colors.neutral.lightBase,

    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,

    cursor: 'pointer',
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs}`,
    zIndex: 1,

    display: 'flex',
    alignItems: 'center',

    position: 'fixed',
    bottom: 170,

    gap: theme.spacingInset.xxs,
    transform: 'translate(-50%, 0%) rotate(-90deg)',
    WebkitTransformOrigin: '50%  51%',

    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,

    '& svg': {
      fontSize: theme.font.fontSize.sm
    },

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  instructionalDesktop: {
    position: 'relative',
    transition: 'all ease-in-out .2s',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    paddingLeft: `${theme.spacingInset.xs} !important`,

    [theme.breakpoints.down('md')]: {
      display: 'none',

      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',

      zIndex: '9981',

      padding: '0 !important'
    }
  }
}))
