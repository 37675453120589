import React, { useCallback, useEffect, useState } from 'react'
import { Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import {
  useTheme, Grid, Box
} from '@mui/material'
import useStyles from './style'
import { useCurricularStore, useStore } from 'store'
import { getStudentPerformance } from 'services/cloe-reports'
import { IStudentPerformance, ISubscriptedTeacherProfile, IUserSchoolProfileTypeEnum } from 'contentCacheManager'
import { Disciplines } from 'components/store/Disciplines'
import { BigNumbersStudent } from './components/BigNumbersStudent'
import { DetailedPerformance, ParentalMessage, StudentCards } from './components'
import { useReport } from 'pages/hooks'
import { LastUpdate } from './components/LastUpdate'
import { getClassSubscriptedProfiles } from 'services/class-subscription'
import { TeacherCard } from 'components/common/reports/BigNumbersReady/components'
import FeatureToggleBySchool from 'components/feature-toggle-by-school'

export const ResultsTracking: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const { profile, session } = useStore()
  const { isLoadingDate, lastUpdatedDate } = useReport()

  // state
  const { currentDiscipline, classId } = useCurricularStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [studentPerformance, setStudentPerformance] = useState<IStudentPerformance | undefined>(undefined)
  const [classTeachers, setClassTeachers] = useState<ISubscriptedTeacherProfile[]>([])

  const fetchStudentPerformance = async () => {
    if (profile?.school?.id && classId && currentDiscipline?.id && profile?.id) {
      const payload = {
        schoolId: profile?.school?.id,
        classId: Number(classId),
        disciplineId: Number(currentDiscipline.id),
        studentProfileId: Number(profile?.id)
      }

      const resStudentPerformance = await getStudentPerformance(payload)

      if (resStudentPerformance?.data) {
        setStudentPerformance(resStudentPerformance?.data)
      } else {
        setStudentPerformance(undefined)
      }

      setIsLoading(false)
    }
  }

  const getSubscribedTeachers = async () => {
    const teacherResponse = await getClassSubscriptedProfiles({ classId: Number(classId), filterByProfile: IUserSchoolProfileTypeEnum.teacher })
    setClassTeachers(Array.from(new Map(teacherResponse.data.teacher.map(item => [item.user_id, item])).values()))
  }

  const renderDisciplineTeacher = useCallback(() => {
    if (!currentDiscipline || !classTeachers) {
      return <></>
    }
    const disciplineTeachers = classTeachers.filter(({ taught_disciplines }) => taught_disciplines.includes(Number(currentDiscipline.id)))

    if (!disciplineTeachers?.length) {
      return <></>
    }

    return (
      <Grid container item xs={12} className={classes.classTeachersContainer}>
        {disciplineTeachers.map(teacher => (
          <Box>
            <TeacherCard.Item teacherName={`${teacher.name} ${teacher.surname}`} />
          </Box>
        ))}
      </Grid>
    )
  }, [currentDiscipline, classTeachers])

  useEffect(() => {
    const element = document.getElementById('student-report-back-button')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    if (currentDiscipline && Number(currentDiscipline.id) !== 0) {
      setIsLoading(true)
      fetchStudentPerformance()
    }
  }, [currentDiscipline])

  useEffect(() => {
    getSubscribedTeachers()
  }, [classId])

  return (
    <div className={classes.container}>
      <Grid
        container
        position='relative'
        id='student-report-back-button'
        sx={{
          padding: {
            sm: theme.spacingInset.sm
          },
          paddingTop: '0 !important'
        }}>
        <StudentCards />

        <Grid container item xs={12} rowSpacing={1}>
          <Grid container direction='column' marginTop={3.5} xs={12}>
            <Text2
              fontSize='sm'
              fontWeight='semibold'
              lineHeight='xs'
              mobile='sm'
              customColor={theme.colors.neutral.dark20}
            >
              {t('Componente curricular')}:
            </Text2>

            <Disciplines dataTestid='student_menu_followup_select_curricular_component' showAlertOnError />
          </Grid>

          <Grid container item xs={12} rowSpacing={2} marginTop={1}>
            {renderDisciplineTeacher()}
          </Grid>

          <Grid item xs={12} marginTop={1}>
            {(lastUpdatedDate ?? isLoadingDate) && (
              <LastUpdate isLoading={isLoadingDate} />
            )}
          </Grid>
          <Grid container marginBottom={5}>
            <BigNumbersStudent
              isLoading={isLoading}
              studentName={session?.name ?? ''}
              studentPerformance={studentPerformance}
            />
          </Grid>
          <FeatureToggleBySchool schoolId={profile?.school?.id ?? 0}>
            <Grid container>
              <ParentalMessage />
            </Grid>
          </FeatureToggleBySchool>
        </Grid>

        {currentDiscipline &&
          <DetailedPerformance
            currentDisciplineId={currentDiscipline?.id}
            isLoading={isLoading}
          />
        }
      </Grid>
    </div>
  )
}
