import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ICoverResponse } from 'services/types'

export interface IStartingPointStyleProps {
  imageUrl?: ICoverResponse
}

export default makeStyles((theme: Theme) => createStyles({
  contentUnitCover: {
    borderRadius: theme.borderRadius.md,
    backgroundPosition: 'center',
    backgroundImage:
      ({ imageUrl }: IStartingPointStyleProps) =>
        imageUrl?.formats?.small?.url
          ? `url(${imageUrl?.formats?.small?.url})`
          : `url(${imageUrl?.formats?.medium?.url})`,

    backgroundSize: 'cover',
    width: '100%',
    aspectRatio: '312/120',

    // mobile
    [theme.breakpoints.up('sm')]: {
      backgroundImage:
        ({ imageUrl }: IStartingPointStyleProps) =>
          imageUrl?.formats?.large
            ? `url(${imageUrl?.formats?.large?.url})`
            : `url(${imageUrl?.formats?.medium?.url})`
    }
  },
  downloadButton: {
    color: `${theme.colorBrand.medium} !important`,
    fill: `${theme.colorBrand.medium} !important`,
    borderColor: `${theme.colorBrand.medium} !important`
  }
}))
