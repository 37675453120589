import { PROMPT } from '../CONSTANTS'
import { ICurricularData } from '../types'

const curricularProps = ['curricular_component', 'first_name', 'student_average_grade', 'segment', 'student_situation'] as Array<keyof ICurricularData>
const studentSituationText = {
  1: 'Informe que as iniciativas da escola serão analisar avaliações com notas baixas para identificar habilidades que o estudante precisa desenvolver, indicar exercícios de reforço e materiais complementares. Peça que os responsáveis conversem com o estudante para entender se existe algum problema atrapalhando a rotina escolar e incentivem o estudante a dedicar mais tempo aos estudos e a tirar suas dúvidas com os professores. O principal objetivo do texto é fortalecer a parceria entre família e escola no acompanhamento deste estudante.',
  2: 'Informe que as iniciativas da escola serão observar temas de interesse deste estudante e incentivá-lo a aprofundar investigações. Sugira que o responsáveis estimulem esse estudante a dedicar mais tempo aos estudos e tirar suas dúvidas com os professores. O principal objetivo do texto é apontar os próximos passos do estudante.',
  3: 'Parabenize o desempenho e sugira que os responsáveis incentivem esse estudante a se aprofundar em temas de interesse a auxiliar colegas de turma.',
  '-1': ''
}

const generateFormattedPrompt = (curricularData: ICurricularData): string => {
  if (typeof curricularData.student_situation === 'number' && curricularData.student_situation > 0) {
    curricularData.student_situation = studentSituationText[curricularData.student_situation]
  } else {
    curricularData.student_situation = ''
  }
  return curricularProps.reduce((acc, replaceableWord) => {
    const regex = new RegExp(`{{${replaceableWord}}}`, 'g')
    const replaceValue = curricularData[replaceableWord] as string
    const formattedText = acc.replace(regex, replaceValue)

    return formattedText
  }, PROMPT)
}

export default generateFormattedPrompt
