/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, createRef } from 'react'
import { IContentUnitResponse, DisciplineCodeEnum, statusSortActionsEnum } from 'services/types'
import { BusinessError } from 'navigation/BusinessError'
import { useTranslation, Trans } from 'react-i18next'
import { getContentUnitByClassDisciplinev2 } from 'services/content-unit'
import { Grid } from '@mui/material'
import useStyle from './style'
import { HeaderTabs } from 'pages/Dashboard/teacher/Header'
import { Search, Text2, Button } from 'components/design-system'
import Loading from './components/Loading'
import { cloneObject } from 'utils/array'
import ContentUnits from './components/ContentUnits'
import NotFoundSearchContentUnits from './components/NotFoundSearchContentUnits'
import Analytics from 'utils/analytics'
import { useStore, useCurricularStore } from 'store'
import { Disciplines } from 'components/store/Disciplines'
import { useAtom } from 'jotai'
import { searchContentUnitSkillsAtom } from './atomStore'
import { useLmsPlatform } from 'navigation/hooks/useLmsPlatform/useLmsPlatform'
import { sortContentUnits } from './helpers/sortContentUnits'
import { sleep } from 'contentCacheManager/utils'
import { StatusSelector } from 'components/common'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shoetest = require('shoetest')

enum StateListContentUnitEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  CONTENT_UNITS = 'CONTENT_UNITS',
  NOT_FOUND_CONTENT_UNITS = 'NOT_FOUND_CONTENT_UNITS',
  NOT_FOUND_SEARCHED_CONTENT_UNITS = 'NOT_FOUND_SEARCHED_CONTENT_UNITS'
}

interface ListContentUnitProps {
  navigation: {
    gradeCode: string
    gradeTypeCode: string
    classId: number
  }
}

export const ListContentUnit: React.FC<ListContentUnitProps> = ({ navigation }) => {
  const [state, setState] = useState<StateListContentUnitEnum>(StateListContentUnitEnum.LOADING)
  const { currentDiscipline, disciplines, setCurrentDiscipline } = useCurricularStore()
  const [contentUnits, setContentUnits] = useState<IContentUnitResponse[]>([])
  const [deltaContentUnits, setDeltaContentUnits] = useState<IContentUnitResponse[]>([])
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const { profile } = useStore()

  const [searchContentUnitSkills, setSearchContentUnitSkills] = useAtom(searchContentUnitSkillsAtom)

  const { t } = useTranslation()
  const classes = useStyle()

  const searchRef = createRef<any>()

  const { getLinkInitialTeacherTrainingModule, getLinkCloeExpandTeacherModule } = useLmsPlatform(t, profile)

  const getContentUnits = async () => {
    setContentUnits([])
    searchRef.current?.clear()
    setState(StateListContentUnitEnum.LOADING)
    setSearchPhrase('')

    if (currentDiscipline?.id) {
      const response = await getContentUnitByClassDisciplinev2(
        navigation.classId,
        parseInt(currentDiscipline.id.toString())
      )
      if (response.success) {
        const sortedContentUnits = sortContentUnits('ASC', cloneObject(response.data))
        setContentUnits(sortedContentUnits)
        setDeltaContentUnits(cloneObject(response.data))
        setState(StateListContentUnitEnum.CONTENT_UNITS)
      } else {
        setState(StateListContentUnitEnum.NOT_FOUND_SEARCHED_CONTENT_UNITS)
      }
    }
  }

  const handleContentUnitsSort = async (sortOption: statusSortActionsEnum) => {
    setState(StateListContentUnitEnum.LOADING)
    const sortedContentUnits = sortContentUnits(sortOption, contentUnits)
    setDeltaContentUnits(sortedContentUnits)
    setContentUnits(sortedContentUnits)
    await sleep(300)
    setState(StateListContentUnitEnum.CONTENT_UNITS)
  }

  const search = (pattern: string) => {
    let analyticsTerm = 'search_term_found'

    setState(StateListContentUnitEnum.LOADING)
    setSearchPhrase(pattern)

    const newContentsUnits = cloneObject(searchContentUnitSkills.length ? searchContentUnitSkills : deltaContentUnits).filter(
      (cur: IContentUnitResponse) => {
        if (shoetest.test(pattern, cur.title)) return true
        if (
          (cur?.key_words ?? []).some(keyWord =>
            shoetest.test(pattern, keyWord)
          )
        ) {
          return true
        }

        if (
          (cur?.bnccSkill ?? []).some(bnccSkill =>
            shoetest.test(pattern, bnccSkill.code) || shoetest.test(pattern, bnccSkill.name)
          )
        ) {
          return true
        }

        if (
          (cur?.skills ?? []).some(skills =>
            shoetest.test(pattern, skills.code) || shoetest.test(pattern, skills.name)
          )
        ) {
          return true
        }

        return false
      }
    )

    setContentUnits(newContentsUnits)
    setState(StateListContentUnitEnum.CONTENT_UNITS)
    if (!newContentsUnits.length) {
      analyticsTerm = 'search_term_not_found'
      setState(StateListContentUnitEnum.NOT_FOUND_SEARCHED_CONTENT_UNITS)
    }

    Analytics.recordEventClick({
      name: analyticsTerm,
      attributes: {
        ...profile?.analytics,
        class_id: navigation.classId,
        search_term: pattern
      }
    })
  }

  const clear = () => {
    setSearchPhrase('')
    setState(StateListContentUnitEnum.LOADING)
    setContentUnits(deltaContentUnits)
    setState(StateListContentUnitEnum.CONTENT_UNITS)
  }

  useEffect(() => {
    if (disciplines && !currentDiscipline) {
      setCurrentDiscipline(disciplines[0])
    }
  }, [])

  useEffect(() => {
    void getContentUnits()
  }, [currentDiscipline])

  useEffect(() => {
    if (deltaContentUnits.length) {
      // É necessario iniciar o state com contentUnits para realizar o merge das habilidades após efetuar a chamada
      setSearchContentUnitSkills(deltaContentUnits)
    }
  }, [deltaContentUnits])

  const handleGoToTrainingArea = async () => {
    const isCloeExpand = currentDiscipline?.code.includes(DisciplineCodeEnum.BI)

    if (isCloeExpand) {
      return await getLinkCloeExpandTeacherModule()
    }

    return await getLinkInitialTeacherTrainingModule()
  }

  const render: { [key in StateListContentUnitEnum]: any } = {
    IDLE: <Loading />,
    LOADING: <Loading />,
    CONTENT_UNITS: (
      <ContentUnits
        contentUnits={contentUnits}
        navigation={navigation}
        highlight={searchPhrase}
      />
    ),
    NOT_FOUND_CONTENT_UNITS: (
      <BusinessError
        error={t('Erro ao carregar unidades de conteúdo da disciplina.')}
      />
    ),
    NOT_FOUND_SEARCHED_CONTENT_UNITS: <NotFoundSearchContentUnits />
  }

  return (
    <Grid
      container
      id={'Dashboard-Teacher-Contents-TeacherNew-ListContentUnit'}
      className={classes.containerContent}
    >
      <div className={`${classes.menu}`}>
        <HeaderTabs className='only-mobile' />
        <Grid item className={classes.explanatoryTextContainer}>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xs'
          >
            <Trans i18nKey='explanatoryTextContent'>
              Cada unidade apresenta conteúdo instrucional e material para o estudante, que pode ser utilizado a critério do(a) professor(a). Para saber mais sobre a anatomia e a carga horária de cada segmento, acesse a área de <b>Formação Cloe.</b>
            </Trans>
          </Text2>

          <Grid item sx={{ pt: 1 }}>
            <Button data-testid={'home_content_go_to_training_area'} onClick={async () => await handleGoToTrainingArea()} className={classes.goTotrainingAreaBtn}>
              {t('Ir para a área de Formação')}
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <Text2
            fontSize='sm'
            fontWeight='bold'
            lineHeight='xs'
            mobile='xs'
            neutral='dark20'
          >
            {t('Componente curricular:')}
          </Text2>
        </Grid>

        <div className={classes.containerTag}>
          <Disciplines disabled={state === StateListContentUnitEnum.LOADING} dataTestid={'home_content_select_curriculum_components'} />
        </div>
        <Grid container spacing={3} className={classes.searchContainer}>
          <Grid item xs={12} md={8}>
            <Search
              placeholder='Localizar na página'
              onSubmit={search}
              onClear={clear}
              dataTestId={{
                clear: 'home_content_search_topic_result_found',
                submit: 'home_content_search_topic_localize'
              }}
              ref={searchRef}
              showSubmitButton={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatusSelector
              onChange={async (sortOption: statusSortActionsEnum) => await handleContentUnitsSort(sortOption)}
              dataTestId={'home_content_order_content_unit'}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container mt={1}>
        {render[state]}
      </Grid>
    </Grid>
  )
}

export default ListContentUnit
