import useStyles from './style'
import { parseKaTex } from 'utils/katex'

export interface IContentProps {
  content: string | null
  title?: string | null
  contentClassName?: string
}

export const Text: React.FC<IContentProps> = ({ content, title = null, contentClassName }) => {
  const classes = useStyles()

  return (
    <>
      {title
        ? <h2 className={classes.subsection}>
          <span
            dangerouslySetInnerHTML={{
              __html: parseKaTex(title) ?? ''
            }}
          />
        </h2>
        : ''
      }
      {
        content
          ? <div
            className={`${classes.content} ${contentClassName}`}
            dangerouslySetInnerHTML={{
              __html: parseKaTex(content) ?? ''
            }}
          />
          : <></>
      }
    </>
  )
}
