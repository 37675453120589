import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    boxModal: {
      position: 'initial',
      textAlign: 'center',
      marginTop: '7%',
      marginInline: 'auto',
      width: '40%',
      backgroundColor: theme.colors.neutral.lightBase,
      borderRadius: theme.borderRadius.sm,
      padding: '48px 48px 0px 48px',
      [theme.breakpoints.down('sm')]: {
        position: 'initial',
        marginInline: 'auto',
        marginTop: theme.spacingStack.lg,
        width: 'calc(100% - 40px)'
      }
    },
    classesItensText: {
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xl,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.darkBase,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.md
      },
      '&.selected': {
        color: theme.colorBrand.medium
      }
    },
    classButton: {
      justifyContent: 'center',
      display: 'grid',
      paddingBlock: theme.spacingStack.xs
    },
    classButtonItem: {
      maxWidth: theme.spacingStack.giant,
      marginTop: '20px !important'
    }
  })
)
