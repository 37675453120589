import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IStyleProps {
  clonedTimes?: number
}

export default makeStyles((theme: Theme) => createStyles({
  contentLink: {
    width: '100%',
    minHeight: '130px',
    textDecoration: 'none',
    background: ({ clonedTimes }: IStyleProps) => clonedTimes === 0 ? 'rgba(230, 230, 230, 0.32)' : theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.sm,
    border: `1px solid ${theme.colors.neutral.light30}`,
    padding: theme.spacingInset.xs,
    cursor: 'pointer',
    '&:hover': {
      transition: 'all ease-in 0.12s',
      transform: 'scale(1.02)',
      boxShadow: theme.shadowLevels.level5
    },
    '&.selected': {
      color: theme.colorBrand.medium,
      background: '#EAE6F1',
      borderColor: theme.colorBrand.medium,
      boxShadow: theme.shadowLevels.level5
    },
    '&.disabled': {
      color: theme.colors.neutral.dark40,
      background: theme.colors.neutral.lightBase,
      transition: 'none',
      transform: 'none',
      boxShadow: 'none',
      cursor: 'not-allowed'
    },

    // mobile
    [theme.breakpoints.down('sm')]: {
      borderColor: theme.colors.neutral.light30
    }
  },
  contentLinkHead: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '50px',
    wordBreak: 'break-word',
    '&.selected': {
      color: theme.colorBrand.darkest
    },
    '&.disabled': {
      color: theme.colors.neutral.dark40
    }
  },
  contentLinkIcon: {
    color: theme.colors.neutral.dark20,
    '&.selected': {
      color: theme.colorBrand.darkest
    },
    '&.disabled': {
      color: theme.colors.neutral.dark40
    },
    '&.MuiSvgIcon-root': {
      fontSize: activityContent => activityContent ? '24px' : '51px',
      // mobile
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px'
      }
    }
  },
  contentLinkTitle: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark20,
    paddingLeft: theme.spacingInset.xxs,
    paddingRight: '25px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    '&.selected': {
      color: theme.colorBrand.darkest
    },
    '&.disabled': {
      color: theme.colors.neutral.dark40
    }
  },
  contentLinkIsAvailable: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.colors.warning.feedbackMedium,
    fontSize: '24px'
  },
  warningLabels: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xs,
    lineHeight: '17px'
  },
  contentLinkTags: {
    display: 'flex',
    marginTop: theme.spacingStack.xxxs,
    gap: theme.spacingStack.nano
  },
  quantityQuestions: {
    color: theme.colors.neutral.dark40,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    paddingLeft: '33px'
  },
  usedTimes: {
    border: `2px solid ${theme.colorBrand.light}`
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacingInline.quarck
  }
}))
