export const CONTENT_UNIT = (id?: string) => `${process.env.REACT_APP_BACKEND_URL as string}/content-units${!id ? '' : `/${id}`}`
export const CONTENT_UNIT_V2 = (id?: string) => `${process.env.REACT_APP_BACKEND_URL as string}/v2/content-units${!id ? '' : `/${id}`}`
export const CONTENT_UNIT_BY_CLASS_DISCIPLINE = (idClass: number, idDiscipline: number) => `${process.env.REACT_APP_BACKEND_URL as string}/classes/${idClass}/disciplines/${idDiscipline}/contents`
export const CONTENT_UNIT_BY_CLASS_DISCIPLINE_V2 = (idClass: number, idDiscipline: number) => `${process.env.REACT_APP_BACKEND_URL as string}/v2/classes/${idClass}/disciplines/${idDiscipline}/contents`
export const CONTENT_UNIT_SKILLS = (id: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/content-units/${id}/skills`
export const CONTENT_UNIT_CONTENT_SKILL = (id: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/content-units/${id}/content-skills`
export const CONTENT_UNIT_DIDATIC_CONTENT = (classId: number, id: number) => `${process.env.REACT_APP_BACKEND_URL as string}/class/${classId}/content-unit/${id}/didactic-content`
export const CONTENT_UNIT_SKILL_TAXONOMIES = (id: string | number) => `${process.env.REACT_APP_BACKEND_URL as string}/skill-taxonomies/getByContentUnit/${id}`
export const CONTENT_UNIT_CLASS_PROGRESS = () => `${process.env.REACT_APP_BACKEND_URL as string}/content-unit-class-progress`
export const GET_CONTENT_UNIT_CLASS_PROGRESS = (contentUnitId: number, classId: number) => `${process.env.REACT_APP_BACKEND_URL as string}/content-unit-class-progress/content-unit/${contentUnitId}/class/${classId}`
