import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  modalWrapper: {
    outline: 'none',
    position: 'absolute',
    top: '51%',
    left: '50%',
    display: 'grid',
    gridTemplateRows: '2fr 6fr',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'calc(100% - 140px)',
    width: '100%',
    height: '97.8%',
    backgroundColor: theme.colors.neutral.lightBase,
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,

    // mobile
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      borderRadius: '0px',
      display: 'grid',
      gridTemplateRows: 'auto 10fr',
      transform: 'unset',
      top: '0',
      left: '0',
      maxWidth: 'unset',
      maxHeight: '100vh',
      '& > .TabsUnstyled-root': {
        minHeight: 'inherit'
      },
      '& > .TabsUnstyled-root > div[role=tabpanel]': {
        height: '100%'
      }
    }
  },
  mobileExpeditionTitle: {
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.sm,
    color: theme.colorBrand.medium,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  contentWrapper: {
    overflowY: 'auto',
    position: 'relative',
    ...theme.scroll,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px'
    }
  },
  content: {
    padding: theme.spacingInline.xxs,
    height: 'fit-content',
    whiteSpace: 'pre-wrap',
    borderBottom: `1px solid ${theme.colors.neutral.light10}`,

    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      padding: theme.spacingInline.xxxs
    }
  },
  contentFiles: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacingInline.xxs,
    borderBottom: `1px solid ${theme.colors.neutral.light10}`,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInline.xxxs
    }
  },
  contentSubscriptions: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacingInline.xxs,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInline.xxxs
    }
  },
  txtTitle: {
    color: theme.colors.neutral.dark20,
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.medium
  },
  txtDescription: {
    marginTop: theme.spacingStack.xxxs,
    color: theme.colors.neutral.dark20,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium
  },
  groupFiles: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: theme.spacingStack.xxs,
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  }
}))
