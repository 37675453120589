import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

interface IStyleProps {
  isTemplate?: boolean
}

export default makeStyles((theme: Theme) =>
  createStyles({
    wrapper: ({ isTemplate }: IStyleProps) => ({
      backgroundColor: isTemplate ? 'rgba(230, 230, 230, 0.32)' : theme.colors.neutral.lightBase,
      padding: '0 16px',
      paddingLeft: theme.spacingInset.sm,

      ...(!isTemplate
        ? {}
        : {
          width: 'auto !important',
          borderRadius: theme.borderRadius.md
        }),
      paddingTop: `${theme.spacingInset.sm} !important`,
      // mobile
      [theme.breakpoints.down('sm')]: {
        padding: '16px !important'
      }
    }),
    backToContent: {
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs,
      color: theme.colorBrand.medium,
      lineHeight: theme.font.lineHeight.xxs,
      '& a': {
        textDecoration: 'none'
      }
    },
    printButton: {
      border: `1px solid ${theme.colorBrand.medium}`,
      borderRadius: `${theme.borderRadius.circular} !important`,
      fontSize: `${theme.font.fontSize.xxs} !important`,
      padding: '0 !important',
      minWidth: '0 !important',
      marginLeft: theme.spacingInline.xxxs,
      marginTop: 0,
      marginBottom: theme.spacingInline.xxxs,
      height: '40px !important',
      width: '40px !important',
      boxSizing: 'border-box',

      '& span': {
        marginLeft: '14px',
        // mobile
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      },
      [theme.breakpoints.down('md')]: {
        '&': {
          visibility: 'hidden'
        }
      }
    },
    printButtonMobile: {
      fontSize: `${theme.font.fontSize.xxs} !important`,
      minWidth: '0 !important',
      padding: '0 !important',
      height: '40px !important',
      width: '40px !important',
      boxSizing: 'border-box',
      '& span': {
        marginLeft: '14px',
        // mobile
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      },
      // mobile
      [theme.breakpoints.down('md')]: {
        '&': {
          visibility: 'visible',
          width: '32px',
          minWidth: '0',
          height: '32px',
          '& svg': {
            fontSize: '18px'
          }
        }
      },
      [theme.breakpoints.up('md')]: {
        '&': {
          visibility: 'hidden'
        }
      }
    },
    uteranceTitle: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs,
      lineHeight: '17px',
      width: '100%'
    },
    utteranceText: {
      color: theme.colors.neutral.dark10,
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: theme.font.fontWeight.regular,
      width: '100%',
      '& table': {
        borderSpacing: 0,
        '& th, td': {
          border: '1px solid #ddd',
          padding: `${theme.spacingStack.nano} ${theme.spacingStack.xxxs}`,
          fontWeight: theme.font.fontWeight.semibold,
          fontSize: theme.font.fontSize.xs,
          [theme.breakpoints.down('sm')]: {
            border: '1px solid #ddd',
            padding: '5px',
            fontSize: '9px'
          }
        }
      }
    },
    title: {
      color: theme.colorBrand.medium,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xl,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: '40px',
      margin: '24px 0 40px 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.lg,
        lineHeight: theme.font.lineHeight.sm,
        marginBottom: theme.spacingStack.xxs
      }
    },
    awnserQuestions: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs,
      lineHeight: '17px'
    },
    text: {
      color: theme.colors.neutral.dark30,
      fontFamily: theme.font.fontFamily,
      fontWeight: theme.font.fontWeight.regular,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.sm,
      paddingTop: 0,
      marginTop: 0,
      '& > a': {
        color: theme.colorBrand.medium,
        fontWeight: theme.font.fontWeight.bold
      }
    },
    nextButton: {
      '&.outlined': {
        padding: '12px 24px',
        '&:first-child': {
          marginRight: theme.spacingInline.xs
        },
        // mobile
        [theme.breakpoints.down('sm')]: {
          minWidth: 'unset',
          paddingInline: '11.5px'
        }
      },
      '& > svg': {
        marginLeft: '14px',
        fontSize: '16px'
      },
      '& span': {
        '&.only-desktop': {
          // mobile
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        },
        '&.only-mobile': {
          display: 'none',
          // mobile
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        paddingBlock: '10px'
      }
    },
    contentTextTitle: {
      textAlign: 'center',
      marginBottom: theme.spacingStack.nano,
      fontSize: theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.semibold,
      color: theme.colors.neutral.dark40,
      display: 'block'
    },
    contentText: {
      textAlign: 'center',
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.medium,
      color: theme.colors.neutral.dark40,
      margin: theme.spacingInline.xxxs,
      display: 'block'
    },
    enunciate: {
      '& img': {
        maxWidth: '100%'
      }
    }
  })
)
