import { DisciplineCodeEnum } from 'services/types/discipline'
import { IGradeTypeEnum } from 'services/types/grade'

const isProjectViewModeRule: (gradeTypeId: number, disciplinesCode: string[]) => boolean = (gradeTypeId, disciplinesCode) => {
  const ef1Rule = (gradeTypeId === IGradeTypeEnum.EF1) && disciplinesCode.includes(DisciplineCodeEnum.BI)
  const ef2Rule = (gradeTypeId === IGradeTypeEnum.EF2) && !disciplinesCode.includes(DisciplineCodeEnum.ING)
  return ef1Rule || ef2Rule
}

export default isProjectViewModeRule
