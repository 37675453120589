import { IApiResponse } from 'services/types'
import { ICommentsResponse, ICommentsRecordRequest } from 'services/types/comments'
import { FETCH_COMMENTS, CREATE_COMMENT, COMMENTS_BY_ID, READ_COMMENTS_BY_ID, COUNT_COMMENT } from './CONSTANTS'
import { request } from 'services/base'

export const getCommentsRecords = async (profileId: number, activityId: number, classId: number | string, questionId: number): Promise<IApiResponse<ICommentsResponse[]>> =>
  await request({ url: FETCH_COMMENTS(profileId, activityId, classId, questionId) })

export const saveCommentRecord = async (data: ICommentsRecordRequest): Promise<IApiResponse<ICommentsResponse>> =>
  await request({ url: CREATE_COMMENT(), method: 'post', data: data })

export const deleteCommentById = async (commentId: string): Promise<IApiResponse<boolean>> =>
  await request({
    url: COMMENTS_BY_ID(commentId),
    method: 'DELETE'
  })

export const editCommentById = async (commentId: string, comment: string): Promise<IApiResponse<ICommentsResponse>> =>
  await request({ url: COMMENTS_BY_ID(commentId), method: 'put', data: { comment } })

export const readComment = async (commentId: string): Promise<IApiResponse<ICommentsResponse>> =>
  await request({ url: READ_COMMENTS_BY_ID(commentId), method: 'put', data: { } })

export const getNotReadActivitiesComments = async (activityId: string | number): Promise<IApiResponse<number>> =>
  await request({ url: COUNT_COMMENT(activityId) })
