import Grid from '@mui/material/Grid'
import { Text, Button, Link } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { useStyle } from './style'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router'
import { IActivityResponse } from 'services/types'
import backgroundImageActivityCompleted from 'assets/activity-completed-women.svg'
import { CONTENTS_STUDENT, CONTENT_UNIT_STUDENT } from 'navigation/CONSTANTS'
import { useTheme } from '@mui/material'

interface ICompletedProps {
  activity: IActivityResponse
}

export const Completed: React.FC<ICompletedProps> = ({ activity }) => {
  const suggestedApplicationTypes = {
    activity: {
      heading: 'Fantástico!',
      backgroundImage: backgroundImageActivityCompleted
    },
    evaluation: {
      heading: 'Muito bem!',
      backgroundImage: backgroundImageActivityCompleted
    }
  }

  const suggestedUrl = {
    activity: 'activities',
    evaluation: 'evaluations'
  }

  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyle()
  const { contentId } = useParams<{ contentId: string }>()
  const typeProps = suggestedApplicationTypes[activity.suggested_application_type]
  const returnUrl = suggestedUrl[activity.suggested_application_type]

  return (
    <Grid
      container
      spacing={1}
      style={{ padding: '4rem 2rem', backgroundColor: 'white' }}
      justifyContent='center'
    >
      <Grid item xs={12}>
        <div
          className={classes.image}
          style={{ backgroundImage: `url(${typeProps.backgroundImage})` }}
        />
      </Grid>
      <Grid item xs={12} justifyContent='center' className={classes.center}>
        <Text type='heading' size='large' color='medium'>
          {t(typeProps.heading)}
        </Text>
      </Grid>
      {
        activity &&
        <Grid item xs={12}>
          <p className={classes.text}>
            {t('Você finalizou a ')}
            <b>{activity.title ?? activity.activity_name}</b>
            <br />
            {t('Para acessar esse conteúdo novamente ou outras atividades, ')}
            <NavLink className={classes.navLink} to={`${CONTENT_UNIT_STUDENT(contentId)}/${returnUrl}`}>
              <Link data-testid={activity.suggested_application_type === 'activity' ? 'respond_activities_access_content' : 'respond_assessments_access_content'} variant='primary' style={{ color: theme.colorBrand.medium }}>
                clique aqui
              </Link>
            </NavLink>
          </p>
        </Grid>
      }
      <Grid item xs={12} justifyContent='center' className={classes.center}>
        <NavLink className={classes.navLink} to={CONTENTS_STUDENT}>
          <Button className={classes.button} variant='primary' type='submit' data-testid={activity.suggested_application_type === 'activity' ? 'respond_activities_access_expeditions' : 'respond_assessments_access_expeditions'}>
            {t('Acessar conteúdos')}
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  )
}

export default Completed
