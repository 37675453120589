import { request } from 'services/base'
import { IUserSessionResponse, IApiResponse } from 'services/types'

import { LMS_PLATFORM, LMS_INITIAL_TEACHER_TRAINING_MODULE, LMS_CLOE_EXPAND_TEACHER_MODULE } from './CONSTANTS'

export const getLinkLmsPlatform = async (): Promise<IApiResponse<IUserSessionResponse>> => await request({
  url: LMS_PLATFORM(),
  method: 'get'
})

export const getLinkLmsInitialTeacherTrainingModule = async (): Promise<IApiResponse<IUserSessionResponse>> => await request({
  url: LMS_INITIAL_TEACHER_TRAINING_MODULE(),
  method: 'get'
})

export const getLinkLmsCloeExpandTeacherModule = async (): Promise<IApiResponse<IUserSessionResponse>> => await request({
  url: LMS_CLOE_EXPAND_TEACHER_MODULE(),
  method: 'get'
})
