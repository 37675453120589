import { createStyles, CSSProperties, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import { Theme } from '@mui/material'

const styleDefault: (theme: CustomTheme) => CSSProperties = theme => ({
  minWidth: '32px',
  height: '32px',
  padding: '0 6px',
  fontWeight: 500,
  fontSize: '0.875rem',
  lineHeight: 1.43,
  '&:active': {
    transition: '.1s',
    boxShadow: `0px 0px 0px 3px ${theme.colors.support.colorSupport02}`
  }
})

export default makeStyles((theme: Theme) =>
  createStyles({
    list: {
      alignItems: 'center',
      padding: 0,
      listStyle: 'none',
      '& li': {
        display: 'inline-block',
        marginRight: '16px',
        '& .outlined': {
          ...styleDefault(theme),
          color: theme.colorBrand.medium,
          border: `solid ${theme.borderThickness.hairline}`,
          borderColor: theme.colorBrand.medium,
          '&:hover': {
            background: theme.colorBrand.lightest
          },
          '&.answered': {
            color: theme.colorBrand.medium,
            background: theme.colorBrand.lightest,
            borderColor: theme.colorBrand.medium
          },
          '&.selected': {
            background: theme.colorBrand.medium,
            color: theme.colors.neutral.lightBase
          }
        }
      }
    }
  })
)
