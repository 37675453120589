import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import OnlyProfile from 'components/common/OnlyProfile'

import { CONTENT_UNIT_STUDENT, CONTENT_UNIT_TEACHER } from 'navigation/CONSTANTS'

import { ActivityControlStatusEnum, IActivityResponse, IUserSchoolProfileTypeEnum } from 'services/types'

import useStyles from './style'
import { previousSubMenuTabAtom } from 'pages/ContentUnit/components/SideMenu/components/atomStore'
import { SubTabsMenusStudent, SubTabsMenusTeacher } from 'pages/ContentUnit/atomStore'
import { useStore } from 'store'

interface IBackToAllProps {
  isActivitySuggestedType?: boolean
  contentId: string
  dataTestid?: string
  activity?: IActivityResponse
}

export const BackToAll = (props: IBackToAllProps) => {
  const classes = useStyles()
  const { isActivitySuggestedType, contentId, dataTestid, activity } = props
  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()
  const { t } = useTranslation()
  const { subscription, profile } = useStore()

  const [previousSubMenuTab, setPreviousSubMenuTab] = useAtom(previousSubMenuTabAtom)
  const [indexSuggested, setIndexSuggested] = useState<SubTabsMenusTeacher | SubTabsMenusStudent | undefined>(undefined)

  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  useEffect(() => {
    const previousSubTab = getActivityBackIndex()

    setIndexSuggested(previousSubTab)

    if (previousSubTab !== undefined) {
      setPreviousSubMenuTab(previousSubTab)
    }
  }, [activity])

  const getActivityBackIndex = () => {
    if (!activity) return

    const isTeacher = profileType === IUserSchoolProfileTypeEnum.teacher
    const hasAnswers = activity.activity_status?.has_answer
    const activityStatus = activity?.activity_status ? Number(activity?.activity_status?.status) : undefined

    let index
    if (isTeacher) {
      index = SubTabsMenusTeacher.ORIGINALS
      if (activityStatus === ActivityControlStatusEnum.AVAILABLE && activity?.custom_type !== 'clone') {
        index = SubTabsMenusTeacher.ORIGINALS
      } else if (activityStatus === ActivityControlStatusEnum.AVAILABLE && activity?.custom_type === 'clone') {
        index = SubTabsMenusTeacher.LIBERATED
      } else if (activityStatus && [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.DONE].includes(activityStatus)) {
        index = SubTabsMenusTeacher.LIBERATED
      } else if (activityStatus && [ActivityControlStatusEnum.CLOSED].includes(activityStatus)) {
        index = SubTabsMenusTeacher.CORRECTION
      } else if (Number(activityStatus) === ActivityControlStatusEnum.RELEASED) {
        index = SubTabsMenusTeacher.FINISHED
      }

      return index
    }

    // student

    if (!activityStatus) {
      return index
    }

    if (!hasAnswers && [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.CLOSED, ActivityControlStatusEnum.RELEASED].includes(activityStatus)) {
      index = SubTabsMenusStudent.LIBERATED
    } else if (hasAnswers && [ActivityControlStatusEnum.DONE, ActivityControlStatusEnum.CLOSED].includes(activityStatus)) {
      index = SubTabsMenusStudent.ANSWERED
    } else if (hasAnswers && activityStatus === ActivityControlStatusEnum.RELEASED) {
      index = SubTabsMenusStudent.CORRECTED
    } else {
      index = SubTabsMenusStudent.ORIGINALS
    }

    return index
  }

  const currentTabIndexStudent = (indexSuggested ?? previousSubMenuTab ?? 0) as unknown as SubTabsMenusStudent
  const currentTabIndexTeacher = (indexSuggested ?? previousSubMenuTab ?? 0) as unknown as SubTabsMenusTeacher

  const backMessages = {
    teacher: {
      [SubTabsMenusTeacher.ORIGINALS]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} originais`),
      [SubTabsMenusTeacher.LIBERATED]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} liberadas`),
      [SubTabsMenusTeacher.CORRECTION]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} para corrigir`),
      [SubTabsMenusTeacher.FINISHED]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} finalizadas`)
    },
    student: {
      [SubTabsMenusStudent.ORIGINALS]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} originais`),
      [SubTabsMenusStudent.LIBERATED]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} liberadas`),
      [SubTabsMenusStudent.ANSWERED]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} respondidas`),
      [SubTabsMenusStudent.CORRECTED]: t(`< ${isActivitySuggestedType ? 'Atividades' : 'Avaliações'} corrigidas`)
    }
  }

  return (
    <React.Fragment>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <div className={classes.backToContent}>
          <Link data-testid={dataTestid} to={`${CONTENT_UNIT_STUDENT(contentId)}/${isActivitySuggestedType ? 'activities' : 'evaluations'}`}>
            <React.Fragment>
              {backMessages.student[currentTabIndexStudent]}
            </React.Fragment>
          </Link>
        </div>
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <div className={classes.backToContent}>
          <Link
            to={`${CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, contentId)}/${isActivitySuggestedType ? 'activities' : 'evaluations'}`}
            data-testid={dataTestid}
          >
            <React.Fragment>
              {backMessages.teacher[currentTabIndexTeacher]}
            </React.Fragment>
          </Link>
        </div>
      </OnlyProfile>
    </React.Fragment>
  )
}

export default BackToAll
