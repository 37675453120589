
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { Link } from 'components/design-system'
import { ResourceCaption } from 'components/common'

// regexr.com/57qr9
const vimeoRegexExp = /.*(vimeo\.com\/|vimeo\.com\/video\/)(\d.*)/

export function getVimeoVideoIdFromURL(url: string) {
  const strMatch = url.match(vimeoRegexExp)
  return (strMatch?.[2]) ? strMatch[2] : ''
}

export interface IVimeoPlayerProps {
  url: string
  source?: string | null
  description?: string | null
}

export const VimeoPlayer: React.FC<IVimeoPlayerProps> = ({ url, source, description }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const videoId = getVimeoVideoIdFromURL(url)

  if (videoId) {
    return (
      <div className='vimeoContainer' style={{ width: '100%', maxWidth: '720px' }}>
        <div className={classes.iFrameContainer}>
          <iframe
            src={`https://player.vimeo.com/video/${videoId}?byline=0&portrait=0`}
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Vimeo Video'
          />
        </div>
        <ResourceCaption source={source} description={description}></ResourceCaption>
      </div>

    )
  }

  return (
    <div className={classes.errorMessage}>
      {t('Desculpe, não foi possível carregar o vídeo')}
      <div>
        <Link
          variant='primary'
          href={url}
          target='_blank'
        >
          {url}
        </Link>
      </div>
    </div>
  )
}
