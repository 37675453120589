import ErrorBoundary from 'components/common/ErrorBoundary'
import OnlyProfile from 'components/common/OnlyProfile'
import { IContentUnitResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import StudentExpeditions from './student'
import BackToTopWindow from 'components/common/BackToTopWindow'

interface IExpeditionsProps {
  content: IContentUnitResponse
}

export const Expeditions: React.FC<IExpeditionsProps> = ({ content }) => {
  return (
    <ErrorBoundary>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <StudentExpeditions content={content} />
      </OnlyProfile>
      <BackToTopWindow/>
    </ErrorBoundary>
  )
}

export default Expeditions
