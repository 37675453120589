import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardStudentAnswerTextInfo: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs
    },
    cardStudentAnswerContentOptionsButton: {
      minWidth: '70px !important',
      fontWeight: `${theme.font.fontWeight.semibold} !important`,
      color: `${theme.colors.neutral.lightBase} !important`,

      '&.primary:disabled': {
        color: `${theme.colors.neutral.lightBase} !important`,
        backgroundColor: `${theme.colorBrand.medium} !important`
      }
    },
    cardStudentAnswerButtonNotActiveNotCorrect: {
      minWidth: '40px !important',
      fontWeight: `${theme.font.fontWeight.semibold} !important`,
      padding: '8px !important',

      '&.primary:disabled': {
        color: `${theme.colors.neutral.lightBase} !important`,
        backgroundColor: `${theme.colorBrand.medium} !important`
      },

      '&.outlined:disabled': {
        backgroundColor: 'transparent !important',
        color: `${theme.colorBrand.medium} !important`,
        borderColor: `${theme.colorBrand.medium} !important`
      }
    },
    cardStudentAnswerButtonRevised: {
      color: `${theme.colors.neutral.lightBase} !important`,
      backgroundColor: `${theme.colorBrand.medium} !important`
    },
    cardStudentAnswerButtonCommentsActivated: {
      backgroundColor: 'rgba(191, 178, 211, 0.24) !important',

      '& svg': {
        fill: theme.colorBrand.medium
      }
    }
  })
)
