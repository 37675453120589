import {
  ClassId,
  ContentUnitId,
  DateIsoString,
  DisciplineId,
  ItemTimelineId,
  RecordId,
  StudentRecordId
} from './common'

export interface ITimelineRequestOptions {
  offset?: number
  limit?: number
  startDate?: Date | DateIsoString | null
  endDate?: Date | DateIsoString | null
}

export interface IRecordTimelineResponse {
  id: RecordId
  text: string
  url: string
  type: string
  createdAt: DateIsoString
  choiceAlternative: string
  questionText: string
  questionOrder?: number
  levelAlternative: number
  alternativaResources: IAlternativaResources[]
}
export interface IAlternativaResources {
  id: number
  title: string
  code: string
  source: string
  type: string
  description: string
  htmlContent: string
  link: string
  isEmbeded: boolean
  showOnLibrary: boolean
}

export enum SuggestedApplicationTypeEnum {
  activity = 'activity',
  evaluation = 'evaluation',
  classRecord = 'class_record',
  marco = 'marco'
}

export interface IStudentRecordsTimelineResponse {
  id: StudentRecordId // aqui sempre será number
  contentUnitId: ContentUnitId
  contentUnitTitle: string
  title: string
  description?: string
  milestoneOwner: number
  suggestedApplicationType: SuggestedApplicationTypeEnum
  createdAt: DateIsoString
  cover?: string
  records: IRecordTimelineResponse[]
  milestoneRecords?: IStudentRecordsTimelineResponse[] | null
  dateStart?: string
  dateEnd?: string
  ownerId: number
}

export interface IFindStudentTimelineResponse {
  id: ItemTimelineId
  disciplineId: DisciplineId
  classId: ClassId
  monthNum: number
  studentRecords: IStudentRecordsTimelineResponse[]
  showActualMonth?: boolean
}

export interface IFindStudentMarcoTimelineResponse {
  studentRecords: Array<IStudentRecordsTimelineResponse | null>
  id: string
  disciplineId: DisciplineId
  classId: ClassId
  monthNum: number
  showActualMonth: boolean
}
