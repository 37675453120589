import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  accordion: {
    border: `1px solid ${theme.colors.neutral.light30}`,
    borderRadius: `${theme.borderRadius.sm}!important`,
    boxShadow: 'none!important',
    width: '100%'
  },
  accordionDetails: {
    padding: '8px 32px 16px!important'
  },
  accordionSummary: {
    minHeight: '96px!important',
    padding: '0px 32px!important'
  },
  tab: {
    color: 'red!important'
  }
}))
