import { isInternalImageUrl, CacheManagerException, makeCacheInstance } from '../'

export const addRequestToCache = async (request: Request) => {
  const appCache = await makeCacheInstance()
  try {
    await appCache.add(request)
    const requestInCache = await appCache.match(request)
    if (requestInCache) {
      const { size } = await requestInCache.clone().blob()
      const data = await new Promise((resolve, reject) => {
        requestInCache.clone().json().then(resolve).catch((err) => {
          if (err instanceof SyntaxError && isInternalImageUrl(requestInCache.url)) {
            resolve(requestInCache.url)
          }
          reject(err)
        })
      })
      return { data, size }
    }
    throw new Error('request does not match, an error occurred')
  } catch (error) {
    return new CacheManagerException(request, error)
  }
}
