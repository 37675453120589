import { createRef, useState } from 'react'
import { IDisciplineStore } from 'services/types'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { Tag, Icon } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IListDisciplinesProps {
  dataTestid?: string
  disciplines?: IDisciplineStore[]
  currentDiscipline?: IDisciplineStore | null
  onChange?: (discipline: any) => void
  hidden?: boolean
  disabled?: boolean
  title?: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  tagsContainer: {
    display: ({ hidden }: IListDisciplinesProps) => hidden ? 'none' : 'flex',
    fontSize: theme.font.fontSize.sm,
    flexFlow: 'inherit',
    overflowX: 'auto',
    transition: 'all ease-in-out 0.2s',
    lineHeight: theme.font.lineHeight.xs,
    fontWeight: theme.font.fontWeight.semibold,
    '&.active': {
      flexFlow: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm
    }
  },
  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano,
    cursor: 'pointer',

    '&.disabled': {
      cursor: 'not-allowed'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: theme.spacingInset.md,
      alignItems: 'center',
      display: 'flex'
    }
  },
  titleContainer: {
    marginBlock: theme.spacingInline.nano,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    lineHeight: theme.font.lineHeight.xs,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.font.fontSize.sm
    },
    '& > p': {
      margin: 0
    }
  },
  titleDrop: {
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    display: 'flex',
    '& > p': {
      margin: 0
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}))

export const ListDisciplines: React.FC<IListDisciplinesProps> = (props: IListDisciplinesProps) => {
  const { dataTestid, disciplines, currentDiscipline, onChange, hidden, disabled, title } = props
  const [dropDown, setDropDown] = useState<boolean>(!!title)
  const { t } = useTranslation()
  const classes = useStyles({ hidden })
  const inputRef = createRef<HTMLDivElement>()

  return (
    <>
      {!!title && (
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            {title}
          </div>
          <div
            className={classes.titleDrop}
            onClick={() => setDropDown(!dropDown)}
            id='see_all_mobile'
          >
            <p>{t('Ver todos')}</p>
            { dropDown
              ? <Icon titleAccess={t('Ver todos')} size='medium'>
                <KeyboardArrowUpOutlinedIcon />
              </Icon>
              : <Icon titleAccess={t('Recolher todos')} size='medium'>
                <KeyboardArrowDownOutlinedIcon />
              </Icon>
            }
          </div>
        </div>
      )}

      <div ref={inputRef} data-testid={dataTestid} hidden />

      <div className={`${classes.tagsContainer} ${!dropDown && 'active'}`}>
        {
          disciplines?.map(discipline =>
            <Tag
              key={discipline?.code}
              className={`${classes.tag}`}
              variant={currentDiscipline?.id === discipline.id ? discipline.code : undefined}
              onClick={() => {
                inputRef?.current?.click()
                onChange?.(discipline)
              }}
              disabled={disabled}
            >
              {t(discipline?.name ?? '')}
            </Tag>
          )
        }
      </div>
    </>
  )
}
