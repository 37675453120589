import { atom } from 'jotai'
import { } from 'services/types/subscription'

type Nullable<T> = T | null

interface IDisciplinesResponse {
  id: number | string
  name: string
}

export interface ITeacherSubscription {
  class_id: number | string
  disciplines: IDisciplinesResponse[]
}

export const isActiveModalAtom = atom<boolean>(false)
export const subscrisptionAtom = atom<Nullable<ITeacherSubscription[]>>(null)
export const gradeTypeSelected = atom<number | null | string>(null)
