import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { OpenDirectionType } from '../DropdownSelector'

interface IStyleProps {
  openDirection: OpenDirectionType
}

export default makeStyles((theme: Theme) => createStyles({
  container: (props: IStyleProps) => ({
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    padding: props.openDirection === 'top-down'
      ? `0 ${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`
      : `${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`,

    '& .currentPeriodsContainer': {
      color: theme.colorBrand.medium
    },
    '& .previousPeriods': {
      color: theme.colors.contentType.default
    }
  }),
  dropdownContainer: {
    minWidth: '50px',
    flex: 1,
    width: '100%',

    '& .selectedText': {
      fontSize: `${theme.font.fontSize.sm} !important`
    },
    [theme.breakpoints.down('md')]: {
      flex: 0
    }
  },

  optionContainer: {
    cursor: 'pointer',
    fontWeight: `${theme.font.fontWeight.medium} !important`,
    fontSize: theme.font.fontSize.sm,
    color: `${theme.colors.neutral.darkBase} !important`,

    margin: `${theme.spacingStack.nano} 0`,

    boxSizing: 'content-box',
    borderRadius: theme.borderRadius.sm,
    border: `2px solid ${theme.colors.neutral.light10}`,
    padding: `${theme.spacingInline.nano}`,
    backgroundColor: theme.colors.neutral.lightBase,

    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    wordWrap: 'break-word',

    '&.active': {
      border: `2px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);',
      color: `${theme.colorBrand.medium} !important`
    },
    '&.disabled': {
      color: `${theme.colors.neutral.dark40} !important`
    },
    '&.focused': {
      border: `2px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    },
    '&:hover': {
      transition: 'all ease-in-out 55ms',
      border: `2px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    }
  },
  selectedText: {
    fontWeight: `${theme.font.fontWeight.regular} !important`,
    color: `${theme.colors.neutral.darkBase} !important`
  }
}))
