/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useEffect } from 'react'
import { useAtom } from 'jotai'
import { useStore } from 'store'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'
import { ILocation } from 'services/types/routes'

import { ActivityControlStatusEnum, IAnswerRecordRequest, IAnswersRequest } from 'services/types'
import { IGradeTypeEnum } from 'services/types/grade'
import { saveAnswerRecord, editAnswerRecord, saveAnswerRecordCache } from 'services/answer'
import { getUserRecordsActivity } from 'services/record'
// TODO: estado de professor em atividad de aluno?
import { contentUnitAtom } from 'pages/ContentUnit/atomStore'
import { getLevels, getActivityByClass } from 'services/activity'
import {
  addAnswerOffline,
  convertOfflineAnswers,
  getAnswerOffline,
  getSubscription,
  useExpeditionCache,
  useOnlineStatus
} from 'contentCacheManager'

import { toast } from 'components/design-system/Toast/manager'

import {
  answersAtom,
  isAnsweredAtom,
  studentLevelsAtom,
  activityStatusControlAtom,
  schoolLevelsAtom,
  questionIndexAtom,
  isSuccessAtom,
  inUploadingProcess,
  activityCurrentAtom
} from 'pages/ContentUnit/components/Activity/atomStore'

import {
  loadingAtom,
  isUnavailableAtom,
  isEditingAtom,
  sendLoadingAtom,
  hasResponseErrorAtom,
  errorMessageAtom,
  actvityStateMachineAtom,
  ActivityStateMachineEnum,
  isVerifyingRoutesAtom
} from './atomsStore'
import { checkExistsAnswerCachedAndUpdate } from 'contentCacheManager/localDatabaseManager/data/answers/checkExistsAnswerCachedAndUpdate'
import { useContents } from '../../Content/useContents'

const useStudentAnswer = () => {
  const [activityStateMachine, setActivitiStateMachine] = useAtom(
    actvityStateMachineAtom
  )
  const [isLoading, setIsLoading] = useAtom(loadingAtom)
  const [isVerifyingRoutes, setIsVerifyingRoutes] = useAtom(isVerifyingRoutesAtom)
  const [activity, setActivity] = useAtom(activityCurrentAtom)
  const [isUnavailable, setIsUnavailable] = useAtom(isUnavailableAtom)
  const [isEditing, setIsEditing] = useAtom(isEditingAtom)
  const [sendLoading, setSendLoading] = useAtom(sendLoadingAtom)
  const [hasResponseError, setHasResponseError] = useAtom(hasResponseErrorAtom)
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom)

  // unifify all content unit fonts
  const [atomContentUnit] = useAtom(contentUnitAtom)
  const { content } = useContents()
  const contentUnit = atomContentUnit ?? content

  const [, setStudentLevels] = useAtom(studentLevelsAtom)
  const [schoolLevels, setSchoolLevels] = useAtom(schoolLevelsAtom)
  const [questionIndex, setQuestionIndex] = useAtom(questionIndexAtom)
  const [activityStatusControl, setActivityStatusControl] = useAtom(activityStatusControlAtom)
  const [isAnswered, setIsAnswered] = useAtom(isAnsweredAtom)
  const [answers, setAnswers] = useAtom(answersAtom)
  const [success, setSuccess] = useAtom(isSuccessAtom)
  const [isUploading] = useAtom(inUploadingProcess)

  const { subscription } = useStore()
  const { contentId, activityId, evaluationId } =
    useParams<{ contentId: string, activityId: string, evaluationId: string }>()
  const activityOrEvaluationId = activityId ?? evaluationId
  const { t } = useTranslation()
  const isEF1 = subscription?.class?.grades?.[0].grade_type === IGradeTypeEnum.EF1
  const isEF2 = subscription?.class?.grades?.[0].grade_type === IGradeTypeEnum.EF2
  const hasDidacticContent = (!!contentUnit?.didactic_content?.id) && contentUnit?.didactic_content?.sections.length
  const isExpeditionView = (
    ((isEF1 || isEF2) && !hasDidacticContent)
  )
  const classSubscription = subscription?.class
  const profile = subscription?.user_school_profile
  const isOnline = useOnlineStatus()
  const location = useLocation<ILocation>()

  const { expeditionInCache } = useExpeditionCache({
    expeditionId: Number(contentId)
  })

  useEffect(() => {
    if (expeditionInCache === null && !isOnline) {
      const errorMessage = t('Não foi possível carregar atividade')
      setActivitiStateMachine(ActivityStateMachineEnum.FAILED_TO_LOAD_ACTIVITY)
      setErrorMessage(errorMessage)
      setHasResponseError(true)
    }
  }, [expeditionInCache, isOnline])

  const allQuestionsAnswered = useMemo(() => {
    if (!answers || !activity || !activity.questions) return false

    const actualQuestionsId = activity.questions.map(({ id }) => id.toString())

    const hasUnansweredQuestions = answers
      .filter(({ question_id }) => actualQuestionsId.includes(question_id))
      .some(question => {
        return question.records.some(record => {
          const hasTextAnswer = record.text_record?.text?.length
          const hasFilesUploaded = record.file_records?.length
          const hasChoices = record.choice_record

          const hasAnswer = hasTextAnswer ?? hasFilesUploaded ?? hasChoices

          return !hasAnswer
        })
      })

    return !hasUnansweredQuestions
  }, [answers])

  const disableSend = useMemo(
    () =>
      (activity?.questions?.length ?? 0) > answers.length ||
      sendLoading ||
      !allQuestionsAnswered,
    [activity, answers, allQuestionsAnswered]
  )

  const cleanupState = () => {
    // cleaning
    setActivitiStateMachine(ActivityStateMachineEnum.IDLE)
    setStudentLevels([])
    setAnswers([])
    setQuestionIndex(0)
    setIsEditing(false)
    setSuccess(false)
    setSendLoading(false)
    setIsAnswered(false)
    setHasResponseError(false)
    setErrorMessage('')
  }

  const isExpedition = (path: string) => {
    const expeditionPathPattern = /\/content-unit\/\d+\/expeditions/

    return expeditionPathPattern.test(path)
  }

  const getActivityData = async (
    subscriptionId: number,
    activityId: string
  ) => {
    setIsLoading(true)
    setActivitiStateMachine(ActivityStateMachineEnum.GETING_ACTIVITY_DATA)

    const response = await getActivityByClass(subscriptionId, activityId, isExpeditionView)
    if (response.success) {
      const activityRes = response.data
      // TODO: transformar tudo relacionado a status num estado derivado e porques activity status control está sendo setado multiplas vezes
      const status = Number(activityRes?.activity_status?.status)

      let isLoadedOfflineAnswer = false
      if (!isOnline) {
        isLoadedOfflineAnswer = await loadOfflineAnswers()
      }

      // TODO: rever nomenclantura do enum AVAILABLE
      if (status === ActivityControlStatusEnum.AVAILABLE) {
        setIsAnswered(true) // bloqueia para o estudante nao mandar respostas
        setIsUnavailable(true)

        if (!isExpedition(location.pathname)) {
          setActivitiStateMachine(ActivityStateMachineEnum.ACTIVITY_UNAVAILABLE)
        }
      } else if (
        [
          ActivityControlStatusEnum.DONE,
          ActivityControlStatusEnum.CLOSED,
          ActivityControlStatusEnum.CORRECTED,
          ActivityControlStatusEnum.RELEASED
        ].includes(status) ||
        (status === ActivityControlStatusEnum.PENDING &&
          !isOnline &&
          isLoadedOfflineAnswer)
      ) {
        setIsAnswered(true)
        setActivitiStateMachine(ActivityStateMachineEnum.UNABLE_ANSWERS)
      } else {
        setActivitiStateMachine(ActivityStateMachineEnum.ENABLE_ANSWERS)
        setIsAnswered(false)
      }
      setSuccess(false)
      setActivityStatusControl(status)
      setActivity(activityRes)
    } else {
      if (response.message === 'Avaliação ainda não foi liberada pelo professor' && isExpeditionView) {
        setActivitiStateMachine(ActivityStateMachineEnum.ACTIVITY_UNAVAILABLE_FUND1)
      } else {
        setActivitiStateMachine(
          ActivityStateMachineEnum.FAILED_TO_LOAD_ACTIVITY
        )
      }
      const errorMessage = t(
        response.message ?? 'Não foi possível carregar atividade'
      )
      setErrorMessage(errorMessage)
      setHasResponseError(true)
      toast.handler({
        content: errorMessage,
        duration: 5000,
        severity: 'error'
      })
    }
    setIsLoading(false)
  }

  const getUserAnsweredRecords = async (
    activityId: string,
    classSubscriptionId: number
  ) => {
    if (activityStatusControl && activityStatusControl === ActivityControlStatusEnum.AVAILABLE) {
      // Não é possivel que existam respostas para uma atividade não liberada
      return
    }

    const response = await getUserRecordsActivity(
      parseInt(activityId),
      classSubscriptionId
    )

    if (response.success) {
      const buildPayload = (cur: { [index: string]: any }) => {
        const obj: { [index: string]: any } = {}
        if (cur.id) obj.id = cur.id
        if (cur.source_type) obj.source_type = cur.source_type
        if (cur.choice_record) obj.choice_record = cur.choice_record
        if (cur.text_record) obj.text_record = cur.text_record
        if (cur.file_records?.length) {
          obj.file_records = cur.file_records
        }
        return obj
      }

      if (Array.isArray(response.data) && response.data?.length > 0) {
        const thereIsEditedCachedAnswers = response.data.some(r => r.edited)
        if (thereIsEditedCachedAnswers) {
          editAnsweredQuestion()
        }

        const _answers: IAnswersRequest[] = response.data.map(r => {
          const formattedAnswer: IAnswersRequest = {
            question_id: r.question_id?.toString(),
            records: r?.records?.map(cur => buildPayload(cur)) ?? []
          }

          if (r?.id) formattedAnswer.id = r.id
          return formattedAnswer
        })
        setAnswers(_answers)
        const _levels = response.data.map(r => ({
          id: parseInt(r.id) ?? 0,
          question_id: r.question_id,
          level:
            schoolLevels.find(l => l.id === (r.level as number)) ?? undefined
        }))
        setStudentLevels(_levels)
      }
    } else {
      setActivitiStateMachine(ActivityStateMachineEnum.FAILED_TO_LOAD_ANSWERS)
      toast.handler({
        content: t('Não foi possível carregar respostas.'),
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const requestLevels = async () => {
    const response = await getLevels()
    if (response.success) {
      setSchoolLevels(response.data)
      return response.data
    }
  }

  const sendAnswerRecord = async () => {
    const request: IAnswerRecordRequest & { isEditing: boolean } = {
      activity: activity?.id?.toString() ?? '',
      content_unit: contentId,
      class: classSubscription?.id?.toString() ?? '',
      answers: answers,
      isEditing
    }

    if (!isOnline) {
      await addAnswerOffline(request)
      setIsAnswered(true)
      await loadOfflineAnswers()
      toast.handler({
        content: t(
          'Sua resposta está salva, quando a internet retornar será enviada.'
        ),
        severity: 'info',
        duration: 10000
      })
      return
    }

    setSendLoading(true)
    setActivitiStateMachine(ActivityStateMachineEnum.SENDING_ANSWERS)

    if (classSubscription) {
      const response = await getUserRecordsActivity(
        parseInt(activityOrEvaluationId),
        classSubscription.id
      )

      if (response.success) {
        const buildPayload = (cur: { [index: string]: any }) => {
          const obj: { [index: string]: any } = {}
          if (cur.id) obj.id = cur.id
          if (cur.source_type) obj.source_type = cur.source_type
          if (cur.choice_record) obj.choice_record = cur.choice_record
          if (cur.text_record) obj.text_record = cur.text_record
          if (cur.file_records?.length && response.data?.length > 0) {
            response.data.forEach(answer => {
              if (answer?.records?.length) {
                obj.file_records = cur.file_records
              }
            })
          } else if (cur.file_records?.length && response.data?.length === 0) {
            obj.file_records = cur.file_records
          } else {
            obj.file_records = []
          }
          return obj
        }

        if (answers.length > 0) {
          const _answers: IAnswersRequest[] = answers.map(a =>
            ({
              id: a.id ?? 0,
              question_id: a.question_id?.toString(),
              records: a.records?.map(cur => buildPayload(cur)) ?? []
            })
          )

          const request: IAnswerRecordRequest = {
            activity: activityOrEvaluationId,
            content_unit: contentId,
            class: classSubscription.id?.toString(),
            answers: _answers
          }

          const sendAnswerResponse = isEditing
            ? await editAnswerRecord(request)
            : await saveAnswerRecord(request)

          if (sendAnswerResponse.success) {
            setIsEditing(false)
            setSuccess(true)
            setActivitiStateMachine(isExpeditionView ? ActivityStateMachineEnum.ANSWER_SENT_FUND1 : ActivityStateMachineEnum.ANSWER_SENT_DEFAULT)
            toast.handler({
              content: t('Respostas enviadas!'),
              duration: 5000,
              severity: 'success'
            })
            await checkExistsAnswerCachedAndUpdate({ activity: activityOrEvaluationId, class: classSubscription.id?.toString(), content_unit: contentId }, {
              answers: sendAnswerResponse.data.answers
            })
            if (isExpeditionView) {
              setActivitiStateMachine(ActivityStateMachineEnum.IDLE)
              await getActivityData(classSubscription.id, activityOrEvaluationId)
              await getUserAnsweredRecords(
                activityOrEvaluationId,
                classSubscription.id
              )
            }
          }
        }
      }
    }

    setSendLoading(false)
  }

  const handleAnswerRecordCache = async () => {
    if (answers.length && isOnline) {
      const answersFound = answers.filter(cur => {
        const hasFileRecord = (cur?.records?.[0]?.file_records?.length ?? 0) > 0
        const hasTextRecord = !!cur.records[0].text_record?.text
        const hasChoiceRecord = cur.records[0].choice_record?.alternative_id

        return (hasFileRecord || hasTextRecord || hasChoiceRecord)
      })

      if (answersFound.length) {
        const request = {
          activity_id: activity?.id?.toString() ?? '',
          content_unit_id: contentId,
          class_id: classSubscription?.id?.toString() ?? '',
          answers: answersFound
        }
        await saveAnswerRecordCache(request, { isEditing })
      }
    }
  }

  const getActivityAndAnswersData = async () => {
    if (!subscription || !classSubscription || !profile) {
      setActivitiStateMachine(ActivityStateMachineEnum.NO_PROFILE_ERROR)
      return
    }
    const schoolLevelsResponse = await requestLevels()
    if (
      classSubscription &&
      profile &&
      activityOrEvaluationId &&
      schoolLevelsResponse?.length
    ) {
      await getActivityData(classSubscription.id, activityOrEvaluationId)
      await getUserAnsweredRecords(activityOrEvaluationId, classSubscription.id)
    }
  }

  const editAnsweredQuestion = () => {
    setActivitiStateMachine(ActivityStateMachineEnum.ENABLE_ANSWERS)
    setIsAnswered(false)
    setIsEditing(true)
  }

  const loadOfflineAnswers = async () => {
    if (!contentId || !activityId || isOnline) {
      return false
    }

    const { class: studentClass } = getSubscription()

    const answerOffline = await getAnswerOffline({
      activity: activityId,
      class: String(studentClass.id),
      content_unit: contentId
    })

    if (!answerOffline) {
      setIsAnswered(false)
      return false
    }

    const answersObj = convertOfflineAnswers(answerOffline)

    setIsAnswered(true)
    setAnswers(answersObj)
    return true
  }

  return {
    isLoading,
    setIsLoading,
    isVerifyingRoutes,
    setIsVerifyingRoutes,
    activity,
    activityType: activity?.type,
    isActivitySuggestedType:
      activity?.suggested_application_type === 'activity',
    sendAnswerRecord,
    handleAnswerRecordCache,
    disableSend,
    getUserAnsweredRecords,
    sendLoading,
    allQuestionsAnswered,
    isAnswered,
    setIsAnswered,
    isUnavailable,
    isEditing,
    requestLevels,
    setIsEditing,
    questionIndex,
    setQuestionIndex,
    success,
    hasResponseError,
    errorMessage,
    isUploading,
    getActivityData,
    editAnsweredQuestion,
    cleanupState,
    getActivityAndAnswersData,
    activityOrEvaluationId,
    currentQuestionId: activity?.questions?.[questionIndex]?.id ?? 0,
    isExpeditionView,
    activityStateMachine,
    loadOfflineAnswers
  }
}

export default useStudentAnswer
