
import { IStudentsPerformance } from 'contentCacheManager'
import { atom } from 'jotai'
import { atomWithReset, atomWithStorage } from 'jotai/utils'

export interface ICurrentUserPerformance {
  user: IStudentsPerformance['user']
  userSchoolProfile: IStudentsPerformance['user_school_profile']
}

export const currentStudentPerformanceAtom = atom<ICurrentUserPerformance | undefined>(undefined)
export const expandedTextGeneratorAccordionAtom = atomWithStorage<boolean>('textGeneratorAccordion', true)
export const showParentalMessagesAtom = atom<Boolean>(false)

export const searchAtom = atomWithReset<string>('')
export const sortByAtom = atomWithReset<'asc' | 'desc'>('desc')

export const searchTermReportsAtom = atomWithReset<string>('')
export const sortByReportsAtom = atomWithReset<string>('situation')

export const lastUpdatedDateAtom = atomWithReset<Date | null>(null)
