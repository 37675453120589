import { Grid, useTheme } from '@mui/material'
import { BigNumberItem } from 'components/common/reports/BigNumber/components'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Text2 } from 'components/design-system'
import { IStudentPerformance } from 'contentCacheManager'

import useStyle from 'components/common/reports/BigNumbersReady/styles'
import { BigNumbersLoading } from 'components/common/reports'
import { truncateExtension } from 'utils/string'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

interface IBigNumbersStudentProps {
  isLoading: boolean
  studentName: string
  studentPerformance: IStudentPerformance | undefined
}

export const BigNumbersStudent = ({ isLoading, studentName, studentPerformance }: IBigNumbersStudentProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyle()

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.READY)

  const studentParticipation = (studentPerformance?.per_participation ?? 0) * 100
  const answeredActivities = String(`${studentPerformance?.nr_answered_activity ?? '-'}/${studentPerformance?.nr_released_activity ?? '-'}` ?? '-')
  const answeredEvaluations = String(`${studentPerformance?.nr_answered_evaluation ?? '-'}/${studentPerformance?.nr_released_evaluation ?? '-'}` ?? '-')
  const studentEvaluation = studentPerformance?.dec_performance !== undefined && studentPerformance?.nr_corrected_evaluation ? studentPerformance?.dec_performance : '-'

  const bigNumbersStudentData = {
    studentParticipation,
    answeredActivities,
    answeredEvaluations,
    studentEvaluation
  }

  useEffect(() => {
    setStateMachine(
      isLoading
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading])

  const states = {
    IDLE: <></>,
    LOADING: <BigNumbersLoading />,
    READY: (
      <Grid container item xs={12} rowSpacing={2}>
        <Grid item>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colorBrand.medium}
          >
            {t('Visão geral de {{studentName}}', { studentName: truncateExtension(studentName, 20) })}
          </Text2>
        </Grid>

        <Grid
          container
          width='100%'
          spacing={8}
          alignItems='flex-start'
          justifyContent='space-between'
          marginTop={3.5}
          mx='auto'
          className={classes.bigNumbesReportsContainer}
        >
          <BigNumberItem
            title={t('Participação do Estudante')}
            percentage={bigNumbersStudentData.studentParticipation}
            valueText={studentPerformance?.per_participation === null || typeof studentPerformance?.per_participation === 'undefined' ? '-' : undefined}
          />
          <BigNumberItem
            title={t('Atividades realizadas')}
            valueText={bigNumbersStudentData.answeredActivities}
          />
          <BigNumberItem
            title={t('Avaliações realizadas')}
            valueText={bigNumbersStudentData.answeredEvaluations}
          />
          <BigNumberItem
            percentage={typeof bigNumbersStudentData.studentEvaluation === 'number' ? (bigNumbersStudentData.studentEvaluation * 10) : 0}
            valueText={`${bigNumbersStudentData.studentEvaluation.toLocaleString('pt-BR') ?? '-'}`}
            title={t('Média do estudante')}
          />
        </Grid>
      </Grid >
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
