import React, { useState } from 'react'
import Linkify from 'react-linkify'
import { Modal, Backdrop } from '@mui/material'
import { IScheduleResponse } from 'services/types/schedule'
import { Cover } from './Cover'
import useStyle from './style'
import { CardFileHover, Gallery } from 'components/common'

interface IScheduleOriginalContentProps extends IScheduleResponse {
  isOpen: boolean
  onClose: () => void
  dataTestIdOpenAttachment?: string
  dataTestIdClose?: string
}

export const ScheduleOriginalContent: React.FC<IScheduleOriginalContentProps> = ({
  isOpen,
  onClose,
  title,
  content,
  discipline,
  files,
  subscriptions,
  class: theClass,
  dataTestIdOpenAttachment,
  dataTestIdClose
}) => {
  const classes = useStyle()
  const [currentIndex, setCurrentIndex] = useState<number | null>(null)
  const filesUrls = files?.map(cur => cur.url) ?? []

  return (
    <Modal
      aria-labelledby='schedule-modal-title'
      aria-describedby='schedule-modal-description'
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <div className={classes.modalWrapper}>
        <div>
          <Cover
            handleCloseModal={onClose}
            discipline={discipline}
            title={title as string}
            dataTestIdClose={dataTestIdClose}
          />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <div className={classes.mobileExpeditionTitle}>
              {title}
            </div>
            <Linkify>
              {content}
            </Linkify>
          </div>
          {(filesUrls || [])?.length > 0 && (
            <div className={classes.contentFiles}>
              <span className={classes.txtTitle}>Anexos</span>
              <div className={classes.groupFiles}>
                {filesUrls.map((fileUrl: string, index: number) =>
                  <CardFileHover
                    key={index}
                    fileUrl={fileUrl}
                    isActive={index === currentIndex}
                    onClick={() => setCurrentIndex(index)}
                    dataTestId={dataTestIdOpenAttachment ?? 'event_open_attachment'}
                  />
                )}
              </div>
              <Gallery medias={filesUrls} activeIndex={currentIndex} setActiveIndex={index => setCurrentIndex(index)} paginationDataTestId='event_paginate_attachment' />
            </div>
          )}
          <div className={classes.contentSubscriptions}>
            <span className={classes.txtTitle}>Destinatários</span>
            <span className={classes.txtDescription}>
              {subscriptions?.length === 0
                ? `${theClass?.name ?? ''}`
                : ((subscriptions ?? []).map(subscription => `${subscription.user_id.name} ${subscription.user_id.surname}`)).join(', ')}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ScheduleOriginalContent
