import * as React from 'react'
import { Grid, Theme } from '@mui/material'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { createStyles, makeStyles } from '@mui/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { ResourceCaption } from 'components/common'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      width: '100%',
      maxWidth: '720px',
      height: 'inherit',
      [theme.breakpoints.down('sm')]: {
        height: 'min-content'
      }
    },
    boxImage: {
      position: 'relative',
      height: 'inherit',
      margin: 'auto',
      maxWidth: '75%',
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '720px'
      }
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    buttonExpand: {
      position: 'absolute' as 'absolute',
      top: 10,
      right: 10,

      '& button': {
        backgroundColor: 'rgba(204,204,204,0.5)',

        '&:hover': {
          backgroundColor: 'rgba(204,204,204,0.8)'
        }
      }
    },
    icon: {
      color: theme.colors.neutral.lightBase,
      fill: theme.colors.neutral.lightBase
    },
    imageLegend: {
      width: '100%',
      position: 'absolute',
      bottom: '.45rem',
      left: 0,
      padding: '0.5rem 1rem',
      color: theme.colors.neutral.lightBase,
      background: 'rgba(0, 0, 0, 0.5)',
      textTransform: 'uppercase',
      fontSize: theme.font.fontSize.xxxs
    },
    modal: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '80%',
      bgcolor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      p: 0,
      textAlign: 'center'
    },
    imageModal: {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    buttonCloseModal: {
      position: 'fixed' as 'fixed',
      top: 10,
      right: 20
    },
    heightInherit: {
      height: 'inherit'
    }
  })
)

interface IExpandableImageProps {
  src: string
  description?: string | null
  image?: string
  type?: 'cover'
  source?: string | null
  className?: string
}

export const ExpandableImage: React.FC<IExpandableImageProps> = ({
  image,
  src,
  description,
  source,
  className,
  ...props
}) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const classes = useStyle()

  return (
    <>
      <Grid container spacing={1} className={`${classes.boxContainer} ${className ?? ''}`}>
        <Grid item xs={12} className={classes.heightInherit}>
          <div className={classes.boxImage}>
            <img src={src} className={classes.image} alt={description ?? ''} />
            {/* {(source ?? description)
              ? <span className={classes.imageLegend}>{source ?? ''}</span>
              : ''
            } */}
            <div className={classes.buttonExpand}>
              <IconButton
                aria-label='delete'
                size='small'
                onClick={handleOpen}
              >
                <FullscreenIcon className={classes.icon} />
              </IconButton>
            </div>
            <ResourceCaption source={source} description={description}></ResourceCaption>
          </div>
        </Grid>
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box>
          <div className={classes.modal}>
            <img src={src} alt={description ?? ''} className={classes.imageModal} />
          </div>
          <div className={classes.buttonCloseModal}>
            <IconButton aria-label='delete' size='large' onClick={handleClose}>
              <CancelIcon className={classes.icon} />
            </IconButton>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ExpandableImage
