import { useAtom } from 'jotai'
import { getActivitiesByContent } from 'services/activity'
import { useParams } from 'react-router-dom'
import { LoadingWrapper } from 'components/common'
import { createRef, useEffect, useState } from 'react'
import { DisciplineCodeEnum, IActivityResponse } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'
import ErrorWrapper from 'components/common/ErrorWrapper'
import NaturalCompare from 'string-natural-compare'
import { contentUnitAtom, SubTabsMenusTeacher } from 'pages/ContentUnit/atomStore'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'

import { ReactComponent as CloeCIcon } from 'assets/cloe-c-icon-teacher.svg'
import { toast } from 'components/design-system/Toast/manager'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import { Badge } from '@mui/material'
import { Correction, Finished, Liberated, Originals } from './components'
import { cloneObject } from 'utils/array'
import ErrorBoundary from 'components/common/ErrorBoundary'

import useStyle from './style'
import { currentSubMenuTabAtom, previousSubMenuTabAtom, subMenuTabsAtom } from '../../SideMenu/components/atomStore'
import { useResetAtom } from 'jotai/utils'
import { getActivitiesFilteredByStatusForTeacher } from 'rules/activitiesByStatus'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shoetest = require('shoetest')

interface IProcessedEvaluations {
  evaluationsAvailable: IActivityResponse[]
  evaluationsInProgress: IActivityResponse[]
  evaluationsCorrection: IActivityResponse[]
  evaluationsCompleted: IActivityResponse[]
}

const EMPTY_PROCESSED_EVALUATIONS = {
  evaluationsAvailable: [],
  evaluationsInProgress: [],
  evaluationsCorrection: [],
  evaluationsCompleted: []
}

export const TeacherEvaluations: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { gradeTypeCode, gradeCode, classId, contentId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string }>()
  const [evaluations, setEvaluations] = useState<IActivityResponse[]>()
  const [hasResponseError, setHasResponseError] = useState<boolean>(false)
  const [contentUnit] = useAtom(contentUnitAtom)
  const [previousSubMenuTab] = useAtom(previousSubMenuTabAtom)
  const resetPreviousSubMenuTab = useResetAtom(previousSubMenuTabAtom)
  const [currentSubMenuTab, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [, setSubMenuTabs] = useAtom(subMenuTabsAtom)

  const [storedEvaluations, setStoredEvaluations] = useState<IProcessedEvaluations>(EMPTY_PROCESSED_EVALUATIONS)
  const [processedEvaluations, setProcessedEvaluations] = useState<IProcessedEvaluations>(EMPTY_PROCESSED_EVALUATIONS)
  const [searchPhrase, setSearchPhrase] = useState<string>('')

  const isCloeExpand = contentUnit?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI) ?? false
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const searchRef = createRef<any>()

  const classes = useStyle({ currentSubMenuTab })

  const currentTabIndex = (currentSubMenuTab ?? 0) as unknown as SubTabsMenusTeacher

  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)

  const subMenuTabsDefault = [
    {
      title: t('Originais'),
      iconComponent: <CloeCIcon />,
      dataTestid: ''
    }
  ]

  useEffect(() => {
    handleTabsAppearance(evaluations)

    if (previousSubMenuTab) {
      setCurrentSubMenuTab(previousSubMenuTab)
      resetPreviousSubMenuTab()
    }

    return () => {
      resetSubMenuTabs()
    }
  }, [evaluations])

  const search = (pattern: string) => {
    const evaluationsTab = {
      [SubTabsMenusTeacher.ORIGINALS]: processedEvaluations.evaluationsAvailable,
      [SubTabsMenusTeacher.LIBERATED]: processedEvaluations.evaluationsInProgress,
      [SubTabsMenusTeacher.CORRECTION]: processedEvaluations.evaluationsCorrection,
      [SubTabsMenusTeacher.FINISHED]: processedEvaluations.evaluationsCompleted
    }
    const evaluationsTabKey = {
      [SubTabsMenusTeacher.ORIGINALS]: 'evaluationsAvailable',
      [SubTabsMenusTeacher.LIBERATED]: 'evaluationsInProgress',
      [SubTabsMenusTeacher.CORRECTION]: 'evaluationsCorrection',
      [SubTabsMenusTeacher.FINISHED]: 'evaluationsCompleted'
    }

    setSearchPhrase(pattern)

    const selectedEvaluationsTab = evaluationsTab[currentTabIndex]

    const searchedEvaluations = cloneObject(selectedEvaluationsTab).filter(
      (cur: IActivityResponse) => {
        if (shoetest.test(pattern, cur.title)) return true

        return false
      }
    )

    setProcessedEvaluations(old => ({
      ...old,
      [evaluationsTabKey[currentTabIndex]]: searchedEvaluations
    }))
  }

  const clear = () => {
    setProcessedEvaluations(storedEvaluations)
    setSearchPhrase('')
  }

  if (!contentId) {
    return (
      <>
        {t('Not Found', true)}
      </>
    )
  }

  const getData = async () => {
    setLoading(true)
    const response = await getActivitiesByContent(parseInt(classId), contentId, ContentTypeEnum.evaluation)
    if (response.success) {
      setEvaluations(response.data)
    } else {
      setHasResponseError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    void getData()
  }, [])

  const handleTabsAppearance = async (evaluations: IActivityResponse[] | undefined) => {
    try {
      const evaluationsOrdered = evaluations?.map(cur => {
        // making the title just one to put in alphabetically order
        cur.title = cur.title ?? cur.activity_name
        cur.title = cur.title.trim()
        return cur
      }).sort((a, b) => NaturalCompare(a.title, b.title, { caseInsensitive: true }))

      const evaluationsByStatus = getActivitiesFilteredByStatusForTeacher(evaluationsOrdered ?? [])

      setProcessedEvaluations({
        evaluationsAvailable: evaluationsByStatus.available,
        evaluationsInProgress: evaluationsByStatus.inProgress,
        evaluationsCorrection: evaluationsByStatus.correction,
        evaluationsCompleted: evaluationsByStatus.completed
      })
      setStoredEvaluations({
        evaluationsAvailable: evaluationsByStatus.available,
        evaluationsInProgress: evaluationsByStatus.inProgress,
        evaluationsCorrection: evaluationsByStatus.correction,
        evaluationsCompleted: evaluationsByStatus.completed
      })

      setSubMenuTabs([
        ...subMenuTabsDefault,
        {
          title: t('Liberadas'),
          iconComponent: <ShareOutlinedIcon />,
          dataTestid: '',
          disabled: !evaluationsByStatus.inProgress.length
        },
        {
          title: t('Para correção'),
          iconComponent:
            <Badge
              badgeContent={evaluationsByStatus.correction.length}
              max={99}
              className={classes.badge}
            >
              <AssignmentTurnedInOutlinedIcon />
            </Badge>,
          dataTestid: '',
          disabled: !evaluationsByStatus.correction.length
        },
        {
          title: t('Finalizadas'),
          iconComponent: <VerifiedOutlinedIcon />,
          dataTestid: '',
          disabled: !evaluationsByStatus.completed.length
        }
      ])
    } catch (err) {
      toast.handler({
        content: 'Ocorreu um erro ao buscar por atividades',
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const defaultProps = {
    isCloeExpand,
    handleSearch: search,
    handleClear: clear,
    searchRef,
    searchPhrase,
    contentId,
    gradeCode,
    gradeTypeCode,
    classId
  }

  const tabs = {
    [SubTabsMenusTeacher.ORIGINALS]: <Originals evaluationsAvailable={processedEvaluations.evaluationsAvailable} {...defaultProps} />,
    [SubTabsMenusTeacher.LIBERATED]: <Liberated evaluationsInProgress={processedEvaluations.evaluationsInProgress} {...defaultProps} />,
    [SubTabsMenusTeacher.CORRECTION]: <Correction evaluationsCorrection={processedEvaluations.evaluationsCorrection} {...defaultProps} />,
    [SubTabsMenusTeacher.FINISHED]: <Finished evaluationsCompleted={processedEvaluations.evaluationsCompleted} {...defaultProps} />
  }

  return (
    <div className={classes.activitiesContainer}>
      <ErrorBoundary>
        <LoadingWrapper type={'linear'} loading={loading}>
          <ErrorWrapper hasError={hasResponseError} errorMessage={t('Não foi possível encontrar as avaliações', true)?.toString()}>
            {tabs[currentTabIndex]}
          </ErrorWrapper>
        </LoadingWrapper>
      </ErrorBoundary>
    </div>
  )
}

export default TeacherEvaluations
