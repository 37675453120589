import { ExpeditionCachedTable } from './../../'
import { makeDatabaseInstanceOffline } from './../../databaseInstance'

export const getExpeditionCached = async (expedition: number | ExpeditionCachedTable) => {
  let currentExpedition

  if (expedition instanceof ExpeditionCachedTable) {
    currentExpedition = await makeDatabaseInstanceOffline().expeditionsCached.where('expeditionId').equals(expedition.expeditionId).first()
  } else {
    currentExpedition = await makeDatabaseInstanceOffline().expeditionsCached.where('expeditionId').equals(expedition).first()
  }
  return currentExpedition
}
