import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  title: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.darkBase
  }
}))
