import useStyles from './style'

export interface IIconImageProps{
  size?: 'small' | 'medium' | 'large'
  image?: string
}

export const IconImage: React.FC<IIconImageProps> = ({ size = 'medium', image }) => {
  const classes = useStyles()

  return (
    image ? <img src={image} className={`${classes.root} ${size}`} title={image} /> : null
  )
}
