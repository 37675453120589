import { Redirect } from 'react-router-dom'
// constants and types
import {
  CONTENT_UNIT_TEACHER,
  DASHBOARD_TEACHER,
  ROOT,
  TAUGHT_DISCIPLINES,
  EDIT_SINGLE_RECORD,
  SINGLE_RECORD,
  MILESTONE,
  MILESTONES_BY_ID,
  PROGRAM_CONTENT,
  PENDING_ACTIVITIES
} from 'navigation/CONSTANTS'
import { IUserSchoolProfileTypeEnum } from 'services/types'

// pages imports
import {
  Dashboard,
  SingleRecord,
  EditSingleRecord,
  Milestone,
  TaughtDisciplines,
  MyClasses,
  ProgramContent,
  PendingActivities
} from 'pages'
import ContentUnit from 'pages/ContentUnit'

// local imports
import { IRoutesGroupProps } from '..'
import { ClassMiddleware, OnlyCurrentSchoolPeriod, ShowCloeProMiddleware, HideCloeProMiddleware } from 'navigation/middlewares'

const teacherPrivateRoutes: IRoutesGroupProps = {
  profileTypes: [IUserSchoolProfileTypeEnum.teacher],
  routes: [
    {
      path: SINGLE_RECORD(':gradeTypeCode', ':gradeCode'),
      component: SingleRecord,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: EDIT_SINGLE_RECORD(':gradeTypeCode', ':gradeCode'),
      component: EditSingleRecord,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: MILESTONE(':gradeTypeCode', ':gradeCode'),
      component: Milestone,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: MILESTONES_BY_ID(':gradeTypeCode', ':gradeCode'),
      component: Milestone,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, ShowCloeProMiddleware]
    },
    {
      path: TAUGHT_DISCIPLINES,
      component: TaughtDisciplines,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: DASHBOARD_TEACHER(':gradeTypeCode', ':gradeCode'),
      component: Dashboard,
      isPrivate: true,
      exact: true,
      middlewares: [ShowCloeProMiddleware]
    },
    {
      path: CONTENT_UNIT_TEACHER(':gradeTypeCode', ':gradeCode'),
      component: ContentUnit,
      isPrivate: true,
      middlewares: [ShowCloeProMiddleware]
    },
    {
      path: ROOT,
      component: MyClasses,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: PROGRAM_CONTENT,
      component: ProgramContent,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: PENDING_ACTIVITIES(),
      component: PendingActivities,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: '/class/:classId/',
      component: () => <Redirect to={{ pathname: '/' }} />,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware]
    }
  ]
}

const defaultMiddlewares = [ClassMiddleware]

teacherPrivateRoutes.routes = teacherPrivateRoutes.routes.map(route => ({
  ...route,
  middlewares: [...(route?.middlewares ?? []), ...defaultMiddlewares]
}))

export {
  teacherPrivateRoutes
}
