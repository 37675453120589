import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useStore } from 'store'

import Grid from '@mui/material/Grid'
import { useMediaQuery, useTheme } from '@mui/material'
import { NavigateBeforeOutlined, NavigateNextOutlined } from '@mui/icons-material'
import OnlyProfile from 'components/common/OnlyProfile'
import { Content, Resource, Text } from 'components/common'
import { Button } from 'components/design-system'
import InstructionalContents from '../Instructional'
import { ShallWePractise } from '../ShallWePractise'
import { BusinessError } from 'navigation/BusinessError'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { ResourceGallery } from 'components/common/ResourceGallery'

import { ISectionResponse, IResourceResponse, IContentResponse, IUserSchoolProfileTypeEnum, DisciplineCodeEnum } from 'services/types'
import { CONTENT_UNIT_STUDENT_CONTENT, CONTENT_UNIT_TEACHER_CONTENT } from 'navigation/CONSTANTS'

import { useContents } from '../useContents'
import { parseKaTex } from 'utils/katex'
import Appcues from 'utils/appcues'
import isProjectViewModeRule from 'rules/isProjectViewModeRule'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'

import useStyles from './style'
import PrintButton from '../../PrintButton'
import { useAtom } from 'jotai'
import { RESET, useResetAtom } from 'jotai/utils'
import { currentSubMenuTabAtom, subMenuTabsAtom } from '../../SideMenu/components'
import { _isOpenInstructionalAtom, useToggleInstructionalAtom } from '../../atomStore'

export const DidacticContents: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { content, didacticContent: didactic } = useContents()

  const { gradeTypeCode, gradeCode, classId, contentId, sectionId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string, sectionId: string }>()
  const history = useHistory()

  const isProjectViewMode = isProjectViewModeRule(content?.grade?.grade_type ?? 0, content?.disciplines?.map(d => d.code) ?? [])

  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const hasDidacticContent = didactic && didactic?.sections?.length > 0

  let section: boolean | ISectionResponse = false
  let sectionIndex = 1
  if (hasDidacticContent) {
    section = (sectionId ? didactic.sections.find(s => s.id === parseInt(sectionId)) : didactic.sections[0]) as ISectionResponse
    sectionIndex = sectionId ? didactic.sections.findIndex(s => s.id === parseInt(sectionId)) + 1 : 1
  }

  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  const [currentSubMenuTab, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [, setSubMenuTabs] = useAtom(subMenuTabsAtom)

  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)

  const { toggleInstructionalAtom } = useToggleInstructionalAtom()

  const [isOpenInstructionalStandard, setIsOpenInstructionalStandard] = useAtom(_isOpenInstructionalAtom)
  const [isOpenInstructionalToggle, setIsOpenInstructionalToggle] = useAtom(toggleInstructionalAtom)

  const isOpenInstructional = isMobile ? isOpenInstructionalStandard : isOpenInstructionalToggle
  const setIsOpenInstructional = isMobile ? setIsOpenInstructionalStandard : setIsOpenInstructionalToggle

  const classes = useStyles()

  useEffect(() => {
    setCurrentSubMenuTab(0)
    handleSubMenuTabs()

    return () => {
      setIsOpenInstructionalStandard(false)

      setCurrentSubMenuTab(RESET)
      resetSubMenuTabs()
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      setIsOpenInstructionalStandard(false)
    }
  }, [isMobile])

  const handleSubMenuTabs = () => {
    if (!hasDidacticContent || !section) return

    const cards = didactic.sections?.map((section, index) => ({
      idSection: section.id,
      section,
      position: index + 1,
      title: section.title,
      subtitles: section.contents.map(
        content => content.title as string
      ),
      dataTestid: 'content_sections_select_item'
    }))

    setSubMenuTabs(cards)

    if (hasDidacticContent) {
      let index = 0
      let selectedIndex = 0

      didactic.sections.forEach(section => {
        if (section.id === parseInt(sectionId)) {
          selectedIndex = index
        }
        index++
      })
      setCurrentSubMenuTab(selectedIndex)
    }
  }

  const renderInstructional = () => {
    const instructionalExpedition = content?.instructional_contents.find(cur => cur.type === 'expedition')
    if (
      content?.grade?.code?.includes('EI_') &&
      !window.location.pathname.includes('/expedition')
    ) {
      return <></>
    }

    if (!instructionalExpedition) {
      return <BusinessError error={t('Não foi possivel encontrar o conteudo instrucional dessa unidade de conteudo')} />
    }

    if (!hasDidacticContent) {
      return <InstructionalContents
        instructional={instructionalExpedition}
        contentId={contentId}
        language={content.language}
        disabledMobileFullPage
        hasDidacticContent={hasDidacticContent}
      />
    }

    return (<>
      <Grid item
        sx={{
          maxWidth: isOpenInstructional ? '50%' : '0',
          transition: 'all ease-in-out .2s',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100% !important'
          }
        }}
      >
        <Grid item container className={classes.instructionalDesktop}
          sx={{
            display: isOpenInstructional || !isMobile ? 'inherit' : 'none'
          }}
        >
          <Grid item
            sx={{ marginLeft: isOpenInstructional ? '-19px !important' : '-11px !important' }}
            className={classes.expandButton}
            onClick={handleOpenCloseInstructional}
            data-testid='contetent_instructional_expand_retract'
          >
            {
              isOpenInstructional
                ? <><MapOutlinedIcon />{isProjectViewMode ? t('Ocultar Instruções') : t('Ocultar Instrucional')}</>
                : <><MapOutlinedIcon />{isProjectViewMode ? t('Instruções para Expedição') : t('Mostrar Instrucional')}</>
            }
          </Grid>
          <InstructionalContents
            instructional={instructionalExpedition}
            contentId={contentId}
            language={content.language}
            hasDidacticContent={hasDidacticContent}
          />
        </Grid>
      </Grid>
    </>)
  }

  useEffect(() => {
    if (currentSubMenuTab === -1) return
    document.getElementById('contentArea')?.scrollTo(0, 0)
    window.scrollTo(0, 0) // go to top
    const sectionIdSelected = didactic?.sections[currentSubMenuTab]?.id?.toString()

    setIsOpenInstructionalStandard(false)

    if (!sectionIdSelected || !contentId) return

    if (
      profileType === IUserSchoolProfileTypeEnum.student
    ) {
      history.push(CONTENT_UNIT_STUDENT_CONTENT(contentId, sectionIdSelected))
    }

    if (!gradeTypeCode || !gradeCode || !classId) return

    if (content?.grade?.code?.includes('EI_')) {
      if (!window.location.pathname.includes('/expedition')) {
        // se não for a página de conteúdo, esconde o instrucional (já que já tem a aba) e faz a navegação de histórico funcionar
        history.push(CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, contentId, sectionIdSelected))
      }
    } else {
      history.push(CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, contentId, sectionIdSelected))
    }
  }, [currentSubMenuTab])

  const handleOpenCloseInstructional = () => {
    setIsOpenInstructional(!isOpenInstructional)
    Appcues.track('prof_instrucoes')
  }

  const handleResourceMedias = (resource: IResourceResponse) => {
    if (resource.medias?.length && resource.medias?.length > 1) {
      const formattedMedias = resource.medias.map(media => ({ media, source: resource.source, description: resource.description }))
      return <ResourceGallery medias={formattedMedias} source={resource.source} description={resource.description} />
    } else {
      return <Resource resource={resource} />
    }
  }

  const isNotFirstPage = currentSubMenuTab > 0
  const isNotLastPage = currentSubMenuTab < content?.didactic_content?.sections?.length - 1

  const printMode = profileType === IUserSchoolProfileTypeEnum.student ? IUserSchoolProfileTypeEnum.student : IUserSchoolProfileTypeEnum.teacher

  return (
    <Grid container>
      {hasDidacticContent && section &&
        <>
          <Grid item sx={{
            maxWidth: isOpenInstructional ? '50%' : '100%',
            transition: 'all ease-in-out .2s',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100% !important'
            }
          }}>
            <div className={classes.readingContainer}>
              <Grid container m={0} justifyContent='space-between'>
                <Grid item xs={9}>
                  <div className={classes.sectionIndex}>{t('Seção')} {sectionIndex}</div>
                  <h1 className={classes.title}>
                    {parseKaTex(section.title)}
                  </h1>
                </Grid>
                <Grid item xs={3} textAlign='right'>
                  <PrintButton
                    content={contentId}
                    section={sectionId ?? null}
                    mode={printMode}
                    lng={content.language}
                    dataTestId='content_print_content'
                    id='print_expedition_tab_content'
                  />
                </Grid>
              </Grid>
              {section.text !== null && section.text && <Text key={content.id} content={section.text ?? ''} />}
              {
                section?.resources?.length
                  ? section.resources.map(resource => handleResourceMedias(resource))
                  : ''
              }
              {
                section.contents.map((content: IContentResponse) => {
                  return <Content content={content} key={content.id} type={content.content_type} showGalleryFormat />
                })
              }
              <ShallWePractise activities={section?.activities} content={content} />

            </div>
            <div className={`${classes.contentButtons} ${!isNotFirstPage && 'first'}`}>
              {
                isNotFirstPage &&
                <Button className={classes.contentButton} variant='outlined' data-testid='content_previous_page' onClick={() => setCurrentSubMenuTab(currentSubMenuTab - 1)}>
                  <NavigateBeforeOutlined /> {isMobile ? t('Anterior', true) : t('Página anterior', true)}
                </Button>
              }
              {
                isNotLastPage &&
                <Button className={classes.contentButton} variant='outlined' data-testid='content_next_page' onClick={() => setCurrentSubMenuTab(currentSubMenuTab + 1)}>
                  {isMobile ? t('Próxima', true) : t('Próxima página', true)} <NavigateNextOutlined />
                </Button>
              }
            </div>
          </Grid>
        </>
      }
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        {renderInstructional()}
      </OnlyProfile>
    </Grid>
  )
}

export default DidacticContents
