import useStyles from './style'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import { useState, useEffect, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
import { CONTENT_UNIT_STUDENT_EXPEDITION, CONTENT_UNIT_TEACHER_CONTENT, CONTENT_UNIT_STUDENT_CONTENT } from 'navigation/CONSTANTS'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { useStore } from 'store'
import { parseKaTex } from 'utils/katex'
import { useAtom } from 'jotai'
import { activityIndexSelectedAtom, allActivities } from 'pages/ContentUnit/atomStore'
import { Grid } from '@mui/material'

export interface CardProps {
  position?: number
  title?: string
  subtitles?: string[]
  section?: Object
  expanded?: boolean
  iconType?: string
  idSection?: number | string
  idActivity?: number | string
  onClick?: () => void
  fowardedRef?: React.LegacyRef<HTMLDivElement>
  endAdornment?: React.ReactNode
  dataTestid?: string
}

function setIconType(iconType: string) {
  switch (iconType) {
    case 'evaluation':
      return <BallotOutlinedIcon />
    case 'activity':
      return <ExtensionOutlinedIcon />
    default:
      return <DescriptionOutlinedIcon />
  }
}

const Card = ({
  expanded = false,
  iconType = 'content',
  position,
  title,
  idSection,
  idActivity,
  onClick,
  endAdornment,
  dataTestid
}: CardProps) => {
  const { gradeTypeCode, gradeCode, contentId, sectionId, activityId, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string, sectionId?: string, activityId?: string }>()
  const cardExpanded = expanded
  const [cardActive, setCardActive] = useState(false)
  const titleRef = useRef<HTMLAnchorElement | null>()
  const { subscription, profile } = useStore()
  const [activities] = useAtom(allActivities)
  const [, setActivityIndexSelected] = useAtom(activityIndexSelectedAtom)
  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  useEffect(() => {
    if (sectionId) {
      setCardActive(false)
      if (sectionId === idSection?.toString()) {
        setCardActive(true)
      }
    }
  }, [sectionId])

  useEffect(() => {
    if (activityId) {
      setCardActive(false)
      if (activityId === idActivity?.toString()) {
        setCardActive(true)
      }
      setActivityIndexSelected(activities?.findIndex(a => a.id === +activityId) ?? 0)
    }
  }, [activityId])

  const props = { cardExpanded, cardActive, titleWidth: titleRef.current?.clientWidth }
  const classes = useStyles({ ...props })

  const getLink = () => {
    if (profileType === IUserSchoolProfileTypeEnum.teacher) {
      return CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, contentId, idSection as string)
    } else if (profileType === IUserSchoolProfileTypeEnum.student && idActivity) {
      return CONTENT_UNIT_STUDENT_EXPEDITION(contentId, idActivity as string)
    }
    return CONTENT_UNIT_STUDENT_CONTENT(contentId, idSection as string)
  }

  const link = getLink()

  return (
    <Link
      to={link}
      ref={(el) => {
        titleRef.current = el
      }}
      className={classes.cardWrapper}
      id={cardActive ? 'card-selected' : ''}
      onClick={onClick}
      data-testid={dataTestid}
    >
      <Grid
        container
        alignItems='center'
        columnSpacing={1}
        className={cardActive ? classes.wrapperDefaultSelected : classes.wrapperDefault}
      >
        {
          position &&
            <Grid item>
              <div className={classes.position}>
                {position}
              </div>
            </Grid>
        }
        <Grid item className={classes.icon}>
          {setIconType(iconType)}
        </Grid>
        <Grid item xs className={classes.title}>
          {parseKaTex(title ?? '')}
        </Grid>
        {endAdornment &&
          <Grid item className={classes.icon}>
            {endAdornment}
          </Grid>
        }
      </Grid>
    </Link>
  )
}

export default Card
