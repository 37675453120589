import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  scroll: {
    backgroundColor: theme.colorBrand.lightest,
    paddingTop: '0px',
    padding: '0 16px',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.font.fontWeight.semibold,
    gap: '8px',
    fontSize: theme.font.fontSize.sm,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  titleArea: {
    display: 'flex',
    height: (isIOS) => isIOS ? '58px' : '48px',
    padding: theme.spacingInline.xxxs,
    borderRadius: '8px 8px 0 0',
    color: theme.colorBrand.darkest,
    backgroundColor: theme.colorBrand.lightest,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      color: theme.colorBrand.darkest
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
})
)
