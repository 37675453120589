import axios from 'axios'
import { IApiResponse } from 'services/types'
import { IUploadResponse, IUploadApiService, IUploadApiAuthenticationResponse, IUploadAuthenticationFileRequest, IFileUploadResponse } from 'services/types/upload'
import Storage from 'utils/storage'
import { UPLOAD } from './CONSTANTS'

/*
* Get all records and answers from an activity by user profile. Return array of records or empty array
*
*/
export const uploadFile = async (data: FormData, onUploadProgress?: (progressEvent: any) => void): Promise<IApiResponse<IUploadResponse[]>> => {
  const client = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL })

  const response = await client({
    method: 'POST',
    url: UPLOAD(),
    data,
    params: {
      stopServiceWorking: true
    },
    onUploadProgress: onUploadProgress,
    headers: {
      Authorization: `Bearer ${Storage.token.get()}`,
      'Content-Type': 'multipart/form-data'
    }
  })

  return {
    success: response.status === 200 || response.status === 204,
    data: response.data
  }
}

export const deleteFile = async (id: number): Promise<IApiResponse<boolean>> => {
  const client = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL })

  const response = await client({
    method: 'DELETE',
    url: `/upload/files/${id}`,
    headers: {
      Authorization: `Bearer ${Storage.token.get()}`,
      'Content-Type': 'multipart/form-data'
    }
  })

  return {
    success: response.status === 200 || response.status === 204,
    data: response.status === 200 || response.status === 204
  }
}
export class UploadApiService implements IUploadApiService {
  client = axios.create({ baseURL: process.env.REACT_APP_SERVICES })
  token = Storage.token.get()
  schoolId = Storage.props.getProfile().school.id
  fileIndex = 0
  files
  setPercentage
  metaData

  constructor(files: File[], setPercentage: Function | null, metaData?: { [key: string]: string | number }) {
    this.files = files
    this.setPercentage = setPercentage
    this.metaData = metaData
  }

  // Animação não usa mais o onUploadProgress do axios pois ele necessita de usar formdata, mas é incompativel com o uploado para o S3
  calculateFileUploadProgress(progress: number) {
    if (!this.setPercentage) return

    const totalPercentage = Math.round(((this.fileIndex + progress) / this.files.length) * 100)

    this.setPercentage(totalPercentage)
  }

  async getAuthorizedUploadUrl(fileProps: IUploadAuthenticationFileRequest): Promise<IUploadApiAuthenticationResponse> {
    const response = await this.client.post('/files/request', fileProps, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    })
    return response.data
  }

  async uploadSingleFile(file: File): Promise<IFileUploadResponse> {
    const fileProps = {
      public: true,
      fileName: file.name,
      contentType: file.type,
      schoolId: 'schoolId'
    } as any

    if (this.metaData) {
      for (const key in this.metaData) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.metaData.hasOwnProperty(key)) {
          fileProps[key] = String(this.metaData[key])
        }
      }
    }

    const uploadAuthorizationResponse = await this.getAuthorizedUploadUrl(fileProps)
    this.calculateFileUploadProgress(0.8)
    const result = await axios({
      url: uploadAuthorizationResponse.url,
      method: 'PUT',
      data: file,
      headers: {
        'Content-Type': file.type
      }
    })
    this.calculateFileUploadProgress(1)
    return {
      status: result.status,
      message: result.statusText,
      url: uploadAuthorizationResponse.public_url,
      transaction_id: uploadAuthorizationResponse.transaction,
      name: file.name,
      thumb: uploadAuthorizationResponse.public_url,
      ext: file.type,
      id: null
    }
  }

  async uploadMultipleFiles(): Promise<IFileUploadResponse[]> {
    const responseMultipleFiles = []
    for await (const file of this.files) {
      try {
        const fileUploadResponse = await this.uploadSingleFile(file)
        this.fileIndex++
        responseMultipleFiles.push(fileUploadResponse)
      } catch (error) {
        this.fileIndex++
      }
    }
    return responseMultipleFiles
  }
}
