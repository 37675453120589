import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  recommendation: {
    backgroundColor: theme.colors.neutral.light10,
    padding: theme.spacingInset.sm,
    color: theme.colors.neutral.dark10,
    borderRadius: theme.borderRadius.md,
    margin: theme.spacingSquish.xs
  }
}))
