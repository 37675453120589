/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback } from 'react'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { MY_CLOE_CONTEXT, myCloeContextAtom, useToggleOpenMyCloeAtom } from 'navigation/components/MyCloe/atomStore'
import { IMediaResponse } from 'services/types'
import { IScheduleRequest } from 'services/types/schedule'
import { ContentTypeEnum } from 'services/types/content'
import {
  scheduleAtom,
  isHoursAndMinutesNowAtom,
  contentUnitCardAtom,
  subscriptionsAtom,
  subscriptionsAllSelectedAtom,
  reloadScheduleAtom,
  expeditionDisciplinesAtom,
  updateScheduleFileRecordsAtom,
  IScheduleAtom,
  updateScheduleDisciplinesAtom,
  updateContentSelectAtom
} from 'navigation/components/MyCloe/components/ScheduleCreator/atomStore'
import { contentUnitAtom, updateScheduleTimeAtom } from 'pages/ContentUnit/atomStore'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { createSchedule, updateSchedule } from 'services/schedule'
import { toast } from 'components/design-system/Toast/manager'
import Appcues from 'utils/appcues'
import { useCurricularStore } from 'store'
import Analytics from 'utils/analytics'
import { useContentUnitLinks } from 'utils/contentUnit/useContentUnitLinks'
import { useHistory } from 'react-router-dom'
import { deleteCloneActivity } from 'services/activity'

const useMyCloeSchedule = () => {
  // atoms
  const { toggleOpenMyCloeAtom } = useToggleOpenMyCloeAtom()
  const [isMyCloeOpen, setOpenMyCloe] = useAtom(toggleOpenMyCloeAtom)

  const myCloeContext = useAtomValue(myCloeContextAtom)
  const contentUnit = useAtomValue(contentUnitAtom)
  const [activity, setActivity] = useAtom(activityCurrentAtom)
  const setSchedule = useUpdateAtom(scheduleAtom)
  const setHoursAndMinutesToNow = useUpdateAtom(isHoursAndMinutesNowAtom)
  const updateContentSelect = useUpdateAtom(updateContentSelectAtom)
  const setContentUnitCard = useUpdateAtom(contentUnitCardAtom)
  const setSubscriptions = useUpdateAtom(subscriptionsAtom)
  const setSubscriptionsAllSelected = useUpdateAtom(subscriptionsAllSelectedAtom)
  const setReloadSchedule = useUpdateAtom(reloadScheduleAtom)
  const setExpeditionDisciplines = useUpdateAtom(expeditionDisciplinesAtom)
  const setfileRecords = useUpdateAtom(updateScheduleFileRecordsAtom)
  const setUpdateScheduleTime = useUpdateAtom(updateScheduleTimeAtom)
  const setScheduleDisciplines = useUpdateAtom(updateScheduleDisciplinesAtom)

  const history = useHistory()
  const { classId, contentId, gradeTypeCode, gradeCode } = useParams<{ classId: string, contentId: string, activityId: string, evaluationId: string, gradeTypeCode: string, gradeCode: string }>()
  const { t } = useTranslation()
  const { getExerciceURL } = useContentUnitLinks()

  const { currentDiscipline, disciplines, currentGrade, currentSegment } = useCurricularStore()

  const createScheduleHandler = () => {
    if (!activity && !contentId) return

    const schedule = {
      activity: activity?.id,
      class: parseInt(classId),
      content_unit: parseInt(contentId),
      disciplineId: Number(currentDiscipline?.id),
      start_date: undefined,
      end_date: undefined,
      room_link: '',
      type: activity ? 'existing_content' : 'original_content',
      subscriptions: []
    }

    setScheduleContentAndOpenMyCloe(schedule)
    setHoursAndMinutesToNow(true)
  }

  const editExistingContentScheduleHandler = () => {
    const schedule = {
      id: parseInt(activity?.schedule?.id ?? ''),
      activity: activity?.id,
      ask_for_records: activity?.schedule?.ask_for_records,
      class: parseInt(classId),
      content_unit: parseInt(contentId),
      disciplineId: Number(currentDiscipline?.id),
      start_date: new Date(activity?.schedule?.startDate ?? ''),
      end_date: new Date(activity?.schedule?.endDate ?? ''),
      room_link: '',
      type: 'existing_content',
      content: activity?.schedule?.content,
      has_updated: activity?.schedule?.has_updated,
      subscriptions: []
    }
    setHoursAndMinutesToNow(false)
    setScheduleContentAndOpenMyCloe(schedule)
  }

  const setScheduleContentAndOpenMyCloe = (schedule: IScheduleAtom) => {
    setSchedule(schedule)
    setSubscriptions([])
    setSubscriptionsAllSelected(true)
    setOpenMyCloe(true)

    if (contentUnit) {
      setScheduleDisciplines(contentUnit.disciplines)
      setContentUnitCard({
        contenUnit: contentUnit.id,
        title: contentUnit.title,
        cover: contentUnit.cover as IMediaResponse,
        coverMobile: contentUnit.cover as IMediaResponse,
        suggested_position: contentUnit.suggested_position,
        disciplines: contentUnit.disciplines,
        // @ts-expect-error:next-line
        grade: contentUnit.grade,
        didactic_content: contentUnit?.didactic_content
      })
    }
    if (activity) {
      updateContentSelect([{
        ...activity,
        id: activity?.id,
        selected: true,
        title: activity.title,
        type: activity.suggested_application_type as ContentTypeEnum
      }])
    }
  }

  const cleanSchedule = () => {
    setExpeditionDisciplines(null)
    setSchedule(prevSchedule => ({
      disciplineId: prevSchedule?.disciplineId,
      start_date: undefined,
      title: '',
      content: '',
      file_records: []
    }))
    updateContentSelect(null)
    setfileRecords(null)
    setSubscriptionsAllSelected(false)
    setSubscriptions([])
    setHoursAndMinutesToNow(true)
    if (myCloeContext === MY_CLOE_CONTEXT.HOME) {
      setContentUnitCard(null)
    }
    setOpenMyCloe(false)
  }

  const redirectToActivityPage = (schedule: IScheduleRequest) => {
    const isActivitySelected = (schedule.contentSelect ?? []).some(content => content.type && [ContentTypeEnum.activity, ContentTypeEnum.evaluation].includes(content.type))

    if (!isActivitySelected) {
      return
    }

    const selectedDiscipline = disciplines?.find(({ id }) => id === schedule.discipline)
    const activity = schedule?.contentSelect?.[0]

    setOpenMyCloe(false)
    setReloadSchedule(true)
    setActivity(null)
    cleanSchedule()

    const linkHrefContentUnit = getExerciceURL({
      currentDisciplineCode: selectedDiscipline?.code,
      gradeTypeCode: currentSegment?.code ?? gradeTypeCode ?? '',
      gradeCode: currentGrade?.code ?? gradeCode ?? '',
      classId,
      contentUnitId: String(schedule.content_unit),
      activityId: String(activity?.id),
      suggestedApplicationType: activity?.suggestedApplicationType ?? ''
    })
    history.push(linkHrefContentUnit)

    setUpdateScheduleTime(true)
  }

  const sendSchedule = async (schedule: IScheduleRequest, scheduleId?: string, isMaterialVision?: boolean) => {
    let response = null
    if (scheduleId) {
      response = await updateSchedule(scheduleId, schedule)
    } else {
      response = await createSchedule(schedule)
    }

    if (!response?.success) {
      if (isMaterialVision) {
        Analytics.recordEventClick({
          name: 'teacher_materials_add_error'
        })
      }

      if (!scheduleId && schedule.contentSelect?.length && schedule.contentSelect[0]?.id && schedule.contentSelect[0]?.type === 'activity') {
        await deleteCloneActivity(parseInt(schedule.contentSelect[0].id.toString()))
      }
      return
    }

    if (scheduleId) {
      toast.handler({
        content: t('Você atualizou o evento!'),
        duration: 5000,
        severity: 'success'
      })
    } else {
      [...new Array(schedule?.contentSelect?.length || 1)].forEach(() => {
        toast.handler({
          content: t('Você criou um novo evento!'),
          duration: 5000,
          severity: 'success'
        })
      })

      Appcues.track('prof_agenda_criar_post')
      if (isMaterialVision) {
        Analytics.recordEventClick({
          name: 'teacher_materials_add_success'
        })
      }
    }

    if (myCloeContext !== MY_CLOE_CONTEXT.HOME) {
      setOpenMyCloe(false)
      setUpdateScheduleTime(true)
    }
    setReloadSchedule(true)

    cleanSchedule()

    if (!scheduleId && myCloeContext !== MY_CLOE_CONTEXT.HOME) {
      redirectToActivityPage(schedule)
    } else {
      setOpenMyCloe(false)
    }
  }

  const handleCreateOrEditActivitySchedule = useCallback(() => {
    activity?.schedule
      ? editExistingContentScheduleHandler()
      : createScheduleHandler()
  }, [activity])

  const handleCreateOrEditSchedule = () => {
    setOpenMyCloe(true)
    createScheduleHandler()
  }

  const openMyCloeHandlers = {
    HOME: () => setOpenMyCloe(!isMyCloeOpen),
    ACTIVITY: handleCreateOrEditActivitySchedule,
    CONTENT_UNIT: handleCreateOrEditSchedule,
    CLASSES: () => {
      // This is intentional
    }
  }

  return {
    editExistingContentScheduleHandler,
    createExistingContentScheduleHandler: createScheduleHandler,
    sendSchedule,
    cleanSchedule,
    openMyCloeHandler: isMyCloeOpen ? openMyCloeHandlers.HOME : openMyCloeHandlers[myCloeContext],
    handleCreateOrEditActivitySchedule,
    isMyCloeOpen
  }
}

export default useMyCloeSchedule
