import { atom } from 'jotai'
import { IActivityResponse, IQuestionResponse } from 'services/types'

export const goToSelectedQuestionAtom = atom<IQuestionResponse | null>(null)
export const goToSelectedQuestionIndexAtom = atom<number>(0)

export const disableSendActivityRegisterAtom = atom<boolean>(true)
export const hideSendActivityRegisterBtnAtom = atom<boolean>(false)
export const activityQuestionsAtom = atom<IQuestionResponse[]>([])
export const currentActivityAtom = atom<IActivityResponse | undefined>(undefined)
export const activityIsEditingQuestionAtom = atom<boolean>(false)
export const activityIsSendingAnswerLoadingAtom = atom<boolean>(false)
