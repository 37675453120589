import React from 'react'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import OnlyProfile from 'components/common/OnlyProfile'
import DashboardStudent from './student'
import DashboardTeacher from './teacher'
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyle = makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    height: 'auto',
    paddingTop: theme.spacingInset.sm,

    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      padding: '0px',
      paddingTop: '64px'
    }
  }
}))

export const Dashboard: React.FC = () => {
  const classes = useStyle()
  return (
    <div className={classes.container}>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <DashboardStudent />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <DashboardTeacher />
      </OnlyProfile>
    </div>
  )
}

export default Dashboard
