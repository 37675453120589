import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    boxModal: {
      position: 'initial',
      textAlign: 'center',
      marginInline: 'auto',
      borderRadius: theme.borderRadius.sm,
      marginBlock: '50%',
      backgroundColor: theme.colors.neutral.lightBase,
      width: 'calc(100% - 40px)',
      padding: `${theme.spacingStack.nano} ${theme.spacingStack.nano} 0 ${theme.spacingStack.nano}`,
      [theme.breakpoints.up('sm')]: {
        position: 'initial',
        textAlign: 'center',
        marginTop: '7%',
        marginInline: 'auto',
        width: '40%',
        backgroundColor: theme.colors.neutral.lightBase,
        borderRadius: theme.borderRadius.sm,
        padding: `${theme.spacingStack.xxs} ${theme.spacingStack.xxs} 0 ${theme.spacingStack.xxs}`
      }
    },
    classesItensText: {
      fontSize: theme.font.fontSize.md,
      color: theme.colors.neutral.dark30,
      [theme.breakpoints.up('sm')]: {
        color: theme.colors.neutral.dark30,
        fontSize: theme.font.fontSize.sm
      }
    },
    classButton: {
      justifyContent: 'center',
      display: 'flex',
      paddingBlock: theme.spacingStack.xxxs
    },
    classCancelButton: {
      [theme.breakpoints.up('sm')]: {
        '&.ghost': {
          padding: `${theme.spacingStack.xxxs} ${theme.spacingStack.md}`,
          marginTop: theme.spacingStack.xxxs
        }
      },
      '&.ghost': {
        padding: `${theme.spacingStack.xxxs} ${theme.spacingStack.sm}`
      }
    },
    classDeleteButton: {
      [theme.breakpoints.up('sm')]: {
        '&.primary': {
          padding: `${theme.spacingStack.xxxs} ${theme.spacingStack.md}`,
          marginTop: theme.spacingStack.xxxs
        }
      },
      '&.primary': {
        padding: `${theme.spacingStack.xxxs} ${theme.spacingStack.sm}`,
        gap: theme.spacingStack.nano,
        fontWeight: `${theme.font.fontWeight.medium} !important`
      }
    },
    circularProgress: {
      color: `${theme.colors.neutral.lightBase} !important`,
      height: '30px !important',
      width: '30px !important'
    }
  })
)
