import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import CloeWormBackground from 'assets/cloe-worm-content-unit.svg'

export default makeStyles((theme: Theme) => createStyles({
  navArea: {
    gridArea: 'nav'
  },
  sideMenuArea: {
    gridArea: 'sideMenu',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacingInline.xxs,
      paddingRight: 0
    }
  },
  backLinkContainer: {
    paddingBottom: theme.spacingInset.sm,

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacingInset.sm
    }
  },
  sideMenuHandleButton: {
    cursor: 'pointer',

    position: 'absolute',
    marginLeft: `-${theme.spacingInline.xxs}`,
    width: '40px',
    height: '40px',
    background: theme.colorBrand.medium,
    borderRadius: `0 ${theme.borderRadius.md} ${theme.borderRadius.md} 0`,
    color: theme.colors.neutral.lightBase,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity easy-in 0.3s, display easy-in 0.3s',
    transitionBehavior: 'allow-discrete',

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  hideSideMenuHandler: {
    display: 'none',
    opacity: '0 !important'
  },
  contentArea: ({ isTeacher }: { isTeacher: boolean }) => ({
    gridArea: 'content',
    padding: theme.spacingInline.xxs,
    paddingBottom: theme.spacingStack.xl,
    backgroundImage: `url(${CloeWormBackground})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    overflowX: 'hidden',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacingInline.xxxs,
      paddingBottom: '60px',
      maxHeight: isTeacher ? 'calc(100vh - 150px)' : 'calc(100vh - 175px)'
    }
  }),
  contentUnitWrapper: {
    display: 'block',
    background: theme.colors.neutral.lightBase,
    height: '100%',
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateAreas: `
      'nav nav'
      'sideMenu content'
    `,

      transition: 'grid-template-columns easy-out 0.1s'
    }
  },
  contentUnitWrapperOpenSideMenu: {
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '4fr 8fr'
    }
  },
  contentUnitWrapperCloseSideMenu: {
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '0px 1fr'
    }
  }
}))
