import React from 'react'
import { allowedSchoolIds } from './CONSTANTS'

interface IFeatureToggleBySchoolProps {
  schoolId?: number
}

export const FeatureToggleBySchool: React.FC<IFeatureToggleBySchoolProps> = ({ children, schoolId }) => {
  const featureToggleEnabled = () => {
    if (!schoolId || !allowedSchoolIds || !Array.isArray(allowedSchoolIds)) {
      return false
    }

    return !!allowedSchoolIds.includes(schoolId)
  }

  return (
    <React.Fragment>
      {featureToggleEnabled() && children}
    </React.Fragment>
  )
}

export default FeatureToggleBySchool
