/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect, useCallback } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingWrapper, CurrentClass, StatusClassHandle } from 'components/common'
import { Avatar as AvatarDS, Button } from 'components/design-system'
import ErrorWrapper from 'components/common/ErrorWrapper'
import { FilterByDateRange } from 'components/common/FilterByDateRange'
import { CustomizedTimeline } from 'components/common/CustomizedTimeline'
import { Disciplines } from 'components/store/Disciplines'
import { BusinessError } from 'navigation/BusinessError'
import { HeaderTabs } from '../Header'

import { findstudentByClass, findstudentTimeline } from 'services/timeline'
import {
  DisciplineCodeEnum,
  IClassSubscriptionResponse,
  IFindStudentTimelineResponse,
  SuggestedApplicationTypeEnum,
  IStudentRecordsTimelineResponse
} from 'services/types'

import { reloadTimelineAtom, currentDisciplineTimelineAtom, currentStudentsTimelineAtom } from '../atomStore'
import { filterByDateRangeAtom } from 'components/common/FilterByDateRange/atomStore'

import { ROOT, SINGLE_RECORD, MILESTONE } from 'navigation/CONSTANTS'

import { useCurricularStore } from 'store'

import { convertToLocaleUTC } from 'utils/date'
import Appcues from 'utils/appcues'

import useStyle from './style'

const TimelineTeacher: React.FC = () => {
  const [loadingInitial, setLoadingInitial] = useState(true)
  const [studentsData, setStudentsData] = useState<IClassSubscriptionResponse[]>([])
  const [hasResponseError, setHasResponseError] = useState<boolean>(false)
  const [contentError, setContentError] = useState<boolean>(false)
  const [loadingTimelineData, setLoadingTimelineData] = useState<boolean>(false)
  const [timelineData, setTimelineData] = useState<IFindStudentTimelineResponse[]>([])

  const { currentDiscipline, setCurrentDiscipline, disciplines } = useCurricularStore()

  const [filterByDateRange] = useAtom(filterByDateRangeAtom)
  const [reloadTimeline, setReloadTimeline] = useAtom(reloadTimelineAtom)
  const [, updateCurrentDisciplineAtom] = useAtom(currentDisciplineTimelineAtom)
  const [currentStudent, setCurrentStudent] = useState<IClassSubscriptionResponse | null>(null)
  const [, setCurrentStudentAtom] = useAtom(currentStudentsTimelineAtom)

  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()

  const { t } = useTranslation()

  const classes = useStyle()

  const getInitialData = async () => {
    try {
      if (disciplines?.length) {
        const responseStudentsByClass = await findstudentByClass(classId)
        const { data: students } = responseStudentsByClass

        if (!disciplines.length || !students.length) {
          setContentError(true)
          setLoadingInitial(false)
        } else {
          const formattedStudents = students.map(({ user_id, ...rest }) => ({
            ...rest,
            user_id: {
              ...user_id,
              fullname: `${user_id?.name} ${user_id.surname}`
            }
          }))

          if (!currentDiscipline || currentDiscipline?.code === DisciplineCodeEnum.ALL) {
            setCurrentDiscipline(disciplines[0])
          }

          updateCurrentDisciplineAtom(currentDiscipline)
          setStudentsData(formattedStudents)
          setCurrentStudent(formattedStudents[0])
          setCurrentStudentAtom([formattedStudents[0]])
        }
      }
    } catch {
      setHasResponseError(true)
    }
  }

  const getTimelineData = async () => {
    try {
      if (currentStudent && currentDiscipline) {
        const response = await findstudentTimeline(classId, currentDiscipline.id, currentStudent.user_school_profile.id, {
          startDate: filterByDateRange?.startDate && convertToLocaleUTC(filterByDateRange.startDate),
          endDate: filterByDateRange?.endDate && convertToLocaleUTC(filterByDateRange.endDate)
        })

        if (response.success) {
          const { data } = response
          const milestones: IStudentRecordsTimelineResponse[] = []

          data.forEach((timelines) => {
            timelines.studentRecords = timelines.studentRecords.filter((item) => {
              if (item.suggestedApplicationType !== SuggestedApplicationTypeEnum.marco || !milestones.some(e => e.id === item.id)) {
                milestones.push(item)
                return true
              }

              return false
            })
            return timelines
          })

          setTimelineData(response.data)
        }

        setLoadingTimelineData(false)
        updateCurrentDisciplineAtom(currentDiscipline)

        if (reloadTimeline) {
          setReloadTimeline(false)
        }
      }
    } catch {
      setContentError(true)
    } finally {
      if (loadingInitial) {
        setLoadingInitial(false)
      }
    }
  }

  useEffect(() => {
    const loadingData = async () => {
      await getInitialData()
    }
    void loadingData()
  }, [disciplines])

  useEffect(() => {
    if (!!classId && currentDiscipline && currentStudent) {
      setLoadingTimelineData(true)

      void getTimelineData()
    }
  }, [classId, currentDiscipline, currentStudent])

  useEffect(() => {
    if (reloadTimeline) {
      setLoadingTimelineData(true)

      void getTimelineData()
    }
  }, [reloadTimeline])

  const handleChangeStudent = useCallback((student: IClassSubscriptionResponse) => {
    if (student.user_id.id !== currentStudent?.user_id.id) {
      setCurrentStudent(student)
      setCurrentStudentAtom([student])
    }
  }, [currentStudent])

  const handleUpdateTimelineDataByFilter = (filterActive: boolean) => setReloadTimeline(filterActive)

  const Avatar: React.FC = () => (
    <>
      <AvatarDS className={classes.avatar} userName={currentStudent?.user_id?.fullname} />
      <p className={classes.avatarName}>{currentStudent?.user_id?.fullname}</p>
    </>
  )

  const HasNoContent: React.FC = () => (
    <Grid container justifyContent='center' alignItems='center'>
      <BusinessError error={t('Não conseguimos carregar o conteúdo para você')}>
        <br />
        <div className={classes.buttonNoContentContainer}>
          <NavLink
            to={ROOT}
            style={{ textDecoration: 'none' }}
            className={classes.buttonBackToHomePage}
            data-testid='back_to_home_page'
          >
            <Button variant='outlined'>
              {t('Voltar para Página Inicial')}
            </Button>
          </NavLink>
          <Button
            onClick={() => window.location.reload()}
            data-testid='try_again'
          >
            {t('Tentar novamente')}
          </Button>
        </div>
      </BusinessError>
    </Grid>
  )

  const HasContent: React.FC = () => (
    <>
      <Grid item xs={12} className={classes.disciplineContainer}>
        <Grid item sx={{ pb: '24px' }}>
          <Disciplines title={t('Exibir por componente curricular')} dataTestid='class_registry_curricular_component' />
        </Grid>
        <StatusClassHandle>
          <CurrentClass>
            <Grid item className={classes.buttonContainer}>
              <NavLink
                to={SINGLE_RECORD(gradeTypeCode, gradeCode, classId)}
                className={classes.navButton}
                data-testid='class_registry_create_register'
                onClick={() => Appcues.track('prof_novo_registro')}
              >
                <Button
                  variant='outlined'
                  sx={{ padding: '8px 24px !important', width: '100%' }}
                >
                  {t('Novo registro')}
                </Button>
              </NavLink>
              <NavLink to={MILESTONE(gradeTypeCode, gradeCode, classId)} className={classes.navButton} data-testid='class_registry_agroup_register'>
                <Button
                  variant='outlined'
                  sx={{ padding: '8px 24px !important', width: '100%' }}
                  onClick={() => Appcues.track('prof_agrupar_registros')}
                >
                  {t('Agrupar registros')}
                </Button>
              </NavLink>
            </Grid>
          </CurrentClass>
        </StatusClassHandle>
      </Grid>
      <Grid item xs={12} gap={1} className={classes.studentContainer}>
        <Accordion defaultExpanded square={false} className={classes.containerAcordeon}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            sx={{ margin: '8px 0', padding: '0px' }}
            className={classes.acordeonSummary}
          >
            <p className={classes.titleAcordeon}>{t('Estudantes')}</p>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
              {studentsData?.map((student) =>
                <button
                  data-testid='class_registry_students'
                  key={student.user_id.id}
                  className={`${classes.avatarCard} ${currentStudent?.user_id.id === student.user_id.id && 'is-active'}`}
                  onClick={() => handleChangeStudent(student)}
                >
                  <AvatarDS size='small' userName={student.user_id?.fullname} />
                  <p className={classes.avatarCardName}>{student.user_id?.fullname}</p>
                </button>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12} gap={1} className={classes.headerContainer}>
        <FilterByDateRange justify='end' onCallback={handleUpdateTimelineDataByFilter}>
          <Grid item xs={12} lg={12} md={8} className={classes.avatarContainerDesktop}>
            <Avatar />
          </Grid>
        </FilterByDateRange>
        <Grid item xs={12} className={classes.avatarContainer}>
          <Avatar />
        </Grid>
      </Grid>
      <LoadingWrapper loading={loadingTimelineData} type='linear'>
        <CustomizedTimeline timelineData={timelineData} />
      </LoadingWrapper>
    </>
  )

  return (
    <Grid container sx={{ pb: '32px' }} justifyContent={hasResponseError ? 'center' : 'initial'}>
      <Grid item xs={12}>
        <HeaderTabs className='only-mobile' />
      </Grid>
      <ErrorWrapper hasError={hasResponseError} errorMessage={t('Houve um erro ao tentar buscar os dados, tente novamente')}>
        <LoadingWrapper loading={loadingInitial} type='linear'>
          {contentError
            ? <HasNoContent />
            : <HasContent />
          }
        </LoadingWrapper>
      </ErrorWrapper>
    </Grid>
  )
}

export default TimelineTeacher
