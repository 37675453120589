import { atom } from 'jotai'
import { CardProps } from './ContentUnitSubmenu/components/Card'
import { atomWithReset, RESET } from 'jotai/utils'

export const subMenuTabsAtom = atomWithReset<CardProps[]>([])
export const subMenuTabsLoadingAtom = atomWithReset<boolean>(false)
export const currentSubMenuTabRawAtom = atomWithReset<number>(0)
export const previousSubMenuTabAtom = atomWithReset<number | null>(null)
export const showActivityDescriptionAtom = atomWithReset<boolean>(false)

export const currentSubMenuTabAtom = atom(
  (get) => get(currentSubMenuTabRawAtom),
  (get, set, newValue: number | typeof RESET) => {
    // const pastValue: number | typeof RESET = get(currentSubMenuTabRawAtom)

    // if (pastValue === newValue) return

    // if (typeof pastValue === 'number') {
    //   set(previousSubMenuTabAtom, pastValue)
    // }

    set(currentSubMenuTabRawAtom, newValue)
  }
)
