import React from 'react'
import { Grid, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Text } from 'components/design-system'

import useStyle from '../../styles'
import { CustomInfoTooltip } from 'components/common/CustomInfoTooltip'

const ReportsHeaderItem: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <Grid
      container
      mx='auto'
      className={classes.bigNumbesReportsContainer}
    >
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Text className={classes.listHeaderText}>
          {t('Estudante')}
        </Text>
      </Grid>
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Text className={classes.listHeaderText}>
          {t('Participação')}
        </Text>
        <CustomInfoTooltip text={t('Considera todas as atividades e avaliações entregues pelo estudante.')} />
      </Grid>
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Text className={classes.listHeaderText}>
          {t('Média')}
        </Text>
        <CustomInfoTooltip text={t('Considera todas as notas das avaliações aplicadas e corrigidas.')} />
      </Grid>
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Text className={classes.listHeaderText}>
          {t('Situação')}
        </Text>
        <CustomInfoTooltip text={t('Considera apenas a média do estudante.')} />
      </Grid>
    </Grid>
  )
}

const ReportsHeaderSkeleton: React.FC = () => {
  const classes = useStyle()

  return (
    <Grid
      container
      mx='auto'
      className={classes.bigNumbesReportsContainer}
    >
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Skeleton variant='text' sx={{ width: '100%' }} height={60} animation='wave' />
      </Grid>
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Skeleton variant='text' sx={{ width: '100%' }} height={60} animation='wave' />
      </Grid>
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Skeleton variant='text' sx={{ width: '100%' }} height={60} animation='wave' />
      </Grid>
      <Grid container item className={classes.reportsTableHeaderItem} gap={1}>
        <Skeleton variant='text' sx={{ width: '100%' }} height={60} animation='wave' />
      </Grid>
    </Grid>
  )
}

export const ReportsHeader = {
  Item: ReportsHeaderItem,
  Skeleton: ReportsHeaderSkeleton
}
