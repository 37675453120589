import React, { createRef } from 'react'
import { Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { Text, Icon, Button } from 'components/design-system'
import { StudentCard } from './components/StudentCard'
import { useTranslation } from 'react-i18next'
import { ReportProblemOutlined, InsertChartOutlined } from '@mui/icons-material'
import { StudentSituation } from './components'
import { IStudentPerformance, IStudentsPerformance } from 'services/types'

import useStyle from '../../styles'
import { Tooltip } from 'components/common'
import { currentStudentPerformanceAtom } from 'pages/Dashboard/teacher/ReportsTab/atomStore'
import { useAtom } from 'jotai'
import Appcues from 'utils/appcues'

interface IReportRowItem {
  participation?: number
  average?: number
  situation?: IStudentPerformance['nr_situation']
  user?: IStudentsPerformance['user']
  userSchoolProfile?: IStudentsPerformance['user_school_profile']
  studentName?: string
  studentNick?: string
  userSchoolProfileId?: string | number
  error?: boolean
}

const ReportRowItem: React.FC<IReportRowItem> = ({ user, situation, average, participation, userSchoolProfile, error }) => {
  const classes = useStyle()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const individualReportButtonRef = createRef<HTMLDivElement>()
  const roundNumber = (num: number) => Math.round(num * 100) / 100
  const [, setCurrentStudentPerformanceAndData] = useAtom(currentStudentPerformanceAtom)

  const studentName = `${user?.name} ${user?.surname}`
  const studentNick = user?.username

  const ErrorIcon = () => (
    <Icon titleAccess={t('Ícone de error')} size='medium' >
      <ReportProblemOutlined />
    </Icon>
  )

  const handleIndividualReport = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    individualReportButtonRef?.current?.click()
    Appcues.track('prof_acompanhamento_estudante')
    if (user && userSchoolProfile) {
      setCurrentStudentPerformanceAndData({ user, userSchoolProfile })
    }
  }

  const IndividualReportButton: React.FC = () => (
    <>
      <div ref={individualReportButtonRef} data-testid='followup_reload_access_individual_report' hidden />
      <Button className={classes.individualReportButton} onClick={handleIndividualReport}>
        <Grid container alignItems='center' justifyContent='center' columnSpacing={2}>
          <Grid item className={classes.reportIcon}>
            <InsertChartOutlined />
          </Grid>
          {isMobile && (
            <Grid item>
              {t('Relatório \n individual')}
            </Grid>
          )}
        </Grid>
      </Button>
    </>
  )

  return (
    <Grid container>
      <Grid
        container
        marginTop={3.5}
        mx='auto'
        className={`${classes.bigNumbesReportsContainer} ${classes.reportsRow}`}
      >
        <Grid container item className={classes.reportsTableHeaderItem}>
          {
            (!!studentName && !!studentNick && !!userSchoolProfile?.id)
              ? (
                <StudentCard.Item
                  studentName={studentName}
                  studentNick={studentNick}
                  userSchoolProfileId={userSchoolProfile?.id}
                />
              )
              : (
                <ErrorIcon />
              )
          }

        </Grid>
        <Grid container item className={classes.reportsTableHeaderItem}>
          <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
            {t('Participação')}
          </Text>
          {
            error
              ? (
                <ErrorIcon />
              )
              : (
                <Text className={classes.listHeaderText}>
                  {participation !== null && typeof participation !== 'undefined' ? `${roundNumber(participation * 100)}%` : '-'}
                </Text>
              )
          }
        </Grid>
        <Grid container item className={classes.reportsTableHeaderItem}>
          <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
            {t('Média')}
          </Text>
          {error
            ? (
              <ErrorIcon />
            )
            : (
              <Text className={classes.listHeaderText}>
                {average?.toLocaleString('pt-BR') ?? '-'}
              </Text>
            )}
        </Grid>
        <Grid container item className={classes.reportsTableHeaderItem}>
          <Text className={`${classes.listHeaderText} ${classes.mobileOnly}`}>
            {t('Situação')}
          </Text>
          {error
            ? (
              <ErrorIcon />
            )
            : situation ? <StudentSituation status={situation} /> : <Text className={classes.listHeaderText}>-</Text>}
        </Grid>
        <Grid container item className={classes.reportsTableHeaderItem} justifyContent={isMobile ? 'space-between' : 'flex-end'}>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            {isMobile
              ? <IndividualReportButton />
              : (
                <Tooltip
                  minWidth={50}
                  maxWidth={118}
                  placement='top'
                  component={<IndividualReportButton />}
                  text={t('Relatório individual')}
                />
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ReportRowSkeleton: React.FC = () => {
  const classes = useStyle()

  return (
    <Grid container>
      <Grid
        container
        marginTop={3.5}
        mx='auto'
        className={`${classes.bigNumbesReportsContainer} ${classes.reportsRow}`}
      >
        <Grid container item className={classes.reportsTableHeaderItem}>
          <StudentCard.Skeleton />
        </Grid>
        <Grid container item className={classes.reportsTableHeaderItem}>
          <Skeleton variant='text' sx={{ width: '100%' }} height={60} animation='wave' />
        </Grid>
        <Grid container item className={classes.reportsTableHeaderItem}>
          <Skeleton variant='text' sx={{ width: '100%' }} height={60} animation='wave' />
        </Grid>
        <Grid container item className={classes.reportsTableHeaderItem}>
          <Skeleton variant='text' sx={{ width: '100%' }} height={60} animation='wave' />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ReportRow = {
  Item: ReportRowItem,
  Skeleton: ReportRowSkeleton
}
