/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createRef, MouseEvent, useEffect, useState } from 'react'
import { Button, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { useTheme, Grid, useMediaQuery } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import useStyles from './style'
import { useCurricularStore, useStore } from 'store'
import { getClassPerformance, getStudentPerformance } from 'services/cloe-reports'
import { IClassPerformance, IStudentPerformance } from 'contentCacheManager'
import { Disciplines } from 'components/store/Disciplines'
import { currentStudentPerformanceAtom, lastUpdatedDateAtom } from '../../atomStore'
import { useAtom } from 'jotai'
import { BigNumbersStudent } from '../BigNumbersStudent'
import { DetailedPerformance, StudentCards, StudentClassComparison, StudentSituationDescription, TextGeneratorAccordion } from './components'
import { useReport } from '../../hooks'
import { useAtomValue } from 'jotai/utils'
import { LastUpdate } from '../LastUpdate'
import { IBigNumbersData } from 'components/common'
import FeatureToggleBySchool from 'components/feature-toggle-by-school'
import { useAuth } from 'navigation/ProvideAuth'

interface IStudentReport {
  classSelectedName: string
}

export const StudentReport: React.FC<IStudentReport> = ({ classSelectedName }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const backButtonRef = createRef<HTMLDivElement>()
  const classes = useStyles()
  const { profile, schoolPeriod } = useStore()
  const { getUpdatedDate, isLoadingDate } = useReport()
  const auth = useAuth()

  // atoms
  const [currentStudentPerformance, setCurrentStudentPerformance] = useAtom(currentStudentPerformanceAtom)
  const lastUpdatedDate = useAtomValue(lastUpdatedDateAtom)

  // state
  const { currentDiscipline, classId, currentGrade, currentSegment } = useCurricularStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [studentPerformance, setStudentPerformance] = useState<IStudentPerformance | undefined>(undefined)
  const [bigNumbersData, setBigNumbersData] = useState<IBigNumbersData>({ data: null, error: null })

  const commonAttributes = {
    user_id: profile?.user,
    profile_type: profile?.type,
    profile_id: profile?.id,
    id_student: currentStudentPerformance?.userSchoolProfile?.id,
    school_id: profile?.school?.id,
    school_name: profile?.school?.name,
    grade_id: currentGrade?.id,
    grade_code: currentGrade?.code,
    class_id: classId,
    class_name: classSelectedName,
    segment_id: currentSegment?.id,
    segment_name: currentSegment?.name,
    discipline_id: currentDiscipline?.id,
    discipline_title: currentDiscipline?.name,
    period_id: schoolPeriod?.id,
    period_title: schoolPeriod?.title
  }

  const curricularData = {
    first_name: currentStudentPerformance?.user?.name ?? '',
    student_average_grade: (studentPerformance?.dec_performance ?? 0).toLocaleString('pt-BR'),
    segment: currentSegment?.name ?? '',
    student_situation: studentPerformance?.nr_situation ?? -1,
    curricular_component: currentDiscipline?.name ?? ''
  }

  const handleBackButton = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    backButtonRef?.current?.click()
    setCurrentStudentPerformance(undefined)
  }

  const fetchClassPerformance = async () => {
    if (typeof currentDiscipline === 'undefined') {
      return
    }

    try {
      if (!currentDiscipline?.id || !classId || !profile?.school?.id) {
        throw new Error('Page info was not found')
      }

      const bigNumbersResponse = await getClassPerformance({ schoolId: profile?.school?.id, classId: Number(classId), disciplineId: Number(currentDiscipline.id) })

      if (!bigNumbersResponse.success) {
        if (!bigNumbersResponse.status || ![400, 404].includes(bigNumbersResponse.status)) { // TODO: CHANGE THIS 400 to only 404
          throw new Error(bigNumbersResponse.message)
        }
        bigNumbersResponse.data = null as unknown as IClassPerformance
      }

      const newClassData: IClassPerformance | null = bigNumbersResponse.data
      setBigNumbersData({ data: newClassData, error: null })
    } catch (err: any) {
      setBigNumbersData({ data: null, error: t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página') })
    }
  }

  const fetchStudentPerformance = async () => {
    if (profile?.school?.id && classId && currentDiscipline?.id && currentStudentPerformance?.userSchoolProfile?.id) {
      const payload = {
        schoolId: profile?.school?.id,
        classId: Number(classId),
        disciplineId: Number(currentDiscipline.id),
        studentProfileId: Number(currentStudentPerformance?.userSchoolProfile?.id)
      }

      const resStudentPerformance = await getStudentPerformance(payload)

      if (resStudentPerformance?.data) {
        setStudentPerformance(resStudentPerformance?.data)
      } else {
        setStudentPerformance(undefined)
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    const element = document.getElementById('student-report-back-button')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    if (!lastUpdatedDate) {
      getUpdatedDate()
    }
  }, [lastUpdatedDate])

  useEffect(() => {
    if (currentDiscipline && Number(currentDiscipline.id) !== 0) {
      setIsLoading(true)
      fetchClassPerformance()
      fetchStudentPerformance()
    }
  }, [currentDiscipline])

  return (
    <Grid container position='relative' top={currentStudentPerformance ? (isMobile ? '-22px' : '-40px') : 0} id='student-report-back-button'>
      <Grid item xs className={classes.backButtonGrid}>
        <div ref={backButtonRef} data-testid='followup_individual_student_back_to_followup_class' hidden />
        <Button onClick={handleBackButton} size='medium' startIcon={<ArrowBackIos fontSize='small' />} variant='ghost'>
          {t('Voltar')}
        </Button>
      </Grid>

      <StudentCards />

      <Grid container item xs={12} rowSpacing={1}>
        <Grid container direction='column' marginTop={3.5} xs={12}>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {t('Componentes Curriculares')}
          </Text2>

          <Disciplines dataTestid='followup_individual_student_select_curricular_component' showAlertOnError />
        </Grid>

        <Grid item xs={12} marginTop={1}>
          {(lastUpdatedDate ?? isLoadingDate) && (
            <LastUpdate isLoading={isLoadingDate} />
          )}
        </Grid>
        <Grid container marginBottom={5}>
          <BigNumbersStudent
            isLoading={isLoading}
            studentName={currentStudentPerformance?.user?.name ?? ''}
            studentPerformance={studentPerformance}
            bigNumbersData={bigNumbersData}
          />
        </Grid>
      </Grid>

      {((bigNumbersData?.data?.dec_performance !== null && studentPerformance?.dec_performance !== null) ?? isLoading)
        ? (
          <Grid container item xs={12} columnSpacing={4} rowSpacing={3}>
            <Grid item xs={12} md={6}>
              <StudentClassComparison
                classAverage={bigNumbersData?.data?.dec_performance ?? -1}
                isLoading={isLoading}
                studentSituation={studentPerformance?.nr_situation ?? -1}
                studentAverage={studentPerformance?.dec_performance ?? -1}
                studentName={currentStudentPerformance?.user?.name ?? ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StudentSituationDescription
                isLoading={isLoading}
                studentSituation={studentPerformance?.nr_situation ?? -1}
              />
            </Grid>
          </Grid>
        )
        : null
      }

      <FeatureToggleBySchool schoolId={profile?.school?.id ?? 0}>
        <Grid container item xs={12} marginTop={5}>
          <Grid item xs={12}>
            <TextGeneratorAccordion
              commonAttributes={commonAttributes}
              curricularData={curricularData}
              studentId={currentStudentPerformance?.userSchoolProfile?.id ?? 0}
              userName={auth?.user?.username as string}
            />
          </Grid>
        </Grid>
      </FeatureToggleBySchool>

      {currentDiscipline &&
        <DetailedPerformance
          currentDisciplineId={currentDiscipline?.id}
          isLoading={isLoading}
        />
      }
    </Grid>
  )
}
