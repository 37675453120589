// All user related database operations can be defined here.
import {
  USER_CPF,
  USER_EMAIL,
  USER_LOGIN,
  USER_ME,
  USER_PHONE,
  USER_REGISTER,
  USER_TOKEN_VALIDATION,
  SESSION,
  USER_FORGOT_PASSWORD,
  USER_RESET_PASSWORD,
  USER_CHANGE_DEFAULT_PASSWORD,
  USER_GET_BY_CPF,
  USER_USERNAME_IDENTIFIER,
  USER_RESET_PASSWORD_TOKEN_VALIDATION
} from 'services/users/CONSTANTS'
import {
  IApiResponse,
  IUserMeResponse,
  IUserLoginResponse,
  IUserRegisterRequest,
  IUserRegisterFlowRequest,
  IUserRegisterResponse,
  IUserResponse,
  IUserTokenResponse,
  IUserSessionResponse,
  IForgotPasswordResponse,
  IUsernamesResponse,
  ICheckResetPasswordTokenResponse
} from 'services/types'
import { request } from 'services/base'
import { CancelTokenSource } from 'axios'

export const userLogin = async (identifier: string, password: string): Promise<IApiResponse<IUserLoginResponse>> => {
  const loginObj = {
    identifier,
    password
  }
  return await request({ url: USER_LOGIN(), method: 'post', data: loginObj, isLogin: true, disableToast: true })
}

/**
 * Function to check if CPF is already registered
 */
export const checkCpf = async (cpf: number): Promise<IApiResponse<boolean>> =>
  await request({ url: USER_CPF(cpf), method: 'get', data: cpf, isLogin: true })

/**
 * Function to check if e-mail is already registered
 */
export const checkEmail = async (email: string): Promise<IApiResponse<boolean>> =>
  await request({ url: USER_EMAIL(email), method: 'get', data: email, isLogin: true })

/**
 * Function to check if phone is already registered
 */
export const checkPhone = async (email: number): Promise<IApiResponse<boolean>> =>
  await request({ url: USER_PHONE(email), method: 'get', data: email, isLogin: true })

/**
 * Function to fetch the details of the logged in user
 */

interface IUserMeProps {
  axiosSource: CancelTokenSource
}

export const userMe = async ({ axiosSource }: IUserMeProps): Promise<IApiResponse<IUserMeResponse>> =>
  await request({ url: USER_ME(), disableToast: true, cancelToken: axiosSource.token })

/**
 * Function to register a user
 */
export const userRegister = async (user: IUserRegisterRequest | IUserRegisterFlowRequest): Promise<IApiResponse<IUserRegisterResponse>> =>
  await request({ url: USER_REGISTER(), method: 'post', data: user, isLogin: true })

/**
* Function to validate token and return profile data
*/
export const userTokenValidation = async (token: string): Promise<IApiResponse<IUserTokenResponse>> =>
  await request({ url: USER_TOKEN_VALIDATION(token), method: 'post', data: token, isLogin: true, disableToast: true })

/**
* Function to return if token is valid
*/
export const resetTokenValidation = async (token: string): Promise<IApiResponse<ICheckResetPasswordTokenResponse>> =>
  await request({ url: USER_RESET_PASSWORD_TOKEN_VALIDATION(token), method: 'get' })

/**
* Function to get the user session data
*/
export const getSession = async (): Promise<IApiResponse<IUserSessionResponse>> =>
  await request({
    url: SESSION(),
    params: { school_period_is_next: false }
  })

/**
* Function to get user by cpf
*/
export const getUserByCpf = async (cpf: number): Promise<IApiResponse<IUserResponse[]>> =>
  await request({
    url: USER_GET_BY_CPF(cpf),
    method: 'get',
    isLogin: true
  })

/**
* Function to get usernames by identifier
*/
export const getUsernameByIdentifier = async (identifier: string): Promise<IApiResponse<IUsernamesResponse[]>> =>
  await request({
    url: USER_USERNAME_IDENTIFIER(identifier),
    method: 'get'
  })

export const userForgotPassword = async (email?: string, telephone?: string, username?: string | null): Promise<IApiResponse<IForgotPasswordResponse>> => {
  const json = {
    referrer: process.env.REACT_APP_PROJECT_NAME,
    email: email ?? null,
    username,
    telephone
  }

  return await request({ url: USER_FORGOT_PASSWORD(), method: 'post', data: json, isLogin: true, disableToast: true })
}

export const userResetPassword = async (code: string, password: string, passwordConfirmation: string): Promise<IApiResponse<IUserLoginResponse>> => {
  const json = {
    code: code,
    password: password,
    passwordConfirmation: passwordConfirmation
  }

  return await request({ url: USER_RESET_PASSWORD(), method: 'post', data: json, isLogin: true, disableToast: true })
}

export const userChangeDefaultPassword = async (userId: number, newPassword: string): Promise<IApiResponse<void>> => {
  const json = {
    userId,
    password: newPassword
  }

  return await request({ url: USER_CHANGE_DEFAULT_PASSWORD(), method: 'post', data: json })
}
