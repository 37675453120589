import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  txtTitle: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colorBrand.medium
  },
  infoText: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium
  },
  '@global': {
    '.MuiTooltip-tooltip': {
      maxWidth: '281px !important',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '298px !important'
      }
    }
  }
}))
