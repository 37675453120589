import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { Avatar, Icon, Text2 } from 'components/design-system'

import useStyle from './styles'
import { IMessage } from 'services/types/messages'
import dayjs from 'dayjs'
import { maxLength } from 'utils/string'
import { useStore, useCurricularStore } from 'store'
import Analytics from 'utils/analytics'

interface IMessageItem {
  message: IMessage | undefined | null
  showMoreButtonDataTestId?: string
  hideButtonDataTestId?: string
}

export const Message = ({ message, showMoreButtonDataTestId, hideButtonDataTestId }: IMessageItem) => {
  const classes = useStyle()
  const theme = useTheme()

  const { profile, schoolPeriod, subscription } = useStore()
  const { currentDiscipline, classId, currentGrade, currentSegment, currentClass } = useCurricularStore()

  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const charLimit = 250
  const limitedText = maxLength(message?.message ?? '', charLimit)

  const commonAnalyticsAttributes = {
    user_id: profile?.user,
    profile_type: profile?.type,
    profile_id: profile?.id,
    school_id: profile?.school?.id,
    school_name: profile?.school?.name,
    class_id: classId,
    class_name: currentClass?.name,
    discipline_id: currentDiscipline?.id,
    discipline_title: currentDiscipline?.name,
    period_title: schoolPeriod?.title,
    period_id: schoolPeriod?.id,

    grade_id: currentGrade?.id,
    grade_code: currentGrade?.code,
    grade_name: currentGrade?.name,
    segment_id: currentSegment?.id,
    segment_name: currentSegment?.name

  }

  const handleRecordEventClick = (eventName: string, exclusiveAttributes?: {}) => {
    Analytics.recordEventClick({
      name: eventName,
      attributes: {
        ...commonAnalyticsAttributes,
        ...subscription?.analytics,
        ...exclusiveAttributes
      }
    })
  }

  const handleExpandButtonClick = () => {
    if (showMoreButtonDataTestId && hideButtonDataTestId) {
      handleRecordEventClick(isExpanded ? hideButtonDataTestId : showMoreButtonDataTestId)
    }

    setIsExpanded(old => !old)
  }

  if (!message) {
    return <></>
  }

  const authorName = `${message.author.name} ${message.author.surname}`

  return (

    <Grid
      container
      width='100%'
      alignItems='flex-start'
      marginTop={3.5}
      mx='auto'
      className={classes.parentalMessageBox}
    >
      <Grid container className={classes.avatarContainer}>
        <Avatar userName={authorName} className={classes.customAvatar} />
        <Grid item alignItems={'flex-start'} direction='column' className={classes.studentHeaderInfo}>
          <Grid item className={classes.studentName}>
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xxs' mobile='sm' neutral='dark20' className={classes.studentTextName}>
              {authorName}
            </Text2>
          </Grid>
          <Grid item>
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xxs' mobile='sm' neutral='dark40'>
              {t('MessageSentAtText', { date: dayjs(message.created_at).format('DD/MM/YYYY') })}
            </Text2>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.messageInnerBox}>
        {
          isExpanded ? message.message : limitedText
        }
      </Grid>

      {
        message.message.length > charLimit && (
          <Grid className={classes.footer}>
            <Grid
              className={classes.hideButton}
              onClick={handleExpandButtonClick}
              sx={{
                '& svg': {
                  cursor: 'pointer',
                  transform: `rotate(${isExpanded ? '180deg' : '0deg'})`
                }
              }}
            >
              <Text2
                customColor={theme.colorBrand.medium}
                cursorpointer
                fontSize='xs'
                fontWeight='semibold'
                lineHeight='xs'
                mobile='xs'>
                {isExpanded ? t('Esconder') : t('Mostrar')}
              </Text2>
              <Icon size='medium'>
                <KeyboardArrowDownOutlinedIcon />
              </Icon>
            </Grid>
          </Grid>
        )
      }

    </Grid>
  )
}
