import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import notFoundSearchContentsUnit from 'assets/notFoundSearchContentsUnit.svg'

export default makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: theme.font.fontSize.sm,
      color: theme.colors.neutral.dark30,
      textAlign: 'center',
      width: '500px',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    image: {
      backgroundImage: `url(${notFoundSearchContentsUnit})`,
      backgroundSize: '100%',
      width: '30rem',
      height: '30rem',
      margin: 'auto',
      marginBottom: '7rem',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        width: '20rem',
        height: '20rem'
      }
    }
  })
)
