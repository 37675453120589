import { toast } from 'components/design-system/Toast/manager'
import { useTranslation } from 'react-i18next'
import { useToggleOpenMyCloeAtom } from 'navigation/components/MyCloe/atomStore'
import {
  scheduleAtom,
  isHoursAndMinutesNowAtom,
  updateContentSelectAtom,
  contentUnitCardAtom,
  updateScheduleFileRecordsAtom,
  reloadScheduleAtom,
  subscriptionsAtom,
  subscriptionsAllSelectedAtom,
  activitySelectTitleAtom
} from 'navigation/components/MyCloe/components/ScheduleCreator/atomStore'
import { useUpdateAtom } from 'jotai/utils'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import MenuItem from '@mui/material/MenuItem'
import { deleteScheduleById } from 'services/schedule'
import OnlyProfile from 'components/common/OnlyProfile'
import { Button } from 'components/design-system'
import { StatusClassHandle, CurrentClass, DropDown } from 'components/common'
import { ActivityControlStatusEnum, IMediaResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { IScheduleSubscriptionsResponse } from 'services/types/schedule'
import { useState } from 'react'

import useStyles from './style'
import { IScheduleCardProps } from '../..'
import { ContentTypeEnum } from 'services/types/content'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

interface IEditDeleteNavigationButtonProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  scheduleProps: IScheduleCardProps
}

export const EditDeleteNavigationButton: React.FC<IEditDeleteNavigationButtonProps> =
  ({
    setIsLoading,
    scheduleProps
  }) => {
    const {
      id: scheduleId,
      title,
      content,
      type,
      discipline,
      start_date: startDate,
      end_date: endDate,
      content_unit: contentUnit,
      class: theClass,
      activity,
      didactic_section: didacticSection,
      ask_for_records: askForRecords,
      has_updated: hasUpdated,
      onDelete,
      files,
      subscriptions
    } = scheduleProps

    const classId = theClass.id ?? ''

    const classes = useStyles()

    const { t } = useTranslation()

    const isReleased = activity?.activity_status?.status === ActivityControlStatusEnum.RELEASED

    // state
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

    // atoms
    const setActivitySelectTitle = useUpdateAtom(activitySelectTitleAtom)
    const activityCurrent = useUpdateAtom(activityCurrentAtom)
    const { toggleOpenMyCloeAtom } = useToggleOpenMyCloeAtom()
    const setOpenMyCloe = useUpdateAtom(toggleOpenMyCloeAtom)
    const setSchedule = useUpdateAtom(scheduleAtom)
    const setHoursAndMinutesToNow = useUpdateAtom(isHoursAndMinutesNowAtom)
    const updateContentSelect = useUpdateAtom(updateContentSelectAtom)
    const setContentUnitCard = useUpdateAtom(contentUnitCardAtom)
    const setfileRecords = useUpdateAtom(updateScheduleFileRecordsAtom)
    const setSubscriptions = useUpdateAtom(subscriptionsAtom)
    const setSubscriptionsAllSelected = useUpdateAtom(subscriptionsAllSelectedAtom)
    const setReloadSchedule = useUpdateAtom(reloadScheduleAtom)

    const onClickEdit = () => {
      if (isReleased) {
        toast.handler({
          content: t('Não é possível alterar a data ou a atividade/avaliação já devolvida aos estudantes.'),
          duration: 10000,
          severity: 'warning'
        })
      } else {
        setOpenMyCloe(true)
        setSchedule({
          id: scheduleId,
          activity: activity?.id,
          activityTitle: activity?.title,
          ask_for_records: askForRecords,
          class: classId,
          content_unit: contentUnit?.id,
          didatic_section: didacticSection?.id?.toString(),
          disciplineId: discipline?.id,
          start_date: new Date(startDate),
          end_date: endDate ? new Date(endDate ?? '') : undefined,
          room_link: scheduleProps.room_link ?? '',
          title: title,
          type: type,
          content: content,
          file_records: files?.map(file => ({ id: file.upload_id, url: file.url, name: file.url, thumb: file.url, ext: file.url, transaction_id: file.transaction_id })) ?? [],
          has_updated: hasUpdated,
          subscriptions: subscriptions?.map((student: IScheduleSubscriptionsResponse) => ({ id: student.id, name: `${student.user_id.name} ${student.user_id.surname}` })) ?? []
        })

        setHoursAndMinutesToNow(false)

        if (files) {
          setfileRecords(files?.map(file => ({ id: file.upload_id, url: file.url, name: file.url, thumb: file.url, ext: file.url, transaction_id: file.transaction_id })))
        }

        if ((subscriptions ?? []).length > 0) {
          setSubscriptionsAllSelected(false)
          setSubscriptions(subscriptions?.map((student: IScheduleSubscriptionsResponse) => ({ id: student.id, name: `${student.user_id.name} ${student.user_id.surname}` })) ?? [])
        } else {
          setSubscriptions([])
        }
        activityCurrent(activity)

        updateContentSelect([{
          id: activity?.id ?? didacticSection?.id,
          selected: true,
          type: activity
            ? (activity.suggested_application_type === 'activity' ? ContentTypeEnum.activity : ContentTypeEnum.evaluation)
            : ContentTypeEnum.reading,
          title: activity?.title ?? activity?.activity_name ?? didacticSection?.title ?? ''
        }])

        if (activity?.title) {
          setActivitySelectTitle(activity.title)
        }

        if (contentUnit) {
          setContentUnitCard({
            contenUnit: contentUnit.id,
            cover: contentUnit.cover_mobile as IMediaResponse,
            coverMobile: contentUnit.cover_mobile as IMediaResponse,
            title: contentUnit.title,
            suggested_position: contentUnit.suggested_position
          })
        }
      }
    }

    const onClickDelete = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (isReleased) {
        toast.handler({
          content: t('Não é possível excluir evento com atividade/avaliação já devolvida aos estudantes.'),
          duration: 10000,
          severity: 'warning'
        })
      } else {
        e.stopPropagation()
        setShowConfirmDelete(true)
      }
    }

    const deleteSchedule = async () => {
      setShowConfirmDelete(false)
      setIsLoading(true)
      const response = await deleteScheduleById(scheduleId?.toString())
      if (response.success && onDelete) {
        onDelete()
      }
      setReloadSchedule(true)
      setIsLoading(false)
    }

    return (
      <StatusClassHandle>
        <CurrentClass>
          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
            <div className={classes.navationButtonWrapper}>
              {
                showConfirmDelete
                  ? <DropDown name={t('Mais opções')}>
                    <div className={classes.dropdownWrapper}>
                      <div className={classes.dropdownTitle}>
                        {t('Você está prestes a excluir esse evento da agenda. Gostaria de prosseguir?')}
                      </div>
                      <div className={classes.dropdownButtons}>
                        <Button
                          tabIndex={-1}
                          variant='ghost'
                          onClick={() => setShowConfirmDelete(false)}
                          sx={{
                            height: '32px !important',
                            fontSize: '12px !important'
                          }}
                          data-testid='delete_event_cancel_deletion'
                        >
                          {t('Cancelar')}
                        </Button>
                        <Button
                          tabIndex={-1}
                          onClick={deleteSchedule}
                          sx={{
                            height: '32px !important',
                            fontSize: '12px !important'
                          }}
                          data-testid='delete_event_confirm_deletion'
                        >
                          {t('Excluir')}
                        </Button>
                      </div>
                    </div>
                  </DropDown>
                  : <DropDown name={t('Mais opções')}>
                    <MenuItem onClick={onClickEdit}>
                      <div className={`${classes.navationButton} ${isReleased && 'disabled'}`} data-testid='edit_event_edit'>
                        <EditOutlined /> {t('Editar')}
                      </div>
                    </MenuItem>
                    <MenuItem onClick={onClickDelete}>
                      <div className={`${classes.navationButton} ${isReleased && 'disabled'}`} data-testid='delete_event_delete'>
                        <DeleteOutline /> {t('Excluir')}
                      </div>
                    </MenuItem>
                  </DropDown>
              }
            </div>
          </OnlyProfile>
        </CurrentClass>
      </StatusClassHandle>
    )
  }
