import React from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { IStudentsListPerformance } from 'services/types'

import { ReportRow, ReportsHeader } from './components'

interface IReportsTableReady {
  studentsListData: IStudentsListPerformance | null
}

const ReportsTableItem: React.FC<IReportsTableReady> = ({ studentsListData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container>
      {!isMobile && <ReportsHeader.Item />}
      {
        studentsListData?.result?.map(({ performance, user, user_school_profile }) => (
          <ReportRow.Item
            user={user}
            userSchoolProfile={user_school_profile}
            situation={performance?.nr_situation}
            average={performance?.dec_performance}
            participation={performance?.per_participation}
            error={(!!studentsListData?.error && studentsListData?.error?.length > 0)}
          />
        ))
      }
    </Grid >
  )
}

const ReportsTableError: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container>
      {!isMobile && <ReportsHeader.Item />}
      <ReportRow.Item error />
    </Grid >
  )
}

const ReportsTableSkeleton: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Grid container>
        {!isMobile && <ReportsHeader.Skeleton />}
        <ReportRow.Skeleton />
        <ReportRow.Skeleton />
        <ReportRow.Skeleton />
      </Grid>
    </>
  )
}

export const ReportsTable = {
  Item: ReportsTableItem,
  Skeleton: ReportsTableSkeleton,
  Error: ReportsTableError
}
