import { useMediaQuery, useTheme } from '@mui/material'
import { useOnlineStatus } from 'contentCacheManager'
import React, { useMemo } from 'react'

export const OnlyNotPWA: React.FC = ({ children }) => {
  const isOnline = useOnlineStatus()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return useMemo(() => {
    if (!isOnline && isMobile) {
      return null
    }

    return <>{children}</>
  }, [children, isMobile, isOnline])
}
