/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { ClassReport, StudentReport } from './components'
import { currentStudentPerformanceAtom, showParentalMessagesAtom } from './atomStore'
import { isCloseHeaderAtom } from '../atomStore'
import ParentalMessages from 'pages/ParentalMessages'

type StateMachine = 'classReport' | 'studentReport' | 'parentalComments'

interface IReportsTab {
  classSelectedName: string
}

export const ReportsTab: React.FC<IReportsTab> = ({ classSelectedName }) => {
  const [state, setState] = useState<StateMachine>('classReport')
  const [currentStudentPerformanceAndData] = useAtom(currentStudentPerformanceAtom)
  const [showParentalMessages] = useAtom(showParentalMessagesAtom)
  const [, setIsCloseHeader] = useAtom(isCloseHeaderAtom)

  useEffect(() => {
    return () => {
      setIsCloseHeader(false)
    }
  }, [])

  useEffect(() => {
    if (!currentStudentPerformanceAndData) {
      setState('classReport')
      setIsCloseHeader(false)
      return
    }

    setIsCloseHeader(true)

    if (showParentalMessages) {
      setState('parentalComments')
      return
    }

    setState('studentReport')
  }, [currentStudentPerformanceAndData, showParentalMessages])

  const stateMachine = {
    classReport: <ClassReport />,
    studentReport: <StudentReport classSelectedName={classSelectedName} />,
    parentalComments: <ParentalMessages />
  }

  return stateMachine[state]
}
