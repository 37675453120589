import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, useTheme } from '@mui/material'

import { DropdownSelector } from 'components/common'

import { ISubscriptionsTaughtDisciplinesResponse } from 'services/types/taught-disciplines'

import { useStore } from 'store'

import useStyle from './styles'

interface ISegmentSelector {
  taughtDisciplines: ISubscriptionsTaughtDisciplinesResponse[]
  selected: number
  setSelected: React.Dispatch<React.SetStateAction<number>>
}

export const SegmentSelector = ({ taughtDisciplines, selected, setSelected }: ISegmentSelector) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false)
  const { schoolPeriod, isQuickAccessOpen } = useStore()

  const classes = useStyle()

  const handleSelectSegment = (selectedSegment: number) => {
    setSelected(selectedSegment)
    setIsSelectorOpen(false)
  }

  const handleBackSelector = () => {
    setIsSelectorOpen(oldIsSelectorOpen => !oldIsSelectorOpen)
  }

  const renderGroups = useCallback(() => {
    if (!taughtDisciplines.length) return <></>

    return taughtDisciplines.map(({ name }, index) => {
      const isActive = index === selected

      if (!name) return <></>

      return (
        <a
          key={index}
          className={`${classes.optionContainer} ${isActive && 'active'} ${schoolPeriod?.current === true ? 'currentPeriodsContainer' : 'previousPeriods'}`}
          onClick={() => handleSelectSegment(index)}
          data-testid='my_classes_segment_selection'
        >
          {name}
        </a>
      )
    })
  }, [taughtDisciplines, schoolPeriod, selected, isQuickAccessOpen])

  return (
    <DropdownSelector
      placeholder={taughtDisciplines[selected]?.name ?? t('Selecione o segmento')}
      colorTheme={schoolPeriod?.current === true ? theme.colorBrand.medium : theme.colors.contentType.default}
      isOpen={isSelectorOpen}
      className={classes.dropdownContainer}
      setIsOpen={setIsSelectorOpen}
      dataTestId={isSelectorOpen ? 'my_classes_retract_segment_selection' : 'my_classes_expand_segment_selection'}
      confirmDataTestId={'class_change_confirm_change'}
      backButtonLabel={t('Segmento')}
      handleSelectorClick={handleBackSelector}
    >
      <Grid className={classes.container}>
        {renderGroups()}
      </Grid>
    </DropdownSelector >
  )
}
