import React from 'react'
import { Text } from 'components/design-system'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { activeChangeClassAtom } from 'navigation/atomStore'
import useStyle from './style'
import * as SvgIcon from '@mui/icons-material'
import { useStore } from 'store'

export const HeaderTeacher: React.FC = () => {
  const [activeMenuClass, setActiveMenuClass] = useAtom(activeChangeClassAtom)
  const classes = useStyle()
  const { t } = useTranslation()
  const { session, profile } = useStore()

  return (
    <>
      <Grid item className={classes.classContainerTitles} id='ProfileManagerMenu-Teacher'>
        <Grid item className={classes.classInforamtionTitle}>
          <Text type='body' size='small' >{t('Escola atual')}</Text>
        </Grid>
        <Grid className={classes.classInforamtionText}>
          {profile?.school?.name}
        </Grid>
      </Grid>
      {session?.profiles.length === 1 || !session?.profiles.length
        ? null
        : <Grid item>
          <a className={classes.classChange} onClick={() => setActiveMenuClass(!activeMenuClass)} data-testid='menu_my_profile_change_schools'>
            <Grid item className={classes.iconChangeClass}>
              <SvgIcon.AutorenewRounded className={classes.iconSvg} />
            </Grid>
            <Text type='body' color='medium' className={classes.classChangeTitle}>{t('Trocar')}</Text>
          </a>
        </Grid>
      }
      <Grid item className={classes.classContainerTitles}>
        <Grid item className={classes.classInforamtionTitle}>
          <Text type='body' size='small' >{t('Perfil atual')}</Text>
        </Grid>
        <Grid item className={classes.inforamtionType}>
          {t('Professor')}
        </Grid>
      </Grid>
    </>
  )
}

export default HeaderTeacher
