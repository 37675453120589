import OnlyProfile from 'components/common/OnlyProfile'
import HomeStudent from './student'
import useStyles from './style'
import { IUserSchoolProfileTypeEnum } from 'services/types'

export const Home: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <HomeStudent />
      </OnlyProfile>
    </div>
  )
}

export default Home
