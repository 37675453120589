import React, { ReactChild } from 'react'
import MUIAlert from '@mui/material/Alert'
import MUIAlertTitle from '@mui/material/AlertTitle'
import { AlertProps, Grid } from '@mui/material'
import useStyles from './style'

export interface IAlert extends AlertProps {
  title?: string
  content: string
  outlined?: boolean
  actionButton?: ReactChild
  closeButton?: ReactChild
}
// Use actionButton + closeButton quando for necessário utilizar botão de ação (Ex: Recarregar) + botão de fechar
export const Alert: React.FC<IAlert> = ({ outlined, title, content, actionButton, closeButton, ...props }) => {
  const classes = useStyles()

  return (
    <MUIAlert
      {...props}
      variant={outlined ? 'outlined' : 'filled'}
      className={`${classes.root} ${props.className as string ?? ''}`}
    >
      <Grid container spacing={1} justifyContent='space-between'>
        <Grid container item xs>
          {title && (
            <Grid item xs={12}>
              <MUIAlertTitle>{title}</MUIAlertTitle>
            </Grid>
          )}
          <Grid item xs={12}>
            <span
              dangerouslySetInnerHTML={{
                __html: content ?? ''
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md='auto' alignItems='center' columnSpacing={0.5} justifyContent='flex-end'>
          {actionButton && (
            <Grid item>
              {actionButton}
            </Grid>
          )}
          {closeButton && (
            <Grid item>
              {closeButton}
            </Grid>
          )}
        </Grid>
      </Grid>
    </MUIAlert>
  )
}
