import { Text, Button, TextField, Loading } from 'components/design-system'
import { useState } from 'react'
import Modal from '@mui/material/Modal'
import { useAtom } from 'jotai'
import useStyle from './style'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { activeModalVinculateAtom } from 'navigation/atomStore'
import { postVinculateToken } from 'services/registration-token'
import { userTokenValidation } from 'services/users/userService'
import { useAuth } from '../../../ProvideAuth'
import { useStore } from 'store'
import { IUserSchoolProfileTypeEnum } from 'services/types'

export const VinculateModal: React.FC = () => {
  const [activeModalVinculate, setActiveModalVinculate] = useAtom(activeModalVinculateAtom)
  const [token, setToken] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const { profile } = useStore()
  const { t } = useTranslation()
  const classes = useStyle()
  const auth = useAuth()

  const handleActiveModal = () => {
    setActiveModalVinculate(!activeModalVinculate)
    setIsLoading(false)
    setErrorMessage(null)
    setToken('')
    setIsSuccess(false)
  }

  const temporaryValidation = async (token: string) => {
    // checo pelo perfil se ele pode se vincular (temporario)
    const tokenType = await userTokenValidation(token)
    if (tokenType?.success) {
      // se o perfil atual for de estudante:
      // só deixa vincular outro perfil de estudante
      if (profile?.type === IUserSchoolProfileTypeEnum.student) {
        const allowedStudentVinculations = [IUserSchoolProfileTypeEnum.student?.toString()]
        if (!allowedStudentVinculations.includes(tokenType.data?.type)) {
          throw new Error(t('Não foi possível vincular o Token.'))
        }
      }

      // se o perfil atual for de professor:
      // deixa vincular perfil de professor, independente da escola
      // deixa vincular perfil de coordenador e admin
      if (profile?.type === IUserSchoolProfileTypeEnum.teacher) {
        const allowedTeacherVinculations = [IUserSchoolProfileTypeEnum.teacher?.toString(), IUserSchoolProfileTypeEnum.coordinator?.toString(), IUserSchoolProfileTypeEnum.admin?.toString()]
        if (!allowedTeacherVinculations.includes(tokenType.data?.type)) {
          throw new Error(t('Não foi possível vincular o Token.'))
        }
      }

      // se o perfil atual for de coordenador ou admin:
      // deixa vincular perfil de professor, admin e coordinator
      if (profile?.type === IUserSchoolProfileTypeEnum.coordinator || profile?.type === IUserSchoolProfileTypeEnum.admin) {
        const allowedAdminVinculations = [IUserSchoolProfileTypeEnum.teacher?.toString(), IUserSchoolProfileTypeEnum.coordinator?.toString(), IUserSchoolProfileTypeEnum.admin?.toString()]
        if (!allowedAdminVinculations.includes(tokenType.data?.type)) {
          throw new Error(t('Não foi possível vincular o Token.'))
        }
      }
    } else {
      throw new Error(t('Token expirado ou inválido.'))
    }
  }

  const onSubmit = async () => {
    if (token.length === 0) {
      setErrorMessage(t('Campo obrigatório'))
    } else {
      setIsLoading(true)

      try {
        // TODO: remover essa validação quando formos mais inteligentes na gestão de perfis na interface (por ex: uma mesma pessoa com perfil de estudante e professor)
        await temporaryValidation(token)

        const response = await postVinculateToken(token)
        if (response?.success) {
          await auth?.setSession(false)
          setToken('')
          setErrorMessage(null)
          // handleActiveModal()
          setIsSuccess(true)
        } else {
          throw new Error(t('Token expirado ou inválido.'))
        }
      } catch (e) {
        if (e instanceof Error) {
          setErrorMessage(e.message)
        } else {
          setErrorMessage(t('Não foi possível vincular o Token.'))
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Modal open={activeModalVinculate} onClose={handleActiveModal}>
      {
        isSuccess
          ? <Box className={classes.boxModal}>
            <Grid>
              <Text className={classes.classesItensText} type='body'>
                {t('Código vinculado com sucesso!')}
              </Text>
            </Grid>
            <Grid className={classes.classButton}>
              <Button
                disabled={isLoading}
                sx={{ marginTop: '10px' }}
                variant='primary'
                onClick={handleActiveModal}
              >
                {t('Fechar')}
              </Button>
            </Grid>
          </Box>
          : <Box className={classes.boxModal}>
            <Grid>
              <Text className={classes.classesItensText} type='body'>
                {t('Vincular código')}
              </Text>
            </Grid>
            <TextField
              id='token'
              variant='filled'
              label={t('Digite seu código')}
              // className={styles.input}
              value={token}
              assistiveText={t(errorMessage as string)}
              error={errorMessage !== null}
              onChange={e => {
                setToken(e.target.value)
                setErrorMessage(null)
              }}
            />
            <Grid className={classes.classButton}>
              <Button variant='primary' disabled={isLoading} onClick={onSubmit} type='submit' data-testid='link_code_link'>
                {!isLoading ? t('Vincular') : <Loading />}
              </Button>

              <Button
                disabled={isLoading}
                sx={{ marginTop: '10px' }}
                variant='ghost'
                onClick={handleActiveModal}
                data-testid='link_code_cancel'
              >
                {t('Cancelar')}
              </Button>
            </Grid>
          </Box>
      }
    </Modal>
  )
}
