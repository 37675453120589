import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  text: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colorBrand.medium
  },
  image: {
    width: '64px',
    height: '100%',
    minHeight: 'inherit',
    objectFit: 'cover',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  }
}))
