import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    background: theme.colors.neutral.lightBase,
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
    height: 'auto',
    paddingTop: theme.spacingInset.sm,

    // mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'unset',
      padding: '0px',
      paddingTop: '64px'
    }
  },
  classTeachersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: theme.spacingInset.lg,
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacingInset.sm
    }
  }
}))
