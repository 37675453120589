import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, TextField } from 'components/design-system'
import Comments from './components/Comments'
import Grid from '@mui/material/Grid'

import { saveCommentRecord, getCommentsRecords, deleteCommentById, editCommentById } from 'services/comments'

import useStyles from './style'
import StatusClassHandle, { CurrentClass } from 'components/common/StatusClassHandle'
interface ICommentsAreaProps {
  classId: string
  activity: number
  selectedStudentProfile: number
  questionId: number
  dataTestid?: string
  dataTestidEditDelete?: string
  dataTestidEditNotDelete?: string
}

export const CommentsArea: React.FC<ICommentsAreaProps> = ({
  classId,
  activity,
  selectedStudentProfile,
  questionId,
  dataTestid,
  dataTestidEditDelete,
  dataTestidEditNotDelete
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [comments, setComments] = useState<any>([])
  const [commentField, setCommentField] = useState<string>('')
  const [isEditing, setIsEditing] = useState<string>('')

  useEffect(() => {
    const fetchComments = async () => {
      const response = await getCommentsRecords(selectedStudentProfile, activity, classId, questionId)

      if (response?.success) {
        setComments(response.data)
      }
    }

    void fetchComments()
  }, [questionId, selectedStudentProfile])

  const createComment = async () => {
    const classIdInt = parseInt(classId)
    const response = await saveCommentRecord({ classId: classIdInt, activity, user_profile: selectedStudentProfile, comment: commentField, questionId })
    setComments([...comments, response.data])
    setCommentField('')
  }

  const deleteComment = async (id: string) => {
    const response = await deleteCommentById(id)

    if (response.success) {
      const removeComments = comments.filter((comment: any) => comment.id !== id)
      setComments(removeComments)
    }

    return response.success
  }

  const editComment = async (id: string, commentString: string) => {
    if (id === 'save') {
      if (isEditing && isEditing !== '') {
        const response = await editCommentById(isEditing, commentField)
        if (response.success) {
          const auxComments = comments.map((comment: any) => comment.id as string === isEditing ? { ...comment, comment: commentField } : comment)
          setComments(auxComments)
          setCommentField('')
          setIsEditing('')
        }
      }
    } else {
      setCommentField(commentString)
      setIsEditing(id)
    }
  }

  const cancelEditComment = async () => {
    setCommentField('')
    setIsEditing('')
  }

  const callButton = async () => {
    if (isEditing && isEditing !== '') {
      await editComment('save', '')
    } else {
      await createComment()
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent='center'
      mt={4}
    >
      {comments?.length > 0 && comments.map((comment: any) => (
        <Comments
          id={comment.id}
          comment={comment.comment}
          commentOwner={comment.comment_owner}
          deleteComment={deleteComment} editComment={editComment}
          cancelEditComment={cancelEditComment}
          isRead={comment.isRead}
          isEdited={comment.isEdited}
          key={comment.id}
        />
      ))}
      <StatusClassHandle>
        <CurrentClass>
          <TextField
            id='comment'
            variant='filled'
            multiline
            numberOfLines={2}
            className={classes.commentInput}
            label={t('Enviar Comentário')}
            value={commentField}
            inputProps={{ maxLength: 2200 }}
            onChange={e => {
              setCommentField(e.target.value)
            }}
          />
          <Grid
            item xs={12} lg={12} display='flex' gap={2} mt={4}
            sx={{
              gridArea: 'interact',
              display: 'flex',
              justifyContent: 'right',
              marginBottom: '20px'
            }}
          >
            {isEditing && isEditing !== '' &&
              <Button
                tabIndex={1}
                variant='outlined'
                onClick={cancelEditComment}
                disabled={commentField.length <= 0}
                data-testid={dataTestidEditNotDelete}
              >
                <span >
                  {t('Cancelar')}
                </span>
              </Button>
            }
            <Button
              tabIndex={1}
              onClick={callButton}
              disabled={commentField.length <= 0}
              data-testid={isEditing && isEditing !== ''
                ? dataTestidEditDelete
                : dataTestid}
            >
              <span >
                {isEditing && isEditing !== '' ? t('Editar') : t('Comentar')}
              </span>
            </Button>
          </Grid>
        </CurrentClass>
      </StatusClassHandle>
    </Grid>
  )
}

export default CommentsArea
