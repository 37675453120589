import { Icon, Text2 } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import useStyles from './style'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, Skeleton } from '@mui/material'
import { useChart } from 'components/hooks'
import { PsychologyOutlined, CrisisAlertOutlined, FaceOutlined } from '@mui/icons-material'

export interface IStudentSituationDescription {
  isLoading: boolean
  studentSituation: number
}

enum StateMachineEnum {
  LOADING = 'LOADING',
  READY = 'READY'
}

export const StudentSituationDescription: React.FC<IStudentSituationDescription> = ({ isLoading, studentSituation }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { getThemeBySituation } = useChart()
  const feedbackTheme = getThemeBySituation(studentSituation)

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.LOADING)
  const [description, setDescription] = useState<React.ReactElement | undefined>(undefined)
  const [icon, setIcon] = useState<React.ReactNode | undefined>(undefined)
  const [title, setTitle] = useState<string | undefined>(undefined)

  const getValuesBySituation = (situation: number) => {
    switch (situation) {
      case 2:
        setDescription(
          <Trans>
            Para estudantes com média entre <strong>6,0</strong> e <strong>8,9</strong>, a sugestão é registrar quais habilidades mostram tendência de queda de rendimento. Observar temas de interesse do estudante e incentivá-lo a trazer dúvidas e aprofundar investigações é um bom convite para maior engajamento.
          </Trans>
        )
        setIcon(<FaceOutlined />)
        setTitle('Precisa de observação')
        break
      case 3:
        setDescription(
          <Trans>
            Estudantes com média superior a <strong>9,0</strong> podem ser instigados através da apresentação de temas de pesquisa adicionais, cursos e concursos apropriados para a faixa etária. Outra estratégia importante é incentivá-los a apoiar outros estudantes.
          </Trans>
        )
        setIcon(<PsychologyOutlined />)
        setTitle('Espaço pra desafios')
        break
      case -1:
      case 1:
      default:
        setDescription(
          <Trans>
            Para estudantes com média entre <strong>0</strong> e <strong>5,9</strong>, é recomendado um acompanhamento detalhado. Analisar avaliações com notas baixas pode indicar quais habilidades o estudante precisa desenvolver. Exercícios de reforço e materiais complementares são boas ferramentas de apoio.
          </Trans>
        )
        setIcon(<CrisisAlertOutlined />)
        setTitle('Requer intervenção')
    }
  }

  useEffect(() => {
    setStateMachine(
      isLoading
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading])

  useEffect(() => {
    getValuesBySituation(studentSituation)
  }, [studentSituation])

  const states = {
    LOADING: (
      <Grid container rowSpacing={2}>
        <Grid container item alignItems='center' columnSpacing={1}>
          <Grid item xs='auto'>
            <Skeleton variant='circular' width={40} height={40} />
          </Grid>
          <Grid item xs>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Skeleton animation='wave' width={64} height={40} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation='wave' width={204} height={40} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant='text' sx={{ width: '100%' }} height={40} />
          <Skeleton variant='text' sx={{ width: '100%' }} height={40} />
          <Skeleton variant='text' sx={{ width: '100%' }} height={40} />
        </Grid>
      </Grid>
    ),
    READY: (
      <Grid container rowSpacing={2}>
        <Grid container item alignItems='center' columnSpacing={1}>
          <Grid className={`${classes.icon} ${studentSituation === 3 ? classes.inverted : ''}`} item xs='auto'>
            <Icon iconColor={feedbackTheme?.feedbackMedium} size={studentSituation === 3 ? 'xxxl' : 'xlarge'}>
              {icon}
            </Icon>
          </Grid>
          <Grid item xs>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Text2 fontSize='xs' fontWeight='bold' lineHeight='sm' mobile='sm' customColor={feedbackTheme?.feedbackMedium}>
                  {t('Situação')}
                </Text2>
              </Grid>
              <Grid item xs={12}>
                <Text2 fontSize='md' fontWeight='medium' lineHeight='sm' mobile='sm' customColor={feedbackTheme?.feedbackMedium}>
                  {title}
                </Text2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark10'>
            {description}
          </Text2>
        </Grid>
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}

export default StudentSituationDescription
