/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createRef, LegacyRef, ReactNode, useEffect } from 'react'

import { Grid, useTheme } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowLeftRounded from '@mui/icons-material/KeyboardArrowLeftRounded'

import { Text, Icon, Button } from 'components/design-system'
import { useClickOutside } from 'components/hooks'

import useStyle from './style'

export type OpenDirectionType = 'top-down' | 'bottom-up'

export interface IDropdownSelectorProps {
  placeholder: string | ReactNode
  onConfirm?: () => void
  onConfirmLabel?: string
  children: ReactNode
  colorTheme?: string
  label?: string
  className?: string
  disableOnConfirm?: boolean
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  dataTestId?: string
  confirmDataTestId?: string
  backButtonLabel?: string
  handleSelectorClick?: () => void
  auxPlaceholder?: string
  selectedTextClassName?: string
  selectedClassName?: string
  drawerContainerClassName?: string
  openDirection?: OpenDirectionType
}

export const DropdownSelector: React.FC<IDropdownSelectorProps> = ({ handleSelectorClick, colorTheme, label, placeholder, onConfirm, onConfirmLabel, children, className, disableOnConfirm = false, isOpen, setIsOpen, dataTestId, confirmDataTestId, backButtonLabel, auxPlaceholder, openDirection = 'top-down', ...props }) => {
  const theme = useTheme()
  const classes = useStyle({ colorTheme: colorTheme ?? theme.colorBrand.medium, openDirection })

  const dropdownRef = useClickOutside(() => setIsOpen(false)) as LegacyRef<HTMLDivElement> | undefined
  const tagginRef = createRef<HTMLDivElement>()

  useEffect(() => {
    setIsOpen(isOpen)
  }, [isOpen])

  const clickRefsDispatch = () => {
    if (handleSelectorClick) {
      handleSelectorClick()
    }
    tagginRef?.current?.click()
  }

  return (
    <>
      <div data-testid={dataTestId} ref={tagginRef} />
      <div className={`${classes.container} ${className} ${isOpen && 'focused'}`} ref={dropdownRef}>
        {backButtonLabel && (
          <Grid>
            <div className={'iconGoBackLink'} onClick={clickRefsDispatch}>
              <KeyboardArrowLeftRounded />
              <Text type='body' color='medium' >{backButtonLabel}</Text>
            </div>
          </Grid>
        )}
        <Grid className={`${classes.selectedContainer} ${props.selectedClassName} ${isOpen && 'focused'} selectedContainer`} onClick={clickRefsDispatch}>
          {label && <Text size='small' className={classes.selectedLabel}>{label}</Text>}

          <Grid className={classes.placeholderGroup}>
            {auxPlaceholder && <Text size='small' className={classes.auxPlaceholder}>{auxPlaceholder}</Text>}
            <Text size='medium' className={`${classes.selectedText} selectedText ${props.selectedTextClassName}`}>{placeholder}</Text>
          </Grid>
          {isOpen
            ? <Icon size='medium'>
              <KeyboardArrowUpOutlinedIcon />
            </Icon>
            : <Icon size='medium'>
              <KeyboardArrowDownOutlinedIcon />
            </Icon>}
        </Grid>
        <Grid className={`${classes.drawerContainer} ${props.drawerContainerClassName} ${isOpen && 'active'} drawerContainer`}>
          <Grid className={'drawerBody'}>
            {children}
          </Grid>
          {onConfirmLabel && (
            <Grid className={classes.drawerFooter}>
              <Button onClick={onConfirm} disabled={disableOnConfirm} data-testid={confirmDataTestId}>
                {onConfirmLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}
