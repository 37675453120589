export const getFileExtension = (fileName: string): string => {
  return (fileName.split('.').pop() ?? '').toLowerCase()
}

export const isAudioFile = (fileName: string): boolean => {
  if (!fileName) return false

  const extension = getFileExtension(fileName ?? '')
  const audioExtensions = ['mp3', 'wav', 'm4a', 'ogg']

  return audioExtensions.includes(extension)
}
