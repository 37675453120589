/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { RefObject, useEffect, useState } from 'react'

function useIntersectionObserver(
  elementRef: RefObject<Element>
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const updateEntry = ([newEntry]: IntersectionObserverEntry[]): void => {
    setEntry(newEntry)
  }

  useEffect(() => {
    const node = elementRef?.current // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || !node) return

    const observer = new IntersectionObserver(updateEntry)

    observer.observe(node)

    return () => observer.disconnect()
  }, [elementRef?.current])

  return entry
}

export default useIntersectionObserver
