import { NavigateNextSharp, NavigateBeforeSharp } from '@mui/icons-material'
import useStyles from './style'

type IDataTestidPagination = {
  before?: string
  next?: string
}

interface IPagination {
  indexSelected: number
  maxLength: number
  setIndexSelected: (index: number) => void
  dataTestid?: IDataTestidPagination
}

const Pagination: React.FC<IPagination> = ({
  indexSelected,
  maxLength,
  setIndexSelected,
  dataTestid
}) => {
  const classes = useStyles()

  return (
    <div className={classes.paginantionWrapper}>
      <div className={classes.paginationControl}>
        <button
          disabled={indexSelected === 0}
          className={classes.paginantionButton}
          onClick={() => setIndexSelected(indexSelected - 1)}
          data-testid={dataTestid?.before}
        >
          <NavigateBeforeSharp />
        </button>
        <div className={classes.paginationDivider} />
        <button
          disabled={indexSelected === maxLength - 1}
          className={classes.paginantionButton}
          onClick={() => setIndexSelected(indexSelected + 1)}
          data-testid={dataTestid?.next}
        >
          <NavigateNextSharp />
        </button>
      </div>
    </div>
  )
}

export default Pagination
