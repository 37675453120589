import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  txtTitle: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colorBrand.medium
  },
  badge: {
    width: '18px',
    height: '18px',
    background: theme.colorBrand.medium,
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    fontFamily: theme.font.fontFamily,
    borderRadius: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    transition: 'all ease-in-out 0.2s',
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colorBrand.medium,
    '&.disabled': {
      transition: 'all ease-in-out 0.2s',
      color: theme.colors.neutral.dark30,
      fontFamily: theme.font.fontFamily,
      lineHeight: theme.font.lineHeight.xs,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold
    }
  }
}))
