import { styled } from '@mui/material/styles'

export const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const Cards = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '8px 8px',
  backgroundColor: theme.colorBrand.lightest
}))

export const ExpandArea = styled('div')(({ theme }) => ({
  backgroundColor: theme.colorBrand.lightest,
  display: 'flex',
  alignTtems: 'stretch',
  width: '100%',
  borderRadius: theme.borderRadius.md,
  marginBottom: theme.spacingInline.xxxs
}))
