import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../../ProvideAuth'
import { LOGIN, TERMS_ACCEPTANCE } from 'navigation/CONSTANTS'
import { ISubscriptionsSessionResponse, IProfilesSessionResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { useStore } from 'store'
import { UserProfileError } from 'navigation/UserProfileError'

interface IPrivateRouteProps extends RouteProps {
  middlewares?: React.FC[]
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, middlewares, component, ...rest }) => {
  const auth = useAuth()
  const { subscription, profile } = useStore()
  // const [erros, setErro] = useState<string[]>([])

  // Como desacoplar essa regra???
  const validateSubscription = (subscription: ISubscriptionsSessionResponse | null): boolean => {
    if (subscription === undefined || subscription === null) {
      // setErro([...erros, 'Você não está matriculado em nenhuma turma'])
      return false
    }

    if (subscription.user_school_profile === undefined || subscription.user_school_profile === null || !subscription.user_school_profile) {
      // setErro([...erros, 'Falta uma escola associada ao seu perfil'])
      return false
    }

    if (subscription.class === undefined || subscription.class === null || !subscription.class) {
      // setErro([...erros, 'Falta uma turma associada ao seu perfil'])
      return false
    }

    return true
  }

  const validateProfile = (profile: IProfilesSessionResponse | null, subscription: ISubscriptionsSessionResponse | null): boolean => {
    if (profile === undefined || profile === null) {
      // setErro([...erros, 'Não foi possivel identificar-te'])
      return false
    }

    if (profile.school === undefined || profile.school === null || !profile.school) {
      // setErro([...erros, 'Você não está associado a nenhuma escola'])
      return false
    }

    if (profile.user === undefined || profile.user === null || !profile.user) {
      // setErro([...erros, 'A escola não está associada ao seu perfil'])
      return false
    }

    if (profile === null || !profile) {
      // setErro([...erros, 'Este perfil não esta associado a nenhuma escola'])
      return false
    }

    if (profile.type === IUserSchoolProfileTypeEnum.student) {
      validateSubscription(subscription)
    }

    return true
  }

  const validateUser = validateProfile(profile, subscription)

  const privateRouteValidation = (location: any) => {
    if (auth?.user) {
      if (validateUser) {
        if (auth.user.accept_terms) {
          return children
        } else {
          return <Redirect to={TERMS_ACCEPTANCE} />
        }
      } else {
        return <UserProfileError />
      }
    } else {
      return <Redirect
        to={{
          pathname: LOGIN,
          state: { from: location }
        }}
      />
    }
  }

  const renderMiddlewares = (location: any, middlewares: any) => {
    let result = privateRouteValidation(location)
    for (const Middleware of middlewares) {
      result = <Middleware>{result}</Middleware>
    }
    return result
  }

  return (
    <Route
      {...rest}
      render={({ location }) => middlewares?.length ? renderMiddlewares(location, middlewares) : privateRouteValidation(location)}
    />
  )
}

export default PrivateRoute
