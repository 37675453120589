export interface ContentResponse {
  id: string
  sectionId: string
  activityId?: string
  evaluationId?: string
  title: string
  type: ContentTypeEnum
  imageUrl?: string
}

export enum ContentTypeEnum {
  reading = 'reading',
  activity = 'activity',
  evaluation = 'evaluation',
  guide = 'guide'
}
