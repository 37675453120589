import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { goToSelectedQuestionIndexAtom } from '../atomStore'
import { useStore } from 'store'
import { BusinessError } from 'navigation/BusinessError'
import StudentActivity from 'pages/ContentUnit/components/Activity/student'
import { CONTENT_UNIT_STUDENT_EXPEDITION } from 'navigation/CONSTANTS'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { allActivities } from 'pages/ContentUnit/atomStore'
import { IContentUnitResponse } from 'services/types'

interface IStudentExpeditionsProps {
  content?: IContentUnitResponse
}

export const StudentExpeditions: React.FC<IStudentExpeditionsProps> = ({ content }) => {
  const { subscription } = useStore()
  const { t } = useTranslation()
  const history = useHistory()
  const { contentId, activityId } = useParams<{
    contentId: string
    activityId: string
  }>()

  // atoms
  const activities = useAtomValue(allActivities)
  const setSelectedQuestionIndex = useUpdateAtom(goToSelectedQuestionIndexAtom)

  if (!subscription || subscription.class?.id === undefined) {
    return <BusinessError error={t('Erro ao selecionar turma')} />
  }

  useEffect(() => {
    if (activityId) setSelectedQuestionIndex(0)
    else if (activities?.length) history.push(CONTENT_UNIT_STUDENT_EXPEDITION(contentId, activities[0]?.id?.toString()))
  }, [activityId, activities])

  return <StudentActivity content={content} />
}

export default StudentExpeditions
