import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    enunciate: {
      justifyContent: 'center',
      '& img': {
        maxWidth: '100%'
      }
    },
    utteranceText: {
      color: theme.colors.neutral.dark10,
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: theme.font.fontWeight.regular,
      width: '100%',
      '& table': {
        borderSpacing: 0,
        '& th, td': {
          border: '1px solid #ddd',
          padding: `${theme.spacingStack.nano} ${theme.spacingStack.xxxs}`,
          fontWeight: theme.font.fontWeight.semibold,
          fontSize: theme.font.fontSize.xs,
          [theme.breakpoints.down('sm')]: {
            border: '1px solid #ddd',
            padding: '5px',
            fontSize: '9px'
          }
        }
      }
    }
  })
)
