import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useAtom } from 'jotai'
import { useStore } from 'store'
import NaturalCompare from 'string-natural-compare'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'

import Grid from '@mui/material/Grid'
import { useTheme, Badge } from '@mui/material'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import { Alert } from 'components/design-system/Alert'
import ErrorWrapper from 'components/common/ErrorWrapper'
import { LoadingWrapper } from 'components/common'
import { toast } from 'components/design-system/Toast/manager'
import { Answered, Corrected, Liberated, Originals } from './components'
import { BusinessError } from 'navigation/BusinessError'

import { IActivityResponse, ActivityControlStatusEnum, DisciplineCodeEnum } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'
import { getActivitiesByContent } from 'services/activity'

import { ReactComponent as CloeCIcon } from 'assets/cloe-c-icon-student.svg'
import { ReactComponent as CloeCIconDisabled } from 'assets/cloe-c-icon-student-disabled.svg'

import {
  contentUnitAtom,
  SubTabsMenusStudent
} from 'pages/ContentUnit/atomStore'

import useStyle from './style'
import { currentSubMenuTabAtom, previousSubMenuTabAtom, subMenuTabsAtom } from '../../SideMenu/components/atomStore'
import { useResetAtom } from 'jotai/utils'
import { getActivitiesFilteredByStatusForStudent } from 'rules/activitiesByStatus'

interface IProcessedActivities {
  activitiesAvailable: IActivityResponse[]
  activitiesPending: IActivityResponse[]
  activitiesAnswered: IActivityResponse[]
  activitiesCorrected: IActivityResponse[]
}

const EMPTY_PROCESSED_ACTIVITIES = {
  activitiesAvailable: [],
  activitiesPending: [],
  activitiesAnswered: [],
  activitiesCorrected: []
}

export const StudentActivities: React.FC = () => {
  const theme = useTheme()

  const { contentId } = useParams<{ contentId: string }>()

  const [loading, setLoading] = useState<boolean>(true)
  const [activities, setActivities] = useState<IActivityResponse[]>([])
  const [hasResponseError, setHasResponseError] = useState<boolean>(false)
  const [processedActivities, setProcessedActivities] = useState<IProcessedActivities>(EMPTY_PROCESSED_ACTIVITIES)

  const { subscription } = useStore()
  const [contentUnit] = useAtom(contentUnitAtom)
  const [previousSubMenuTab] = useAtom(previousSubMenuTabAtom)
  const resetPreviousSubMenuTab = useResetAtom(previousSubMenuTabAtom)
  const [currentSubMenuTab, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [, setSubMenuTabs] = useAtom(subMenuTabsAtom)

  const classes = useStyle({ currentSubMenuTab })

  const isCloeExpand = contentUnit?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI) ?? false
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)

  const subMenuTabsDefault = [
    {
      title: t('Originais'),
      iconComponent: !activities.length ? <CloeCIconDisabled /> : <CloeCIcon />,
      dataTestid: '',
      disabled: !activities.length
    }
  ]

  useEffect(() => {
    handleTabsAppearance(activities)

    if (previousSubMenuTab) {
      setCurrentSubMenuTab(previousSubMenuTab)
      resetPreviousSubMenuTab()
    }

    return () => {
      resetSubMenuTabs()
    }
  }, [activities])

  useEffect(() => {
    void getData()
  }, [])

  const getData = async () => {
    try {
      if (!subscription) return

      setLoading(true)
      const response = await getActivitiesByContent(subscription.class.id, contentId, ContentTypeEnum.activity)

      if (!response.success) {
        throw new Error()
      }
      setActivities(response.data)

      if (previousSubMenuTab !== null) {
        setLoading(false)
        return
      }

      const activitiesPending = response.data?.filter(a => !a.activity_status?.has_answer && [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.CLOSED, ActivityControlStatusEnum.RELEASED].includes(a.activity_status?.status ?? 0)) ?? []
      const activitiesAnswered = response.data?.filter(a => a.activity_status?.has_answer && [ActivityControlStatusEnum.DONE, ActivityControlStatusEnum.CLOSED].includes(a.activity_status?.status)) ?? []
      const activitiesCorrected = response.data?.filter(a => a.activity_status?.has_answer && a.activity_status?.status === ActivityControlStatusEnum.RELEASED) ?? []

      if (activitiesPending.length) {
        setCurrentSubMenuTab(SubTabsMenusStudent.LIBERATED)
      } else if (activitiesAnswered.length) {
        setCurrentSubMenuTab(SubTabsMenusStudent.ANSWERED)
      } else if (activitiesCorrected.length) {
        setCurrentSubMenuTab(SubTabsMenusStudent.CORRECTED)
      } else {
        setCurrentSubMenuTab(SubTabsMenusStudent.ORIGINALS)
      }
    } catch (err: any) {
      setHasResponseError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleTabsAppearance = async (activities: IActivityResponse[] | undefined) => {
    try {
      const activitiesOrdered = activities?.map(cur => {
        // making the title just one to put in alphabetically order
        cur.title = cur.title ?? cur.activity_name
        cur.title = cur.title.trim()
        return cur
      }).sort((a, b) => NaturalCompare(a.title, b.title, { caseInsensitive: true }))

      const activitiesByStatus = getActivitiesFilteredByStatusForStudent(activitiesOrdered ?? [])

      setProcessedActivities({
        activitiesAvailable: activitiesByStatus.available,
        activitiesPending: activitiesByStatus.pending,
        activitiesAnswered: activitiesByStatus.answered,
        activitiesCorrected: activitiesByStatus.corrected
      })

      setSubMenuTabs([
        {
          title: t('Liberadas'),
          iconComponent:
            <Badge
              badgeContent={activitiesByStatus.pending.length}
              max={99}
              className={classes.badge}
            >
              <ShareOutlinedIcon />
            </Badge>,
          dataTestid: '',
          disabled: !activitiesByStatus.pending.length
        },
        {
          title: t('Respondidas'),
          iconComponent: <AssignmentTurnedInOutlinedIcon />,
          dataTestid: '',
          disabled: !activitiesByStatus.answered.length
        },
        {
          title: t('Corrigidas'),
          iconComponent: <VerifiedOutlinedIcon />,
          dataTestid: '',
          disabled: !activitiesByStatus.corrected.length
        },
        ...subMenuTabsDefault
      ])
    } catch (err) {
      toast.handler({
        content: 'Ocorreu um erro ao buscar por atividades',
        duration: 5000,
        severity: 'error'
      })
    }
  }

  if (!subscription || subscription.class?.id === undefined) {
    return <BusinessError error={t('Erro ao selecionar turma', true)} />
  }

  if (!contentId) {
    return (
      <>
        {t('Not Found', true)}
      </>
    )
  }

  const EmptyStateComponent = () =>
    activities?.length === 0
      ? <Grid item xs={12} mt={2}>
        <Alert
          sx={{
            fontSize: '12px',
            background: '#FFE399 !important',
            color: `${theme.colors.warning.feedbackDarkest} !important`,
            width: 'fit-content'
          }}
          content={t('Você ainda não possui atividades.')}
          icon={<React.Fragment />}
        />
      </Grid>
      : <></>

  const defaultProps = {
    isCloeExpand,
    contentId
  }

  const tabs = {
    [SubTabsMenusStudent.ORIGINALS]: <Originals activitiesAvailable={processedActivities.activitiesAvailable} {...defaultProps} />,
    [SubTabsMenusStudent.LIBERATED]: <Liberated activitiesInProgress={processedActivities.activitiesPending} {...defaultProps} />,
    [SubTabsMenusStudent.ANSWERED]: <Answered activitiesAnswered={processedActivities.activitiesAnswered} {...defaultProps} />,
    [SubTabsMenusStudent.CORRECTED]: <Corrected activitiesCorrected={processedActivities.activitiesCorrected} {...defaultProps} />
  }

  const currentTabIndex = (currentSubMenuTab ?? 0) as unknown as SubTabsMenusStudent

  return (
    <>
      <LoadingWrapper type={'linear'} loading={loading}>
        <ErrorWrapper hasError={hasResponseError} errorMessage={t('Não foi possível encontrar as atividades')?.toString()}>
          <EmptyStateComponent />
          {tabs[currentTabIndex]}
        </ErrorWrapper>
      </LoadingWrapper>
    </>
  )
}

export default StudentActivities
