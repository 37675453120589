import React from 'react'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import OnlyProfile from 'components/common/OnlyProfile'
import useStyles from './style'
import { ContentUnitHeaderStudentWithProjectViewMode } from './student'
import { ContentUnitHeaderTeacherWithProjectViewMode } from './teacher'
import { IHeaderProps } from './types'

export const ContentUnitHeader: React.FC<IHeaderProps> = ({ content, className }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.headerTabs} ${className}`}>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <ContentUnitHeaderStudentWithProjectViewMode content={content} />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <ContentUnitHeaderTeacherWithProjectViewMode content={content} />
      </OnlyProfile>
    </div>
  )
}

export default ContentUnitHeader
