import { createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import * as SvgIcons from '@mui/icons-material'

import { Tag } from 'components/design-system'
import { ContentTypeEnum } from 'services/types/content'
import { maxLength } from 'utils/string'
import useStyles from './style'

export interface CardSelectorProps {
  title: string
  type: ContentTypeEnum
  showTypeTag?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  maxWidth?: string
  minWidth?: string
  dataTestId?: string
  onClick?: () => void
  isTemplate?: boolean
  quantityQuestions?: number
  clonedTimes?: number
  momentType?: 'home' | 'during_class'
}

export const CardSelector: React.FC<CardSelectorProps> = ({
  type,
  title,
  showTypeTag,
  dataTestId,
  isSelected = false,
  isDisabled = false,
  maxWidth = '293px',
  minWidth = '293px',
  isTemplate,
  quantityQuestions,
  clonedTimes,
  momentType,
  onClick = () => {
    // This is intentional
  }
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles({ clonedTimes })
  const inputRef = createRef<HTMLDivElement>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'))

  const contentTypes = {
    reading: {
      icon: SvgIcons.DescriptionOutlined,
      label: t('Conteúdo'),
      tagColor: theme.colors.contentType.default
    },
    activity: {
      icon: SvgIcons.ExtensionOutlined,
      label: t('Atividade'),
      tagColor: theme.colors.contentType.activity
    },
    evaluation: {
      icon: SvgIcons.BallotOutlined,
      label: t('Avaliação'),
      tagColor: theme.colors.contentType.evaluation
    },
    guide: {
      icon: SvgIcons.BorderColorOutlined,
      label: t('Atividade'),
      tagColor: theme.colors.contentType.activity
    }
  }

  const { icon: SvgIcon } = contentTypes[type]

  const validateClassRender = () => {
    if (isDisabled) return 'disabled'
    if (isSelected) return 'selected'
    return ''
  }

  const auxSx = (isMobile || isTablet) ? { maxWidth: '100%', minWidth: '100%' } : { maxWidth, minWidth }

  return (
    <>
      <Grid sx={auxSx}>
        <div ref={inputRef} data-testid={dataTestId} hidden />
        <Grid container
          className={`${classes.contentLink} ${validateClassRender()}`}
          flexDirection='column'
          onClick={(e) => {
            e.stopPropagation()
            if (!isDisabled) {
              onClick()
            }
            inputRef?.current?.click()
          }}
        >
          <div className={`${classes.contentLinkHead} ${validateClassRender()}`} >
            <SvgIcon className={`${classes.contentLinkIcon} ${validateClassRender()}`} />
            <div className={`${classes.contentLinkTitle} ${validateClassRender()}`}>
              {isTemplate && <span style={{ textTransform: 'uppercase' }}>{t('[Original]')}</span>}
              <div>
                {maxLength(title, isTemplate ? 17 : 35)}
              </div>
            </div>
          </div>
          {quantityQuestions &&
            <Grid item className={classes.quantityQuestions}>
              <span>{quantityQuestions} {quantityQuestions <= 1 ? 'questão' : 'questões'}</span>
            </Grid>
          }
          <div className={classes.contentLinkTags}>
            {showTypeTag &&
              <Tag color='white' background={isDisabled ? theme.colors.neutral.light40 : contentTypes[type].tagColor}>
                {contentTypes[type].label}
              </Tag>
            }
            {momentType && (
              <Tag
                color={theme.colors.neutral.lightBase}
                className={classes.tag}
                background={theme.colors.contentType.activity}
              >
                {t(momentType)}
              </Tag>
            )}
            {
              clonedTimes !== undefined && (
                <Tag
                  color={theme.colorBrand.light}
                  className={`${classes.tag} ${classes.usedTimes}`}
                  background={'transparent'}
                >
                  {`Utilizada ${clonedTimes} vez(es)`}
                </Tag>
              )
            }
          </div>
        </Grid>
      </Grid>
    </>
  )
}
