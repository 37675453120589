import { makeStyles } from '@mui/styles'
import { Theme, styled as styledMUI } from '@mui/material'
import { Button, TextField } from 'components/design-system'

import styled from 'styled-components'

export const CustomTextField = styled(TextField)`
  margin-top: 0.3rem;
`

export const CustomButton = styled(Button)`
  margin-top: 1.5rem;

  &:hover {
    background: none !important;
    color: #5B5B5B !important;
  }
`

export const WrapperPrivacyPolicy = styledMUI('div')(({ theme }) => ({
  padding: `${theme.spacingStack.xxs} ${theme.spacingStack.xxxs} 0px`,
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}))

export default makeStyles((theme: Theme) => ({
  accordion: {
    '&.MuiAccordion-root': {
      background: 'rgba(230, 230, 230, 0.32)',
      borderRadius: `${theme.spacingInline.nano}!important`,
      boxShadow: 'none',
      '&.selected': {
        backgroundColor: 'rgba(233, 243, 249, 1)',
        border: `2px solid ${theme.colorBrand.medium}`
      }
    },
    '& .MuiAccordionSummary-root .MuiSvgIcon-root': {
      fill: theme.colors.support.colorSupport01
    },
    // to fix divider visualization before expand
    '&.MuiAccordion-root:before': {
      height: '0px'
    }
  },
  formIconBack: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacingStack.xxs,
    color: theme.colors.neutral.light40,
    cursor: 'pointer',
    fontSize: theme.font.fontSize.sm
  },
  formTitle: {
    color: theme.colors.neutral.dark40,
    width: '70%',
    margin: '0 auto'
  },
  title: {
    '& > svg': {
      color: `${theme.colors.support.colorSupport01}!important`,
      marginRight: theme.spacingInline.nano
    },
    display: 'flex',
    alignItems: 'center',
    color: `${theme.colors.support.colorSupport03}!important`,
    lineHeight: `${theme.font.lineHeight.sm}!important`,
    fontWeight: `${theme.font.fontWeight.medium}!important`
  },
  formInput: {
    paddingTop: theme.spacingStack.xs,
    paddingBottom: theme.spacingStack.xs
  },
  input: {
    color: `${theme.colors.support.colorSupport03}`,
    width: '100%',
    textAlign: 'left',
    '&.MuiFormHelperText-root': {
      color: `${theme.colors.warning.feedbackDarkest}`,
      fontWeight: `${theme.font.fontWeight.medium}!important`
    },
    '&.MuiOutlinedInput-root': {
      background: `${theme.colors.neutral.lightBase}!important`
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.colors.support.colorSupport01}`
      },
      '& .MuiSvgIcon-root': {
        fill: `${theme.colors.support.colorSupport01}!important`
      }
    },
    '&.MuiOutlinedInput-root:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.colors.support.colorSupport01}`
      },
      '& .MuiSvgIcon-root': {
        fill: `${theme.colors.support.colorSupport01}!important`
      }
    }
  },
  submitButton: {
    width: '100%',
    '&.active': {
      background: `${theme.colors.support.colorSupport01} !important`,
      border: `1px solid ${theme.colors.support.colorSupport01} !important`
    },
    '&:hover': {
      background: '#602680 !important',
      border: '1px solid #602680 !important'
    },
    '& span': {
      width: '30px !important',
      height: '30px !important'
    },
    '& span svg': {
      color: `${theme.colors.neutral.lightBase} !important`
    }
  },
  alertError: {
    color: `${theme.colors.negative.feedbackDarkest}!important`,
    fontSize: `${theme.font.fontSize.xxs}!important`,
    fontWeight: `${theme.font.fontWeight.semibold}!important`,
    padding: `${theme.spacingSquish.nano} !important`,
    textAlign: 'left',
    '& .MuiAlert-icon': {
      margin: '0px 8px'
    }
  },
  helpOptionBtn: {
    width: '100%',
    color: `${theme.colors.support.colorSupport04}!important`,
    borderColor: `${theme.colors.support.colorSupport04}!important`,

    '&:hover': {
      background: `${theme.colors.neutral.lightBase}!important`
    }
  },
  registerMessage: {
    padding: `${theme.spacingStack.xxs} ${theme.spacingStack.xxxs} 0px`
  },
  itemLeft: {
    paddingTop: theme.spacingStack.nano,
    paddingBottom: theme.spacingStack.xxxs,
    textAlign: 'left'
  },
  itemLeftColor: {
    color: '#4B61AA',
    fontWeight: theme.font.fontWeight.medium,
    cursor: 'pointer'
  },
  linkBlock: {
    paddingTop: theme.spacingStack.nano,
    paddingBottom: theme.spacingStack.xxxs
  },
  autonomousRegistrationButton: {
    color: theme.colors.support.colorSupport02,
    fontSize: `${theme.font.fontSize.xs}!important`,
    width: '100%',
    '&.ghost': {
      color: theme.colors.support.colorSupport02,
      '&:hover': {
        background: theme.colorBrand.lightest
      }
    },
    '& svg': {
      marginRight: '8px'
    }
  },
  text: {
    color: theme.colors.support.colorSupport03,
    lineHeight: theme.font.lineHeight.xs,
    textAlign: 'left'
  },
  divider: {
    '& .MuiDivider-wrapper': {
      color: theme.colors.support.colorSupport03,
      fontWeight: theme.font.fontWeight.medium,
      padding: '0px 16px'
    },
    '&.MuiDivider-root::before': {
      borderTop: `2px solid ${theme.colors.support.colorSupport01}`
    },
    '&.MuiDivider-root::after': {
      borderTop: `2px solid ${theme.colors.support.colorSupport01}`
    }
  },
  accessKeyIcon: {
    marginRight: '8px',
    width: '16px'
  },
  accessKeyContainer: {
    '& input': {
      width: '42.6px !important'
    }
  }
}))
