import { request } from '../base'
import { FIND_STUDENT_TIMELINE, CLASS_SUBSCRIPTIONS_BY_SCHOOL_CLASS_USER } from './CONSTANTS'
import { IFindStudentTimelineResponse, ITimelineRequestOptions } from '../types/timeline'
import { IApiResponse } from '../types'
import { IClassSubscriptionResponse } from 'services/types'

export const findstudentTimeline = async (classId: string | number, disciplineId: string | number, studentId: number | string, options?: ITimelineRequestOptions): Promise<IApiResponse<IFindStudentTimelineResponse[]>> => {
  return await request({
    url: FIND_STUDENT_TIMELINE(classId, disciplineId, studentId),
    method: 'get',
    params: options
  })
}

export const findstudentByClass = async (classId: string | number): Promise<IApiResponse<IClassSubscriptionResponse[]>> => {
  return await request({
    url: CLASS_SUBSCRIPTIONS_BY_SCHOOL_CLASS_USER(classId),
    method: 'get'
  })
}
