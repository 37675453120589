import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IContentCardProps } from '.'

export default makeStyles((theme: Theme) =>
  createStyles({
    backgroundImage: {
      border: `1px solid ${theme.colors.neutral.light10}`,
      width: '320px',
      minHeight: '418px',
      position: 'relative',
      height: 'fit-content',
      borderRadius: theme.borderRadius.sm,
      display: 'inline-block',
      overflow: 'hidden',

      // mobile
      [theme.breakpoints.down('sm')]: {
        borderRadius: theme.borderRadius.sm,
        width: '100%'
      }
    },
    boxCover: {
      backgroundImage: ({ imageUrl }: IContentCardProps) =>
        imageUrl ? `url(${imageUrl})` : 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '300px',
      padding: theme.spacingInset.xs,
      alignItems: 'flex-end',
      display: 'flex',
      // mobile
      [theme.breakpoints.down('sm')]: {
        padding: '104px 16px 16px'
      }
    },
    navationButton: {
      '&.MuiButton-root': {
        borderColor: 'white',
        color: 'white',
        padding: '8px 16px',
        fontSize: '12px',
        '& svg': {
          marginRight: '15px'
        },
        '&:hover': {
          background: 'none'
        }
      }
    },
    disciplineContainer: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      marginTop: theme.spacingStack.xs,
      [theme.breakpoints.down('sm')]: {
        marginTop: '16px'
      },

      '& label': {
        marginRight: theme.spacingInline.nano
      }
    },
    tagContent: {
      fontSize: theme.font.fontSize.xxs
    },
    expeditionContainer: {
      margin: theme.spacingStack.xxxs,
      color: theme.colors.neutral.dark20,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.semibold,

      // mobile
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.sm
      }
    },
    titleContainer: {
      marginTop: theme.spacingStack.xxxs,
      lineHeight: theme.font.lineHeight.xs,
      flexWrap: 'wrap',
      overflow: 'hidden',
      width: '100%',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      fontSize: theme.font.fontSize.xs,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.sm,
        marginBottom: theme.spacingStack.xs
      }
    },
    classNumber: {
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark30
    },
    buttonClass: {
      '&.primary': {
        fontSize: theme.font.fontSize.xxs,
        lineHeight: theme.font.lineHeight.xxs,
        maxHeight: '32px',
        position: 'absolute',
        bottom: '0',
        marginBottom: theme.spacingInline.xxs,
        textTransform: 'none',
        width: '90%'
      }
    },
    dataTag: {
      backgroundColor: 'rgba(67, 67, 67, 0.64)',
      color: theme.colors.neutral.lightBase
    },
    number: {
      top: 0,
      left: 0,
      position: 'absolute',
      marginTop: theme.spacingInline.xxxs,
      marginLeft: theme.spacingInline.xxxs,
      backgroundColor: theme.colorBrand.lightest,
      width: '32px',
      aspectRatio: '1',
      color: theme.colorBrand.darkest,
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.semibold,
      borderRadius: theme.borderRadius.circular,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '1px 0 0 1px',
      border: `${theme.borderThickness.thin} solid ${theme.colorBrand.darkest}`
    }
  })
)
