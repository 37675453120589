import React, { useEffect, useState } from 'react'
import { useStore } from 'store'

import { Grid, useTheme } from '@mui/material'
import { Loading } from 'components/design-system'
import { ReadyState, ErrorState, ConfigurationErrorState, SchoolPeriodNotFound } from './components'

import { getSubscriptionTaughtDisciplinesBySchool } from 'services/taught-disciplines'
import { ISubscriptionsTaughtDisciplinesResponse, IGradeResponse } from 'services/types/taught-disciplines'
import { getGradeTypesByProgram, getGradeFromGradeType } from 'services/grade'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
  SCHOOL_PERIOD_NOT_FOUND = 'SCHOOL_PERIOD_NOT_FOUND',
  ERROR = 'ERROR',
}
export const MyClasses: React.FC = () => {
  const { profile, session, schoolPeriod } = useStore()
  const theme = useTheme()

  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [taughtDisciplines, setTaughtDisciplines] = useState<ISubscriptionsTaughtDisciplinesResponse[]>([])
  const [grades, setGrades] = useState<IGradeResponse[]>([])

  useEffect(() => {
    setStateMachine(StateMachineEnum.LOADING)

    if (!schoolPeriod) {
      setStateMachine(StateMachineEnum.SCHOOL_PERIOD_NOT_FOUND)
      return
    }

    if (!profile || !session) {
      setStateMachine(StateMachineEnum.ERROR)
      return
    }

    // TODO: timeout para dar tempo de adicionar os dados no header
    setTimeout(() => { void getSubscriptionsTaughtDisciplines() }, 1000)
  }, [profile, session, schoolPeriod])

  const getGradeData = async (gradeTypeId: number) => {
    const response = await getGradeFromGradeType(gradeTypeId, schoolPeriod?.id)
    if (response.success) {
      const gradesFiltered = response.data.filter(grade => grade?.classes_count > 0)
      setGrades(gradesFiltered)
    }
  }

  const getGradeTypesData = async () => {
    const response = await getGradeTypesByProgram()
    if (response.success) {
      if (response.data.length) {
        const gradeTypesFiltered = response.data.filter(gradeType => gradeType.classes_count > 0)
        await getGradeData(gradeTypesFiltered[0].id)
      }
    }
  }

  const getSubscriptionsTaughtDisciplines = async () => {
    if (!schoolPeriod?.id) return

    const response = await getSubscriptionTaughtDisciplinesBySchool(schoolPeriod.id)

    if (!response.success) {
      setStateMachine(StateMachineEnum.ERROR)
      return
    }

    const searchSubscriptionFromProfile = session?.subscriptions.find(cur => cur?.user_school_profile?.id && profile?.id && cur.user_school_profile.id === profile.id)

    if (!searchSubscriptionFromProfile) {
      setStateMachine(StateMachineEnum.CONFIGURATION_ERROR)
      return
    }

    if (!response.data.length) {
      await getGradeTypesData()
    }

    setTaughtDisciplines(response.data)
    setStateMachine(StateMachineEnum.READY)
  }

  const pageStates = {
    IDLE: '',
    LOADING: <Grid sx={{ padding: theme.spacingInset.sm }}><Loading type={'linear'} /></Grid>,
    READY: <ReadyState taughtDisciplines={taughtDisciplines} grades={grades} />,
    CONFIGURATION_ERROR: <ConfigurationErrorState />,
    SCHOOL_PERIOD_NOT_FOUND: <SchoolPeriodNotFound />,
    ERROR: <ErrorState />
  }

  return (
    <>
      {pageStates[stateMachine]}
    </>
  )
}

export default MyClasses
