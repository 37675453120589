/* eslint-disable multiline-ternary */
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface IProps {
  titleWidth?: string | number
  disabled?: boolean
}

export default makeStyles((theme: Theme) => createStyles({
  cardWrapper: (props: IProps) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacingInline.nano,

    padding: theme.spacingInset.xxs,

    flexWrap: 'wrap',
    cursor: props.disabled ? 'default' : 'pointer',
    transition: 'all ease-in-out 0.2s',
    alignItems: 'center',

    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.sm,
    fontWeight: 600,

    color: props.disabled ? theme.colors.neutral.dark40 : theme.colorBrand.dark,

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    WebkitFlexWrap: 'nowrap',

    '&:hover': {
      backgroundColor: theme.colorBrand.light,
      color: props.disabled ? theme.colors.neutral.dark40 : theme.colorBrand.lightest
    }
  }),
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}
)
)
