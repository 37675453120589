import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  image404: {
    backgroundImage: 'url(\'/images/cat-offline.png\')',
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat'
  },
  titleEmptyDisciplines: {
    fontSize: theme.font.fontSize.xxxxl,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    fontFamily: theme.font.fontFamily
  },
  containerEmptyDisciplines: {
    textAlign: 'center',
    paddingBottom: theme.spacingInset.lg
  }
}))
