import { NavigateBeforeOutlined, NavigateNextOutlined } from '@mui/icons-material'
import Grid from '@mui/material/Grid'
import { CONTENT_UNIT_STUDENT_EXPEDITION } from 'navigation/CONSTANTS'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { Button } from 'components/design-system'
import { createRef } from 'react'
import { activityIndexSelectedAtom, allActivities } from 'pages/ContentUnit/atomStore'
import useStyles from './styles'

import SendAnswerButton from '../SendAnswerButton'
import useStudentAnswer from '../../hooks/useStudentAnswer'

enum addOrSubstractEnum {
  'ADD',
  'SUBTRACT'
}

export const ActivityFooter = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { contentId } = useParams<{ contentId: string, activityId: string, evaluationId: string }>()
  const inputPreviousRef = createRef<HTMLDivElement>()
  const inputNextRef = createRef<HTMLDivElement>()

  const [activities] = useAtom(allActivities)
  const [activityIndexSelected, setActivityIndexSelected] = useAtom(activityIndexSelectedAtom)
  const {
    activity,
    questionIndex,
    setQuestionIndex,
    isExpeditionView,
    isUploading,
    isActivitySuggestedType
  } = useStudentAnswer()
  const classes = useStyles()
  const nextButtonMessage = isExpeditionView ? 'Próxima atividade' : 'Próximo'
  const previousButtonMessage = isExpeditionView ? 'Atividade anterior' : 'Anterior'
  const showPreviousButton = questionIndex > 0 || isExpeditionView
  const showNextButton = questionIndex < (activity?.questions?.length ?? 0) - 1 || isExpeditionView

  const handleActivityNavigation = (index: number) => {
    setActivityIndexSelected(index)
    if (activities) {
      history.push(CONTENT_UNIT_STUDENT_EXPEDITION(contentId, activities[index].id?.toString()))
    }
  }

  const handleQuestionNavigation = (index: number) => isUploading ? null : setQuestionIndex(index)

  const handleNavigation = (addOrSbstract: addOrSubstractEnum) => {
    const operationValue = addOrSbstract === addOrSubstractEnum.ADD ? 1 : -1
    isExpeditionView ? handleActivityNavigation(activityIndexSelected + operationValue) : handleQuestionNavigation(questionIndex + operationValue)
  }

  const validatePreviousButtonDataTestId = () => {
    if (isActivitySuggestedType) {
      if (isExpeditionView) return 'respond_activities_previous_activity'
      return 'respond_activities_back_question'
    }

    if (isExpeditionView) return 'respond_assessments_previous_activity'
    return 'respond_assessments_back_question'
  }

  const validateNextButtonDataTestId = () => {
    if (isActivitySuggestedType) {
      if (isExpeditionView) return 'respond_activities_next_activity'
      return 'respond_activities_next_question'
    }

    if (isExpeditionView) return 'respond_assessments_next_activity'
    return 'respond_assessments_next_question'
  }

  return (
    <Grid
      container
      justifyContent='space-between'
      mt={3}
      sx={{ marginTop: { lg: '32px' }, marginBottom: '41px' }}
    >
      {
        (showPreviousButton || showNextButton) &&
        <>
          <div ref={inputPreviousRef} data-testid={validatePreviousButtonDataTestId()} hidden />
          <div ref={inputNextRef} data-testid={validateNextButtonDataTestId()} hidden />
          <Grid item>
            {showPreviousButton && (
              <Button
                variant='outlined'
                onClick={() => {
                  inputPreviousRef?.current?.click()
                  handleNavigation(addOrSubstractEnum.SUBTRACT)
                }}
                className={classes.nextButton}
                disabled={activityIndexSelected === 0 && isExpeditionView}
              >
                <span className='only-desktop'>{t(previousButtonMessage)}</span>
                <span className='only-mobile'><NavigateBeforeOutlined /></span>
              </Button>
            )}
            {showNextButton && (
              <Button
                variant='outlined'
                onClick={() => {
                  inputNextRef?.current?.click()
                  handleNavigation(addOrSubstractEnum.ADD)
                }}
                className={classes.nextButton}
                disabled={activityIndexSelected === (activities?.length ?? 0) - 1 && isExpeditionView}
              >
                <span className='only-desktop'>{t(nextButtonMessage)}</span>
                <span className='only-mobile'><NavigateNextOutlined /></span>
              </Button>
            )}
          </Grid>
        </>
      }
      {!isExpeditionView && <SendAnswerButton />}
    </Grid>
  )
}

export default ActivityFooter
