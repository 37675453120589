import MUiButton from '@mui/material/Button'
import { ButtonProps } from '@mui/material'
import makeStyles, { ripple } from './style'
import { IconImage } from 'components/design-system'
import { HTMLAttributeAnchorTarget } from 'react'

export interface IButtonProps extends Omit<ButtonProps, 'variant'> {
  variant?: 'primary' | 'outlined' | 'ghost' | 'outlinedGhost'
  component?: string
  icon?: string
  target?: HTMLAttributeAnchorTarget | undefined
}

export const Button: React.FC<IButtonProps> = ({ variant = 'primary', children, icon, ...props }) => {
  const classes = makeStyles()

  return (
    <MUiButton
      {...props}
      className={`${props.className as string ?? ''} ${classes.root} ${variant}`}
      TouchRippleProps={{ classes: ripple[variant]() }}
    >
      {icon ? <IconImage image={icon} size='medium' /> : null}
      {children}
    </MUiButton>
  )
}
