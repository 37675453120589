import { Grid, Divider, useMediaQuery, useTheme } from '@mui/material'
import { Text2 } from 'components/design-system'
import React, { useEffect, useState } from 'react'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { EmptyOrErrorMessage } from 'components/common'
import AvatarWithCrossedArms from 'assets/avatar-with-crossed-arms-student-colors.svg'
import dayjs from 'dayjs'
import { IActivityPerformance, IUnitStudentDetailedPerformance } from 'contentCacheManager'
import { getDeliveryStatus } from '../../helpers'
import Analytics from 'utils/analytics'
import { CONTENT_UNIT_STUDENT_ACTIVITY } from 'navigation/CONSTANTS'
import { useCurricularStore, useStore } from 'store'

enum StateMachineEnum {
  EMPTY = 'EMPTY',
  IDLE = 'IDLE',
  MOBILE = 'MOBILE',
  READY = 'READY'
}

interface IDetailedPerformanceActivity {
  activities: IActivityPerformance[]
  contentUnit: IUnitStudentDetailedPerformance
}

export const DetailedPerformanceActivity: React.FC<IDetailedPerformanceActivity> = ({ activities, contentUnit }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { profile, subscription, schoolPeriod } = useStore()
  const classId = subscription?.class.id
  const { currentDiscipline, currentClass } = useCurricularStore()

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  useEffect(() => {
    const state = isMobile
      ? StateMachineEnum.MOBILE
      : StateMachineEnum.READY

    setStateMachine(
      activities.length
        ? state
        : StateMachineEnum.EMPTY
    )
  }, [activities, isMobile])

  const handleActivityClick = (a: IActivityPerformance) => {
    window.open(CONTENT_UNIT_STUDENT_ACTIVITY(a.id_contents.toString(), a.id_activity.toString()), '_blank')
    Analytics.recordEventClick({
      name: 'student_menu_followup_activity_open',
      attributes: {
        ...subscription?.analytics,
        school_name: profile?.school?.name,
        class_id: classId,
        class_name: currentClass?.name,
        discipline_id: currentDiscipline?.id,
        discipline_title: currentDiscipline?.name,
        period_status: schoolPeriod?.current,
        period_title: schoolPeriod?.title,
        period_id: schoolPeriod?.id,
        expedition_id: a.id_contents,
        expedition_title: contentUnit.description,
        activity_id: a.id_activity,
        activity_title: a.title
      }
    })
  }

  const states = {
    EMPTY: (
      <EmptyOrErrorMessage
        image={AvatarWithCrossedArms}
        message='Ainda não existem atividades liberadas para a turma nesta unidade. As atividades liberadas aparecerão aqui.'
        reverse
      />
    ),
    IDLE: <></>,
    MOBILE: activities.map((a, index) => (
      <Grid container marginTop={1} rowSpacing={3} key={a.id_activity}>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Data final')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {dayjs(a.dt_end_activity).format('DD/MM/YYYY')}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nome')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2
              data-testid='student_menu_followup_activity_open'
              fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20' cursorpointer
              style={{ textDecoration: 'underline' }}
              onClick={() => {
                handleActivityClick(a)
              }}
            >
              {a.title}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Entrega')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {getDeliveryStatus(a.activity_submitted_status)}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item alignItems='center'>
          <Grid item xs={6}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Situação da atividade')}:
            </Text2>
          </Grid>
          <Grid item xs={6} className={classes.textAlignRight}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
              {a.activity_correction_status}
            </Text2>
          </Grid>
        </Grid>
        {((activities.length - 1) !== index) && (
          <Grid container item xs={12}>
            <Divider className={classes.divider} variant='fullWidth' />
          </Grid>
        )}
      </Grid>
    )),
    READY: (
      <Grid container className={classes.tabPanel} rowSpacing={2}>
        <Grid container item alignItems='center' justifyContent='space-around' columnSpacing={2}>
          <Grid item xs={2} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Data final')}
            </Text2>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Nome')}
            </Text2>
          </Grid>
          <Grid item xs={3} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Entrega')}
            </Text2>
          </Grid>
          <Grid item xs={4} className={classes.cell}>
            <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='sm' neutral='dark20'>
              {t('Situação da atividade')}
            </Text2>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Divider className={classes.divider} variant='fullWidth' />
        </Grid>
        {activities.map((a, index) => (
          <React.Fragment key={a.id_activity}>
            <Grid container item alignItems='center' justifyContent='space-around' columnSpacing={2}>
              <Grid item xs={2} className={classes.cell}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                  {dayjs(a.dt_end_activity).format('DD/MM/YYYY')}
                </Text2>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                <Text2
                  data-testid='student_menu_followup_activity_open'
                  fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20' cursorpointer
                  style={{ textDecoration: 'underline' }}
                  onClick={() => {
                    handleActivityClick(a)
                  }}
                >
                  {a.title}
                </Text2>
              </Grid>
              <Grid item xs={3} className={classes.cell}>
                {getDeliveryStatus(a.activity_submitted_status)}
              </Grid>
              <Grid item xs={4} className={classes.cell}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' neutral='dark20'>
                  {a.activity_correction_status}
                </Text2>
              </Grid>
            </Grid>
            {((activities.length - 1) !== index) && (
              <Grid container item xs={12}>
                <Divider className={classes.divider} variant='fullWidth' />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
