import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

import { IFilterByDateRangeProps } from '.'

export default makeStyles((theme: Theme) => createStyles({
  filterTitle: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark30,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  filterTitleDesktop: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark30,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  datePicker: {
    background: theme.colors.neutral.light10
  },
  buttonDatePicker: {
    padding: '8px 40px !important',
    marginBottom: `${theme.spacingInset.sm} !important`,
    height: '58px !important',
    width: '100%',
    color: `${theme.colors.neutral.dark30} !important`,
    background: `${theme.colors.neutral.light10} !important`,
    '&.selected': {
      background: `${theme.colorBrand.medium} !important`,
      color: `${theme.colors.neutral.lightBase} !important`
    },
    '&.cleanFilter': {
      background: `${theme.colors.negative.feedbackLight} !important`,
      color: `${theme.colors.neutral.lightBase} !important`
    },
    [theme.breakpoints.up('md')]: {
      marginTop: [[0], '!important'],
      width: 'inherit'
    }
  },
  accordion: {
    '&.MuiPaper-root': {
      boxShadow: 'none',
      [theme.breakpoints.up('md')]: {
        '& div.MuiAccordionSummary-root': {
          display: 'none'
        },
        '& div div.MuiAccordionSummary-expandIconWrapper': {
          display: 'none'
        }
      }
    }
  },
  accordionDetails: {
    [theme.breakpoints.up('md')]: {
      padding: '16px 0px 8px 0px !important',
      display: 'flex'
    }
  },
  inputDateContainer: {
    height: '102px',
    '& > div': {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'inherit'
      }
    }
  },
  gridTemplate: {
    display: 'grid !important',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
                        "title"
                        "datainicial"
                        "datafinal"
                        "button"
                      `,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, auto)',
      justifyContent: ({ justify }: Partial<IFilterByDateRangeProps>) => justify,
      gridTemplateAreas: `
                          "title title title"
                          "datainicial datafinal button"
                        `
    }
  }
}))
