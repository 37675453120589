import useStyles from './style'
import { Icon } from 'components/design-system/Icon'
import { IconButton } from '@mui/material'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'

interface ExpandButtonProps {
  open: boolean
}

const ExpandButton = ({ open }: ExpandButtonProps) => {
  const classes = useStyles()
  return (
    <IconButton className={classes.wrapper}>
      <Icon className={classes.default} size='large'>
        {
          open
            ? <KeyboardArrowDownOutlinedIcon className={classes.default} />
            : <KeyboardArrowUpOutlinedIcon className={classes.default} />
        }
      </Icon>
    </IconButton>
  )
}

export default ExpandButton
