import { useEffect } from 'react'
import { useStore } from 'store'

import { useRouter } from 'navigation/hooks/useRouter'
import { ROOT } from 'navigation/CONSTANTS'

import { CurrentClass, StatusClassHandle } from 'components/common'

export const OnlyTeacherCurrentSchoolPeriod: React.FC = ({ children }) => {
  const router = useRouter()
  const { schoolPeriod, profile } = useStore()

  useEffect(() => {
    checkSchoolPeriodRedirect()
  }, [schoolPeriod])

  const checkSchoolPeriodRedirect = () => {
    if (schoolPeriod?.current === true || profile?.type !== 'teacher') {
      return
    }

    const notAllowedPaths = ['/single-record', '/milestone']

    const isBlockedPath = notAllowedPaths.map(blockedPath => router.pathname.includes(blockedPath))
    if (isBlockedPath) {
      router.push(ROOT)
    }
  }

  return (
    <StatusClassHandle>
      <CurrentClass>{children}</CurrentClass>
    </StatusClassHandle>
  )
}
