import { useRef } from 'react'
import { Grid } from '@mui/material'

import useStyles from './style'

export interface CardProps {
  title: string
  id?: number | string
  expanded?: boolean
  iconComponent?: React.ReactNode
  endAdornment?: React.ReactNode
  dataTestid?: string
  isActive?: boolean
  onClick?: () => void
  disabled?: boolean
  onlyIcon?: boolean
}

export const Card = ({
  expanded = false,
  iconComponent,
  title,
  endAdornment,
  dataTestid,
  isActive: isActiveRaw = false,
  onlyIcon = false,
  disabled = false,
  onClick,
  id
}: CardProps) => {
  const cardExpanded = expanded
  const titleRef = useRef<HTMLDivElement | null>()

  const props = { cardExpanded, titleWidth: titleRef.current?.clientWidth, disabled }
  const classes = useStyles({ ...props })

  const handleClick = () => {
    if (onClick) onClick()
  }

  return (
    <Grid
      ref={(el) => {
        titleRef.current = el
      }}
      className={classes.cardWrapper}
      id={`${id}`}
      onClick={disabled ? () => { } : handleClick}
      data-testid={dataTestid}
    >
      {iconComponent ?? null}
      <div className={classes.title}>{title}</div>
      {endAdornment ?? null}
    </Grid>
  )
}
