import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { IAlternativeProps } from '.'

export default makeStyles((theme: Theme) =>
  createStyles({
    question: {
      padding: theme.spacingStack.nano,
      marginLeft: 0,
      marginBlock: theme.spacingStack.xxxs,
      borderRadius: theme.spacingStack.nano,
      width: '100%',
      backgroundColor: (props: IAlternativeProps) => {
        if (props.isSolutionView) {
          return 'rgba(198, 228, 255, 0.32)'
        }
        return theme.colors.neutral.lightBase
      },
      border: (props: IAlternativeProps) => {
        if (props.isStudentView) {
          return `1px solid ${theme.colors.neutral.light30}`
        }

        if (props.isSolutionView) {
          return `1px solid ${theme.colors.information.feedbackLight}`
        }

        if (props.isWrong) {
          return `2px solid ${theme.colors.negative.feedbackMedium}`
        } else if (props.isCorrect) {
          return `2px solid ${theme.colors.positive.feedbackMedium}`
        } else if (props.isActive) {
          return `2px solid ${theme.colorBrand.medium}`
        } else {
          return `1px solid ${theme.colors.neutral.light30}`
        }
      },
      '& span': {
        fontWeight: theme.font.fontWeight.medium,
        color: (props: IAlternativeProps) => {
          if (props.isStudentView) {
            return `${theme.colors.neutral.light30}`
          }

          if (props.isSolutionView) {
            return theme.colors.information.feedbackDarkest
          }

          if (props.isWrong) {
            return `${theme.colors.negative.feedbackMedium}`
          } else if (props.isCorrect) {
            return `${theme.colors.positive.feedbackMedium}`
          } else if (props.isActive) {
            return `${theme.colorBrand.medium}`
          } else {
            return `${theme.colors.neutral.light30}`
          }
        }
      },
      '& .MuiFormControlLabel-label': {
        width: '100%'
      }
    },
    resource: {
      '& img': {
        width: 'inherit'
      }
    },
    colorButtonDefault: {
      color: `${theme.colors.neutral.dark40} !important`
    },
    correctText: {
      padding: `0 ${theme.spacingInline.xxxs}`,
      fontSize: theme.font.fontSize.xxs,
      alignSelf: 'center'
    },
    alternativeText: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '18px', // theme.font.fontSize.sm,
      color: (props: IAlternativeProps) => {
        if (props.isStudentView) {
          return `${theme.colors.neutral.dark40}`
        }

        if (props.isSolutionView) {
          return theme.colors.information.feedbackDarkest
        }

        if (props.isWrong) {
          return `${theme.colors.negative.feedbackMedium}`
        } else if (props.isCorrect) {
          return `${theme.colors.positive.feedbackMedium}`
        } else if (props.isActive) {
          return `${theme.colorBrand.medium}`
        } else {
          return `${theme.colors.neutral.dark40}`
        }
      },
      // mobile
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.xxs
      }
    }
  })
)
