import { Text, Button } from 'components/design-system'
import Modal from '@mui/material/Modal'
import { useAtom } from 'jotai'
import useStyle from './style'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import Grid from '@mui/material/Grid'
import { changeschoolMenuStateSchoolsAtom, activeModalConfirmationAtom } from 'navigation/atomStore'
import { ROOT, CLOE_ADMIN } from 'navigation/CONSTANTS'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import Analytics from 'utils/analytics'
import { useAuth } from 'navigation/ProvideAuth'
import { useState } from 'react'

export const ConfirmationModal: React.FC = () => {
  const { profile, setProfile, setShowCloePro } = useStore()
  const [activeModalConfirmation, setActiveModalConfirmation] = useAtom(activeModalConfirmationAtom)
  const [localProfile, setLocalProfile] = useAtom(changeschoolMenuStateSchoolsAtom)
  const { t } = useTranslation()
  const classes = useStyle()
  const auth = useAuth()

  const [isLoading, setIsLoading] = useState(false)

  const handleActiveModal = () => {
    setActiveModalConfirmation(!activeModalConfirmation)
    setLocalProfile(null)
  }

  const selectSchools = async () => {
    setIsLoading(true)
    const redirectToAdmin = [IUserSchoolProfileTypeEnum.coordinator?.toString(), IUserSchoolProfileTypeEnum.admin?.toString()]

    if (profile) {
      try {
        await Analytics.recordEventClickSync({
          name: 'change_profile',
          attributes: {
            ...profile?.analytics,
            current_school_id: profile?.school?.id,
            current_school_name: profile?.school?.name,
            selected_school_id: localProfile?.school?.id,
            selected_school_name: localProfile?.school?.name
          }
        })
      } catch (err) {
        console.error(`ERROR: ${err}`)
      }
    }

    if (localProfile) {
      await auth?.updateLastUsedProfile(localProfile.id)
    }

    if (localProfile && redirectToAdmin.includes(localProfile?.type)) {
      window.location.href = CLOE_ADMIN
    } else {
      setProfile(localProfile)
      void handleActiveModal()
      window.location.href = ROOT
    }

    setIsLoading(false)
    setShowCloePro(false)
  }

  return (
    <Modal open={activeModalConfirmation} onClose={handleActiveModal}>
      <Box className={classes.boxModal}>
        <Grid>
          <Text className={classes.classesItensText} type='body'>
            {t('Ao confirmar a troca, iremos te redirecionar para a página inicial da escola escolhida')}
          </Text>
        </Grid>
        <Grid className={classes.classButton}>
          <Button
            variant='primary'
            onClick={selectSchools}
            disabled={isLoading}
            data-testid='confirm_profile_change_confirm'
          >
            {t('Confirmar troca')}
          </Button>
          <Button
            sx={{ marginTop: '10px' }}
            variant='ghost'
            onClick={handleActiveModal}
            data-testid='confirm_profile_change_cancel'
          >
            {t('Cancelar')}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
