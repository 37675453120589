/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { format, parseISO } from 'date-fns'

import { ICommentsResponse } from 'services/types/comments'
import { Avatar } from 'components/design-system'

import useStyles from './style'
import { useAuth } from 'navigation/ProvideAuth'
import CardStudentAnswerCommentContentActions from './components/ContentActions'
import CardStudentAnswerCommentForm from '../CommentForm'
import { readComment } from 'services/comments'

interface ICardStudentAnswerCommentProps {
  comment: ICommentsResponse
}

export const CardStudentAnswerComment: React.FC<ICardStudentAnswerCommentProps> = ({ comment }) => {
  const classes = useStyles()
  const auth = useAuth()

  const [isEditing, setIsEditing] = useState(false)

  const readComments = async () => {
    if (auth?.user?.id !== comment.comment_owner?.id && !comment.isRead) {
      await readComment(`${comment.id}`)
    }
  }

  useEffect(() => {
    void readComments()
  }, [])

  return (
    <Grid container display='flex' flexDirection='column' pb='19px' gap='19px' width={auth?.user?.username === comment.comment_owner?.username ? '100%' : '90%'} alignSelf='flex-end'>
      <Grid item display='flex' gap='8px' width='100%'>
        <Avatar userName={comment?.comment_owner?.name} sx={{ width: 24, height: 24 }} />
        <Grid width='100%' display='flex' justifyContent='space-between' alignItems='center'>
          <Grid item display='flex' flexDirection='column'>
            <span className={classes.cardStudentAnswerCommentTextName}>{`${comment.comment_owner?.name} ${comment.comment_owner?.surname}`}</span>
            <span className={classes.cardStudentAnswerCommentTextDate}>Enviada em: {format(parseISO(comment?.created_at), "dd/MM/yyyy 'às' HH:mm")} {comment?.isEdited ? `(editado em ${format(parseISO(comment?.updated_at), "dd/MM/yyyy 'às' HH:mm")})` : ''}</span>
          </Grid>

          <CardStudentAnswerCommentContentActions comment={comment} handleEdit={() => setIsEditing(true)} />
        </Grid>
      </Grid>
      {
        isEditing
          ? <CardStudentAnswerCommentForm user_profile={Number(comment?.user_school_profile.id ?? '0')} isEditing currentComment={comment} handleClose={() => setIsEditing(false)} />
          : <Grid className={classes.cardStudentAnswerComment}>{comment.comment}</Grid>
      }
    </Grid >
  )
}

export default CardStudentAnswerComment
