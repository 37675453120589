import React, { useEffect } from 'react'
import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Text2 } from 'components/design-system'
import { Disciplines } from 'components/store/Disciplines'
import { BigNumbers, ReportsList } from '../'
import { HeaderTabs } from '../../../Header'

import useStyle from './styles'
import { useAtomValue, useResetAtom } from 'jotai/utils'
import { useReport } from '../../hooks'
import { lastUpdatedDateAtom } from '../../atomStore'
import { LastUpdate } from '../LastUpdate'

export const ClassReport: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()
  const { getUpdatedDate, isLoadingDate } = useReport()
  const lastUpdatedDate = useAtomValue(lastUpdatedDateAtom)
  const resetLastUpdatedDate = useResetAtom(lastUpdatedDateAtom)

  useEffect(() => {
    resetLastUpdatedDate()
    getUpdatedDate()
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeaderTabs className='only-mobile' />
      </Grid>

      <Grid container>
        <Grid container direction='column'>
          <Text2
            fontSize='xl'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xl'
            customColor={theme.colorBrand.medium}
          >
            {t('Acompanhamento')}
          </Text2>

          <Grid container gap={1} alignItems={'center'}>
            {(lastUpdatedDate ?? isLoadingDate) && (
              <LastUpdate isLoading={isLoadingDate} />
            )}
          </Grid>
        </Grid>

        <Grid container direction='column' marginTop={3.5} xs={12} className={classes.disciplineContainer} >
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark20}
          >
            {t('Componentes Curriculares')}
          </Text2>

          <Disciplines dataTestid='followup_select_curricular_component' showAlertOnError />
        </Grid>

        <Grid container marginBottom={5}>
          <BigNumbers />
        </Grid>
        <Grid container marginBottom={3.5}>
          <ReportsList />
        </Grid>
      </Grid>
    </Grid>
  )
}
