import { createRef } from 'react'
import { useMediaQuery, useTheme, Grid, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Text2, Icon } from 'components/design-system'

import useStyle from './styles'

type MuiICon = OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
  muiName: string
}

interface IQuickAccessHeaderProps {
  onClick: () => void
  IconComponent: MuiICon
  title: string
  description: string
  wormImageSrc: string
  wormImageClassName: string
  dataTestId?: string
}

export const QuickAccessCard: React.FC<IQuickAccessHeaderProps> = ({ onClick, IconComponent, title, description, wormImageSrc, wormImageClassName, dataTestId }) => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const inputRef = createRef<HTMLDivElement>()

  const handleClick = () => {
    inputRef?.current?.click()
    onClick()
  }

  return (
    <>
      <div ref={inputRef} data-testid={dataTestId} hidden />
      <Grid className={classes.quickAccessCardContainer} onClick={handleClick}>
        <Grid className={classes.quickAccessCard}>
          <Grid className={classes.quickAccessCardTitleContainer}>
            <Icon size='medium'>
              <IconComponent />
            </Icon>
            <Text2
              className={classes.quickAccessCardTitle}
              fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs'>
              {title}
            </Text2>
          </Grid>
          <Text2
            className={classes.quickAccessCardDescription}
            fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs'>
            {description}
          </Text2>
        </Grid>
        {!isMobile && <img src={wormImageSrc} className={wormImageClassName} />}
      </Grid>
    </>
  )
}
