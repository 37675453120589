import { useState, useEffect, useLayoutEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { getContentUnitV2, getDidaticContent } from 'services/content-unit'
import { IContentUnitResponse } from 'services/types/content-unit'
import Loading from 'components/common/Loading'
import useStyles from './style'
import ContentUnitHeader from './components/Header'
import { useCurricularStore, useStore } from 'store'
import { useTranslation } from 'react-i18next'
import { BusinessError } from 'navigation/BusinessError'
import ErrorBoundary from 'components/common/ErrorBoundary'
import OnlyProfile from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { ContentUnitStudent } from './student'
import { ContentUnitTeacher } from './teacher'
import {
  sectionIdAtom,
  contentUnitAtom,
  didaticContentAtom
} from './atomStore'
import { useAtom } from 'jotai'
import { RESET, useAtomValue, useResetAtom } from 'jotai/utils'
import Analytics from 'utils/analytics'
import MenuIcon from '@mui/icons-material/Menu'
import ErrorWrapper from 'components/common/ErrorWrapper'
import { useSkills } from './hooks/useSkills'
import { useContentSkills } from './hooks/useContentSkills'
import useStudentAnswer from './components/Activity/hooks/useStudentAnswer'
import { useRouter } from 'navigation/hooks/useRouter'
import {
  CONTENT_UNIT_STUDENT_ACTIVITIES,
  CONTENT_UNIT_STUDENT_ACTIVITY,
  CONTENT_UNIT_STUDENT_EVALUATION,
  CONTENT_UNIT_STUDENT_EVALUATIONS,
  CONTENT_UNIT_STUDENT_EXPEDITION,
  CONTENT_UNIT_STUDENT_EXPEDITIONS,
  CONTENT_UNIT_TEACHER_ACTIVITIES,
  CONTENT_UNIT_TEACHER_ACTIVITY,
  CONTENT_UNIT_TEACHER_EVALUATION,
  CONTENT_UNIT_TEACHER_EVALUATIONS,
  CONTENT_UNIT_TEACHER_EXPEDITION,
  CONTENT_UNIT_TEACHER_EXPEDITION_ONE
} from 'navigation/CONSTANTS'
import { SideMenu } from './components/SideMenu'
import { subMenuTabsAtom, currentSubMenuTabAtom, previousSubMenuTabAtom } from './components/SideMenu/components/atomStore'
import { useToggleSideMenuAtom } from './components/atomStore'
import { BottomMenu } from './components/BottomMenu'
import { useMediaQuery, useTheme } from '@mui/material'
import { BackLink } from './components/BackLink'

enum PageStateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR',
  SECTION_ERROR = 'SECTION_ERROR'
}

export const ContentUnit: React.FC = () => {
  const { contentId, activityId, evaluationId, classId, gradeTypeCode, gradeCode } = useParams<{ contentId: string, activityId?: string, evaluationId: string, classId: string, gradeTypeCode?: string, gradeCode?: string }>()
  const { subscription, profile } = useStore()
  const { t } = useTranslation()
  const { setCurrentContentUnit } = useCurricularStore()
  const { get: getSkills } = useSkills(Number(contentId))
  const { get: getContentSkills } = useContentSkills(Number(contentId))
  const { pathname, history } = useRouter()
  const { isExpeditionView, activity, setIsVerifyingRoutes, isVerifyingRoutes, activityOrEvaluationId } = useStudentAnswer()

  // atoms
  const [contentUnit, setContentUnit] = useAtom(contentUnitAtom)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { toggleSideMenuAtom } = useToggleSideMenuAtom()
  const [isOpenSideMenu, setIsOpenSideMenu] = useAtom(toggleSideMenuAtom)

  const [didacticContent, setDidacticContent] = useAtom(didaticContentAtom)
  const sectionId = useAtomValue(sectionIdAtom)
  const [, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)
  const resetPreviousSubMenuTab = useResetAtom(previousSubMenuTabAtom)

  const classes = useStyles({ isTeacher: profile?.type === IUserSchoolProfileTypeEnum.teacher })

  // states
  const [pageStateMachine, setPageStateMachine] = useState<PageStateMachineEnum>(PageStateMachineEnum.IDLE)

  let localClassID: number = Number(classId)

  const getData = async () => {
    setPageStateMachine(PageStateMachineEnum.LOADING)

    if (contentUnit?.id && Number(contentUnit.id) === Number(contentId)) {
      setPageStateMachine(PageStateMachineEnum.READY)
      return
    }

    setContentUnit(null)
    setDidacticContent(null)

    const response = await getContentUnitV2(contentId)

    if (!response.success) {
      setPageStateMachine(PageStateMachineEnum.ERROR)
      return
    }

    setContentUnit(response.data)

    if (!response.data.sections.length) {
      setPageStateMachine(PageStateMachineEnum.SECTION_ERROR)
      return
    }

    if (profile?.type === IUserSchoolProfileTypeEnum.student) {
      localClassID = Number(subscription?.class.id)
    }

    await setCurrentContentUnit(response.data)

    const responseDidaticContent = await getDidaticContent(localClassID, Number(contentId))
    setDidacticContent(responseDidaticContent?.data?.didactic_content ?? null)

    setPageStateMachine(PageStateMachineEnum.READY)
  }

  const checkExpeditionPath = async () => {
    if (!contentUnit?.title) return
    setIsVerifyingRoutes(true)

    const isTeacher = profile?.type === IUserSchoolProfileTypeEnum.teacher && gradeTypeCode && gradeCode
    const pathnameArr = pathname.split('/')
    const pathParameterPageType = isTeacher ? pathnameArr[9] : pathnameArr[3]
    const activityEvaluationId = activityOrEvaluationId ?? (isTeacher ? pathnameArr[10] : pathnameArr[4])

    const relativePages = ['evaluations', 'expeditions', 'activities']

    const CONTENT_UNIT_EXPEDITIONS_PATH = isTeacher ? CONTENT_UNIT_TEACHER_EXPEDITION(gradeTypeCode, gradeCode, classId, contentId) : CONTENT_UNIT_STUDENT_EXPEDITIONS(contentId)
    const CONTENT_UNIT_EXPEDITION_PATH = isTeacher ? CONTENT_UNIT_TEACHER_EXPEDITION_ONE(gradeTypeCode, gradeCode, classId, contentId, activityEvaluationId ?? '') : CONTENT_UNIT_STUDENT_EXPEDITION(contentId, activityEvaluationId)
    const CONTENT_UNIT_EVALUATION_PATH = isTeacher ? CONTENT_UNIT_TEACHER_EVALUATION(gradeTypeCode, gradeCode, classId, contentId, activityEvaluationId) : CONTENT_UNIT_STUDENT_EVALUATION(contentId, activityEvaluationId)
    const CONTENT_UNIT_EVALUATIONS_PATH = isTeacher ? CONTENT_UNIT_TEACHER_EVALUATIONS(gradeTypeCode, gradeCode, classId, contentId) : CONTENT_UNIT_STUDENT_EVALUATIONS(contentId)
    const CONTENT_UNIT_ACTIVITY_PATH = isTeacher ? CONTENT_UNIT_TEACHER_ACTIVITY(gradeTypeCode, gradeCode, classId, contentId, activityEvaluationId) : CONTENT_UNIT_STUDENT_ACTIVITY(contentId, activityEvaluationId)
    const CONTENT_UNIT_ACTIVITIES_PATH = isTeacher ? CONTENT_UNIT_TEACHER_ACTIVITIES(gradeTypeCode, gradeCode, classId, contentId) : CONTENT_UNIT_STUDENT_ACTIVITIES(contentId)

    const expeditionPath = CONTENT_UNIT_EXPEDITIONS_PATH
    const expeditionPathArr = expeditionPath.split('/')

    if (!relativePages.includes(pathParameterPageType)) {
      setIsVerifyingRoutes(false)
      return
    }

    const isExpeditionPath = pathParameterPageType === (isTeacher ? expeditionPathArr[9] : expeditionPathArr[3])

    // If it should be expeditionView and isn't
    if (isExpeditionView && !isExpeditionPath) {
      const redirectPath = activityEvaluationId ? CONTENT_UNIT_EXPEDITION_PATH : expeditionPath
      history.push(redirectPath)
      setIsVerifyingRoutes(false)
      return
    }

    if (!activity || (activity && activity?.id !== Number(activityEvaluationId))) {
      setIsVerifyingRoutes(false)
      return
    }

    const isEvaluation = activity.suggested_application_type === 'evaluation'
    const isPathEvaluation = (isTeacher ? pathnameArr[9] : pathnameArr[3]) === 'evaluations'
    // If it should be activity/evaluation and isn't

    if ((!isExpeditionView && isExpeditionPath) && (isPathEvaluation !== isEvaluation)) {
      let redirectPath
      if (activityEvaluationId) {
        redirectPath = isEvaluation ? CONTENT_UNIT_EVALUATION_PATH : CONTENT_UNIT_ACTIVITY_PATH
      } else {
        redirectPath = isEvaluation ? CONTENT_UNIT_EVALUATIONS_PATH : CONTENT_UNIT_ACTIVITIES_PATH
      }

      history.push(redirectPath)
    }
    setIsVerifyingRoutes(false)
  }

  useLayoutEffect(() => {
    checkExpeditionPath()
  }, [pathname, contentId, activity, evaluationId, activityId])

  useEffect(() => {
    getSkills()
    getContentSkills()

    setIsOpenSideMenu(true)

    return () => {
      setCurrentSubMenuTab(RESET)
      resetPreviousSubMenuTab()
      resetSubMenuTabs()
    }
  }, [])

  useLayoutEffect(() => {
    if (contentId ?? activityId ?? sectionId ?? evaluationId) {
      getData()
    }
  }, [contentId, activityId, sectionId, evaluationId, pathname])

  const analyticsOnErrorContentNotFound = (data?: IContentUnitResponse) => {
    if (subscription) {
      Analytics.recordEventClick({
        name: 'error',
        attributes: {
          ...subscription?.analytics,
          discipline_id: data?.disciplines[0].id ?? '',
          discipline_title: data?.disciplines.map(d => d.name) ?? '',
          expedition_id: contentId,
          expedition_title: data?.title ?? '',
          url: `/content-units/${contentId}`
        }
      })
    }
  }

  const renderStudent = (data: IContentUnitResponse) => {
    return (
      <ErrorWrapper hasError={!data} errorMessage={t('Falha ao carregar Conteúdo Didático')?.toString()}>
        <ContentUnitStudent data={data} didacticContent={didacticContent} />
      </ErrorWrapper>
    )
  }

  if (!contentUnit?.title || isVerifyingRoutes) {
    return <Loading type='linear' />
  }

  const states = {
    IDLE: '',
    LOADING: <Loading type='linear' />,
    ERROR: (
      <>
        <BusinessError error={t('Não foi possível carregar o contéudo')} />
        {analyticsOnErrorContentNotFound()}
      </>
    ),
    SECTION_ERROR: (
      <>
        <BusinessError error={t('Falha ao carregar seções do conteudo')} />
        {analyticsOnErrorContentNotFound(contentUnit)}
      </>
    ),
    READY: (
      <ErrorBoundary>
        <div id='contentUnitWrapper' className={`${classes.contentUnitWrapper} ${isOpenSideMenu ? classes.contentUnitWrapperOpenSideMenu : classes.contentUnitWrapperCloseSideMenu}`}>
          <ContentUnitHeader content={contentUnit} className={classes.navArea} />
          <div className={classes.sideMenuArea}>
            <SideMenu contentUnit={contentUnit} />
          </div>
          <div id='contentArea' className={classes.contentArea}>
            <div onClick={() => { setIsOpenSideMenu(true) }} className={`${classes.sideMenuHandleButton} ${isOpenSideMenu && classes.hideSideMenuHandler}`}>
              <MenuIcon />
            </div>
            {isMobile && (
              <div className={classes.backLinkContainer}>
                <BackLink />
              </div>
            )}
            <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
              {renderStudent(contentUnit)}
            </OnlyProfile>
            <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
              <ContentUnitTeacher contentUnit={contentUnit} didacticContent={didacticContent} />
            </OnlyProfile>
          </div>
          <BottomMenu />
        </div>
      </ErrorBoundary>
    )
  }

  return (
    <>
      {states[pageStateMachine]}
    </>
  )
}

export default withRouter(ContentUnit)
