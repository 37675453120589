/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Grid } from '@mui/material'
import { useAtom } from 'jotai'

import { CardSelector } from 'components/common/CardSelector'
import { ContentTypeEnum } from 'services/types/content'
import { updateContentSelectAtom, scheduleAtom } from '../../../../atomStore'

import { CustomCardSelectorProps, CustomTitleProps, RenderListProps } from '../types'

import useStyles from '../style'

export const CustomCardSelector = ({
  type,
  title,
  cardId,
  questions,
  isDisabled = false,
  suggestedApplicationType,
  isTemplateActivity,
  clonedTimes,
  momentType,
  showTypeTag
}: CustomCardSelectorProps) => {
  const [contentSelect, updateContentSelect] = useAtom(updateContentSelectAtom)
  const [eventObject] = useAtom(scheduleAtom)

  const MULTIPLE_SELECTION_ENABLE = false

  const checkSelectedCard = (id: string): boolean => {
    return (contentSelect ?? []).some(content => content.id === id)
  }

  const checkButtonSchedule = (id: string, type: ContentTypeEnum, title: string) => {
    const isExists = (contentSelect ?? []).some(content => content.title === title)

    if (isExists) {
      updateContentSelect((contentSelect ?? []).filter(content => content.id !== id))
      return
    }

    // is edit? only one selection
    if (!MULTIPLE_SELECTION_ENABLE || (eventObject?.activity || eventObject?.didatic_section)) {
      updateContentSelect([{
        id,
        type,
        title,
        questions,
        suggestedApplicationType
      }])
      return
    }

    updateContentSelect([...(contentSelect ?? []), {
      id,
      type,
      title,
      questions,
      suggestedApplicationType
    }])
  }

  return (
    <CardSelector
      dataTestId={`select_content_activity_or_evaluation_select_${type}`}
      type={type}
      title={title}
      showTypeTag={showTypeTag}
      isSelected={checkSelectedCard(cardId)}
      isDisabled={isDisabled}
      onClick={() => checkButtonSchedule(cardId, type, title)}
      isTemplate={isTemplateActivity}
      quantityQuestions={questions?.length}
      clonedTimes={clonedTimes}
      momentType={momentType}
    />
  )
}

export const CustomTitle = ({ title, description }: CustomTitleProps) => {
  const classes = useStyles()

  return (
    <Grid container style={{ gap: '16px' }}>
      <div className={classes.mobileExpeditionTitle}>
        {title}
      </div>
      <div className={classes.selectTitle}>
        {description}
      </div>
    </Grid>
  )
}

export const RenderedList = ({ filtered, title, description, showTypeTag }: RenderListProps) => {
  const classes = useStyles()

  const validateAvailableate = (schedule?: { startDate: string, endDate: string }) => {
    if (!schedule) {
      return true
    }

    return false
  }

  return (
    <div className={classes.renderedList}>
      <CustomTitle title={title} description={description} />
      {(filtered ?? []).map((activity, i: number) => validateAvailableate(activity.schedule) &&
        <CustomCardSelector
          key={i}
          suggestedApplicationType={activity.suggested_application_type}
          type={ContentTypeEnum.activity}
          title={activity.title ?? activity.activity_name ?? ''}
          cardId={activity.id?.toString()}
          questions={activity?.questions}
          isTemplateActivity={activity?.custom_type !== 'clone'}
          clonedTimes={activity?.clonedTimes}
          momentType={activity?.moment_type}
          showTypeTag={showTypeTag}
        />
      )}
    </div>
  )
}
