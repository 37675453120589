import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
// assets
import imgBackground from 'assets/login-background-kids.webp'
import imgBackgroundMobile from 'assets/login-background-mobile.svg'
import imgBackgroundIntersect from 'assets/intersect.svg'

export default makeStyles((theme: Theme) => ({
  container: {
    // height: '100%',
    width: '100%',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      background: theme.colors.support.colorSupport01
    }
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: -1,
    backgroundImage: `url(${imgBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // width: '100vw',

    // mobile
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'top',
      backgroundImage: `url(${imgBackgroundMobile})`
    }
  },
  backgroundImageIntersect: {
    position: 'absolute',
    left: '40%',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: -1,
    backgroundImage: `url(${imgBackgroundIntersect})`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  helpButtonContainer: {
    position: 'absolute',
    top: theme.spacingInset.sm,
    right: theme.spacingInset.sm
  },
  helpButton: {
    background: `${theme.colors.support.colorSupport01} !important`,
    border: `1px solid ${theme.colors.support.colorSupport01} !important`,
    padding: `${theme.spacingSquish.nano} !important`,
    fontSize: `${theme.font.fontSize.xs} !important`,
    '& svg': {
      marginRight: '14px !important'
    },

    '&:hover': {
      background: '#602680 !important',
      border: '1px solid #602680 !important'
    },

    // mobile
    [theme.breakpoints.down('sm')]: {
      padding: '12px !important',
      minWidth: '0 !important',
      '& span': {
        display: 'none'
      },
      '& svg': {
        marginRight: '0 !important'
      }
    }
  },
  root: {
    width: '100%',
    minHeight: '100vh',
    paddingTop: theme.spacingInset.sm
  }
}))
