import { ROOT } from 'navigation/CONSTANTS'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import Storage from 'utils/storage'

export const OnlyNotAuthRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => Storage.token.get() ? <Redirect to={ROOT} /> : children}
    />
  )
}

export default OnlyNotAuthRoute
