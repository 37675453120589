import { ReactComponent as LogotypeWhite } from './assets/logotype_white.svg'
import { ReactComponent as Logotype } from './assets/logotype.svg'
import { ReactComponent as Symbol } from './assets/symbol.svg'
import { ReactComponent as SymbolWhite } from './assets/symbol_white.svg'
import useStyles from './style'

export interface IBrandProps {
  type: 'symbol' | 'logo'
  variant?: 'default' | 'white'
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

export const Brand: React.FC<IBrandProps> = ({ type, variant = 'default', size = 'md' }) => {
  const classes = useStyles()
  const className = `${classes.root} size-${size}`

  const brandTypes = {
    symbolwhite: <SymbolWhite className={className} />,
    symboldefault: <Symbol className={className} />,
    logowhite: <LogotypeWhite className={className} />,
    logodefault: <Logotype className={className} />
  }

  return brandTypes[`${type}${variant}`]
}
