import { useCallback, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { ptBR } from 'date-fns/locale'
import dayjs from 'dayjs'

import { Grid, Stack } from '@mui/material'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider } from '@mui/lab'
import AccordionMultiStudents from '../AccordionMultiStudents'
import { InputDate, InputFilePreview } from 'components/common'
import { Text, Button, TextField } from 'components/design-system'
import { toast } from 'components/design-system/Toast/manager'
import { Disciplines } from 'components/common/Disciplines'
import { ConfirmationModal } from 'components/common/CustomizedTimeline/ConfirmationModal'

import { findstudentByClass } from 'services/timeline'
import { getDisciplines } from 'services/discipline'
import { IClassSubscriptionResponse, IDisciplineResponse, IDisciplineStore, ISubscriptionsSessionResponse } from 'services/types'
import { deleteFile } from 'services/upload'
import { saveMilestone, updateMilestone, getMilestoneById } from 'services/milestones'

import { IPreviewState } from 'components/common/InputFilePreview/atomStore'
import { inUploadingProcess, tabIndexAtom, currentStudentsTimelineAtom, currentDisciplineTimelineAtom } from '../../../atomStore'
import { openModalConfirmationTimelineAtom } from 'components/common/CustomizedTimeline/atomStore'

import { useStore } from 'store'

import useStyle from './style'

interface IRecordFiles {
  file_records: {
    upload_id: number | null
    url: string
  }
}

export const Milestone: React.FC = () => {
  const [disciplinesData, setDisciplinesData] = useState<IDisciplineResponse[]>([])
  const [initialDate, setInitialDate] = useState<Date | null>(null)
  const [finalDate, setFinalDate] = useState<Date | null>(null)
  const [currentDisciplineId, setCurrentDisciplineId] = useState<IDisciplineStore | null>(null)
  const [studentsData, setStudentsData] = useState<IClassSubscriptionResponse[]>([])
  const [studentCollection, setStudentCollection] = useState<number[]>([])
  const [titleField, setTitleField] = useState<string>('')
  const [descriptionField, setDescriptionField] = useState<string>('')
  const [recordFiles, setRecordFiles] = useState<IRecordFiles[]>([])

  const [, setInUploadingProcess] = useAtom(inUploadingProcess)
  const [, setTabIndex] = useAtom(tabIndexAtom)
  const [openConfirmation, setOpenConfirmation] = useAtom(openModalConfirmationTimelineAtom)
  const [currentStudent] = useAtom(currentStudentsTimelineAtom)
  const [currDisciplineTimeline] = useAtom(currentDisciplineTimelineAtom)

  const { classId, milestoneId } = useParams<{ classId: string, milestoneId?: string }>()

  const { session } = useStore()

  const history = useHistory()

  const { t } = useTranslation()

  const classes = useStyle()

  const descriptionFieldMaxLength = 1000

  const classSelected = session?.subscriptions
    .filter((cur: ISubscriptionsSessionResponse) => cur.class)
    .find(sub => sub?.class?.id?.toString() === classId)?.class
  const timelineTab = !classSelected?.grades[0]?.code?.includes('EI_') ? 3 : 2

  const today = dayjs().toDate()
  const YearFirstDay = dayjs().set('month', 0).set('date', 1).toDate()

  const handleUploadFiles = (uploads: IPreviewState[]) => {
    const uploadData = uploads.map(file => ({
      file_records: {
        upload_id: file.id,
        transaction_id: file.transaction_id,
        url: file.url
      }
    }))

    setRecordFiles(uploadData)
    setInUploadingProcess(false)
  }

  const handleChangeDiscipline = useCallback((disciplineId: IDisciplineResponse) => {
    if (disciplineId !== currentDisciplineId) {
      setCurrentDisciplineId(disciplineId)
    }
  }, [currentDisciplineId])

  const handleChangeStudent = (student: number[]) => {
    setStudentCollection(student)
  }

  const validationSubmitButton = () => {
    if (initialDate &&
      finalDate &&
      studentCollection.length &&
      titleField &&
      descriptionField &&
      currentDisciplineId &&
      recordFiles.length
    ) {
      return false
    } else {
      return true
    }
  }

  const handleGoBack = async () => {
    if (openConfirmation) {
      setOpenConfirmation(false)
    }

    history.goBack()

    if (!milestoneId) {
      if (recordFiles.length) {
        await Promise.all(recordFiles.map(async ({ file_records: fileRecords }) => {
          try {
            if (fileRecords.upload_id) await deleteFile(fileRecords.upload_id)
          } catch (err) {
            console.log(err)
          }
        }))
      }
    }
  }

  const handleInitialDateChange = (newInitialDate: Date) => {
    const isInitialAfterFinalDate = dayjs(newInitialDate).valueOf() > dayjs(finalDate).valueOf()

    if (finalDate && isInitialAfterFinalDate) {
      throw new Error(t('A data inicial não deve ser maior que a final'))
    }

    setInitialDate(newInitialDate)
  }

  const handleFinalDateChange = (newFinalDate: Date) => {
    const isInitialAfterFinalDate = dayjs(initialDate).valueOf() > dayjs(newFinalDate).valueOf()

    if (initialDate && isInitialAfterFinalDate) {
      throw new Error(t('A data final não deve ser menor que a inicial'))
    }

    setFinalDate(newFinalDate)
  }

  const handleFinish = async () => {
    try {
      const milestoneData = {
        classId,
        discipline: Number(currentDisciplineId?.id),
        title: titleField,
        description: descriptionField,
        cover: recordFiles[0].file_records.url,
        date_start: initialDate,
        date_end: finalDate,
        class_milestone_students: studentCollection
      }

      const response = milestoneId
        ? await updateMilestone(milestoneId, milestoneData)
        : await saveMilestone(milestoneData)

      if (response.success) {
        toast.handler({
          content: milestoneId ? t('Agrupamento editado com sucesso') : t('Agrupamento inserido com sucesso'),
          duration: 6000,
          severity: 'success'
        })
        setTimeout(() => {
          setTabIndex(timelineTab)
          history.goBack()
        }, 1000)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const loadingData = async () => {
      const disciplines = await getDisciplines(parseInt(classId))
      const taughtDisciplines = disciplines.data.filter(discipline => discipline.taught_discipline)

      if (taughtDisciplines.length) {
        const responseStudentsByClass = await findstudentByClass(classId)
        const { data: students } = responseStudentsByClass
        setDisciplinesData(taughtDisciplines)
        setCurrentDisciplineId(currDisciplineTimeline)
        setStudentsData(students)
        handleChangeStudent([currentStudent[0].user_school_profile.id])
      }

      if (milestoneId) {
        const { data } = await getMilestoneById(milestoneId)

        handleInitialDateChange(dayjs(data.date_start).toDate())
        handleFinalDateChange(dayjs(data.date_end).toDate())
        setTitleField(data.title)
        setDescriptionField(data.description)
        setCurrentDisciplineId(data.discipline)

        const studentsTimeline = data.class_milestones_students.map(student => student.user_school_profile.id)
        handleChangeStudent(studentsTimeline)

        const file: IRecordFiles = {
          file_records: {
            upload_id: data.id,
            url: data.cover
          }
        }
        setRecordFiles([file])
      }
    }
    loadingData()
  }, [])

  return (
    <Grid container className={classes.container}>
      <ConfirmationModal
        title={t('Cancelar agrupar registro')}
        description={t('Ao cancelar a criação/edição de agrupar registro, você será direcionado para a página registros de turma e não será possível retomar a edição.')}
        confirmLabel={milestoneId ? t('Cancelar edição') : t('Cancelar criação')}
        cancelLabel={milestoneId ? t('Continuar editando') : t('Continuar criação')}
        onCancel={() => setOpenConfirmation(false)}
        onConfirm={handleGoBack}
        dataTestidCancel={milestoneId ? 'edit_agroup_register_continue_creation' : 'cancel_agroup_register_continue_creation'}
        dataTestidConfirm={milestoneId ? 'edit_agroup_register_cancel_criation' : 'cancel_agroup_register_cancel_criation'}
      />
      <Grid item xs={12} md={3} display='flex' sx={{ pb: '32px' }} alignItems='center'>
        <Grid item className={classes.backLink} data-testid={milestoneId ? 'edit_agroup_register_back_class_registrys' : 'agroup_register_back_class_registrys'} onClick={() => setOpenConfirmation(true)}>
          <ArrowBackIosNewOutlined />
          <Text className={classes.backButton}>
            {t('Voltar')}
          </Text>
        </Grid>
      </Grid>
      <Grid item xs={12} display='grid'>
        <Text
          type='subtitle'
          size='medium'
          className={classes.titleAccordion}
        >{t('Agrupar registros na linha do tempo')}
        </Text>
        <Text
          className={classes.subtitleAccordion}>{`*${t('Campo obrigatório')}`}
        </Text>
      </Grid>
      <AccordionMultiStudents
        onChangeStudent={handleChangeStudent}
        studentsData={studentsData}
        selectedStudents={studentCollection}
        dataTestidAllStudents={milestoneId ? 'edit_agroup_register_all_students_to_class_registrys' : 'agroup_register_all_students_to_class_registrys'}
        dataTestidHide={milestoneId ? 'edit_agroup_register_hide_student_list' : 'agroup_register_hide_student_list'}
        dataTestidSelectAllStudents={milestoneId ? 'edit_agroup_register_select_all_students' : 'agroup_register_select_all_students'}
      />
      <Grid item xs={12}>
        <Text
          type='body'
          size='small'
          className={classes.subtitleAccordion}
        >
          {t('Selecione o componente curricular onde será inserido o registro')}
        </Text>
      </Grid>
      <Grid item xs={12} className={classes.disciplineContainer}>
        <Disciplines
          disciplines={disciplinesData}
          currentDiscipline={currentDisciplineId}
          changeDiscipline={handleChangeDiscipline}
          dataTestid={milestoneId ? 'edit_agroup_register_select_curricular_component' : 'agroup_register_select_curricular_component'}
        />
      </Grid>
      <Grid item xs={12} marginBottom={4}>
        <p className={classes.titleAccordion}>{`${t('Período')}*`}</p>
        <Text
          type='body'
          size='small'
          className={classes.subtitleAccordion}
        >
          {`${t('Selecione o período do agrupamento que você deseja criar')}:`}
        </Text>
      </Grid>
      <Grid item display='contents'>
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <Stack sx={{ mb: 3 }} className={classes.stackContainer}>
            <InputDate
              label={t('Data inicial')}
              initialDate={initialDate}
              minDate={YearFirstDay}
              maxDate={today}
              onChangeDate={handleInitialDateChange}
              dataTestid={milestoneId ? 'edit_agroup_register_insert_start_date' : 'agroup_register_insert_start_date'}
            />
          </Stack>
        </LocalizationProvider>
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <Stack className={classes.stackContainer}>
            <InputDate
              label={t('Data final')}
              initialDate={finalDate}
              minDate={YearFirstDay}
              maxDate={today}
              onChangeDate={(value) => handleFinalDateChange(value)}
              dataTestid={milestoneId ? 'edit_agroup_register_insert_end_date' : 'agroup_register_insert_end_date'}
            />
          </Stack>
        </LocalizationProvider>
        <Grid item xs={12}>
          <p className={classes.titleAccordion}>{t(`Prévia da capa${'*'}`)}</p>
        </Grid>
        <Grid item xs={12} mb={2}>
          <InputFilePreview
            initialPreviews={recordFiles.map(({ file_records: fileRecords }) => ({
              fileRecordId: Number(fileRecords.upload_id),
              id: fileRecords.upload_id,
              url: fileRecords.url,
              name: fileRecords.url,
              thumb: fileRecords.url,
              ext: fileRecords.url
            }))
            }
            reset={!recordFiles.length}
            multiple={false}
            description={t('Formatos de imagem suportados: JPG, PNG, GIF, WEBP')}
            availableArchivesTypes='.jpeg,.jpg,.png,.gif,.webp'
            onChange={urls => handleUploadFiles(urls)}
            onProgress={progress => {
              if (progress > 1) setInUploadingProcess(true)
            }}
            dataTestid={milestoneId ? 'edit_agroup_register_add_files_to_registry' : 'agroup_register_add_files_to_registry'}
            maxFileSize={70}
          />
        </Grid>
        <Grid item xs={12} className={classes.textField}>
          <TextField
            maxLength={50}
            required
            fullWidth
            id='outlined-helperText'
            label={`${t('Título')}*`}
            variant='filled'
            value={titleField}
            onChange={e => {
              setTitleField(e.target.value)
            }}
          />
          <p className={`${String(classes.helpTextTitle)} ${(titleField.length === 50) && 'limit'}`}>{t('HelpTextTitle', { titleNumber: `${titleField.length}` })}</p>
        </Grid>
        <Grid item xs={12} className={classes.textField}>
          <TextField
            maxLength={descriptionFieldMaxLength}
            required
            fullWidth
            id='filled-multiline-static'
            label={`${t('Descrição')}*`}
            variant='filled'
            value={descriptionField}
            onChange={e => {
              setDescriptionField(e.target.value)
            }}
            multiline
          />
          <p className={`${String(classes.helpTextDescription)} ${descriptionField.length === descriptionFieldMaxLength && 'limit'}`}>{t('HelpTextDescription', { descriptionNumber: `${descriptionField.length}` })}</p>
        </Grid>
      </Grid>
      <Grid className={classes.classButton}>
        <Button
          sx={{ marginTop: '10px' }}
          variant='ghost'
          onClick={() => setOpenConfirmation(true)}
          data-testid={milestoneId ? 'edit_agroup_register_cancel_creation' : 'agroup_register_cancel_creation'}
        >
          {t('Cancelar')}
        </Button>
        <Button
          disabled={validationSubmitButton()}
          variant='primary'
          onClick={handleFinish}
          data-testid={milestoneId ? 'edit_agroup_register_finish_creation' : 'agroup_register_finish_creation'}
        >
          {milestoneId ? t('Editar') : t('Finalizar')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Milestone
