import React from 'react'
import { Grid } from '@mui/material'
import { useAtom } from 'jotai'

import { studentsAnswerAtom } from '../../atomStore'
import CardStudentAnswer from '../CardStudentAnswer'

export const ListStudentsAnswer: React.FC = () => {
  // atom
  const [studentsAnswer] = useAtom(studentsAnswerAtom)

  return (
    <Grid container xs={12} display='flex' flexDirection='row' justifyContent='space-between'>
      {(studentsAnswer ?? []).map(studentAnswer => (
        <CardStudentAnswer studentAnswer={studentAnswer} />
      ))}
    </Grid>
  )
}

export default ListStudentsAnswer
