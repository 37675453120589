import ReactCodeInput, { ReactCodeInputProps } from '@acusti/react-code-input'
import makeStyles from './style'

export interface CodeInputProps extends ReactCodeInputProps {

}

export const CodeInput: React.FC<CodeInputProps> = ({ ...props }) => {
  const classes = makeStyles(props)

  return <ReactCodeInput className={classes.root} {...props} />
}
