import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    backToContent: {
      '& a': {
        textDecoration: 'none',
        color: theme.colorBrand.medium
      },
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs,
      color: theme.colorBrand.medium,
      lineHeight: theme.font.lineHeight.xxs,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacingInline.nano,
        marginTop: theme.spacingInline.nano
      }
    }
  })
)
