
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useStyles from '../../style'
import { ROOT } from 'navigation/CONSTANTS'
import { Button, Modal } from 'components/design-system'
import { Grid } from '@mui/material'

interface IDocumentRegisteredModalProps {
  open: boolean
  onClose: () => void
}

export const DocumentRegisteredModal: React.FC<IDocumentRegisteredModalProps> = ({ open, onClose }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const styles = useStyles()

  const handleClick = () => window.open('https://api.whatsapp.com/send/?phone=5511986588112&text&type=phone_number&app_absent=0', '_blank')

  return (
    <Modal
      size='sm'
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Grid container rowSpacing={3}>
        <Grid item className={styles.modalTitle} id='modal-title'>
          {t('Este CPF já foi cadastrado.')}
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          {t('Enviamos um link de recuperação para o contato associado a conta.')}
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          {t('Caso não tenha recebido, Fale com a Cloe por WhatsApp.')}
        </Grid>
        <Grid container item xs={12} columnSpacing={3}>
          <Grid item xs={6}>
            <Button
              className={styles.modalCancelButton}
              variant='outlined'
              onClick={handleClick}
              type='button'
              data-testid='autonomous_registration_warning_cpf_whatsapp'
            >
              {t('Whatsapp')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={styles.modalSubmitButton}
              variant='primary'
              onClick={() => history.replace(ROOT)}
              type='button'
              data-testid='autonomous_registration_warning_cpf_close'
            >
              {t('Fechar')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default DocumentRegisteredModal
