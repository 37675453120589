import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Alert, Button } from 'components/design-system'

import { ReportsTable, SearchTermNotFound } from '..'
import { AuxStateMachineEnum, IStudentsListData } from '../..'

import { useAtomValue } from 'jotai/utils'
import { searchTermReportsAtom } from '../../../../atomStore'

import useStyle from './styles'

interface IReportsListReadyProps {
  studentsListData: IStudentsListData
  loadMore: () => Promise<void>
  auxState: AuxStateMachineEnum
}

export const ReportsListReady: React.FC<IReportsListReadyProps> = ({ studentsListData, auxState, loadMore }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const searchTerm = useAtomValue(searchTermReportsAtom)

  const LoadMoreButton = () => (
    <Button
      variant='outlined'
      onClick={loadMore}
      data-testid='followup_load_more'
      sx={{ margin: '0 auto' }}
    >
      {t('Carregar mais...')}
    </Button>
  )

  const NotFoundAlert = () => (
    <Alert
      content={t('No momento não há alunos para exibir.')}
      severity='info'
      style={{ marginTop: '20px', width: '100%' }}
    />
  )

  const renderAuxStates = {
    IDLE: <></>,
    NOT_FOUND: <NotFoundAlert />,
    SEARCH_NOT_FOUND: <SearchTermNotFound term={searchTerm} />,
    READY_TO_LOAD_MORE: <LoadMoreButton />
  }

  return (
    <Grid container className={classes.reportsListContainer}>
      {studentsListData.error
        ? (
          <ReportsTable.Error />
        )
        : (
          <ReportsTable.Item studentsListData={studentsListData.data} />
        )}

      {renderAuxStates[auxState]}
    </Grid>
  )
}
