import { ConfirmationModal } from 'components/common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface ICacheConfirmationModal {
  dataTestIdCancelButton: string
  dataTestIdConfirmButton: string
  isBlockSituation: boolean
}

export const CacheConfirmationModal = ({ dataTestIdCancelButton, dataTestIdConfirmButton, isBlockSituation }: ICacheConfirmationModal) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [currentPath, setCurrentPath] = useState<string>('')

  const history = useHistory()

  useEffect(() => {
    if (isBlockSituation) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        setShowModal(true)
        return 'true'
      })
    } else {
      history.block(() => {
        // This is intentional
      })
    }

    return () => {
      history.block(() => {
        // This is intentional
      })
    }
  }, [history, isBlockSituation])

  const closeModal = () => {
    setShowModal(false)
  }

  const handleLeave = () => {
    history.block(() => {
      // This is intentional
    })
    history.push(currentPath)

    closeModal()
  }

  return (
    <ConfirmationModal
      onConfirmModal={handleLeave}
      onCloseModal={closeModal}
      isOpen={showModal}
      title={t('Tem certeza que deseja sair?')}
      description={t('Suas respostas ficarão salvas e você poderá enviá-las depois.')}
      confirmDatatestId={dataTestIdConfirmButton}
      cancelDatatestId={dataTestIdCancelButton}
    />
  )
}
