import React, { useEffect, useState } from 'react'
import useStyles from './style'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Skeleton, useTheme } from '@mui/material'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { Text, Text2, Button } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { IMessage } from 'services/types/messages'
import { LoadMoreErrorState, ParentalMessageReady } from '../'
import { getMessages } from 'services/messages'
import { useCurricularStore, useStore } from 'store'
import { Message } from 'components/common'
import Analytics from 'utils/analytics'
import { BusinessError } from 'navigation/BusinessError'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  READY = 'READY',
  LOAD_MORE_ERROR = 'LOAD_MORE_ERROR'
}

export const StudentParentalMessages: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()

  const { profile, schoolPeriod, subscription } = useStore()
  const { currentDiscipline, classId, currentClass } = useCurricularStore()

  const { disciplineId } = useParams<{ disciplineId: string }>()

  const { t } = useTranslation()

  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [messages, setMessages] = useState<IMessage[]>([])
  const [page, setPage] = useState(1)

  const pageSize = 10

  const commonAnalyticsAttributes = {
    profile_type: profile?.type,
    profile_id: profile?.id,
    school_id: profile?.school?.id,
    school_name: profile?.school?.name,
    class_id: classId,
    class_name: currentClass?.name,
    discipline_id: currentDiscipline?.id,
    discipline_title: currentDiscipline?.name,
    period_title: schoolPeriod?.title,
    period_id: schoolPeriod?.id
  }

  useEffect(() => {
    void reset()
  }, [disciplineId])

  const fetchMessages = async (pageCount: number) => {
    setStateMachine(StateMachineEnum.LOADING)
    if (!profile?.id || !classId || !disciplineId) {
      return
    }

    const response = await getMessages(
      'parental_notice',
      profile.id,
      classId ?? 0,
      Number(disciplineId),
      'DESC',
      pageSize,
      pageCount
    )

    if (!response.success) {
      setStateMachine(pageCount === 0 ? StateMachineEnum.ERROR : StateMachineEnum.LOAD_MORE_ERROR)
      return
    }

    if (response.data.totalPages <= pageCount) {
      setStateMachine(StateMachineEnum.IDLE)
    } else {
      setStateMachine(StateMachineEnum.READY)
    }

    setMessages(oldMessages => [...oldMessages, ...response.data.messages])
  }

  const reset = async () => {
    setMessages([])
    setPage(1)
    await fetchMessages(1)
  }

  const tryAgain = async () => {
    await fetchMessages(page)
  }

  const loadMore = async () => {
    handleRecordEventClick('chat_gpt_historic_message_student_load_more_messages')
    setPage(oldPage => oldPage + 1)

    await fetchMessages(page + 1)
  }

  const handleRecordEventClick = (eventName: string, exclusiveAttributes?: {}) => {
    Analytics.recordEventClick({
      name: eventName,
      attributes: {
        ...subscription?.analytics,
        ...commonAnalyticsAttributes,
        ...exclusiveAttributes
      }
    })
  }

  const goBack = () => {
    handleRecordEventClick('chat_gpt_historic_message_student_back')
    history.goBack()
  }

  const states = {
    IDLE: <></>,
    LOADING: <Skeleton variant='text' sx={{ width: '100%' }} height={200} animation='wave' />,
    READY: <ParentalMessageReady loadMore={loadMore} />,
    ERROR: <Grid item sx={{ flex: 1 }}>
      <BusinessError error={'Não foi possivel carregar as mensagens'}>
        <Button
          className={classes.button}
          variant='primary'
          type='submit'
          onClick={async () => await reset()}
        >
          {t('Tentar novamente')}
        </Button>
      </BusinessError>
    </Grid>,
    LOAD_MORE_ERROR: <LoadMoreErrorState tryAgain={tryAgain} />
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <Grid container className={classes.container} sx={{ display: 'flex' }}>
      <Grid flexDirection={'column'} item xs={12} display='flex'>
        <button className={classes.backLink} onClick={goBack}>
          <ArrowBackIosNewOutlined />
          <Text>{t('Voltar')}</Text>
        </button>

        <Grid item>
          <Text2
            fontSize='lg'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colorBrand.medium}
            sx={{ marginBottom: theme.spacingStack.xxs }}
          >
            {t('Histórico de mensagens')}
          </Text2>
        </Grid>
        <Grid item width='100%'>
          <Text2
            fontSize='sm'
            fontWeight='medium'
            lineHeight='xs'
            mobile='sm'
            customColor={theme.colors.neutral.dark10}
          >
            {t('Mensagens enviadas pelos(as) professores(as).')}
          </Text2>
        </Grid>
      </Grid>

      <Grid className={classes.messagesContainer} marginBottom={5}>
        {
          messages?.map(message =>
            <Message
              message={message}
              hideButtonDataTestId='chat_gpt_historic_message_student_hide_full_text'
              showMoreButtonDataTestId='chat_gpt_historic_message_student_display_full_text'
            />
          )
        }
      </Grid>

      <Grid container justifyContent='center'>
        {renderStateMachine()}
      </Grid>
    </Grid>
  )
}
