import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((_theme: CustomTheme) => createStyles({
  root: {
    width: '100%',
    verticalAlign: 'middle',
    height: 'auto',
    '&.size-xs': { height: '16px', width: 'auto%' },
    '&.size-sm': { height: '24px', width: 'auto%' },
    '&.size-md': { height: '40px', width: 'auto%' },
    '&.size-lg': { height: '56px', width: 'auto%' }
  }
}))
