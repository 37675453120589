import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import isToday from 'dayjs/plugin/isToday'
import { useAtomValue } from 'jotai/utils'
import dayjs from 'dayjs'
import IconButton from '@mui/material/IconButton'
import { useTheme, Skeleton } from '@mui/material'
import { Button, Icon } from 'components/design-system'
import { StatusClassHandle, CurrentClass } from 'components/common'
import WeekDay, { IWeekDayProps } from './components/WeekDay'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { changingScheduleAtom, reloadScheduleAtom } from 'navigation/components/MyCloe/components/ScheduleCreator/atomStore'
import { useStore } from 'store'
import { getScheduleTotalsByRange } from 'services/schedule'
import {
  addDay,
  subtractDay,
  toStringDay,
  toStringMonth,
  monthsTitle,
  weekDaysInitials,
  weekDaysTitles,
  yyyyMMdd,
  removeTimezoneFromDate
} from 'utils/date'
import useStyle from './style'
import { useAtom } from 'jotai'
import { NavigateBeforeOutlined, NavigateNextOutlined } from '@mui/icons-material'

interface ICalendarProps {
  classId: string
  disciplineId?: string | number | null
  onSelectDate: (date: Date) => void
}

export const Calendar: React.FC<ICalendarProps> = ({ classId, disciplineId, onSelectDate }) => {
  dayjs.extend(customParseFormat)
  dayjs.extend(isToday)

  const theme = useTheme()
  const classes = useStyle()
  const { t } = useTranslation()
  const { schoolPeriod } = useStore()
  let initialDate = new Date()

  if (schoolPeriod?.current === false && schoolPeriod.end_date) {
    const schoolPeriodEndDate = removeTimezoneFromDate(schoolPeriod.end_date)
    initialDate = new Date(schoolPeriodEndDate)
  }

  // atoms
  const [reloadSchedule, setReloadSchedule] = useAtom(reloadScheduleAtom)
  const changingSchedule = useAtomValue(changingScheduleAtom)

  // states
  const [weekDays, setWeekDays] = useState<IWeekDayProps[]>([])
  const [selectedDay, setSelectedDay] = useState<Date>(initialDate)
  const [addWeeks, setAddWeeks] = useState<number>(0)
  const [disablePaginationBefore, setDisablePaginationBefore] = useState(false)
  const [disablePaginationNext, setDisablePaginationNext] = useState(false)
  const [isLoadingDays, setIsLoadingDays] = useState<boolean>(true)
  const [isLoadingCalendarControls, setIsLoadingCalendarControls] = useState<boolean>(true)
  const [isAddingWeeks, setIsAddingWeeks] = useState(false)

  const schoolPeriodStartDate = dayjs(schoolPeriod?.start_date).toDate()
  const schoolPeriodEndDate = dayjs(schoolPeriod?.end_date).toDate()

  const disablePaginationBeforeMobile = yyyyMMdd(selectedDay) <= yyyyMMdd(schoolPeriodStartDate)
  const disablePaginationNextMobile = yyyyMMdd(selectedDay) >= yyyyMMdd(schoolPeriodEndDate)

  const getWeekDays = (weekNumber: number) => {
    const date = dayjs(initialDate).add(weekNumber, 'week').toDate()
    const week: IWeekDayProps[] = []

    for (let i = 0; i <= 6; i++) {
      const day = date.getDate() - date.getDay() + i
      date.setDate(day)
      week.push({
        weekDay: weekDaysInitials[date.getDay()],
        day: toStringDay(date),
        month: toStringMonth(date),
        date: new Date(date),
        totalEvents: 0,
        selected: false,
        isToday: dayjs(date).isToday()
      })
    }

    return week
  }

  const getScheduleTotalEvents = async () => {
    const week = getWeekDays(addWeeks)
    const startDate = yyyyMMdd(week[0].date)
    const endDate = yyyyMMdd(week[week.length - 1].date)
    const response = await getScheduleTotalsByRange(classId, startDate, endDate, disciplineId)

    if (response.success) {
      const newWeek: IWeekDayProps[] = week.map(w => {
        const currentDateIsLessStartSchoolPeriod = yyyyMMdd(w.date) < yyyyMMdd(schoolPeriodStartDate)
        const currentDateIsGreaterEndSchoolPeriod = yyyyMMdd(w.date) > yyyyMMdd(schoolPeriodEndDate)

        if (currentDateIsLessStartSchoolPeriod) {
          w.disabled = true
          w.beforeSchoolPeriod = true
        } else if (currentDateIsGreaterEndSchoolPeriod) {
          w.disabled = true
          w.afterSchoolPeriod = true
        }

        return {
          ...w,
          totalEvents: response.data.filter(d => yyyyMMdd(dayjs(d.start_date).toDate()) === yyyyMMdd(w.date))?.length ?? 0
        }
      })

      setDisablePaginationBefore(startDate <= yyyyMMdd(schoolPeriodStartDate))
      setDisablePaginationNext(endDate >= yyyyMMdd(schoolPeriodEndDate))

      setWeekDays(newWeek)
    } else {
      console.warn('error')
    }

    setIsLoadingDays(false)
    setIsLoadingCalendarControls(false)
    setIsAddingWeeks(false)
    setReloadSchedule(false)
  }

  useEffect(() => {
    onSelectDate(selectedDay)
  }, [selectedDay])

  useEffect(() => {
    if (!reloadSchedule) {
      if (!isAddingWeeks) setIsLoadingCalendarControls(true)
      setIsLoadingDays(true)
      if (disciplineId !== undefined) getScheduleTotalEvents()
    }
  }, [addWeeks, changingSchedule, classId, disciplineId])

  useEffect(() => {
    if (reloadSchedule) {
      setIsLoadingDays(true)
      setIsLoadingCalendarControls(true)
      getScheduleTotalEvents()
    }
  }, [reloadSchedule])

  const handleToday = () => {
    setIsAddingWeeks(true)
    setAddWeeks(0)
    setSelectedDay(new Date())
  }

  const handleAddWeeks = (increment: number) => {
    setIsAddingWeeks(true)
    setAddWeeks(addWeeks + increment)
  }

  const handlePreviousWeek = () => handleAddWeeks(-1)
  const handleNextWeek = () => handleAddWeeks(1)

  const handleMobilePreviousWeek = () => setSelectedDay(subtractDay(selectedDay, 1))
  const handleMobileNextWeek = () => setSelectedDay(addDay(selectedDay, 1))

  const handleSelectDay = (date: Date) => setSelectedDay(dayjs(date).toDate())

  const isSelectedDayToday = dayjs(selectedDay).isToday()

  const monthYearTitle = useMemo(() => {
    return weekDays
      .map(week => ({ month: week.month, year: week.date.getFullYear() }))
      .filter((obj, i, s) => {
        return i === s.findIndex((t) => (
          t.month === obj.month && t.year === obj.year
        ))
      })
      .map(({ month, year }, i, s) => {
        const monthTitle = t(monthsTitle[parseInt(month) - 1])
        if (i === 0) {
          return `${monthTitle}${(year === s[i + 1]?.year) ? '' : '/' + year}`
        }

        return ` - ${monthTitle}/${year}`
      })
  }, [weekDays])

  return (
    <>
      <div className={classes.calendarControls}>
        <span className={classes.calendarMonth}>
          {isLoadingCalendarControls
            ? <Skeleton variant='text' width={60} height={50} animation='wave' />
            : monthYearTitle
          }
        </span>
        <div className={classes.calendarActions}>
          {isLoadingCalendarControls
            ? <Skeleton variant='text' width={60} height={50} animation='wave' />
            : <StatusClassHandle>
              <CurrentClass>
                <Button
                  className={classes.btnToday}
                  style={{
                    backgroundColor: isSelectedDayToday ? theme.colorBrand.medium : theme.colors.neutral.lightBase,
                    color: isSelectedDayToday ? theme.colors.neutral.lightBase : theme.colorBrand.medium,
                    border: isSelectedDayToday ? 'none' : `1px solid ${theme.colorBrand.medium}`
                  }}
                  data-testid='schedule_current_date'
                  onClick={handleToday}
                >
                  {t('Hoje')}
                </Button>
              </CurrentClass>
            </StatusClassHandle>
          }
          {/* DESKTOP */}
          <div className={classes.changeWeekDesktop}>
            {isLoadingCalendarControls
              ? <Skeleton variant='text' width={233} height={50} animation='wave' />
              : <>
                <IconButton onClick={handlePreviousWeek} disabled={disablePaginationBefore} sx={{ opacity: disablePaginationBefore ? 0.2 : 1 }} >
                  <Icon size='small' iconColor={theme.colorBrand.medium} data-testid='schedule_back_previous_week'>
                    <NavigateBeforeOutlined />
                  </Icon>
                </IconButton>
                <span className={classes.calendarControlsDescription}>
                  {t('Alterar semana')}
                </span>
                <IconButton onClick={handleNextWeek} disabled={disablePaginationNext} sx={{ opacity: disablePaginationNext ? 0.2 : 1 }}>
                  <Icon size='small' iconColor={theme.colorBrand.medium} data-testid='schedule_forward_next_week'>
                    <NavigateNextOutlined />
                  </Icon>
                </IconButton>
              </>
            }
          </div>
          {/* MOBILE */}
          <div className={classes.changeDayMobile}>
            <IconButton onClick={handleMobilePreviousWeek} disabled={disablePaginationBeforeMobile} sx={{ opacity: disablePaginationBeforeMobile ? 0.2 : 1 }}>
              <Icon size='small' iconColor={theme.colorBrand.medium} data-testid='schedule_back_previous_week'>
                <NavigateBeforeOutlined />
              </Icon>
            </IconButton>
            <span className={classes.calendarDayMobileDescription} style={{ margin: '0px 5px' }}>
              <div>{selectedDay.toLocaleString('pt-br', { month: 'long', day: '2-digit' })},</div>
              <div>{t(weekDaysTitles[selectedDay.getDay()])}</div>
            </span>
            <IconButton onClick={handleMobileNextWeek} disabled={disablePaginationNextMobile} sx={{ opacity: disablePaginationNextMobile ? 0.2 : 1 }}>
              <Icon size='small' iconColor={theme.colorBrand.medium} data-testid='schedule_forward_next_week'>
                <NavigateNextOutlined />
              </Icon>
            </IconButton>
          </div>
        </div>
      </div>
      {isLoadingDays
        ? <div className={classes.calendarWeekDays}>
          {[...Array(7)].map((cur, i) => <Skeleton key={i} variant='rectangular' width={108} height={108} animation='wave' />)}
        </div>
        : <div className={classes.calendarWeekDays}>
          {
            weekDays.map((dayItems, i) =>
              <WeekDay
                {...dayItems}
                key={i}
                selected={dayjs(dayItems.date).isSame(dayjs(selectedDay), 'day')}
                onClick={() => handleSelectDay(dayItems.date)}
              />
            )
          }
        </div>
      }
    </>
  )
}
