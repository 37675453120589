export const maxLength = (str: string, maxLength: number, showDots: boolean = true) => {
  if (!str) return ''
  return str.length > maxLength ? str.substring(0, maxLength) + (showDots ? '...' : '') : str
}

export const truncateExtension = (str?: string, maxLetters: number = 10): string => {
  if (!str) return ''
  const countLetters = str.split('')
  // se nome < que a quantidade maxima de letras (- nome da extensão)
  if (!countLetters.length || (countLetters?.length - 5) < maxLetters) return str
  const foundExtension = str.match(/.*\.(.*)$/)
  const extension = foundExtension?.length ? foundExtension[1] : ''
  const newTitle = maxLength(str, maxLetters)
  return `${newTitle}${extension}`
}

export const formatCPF = (cpf: string) => {
  if (cpf) {
    // removes the unwanted characters...
    cpf = cpf.replace(/[^\d]/g, '')
    if (cpf.length === 11) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }
    return cpf
  }
  return ''
}

export const formatPhone = (phone: string) => {
  if (phone) {
    if (phone.length === 9) {
      return phone
        .replace(/\D/g, '')
        .replace(/(\d)/, '$1')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, '$1')
    }
    if (phone.length === 11) {
      return phone
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, '$1')
    }
    return phone
  }
  return ''
}

export const isDoNotSendEmail = (email: string | undefined) => {
  const _email = email?.toLowerCase()
  return _email?.includes('donotsend') && _email?.includes('@camino.com.br')
}

export const isUnformattedPhone = (phone: string) => !!(phone && phone !== '0' && phone.length && (phone.length === 9 || phone.length === 11) && phone.match(/\d+/g))
