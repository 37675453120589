import { ISchoolsClassResponse } from 'services/types'
import { IGradeResponse } from 'services/types/taught-disciplines'

export enum STATES {
  idle = 'idle',
  choose_school = 'choose_school',
  choose_class = 'choose_class',
  sucess = 'sucess',
  error = 'error'
}

export type StepsContainerProps = {
  schoolsClassesResponse: ISchoolsClassResponse | null
  contentUnitId: number | string | null
  activityId: number | string | null
  grade: any | null
  gradeCode: string
}

export type StepSchoolSelectProps = {
  schoolsClassesResponse: ISchoolsClassResponse | null
  schoolSelected: SchoolSelected | null
  setSchoolSelected: (value: SchoolSelected) => void
}

export type StepClassSelectProps = {
  schoolsClassesResponse: ISchoolsClassResponse | null
  schoolSelected: SchoolSelected | null
  classSelected: any | null
  setClassSelected: (value: any) => void
  gradeSelected: any | null
  setGradeSelected: (value: any) => void
}

export type StepConfirmationProps = {
  schoolSelected: SchoolSelected | null
  classSelected: any | null
  gradeSelected: any | null
  maxStep?: number
}

export type StepErrorProps = {
  grade: IGradeResponse | null
}

export type CustomFooterProps = {
  isDisable: boolean
  isConfirmation?: boolean
  isError?: boolean
  currentStep: number
  maxStep: number

  onSubmit: () => void
  onCancel: () => void
}

export type CustomHeaderProps = {
  currentStep: number
  maxStep: number
  isError?: boolean

  onClose: () => void
}

export type SchoolSelected = {
  id: number
  name: string
  userSchoolProfile: number
  classes: Array<{
    grades: Array<{ id: number, name: string, code: string }>
    id: number
    name: string
    subscription_id: number
  }>
}

export type ClassSelected = {
  subscription_id: number
  id: number
  name: string
  grades: Array<{ id: number, name: string, code: string }>
}

export type GradeSelected = {
  id: number
  name: string
  code: string
}
