import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  itensContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  cardItemTimeline: {
    minWidth: 150,
    cursor: 'pointer',
    padding: 0,
    height: 150,
    width: 150,
    margin: '12px 12px 0px 0px',
    boxShadow: 'none !important',
    borderRadius: '8px !important',
    border: '1px solid #E6E6E6',
    '&:hover': {
      boxShadow: `${theme.shadowLevels.level3} !important`
    }
  },
  cardItemContentTimeline: {
    padding: 0,
    '&.MuiGrid-root': { maxWidth: '100%' },
    '&.MuiCardContent-root': {
      paddingBottom: 0,
      padding: 0,
      width: '150px',
      height: '150px'
    }
  },
  source: {
    marginTop: '8px',
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '24px',
    letterSpacing: '0.14px',
    color: theme.colors.neutral.dark40,
    textAlign: 'center'
  }
}))
