import { Link as MUiLink, LinkProps } from '@mui/material'
import useStyles from './style'
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material'

export interface ILinkProps extends Omit<LinkProps, 'variant'> {
  variant?: 'primary' | 'returnArrow' | 'nextArrow'
  nomargin?: boolean
}

export const Link: React.FC<ILinkProps> = ({ variant = 'primary', nomargin, children, ...props }) => {
  const classes = useStyles()
  return (
    <MUiLink
      className={`${classes.root} ${variant} ${props.className as string ?? ''} ${nomargin ? 'noMargin' : ''}`}
      {...props}
    >
      {variant === 'returnArrow' ? <ArrowBackIos /> : null}
      {children}
      {variant === 'nextArrow' ? <ArrowForwardIos /> : null}
    </MUiLink>
  )
}
