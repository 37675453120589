import { useCallback, useEffect, useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { IconButton, useTheme } from '@mui/material'
import useStyles from './style'

interface IBackToTopWindow {
  startHeight?: number
}

export const BackToTopWindow = ({ startHeight = 30 }: IBackToTopWindow) => {
  const classes = useStyles()
  const [buttonClass, setButtonClass] = useState<string>(classes.block)
  const [statusControl, setStatusControl] = useState<'dissapear' | 'scrollTop' | 'scrollDown'>('dissapear')
  const [y, setY] = useState(0)
  const theme = useTheme()

  const handleNavigation = useCallback((e) => {
    const element = e.currentTarget
    if (y <= startHeight) {
      setButtonClass(classes.dissapear)
      setStatusControl('dissapear')
    } else if (y > element.scrollY) {
      setButtonClass(classes.scrollTop)
      setStatusControl('scrollTop')
    } else if (y < element.scrollY) {
      setButtonClass(classes.scrollDown)
      setStatusControl('scrollDown')
    }
    setY(element.scollY)
  }, [y])

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation)
    setY(window.scrollY)
    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <IconButton size='large' onClick={() => scrollToTop()} className={buttonClass} id={statusControl}>
      <ArrowUpwardIcon sx={{ color: theme.colorBrand.medium, fontSize: '2rem' }} />
    </IconButton>
  )
}

export default BackToTopWindow
