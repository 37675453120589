import { request } from 'services/base'
import { IApiResponse, IRecordResponse, IStudentAnswerResponse } from 'services/types'
import { USER_RECORDS_ACTIVITY, USER_RECORDS } from './CONSTANTS'

/*
* Get all records and answers from an activity by user profile. Return array of records or empty array
*
*/
export const getUserRecords = async (profileId: number, activityId: number, classId: number | string): Promise<IApiResponse<IRecordResponse[]>> =>
  await request({ url: USER_RECORDS(profileId, activityId, classId) })

export const getUserRecordsActivity = async (activityId: number, classId: number | string): Promise<IApiResponse<IStudentAnswerResponse[]>> =>
  await request({ url: USER_RECORDS_ACTIVITY(activityId, classId) })
