import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    chatContainer: {
      position: 'fixed',
      bottom: 0,
      zIndex: 2000,
      justifyContent: 'flex-end',
      transition: 'all ease-in-out 0.2s',
      width: '480px !important',
      right: '32px !important',
      pointerEvents: 'none', // avoid container blocking behind elements click

      '&.openMyCloe': {
        right: 'calc(32px + 320px) !important'
      },

      // mobile
      [theme.breakpoints.down('md')]: {
        width: '100vw !important',
        maxHeight: '50vh',
        right: '0 !important',

        '&.openMyCloe': {
          right: 'calc(360px) !important',
          zIndex: -2000
        },
        '&.activeMenuClass': {
          zIndex: -2000
        },
        '&.activeMenuClassConfirmation': {
          zIndex: -2000
        },
        '&.isSelectorClassOpen': {
          zIndex: -2000
        },

        '&.closed': {
          width: '30vw !important'
        }
      }
    },
    openCloseButton: {
      height: '67px',
      width: '208px',
      position: 'relative',
      marginBottom: '-1px !important',
      cursor: 'pointer',
      pointerEvents: 'all',

      // mobile
      [theme.breakpoints.down('md')]: {
        marginRight: '-104px !important',

        '&.openMyCloe': {
          marginRight: '0px !important'
        },

        '&.open': {
          marginRight: '0px !important'
        }
      }
    },
    cloeProNotch: {
      fill: theme.colorBrand.medium,

      '&:hover': {
        fill: theme.colorBrand.dark
      }
    },
    iframeContainer: {
      width: '100%',
      borderTop: `${theme.borderThickness.thick} solid ${theme.colorBrand.medium}`,
      background: theme.colors.neutral.lightBase,
      height: '0px',
      transition: 'height ease-in 0.2s',
      marginBottom: `-${theme.borderThickness.thick} !important`,
      boxShadow: theme.shadowLevels.level5,
      pointerEvents: 'all',

      '&.open': {
        marginBottom: '0px',
        height: '50vh',
        [theme.breakpoints.down('md')]: {
          height: '80vh'
        }
      },

      [theme.breakpoints.up('sm')]: {
        '&.open': {
          height: '400px'
        }
      }
    },
    darkBackground: {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      left: '0',
      top: '0',
      background: theme.colors.neutral.darkBase,
      zIndex: 1900,
      opacity: '0.5',

      [theme.breakpoints.down('md')]: {
        '&.open': {
          display: 'block'
        },
        '&.closed': {
          display: 'none'
        }
      },

      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  })
)
