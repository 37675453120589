import { IContentUnitResponse, IDidacticContentResponse } from 'services/types'
import { ProvideContents } from './useContents'
import DidacticContents from './Didactic'
interface IContentsProps {
  content: IContentUnitResponse
  didacticContent: IDidacticContentResponse | null
}

export const Content: React.FC<IContentsProps> = ({ content, didacticContent = null }) => {
  return (
    <ProvideContents content={content} didacticContent={didacticContent}>
      <DidacticContents />
    </ProvideContents>
  )
}

export default Content
