import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik, Form, Field, FieldProps, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Button, Loading, TextField, Alert } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { LOGIN } from 'navigation/CONSTANTS'
import BaseAuthLayout from 'pages/BaseAuthLayout'
import { useAuth } from 'navigation/ProvideAuth'
import { Grid } from '@mui/material'
import { VpnKeyOutlined, CheckOutlined, AccountCircleOutlined, SendOutlined } from '@mui/icons-material'
import { resetTokenValidation } from 'services/users'
import { BackToLoginConfirmationModal } from './components/BackToLoginConfirmationModal'

interface FormValues {
  password: string
  passwordConfirmation: string
}

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never
  set: never
  delete: never
  sort: never
}

export const ResetPassword: React.FC = () => {
  const auth = useAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const styles = useStyles()
  const { search } = useLocation()

  // states
  const [initialState, setInitialState] = useState<'loading' | 'token-invalid' | 'token-expired' | 'form' | 'resend-token-success'>('loading')
  const [username, setUsername] = useState('')
  const [loadingResendForgetPassword, setLoadingResendForgetPassword] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [successResendMessage, setSuccessResendMessage] = useState('')

  const searchParams = useMemo(
    () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
    [search]
  )

  const code = searchParams.get('code')

  const initialValues = {
    password: '',
    passwordConfirmation: ''
  }

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Digite pelo menos 8 caracteres')
      .required('Campo obrigatório'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas digitadas não são iguais')
      .required('Campo obrigatório')
  })

  const setInvalidToken = () => {
    setInitialState('token-invalid')
    setTimeout(() => {
      history.replace(LOGIN)
    }, 4000)
  }

  const checkIfTokenIsValid = async (code?: string | null) => {
    if (!code) {
      return setInvalidToken()
    }

    const response = await resetTokenValidation(code)
    if (response.success && response.data.ok) {
      setInitialState('form')
    } else if (!response.data.ok && response.data.username) {
      setInitialState('token-expired')
      setUsername(response.data.username)
    } else {
      setInvalidToken()
    }
  }

  useEffect(() => {
    checkIfTokenIsValid(code)
  }, [code])

  const handleRecover = async (values: any) => {
    const response = await auth?.resetPassword(
      code as string,
      values.password,
      values.passwordConfirmation
    )

    if (response?.success) {
      auth?.signout(true)
    } else {
      setInvalidToken()
    }
  }

  const handleResendForgotPassword = async () => {
    setLoadingResendForgetPassword(true)
    if (username === '') return
    const _ignoredOptionalParams = undefined
    const response = await auth?.forgotPassword(_ignoredOptionalParams, _ignoredOptionalParams, username)

    if (!response?.success) {
      return setInvalidToken()
    }

    if (response?.data?.sentToEmail) {
      const userRecipientEmail = response?.data?.sentToEmail
      setSuccessResendMessage(`Instruções enviadas para ${userRecipientEmail}`)
    }

    if (response?.data?.sentToSms) {
      const userRecipientSms = response?.data?.sentToSms
      setSuccessResendMessage(`Instruções enviadas para ${userRecipientSms}`)
    }
    setInitialState('resend-token-success')
    setLoadingResendForgetPassword(false)
  }

  const onSubmit = async (values: any) => {
    if (initialState === 'form') {
      await handleRecover(values)
    }
  }

  const render = {
    loading: () => (<></>),
    'token-invalid': () => (
      <Grid item xs={12}>
        <Alert
          className={styles.alertError}
          content={t('Link inválido. Você será redirecionado para o login .')}
          severity='error'
        />
      </Grid>
    ),
    'token-expired': () => (
      <>
        <Grid item xs={12}>
          <Alert
            className={styles.alertError}
            content={t('Link expirado. Clique no botão abaixo para reenviar o e-mail de redefinição de senha.')}
            severity='error'
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={`${styles.submitButton} ${!username ? '' : 'active'}`}
            variant='primary'
            disabled={!username || loadingResendForgetPassword}
            type='submit'
            data-testid='set_new_password_confirm'
            onClick={handleResendForgotPassword}
          >
            {!loadingResendForgetPassword
              ? <>{t('Reenviar e-mail')}<SendOutlined sx={{ marginLeft: '14px' }} /></>
              : <Loading />}
          </Button>
        </Grid>
      </>
    ),
    'resend-token-success': () => (
      <Grid item xs={12}>
        <Alert
          content={successResendMessage}
          severity='success'
        />
      </Grid>
    ),
    form: ({ dirty, isSubmitting, errors }: FormikProps<{
      password: string
      passwordConfirmation: string
    }>) => (
      <>
        <Grid item xs={12}>
          <Field name='password'>
            {({
              field,
              meta
            }: FieldProps<FormValues>) => (
              <TextField
                {...field}
                id='password'
                variant='outlined'
                className={styles.input}
                password
                label={t('Digite sua nova senha')}
                error={Boolean(meta.error)}
                assistiveText={meta.error}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name='passwordConfirmation'>
            {({
              field,
              form,
              meta
            }: FieldProps<FormValues>) => (
              <TextField
                {...field}
                onChange={e => {
                  if (field.value.passwordConfirmation !== '') {
                    form.validateField(field.name)
                  }
                  return field.onChange(e)
                }}
                id='passwordConfirmation'
                variant='outlined'
                className={styles.input}
                password
                label={t('Confirme sua nova senha')}
                error={Boolean(meta.error)}
                assistiveText={meta.error}
                onPaste={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }} // avoid paste on this field
              />
            )}
          </Field>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Button
              className={`${styles.submitButton} ${!dirty || isSubmitting || !!errors.password?.length || !!errors.passwordConfirmation?.length || 'active'}`}
              variant='primary'
              disabled={!dirty || isSubmitting || !!errors.password?.length || !!errors.passwordConfirmation?.length}
              type='submit'
              data-testid='set_new_password_confirm'
            >
              {!isSubmitting
                ? <>{t('Confirmar')}<CheckOutlined sx={{ marginLeft: '14px' }} /></>
                : <Loading />}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <React.Fragment>
      <BaseAuthLayout>
        <Formik
          initialValues={initialValues}
          validationSchema={ResetPasswordSchema}
          onSubmit={onSubmit}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {formikProps => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={styles.title}>
                      <VpnKeyOutlined sx={{ marginRight: '8px' }} />
                      {`${t('Redefinição de senha')}`}
                    </div>
                  </Grid>
                  {render[initialState](formikProps)}
                  <Grid item xs={12} mt={1}>
                    <Button
                      className={styles.backButton}
                      onClick={() => setShowConfirmationModal(true)}
                      variant='ghost'
                      data-testid='set_new_password_back'
                    >
                      <AccountCircleOutlined sx={{ marginRight: '8px' }} />
                      {t('Ir para login')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </BaseAuthLayout>
      <BackToLoginConfirmationModal
        showModal={showConfirmationModal}
        onConfirm={() => history.replace(LOGIN)}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </React.Fragment>
  )
}

export default ResetPassword
