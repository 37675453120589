import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardLoadStudentAnswerContainer: {
      backgroundColor: theme.colors.neutral.lightBase,
      padding: theme.spacingInline.xxs,
      border: `1px solid ${theme.colors.neutral.light20}`,
      borderRadius: theme.borderRadius.md,
      marginTop: theme.spacingStack.xxs
    }
  })
)
