import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IUserSchoolProfileTypeEnum } from 'contentCacheManager'

export default makeStyles((theme: Theme) =>
  createStyles({
    // Ready component
    cardsContainer: {
      display: 'flex',
      position: 'relative',
      minWidth: '100%',
      paddingBottom: theme.spacingStack.xxs,
      paddingLeft: '0 !important',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        overflow: 'visible'
      }
    },
    cardsFadeBackground: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        position: 'absolute',
        width: '155px',
        height: '270px',
        bottom: 0,
        right: `-${theme.spacingStack.xxxs}`,
        background: ({ profileType }: { profileType?: IUserSchoolProfileTypeEnum }) =>
          profileType === IUserSchoolProfileTypeEnum.teacher
            ? 'linear-gradient(270deg, #EBE6F1 10%, rgba(235, 230, 241, 0.00) 100%)'
            : 'linear-gradient(270deg, #F7F1F7 10%, rgba(247, 241, 247, 0.00) 100%)'
      }
    },
    cardsItemContainer: {
      margin: '0px 16px 0px 0px',
      borderRadius: theme.spacingInline.xxxs,
      '& > a > div': {
        minHeight: '100%'
      },

      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          boxShadow: '0px 8px 14px 0px rgba(20, 20, 20, 0.16)'
        }
      }
    },
    paddingCards: {
      margin: '8px 8px 0px 0px',
      [theme.breakpoints.up('sm')]: {
        margin: '16px 16px 0px 0px'
      }
    },
    background: {
      backgroundColor: `${theme.colorBrand.lightest}57`,
      borderRadius: theme.borderRadius.sm,
      transition: 'all ease-in 0.2s',
      marginTop: `${theme.spacingStack.xxxs}!important`,
      padding: `${theme.spacingStack.xxxs} !important`,
      marginLeft: '0 !important',
      width: '100% !important',
      overflow: 'hidden',

      [theme.breakpoints.up('sm')]: {
        '&.adjustable': {
          height: 'auto'
        }
      }
    },
    // Header component
    titleDropContainer: {
      marginBlock: 0,
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginBlock: 0
      },
      display: 'flex'
    },
    titleDrop: {
      textDecoration: 'none',
      padding: '0 !important',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      color: theme.colorBrand.medium,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs,
      justifyContent: 'space-between',
      '& > p': {
        margin: 0
      },
      '& > svg': {
        fontSize: `${theme.font.fontSize.md}!important`,
        marginLeft: '8px'
      },

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.font.fontSize.sm,
        '& > svg': {
          fontSize: `${theme.font.fontSize.lg}!important`
        }
      }
    },
    buttonInsideAlert: {
      '&.MuiButton-root': {
        color: theme.colors.negative.feedbackDarkest,
        fontWeight: 900
      }
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 !important',
      width: '100%',
      alignItems: 'center'
    },
    cardsBottom: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacingInline.xxxs,
      paddingTop: '0 !important'
    }
  })
)
