
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  countContainer: {
    display: 'flex',
    gap: theme.spacingInset.nano,
    flex: 1,
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start'
  },
  countText: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30
  }
}))
