import { atom } from 'jotai'
import { ActivityControlStatusEnum, IActivityResponse, IAnswersRequest, ILevelResponse, IStudentsLevel } from 'services/types'

export const isSuccessAtom = atom<boolean>(false)
export const questionIndexAtom = atom<number>(0)
export const answersAtom = atom<IAnswersRequest[]>([])
export const isAnsweredAtom = atom<boolean>(false)
export const isEditingAtom = atom<boolean>(false)
export const activityStatusControlAtom = atom<ActivityControlStatusEnum | number | string>(0)
export const inUploadingProcess = atom<boolean>(false)
export const activityCurrentAtom = atom<IActivityResponse | null>(null)

export const studentLevelsAtom = atom<IStudentsLevel[]>([])
export const schoolLevelsAtom = atom<ILevelResponse[]>([])

export const isStudentViewAtom = atom<boolean>(false)
