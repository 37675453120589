import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  overviewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,
    paddingInline: theme.spacingInline.xxs,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingInline: 0
    }
  },
  content: {
    // color: theme.colors.neutral.dark10,
    // fontSize: theme.font.fontSize.sm,
    // fontWeight: theme.font.fontWeight.medium,
    // lineHeight: '24px',
    // letterSpacing: '0.16px',

    // [theme.breakpoints.down('sm')]: {
    //   fontSize: theme.font.fontSize.xs
    // },
    // '& p': {
    //   color: theme.colors.neutral.dark10,
    //   fontSize: theme.font.fontSize.sm,
    //   fontWeight: theme.font.fontWeight.medium,
    //   lineHeight: '24px',
    //   letterSpacing: '0.16px',

    //   [theme.breakpoints.down('sm')]: {
    //     fontSize: theme.font.fontSize.xs,
    //     margin: 0
    //   },
    //   '& span': {
    //     // fontWeight: `${theme.font.fontWeight.medium} !important`,
    //     // fontSize: `${theme.font.fontSize.sm} !important`,

    //     [theme.breakpoints.down('sm')]: {
    //       fontSize: `${theme.font.fontSize.xs} !important`
    //     }
    //   }
    // }
  },
  contentText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingStack.xxxs,
    [theme.breakpoints.down('sm')]: {
      gap: '10px'
    }
  },
  readingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.sm,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      overflowWrap: 'anywhere'
    }
  },
  printButton: {
    padding: '12px !important',
    fontSize: '12px !important',
    minWidth: 'unset !important',
    border: `1px solid ${theme.colorBrand.medium} !important`
  },
  title: {
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xl,
    letterSpacing: theme.font.spacing.distant,
    lineHeight: '40px',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.lg,
      lineHeight: theme.font.lineHeight.sm
    }
  },
  overviewSection: {
    '& h3': {
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xl,
      lineHeight: '40px',
      letterSpacing: '0.01em',
      color: theme.colorBrand.medium,

      // mobile
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.lg,
        margin: '32px 0 8px'
      }
    },
    '& p': {
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark20,

      // mobile
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    }
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px 8px'
  },
  startPointCollapseTitle: {
    '& h2': {
      fontWeight: theme.font.fontWeight.bold,
      fontSize: theme.font.fontSize.md,
      lineHeight: '40px',
      letterSpacing: '0.01em',
      color: theme.colorBrand.medium,

      // mobile
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.lg,
        margin: '32px 0 8px'
      }
    },
    '& p': {
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark20,

      // mobile
      [theme.breakpoints.down('sm')]: {
        margin: 0
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      marginBottom: theme.spacingInline.nano,
      gap: theme.spacingInline.nano
    }
  },
  sectionTitle: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.md
  },
  selectorTitle: {
    fontSize: theme.font.fontSize.sm,
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xxs}`,
    color: theme.colorBrand.darkest,
    fontWeight: theme.font.fontWeight.medium
  },
  startingPointCoverImage: {
    width: '100%',
    height: '256px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: theme.spacingInline.xxxs
  },
  startingPointMyMaterialsButton: {
    width: '100%'
  },
  curriculumContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacingInset.xs,
    '& ul, p': {
      marginBlock: 0
    }
  }
}))
