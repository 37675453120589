import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import cloeDogServerDown from 'assets/cloe-dog-server-down.svg'

export default makeStyles((theme: Theme) => createStyles({
  image404: {
    backgroundImage: `url(${cloeDogServerDown})`,
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat',
    display: 'block'
  },
  titleEmptyDisciplines: {
    fontSize: theme.font.fontSize.xxxxl,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colors.neutral.dark30,
    fontFamily: theme.font.fontFamily
  },
  containerNoSchoolPeriod: {
    textAlign: 'center',
    paddingBottom: theme.spacingInset.lg,
    display: 'grid',
    width: '100%',
    height: '100%',
    justifyItems: 'center',
    alignContent: 'center',
    padding: '0px 60px 0px 60px'
  },
  title: {
    maxWidth: '700px'
  }
}))
