import { atom } from 'jotai'
import { IStudentRecordsTimelineResponse } from 'services/types'
import { IConfirmationModalProps } from '../ConfirmationModal'

export const tabIndexModalTimelineAtom = atom<number>(0)
export const openModalTimelineAtom = atom<boolean>(false)
export const contentModalTimelineAtom = atom<IStudentRecordsTimelineResponse | null>(null)

export const openModalConfirmationTimelineAtom = atom<boolean>(false)
export const confirmationModalPropsAtom = atom<IConfirmationModalProps | null>(null)
