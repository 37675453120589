import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useAtom } from 'jotai'

import CardStudentAnswer from '../CardStudentAnswer'
import { studentsAnswerAtom } from '../../atomStore'
import { IStudentsAnswerByQuestionResponse } from 'contentCacheManager'

export const ListStudentsAnswer: React.FC = () => {
  // state
  const [studentsWithAnswer, setStudentsWithAnswer] = useState<IStudentsAnswerByQuestionResponse[]>([])
  const [studentsWithoutAnswer, setStudentsWithoutAnswer] = useState<IStudentsAnswerByQuestionResponse[]>([])

  // atom
  const [studentsAnswer] = useAtom(studentsAnswerAtom)

  const validateListStudents = () => {
    setStudentsWithAnswer((studentsAnswer ?? []).filter(student => student?.answer).sort((a, b) => a.user_name > b.user_name ? 1 : -1))
    setStudentsWithoutAnswer((studentsAnswer ?? []).filter(student => !student?.answer).sort((a, b) => a.user_name > b.user_name ? 1 : -1))
  }

  useEffect(() => {
    validateListStudents()
  }, [])

  return (
    <Grid container display='flex' flexDirection='row' justifyContent='space-between'>
      {(studentsWithAnswer ?? []).map(studentAnswer => (
        <CardStudentAnswer studentAnswer={studentAnswer} />
      ))}

      {(studentsWithoutAnswer ?? []).map(studentAnswer => (
        <CardStudentAnswer studentAnswer={studentAnswer} />
      ))}
    </Grid>
  )
}

export default ListStudentsAnswer
