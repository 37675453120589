import { useMediaQuery, useTheme } from '@mui/material'
import { useOnlineStatus } from 'contentCacheManager'
import React, { useMemo } from 'react'

type OnlyOfflineProps = {
  forceMobile: boolean
}
const OnlyOffline: React.FC<OnlyOfflineProps> = ({ children, forceMobile }) => {
  const isOnline = useOnlineStatus()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return useMemo(() => {
    if (isOnline) {
      return null
    }

    if (forceMobile && !isMobile) {
      return null
    }

    return <>{children}</>
  }, [forceMobile, children, isMobile, isOnline])
}

export default OnlyOffline
