import React, { useEffect } from 'react'
import { Switch, useLocation } from 'react-router-dom'

import { IUserSchoolProfileTypeEnum } from 'services/types'
import OnlyProfile from 'components/common/OnlyProfile'
import { useAuth } from 'navigation/ProvideAuth'
import Appcues from 'utils/appcues'

import { RouteWithSubRoutes, IRouteWithSubRouteProps } from './components/RouteWithSubRoutes'
import { PrivateRouteGroupWrapper } from 'navigation/routes/components/PrivateRouteGroupWrapper'
import { getPublicRoutes } from './config/publicRoutes'
import { studentPrivateRoutes } from './config/studentPrivateRoutes'
import { teacherPrivateRoutes } from './config/teacherPrivateRoutes'
import { commonPrivateRoutes } from './config/commonPrivateRoutes'

export interface IRoutesGroupProps {
  routes: IRouteWithSubRouteProps[]
  profileTypes?: IUserSchoolProfileTypeEnum[]
}

export const Routes: React.FC = () => {
  const auth = useAuth()
  const location = useLocation()

  useEffect(() => {
    Appcues.identify(auth?.user)
  }, [location])

  const publicRoutes = getPublicRoutes(!!auth?.user)

  return (
    <Switch>
      {
        publicRoutes.routes.map((route, index) => <RouteWithSubRoutes {...route} key={index} />)
      }
      <PrivateRouteGroupWrapper>
        <OnlyProfile profileTypes={teacherPrivateRoutes.profileTypes as IUserSchoolProfileTypeEnum[]}>
          {
            teacherPrivateRoutes.routes.map((route, index) => (
              <RouteWithSubRoutes {...route} key={index} />
            ))
          }
        </OnlyProfile>
        <OnlyProfile profileTypes={studentPrivateRoutes.profileTypes as IUserSchoolProfileTypeEnum[]}>
          {
            studentPrivateRoutes.routes.map((route, index) => (
              <RouteWithSubRoutes {...route} key={index} />
            ))
          }
        </OnlyProfile>
        {
          commonPrivateRoutes.routes.map((route, index) => (
            <RouteWithSubRoutes {...route} key={index} />
          ))
        }
      </PrivateRouteGroupWrapper>
    </Switch>
  )
}
