export const FETCH_COMMENTS = (profileId: string | number, activityId: string | number, classId: string | number, questionId: number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/comments?activity=${activityId}&user_school_profile=${profileId}&class=${classId}&questionId=${questionId}&_sort=created_at:desc`

export const CREATE_COMMENT = () =>
  `${process.env.REACT_APP_BACKEND_URL as string}/comments`

export const COMMENTS_BY_ID = (commentId: string) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/comments/${commentId}`

export const READ_COMMENTS_BY_ID = (commentId: string) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/comments/${commentId}/read`

export const COUNT_COMMENT = (activityId: string | number) =>
  `${process.env.REACT_APP_BACKEND_URL as string}/comments/count?activity=${activityId}&isRead=false`
