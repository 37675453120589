import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    summaryQuestionContainer: {
      marginTop: theme.spacingInline.xxs
    },
    summaryQuestionContent: {
      '&.hide': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical'
      }
    },
    summaryQuestionTxt: {
      color: theme.colors.neutral.dark20,
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.semibold
    },
    summaryQuestionButtonShowResources: {
      marginTop: theme.spacingStack.xxxs,
      color: theme.colorBrand.medium,
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.semibold,
      cursor: 'pointer'
    }
  })
)
