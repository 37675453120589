import ErrorWrapper from 'components/common/ErrorWrapper'
import useStudentAnswer from '../../hooks/useStudentAnswer'

const ActivityNotFound = () => {
  const { hasResponseError, errorMessage } = useStudentAnswer()
  return (
    <ErrorWrapper hasError={hasResponseError} errorMessage={errorMessage} />
  )
}

export default ActivityNotFound
