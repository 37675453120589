import React, { useRef } from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { useAtom } from 'jotai'

import useStyles from './style'
import { Tooltip } from 'components/common'
import ButtonQuestionSelector from './components/ButtonQuestionSelector'
import ButtonRevised from './components/ButtonRevised'
import ButtonFixCorrect from './components/ButtonFixCorrect'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import ButtonShowResults from './components/ButtonShowResults'

export const QuestionSelector: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ref = useRef<HTMLDivElement>(null)

  // atom
  const [activity] = useAtom(activityCurrentAtom)

  return (
    <>
      <Grid container display='flex' ref={ref} />
      <Grid container className={`${classes.questionSelectorContainer}`} display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid item>
          <Grid display='flex' gap={1} className={classes.questionSelectorContentOptions} flexWrap='wrap'>
            {(activity?.questions ?? [])?.map((question, index) => <ButtonQuestionSelector index={index} question={question} />)}
          </Grid>
        </Grid>

        <Grid item display='flex' alignItems='center' gap={1.5} className={classes.questionSelectorContentHandles}>
          <ButtonRevised />

          <Grid item display='flex' alignItems='center' gap={1.5} {...(isMobile && { width: '100%' })}>
            {activity?.suggested_application_type === 'evaluation' ? <ButtonShowResults /> : <ButtonFixCorrect />}

            <Tooltip
              component={
                <Grid item display='flex' alignItems='center'>
                  <InfoOutlined />
                </Grid>
              }
              text={activity?.suggested_application_type === 'evaluation' ? 'Você poderá visualizar e liberar os resultados após a avaliação de todas as respostas dos estudantes.' : 'Você poderá liberar as correções após o visto de todas as respostas dos estudantes.'}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default QuestionSelector
