import { ExpeditionCachedTable } from '../../'
import { makeDatabaseInstanceOffline } from 'contentCacheManager'

export const setExpeditionCachedData = async (data: Partial<ExpeditionCachedTable>, expedition: number | ExpeditionCachedTable) => {
  let currentExpedition
  if (expedition instanceof ExpeditionCachedTable) {
    currentExpedition = expedition
  } else {
    currentExpedition = await makeDatabaseInstanceOffline().expeditionsCached.where('expeditionId').equals(expedition).first()
  }

  if (currentExpedition) {
    return await makeDatabaseInstanceOffline().expeditionsCached.update(Number(currentExpedition.id), {
      ...data
    })
  }
}
