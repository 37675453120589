import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bottomMenu: {
    position: 'absolute',
    bottom: '0',
    background: theme.colorBrand.lightest,
    width: '100%',
    zIndex: '9',
    height: '48px',

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      height: '40px'
    }
  },
  bottomMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%'
  },
  notch: {
    '& svg': {
      height: '24px',
      width: '24px',
      '& path': {
        fill: `${theme.colorBrand.lightest} !important`
      }
    },

    position: 'absolute',
    top: '-24px'
  },
  notchLeft: {
  },
  notchRight: {
    right: '0',
    transform: 'scaleX(-1)'
  },
  cloeProCardSpace: {
    width: '208px', // same width as openClosebutton on cloe pro
    height: '100%'
  }
}))
