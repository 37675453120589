import { Grid, useTheme, useMediaQuery, Alert } from '@mui/material'

import {
  IContentUnitResponse
} from 'services/types'
import useStyles from '../style'
import { DefaultHeader, ActivityFooter } from '../../common'
import { ActivityTypeTag } from '../../student/../common/ActivitySideContent/components/ActivityTypeTag'
import useStudentAnswer from '../../hooks/useStudentAnswer'

interface IStudentActivityProps {
  content?: IContentUnitResponse
}

export const UnavailableActivityFund1: React.FC<IStudentActivityProps> = ({ content }) => {
  const classes = useStyles({})
  const { activity } = useStudentAnswer()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {content && <DefaultHeader content={content} />}
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} lg={4}>
          <div className={!isMobile ? classes.fixed : ''}>
            <Grid
              container
              item
              xs={12}
              lg={10}
              spacing={3}
              mt={0}
              mb={3}
              justifyContent='flex-start'
              direction='row'
              alignItems='center'
            >
              <ActivityTypeTag activity={activity} content={content} />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} lg={8} pt={0} ml={0}>
          <Grid width='100%' container spacing={1} ml={0}>
            <Alert severity='warning' icon={<></>}>O professor ainda não liberou esta avaliação.</Alert>
            <ActivityFooter />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default UnavailableActivityFund1
