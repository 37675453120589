import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Button, Text2 } from 'components/design-system'

import useStyle from './styles'
import { IMessage } from 'services/types/messages'
import { PARENTAL_MESSAGES } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'
import { Message } from 'components/common'
import { useStore, useCurricularStore } from 'store'
import Analytics from 'utils/analytics'

interface IParentalMessageReady {
  message: IMessage | undefined | null
  showHistory: Boolean
}

export const ParentalMessageReady = ({ message, showHistory }: IParentalMessageReady) => {
  const classes = useStyle()
  const theme = useTheme()
  const history = useHistory()

  const { profile, schoolPeriod, subscription } = useStore()
  const { currentDiscipline, classId, currentClass } = useCurricularStore()

  const commonAnalyticsAttributes = {
    profile_type: profile?.type,
    profile_id: profile?.id,
    school_id: profile?.school?.id,
    school_name: profile?.school?.name,
    class_id: classId,
    class_name: currentClass?.name,
    discipline_id: currentDiscipline?.id,
    discipline_title: currentDiscipline?.name,
    period_title: schoolPeriod?.title,
    period_id: schoolPeriod?.id
  }

  const { t } = useTranslation()

  const handleRecordEventClick = (eventName: string, exclusiveAttributes?: {}) => {
    Analytics.recordEventClick({
      name: eventName,
      attributes: {
        ...subscription?.analytics,
        ...commonAnalyticsAttributes,
        ...exclusiveAttributes
      }
    })
  }

  const handleSeeHistoryButton = () => {
    if (!currentDiscipline?.id) {
      return
    }
    handleRecordEventClick('chat_gpt_responsible_message_view_message_history')
    history.push(PARENTAL_MESSAGES(String(currentDiscipline.id)))
  }

  if (!message) {
    return <></>
  }

  return (
    <Grid container item xs={12} rowSpacing={2}>
      <Grid item>
        <Text2
          fontSize='lg'
          fontWeight='semibold'
          lineHeight='xs'
          mobile='sm'
          customColor={theme.colorBrand.medium}
        >
          {t('Mensagem aos responsáveis')}
        </Text2>
      </Grid>
      <Grid item width='100%'>
        <Text2
          fontSize='sm'
          fontWeight='medium'
          lineHeight='xs'
          mobile='sm'
          customColor={theme.colors.neutral.dark10}
        >
          {t('As mensagens a seguir foram enviadas pelos seus professores e devem ser mostradas ao seu responsável.')}
        </Text2>
      </Grid>

      <Message
        message={message}
        hideButtonDataTestId='chat_gpt_responsible_message_hide_full_text'
        showMoreButtonDataTestId='chat_gpt_responsible_message_display_full_text'
      />

      {!!showHistory && (
        <Button
          variant='outlined'
          onClick={handleSeeHistoryButton}
          data-testid=''
          className={classes.historyButton}
        >
          {t('Ver histórico de mensagens')}
        </Button>
      )}
    </Grid>
  )
}
