import React from 'react'
import { Modal, Backdrop, Grid, useTheme, useMediaQuery } from '@mui/material'

import { Button } from 'components/design-system'

import useStyles from './style'

type NoticeNeedSignInModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  confirmDataTestId: string
}

export const NoticeNeedSignInModal: React.FC<NoticeNeedSignInModalProps> = ({ isOpen, setIsOpen, confirmDataTestId }: NoticeNeedSignInModalProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={isOpen}
        onClose={() => {
          // This is intentional
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Grid container display='flex' flexDirection='column' className={classes.container}>
          <span className={classes.txtTitle}>Você ainda não acessou a plataforma Cloe</span>

          <span className={classes.txtDescription}>Para completar esta ação você precisa fazer o login</span>

          <Button
            sx={{
              ...(isMobile && { width: '100%' })
            }}
            data-testid={confirmDataTestId}
            onClick={() => setIsOpen(false)}
          >
            Fazer Login
          </Button>
        </Grid>
      </Modal>
    </div>
  )
}
