import Grid from '@mui/material/Grid'

import {
  ActivityTypeDescription,
  IContentUnitResponse,
  IUserSchoolProfileTypeEnum
} from 'services/types'
import { LoadingWrapper } from 'components/common'
import ErrorWrapper from 'components/common/ErrorWrapper'
import useStyles from '../style'
import { LeavingActivityWarning, ActivityBody, ActivityFooter, RelatedContent } from '../../common'
import useStudentAnswer from '../../hooks/useStudentAnswer'
import { ExpeditionHeader, DefaultHeader } from '../../common/ActivityHeader'
import ActivityTitle from '../../common/ActivityHeader/DefaultHeader/components/ActivityTitle'

interface IStudentActivityProps {
  content?: IContentUnitResponse
}

export const StudentActivityView: React.FC<IStudentActivityProps> = ({ content }) => {
  const {
    isLoading,
    activity,
    errorMessage,
    hasResponseError,
    isExpeditionView
  } = useStudentAnswer()
  const isTemplate = activity?.custom_type !== 'clone'

  const classes = useStyles({ isTemplate })
  const activityType = activity?.type

  return (
    <LoadingWrapper loading={!activity || isLoading} type='linear'>
      <ErrorWrapper hasError={hasResponseError} errorMessage={errorMessage}>
        {isExpeditionView && <ExpeditionHeader />}
        <Grid container className={classes.wrapper}>
          <LeavingActivityWarning />
          {(!isExpeditionView && content) && <DefaultHeader content={content} />}
          {
            isExpeditionView &&
              <Grid item xs={12} sx={{ mt: { xs: 2, md: 0 } }}>
                <ActivityTitle title={activity?.title ?? activity?.activity_name ?? ''} customType={activity?.custom_type ?? 'clone'} profileType={IUserSchoolProfileTypeEnum.student} />
              </Grid>
          }
          {activity && (
            <RelatedContent
              activity={activity}
              activityType={(activityType && ActivityTypeDescription[activityType])}
              content={content}
            />
          )}
          <Grid item xs={12} lg={12} pt={0} ml={0}>
            <Grid width='100%' container spacing={1} ml={0}>
              <ActivityBody />
              <ActivityFooter />
            </Grid>
          </Grid>
        </Grid>
      </ErrorWrapper>
    </LoadingWrapper>
  )
}

export default StudentActivityView
