import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import emptyStateScreenStudents from 'assets/empty-state-screen-students.svg'

export default makeStyles((theme: Theme) => createStyles({
  containerEmptyRecord: {
    maxWidth: '20rem',
    textAlign: 'center',
    paddingBottom: theme.spacingInset.lg
  },
  image: {
    backgroundImage: `url(${emptyStateScreenStudents})`,
    marginBlock: theme.spacingInline.md,
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat'
  },
  container: {
    paddingTop: theme.spacingInset.sm
  },
  cardMonth: {
    width: '119px',
    height: '48px',
    background: theme.colorBrand.lightest,
    borderRadius: theme.borderRadius.sm,
    padding: '6px',
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '141px',
      height: '52px',
      fontSize: theme.font.fontSize.md
    }
  },
  oppositeContent: {
    '&.MuiTimelineOppositeContent-root': {
      fontFamily: theme.font.fontFamily,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xxs,
      marginTop: '12px',
      padding: '6px 16px 16px 0px',
      flex: 'none',
      color: theme.colors.neutral.dark30,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'inherit'
      }
    }
  },
  contentDay: {
    paddingBottom: theme.spacingInset.xxs,
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  itemTimeline: {
    display: 'block'
  },
  activityTitleContainer: {
    '&.MuiGrid-root': {
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacingInset.xxs,
      [theme.breakpoints.up('sm')]: {
        gap: theme.spacingInset.xs,
        fontSize: theme.font.fontSize.sm
      }
    },
    '& span': {
      color: theme.colors.neutral.lightBase
    }
  },
  itensContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  cardItemTimeline: {
    minWidth: 150,
    cursor: 'pointer',
    padding: 0,
    height: 150,
    width: 150,
    margin: '12px 12px 0px 0px'
  },
  text: {
    lineHeight: theme.font.lineHeight.xs,
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    WebkitLineClamp: 6,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    margin: 0,
    color: theme.colors.neutral.lightBase,
    padding: theme.spacingInset.xs,
    '& span': {
      color: theme.colors.neutral.lightBase
    }
  },
  deleteButton: {
    '&.ghost': {
      width: '150px',
      justifyContent: 'space-between',
      padding: theme.spacingStack.nano,
      fontSize: theme.font.fontSize.xxs,
      marginTop: theme.spacingStack.xxxs
    }
  },
  cardItemContentTimeline: {
    maxWidth: '0px'
  },
  cardsTimeline: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacingInset.xs
  },
  subTitle: {
    padding: '12px 0',
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium
  },
  tagSubTitle: {
    marginBottom: theme.spacingStack.quarck,
    lineHeight: theme.font.lineHeight.xs
  },
  navLink: {
    textDecorationColor: theme.colorBrand.medium
  }
}))
