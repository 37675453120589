import { makeStyles, createStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    marginRight: theme.spacingInline.nano,
    '&.small': {
      height: theme.spacingStack.xxxs,
      width: theme.spacingStack.xxxs
    },
    '&.medium': {
      height: theme.spacingStack.xxs,
      width: theme.spacingStack.xxs
    },
    '&.large': {
      height: theme.spacingStack.xs,
      width: theme.spacingStack.xs
    }
  }
}))
