import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import WhiteWorm from 'assets/cloe-worm-white.svg'
import EllipseMedium from 'assets/elipse-lilas-media.svg'
import EllipseSmall from 'assets/elipse-lilas-pequena.svg'

export default makeStyles((theme: Theme) => createStyles({
  accordion: {
    backgroundImage: `url(${WhiteWorm})`,
    backgroundPosition: 'top 0px right 0px',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'top -28px right -156px'
    },
    '&.MuiAccordion-root': {
      backgroundColor: '#E9E5F0',
      boxShadow: '0px 8px 24px rgba(20, 20, 20, 0.16)'
    },
    '& .MuiAccordionSummary-content': {
      marginBottom: theme.spacingInline.nano
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      position: 'absolute',
      right: theme.spacingInline.xxxs,
      top: theme.spacingInline.xxxs
    },
    '& .MuiAccordionDetails-root': {
      marginTop: theme.spacingInline.xxxs,
      padding: `0px ${theme.spacingInline.xxs} ${theme.spacingInline.xxs}`
    }
  },
  accordionSummary: {
    minHeight: '80px!important'
  },
  accordionTitle: {
    padding: '10px 0px 10px 92px',
    zIndex: '1',
    [theme.breakpoints.down('md')]: {
      padding: `72px 0px 0px ${theme.spacingInline.nano}`
    },
    '& .MuiTypography-root': {
      [theme.breakpoints.up('md')]: {
        fontSize: `${theme.font.fontSize.lg}!important`
      }
    }
  },
  buttonGroup: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '48px!important'
    }
  },
  confirmButton: {
    '&:disabled': {
      background: `${theme.colorBrand.lightest}!important`,
      color: `${theme.colorBrand.light}!important`
    },
    '& .MuiCircularProgress-root': {
      height: `${theme.spacingInline.xxxs}!important`,
      width: `${theme.spacingInline.xxxs}!important`
    }
  },
  cloeAvatar: {
    backgroundImage: `url(${EllipseMedium}), url(${EllipseSmall})`,
    backgroundPosition: '84px 8px, 12px 24px',
    backgroundRepeat: 'no-repeat, no-repeat',
    position: 'absolute',
    left: '-14px',
    top: `-${theme.spacingInline.sm}`,
    paddingRight: theme.spacingInline.nano,
    width: '144px'
  },
  expandText: {
    position: 'absolute',
    top: theme.spacingInline.xxxs,
    right: theme.spacingInline.sm
  },
  generateTextButton: {
    '&:disabled': {
      background: `${theme.colorBrand.lightest}!important`,
      color: `${theme.colorBrand.light}!important`
    },
    '& .MuiCircularProgress-root': {
      height: `${theme.spacingInline.xxxs}!important`,
      width: `${theme.spacingInline.xxxs}!important`
    }
  },
  modal: {
    '& .MuiBox-root': {
      padding: '0'
    }
  },
  modalDescription: {
    padding: '16px'
  },
  modalGeneratedText: {
    background: 'rgba(230, 230, 230, 0.32)',
    borderRadius: theme.borderRadius.sm,
    maxHeight: '432px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 10px transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colors.neutral.light20,
      outline: 'none',
      borderRadius: theme.borderRadius.sm
    },
    padding: theme.spacingSquish.nano,
    whiteSpace: 'pre-wrap',
    width: '100%'
  },
  modalFooter: {
    background: 'rgba(230, 230, 230, 0.32)',
    borderRadius: `0 0 ${theme.spacingInline.xxxs} ${theme.spacingInline.xxxs}`,
    gap: '24px',
    marginLeft: '0',
    padding: theme.spacingInline.xxxs,
    width: '100%'
  },
  textGeneratorTextField: {
    width: '100%',
    '&.MuiFilledInput-root': {
      backgroundColor: `${theme.colors.neutral.lightBase}!important`,
      padding: `${theme.spacingInline.xxxs} 0 0`,
      '& .MuiFilledInput-notchedOutline': {
        borderColor: `${theme.colors.neutral.light20}!important`
      },
      '&.Mui-focused': {
        '& .MuiFilledInput-notchedOutline': {
          borderColor: `${theme.colorBrand.medium}!important`
        }
      }
    }
  },
  tooltip: {
    display: 'inline-block',
    '& svg': {
      alignSelf: 'center',
      color: theme.colorBrand.medium
    }
  }
}))
