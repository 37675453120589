import { Grid, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Button, Icon } from 'components/design-system'

interface IReadyProps {
  add: () => void
  dataTestid?: string
}

export const AddScheduleCard: React.FC<IReadyProps> = ({ add, dataTestid }) => {
  const theme = useTheme()
  return (
    <Button
      onClick={add}
      variant='outlined'
      data-testid={dataTestid}
      sx={{
        border: `1px dashed ${theme.colors.neutral.light30}`,
        width: '304px',
        position: 'relative',
        height: '353px !important',
        borderRadius: `${theme.borderRadius.sm} !important`,

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: '353px !important'
        }
      }}
    >
      <Grid container sx={{ background: theme.colorBrand.medium, borderRadius: theme.borderRadius.circular, justifyContent: 'center', alignItems: 'center', width: '56px', height: '56px' }}>
        <Icon size='large' iconColor={theme.colors.neutral.lightBase}>
          <AddIcon sx={{ fontSize: '24px' }} />
        </Icon>
      </Grid>
    </Button>
  )
}
