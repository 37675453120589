import { useTranslation } from 'react-i18next'

import { StatusSelector as StatusSelectorCommon } from 'components/common'
import { toast } from 'components/design-system/Toast/manager'

import { IContentUnitStatus } from 'services/types'

import useStyle from './styles'
import { updateContentUnitProgress } from 'services/content-unit'

interface IStatusSelectorProps {
  contentUnitId: number | string
  classId: number | string
  initialStatus: IContentUnitStatus
  setSelected: (selected: IContentUnitStatus) => void
}

export const StatusSelector = ({ contentUnitId, classId, initialStatus: selected = 'IDLE', setSelected }: IStatusSelectorProps) => {
  const { t } = useTranslation()

  const classes = useStyle()

  const setContentUnitStatus = async (status: IContentUnitStatus) => {
    const oldStatus = selected
    try {
      setSelected(status)

      const response = await updateContentUnitProgress(contentUnitId, classId, status)

      if (!response.success) {
        throw new Error('Erro ao atualizar status')
      }
    } catch (err) {
      setSelected(oldStatus)
      toast.handler({
        content: t('Ocorreu um erro ao tentar modificar o status da unidade de conteúdo. Tente novamente.'),
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const statusOptions = {
    IDLE: {
      title: t('Não iniciada')
    },
    INPROGRESS: {
      title: t('Em andamento')
    },
    DONE: {
      title: t('Concluída')
    }
  }

  const handleSelect = (selected: IContentUnitStatus) => {
    setContentUnitStatus(selected)
  }

  return (
    <StatusSelectorCommon
      initialStatus={selected}
      statusOptions={statusOptions}
      dataTestId={'home_content_status_content_unit'}
      onChange={(selected) => { handleSelect(selected as IContentUnitStatus) }}
      customContainerClassName={classes.container}
      customAuxPlaceholder={t('Situação')}
      openDirection='bottom-up'
    />
  )
}
