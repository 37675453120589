import { Grid } from '@mui/material'
import makeStyles from './styles'

interface IEmptyOrErrorMessageProps {
  image: string
  message: string
  reverse?: boolean
}

export const EmptyOrErrorMessage: React.FC<IEmptyOrErrorMessageProps> = ({
  image,
  message,
  reverse = false
}) => {
  const classes = makeStyles()

  return (
    <Grid container direction={reverse ? 'column-reverse' : 'column'} alignItems='center' justifyContent='center' rowSpacing={3} className={classes.container}>
      <Grid item xs={12} md>
        <div
          className={classes.message}
          dangerouslySetInnerHTML={{
            __html: message
          }}
        />
      </Grid>
      <Grid item xs={12} md>
        <div className={classes.image}>
          <img src={image} />
        </div>
      </Grid>
    </Grid>
  )
}

export default EmptyOrErrorMessage
