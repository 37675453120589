import { useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { Loading } from 'components/design-system'
import { useParams } from 'react-router-dom'
import { FeatureFlags } from 'components/feature-toggle'

const EnableFeatureToggle: React.FC = () => {
  const [stateMachine, setStateMachine] = useState<'loading' | 'featureEnable' | 'featureDisable' | 'notFound'>('loading')
  const { featureToggle } = useParams<{ featureToggle: string }>()

  const isEnableFeatureToggle = () => {
    const featureFlags = Object.values(FeatureFlags).map(v => v.toString())

    if (featureFlags.includes(featureToggle)) {
      const cloeEnabledFeatureToggles = JSON.parse(localStorage.getItem('cloe-web::enabled-features') ?? '[]') as string[]
      const foundFeatureToggle = cloeEnabledFeatureToggles.find(f => f === featureToggle)
      if (foundFeatureToggle) {
        setStateMachine('featureEnable')
      } else {
        setStateMachine('featureDisable')
      }
    } else {
      setStateMachine('notFound')
    }
  }

  useEffect(() => {
    if (featureToggle) {
      isEnableFeatureToggle()
    }
  }, [featureToggle])

  const enableFeatureToggle = () => {
    const cloeEnabledFeatureToggles = JSON.parse(localStorage.getItem('cloe-web::enabled-features') ?? '[]') as string[]
    localStorage.setItem('cloe-web::enabled-features', JSON.stringify([...cloeEnabledFeatureToggles, featureToggle]))
    setStateMachine('featureEnable')
  }

  const disableFeatureToggle = () => {
    const cloeEnabledFeatureToggles = JSON.parse(localStorage.getItem('cloe-web::enabled-features') ?? '[]') as string[]
    localStorage.setItem('cloe-web::enabled-features', JSON.stringify(cloeEnabledFeatureToggles.filter(f => f !== featureToggle)))
    setStateMachine('featureDisable')
  }

  const renderView = {
    loading: (
      <Grid container>
        <Grid xs={12} p={2}>
          <Loading type='circular' />
        </Grid>
      </Grid>
    ),
    featureEnable: (
      <Grid container textAlign='center'>
        <Grid xs={12} p={2} color='green' fontSize='20px'>
          Feature <i><b>{featureToggle}</b></i> habilitada!
        </Grid>
        <Grid xs={12} p={2}>
          <Button onClick={disableFeatureToggle} variant='contained'>
            Clique para desabilitar
          </Button>
        </Grid>
      </Grid>
    ),
    featureDisable: (
      <Grid container textAlign='center'>
        <Grid xs={12} p={2} color='#e69b00' fontSize='20px'>
          Feature <i><b>{featureToggle}</b></i> desabilitada!
        </Grid>
        <Grid xs={12} p={2}>
          <Button onClick={enableFeatureToggle} variant='contained'>
            Clique para habilitar
          </Button>
        </Grid>
      </Grid>
    ),
    notFound: (
      <Grid container>
        <Grid xs={12} p={2} color='red'>
          Feature <i><b>{featureToggle}</b></i> não encontrada!
        </Grid>
      </Grid>
    )
  }

  return renderView[stateMachine]
}

export default EnableFeatureToggle
