import { PrintOutlined } from '@mui/icons-material'
import { CircularProgress, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { Button } from 'components/design-system'
import { useState } from 'react'
import { printActivity, printContent, printInstructions } from 'services/print'
import { IApiResponse } from '../Content/SectionsIndex/types'
import { IPrintResponse } from 'services/types/print'

interface IProps {
  content: string
  section?: string | null
  mode?: string | null
  lng?: string | null
  activity?: string
  dataTestId?: string
  id?: string
  printType?: 'content' | 'instructions' | 'activity'
  className?: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  printButton: {
    padding: '12px !important',
    fontSize: '12px !important',
    minWidth: 'unset !important',
    height: '50px !important',
    border: `1px solid ${theme.colorBrand.medium} !important`
  }
}))

const PrintButton = (props: IProps) => {
  const { content, section, mode, lng, activity, dataTestId, id, printType, className: customClassName } = props

  const classes = useStyles()
  const [isLoadingPrint, setIsLoadingPrint] = useState(false)

  const print = async () => {
    setIsLoadingPrint(true)

    try {
      let response: IApiResponse<IPrintResponse>
      switch (printType) {
        case 'content':
          response = await printContent(content, section, mode, lng)
          break
        case 'instructions':
          response = await printInstructions(content, mode, lng)
          break
        case 'activity':
          response = await printActivity(content, activity ?? '', mode, lng)
          break
        default:
          response = await printContent(content, section, mode, lng)
          break
      }

      if (response?.success) {
        // abrir na nova aba a impressão
        const { data } = response
        const w = window.open(`${data.url}`, '_blank')
        if (w) w.focus()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingPrint(false)
    }
  }

  return (
    <Button
      data-testid={dataTestId}
      variant='ghost'
      disabled={isLoadingPrint}
      onClick={async () => await print()}
      id={id}
      className={`${classes.printButton} ${customClassName ?? ''}`}
    >
      {!isLoadingPrint ? <PrintOutlined /> : <CircularProgress size={16} />}
    </Button>
  )
}

export default PrintButton
