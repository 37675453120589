import MUICard from '@mui/material/Card'
import MUICardContent from '@mui/material/CardContent'
import useStyles from './style'

type CardState = 'hover' | 'focused' | 'selected'

export interface ICardProps {
  format: 'vertical' | 'horizontal' | 'horizontalMax'
  cardState?: CardState
  children: React.ReactNode
  extraClasses?: string
  extraClassesCardContent?: string
  selected?: boolean
  clickable?: boolean
  onClick?: any
}

export const Card: React.FC<ICardProps> = ({ children, extraClasses = '', extraClassesCardContent = '', format, selected, clickable, ...props }) => {
  const styles = useStyles()
  const clickableClass = clickable ? 'clickable' : ''
  const selectedClass = selected ? 'selected' : ''

  return (
    <MUICard
      className={`${styles.root} ${format} ${clickableClass} ${selectedClass} ${extraClasses}`}
      {...props}
      variant='outlined'
    >
      <MUICardContent className={extraClassesCardContent}>
        {children}
      </MUICardContent>
    </MUICard>
  )
}
