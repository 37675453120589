import dayjs from 'dayjs'
import { IFindStudentTimelineResponse } from 'services/types'
import { monthsTitle } from 'utils/date'

interface IHandleMilestoneMonthLabel {
  timelineData: IFindStudentTimelineResponse[]
  dateStart: string
  dateEnd: string
  isDropDownOpened: boolean
}
export const handleMilestoneMonthLabel = ({ timelineData, dateStart, dateEnd, isDropDownOpened }: IHandleMilestoneMonthLabel) => {
  const milestoneEndMonthNum = dayjs(dateEnd).month()
  const milestoneStartMonthNum = dayjs(dateStart).month()

  const monthLabelContainer: HTMLDivElement | null = document.querySelector(`#timeline-month-label-${milestoneStartMonthNum}`)

  if (!monthLabelContainer) return

  const startMonthRecords = timelineData.some(({ monthNum }) => monthNum === milestoneEndMonthNum)
  /**
   * IF the searched month doesnt exists, the records are inside the actual milestone
   */
  const endMonthLabelExists = startMonthRecords && milestoneEndMonthNum !== milestoneStartMonthNum
  if (endMonthLabelExists) {
    monthLabelContainer.style.display = isDropDownOpened ? 'none' : 'flex'
    return
  }

  const monthLabelComponent: HTMLSpanElement | null = document.querySelector(`#timeline-month-label-${milestoneStartMonthNum} span`)
  if (!monthLabelComponent) return

  const monthLabel = monthsTitle[isDropDownOpened ? milestoneEndMonthNum : milestoneStartMonthNum]
  monthLabelComponent.innerText = monthLabel
}
