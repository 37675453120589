import { styled } from '@mui/material/styles'

export const Wrapper = styled('div')(({ theme }) => ({
  padding: '6px 8px',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  // width: 'fit-content',
  width: '100%',
  gap: '8px',
  backgroundColor: theme.colorBrand.lightest
}))

export const FileName = styled('div')(({ theme }) => ({
  display: 'flex'
}))

export const Title = styled('div')(({ theme }) => ({
  fontSize: theme.font.fontSize.xxs,
  color: theme.colors.neutral.darkBase,
  overflowWrap: 'anywhere',

  // mobile
  [theme.breakpoints.down('sm')]: {
    maxWidth: '195px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

export const Extension = styled('span')(({ theme }) => ({
  fontSize: theme.font.fontSize.xxs,
  color: theme.colors.neutral.darkBase
}))

export const Close = styled('div')(({ theme }) => ({
  backgroundColor: theme.colorBrand.light,
  border: 'none',
  borderRadius: '50%',
  padding: '1px'
}))

export const VolumeUpContainer = styled('div')(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: theme.borderRadius.circular,
  background: theme.colorBrand.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))
