import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  pdf: {
    backgroundColor: theme.colors.neutral.light10,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '50rem',
    [theme.breakpoints.down('sm')]: {
      '&': {
        height: '30rem'
      }
    },
    '& iframe': {
      border: 'none',
      maxWidth: '75%',
      width: '75%',
      height: '100%',
      borderRadius: theme.borderRadius.sm,
      zIndex: 2
    },
    [theme.breakpoints.down('md')]: {
      '& iframe': {
        width: '100%',
        maxWidth: '100%'
      }
    }
  },
  movWarning: {
    width: '100%',
    marginTop: '10px'
  },
  contentDownload: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacingInline.xxxs,
    backgroundColor: theme.colorBrand.medium,
    color: theme.colors.neutral.lightBase,

    '& svg': {
      fill: theme.colors.neutral.lightBase,
      cursor: 'pointer'
    }
  }
}))
