import { useMediaQuery, useTheme, Grid } from '@mui/material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'

import { Button } from 'components/design-system'

interface IFileDownloadButtonProps {
  title: string
  fileUrl: string
  fileName: string
  dataTestId?: string
  onClick?: () => void
}

export const FileDownloadButton: React.FC<IFileDownloadButtonProps> = ({
  title,
  fileUrl,
  fileName,
  dataTestId,
  onClick
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid item xs={12} sm='auto' sx={{ '& a': { textDecoration: 'none' } }}>
      <a href={fileUrl} download={fileName} target='_blank' rel='noopener noreferrer'>
        <Button
          fullWidth={isMobile}
          startIcon={<DownloadOutlinedIcon sx={{ fontSize: '1.5rem !important' }} />}
          sx={{ height: '40px !important' }}
          data-testid={dataTestId}
          onClick={onClick}
        >
          {title}
        </Button>
      </a>
    </Grid>
  )
}
