import { IContentUnitResponse, IUserSchoolProfileTypeEnum, ISectionResponse } from 'services/types'
// import isProjectViewModeRule from 'rules/isProjectViewModeRule'

type allowedUsers = IUserSchoolProfileTypeEnum.student | IUserSchoolProfileTypeEnum.teacher

interface IFilterUserSectionsProps {
  content: IContentUnitResponse
  userType: allowedUsers
}

/**
 * Default sections filters
 */

const overviewFilter = (section: ISectionResponse) => {
  return section.section_type === 'overview'
}

const workbookFilter = (section: ISectionResponse) => {
  return section.section_type === 'workbook' && section.resources?.length && section.resources[0].medias?.length
}

const openingFilter = (section: ISectionResponse) => {
  return section.section_type === 'opening' && !section.only_for_teacher
}

const openingFilterTeacher = (section: ISectionResponse) => {
  return section.section_type === 'opening'
}

/**
 * Filter handler for default sections
 */

const filterSessionsByUserType = (section: ISectionResponse, userType: allowedUsers) => {
  const sectionFilter = userTypeSectionsFilter[userType]
  const availableTypes = Object.keys(sectionFilter)

  if (!availableTypes.includes(section.section_type)) {
    return false
  }

  const sectionType = section.section_type as keyof typeof sectionFilter
  return sectionFilter[sectionType](section)
}

/**
 * Exception section getters
 */

const getTriad = (content: IContentUnitResponse, isProjectViewMode: boolean) => {
  const instructionalExpedition = content?.instructional_contents.find(cur => cur.type === 'expedition')
  return instructionalExpedition && !isProjectViewMode ? instructionalExpedition.sections.find(cur => cur.section_type === 'triad') ?? null : null
}

/**
 * Section data modifier
 */

const enhanceSectionsData = (section: ISectionResponse) => {
  const sectionRaw = section

  if (section.section_type === 'overview') {
    sectionRaw.title = 'Introdução'
  }

  return sectionRaw
}

const userTypeSectionsFilter = {
  student: {
    // 'triad', is not present on common sections
    overview: overviewFilter,
    opening: openingFilter
  },
  teacher: {
    // 'triad', is not present on common sections
    overview: overviewFilter,
    workbook: workbookFilter,
    opening: openingFilterTeacher
  }
}

export const getUserSections = ({ content, userType }: IFilterUserSectionsProps): ISectionResponse[] => {
  /**
   * Triad by default will be evaluated at every user type
   * Because it's not on content?.sections
   */
  // const isProjectViewMode = isProjectViewModeRule(content?.grade?.grade_type ?? 0, content?.disciplines?.map(d => d.code) ?? []) // comentando pq isso aqui vai e volta sempre :)
  const triad = getTriad(content, false)

  /**
   * Filter by the allowed sections for each type of user
   */
  const filteredSections = content?.sections.filter(section => filterSessionsByUserType(section, userType))

  /**
   * Modify the sections data depending upon the user
   */
  const sectionsEnhanced = filteredSections.map(section => enhanceSectionsData(section))

  return [triad as ISectionResponse, ...sectionsEnhanced].filter(Boolean)
}
