import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  quickAccessCard: {
    position: 'relative',
    background: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.sm,
    boxShadow: theme.shadowLevels.level1,
    padding: theme.spacingSquish.nano,
    color: `${theme.colorBrand.dark} !important`,
    cursor: 'pointer !important',

    '&:hover': {
      transition: 'all ease-in-out 0.1s',
      transform: 'scale(1.04)'
    },

    minHeight: '136px',
    zIndex: 1
  },
  quickAccessCardContainer: {
    position: 'relative',
    width: '100%',
    flex: 1,

    [theme.breakpoints.up('md')]: {
      width: 'initial'
    }
  },
  quickAccessCardTitle: {
    color: `${theme.colorBrand.dark} !important`,
    cursor: 'pointer !important',
    textDecoration: 'none !important' as 'none'
  },
  quickAccessCardDescription: {
    cursor: 'pointer !important',
    color: `${theme.colorBrand.dark} !important`
  },
  quickAccessCardTitleContainer: {
    height: theme.font.fontSize.xxl,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacingInline.nano
  }
}))
