import React from 'react'
import { Grid } from '@mui/material'
import { isAfter, isBefore } from 'date-fns'
import { useAtom } from 'jotai'

import { IAnswerResponse, IQuestionResponse } from 'contentCacheManager'
import { Alternative } from 'pages/ContentUnit/components/Activity/common/InputTypeComponent/components/MultiChoice/Alternative'

import useStyles from './style'
import AnsweredFile from 'pages/ContentUnit/components/Activity/common/InputTypeComponent/components/Record/AnsweredFile'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

interface IStudentAnswerProps {
  answer?: IAnswerResponse & { question: IQuestionResponse }
}

export const StudentAnswer: React.FC<IStudentAnswerProps> = ({ answer }) => {
  const classes = useStyles()
  const [activity] = useAtom(activityCurrentAtom)

  const render = () => {
    if (!answer && activity?.schedule?.endDate && isBefore(new Date(), new Date(activity?.schedule?.endDate))) return <Grid item display='flex' className={classes.studentAnswerContentNoAnswer}>(Resposta pendente)</Grid>
    if (!answer && activity?.schedule?.endDate && isAfter(new Date(), new Date(activity?.schedule?.endDate))) return <Grid item display='flex' className={classes.studentAnswerContentNoAnswer}>(Resposta não enviada)</Grid>

    if (answer?.question?.input_type === 'multi_choice') {
      const alternative = answer?.question?.alternatives.find(alternative => alternative.id === parseInt(answer.records?.[0]?.choice_record?.alternative_id ?? '0'))
      return alternative && <Alternative alternative={alternative} isWrong={alternative?.level !== 100} disabled isActive={true} isCorrect={alternative?.level === 100} />
    }

    return (
      <Grid className={classes.studentAnswerContentTextResource}>
        <span>{answer?.records[0]?.text_record?.text ?? ''}</span>
      </Grid>
    )
  }

  return (
    <Grid container display='flex' flexDirection='column' justifyContent='space-between' className={classes.studentAnswerContainer}>
      {render()}
      <Grid container mt={4} spacing={2}>
        {
          answer?.records[0]?.file_records?.map((mock, i) =>
            <Grid item key={i} xs={6} sm={3}>
              <AnsweredFile src={mock.url} />
            </Grid>)
        }
      </Grid>
    </Grid>
  )
}

export default StudentAnswer
