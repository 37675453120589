/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Grid, Skeleton } from '@mui/material'

import useStyle from './styles'

export const ParentalMessageLoading: React.FC = () => {
  const classes = useStyle()

  return (
    <>
      <Grid container flexDirection='column'>
        <Skeleton variant='text' width={200} height={60} animation='wave' />

        <Skeleton variant='text' sx={{ marginBottom: '-30px' }} width={360} height={60} animation='wave' />

        <Skeleton variant='text' sx={{ width: '100%' }} height={200} animation='wave' />

      </Grid>
    </>
  )
}
