import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAuth } from 'navigation/ProvideAuth'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import BaseAuthLayout from 'pages/BaseAuthLayout'
import { LOGIN, ROOT } from 'navigation/CONSTANTS'
import { Formik, Form, FormikErrors } from 'formik'
import { IUserRegisterFlowRequest, IUserTokenResponse } from 'services/types'
import {
  ConfirmationSchoolData,
  CompleteRegistration,
  DocumentRegisteredModal,
  RegistrationCompleted,
  SchoolToken
} from './components'
import { getUserByCpf, userRegister, userTokenValidation } from 'services/users'
import { RegisterSchema } from 'schemas/yup/register'
import { ConfirmationModal } from 'components/common'
import { useTranslation } from 'react-i18next'

interface ILocation {
  from: {
    pathname: string
  }
}

export function Register(): JSX.Element {
  dayjs.extend(customParseFormat)

  const auth = useAuth()
  const history = useHistory()
  const location = useLocation<ILocation>()
  const { from } = location.state || { from: { pathname: '/' } }
  const { t } = useTranslation()

  // states
  const [step, setStep] = useState<number>(0)
  const [lastStep, setLastStep] = useState<boolean>(false)
  const [completeRegistration, setCompleteRegistration] = useState<boolean>(false)
  const [openDocumentRegisteredModal, setOpenDocumentRegisteredModal] = useState<boolean>(false)
  const [openCancelRegistrationModal, setOpenCancelRegistrationModal] = useState<boolean>(false)
  const [jwtToken, setJwtToken] = useState<string>('')
  const [userToken, setUserToken] = useState<IUserTokenResponse>()
  const [username, setUsername] = useState('')
  const [tempPassword, setTempPassword] = useState('')
  const [cpf, setCpf] = useState<number>(0)

  const handleSubmit = () => setCompleteRegistration(true)

  const handleOpenCancelRegistrationModal = () => setOpenCancelRegistrationModal(true)

  const handleCloseDocumentRegisteredModal = () => setOpenDocumentRegisteredModal(false)

  const initialValues = [
    {
      token: ''
    },
    {},
    {
      cpf: '',
      name: '',
      surname: '',
      birthDate: '',
      email: '',
      phone: ''
    }
  ]

  const sendRecoverLink = async (cpf: number) => {
    const userResponse = await getUserByCpf(cpf)
    await auth?.forgotPassword(
      userResponse.data[0].email,
      String(userResponse.data[0].phone).replace(/\D/g, ''),
      userResponse.data[0].username
    )
  }

  useEffect(() => {
    if (cpf) {
      sendRecoverLink(cpf)
    }
  }, [cpf])

  type ISetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void

  const renderStep = (dirty: boolean, isSubmitting: boolean, setFieldValue: ISetFieldValue, values?: any, errors?: FormikErrors<any>) => {
    const documentRegistered = errors?.cpf === 'CPF já cadastrado'
    const document = Number(values?.cpf?.trim().replace(/\D/g, ''))
    if (document && documentRegistered && isSubmitting) {
      setCpf(document)
      setOpenDocumentRegisteredModal(true)
    }

    switch (step) {
      case 0:
        return (
          <SchoolToken
            dirty={dirty}
            tokenError={!!errors?.token}
            token={values?.token ?? ''}
            onCancel={handleOpenCancelRegistrationModal}
            setFieldValue={setFieldValue}
          />
        )
      case 1:
        return (
          <ConfirmationSchoolData
            onCancel={handleOpenCancelRegistrationModal}
            previousStep={() => {
              setStep(prevStep => prevStep - 1)
            }}
            schoolName={userToken?.school.name ?? ''}
            token={userToken?.token ?? ''}
            type={userToken?.type}
          />
        )
      case 2:
        return (
          <CompleteRegistration
            dirty={dirty}
            onCancel={handleOpenCancelRegistrationModal}
            onSubmit={handleSubmit}
            previousStep={() => {
              setStep(prev => prev - 1)
              setCompleteRegistration(false)
            }}
            errors={errors}
          />
        )
      case 3:
        setLastStep(true)
        return (
          <RegistrationCompleted
            username={username}
            tempPassword={tempPassword}
            onCancel={handleOpenCancelRegistrationModal}
          />
        )
      default:
        history.replace(ROOT)
    }
  }

  return (
    <BaseAuthLayout>
      <Formik
        initialValues={initialValues[step]}
        validationSchema={RegisterSchema[step]}
        validateOnChange={step !== 2}
        onSubmit={async (values) => {
          if (completeRegistration && userToken) {
            const userRegisterPayload: IUserRegisterFlowRequest = {
              name: values.name?.trim() ?? '',
              surname: values.surname?.trim() ?? '',
              birth_date: dayjs(values.birthDate, 'DD/MM/YYYY')
                .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              email: values.email?.trim() ?? '',
              phone: Number(values.phone?.replace(/\D/g, '')),
              cpf: Number(values.cpf?.trim().replace(/\D/g, '')),
              token: userToken.token,
              schoolId: userToken.school.id,
              profileType: userToken.type
            }
            const response = await userRegister(userRegisterPayload)
            if (response.success) {
              setJwtToken(response.data.jwt)
              setUsername(response.data.user.username)
              setTempPassword(response.data.user.password)
              setStep(step + 1)
            }

            setCompleteRegistration(false)
          } else if (!lastStep) {
            if (step === 0 && values.token) {
              const response = await userTokenValidation(values.token)
              setUserToken(response.data)
            }
            setStep(step + 1)
          } else {
            const response = await auth?.registerSignin(jwtToken)
            if (response?.success) {
              history.replace(from.pathname !== LOGIN ? from : { pathname: ROOT })
            }
          }
        }}
      >
        {({ dirty, errors, isSubmitting, setFieldValue, values }) => (
          <Form>
            {renderStep(dirty, isSubmitting, setFieldValue, values, errors)}
          </Form>
        )}
      </Formik>
      <DocumentRegisteredModal
        open={openDocumentRegisteredModal}
        onClose={handleCloseDocumentRegisteredModal}
      />
      <ConfirmationModal
        onConfirmModal={() => history.replace(LOGIN)}
        onCloseModal={() => setOpenCancelRegistrationModal(false)}
        isOpen={openCancelRegistrationModal}
        title={t('Tem certeza que deseja sair?')}
        description={t('As informações preenchidas serão descartadas.')}
        confirmDatatestId='autonomous_registration_registration_close_closer'
        cancelDatatestId='autonomous_registration_registration_closer_back'
      />
    </BaseAuthLayout>
  )
}

export default Register
