
import { createRef } from 'react'
import { Grid, useTheme, useMediaQuery } from '@mui/material'
import { Close, KeyboardArrowLeft } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import OnlyProfile from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'

import useStyles from '../../style'
import { CustomHeaderProps } from '../../types'

export const CustomHeader = ({ currentStep, maxStep, isError, onClose }: CustomHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const inputRef = createRef<HTMLDivElement>()

  return (
    <>
      <Grid className={classes.infoCountStep}>
        {!isMobile && !isError && currentStep <= maxStep && <span>{currentStep} de {maxStep}</span>}

        {currentStep > maxStep && !isMobile && (
          <Grid item
            onClick={() => {
              inputRef?.current?.click()
              onClose()
            }}
            sx={{
              cursor: 'pointer',
              '& svg': {
                fill: theme.colors.neutral.light40,
                fontSize: theme.font.fontSize.xl
              }
            }}
          >
            <Close />
            <div ref={inputRef} data-testid='qr_code_do_not_access_activity' />
          </Grid>
        )}
      </Grid>

      {isMobile && !isError && (
        <Grid display='flex' flexDirection='row' justifyContent='space-between' className={classes.infoCountStepMobile}>
          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
            <Grid item data-testid='qr_code_back_to_schedule' onClick={onClose} sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              cursor: 'pointer',
              '& svg': {
                fill: theme.colorBrand.dark,
                fontSize: theme.font.fontSize.xl
              }
            }}>
              <KeyboardArrowLeft />
              {t('Voltar para agenda')}
            </Grid>
          </OnlyProfile>

          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
            <Grid item data-testid='qr_code_back_to_class' onClick={onClose} sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              cursor: 'pointer',
              '& svg': {
                fill: theme.colorBrand.dark,
                fontSize: theme.font.fontSize.xl
              }
            }}>
              <KeyboardArrowLeft />
              {t('Voltar para turma')}
            </Grid>
          </OnlyProfile>

          {currentStep <= maxStep && <span>{currentStep} de {maxStep}</span>}
        </Grid>
      )}

      {isError && isMobile && <Grid width={1} gap={1} />}
    </>
  )
}
