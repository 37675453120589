import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'

import { Grid, useTheme } from '@mui/material'
import { InputTime, InputDate } from 'components/common'
import { Tooltip } from 'components/common/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { scheduleAtom, updateEndDateAtom, updateStartDateAtom, isHoursAndMinutesNowAtom, contentSelectAtom, isInvalidStartDateAtom, isInvalidEndDateAtom } from '../atomStore'

import useStyles from './style'

import { ContentTypeEnum } from 'services/types/content'
import { setToLocaleUTC } from 'utils/date'

interface ITimePickerToolbarProps {
  setOpenPicker: React.Dispatch<React.SetStateAction<boolean>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
}

interface IDateSelectProps {
  isMaterialVision?: boolean
}

export const DateSelect = ({ isMaterialVision = false }: IDateSelectProps) => {
  const [isHoursAndMinutesNow, setHoursAndMinutesToNow] = useAtom(isHoursAndMinutesNowAtom)
  const [, setEndDate] = useAtom(updateEndDateAtom)
  const [, setStartDate] = useAtom(updateStartDateAtom)
  const [schedule] = useAtom(scheduleAtom)
  const [contentSelect] = useAtom(contentSelectAtom)
  const [, setIsStartDateInvalid] = useAtom(isInvalidStartDateAtom)
  const [, setIsEndDateInvalid] = useAtom(isInvalidEndDateAtom)
  const { schoolPeriod } = useStore()
  const minDateSchoolPeriod = schoolPeriod ? new Date(setToLocaleUTC(schoolPeriod?.start_date)) : undefined
  const maxDateSchoolPeriod = schoolPeriod ? new Date(setToLocaleUTC(schoolPeriod?.end_date)) : undefined

  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()

  const startDate = useMemo(() => schedule?.start_date ?? dayjs().toDate(), [schedule?.start_date])
  const endDate = useMemo(() => schedule?.end_date ?? null, [schedule?.end_date])

  const defaultVisionDataTestIds = {
    startDate: 'event_without_expedition_event_start_date',
    startTime: 'event_without_expedition_event_start_time'
  }

  const materialVisionDataTestIds = {
    startDate: 'teacher_materials_add_start_date',
    startTime: 'teacher_materials_add_start_time'
  }

  const dataTestIds = isMaterialVision ? materialVisionDataTestIds : defaultVisionDataTestIds

  const updateHourAndMinute = (newDate: dayjs.Dayjs, previousDate: Date | undefined) => {
    return dayjs(previousDate).set({
      hour: newDate.hour(),
      minute: newDate.minute()
    })
  }

  const updateDayMonthYear = (newDate: dayjs.Dayjs, previousDate: Date | undefined) => {
    return dayjs(previousDate).set({
      year: newDate.year(),
      month: newDate.month(),
      date: newDate.date()
    })
  }

  const getUpdatedFormattedDate = (newDate: Date, previousDate: Date | undefined | 'now', updateType: 'day' | 'hour-minute') => {
    // componente de hora em alguns cenários com causa incerta não atualiza valor interno de data, apenas de hora.
    // por isso foram separadas responsabilidades de alteração de apenas suas partes respectivas da data para cada componente de hora e data.
    if (previousDate === 'now') return

    const unformattedDate = dayjs(newDate)
    if (updateType === 'hour-minute') {
      return updateHourAndMinute(unformattedDate, previousDate).toDate()
    } else {
      return updateDayMonthYear(unformattedDate, previousDate).toDate()
    }
  }

  const isShowEndDate = () => {
    return (contentSelect ?? []).some(content => content.type && [ContentTypeEnum.activity, ContentTypeEnum.evaluation].includes(content.type))
  }

  const handleChangeStartDate = useCallback((newDate: Date | null, updateType: 'day' | 'hour-minute') => {
    if (!newDate || !dayjs(newDate).isValid()) return

    const formattedNewDate = getUpdatedFormattedDate(newDate, schedule?.start_date, updateType)
    setHoursAndMinutesToNow(false)
    setStartDate(formattedNewDate)
  }, [schedule])

  const handleChangeEndDate = useCallback((newDate: Date | null, updateType: 'day' | 'hour-minute') => {
    if (!newDate || !dayjs(newDate).isValid()) return

    const formattedNewDate = getUpdatedFormattedDate(newDate, schedule?.end_date, updateType)
    setEndDate(formattedNewDate)
  }, [schedule])

  const TimePickerToolbar: React.FC<ITimePickerToolbarProps> = ({ setOpenPicker, setInputValue }) => (
    <p
      data-testid='release_immediately_now'
      className={classes.nowButton}
      onClick={() => {
        setStartDate('now')
        setOpenPicker(false)
        setHoursAndMinutesToNow(true)
        setInputValue('')
      }}
    >
      Agora
    </p>
  )

  return (
    <>
      <Grid
        container
        sx={{
          marginTop: '30px',
          padding: theme.spacingSquish.nano,
          paddingY: '24px',
          borderTop: `solid 1px ${theme.colors.neutral.light10}`,
          borderBottom: `solid 1px ${theme.colors.neutral.light10}`,
          display: 'grid',
          gridTemplateColumns: '1fr'
        }}
        rowGap={'16px'}
      >
        <Grid
          item
          sx={{
            display: 'grid',
            alignContent: 'center',
            gridTemplateColumns: 'auto auto',
            columnGap: theme.spacingInset.xs
          }}
        >
          <span className={classes.infoText}>
            {t('Qual a data e horário que os alunos utilizarão esta instrução?')}
          </span>
          <Tooltip
            component={
              <Grid
                item
                sx={{
                  height: '100%',
                  display: 'flex',
                  '& svg': {
                    color: theme.colorBrand.medium
                  }
                }}
              >
                <InfoOutlinedIcon
                  style={{
                    alignSelf: 'center'
                  }}
                />
              </Grid>
            }
            text={t('A data de início é quando o evento será mostrado na agenda dos estudantes.')}
          />
        </Grid>
        <Grid item display={'grid'} rowGap={'16px'}>
          <span className={classes.dateLabel}>{t('Início')}</span>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <InputDate
                label={t('Dia')}
                initialDate={startDate === 'now' ? dayjs().toDate() : startDate}
                minDate={minDateSchoolPeriod}
                maxDate={maxDateSchoolPeriod}
                onChangeDate={(date) => handleChangeStartDate(date, 'day')}
                updateHoursMinutes={false}
                dataTestid={dataTestIds.startDate}
                onError={reason => {
                  if (!reason || reason === 'minDate') {
                    setIsStartDateInvalid(false)
                    return
                  }
                  if (reason === 'invalidDate') setIsStartDateInvalid(true)
                }}
                forceDateRefresh
              />
            </Grid>
            <Grid item xs={6}>
              <InputTime
                label={t('Horário')}
                onChange={(date) => handleChangeStartDate(date, 'hour-minute')}
                inputTime={
                  isHoursAndMinutesNow
                    ? null
                    : startDate === 'now' ? dayjs().toDate() : startDate
                }
                dataTestid={dataTestIds.startTime}
                defaultTimeIsNow
                ToolbarComponent={TimePickerToolbar}
              />
            </Grid>
          </Grid>
        </Grid>
        {isShowEndDate() && (
          <Grid item display={'grid'} rowGap={'16px'}>
            <span className={classes.dateLabel}>{t('Fim')}</span>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <InputDate
                  label={t('Dia')}
                  onChangeDate={(date) => handleChangeEndDate(date, 'day')}
                  initialDate={endDate}
                  minDate={minDateSchoolPeriod}
                  maxDate={maxDateSchoolPeriod}
                  updateHoursMinutes={false}
                  dataTestid='event_with_expedition_event_end_date'
                  onError={reason => {
                    if (!reason || reason === 'minDate') {
                      setIsEndDateInvalid(false)
                      return
                    }
                    if (reason === 'invalidDate') setIsEndDateInvalid(true)
                  }}
                  forceDateRefresh
                />
              </Grid>
              <Grid item xs={6}>
                <InputTime
                  label={t('Horário')}
                  onChange={(date) => handleChangeEndDate(date, 'hour-minute')}
                  inputTime={endDate}
                  dataTestid='event_with_expedition_event_end_time'
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}
