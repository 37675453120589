export interface IGradeResponse {
  id: string | number
  name: string
  code: string
  grade_type: IGradeTypeEnum
}

export enum IGradeTypeCodeEnum {
  EI = 'EI',
  EF1 = 'EF1',
  EF2 = 'EF2',
  EM = 'EM',
  EM_IT = 'EM_IT',
  multigrade = 'multigrade'
}

export enum IGradeTypeEnum {
  EI = 1,
  EF1 = 2,
  EF2 = 3,
  EM = 4,
  EM_IT = 5,
  multigrade = 6
}
