import { ISchoolPeriodResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { useLocalStore } from './localProfileStore'
import { useSessionStore } from './sessionProfileStore'
import { useLocalPreferencesStore } from './preferencesStore'
import Analytics from 'utils/analytics'

export const useStore = () => {
  const localStore = useLocalStore()
  const sessionStore = useSessionStore()
  const preferencesStore = useLocalPreferencesStore()

  const isStudentProfile = localStore.profile?.type === IUserSchoolProfileTypeEnum.student

  const setSchoolPeriod = (schoolPeriod: ISchoolPeriodResponse | null) => {
    Analytics.setDefaultAttributes({
      period_id: schoolPeriod?.id,
      period_status: schoolPeriod?.current ?? false,
      period_title: schoolPeriod?.title
    })
    sessionStore.setSchoolPeriod(schoolPeriod)
    localStore.setSchoolPeriod(schoolPeriod)
  }

  const schoolPeriod = isStudentProfile ? localStore.schoolPeriod : sessionStore.schoolPeriod

  return ({
    ...localStore,
    ...sessionStore,
    ...preferencesStore,
    schoolPeriod,
    setSchoolPeriod
  })
}

// TODO: trocar a ordem dos middlewares do zustand https://github.com/pmndrs/zustand
// https://github.com/pmndrs/zustand/blob/9277cff13a0d1b513907b39c1c25b22232e1a83d/tests/middlewareTypes.test.tsx >> ver teste linha 411
// export const useStore = create(
//   devtools(subscribeWithSelector(
//     persist<
//     StoreProps,
//     SetState<StoreProps>,
//     GetState<StoreProps>,
//     StoreApiWithSubscribeWithSelector<StoreProps> &
//     StoreApiWithPersist<StoreProps>
//     >(
//       (set) => ({
//         session: null,
//         setUserSession: (session: IUserSessionResponse | null) =>
//           set({ session }),
//         subscription: null,
//         setSubscription: (subscription: ISubscriptionsSessionResponse | null) =>
//           set({ subscription })
//       }),
//       { name: 'cloe::web::props', version: 1, getStorage: () => localStorage }
//     )
//   ))
// )

export default useStore
