export interface ILevelResponse {
  id: number
  name: string
  value: number
  description: string
  created_at: string
  updated_at: string
}

// TODO: [URGENTE] tirar isso e usar a api
export const getLevelIdByValue = (value: string) => {
  switch (value) {
    case '100':
      return 5
    case '75':
      return 4
    case '50':
      return 3
    case '25':
      return 2
    default:
      return 1
  }
}
