import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import { Alert, Button } from 'components/design-system'

export interface IErrorSkillTaxonomiesProps {
  tryAgainCallback?: () => void
}

export const ErrorSkillTaxonomies: React.FC<IErrorSkillTaxonomiesProps> = ({
  tryAgainCallback
}) => {
  const { t } = useTranslation()

  const ActionButton = () => (
    <Button
      className='actionButton'
      variant='ghost'
      size='small'
      data-testid='followup_reload'
      onClick={tryAgainCallback}
    >
      {t('Recarregar')}
    </Button>
  )

  return (
    <Grid item xs={12} lg={3}>
      <Grid
        item
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='stretch'
      >
        <Grid container>
          <Grid item xs={11.7} mr={1}>
            <Alert
              content={t('Falha no carregamento das Habilidades. Aguarde alguns instantes e tente recarregar esta seção.')}
              outlined={true}
              severity='error'
              actionButton={<ActionButton />}
              sx={{ alignItems: 'center', '& .MuiAlert-action': { padding: '0px' } }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ErrorSkillTaxonomies
