import { styled } from '@mui/material/styles'

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacingInline.xxxs,
  border: '1px solid #CCCCCC',
  justifyContent: 'flex-start',
  borderRadius: '16px',
  width: '100%',

  // mobile
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacingInline.xxxs
  }
}))

export const Title = styled('div')(({ theme }) => ({
  fontSize: theme.font.fontSize.sm,
  color: theme.colors.neutral.dark30,
  textAlign: 'left'
}))

export const PreviewArea = styled('div')(({ theme }) => ({
  margin: `${theme.spacingInline.xxs} 0`,
  width: '100%',
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap'
}))

export const Percentage = styled('div')(({ theme }) => ({
  fontSize: theme.font.fontSize.xxs,
  color: theme.colors.neutral.light40,
  textAlign: 'left'
}))
