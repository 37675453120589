import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLastUpdatedDate } from 'services/cloe-reports'
import { useUpdateAtom } from 'jotai/utils'
import { toast } from 'components/design-system/Toast/manager'
import { lastUpdatedDateAtom } from '../atomStore'

export const useReport = () => {
  const { t } = useTranslation()
  // atoms
  const setLastUpdatedDate = useUpdateAtom(lastUpdatedDateAtom)

  // states
  const [isLoadingDate, setIsLoadingDate] = useState<boolean>(false)

  const getUpdatedDate = async () => {
    try {
      setIsLoadingDate(true)
      const response = await getLastUpdatedDate()

      if (response?.success) {
        setLastUpdatedDate(response?.data?.lastUpdated)
      } else {
        throw new Error(t('Erro ao obter a data de atualização'))
      }
    } catch (e) {
      if (e instanceof Error) {
        toast.handler({
          content: e.message,
          duration: 3000,
          severity: 'error'
        })
      }
    } finally {
      setIsLoadingDate(false)
    }
  }

  return {
    getUpdatedDate,
    isLoadingDate
  }
}
