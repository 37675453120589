import { useRouter } from 'navigation/hooks/useRouter'
import React, { useEffect, useState } from 'react'
import { useCurricularStore } from 'store'

enum ClassMiddlewareStateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY'
}

export const ClassMiddleware: React.FC = ({ children }) => {
  const [stateMachine, setStateMachine] = useState<ClassMiddlewareStateMachineEnum>(ClassMiddlewareStateMachineEnum.IDLE)
  const router = useRouter()
  const classId = router.query?.classId

  const { setStoreDisciplines } = useCurricularStore()

  const states = {
    IDLE: <></>,
    LOADING: <></>,
    READY: <>{children}</>
  }

  useEffect(() => {
    if (!classId) {
      setStateMachine(ClassMiddlewareStateMachineEnum.READY)
      return
    }

    const revalidateClasses = async () => {
      setStateMachine(ClassMiddlewareStateMachineEnum.LOADING)
      let theClass: number | null = null
      if (classId) theClass = parseInt(classId)

      await setStoreDisciplines({ theClass })

      setStateMachine(ClassMiddlewareStateMachineEnum.READY)
    }

    revalidateClasses()
  }, [classId])

  return states[stateMachine]
}

export default ClassMiddleware
