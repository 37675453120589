import { IApiResponse, IProfilesSessionResponse, ISchoolPeriodResponse, ISubscriptionsSessionResponse, IUpdateLastUsedProfileRequest, IUserLoginResponse, IUserMeResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { userLogin, getSession, userMe, userForgotPassword, userResetPassword, userChangeDefaultPassword } from 'services/users'
import { loginWithTemporaryAccess } from 'services/temporary-access'
import { useState } from 'react'
import Storage from 'utils/storage'
import { useStore, useCurricularStore } from 'store'
import { CHANGE_DEFAULT_PASSWORD, CLOE_ADMIN, LOGIN, NO_ENABLED_SCHOOL_FOUND, NO_PROFILE_FOUND, NO_SCHOOL_PERIOD_FOUND, ROOT, TERMS_ACCEPTANCE } from 'navigation/CONSTANTS'
import { redirectLinkAtom, windowLoadingAtom } from 'navigation/atomStore'
import { useAtom } from 'jotai'
import Analytics from 'utils/analytics'
import { useLocation } from 'react-router-dom'
import { getPublicRoutes } from './routes/config/publicRoutes'
import { useUpdateAtom } from 'jotai/utils'
import { updateLastUsedProfile as updateLastUsedProfileService } from 'services/user-school-profile'
import { toast } from 'components/design-system/Toast/manager'
import { loginWithJwt } from 'services/login'
import { SSOErrorsAtom } from 'pages/atomStore'
import axios from 'axios'

interface IOptionsSetSession {
  eventDispatch?: boolean
}

interface ISubscriptionAnalytics {
  grade_id?: string
  grade_name?: string
  grade_code?: string
  segment_id?: string
  segment_name?: string
  segment_code?: string
}

export function useProvideAuth() {
  const { subscription, profile, schoolPeriod, setUserSession, setSubscription, setProfile, setSchoolPeriod } = useStore()
  const { classId, reset: resetCurricularStore } = useCurricularStore()
  const location = useLocation()
  const { pathname, search } = window.location

  // states
  const [user, setUser] = useState<IUserMeResponse | null>(null)

  // atoms
  const setSSOErrors = useUpdateAtom(SSOErrorsAtom)
  const setWindowLoading = useUpdateAtom(windowLoadingAtom)
  const [redirectLink, setRedirectLink] = useAtom(redirectLinkAtom)

  const currentClassId = classId && location.pathname.includes(String(classId)) ? classId : undefined

  const source = axios.CancelToken.source()

  const registerSignin = async (jwtToken: string) => {
    Storage.token.set(jwtToken)
    const response = await currentUser()

    if (response?.data?.isDefaultPass && response?.data?.accept_terms) {
      setWindowLoading(true)
      window.location.href = CHANGE_DEFAULT_PASSWORD
    }

    await setSession(true, { eventDispatch: true })

    Analytics.setup({
      username: response?.data?.username ?? '',
      attributes: {
        username: [response?.data?.username],
        userId: [response?.data?.id],
        origin: ['cloe']
      }
    })

    return response
  }

  const handleChangeDefaultPassword = async (response: IApiResponse<IUserLoginResponse>) => {
    if (response?.data?.user?.isDefaultPass && response?.data?.user?.accept_terms) {
      setWindowLoading(true)
      await source.cancel('Request canceled')
      window.location.href = CHANGE_DEFAULT_PASSWORD
    }
  }

  const signin = async (identifier: string, password: string) => {
    const response = await userLogin(identifier, password)
    if (response.success) {
      Storage.token.set(response.data.jwt)
      setUser(response.data.user)

      await handleChangeDefaultPassword(response)

      await setSession(true, { eventDispatch: true })

      Analytics.setup({
        username: response?.data?.user?.username,
        attributes: {
          username: [response?.data?.user?.username],
          userId: [response?.data?.user?.id],
          origin: ['cloe']
        }
      })
    } else {
      await clean()
    }

    return response
  }

  const temporaryAccess = async (token: string) => {
    const response = await loginWithTemporaryAccess(token)
    if (response.success) {
      Storage.token.set(response.data.jwt)
      setUser(response.data.user)
      await setSession(true)
      Analytics.setup({
        username: response?.data?.user?.username,
        attributes: {
          username: [response?.data?.user?.username],
          userId: [response?.data?.user?.id],
          origin: ['cloe']
        }
      })
    } else {
      await clean()
    }
    return response
  }

  const getCloeAllowedProfiles = (profiles: IProfilesSessionResponse[]) => profiles
    ?.filter(cur =>
      [IUserSchoolProfileTypeEnum.student, IUserSchoolProfileTypeEnum.teacher].includes(cur.type))

  const getAdminAllowedProfiles = (profiles: IProfilesSessionResponse[]) => profiles
    ?.filter(cur => [IUserSchoolProfileTypeEnum.admin, IUserSchoolProfileTypeEnum.coordinator].includes(cur.type))

  const setSessionAnalytics = (profileLocal: IProfilesSessionResponse | null, subscriptionAnalytics: ISubscriptionAnalytics) => {
    Analytics.recordEventClick({
      name: 'set_session',
      attributes: {
        profile_type: profileLocal?.type,
        school_id: profileLocal?.school?.id,
        user_id: profileLocal?.user,
        ...subscriptionAnalytics
      }
    })
  }

  const analyticsRecordEventClick = (profileLocal: IProfilesSessionResponse | null, subscriptionAnalytics: ISubscriptionAnalytics, eventDispatch: boolean) => {
    if (eventDispatch) {
      Analytics.recordEventClick({
        name: 'login',
        attributes: {
          origin: 'cloe',
          profile_type: profileLocal?.type,
          school_id: profileLocal?.school?.id,
          user_id: profileLocal?.user,
          ...subscriptionAnalytics
        }
      })
    }
  }

  const termsAcceptanceRedirect = async (cloeAllowedProfiles: IProfilesSessionResponse[], hasCloeProfiles: boolean, hasAdminProfiles: boolean) => {
    if (hasCloeProfiles && hasAdminProfiles) {
      await updateLastUsedProfile(cloeAllowedProfiles[0].id)
      setProfile(cloeAllowedProfiles[0])
    } else if (!hasCloeProfiles && hasAdminProfiles) {
      window.location.href = TERMS_ACCEPTANCE
    }

    const studentProfile = cloeAllowedProfiles.find(p => p.type === IUserSchoolProfileTypeEnum.student)
    const teacherProfile = cloeAllowedProfiles.find(p => p.type === IUserSchoolProfileTypeEnum.teacher)
    if (hasCloeProfiles && !hasAdminProfiles && (studentProfile && teacherProfile)) {
      await updateLastUsedProfile(teacherProfile.id)
      setProfile(teacherProfile)
    }
  }

  const adminRedirect = (hasCloeProfiles: boolean, hasAdminProfiles: boolean, lastUsedProfile: IProfilesSessionResponse | undefined) => {
    const lastUsedIsAdminProfile = lastUsedProfile
      ? [IUserSchoolProfileTypeEnum.admin, IUserSchoolProfileTypeEnum.coordinator].includes(lastUsedProfile.type)
      : false
    if ((!hasCloeProfiles && hasAdminProfiles) || lastUsedIsAdminProfile) {
      window.location.href = CLOE_ADMIN
    }
  }

  const noEnabledSchoolRedirect = (enabledSchoolProfiles: IProfilesSessionResponse[], profiles: IProfilesSessionResponse[]) => {
    const shouldBlock = enabledSchoolProfiles.length === 0 && profiles.length !== 0
    if (!shouldBlock) return

    // esse cara não tem acesso a nenhum perfil vinculado à uma escola ativa
    signout()
    window.location.href = NO_ENABLED_SCHOOL_FOUND
  }

  const noProfileFoundRedirect = (cloeAllowedProfiles: IProfilesSessionResponse[], hasCloeProfiles: boolean, hasAdminProfiles: boolean, profileLocal: IProfilesSessionResponse | null) => {
    // IS STUDENT?
    const doesHaveTeacherProfile = cloeAllowedProfiles.map(el => el.type).includes(IUserSchoolProfileTypeEnum.teacher)
    if ((!hasCloeProfiles || (!profileLocal && !doesHaveTeacherProfile)) && !hasAdminProfiles) {
      // esse cara não tem nenhum perfil
      signout()
      window.location.href = NO_PROFILE_FOUND
    }
  }

  const noSchoolPeriodFoundRedirect = (hasAdminProfiles: boolean, availableSchoolPeriods: ISchoolPeriodResponse[], foundSubscriptionSchoolProfile: boolean, subscriptionLocal: ISubscriptionsSessionResponse | null) => {
    if ((!availableSchoolPeriods.length || !foundSubscriptionSchoolProfile) && !hasAdminProfiles && subscriptionLocal) {
      // esse cara não tem nenhum periodo escolar cadastrado, é necessario que a escola configure os periodos
      signout()
      window.location.href = NO_SCHOOL_PERIOD_FOUND
    }
  }

  const updateLastUsedProfile = async (profileId: number) => {
    try {
      const updateLastUsedProfileRequest: IUpdateLastUsedProfileRequest = { profileId }
      const response = await updateLastUsedProfileService(updateLastUsedProfileRequest)

      if (response.errors) throw new Error('Erro ao tentar atualizar a data de último acesso do perfil.')
    } catch (e) {
      if (e instanceof Error) {
        toast.handler({
          content: e.message,
          duration: 10000,
          severity: 'error'
        })
      }
    }
  }

  const setSession = async (windowLoading: boolean, options?: IOptionsSetSession) => {
    if (windowLoading) setWindowLoading(true)

    const { data } = await getSession()
    const { accept_terms, profiles, subscriptions } = data

    if (!data || (data && !profiles.length)) {
      window.location.href = NO_PROFILE_FOUND
      return
    }

    setUserSession(data)

    const enabledSchoolProfiles = profiles.filter(profile => profile.school.enabled)

    const profilesAvailableInSubscription: number[] = subscriptions.map(el => el.user_school_profile.id)
    let cloeAllowedProfiles = getCloeAllowedProfiles(enabledSchoolProfiles)
    const adminAllowedProfiles = getAdminAllowedProfiles(enabledSchoolProfiles)

    const hasCloeProfiles = !!cloeAllowedProfiles.length
    const hasAdminProfiles = !!adminAllowedProfiles.length

    const lastUsedProfile = enabledSchoolProfiles.find(p => p.lastUsed)

    if (!lastUsedProfile) {
      cloeAllowedProfiles = cloeAllowedProfiles
        // Sort by the profiles with a correspodent subscription
        ?.sort((a: IProfilesSessionResponse, b: IProfilesSessionResponse) => {
          const AprofileMatchWithSubscription = profilesAvailableInSubscription.includes(a.id)
          const BprofileMatchWithSubscription = profilesAvailableInSubscription.includes(b.id)

          return Number(BprofileMatchWithSubscription) - Number(AprofileMatchWithSubscription)
        })
    }

    // valores iniciais
    let profileLocal: IProfilesSessionResponse | null = profile
    let subscriptionLocal: ISubscriptionsSessionResponse | null = subscription
    let schoolPeriodLocal: ISchoolPeriodResponse | null = schoolPeriod

    // verifica se o perfil e subscription salvos são do mesmo usuário e não altera caso já exista (para evitar de no redirect cair sempre de volta no primeiro)
    let resetProfile = true
    let currentProfile = profile
    let resetInnerProfileData = false
    if (profileLocal) {
      const foundedProfile = cloeAllowedProfiles.find(cur => cur.id === profileLocal?.id)
      const isFoundProfileEqual = JSON.stringify(foundedProfile) === JSON.stringify(profileLocal)

      if (!isFoundProfileEqual) {
        resetInnerProfileData = true
      }

      if (foundedProfile) {
        resetProfile = resetInnerProfileData
        currentProfile = foundedProfile
      }
    }

    if (resetProfile && hasCloeProfiles) {
      // resetInnerProfileData = Keep the same profile, updating the data
      profileLocal = resetInnerProfileData ? currentProfile : cloeAllowedProfiles[0]
      currentProfile = profileLocal

      if (profileLocal?.type === IUserSchoolProfileTypeEnum.teacher) {
        setSubscription(null)
        // setSchoolPeriod(null)
      } else if (profilesAvailableInSubscription.length && !resetInnerProfileData) {
        // IF there is any subscription available and the actual user is a student, select a profile
        // TODO: ADD ERROR SCREEN CASE: Estudante possui subscriptions e profiles, porém nenhum profile está linkado a um desses subscriptions
        profileLocal = cloeAllowedProfiles.find(el => profilesAvailableInSubscription.includes(el.id)) ?? null
      }

      setProfile(profileLocal)
    }

    // verifico se tenho que resetar o subscription
    let resetSubscription = true
    if (profileLocal) {
      const possibleSubscriptionsInProfile = subscriptions.filter(cur => cur.user_school_profile.id === profileLocal?.id)
      if (subscription) {
        // tem alguma subscription na store, valido se existe no perfil pra evitar problemas
        const foundSubscription = possibleSubscriptionsInProfile.filter(cur => cur.user_school_profile.id === subscription.user_school_profile.id).map(cur => cur?.class?.id).filter(Boolean)
        if (foundSubscription?.length && subscription?.user_school_profile?.id && subscription?.class?.id && foundSubscription.includes(subscription?.class?.id)) resetSubscription = false
      }
    }

    if (hasCloeProfiles) {
      profileLocal = cloeAllowedProfiles[0]
      currentProfile = profileLocal

      if (profileLocal?.type === IUserSchoolProfileTypeEnum.teacher) {
        setSubscription(null)
      } else if (profilesAvailableInSubscription.length) {
        // IF there is any subscription available and the actual user is a student, select a profile
        // TODO: ADD ERROR SCREEN CASE: Estudante possui subscriptions e profiles, porém nenhum profile está linkado a um desses subscriptions
        profileLocal = cloeAllowedProfiles.find(el => profilesAvailableInSubscription.includes(el.id)) ?? null
      }

      await updateLastUsedProfile(profileLocal ? profileLocal.id : cloeAllowedProfiles[0].id)
      setProfile(profileLocal)
    }

    // verifica se o periodo atual está presente no perfil salvo e resetar caso não esteja
    const availableSchoolPeriods = currentProfile?.school?.school_periods ?? []
    let resetSchoolPeriod = true

    const selectedClass = currentClassId
      ? subscriptions.find(cur => cur.class?.id === Number(currentClassId))
      : null

    if (schoolPeriodLocal) {
      const foundSchoolPeriod = availableSchoolPeriods.some(schoolPeriod => {
        const isSameId = schoolPeriod.id === schoolPeriodLocal?.id
        const isSameVersion = schoolPeriod.updated_at === schoolPeriodLocal?.updated_at

        return isSameId && isSameVersion
      })

      const isSelectedClassInScholPeriod = !selectedClass || selectedClass.class.school_period === schoolPeriodLocal?.id

      if (foundSchoolPeriod && isSelectedClassInScholPeriod) resetSchoolPeriod = false
    }

    let foundSubscriptionSchoolProfile = true
    if (resetSchoolPeriod) {
      if (availableSchoolPeriods.length) {
        let subscriptionsSchoolPeriodIds = subscriptions.map(cur => cur.class?.school_period)

        if (selectedClass && subscriptionsSchoolPeriodIds.includes(selectedClass.class.school_period)) {
          subscriptionsSchoolPeriodIds = [selectedClass.class.school_period]
        }

        const schoolPeriodInSubscriptions = availableSchoolPeriods
          .sort((a, b) => Number(b.current) - Number(a.current))
          .find(schoolPeriod => subscriptionsSchoolPeriodIds.some(subscriptionSchoolPeriodId => schoolPeriod.id === subscriptionSchoolPeriodId)) ?? null

        schoolPeriodLocal = schoolPeriodInSubscriptions
        foundSubscriptionSchoolProfile = !!schoolPeriodInSubscriptions
      } else {
        schoolPeriodLocal = null
      }
      setSchoolPeriod(schoolPeriodLocal)
    }

    // somente aluno tem contexto de subscrition
    if (resetSubscription && profileLocal && profileLocal.type === IUserSchoolProfileTypeEnum.student) {
      const subscription: ISubscriptionsSessionResponse | null = subscriptions.find(cur => cur.user_school_profile.id === profileLocal?.id && schoolPeriodLocal?.id === cur.class.school_period) ?? null

      subscriptionLocal = subscription
      setSubscription(subscription ?? null)
    }

    // redirects
    if (!accept_terms) {
      await termsAcceptanceRedirect(cloeAllowedProfiles, hasCloeProfiles, hasAdminProfiles)
    } else {
      noProfileFoundRedirect(cloeAllowedProfiles, hasCloeProfiles, hasAdminProfiles, profileLocal)
      noEnabledSchoolRedirect(enabledSchoolProfiles, profiles)
      adminRedirect(hasCloeProfiles, hasAdminProfiles, lastUsedProfile)
      noSchoolPeriodFoundRedirect(hasAdminProfiles, availableSchoolPeriods, foundSubscriptionSchoolProfile, subscriptionLocal)
    }

    if (windowLoading) setWindowLoading(false)

    const subscriptionAnalytics: ISubscriptionAnalytics = subscriptionLocal
      ? {
        grade_id: subscriptionLocal.analytics?.grade_id,
        grade_name: subscriptionLocal.analytics?.grade_name,
        grade_code: subscriptionLocal.analytics?.grade_code,
        segment_id: subscriptionLocal.analytics?.segment_id,
        segment_name: subscriptionLocal.analytics?.segment_name,
        segment_code: subscriptionLocal.analytics?.segment_code
      }
      : {}

    if (profileLocal) {
      setSessionAnalytics(profileLocal, subscriptionAnalytics)
      if (options?.eventDispatch) analyticsRecordEventClick(profileLocal, subscriptionAnalytics, options?.eventDispatch)
    }

    if (redirectLink) {
      window.location.href = redirectLink
      setRedirectLink('')
    }
  }

  const currentUser = async (attempt = 0) => {
    const token = Storage.token.get()
    if (token === null || token === undefined || !token) {
      await signout()
      return
    }

    let response = await userMe({ axiosSource: source })
    if (response.success) {
      setUser(response.data)
      await setSession(false)
    } else if (!response.status && !user && attempt === 0) {
      const secondAttempt = await currentUser(1)
      if (secondAttempt) response = secondAttempt
    } else if (!!token && JSON.stringify(response.data) === JSON.stringify('Network Error')) {
      // Ignorando erro caso tenhamos o token e por algum motivo a requisicao falhar
      // Isso acontece no iOS quando redirecionamos para mudar a senha default
      // essa requisicao é cancelada mas nao deveriamos deslogar o user
      console.error('Error', response.errors)
    } else {
      await signout()
      return
    }
    return response
  }

  const clearCookies = () => {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
    }
  }

  const clean = async () => {
    setUser(null)

    setSchoolPeriod(null)
    setSubscription(null)
    setUserSession(null)
    setProfile(null)

    resetCurricularStore()

    localStorage.clear()
    sessionStorage.clear()
    clearCookies()

    Storage.token.remove()
    Storage.props.remove()
    Storage.disciplines.remove()
  }

  const validateRedirectionLink = (resetRedirectLink?: boolean) => {
    const publicRoutes = getPublicRoutes(!!user)
    const isPublicRoute = publicRoutes.routes.some(route => route.path === pathname)
    const isRootRoute = pathname === ROOT

    if (!isPublicRoute && !isRootRoute && !resetRedirectLink) {
      setRedirectLink(pathname + search)
    } else if (resetRedirectLink) {
      setRedirectLink('')
    }
  }

  const signout = async (resetRedirectLink?: boolean) => {
    try {
      setWindowLoading(true)
      validateRedirectionLink(resetRedirectLink)
    } catch (err: any) {
      console.log(err?.message ?? '')
    } finally {
      await clean()
      window.location.href = LOGIN
    }
  }

  const forgotPassword = async (email?: string, phone?: string, username?: string | null) =>
    await userForgotPassword(email, phone, username)

  const resetPassword = async (code: string, password: string, passwordConfirmation: string) => {
    const response = await userResetPassword(code, password, passwordConfirmation)

    if (response.success) {
      setUser(response.data.user)
      Storage.token.set(response.data.jwt)
    } else {
      setTimeout(() => {
        signout(true)
      }, 5000)
    }

    return response
  }

  const changeDefaultPassword = async (userId: number, newPassword: string) => {
    const response = await userChangeDefaultPassword(userId, newPassword)

    if (response.success) {
      window.location.href = ROOT
    } else {
      await signout()
    }

    return response
  }

  const loginWithSignedJwt = async (jwt: string) => {
    await clean()
    const response = await loginWithJwt(jwt)
    if (response.success) {
      Storage.token.set(response.data.jwt)
      setUser(response.data.user)
      await setSession(true)
      Analytics.setup({
        username: response?.data?.user?.username,
        attributes: {
          username: [response?.data?.user?.username],
          userId: [response?.data?.user?.id],
          origin: ['cloe']
        }
      })
      window.location.href = ROOT
    } else {
      if (Array.isArray(response?.message)) setSSOErrors(response.message)
      window.location.href = LOGIN
    }
  }

  return {
    user,
    currentUser,
    registerSignin,
    signin,
    temporaryAccess,
    signout,
    setSession,
    forgotPassword,
    resetPassword,
    changeDefaultPassword,
    clean,
    updateLastUsedProfile,
    loginWithSignedJwt
  }
}
