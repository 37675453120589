import { ISkillTreeResponse } from 'contentCacheManager'
import { useContentSkills } from 'pages/ContentUnit/hooks/useContentSkills'
import { useSkills } from 'pages/ContentUnit/hooks/useSkills'
import { useEffect, useState } from 'react'
import useStyle from '../../../../style'
import { flatDeep } from 'utils/array'
import { LoadingWrapper, Text, Tooltip } from 'components/common'
import { BusinessError } from 'navigation/BusinessError'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'

interface IProps {
  contentId: string
  isCloeExpand?: boolean
}

const Curriculum = ({ contentId, isCloeExpand }: IProps) => {
  const classes = useStyle()
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const { hasResponseError: skillError, skills } = useSkills(Number(contentId))
  const { contentSkills, hasResponseError: contentSkillError } = useContentSkills(Number(contentId))

  // states
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [bnccSkills, setBnccSkills] = useState<ISkillTreeResponse[]>([])
  const [experienceSkills, setExperienceSkills] = useState<ISkillTreeResponse[]>([])

  useEffect(() => {
    const bncc = skills
      .map((curCaminoSkill) =>
        curCaminoSkill.skill_tree.filter(
          (curSkill) => curSkill.type === 'bncc_skill' && curSkill.code
        )
      )

    setBnccSkills(flatDeep(bncc).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i))

    const experience = skills
      .map((curCaminoSkill) =>
        curCaminoSkill.skill_tree.filter(
          (curSkill) => curSkill.type === 'experience_field'
        )
      )

    setExperienceSkills(flatDeep(experience).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i))
    setIsLoading(false)
  }, [skills])

  if (skillError || contentSkillError) {
    return <BusinessError error={t('Falha ao carregar seções')} />
  }

  return (
    <LoadingWrapper loading={isLoading} type='linear'>
      <div className={classes.curriculumContainer}>
        {
          contentSkills?.length || bnccSkills?.length || skills?.length || experienceSkills?.length
            ? <h1 className={classes.title}>{t('Currículo')?.toString()}</h1>
            : ''
        }
        {/*
         CONTEUDO PEDIU PARA DESATIVAR TEMPORARIAMENTE
        {
          contentSkills?.length
            ? <>
              <div className={classes.sectionTitle}>
                {t('Conteúdo(s)')?.toString()}
              </div>
              <ul className={classes.overviewSection}>
                {contentSkills.map((skill) => (
                  <li key={skill.id}>
                    <p>{skill.name}</p>
                  </li>
                ))}
              </ul>
            </>
            : ''
        } */}
        {
          bnccSkills?.length
            ? <>
              <div className={classes.sectionTitle}>
                {t('Habilidades BNCC')?.toString()}
              </div>
              <div className={classes.tagsContainer}>
                {bnccSkills
                  .map((item) => (
                    <Tooltip key={item.id} label={item.code} text={item.name} />
                  ))}
              </div>
            </>
            : ''
        }
        {
          experienceSkills?.length
            ? <>
              <div className={classes.sectionTitle}>
                {t('Campo de experiência')?.toString()}
              </div>
              <ul className={classes.overviewSection}>
                {
                  experienceSkills.map(experienceSkill =>
                    <li key={experienceSkill.id}>
                      <Text content={experienceSkill.name} />
                    </li>)
                }
              </ul>
            </>
            : ''
        }
        {
          skills?.length
            ? <>
              <div className={classes.sectionTitle}>
                {t('Habilidades Camino')?.toString()}
              </div>
              <ul className={classes.overviewSection}>
                {
                  skills.map(curCaminoSkill =>
                    <li key={curCaminoSkill.id}>
                      <Text content={curCaminoSkill.name} />
                    </li>)
                }
              </ul>
            </>
            : ''
        }
      </div>
    </LoadingWrapper>
  )
}

export default Curriculum
