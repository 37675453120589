import { IApiResponse, ISubscriptedProfilesResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { request } from 'services/base'
import {
  CLASS_SUBCRIPTED_PROFILES
} from './CONSTANTS'

interface IGetClassSubscriptedProfilesParams {
  classId: number
  filterByProfile?: IUserSchoolProfileTypeEnum.student | IUserSchoolProfileTypeEnum.teacher
}

export const getClassSubscriptedProfiles = async ({ classId, filterByProfile }: IGetClassSubscriptedProfilesParams): Promise<IApiResponse<ISubscriptedProfilesResponse>> =>
  await request({ url: CLASS_SUBCRIPTED_PROFILES(classId, filterByProfile) })
