import React, { useState, useEffect } from 'react'
import { IAnswerRecordRequest, getAnswerOfflineByContent, AnswerCacheTable } from 'contentCacheManager'

type OnlyAnsweredProps = Partial<Omit<IAnswerRecordRequest, 'answers'>> & { render: (data: AnswerCacheTable[]) => React.ReactElement}

const OnlyAnswered: React.FC<OnlyAnsweredProps> = (props) => {
  const [answers, setAnswers] = useState<AnswerCacheTable[] | undefined>()

  useEffect(() => {
    const loadAnswersByContext = async () => {
      const answersLoaded = await getAnswerOfflineByContent(props)
      if (answersLoaded) {
        setAnswers(answersLoaded)
      }
    }
    loadAnswersByContext()
  }, [props])

  return answers ? props.render(answers) : null
}

export default OnlyAnswered
