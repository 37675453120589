import { makeStyles, createStyles } from '@mui/styles'
import { Grid, styled, Theme } from '@mui/material'

import { SubTabsMenusStudent } from '../../../atomStore'

interface IStyleProps {
  currentSubMenuTab: SubTabsMenusStudent
}

export const Intro = styled('div')(({ theme }) => ({
  marginBottom: theme.spacingInline.xxs,
  fontWeight: theme.font.fontWeight.semibold,
  fontSize: theme.font.fontSize.lg,
  color: theme.colors.neutral.dark20
}))

export const Legend = styled('p')(({ theme }) => ({
  margin: '0px',
  fontWeight: theme.font.fontWeight.regular,
  fontSize: '18px', // theme.font.fontSize.sm,
  color: theme.colors.neutral.dark20
}))

export const Title = styled('span')(({ theme }) => ({
  color: theme.colors.neutral.dark20,
  fontSize: theme.font.fontSize.lg,
  margin: theme.spacingInline.xxs,
  fontWeight: theme.font.fontWeight.medium,
  lineHeight: theme.font.lineHeight.md,

  [theme.breakpoints.down('sm')]: {
    marginInline: theme.spacingInline.xxxs
  }
}))

export const OverviewWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacingInset.xs,
  background: theme.colors.neutral.lightBase,
  paddingInline: theme.spacingInline.xxs,
  paddingBottom: theme.spacingInline.xxs,
  marginTop: theme.spacingInset.xs,
  [theme.breakpoints.down('sm')]: {
    paddingInline: theme.spacingInline.xxxs
  }
}))

export const SearchContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacingInline.xxs
}))

export default makeStyles((theme: Theme) => createStyles({
  badge: {
    '& span.MuiBadge-badge': {
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs,
      color: theme.colors.neutral.lightBase,
      background: ({ currentSubMenuTab }: IStyleProps) => currentSubMenuTab === SubTabsMenusStudent.LIBERATED ? theme.colorBrand.darkest : theme.colorBrand.dark,
      fontFamily: theme.font.fontFamily
    }
  }
}))
