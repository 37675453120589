import { IActivityResponse } from 'services/types'
import { Intro, Legend } from '../../style'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { Grid, useTheme } from '@mui/material'
import ContentLink from 'components/common/ContentLink'
import { ContentTypeEnum } from 'services/types/content'

interface IOriginals {
  activitiesAvailable: IActivityResponse[]
  isCloeExpand: boolean
  contentId: string
}

export const Originals = ({
  activitiesAvailable,
  isCloeExpand,
  contentId
}: IOriginals) => {
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })
  const theme = useTheme()

  return (
    <>
      <Intro>
        {t('Originais')}
        <Grid item sx={{ pt: 2 }}>
          <Legend>
            {t('As atividades originais são usadas como modelo pelo(a) professor(a). Ao liberá-las para a turma, ele(a) criará uma cópia e poderá editar a quantidade de questões.')}
          </Legend>
        </Grid>
      </Intro>
      {
        <Grid container>
          <Grid item xs={12} gap={theme.spacingInline.xxs} display='flex' sx={{
            flexWrap: 'wrap',
            background: theme.colors.neutral.lightBase,
            [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
          }}>
            {
              activitiesAvailable.map((e: IActivityResponse, i) => {
                return (
                  <Grid sx={{
                    maxWidth: '284px',
                    // minWidth: '284px',
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '100%',
                      minWidth: '100%'
                    }
                  }} key={i}>
                    <ContentLink
                      id={contentId?.toString()}
                      activityId={e.id?.toString()}
                      title={e.title ?? e.activity_name}
                      type={e.type === 'guide' ? ContentTypeEnum.guide : ContentTypeEnum.activity}
                      momentType={e.moment_type}
                      showTypeTag={false}
                      suggestedApplicationType={e.suggested_application_type}
                      dataTestid='activities_select_all_activity'
                      useCloeExpandTranslation={isCloeExpand}
                      quantityQuestions={e.questions.length}
                      isTemplate
                    />
                  </Grid>)
              })
            }
          </Grid>
        </Grid>
      }
    </>
  )
}
