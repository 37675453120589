import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  boxContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '11px 24px'
  },
  boxClose: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: 'none',
    border: 'none'
  },
  text: {
    color: theme.colors.neutral.dark40,
    marginInline: '16px',
    padding: '16px',
    borderRadius: '8px',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginInline: theme.spacingStack.xxs,
      color: theme.colors.neutral.dark40
    }
  },
  boxIcon: {
    '&.MuiSvgIcon-root': {
      color: theme.colorBrand.medium
    }
  },
  close: {
    paddingLeft: '14px',
    margin: 0,
    fontWeight: 600,
    fontSize: '14px',
    color: theme.colorBrand.medium
  },
  dialogContent: {
    padding: '20px 8px'
  },
  modalTabs: {
    display: 'flex',
    gap: '8px',
    height: '100%',
    width: 'auto',
    maxWidth: 'unset',
    justifyContent: 'center'
  },
  mediaContainer: {
    position: 'relative',
    height: '100%',

    '& > img': {
      maxHeight: 'calc(100vh - 135px)',
      objectFit: 'unset',
      borderRadius: '8px'
    }
  },
  captionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '7px',
    left: 0,
    right: 0,
    background: 'rgba(133, 31, 130, 0.90)',
    padding: '4px 8px',
    borderRadius: '8px'
  },
  hideCaptionContainer: {
    display: 'none'
  },
  source: {
    textAlign: 'center',
    color: '#E7D2E6',
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '24px',
    letterSpacing: '0.7px',
    marginLeft: theme.spacingInline.nano,
    textTransform: 'uppercase'
  },
  caption: {
    '& span p': {
      display: 'inline'
    },
    flex: 1,
    color: '#FFF',
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.bold,
    lineHeight: '24px',
    letterSpacing: '0.7px',
    width: '100%',
    fontFamily: theme.font.fontFamily,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      fontSize: theme.font.fontSize.xxxs,
      fontWeight: theme.font.fontWeight.regular,
      padding: theme.spacingInline.nano,
      width: '100%'
    }
  },
  captionClose: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      color: theme.colors.neutral.lightBase,
      cursor: 'pointer'
    }
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
    minWidth: '50px',

    '& > svg': {
      color: '#851F82',
      cursor: 'pointer'
    }
  }
}))
