import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column !important' as 'column'
  },
  selectorSubtitle: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xxs,
    color: theme.colors.neutral.dark30,

    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'initial'
    }
  }
}))
