import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import { useAtom } from 'jotai'
import { useStore } from 'store'

import { IQuestionResponse, IUserSchoolProfileTypeEnum, ActivityControlStatusEnum } from 'services/types'
import { questionIndexAtom, activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import OnlyProfile from 'components/common/OnlyProfile'
import { Text2 } from 'components/design-system'
import { QuestionResource } from './components/QuestionResource'
import { StudentQuestionView } from './components/StudentQuestionView'
import { TeacherQuestionView } from './components/TeacherQuestionView'
import { CommentsArea } from '../../../../../ActivityFooter/components/CommentsArea'
import { OfflineSentAlert } from './components/OfflineSentAlert'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'
import useStatyle from '../../../../style'

interface IQuestionsProps {
  question: IQuestionResponse
  index: number
}

export const Question: React.FC<IQuestionsProps> = ({ question, index }) => {
  const [questionIndex] = useAtom(questionIndexAtom)
  const [activity] = useAtom(activityCurrentAtom)
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStatyle()
  const { subscription } = useStore()
  const showCommentsArea = [ActivityControlStatusEnum.CORRECTED, ActivityControlStatusEnum.RELEASED].includes(Number(activity?.activity_status?.status)) && subscription?.user_school_profile.type === 'student'
  const { isActivitySuggestedType, isExpeditionView } = useStudentAnswer()
  const showQuestionIndex = subscription?.user_school_profile.type === 'student' && isExpeditionView

  if (!activity) return null
  return (
    <div className={classes.awnserQuestions} id={`${questionIndex === index ? 'selected-question' : ''}`}>
      {showQuestionIndex &&
        <Text2
          fontSize='sm'
          fontWeight='bold'
          lineHeight='lg'
          mobile='sm'
          style={{ width: '100%' }}
          customColor={theme.colorBrand.medium}
        >
          {t('Questão', { questionNumber: index + 1 })}
        </Text2>
      }
      <QuestionResource question={question} />
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <StudentQuestionView question={question} index={index} />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <TeacherQuestionView question={question} />
      </OnlyProfile>
      {showCommentsArea &&
        <CommentsArea
          activity={activity.id}
          questionId={question.id}
          classId={subscription?.class.id.toString() ?? ''}
          selectedStudentProfile={subscription.user_school_profile.id}
          dataTestid={isActivitySuggestedType
            ? 'correction_activity_exercise_comment'
            : 'correction_assessment_comment'}

          dataTestidEditDelete={isActivitySuggestedType
            ? 'edit_activity_comment_confirm'
            : 'edit_assessment_comment_confirm'}

          dataTestidEditNotDelete={isActivitySuggestedType
            ? 'edit_activity_comment_cancel'
            : 'edit_assessment_comment_cancel'}
        />
      }
      <OfflineSentAlert />
    </ div>
  )
}

export default Question
