import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  returnLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.colors.support.colorSupport02,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.semibold,
    '& span': {
      marginLeft: theme.spacingInline.nano
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.support.colorSupport03,
    '& svg': {
      color: theme.colors.support.colorSupport01,
      fontSize: theme.font.fontSize.sm
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xs,
      '& svg': {
        fontSize: theme.font.fontSize.xs
      }
    }
  },
  description: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.regular,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colors.support.colorSupport03,
    paddingTop: theme.spacingInline.xxxs,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xs
    }
  },
  input: {
    '&.MuiFormHelperText-root': {
      color: `${theme.colors.warning.feedbackDarkest}`
    },
    color: `${theme.colors.support.colorSupport03}`,
    width: '100%',
    textAlign: 'left'
  },
  submitButton: {
    width: '100%',
    '&.active': {
      background: `${theme.colors.support.colorSupport01} !important`,
      border: `1px solid ${theme.colors.support.colorSupport01} !important`
    },
    '&:hover': {
      background: '#602680 !important',
      border: '1px solid #602680 !important'
    }
  },
  cancelButton: {
    width: '100%',
    fontSize: `${theme.font.fontSize.xs}!important`,
    color: theme.colors.support.colorSupport04,
    '&.ghost': {
      color: theme.colors.support.colorSupport04,
      '&:hover': {
        background: theme.colorBrand.lightest
      }
    },
    '& svg': {
      marginRight: '8px'
    }
  },
  alertError: {
    color: `${theme.colors.negative.feedbackDarkest}!important`,
    fontSize: `${theme.font.fontSize.xxs}!important`,
    fontWeight: `${theme.font.fontWeight.semibold}!important`,
    padding: '8px!important',
    textAlign: 'left',
    '& .MuiAlert-icon': {
      margin: '0px 8px'
    }
  },
  alertSuccess: {
    color: `${theme.colors.positive.feedbackDarkest}!important`,
    fontSize: `${theme.font.fontSize.xxs}!important`,
    fontWeight: `${theme.font.fontWeight.semibold}!important`,
    padding: '0px 8px!important',
    textAlign: 'left',
    '& .MuiAlert-icon': {
      margin: '0px 8px'
    }
  }
}))
