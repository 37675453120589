import ErrorBoundary from 'components/common/ErrorBoundary'
import OnlyProfile from 'components/common/OnlyProfile'
import { IContentUnitResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import StudentActivity from './student'
import TeacherActivity from './teacher'
import { useAtom } from 'jotai'
import { showActivityDescriptionAtom } from '../SideMenu/components'
import { useEffect } from 'react'

interface IActivityProps {
  content: IContentUnitResponse
}

export const Activity: React.FC<IActivityProps> = ({ content }) => {
  const [, setShowActivityDescription] = useAtom(showActivityDescriptionAtom)

  useEffect(() => {
    setShowActivityDescription(true)

    return () => {
      setShowActivityDescription(false)
    }
  })
  return (
    <ErrorBoundary>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <StudentActivity content={content} />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <TeacherActivity content={content} />
      </OnlyProfile>
    </ErrorBoundary>
  )
}

export default Activity
