import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  basicFillContainer: {
    textAlign: 'center',
    width: '100%'
  },
  fillContainer: {
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.lightBase,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  }
}))
