/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  teacherCardContainer: {
    flexDirection: 'row !important' as 'row',
    gap: theme.spacingInline.xxxs,
    flexWrap: 'nowrap !important' as 'nowrap'
  },
  customAvatar: {
    width: '3.2rem !important',
    height: '3.2rem !important',
    background: theme.colorBrand.medium,

    [theme.breakpoints.up('md')]: {
      width: '4rem',
      height: '4rem'
    }
  },
  teacherCardNameKey: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    justifyContent: 'center',
    alignItems: 'left',
    gap: theme.spacingStack.quarck
  },
  teacherName: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word'
  }
}))
