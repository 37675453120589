import React from 'react'
import { Grid, Skeleton } from '@mui/material'

import { ReportsTable } from '..'

export const ReportsListLoading: React.FC = () => {
  return (
    <Grid container flexDirection='column'>
      <Skeleton variant='text' width={300} height={60} animation='wave' />

      <Skeleton sx={{ bgcolor: 'grey.200', width: '100%' }} variant='text' height={100} animation='wave' />

      <Skeleton sx={{ marginBottom: '2rem' }} variant='text' width={130} height={60} animation='wave' />

      <ReportsTable.Skeleton />
    </Grid>
  )
}
