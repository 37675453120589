import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  shortcutCardContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    '&.MuiCard-root': {
      height: '320px',
      maxWidth: '100%',
      border: `1px solid ${theme.colors.neutral.light10}`,
      borderRadius: theme.borderRadius.sm,
      padding: theme.spacingInset.xs,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxShadow: 'none',
      [theme.breakpoints.up('md')]: {
        maxWidth: '40%',
        '&:nth-child(1)': {
          marginRight: theme.spacingInset.xs
        },
        '&:nth-child(2)': {
          marginRight: theme.spacingInset.xs
        }
      },
      [theme.breakpoints.down('md')]: {
        height: '240px',
        '&:nth-child(1)': {
          marginBottom: theme.spacingInset.sm
        },
        '&:nth-child(2)': {
          marginBottom: theme.spacingInset.sm
        }
      },
      '&:hover': {
        boxShadow: theme.shadowLevels.level3
      },
      [theme.breakpoints.down('sm')]: {
        height: '300px'
      }
    }
  },
  shortcutCardHeader: {
    paddingBottom: theme.spacingInset.xs,
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    '& img': {
      width: '70px',
      height: '70px',
      [theme.breakpoints.up('md')]: {
        width: '80px',
        height: '80px'
      }
    }
  }
}))
