import { useAtom } from 'jotai'
import { getContentSkills } from 'services/content-unit'
import { contentSkillsAtom, hasResponseErrorAtom } from '../atomStore'

export const useContentSkills = (contentId: number) => {
  const [contentSkills, setContentSkills] = useAtom(contentSkillsAtom)
  const [hasResponseError, setHasResponseError] = useAtom(hasResponseErrorAtom)

  const get = async () => {
    const response = await getContentSkills(contentId)
    if (response.success) {
      setContentSkills(response.data)
    } else {
      setHasResponseError(true)
    }
  }

  return {
    contentSkills,
    get,
    hasResponseError
  }
}
