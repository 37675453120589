/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import dayjs from 'dayjs'

import SendIcon from '@mui/icons-material/Send'
import { CircularProgress, Grid, useTheme } from '@mui/material'
import { Button } from 'components/design-system'
import { toast } from 'components/design-system/Toast/manager'

import {
  scheduleAtom,
  updateContentSelectAtom,
  subscriptionsAtom,
  isHoursAndMinutesNowAtom,
  isInvalidStartDateAtom,
  activityRemovedQuestionsAtom,
  isInvalidEndDateAtom,
  activitySelectTitleAtom
} from '../atomStore'
import { MY_CLOE_CONTEXT, myCloeContextAtom } from '../../../atomStore'
import { useOnlineStatus } from 'contentCacheManager'
import useMyCloeSchedule from 'navigation/hooks/useMyCloeSchedule'

import { IScheduleRequest } from 'services/types/schedule'
import { ContentTypeEnum } from 'services/types/content'
import { cloneActivity } from 'services/activity'

interface ISendSchedule {
  isMaterialVision?: boolean
}

export const SendSchedule = ({ isMaterialVision = false }: ISendSchedule) => {
  const theme = useTheme()
  const { classId } = useParams<{ classId: string }>()
  const { t } = useTranslation()
  const { sendSchedule, cleanSchedule } = useMyCloeSchedule()

  // state
  const [loading, setLoading] = useState<boolean>(false)

  // atom
  const [isStartDateInvalid, setIsStartDateInvalid] = useAtom(isInvalidStartDateAtom)
  const [isEndDateInvalid, setIsEndDateInvalid] = useAtom(isInvalidEndDateAtom)
  const activitySelectTitle = useAtomValue(activitySelectTitleAtom)
  const activityRemovedQuestions = useAtomValue(activityRemovedQuestionsAtom)
  const contentSelect = useAtomValue(updateContentSelectAtom)
  const isHoursAndMinutesNow = useAtomValue(isHoursAndMinutesNowAtom)
  const schedule = useAtomValue(scheduleAtom)
  const subscriptions = useAtomValue(subscriptionsAtom)
  const myCloeContext = useAtomValue(myCloeContextAtom)

  const isOnline = useOnlineStatus()

  const defaultVisionDataTestIds = {
    cancel_creation: 'event_without_expedition_cancel_event_creation',
    create: 'event_without_expedition_create_event'
  }

  const materialVisionDataTestIds = {
    cancel_creation: 'teacher_materials_add_cancel_creation',
    create: 'teacher_materials_add_create'
  }

  const dataTestIds = isMaterialVision ? materialVisionDataTestIds : defaultVisionDataTestIds

  const handleCancel = () => {
    cleanSchedule()
  }

  const handleSubmit = async () => {
    let scheduleStartDate
    setLoading(true)
    const isActivity = schedule?.activity && schedule?.id
    const limitTitle = (schedule?.title ?? '')?.trim()?.length > 255

    // to remove seconds
    const dateFormat: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }
    if (isHoursAndMinutesNow) {
      scheduleStartDate = 'now'
    } else {
      scheduleStartDate = dayjs(schedule?.start_date)
      scheduleStartDate = `${(scheduleStartDate.toDate()).toLocaleString('sv', dateFormat)}:00${scheduleStartDate.format('Z')}`
    }

    const isActivitySelected = (contentSelect ?? []).some(content => content.type && [ContentTypeEnum.activity, ContentTypeEnum.evaluation].includes(content.type))

    const scheduleRequest: IScheduleRequest = {
      activity: isActivity ? schedule?.activity : null,
      ask_for_records: false,
      class: Number(classId),
      content: schedule?.content?.trim() ?? null,
      didactic_section: !isActivity ? schedule?.didatic_section : null,
      content_unit: schedule?.content_unit ?? null,
      discipline: schedule?.disciplineId ?? null,
      start_date: scheduleStartDate ?? null,
      end_date: schedule?.end_date && (isActivity || isActivitySelected)
        ? `${schedule?.end_date.toLocaleString('sv', dateFormat)}:00${dayjs(schedule?.end_date).format('Z')}`
        : null,
      room_link: schedule?.room_link ?? null,
      title: schedule?.title?.trim() ?? null,
      type: schedule?.title ? 'original_content' : 'existing_content',
      files: schedule?.file_records?.length ? schedule?.file_records?.map(cur => ({ upload_id: cur.id, url: cur.url, transaction_id: cur.transaction_id })) : null,
      subscriptions: subscriptions ?? [],
      contentSelect: contentSelect ?? []
    }

    // verifica se é atualização
    if (schedule?.id) {
      if (contentSelect?.[0]?.type && [ContentTypeEnum.activity, ContentTypeEnum.evaluation].includes(contentSelect?.[0]?.type)) {
        scheduleRequest.activity = Number(contentSelect?.[0]?.id ?? '')
        scheduleRequest.didactic_section = null
      } else {
        setIsStartDateInvalid(false)
        setIsEndDateInvalid(false)
        scheduleRequest.didactic_section = `${contentSelect?.[0]?.id ?? ''}`
        scheduleRequest.activity = null
        scheduleRequest.end_date = null
      }
    }

    let validationError = true

    // validates Activity end Date
    if ((isActivity || isActivitySelected) && !scheduleRequest?.end_date && !scheduleRequest.didactic_section) {
      toast.handler({
        content: t('A data de conclusão é necessária para atividades e avaliações.'),
        duration: 5000,
        severity: 'warning'
      })
    } else if (!scheduleRequest?.content_unit && (!scheduleRequest.title || !schedule?.content)) {
      toast.handler({
        content: t('É necessário título e descrição em um evento sem conteúdo vínculado!'),
        duration: 5000,
        severity: 'warning'
      })
    } else if (myCloeContext === MY_CLOE_CONTEXT.CONTENT_UNIT && (!scheduleRequest.title || !schedule?.content)) {
      toast.handler({
        content: t('É necessário informar título e descrição!'),
        duration: 5000,
        severity: 'warning'
      })
    } else if (limitTitle) {
      toast.handler({
        content: t('O tamanho máximo do titulo é 255 caracteres!'),
        duration: 5000,
        severity: 'warning'
      })
    } else if (isStartDateInvalid || isEndDateInvalid) {
      toast.handler({
        content: t('As datas devem seguir o formato DD/MM'),
        duration: 5000,
        severity: 'warning'
      })
    } else if (isActivitySelected && !activitySelectTitle) {
      toast.handler({
        content: t('O título é obrigatório!'),
        duration: 5000,
        severity: 'error'
      })
    } else if (!isOnline) {
      toast.handler({
        content: t('Algo deu errado e não foi possível criar o seu evento. Tente novamente. Código: OFFLINE_CONNECTION'),
        duration: 5000,
        severity: 'error'
      })
    } else {
      validationError = false
    }

    if (validationError) {
      setLoading(false)
      return
    }

    if (isActivitySelected && !schedule?.id) {
      const response = await cloneActivity({
        activityId: contentSelect?.[0]?.id ?? '-1',
        contentUnitId: scheduleRequest.content_unit ?? '-1',
        classId: scheduleRequest.class,
        removedQuestions: activityRemovedQuestions ?? [],
        newTitle: activitySelectTitle ?? ''
      })

      if (!response?.success) {
        toast.handler({
          content: t('Ocorreu um erro ao resgatar a atividade original'),
          duration: 5000,
          severity: 'error'
        })

        setLoading(false)

        return
      }

      if (scheduleRequest?.contentSelect && scheduleRequest.contentSelect.length > 0) {
        // decomposição para evitar que o atom seja sobrescrito
        scheduleRequest.contentSelect = [{
          ...scheduleRequest.contentSelect[0],
          id: response.data.id
        }]
      }
    }

    // verifica se é atualização
    if (schedule?.id) {
      if (contentSelect?.[0]?.type && [ContentTypeEnum.activity, ContentTypeEnum.evaluation].includes(contentSelect?.[0]?.type)) {
        scheduleRequest.activity = Number(contentSelect?.[0]?.id ?? '')
      } else {
        scheduleRequest.didactic_section = `${contentSelect?.[0]?.id ?? ''}`
      }
    }

    await sendSchedule(scheduleRequest, schedule?.id?.toString(), isMaterialVision)

    setLoading(false)
  }

  return (
    <Grid
      container
      display={'grid'}
      sx={{
        padding: theme.spacingInset.xs,
        maxHeight: '40px',
        height: '40px',
        marginBottom: '50px',
        gridTemplateColumns: 'auto auto'
      }}
    >
      <Grid item justifySelf={'flex-start'}>
        <Button
          variant={'outlined'}
          sx={{
            padding: '8px 12px !important',
            gap: '8px',
            fontWeight: `${theme.font.fontWeight.medium} !important`
          }}
          onClick={() => handleCancel()}
          data-testid={dataTestIds.cancel_creation}
        >
          {t('Cancelar')}
        </Button>
      </Grid>
      <Grid item justifySelf={'flex-end'}>
        <Button
          sx={{
            padding: '8px 12px !important',
            gap: '8px',
            fontWeight: `${theme.font.fontWeight.medium} !important`
          }}
          onClick={handleSubmit}
          disabled={loading || !schedule?.disciplineId || (!schedule?.start_date && !isHoursAndMinutesNow)}
          data-testid={dataTestIds.create}
        >
          {
            !loading
              ? <>
                {t('Enviar')}
                <SendIcon data-testid={dataTestIds.create} />
              </>
              : <CircularProgress
                sx={{
                  color: 'white',
                  height: '30px !important',
                  width: '30px !important'
                }}
              />
          }
        </Button>
      </Grid>
    </Grid>
  )
}
