import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    containerDesktop: {
      background: 'white',
      textAlign: 'center'
    },
    image: {
      backgroundSize: '100%',
      maxWidth: '30rem',
      height: '30rem',
      width: '100%',
      maxHeight: '30rem',
      margin: 'auto',
      minHeight: 'auto',
      backgroundRepeat: 'no-repeat'
    },
    button: {
      width: '15rem',
      height: theme.spacingStack.xs,
      textDecoration: 'none'
    },
    navLink: {
      textDecoration: 'none'
    },
    text: {
      fontFamily: theme.font.fontFamily,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.sm,
      textAlign: 'center',
      color: theme.colors.neutral.dark30
    },
    center: {
      textAlign: 'center'
    }
  })
)
