import ErrorBoundary from 'components/common/ErrorBoundary'
import OnlyProfile from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import StudentEvaluations from './student'
import TeacherEvaluations from './teacher'
import BackToTopWindow from 'components/common/BackToTopWindow'
import { useTheme, useMediaQuery } from '@mui/material'

export const Evaluations: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ErrorBoundary>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <StudentEvaluations />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <TeacherEvaluations />
      </OnlyProfile>
      {isMobile && <BackToTopWindow />}
    </ErrorBoundary>
  )
}

export default Evaluations
