import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '892px',
    width: '100%',
    backgroundColor: theme.colors.neutral.lightBase,
    borderRadius: theme.borderRadius.md,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      display: 'grid',
      borderRadius: '0px',
      gridTemplateRows: 'auto 10fr 1fr'
    }
  },
  buttonsWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      justifyContent: 'space-between',
      '& > .ghost': {
        padding: '0px'
      }
    }
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      '& > a': {
        padding: '0px !important'
      }
    }
  },
  selector: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: theme.spacingInset.sm,
    gap: '16px',
    boxShadow: '0px 6px 13px -4px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInset.xs
    }
  },
  contentWrapper: {
    height: '400px',
    overflowY: 'scroll',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 'inherit',
      maxHeight: '100%'
    },
    ...theme.scroll
  },
  selectorTitle: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colorBrand.medium,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs
    }
  },
  selectorSubtitle: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark20,
    width: '100%'
  },
  contentTitle: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.md,
    width: '460px',
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    }
  },
  content: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    width: '100%',
    left: '0',
    right: '-17px',
    gap: '24px',
    display: 'grid',
    height: 'fit-content',
    ...theme.scroll,
    padding: theme.spacingInset.sm,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacingInset.xs,
      ...theme.scroll
    }
  },
  inputWrapper: {
    display: 'flex',
    border: `solid 2px ${theme.colors.neutral.light10}`,
    borderRadius: theme.borderRadius.sm,
    width: '100%',
    alignItems: 'center',
    padding: theme.spacingInset.xxs,
    gap: '8px'
  },
  input: {
    outline: 'none',
    color: theme.colors.neutral.darkBase,
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.sm,
    border: 'none'
  },
  iconWrapper: {
    color: theme.colorBrand.medium,
    display: 'flex'
  }
}))
