import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardStudentAnswerCommentContentActionsDropdown: {
      padding: theme.spacingInline.nano
    },
    cardStudentAnswerCommentContentActionsMenuOptions: {
      color: theme.colorBrand.dark,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs,

      '& svg': {
        fontSize: theme.font.fontSize.md,
        color: theme.colorBrand.dark
      }
    },
    cardStudentAnswerCommentContentActionsBadgeRead: {
      width: '10px',
      height: '10px',
      background: '#FFB800',
      borderRadius: '9999px'
    }
  })
)
