import {
  isInternalImageUrl,
  makeRequestOptions,
  addRequestToCache,
  CacheManagerException
} from '../../'

type CachedFileResponse = {
  data: unknown
  size: number
}

export const makeCacheEmbedded = async (object: any, onCacheFile: (data: CachedFileResponse) => void) => {
  if (!object) return
  if (Array.isArray(object) && object.length) {
    await Promise.all(object.map(async item => await makeCacheEmbedded(item, onCacheFile)))
  } else if (typeof object === 'object' && Object.keys(object).length) {
    await Promise.all(Object.values(object).map(async item => await makeCacheEmbedded(item, onCacheFile)))
  }

  if (typeof object === 'string' && isInternalImageUrl(object)) {
    const options = makeRequestOptions(null, true)
    const response = await addRequestToCache(new Request(object, { mode: 'no-cors', ...options }))

    if (response instanceof CacheManagerException) {
      throw response
    }

    onCacheFile(response)
  }
}
