import { useEffect, useState } from 'react'
import { Grid, useTheme } from '@mui/material'
import { contentUnitAtom } from 'pages/ContentUnit/atomStore'
import { useAtom } from 'jotai'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import useStyle from './style'
import { DisciplineCodeEnum, IScheduleResponse } from 'services/types'
import { Text2 } from 'components/design-system'
import { Empty, ShowCards } from './components'

enum StateMachineEnum {
  IDLE = 'IDLE',
  EMPTY = 'EMPTY',
  SHOW_CARDS = 'SHOW_CARDS'
}

interface IReadyProps {
  schedules: IScheduleResponse[]
}

export const Ready: React.FC<IReadyProps> = ({ schedules }) => {
  const theme = useTheme()
  const classes = useStyle()

  const [contentUnit] = useAtom(contentUnitAtom)

  const [currentState, setCurrentState] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  const isCloeExpand = contentUnit?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  useEffect(() => {
    setCurrentState(StateMachineEnum.IDLE)

    if (!schedules.length) {
      setCurrentState(StateMachineEnum.EMPTY)
      return
    }

    setCurrentState(StateMachineEnum.SHOW_CARDS)
  }, [schedules])

  const stateMachine = {
    IDLE: <></>,
    EMPTY: <Empty />,
    SHOW_CARDS: <ShowCards schedules={schedules} />
  }

  return (
    <>
      <h1 className={classes.title}>
        {t('Materiais do professor')}
      </h1>
      <Grid item >
        <Text2
          fontSize='md'
          fontWeight='medium'
          lineHeight='xs'
          mobile='md'
          customColor={theme.colors.neutral.dark20}
        >
          {t('Aqui você encontra os materiais adicionados pelo seu professor.')}
        </Text2>
      </Grid>
      <Grid item>
        {
          stateMachine[currentState]
        }
      </Grid>
    </>
  )
}
