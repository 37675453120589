import { useTranslation } from 'react-i18next'
import { useStore } from 'store'

import { Alert, Button } from 'components/design-system'
import { useTheme } from '@mui/material'
import { IUserSchoolProfileTypeEnum } from 'services/types'

export interface ILoadMoreErrorState {
  tryAgain: () => Promise<void>
}

export const LoadMoreErrorState = ({ tryAgain }: ILoadMoreErrorState) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  const ActionButton = () => (
    <Button size='small' style={{ color: theme.colors.negative.feedbackDarkest }} variant='ghost' onClick={tryAgain} data-testid={profileType === IUserSchoolProfileTypeEnum.student ? 'activities_to_answer_reload' : 'activities_to_correct_reload'}>
      {t('Carregar cards')}
    </Button>
  )

  return (

    <Alert
      content={t('Erro ao carregar cards... Tente novamente.')}
      severity='error'
      style={{ marginTop: '20px', width: '100%' }}
      action={<ActionButton />}
    />
  )
}
