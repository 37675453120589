import { Grid, Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

const DisabledButtonMessage = () => {
  const { t } = useTranslation()

  return (
    <Grid item xs={12} sx={{ padding: '4px' }}>
      <Alert severity='warning' icon={<></>}>
        {t('O botão ficará ativo após responder todas as questões')}
      </Alert>
    </Grid>
  )
}

export default DisabledButtonMessage
