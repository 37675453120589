import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { contentUnitCardAtom, openContentUnitModalAtom } from '../atomStore'
import { ContentAreaContainer as ContentArea, ContentModalCover } from './components'
import useStyles from './style'
import { useAtom } from 'jotai'
import { Box } from '@mui/material'
import { LoadingWrapper } from 'components/common'
import { getContentsUnitsSchedule } from 'services/schedule'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { IContentUnitResponse } from 'services/types'
import useWindowDimensions from 'utils/dimensions/useWindowDimensions'
import { getActivitiesByContentUnit } from 'services/activity'

export const ContentUnitModal = () => {
  const [openContentUnitModal, setOpenContentUnitModal] = useAtom(openContentUnitModalAtom)
  const [contentUnitCard] = useAtom(contentUnitCardAtom)

  const { classId } = useParams<{ classId: string }>()

  const { height } = useWindowDimensions()

  const [content, setContent] = useState<IContentUnitResponse | null>(null)
  const handleClose = () => setOpenContentUnitModal(false)

  const getContent = async () => {
    setContent(null)
    try {
      const response = await getContentsUnitsSchedule(classId, contentUnitCard?.contenUnit?.toString() as string)
      const responseActivities = await getActivitiesByContentUnit(classId, contentUnitCard?.contenUnit?.toString() as string)
      if (response.success) {
        setContent({
          ...response.data,
          /* Estamos filtrando para apenas imprimir em tela as atividades 'template'
            Seria ideal, passarmos a consumir um endpoint só, atualmente temos dois endpoint que faz praticamente a mesma coisa:
              - endpoint 1 - /activities/${classId}/content/${contentId}?suggested_application_type=${activityType}
              - endpoint 2(responsavel por alimentar nosso componente ContentUnitModal) - /v1/activities/${classId}/content/${contentUnit}`
            a unica diferença entre eles é "activityType"
          */
          activities: responseActivities.data.filter(cur => cur.custom_type !== 'clone')
        })
      } else {
        setContent(null)
      }
    } catch (ex) {
      console.log('error')
    }
  }

  useEffect(() => {
    if (contentUnitCard?.contenUnit) {
      void getContent()
    }
  }, [contentUnitCard])

  const classes = useStyles()

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openContentUnitModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openContentUnitModal}>
          <Box className={`${classes.modalWrapper} ${height < 700 ? 'lowheight' : ''}`}>
            <ContentModalCover/>
            <LoadingWrapper type='linear' loading={content === null}>
              {
                content
                  ? <ContentArea content={content}/>
                  : 'Houve um problema com o conteúdo solicitado'
              }
            </LoadingWrapper>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
