import { IDisciplineResponse } from 'services/types'
import { flatDeep } from 'utils/array'

import { DisabledMenuSetup } from './types'

export const disableDidacticContent = (setup: DisabledMenuSetup[] | null | undefined, disciplines: IDisciplineResponse[] | null | undefined, code: string | null | undefined) => {
  // se qualquer um vier em branco nåo filtra nada
  if (!setup || !disciplines || !code) return true

  const contentsDisciplinesCode = disciplines.reduce((acc, cur) => {
    acc.push(cur.code)
    return acc
  }, ['ALL'])

  const arr = setup.filter(cur => cur.grade === code).map(cur => cur.disciplines)
  return flatDeep(arr).some(cur => contentsDisciplinesCode.includes(cur))
}

export const disableEvaluations = (setup: DisabledMenuSetup[] | null | undefined, disciplines: IDisciplineResponse[] | null | undefined, code: string | null | undefined, type: string) => {
  // se qualquer um vier em branco nåo filtra nada
  if (!setup || !disciplines || !code) return true
  // se não for avaliação não filtra
  if (type !== 'avaliacoes') return true

  const contentsDisciplinesCode = disciplines.reduce((acc, cur) => {
    acc.push(cur.code)
    return acc
  }, ['ALL'])

  const arr = setup.filter(cur => cur.grade === code).map(cur => cur.disciplines)
  return !flatDeep(arr).some(cur => contentsDisciplinesCode.includes(cur))
}
