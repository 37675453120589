import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined'
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined'
import Grid from '@mui/material/Grid'
import { Brand, Icon } from 'components/design-system'
import { StatusClassHandle, CurrentClass } from 'components/common'
import { NavLink, useLocation } from 'react-router-dom'
import {
  activeMenuSidebarAtom
} from '../../../atomStore'
import { useAtom } from 'jotai'
import { CONTENTS_STUDENT, ROOT, STUDENT_TIMELINE, STUDENT_SCHEDULE, RESULTS_TRACKING } from 'navigation/CONSTANTS'
import { ILocation } from 'services/types/routes'
import Analytics from 'utils/analytics'
import { useCurricularStore, useStore } from 'store'
import { ReactComponent as WhatsappIconImage } from 'assets/whatsapp-icon.svg'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

export const SidebarStudent: React.FC = () => {
  const [activeMenuSidebar, setActiveMenuSidebar] = useAtom(
    activeMenuSidebarAtom
  )
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation<ILocation>()
  const { subscription } = useStore()
  const { currentClass } = useCurricularStore()

  const eventAnalytics = () => {
    if (subscription) {
      Analytics.recordEventClick({
        name: 'content',
        attributes: subscription?.analytics
      })
    }
  }

  const UpperMenu = () => (
    <div
      className={`${classes.box} ${classes.upperBox} ${!activeMenuSidebar && classes.beforeMobile}`}
    >
      <div className={classes.top} />
      <div className={classes.inLineBrandMobile}>
        <Icon
          titleAccess='CloseIcon'
          onClick={() => setActiveMenuSidebar(!activeMenuSidebar)}
          size='large'
        >
          <CloseOutlinedIcon />
        </Icon>
        <Brand size='sm' type='logo' variant='white' />
      </div>
      <StatusClassHandle>
        <CurrentClass>
          <NavLink className={classes.navlink} to={ROOT} data-testid='menu_home_student_access_menu_home'>
            <Grid
              className={`navButton ${location.pathname === ROOT && 'is-active'}`}
              onClick={eventAnalytics}
            >
              <Icon titleAccess={t('Página inicial')} size='medium'>
                <HomeOutlinedIcon />
              </Icon>
              <p>{t('Página inicial')}</p>
            </Grid>
          </NavLink>
        </CurrentClass>
      </StatusClassHandle>
      <StatusClassHandle>
        <CurrentClass>
          <NavLink className={classes.navlink} to={STUDENT_SCHEDULE} data-testid='menu_home_student_access_menu_schedule'>
            <Grid className={`navButton ${location.pathname === STUDENT_SCHEDULE && 'is-active'}`}>
              <Icon titleAccess={t('Agenda')} size='medium'>
                <InsertInvitationOutlinedIcon />
              </Icon>
              <p>{t('Agenda')}</p>
            </Grid>
          </NavLink>
        </CurrentClass>
      </StatusClassHandle>
      <NavLink className={classes.navlink} to={CONTENTS_STUDENT} data-testid='menu_home_student_access_menu_content'>
        <Grid
          className={`navButton ${location.pathname === CONTENTS_STUDENT && 'is-active'}`}
          onClick={eventAnalytics}
        >
          <Icon titleAccess={t('Conteúdo')} size='medium'>
            <SchoolOutlinedIcon />
          </Icon>
          <p>{t('Conteúdo')}</p>
        </Grid>
      </NavLink>
      <NavLink className={classes.navlink} to={STUDENT_TIMELINE} data-testid='menu_home_student_access_menu_registers'>
        <Grid
          className={`navButton ${location.pathname === STUDENT_TIMELINE && 'is-active'}`}
          onClick={() => eventAnalytics}
        >
          <Icon titleAccess={t('Meus registros')} size='medium'>
            <ViewTimelineOutlinedIcon />
          </Icon>
          <p>{t('Meus registros')}</p>
        </Grid>
      </NavLink>
      {!!currentClass?.grades && !currentClass?.grades[0]?.code?.includes('EI_') && (
        <NavLink className={classes.navlink} to={RESULTS_TRACKING} data-testid='menu_home_student_access_menu_followup'>
          <Grid className={`navButton ${location.pathname === RESULTS_TRACKING && 'is-active'}`}>
            <Icon titleAccess={t('Acompanhamento')} size='medium'>
              <RuleFolderOutlinedIcon />
            </Icon>
            <p>{t('Acompanhamento')}</p>
          </Grid>
        </NavLink>
      )}
      <div className={classes.bottom} />
    </div>
  )

  const LowerMenu = () => (
    <Grid className={`${classes.box} ${classes.downBox} ${!activeMenuSidebar && classes.beforeMobile}`}>
      <div className={`${classes.top} topBevel`} />
      <a className={classes.navlink} data-testid='menu_help_center_faq' href={'https://centraldoconhecimento.cloeapp.com/perguntas-frequentes'} target='_blank'>
        <Grid className={'navButton'}>
          <Icon titleAccess={t('FAQ')} size='medium'>
            <HelpOutlineOutlinedIcon />
          </Icon>
          <p>{t('FAQ')}</p>
        </Grid>
      </a>
      <a className={classes.navlink} data-testid='menu_help_center_whatsapp' href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_SUPPORT_NUMBER ?? '5511986588112'}&text&type=phone_number&app_absent=0`} target='_blank'>
        <Grid className={'navButton'}>
          <WhatsappIconImage className={classes.svgCustomIcon} />
          <p>{t('Whatsapp')}</p>
        </Grid>
      </a>
    </Grid>
  )

  return (
    <div
      id='ui-sidebar_student'
      onClick={() => setActiveMenuSidebar(!activeMenuSidebar)}
      className={`${classes.contentBox} ${!activeMenuSidebar && classes.beforeMobile}`}
    >
      <UpperMenu />
      <LowerMenu />
    </div>
  )
}
