import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(dayjsUTC)
dayjs.extend(customParseFormat)

export const addDay = (date: Date, value: number, unit: 'day' | 'week' = 'day'): Date => {
  const newDate = dayjs(date).add(value, unit)
  return newDate.toDate()
}

export const subtractDay = (date: Date, value: number, unit: 'day' | 'week' = 'day'): Date => {
  const newDate = dayjs(date).subtract(value, unit)
  return newDate.toDate()
}

export const parseToString = (date: Date) => {
  const newDate = dayjs(date)
  return newDate.toDate().toISOString().slice(0, 10)
}

export const ddMMyyyy = (date: Date): string => {
  const newDate = dayjs(date)
  return newDate.toDate().toISOString().slice(0, 10)
}
export const yyyyMMdd = (date: Date): string => dayjs(date).format('YYYY-MM-DD')

export const toStringDay = (date: Date): string => {
  const newDate = dayjs(date)
  return newDate.toDate().toLocaleString('pt-br', { day: '2-digit' })
}

export const toStringMonth = (date: Date) => {
  const newDate = dayjs(date)
  return newDate.toDate().toLocaleString('pt-br', { month: '2-digit' })
}

export const convertToLocaleUTC = (date: Date) => dayjs.utc(date).local().format()

export const setToLocaleUTC = (date: Date) => dayjs(date).utc().local().format() // mudar para UTC local sem alteração de horário

export const removeTimezoneFromDate = (date: Date | string) => new Date(new Date(date).toISOString().slice(0, -1)) // removes Z from timestamp

export const getPartialDateFormatted = (dateValue: string, datePart: 'day' | 'month' | 'year') => {
  const datePartials = {
    day: 'DD',
    month: 'MM',
    year: 'YYYY'
  }

  return dayjs(dateValue.replace(/t.+/i, ''), 'YYYY-MM-DD').format(datePartials[datePart])
}

export const getDayFormatted = (dateValue: string): string => getPartialDateFormatted(dateValue, 'day')
export const getMonthFormatted = (dateValue: string): string => getPartialDateFormatted(dateValue, 'month')
export const getYearFormatted = (dateValue: string): string => getPartialDateFormatted(dateValue, 'year')

export const weekDaysInitials = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb'
]

export const weekDaysTitles = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado'
]

export const monthsTitle = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]
