/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRecordEventClick, IAnalytics, IDataSetup } from '../interfaces'

export class AnalyticsProvider {
  private readonly services: IAnalytics[]
  private defaultAttributes: Object = {}

  constructor(services: IAnalytics[]) {
    this.services = services
  }

  public setup(data: IDataSetup) {
    this.services.filter(cur => cur.hasSetup).forEach(cur => {
      cur.setup(data)
      return null
    })
  }

  public recordEventClick(data: IRecordEventClick, filterByName?: any[string]) {
    data.attributes = { ...this.defaultAttributes, ...data?.attributes }

    let filter = (cur: IAnalytics) => true
    if (filterByName?.length) {
      filter = (cur: IAnalytics) => cur?.name && filterByName.includes[cur.name]
    }
    this.services.filter(filter).forEach(cur => {
      cur.recordEventClick(data)
      return null
    })
  }

  public async recordEventClickSync(data: IRecordEventClick, filterByName?: any[string]): Promise<unknown> {
    data.attributes = { ...this.defaultAttributes, ...data?.attributes }

    let filter = (cur: IAnalytics) => true
    if (filterByName?.length) {
      filter = (cur: IAnalytics) => cur?.name && filterByName.includes[cur.name]
    }
    // eslint-disable-next-line @typescript-eslint/return-await
    const events = this.services.filter(filter).map(async cur => await cur.recordEventClickSync(data))
    return await Promise.all(events)
  }

  public setDefaultAttributes(attributes: Object): void {
    this.defaultAttributes = attributes
    this.recordEventClick({
      name: 'setSchoolPeriod'
    })
  }
}
