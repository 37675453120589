import { makeDatabaseInstanceOffline, IAnswerCached } from 'contentCacheManager'
import { getAnswerOffline } from './getAnswerOffline'

export const deleteAnswerFile = async (params: Pick<IAnswerCached['requestAnswer'], 'activity' | 'class' | 'content_unit'>, questionId: number, fileName: string) => {
  const database = makeDatabaseInstanceOffline()
  const found = await getAnswerOffline(params)

  if (found) {
    const currentFile = found.filesOffline.find(file => file.question_id === questionId)
    if (currentFile) {
      const newFiles = currentFile.files.filter(file => file.fileName !== fileName)
      const newFileList = { ...currentFile, files: newFiles }
      const allFilesOffline = found.filesOffline.filter(fileOffline => fileOffline.question_id !== questionId).concat([newFileList])
      return await database.answersCached.update(found, { filesOffline: allFilesOffline })
    }
  }
}
