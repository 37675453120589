import { getLinkLmsPlatform, getLinkLmsInitialTeacherTrainingModule, getLinkLmsCloeExpandTeacherModule } from 'services/lms-platform'

import { toast } from 'components/design-system/Toast/manager'
import { TFunction } from 'react-i18next'
import Analytics from 'utils/analytics'
import { IProfilesSessionResponse } from 'services/types'

const handleEventAnalytics = (type: 'success' | 'error', profile: IProfilesSessionResponse | null) => {
  Analytics.recordEventClick({
    name: `redirect_lms_plataform_${type}`,
    attributes: {
      ...profile?.analytics
    }
  })
}

export function useLmsPlatform(t: TFunction, profile?: IProfilesSessionResponse | null) {
  const getLink = async () => {
    try {
      toast.handler({
        content: t('Você será redirecionado em instantes'),
        severity: 'info',
        id: 'sso'
      })
      const response = await getLinkLmsPlatform()
      toast.destroy('sso')

      if (!response.data.url) throw new Error()

      setTimeout(() => {
        window.open(response.data.url)
      })

      if (profile) handleEventAnalytics('success', profile)
    } catch (err) {
      if (profile) handleEventAnalytics('error', profile)
      toast.handler({
        content: t('Não foi possível redirecionar para Formação'),
        duration: 3000,
        severity: 'error'
      })
    }
  }

  const getLinkInitialTeacherTrainingModule = async () => {
    try {
      toast.handler({
        content: t('Você será redirecionado em instantes'),
        severity: 'info',
        id: 'sso_initial_teacher_training_module'
      })

      const response = await getLinkLmsInitialTeacherTrainingModule()
      toast.destroy('sso_initial_teacher_training_module')
      if (!response.data.url) throw new Error()
      setTimeout(() => {
        window.open(response.data.url)
      })
    } catch (err) {
      toast.handler({
        content: t('Não foi possível redirecionar para o modulo de Formação Inicial'),
        duration: 3000,
        severity: 'error'
      })
    }
  }

  const getLinkCloeExpandTeacherModule = async () => {
    try {
      toast.handler({
        content: t('Você será redirecionado em instantes'),
        severity: 'info',
        id: 'sso_cloe_expand_teacher_module'
      })

      const response = await getLinkLmsCloeExpandTeacherModule()
      toast.destroy('sso_cloe_expand_teacher_module')
      if (!response.data.url) throw new Error()
      setTimeout(() => {
        window.open(response.data.url)
      })
    } catch (err) {
      toast.handler({
        content: t('Não foi possível redirecionar para o modulo de Formação Cloe Expand'),
        duration: 3000,
        severity: 'error'
      })
    }
  }

  return { getLink, getLinkInitialTeacherTrainingModule, getLinkCloeExpandTeacherModule }
}
