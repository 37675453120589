import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  emptyStateTitle: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.lg,
    lineHeight: theme.font.lineHeight.sm,
    textAlign: 'center',
    color: theme.colors.neutral.dark30,
    maxWidth: '759px',
    margin: '0 auto !important',

    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: theme.font.fontSize.md
    }
  },
  schedulesTime: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacingInline.nano,
    color: theme.colorBrand.dark,
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.md,
    lineHeight: theme.font.lineHeight.sm,
    '& svg': {
      fontSize: theme.font.fontSize.sm
    }
  },
  createEventButton: {
    margin: `${theme.spacingStack.xxs} 0 ${theme.spacingStack.xs} !important`,
    padding: `${theme.spacingStack.nano} ${theme.spacingStack.xxxs} !important`
  }
}))
