import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  navationButtonWrapper: {
    position: 'absolute',
    top: theme.spacingInline.nano,
    right: theme.spacingInline.nano
  },
  dropdownWrapper: {
    padding: `0 ${theme.spacingInline.nano}`,
    width: '245px'
  },
  dropdownTitle: {
    color: theme.colors.neutral.dark20,
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xs,
    lineHeight: '17px',
    letterSpacing: '0.01em'
  },
  dropdownWrapperButtons: {
    padding: `0 ${theme.spacingInline.nano}`
  },
  dropdownButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacingInline.nano,
    marginTop: theme.spacingInline.nano
  },
  navationButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: theme.font.lineHeight.xxs,
    '&.disabled': {
      color: theme.colors.neutral.light30
    },
    '& > svg': {
      marginRight: theme.spacingInline.nano,
      height: theme.spacingStack.xxxs
    }
  }
}))
