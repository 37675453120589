import { Header, Sidebar } from 'navigation/components'
import { useToggleOpenMyCloeAtom } from 'navigation/components/MyCloe/atomStore'

import BarInfoPreviousSchool from './components/BarInfoPreviousSchool'
import useStyle from './style'
import { useAtomValue } from 'jotai/utils'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'

const BaseScreen: React.FC = ({ children }) => {
  const { toggleOpenMyCloeAtom } = useToggleOpenMyCloeAtom()
  const openMyCloe = useAtomValue(toggleOpenMyCloeAtom)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyle({ openMyCloe })

  const myCloeStyles = {
    desktop: {
      opened: { width: 'calc(100% - 360px)' },
      closed: { width: 'calc(100% - 40px)' }
    },
    mobile: {
      opened: { width: 'calc(100% - 40px)' },
      closed: { width: 'auto' }
    }
  }

  const baseWrapperStyle = useMemo(() =>
    myCloeStyles[isMobile ? 'mobile' : 'desktop'][openMyCloe ? 'opened' : 'closed'], [isMobile, openMyCloe])

  return (
    <div id='pages'>
      <Header />
      <Sidebar />
      <Box
        className={classes.baseScreenWrapper}
        sx={baseWrapperStyle}
      >
        <div className={classes.baseScreenContent}>
          <BarInfoPreviousSchool />
          {children}
        </div>
      </Box>
    </div>
  )
}

export default BaseScreen
