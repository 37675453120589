import { Theme, CircularProgress, LinearProgress, useTheme } from '@mui/material'
import { linearProgressClasses } from '@mui/material/LinearProgress'
import { createStyles, makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme: Theme) => createStyles({
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  root: {
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.colorBrand.medium
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.colorBrand.lightest
    }
  }
}))

interface ILoadingProps {
  type?: 'circular' | 'linear'
  className?: string
}

export const Loading: React.FC<ILoadingProps> = ({ type = 'circular', className }) => {
  const theme = useTheme()
  const classes = useStyle()

  const componentTypes = {
    circular: (
      <div className={`${classes.loadingWrapper} ${className}`}>
        <CircularProgress sx={{ color: theme.colorBrand.medium }} />
      </div>
    ),
    linear: (
      <div className={className}>
        <LinearProgress className={classes.root} />
      </div>
    )
  }

  return (componentTypes[type])
}

export default Loading
