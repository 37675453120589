import { Theme } from '@mui/material'

import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    block: {
      '&.MuiButtonBase-root': {
        display: 'none'
      }
    },

    dissapear: {
      '&.MuiButtonBase-root': {
        transition: 'all ease-in 0.2s',
        position: 'sticky',
        bottom: '-100px',
        left: '50%',
        opacity: '0',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(96, 63, 146, 0.8)',
        zIndex: '10',
        width: 'fit-content',
        [theme.breakpoints.up('sm')]: {
          visibility: 'hidden',
          width: 0,
          height: 0
        }
      }
    },

    scrollDown: {
      '& .MuiButtonBase-root': {
        transition: 'all ease-in 0.1s',
        position: 'sticky',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(96, 63, 146, 0.8)',
        zIndex: '10',
        width: 'fit-content',
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        }
      }
    },

    scrollTop: {
      '&.MuiButtonBase-root': {
        transition: 'all ease-in 0.1s',
        position: 'sticky',
        bottom: '10px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(96, 63, 146, 0.8)',
        left: '50%',
        transform: 'translateX(-50%)',
        opacity: '0.2',
        zIndex: '10',
        width: 'fit-content',
        [theme.breakpoints.up('sm')]: {
          visibility: 'hidden',
          width: 0,
          height: 0
        }
      }
    }
  })
)
