import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    itemContent: {
      borderRadius: theme.borderRadius.md,
      border: `1px solid ${theme.colors.neutral.light20}`,
      margin: theme.spacingStack.xxxs,
      padding: theme.spacingStack.xxs,
      width: '500px',
      flex: '1 1 auto',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacingStack.nano,
        margin: theme.spacingStack.nano,
        maxWidth: '100%',
        flexWrap: 'wrap'
      }
    },
    itemHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacingStack.quarck
    },
    classesItensText: {
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.md,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.darkBase
    },
    closeIcon: {
      color: theme.colors.neutral.light40
    },
    itemContainer: {
      display: 'flex',
      paddingInline: theme.spacingStack.sm,
      [theme.breakpoints.down('sm')]: {
        height: '100%'
      }
    },
    schoolIcon: {
      color: theme.colorBrand.medium
    },
    tag: {
      cursor: 'pointer',
      '&.disabled': {
        cursor: 'not-allowed'
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: theme.spacingInset.md,
        alignItems: 'center',
        display: 'flex'
      }
    },
    tagsContainer: {
      display: 'flex',
      flexFlow: 'wrap',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      marginBlock: theme.spacingStack.xxxs,
      gap: theme.spacingStack.nano,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        fontSize: theme.font.fontSize.sm,
        overflowX: 'auto',
        transition: 'all ease-in-out 0.2s',
        fontWeight: theme.font.fontWeight.semibold,
        '&.active': {
          flexFlow: 'wrap'
        }
      }
    },
    itemclass: {
      paddingInline: theme.spacingStack.nano,
      fontSize: theme.font.fontSize.md,
      color: theme.colorBrand.medium,
      linheHeith: theme.font.lineHeight.xs,
      fontWeight: theme.font.fontWeight.semibold,
      margin: '0px'
    },
    boxButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: theme.spacingSquish.md,
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.colors.neutral.lightBase,
      boxShadow: '0px -4px 8px rgba(20, 20, 20, 0.16)',
      width: '100%'
    },
    classButton: {
      paddingLeft: theme.spacingInline.xxs
    }
  })
)
