import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    textDecoration: 'none !important',
    maxWidth: '600px',
    width: '100%',

    [theme.breakpoints.up('xl')]: {
      width: '70%',
      maxWidth: '70%'
    }
  },
  nonEmbeddedResource: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    position: 'relative',
    borderRadius: theme.borderRadius.sm,
    border: `1px solid ${theme.colors.neutral.light10}`,
    margin: '0 auto',
    maxWidth: '600px',
    padding: theme.spacingInline.xxxs,
    transition: 'transform 0.5s',
    background: theme.colors.neutral.lightBase,
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0px 8px 24px 0px rgba(20, 20, 20, 0.16)',
      '& svg': {
        color: theme.colors.support.colorSupport02
      }
    }
  },
  type: {
    position: 'absolute',
    color: 'rgba(184, 48, 194, 0.4)',
    top: theme.spacingInline.xxxs,
    right: theme.spacingInline.xxxs,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& span': {
      marginLeft: theme.spacingInline.quarck
    }
  },
  title: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.bold,
    lineHeight: '24px',
    letterSpacing: '0.16px',
    textTransform: 'uppercase',
    color: theme.colors.neutral.dark20
  },
  description: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '24px',
    letterSpacing: '0.14px',
    color: theme.colors.neutral.dark10,
    '& p': {
      margin: 0
    }
  },
  source: {
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '24px',
    letterSpacing: '0.14px',
    color: theme.colors.neutral.dark40,
    textTransform: 'uppercase'
  },
  tag: {
    display: 'flex',
    '& > label': {
      fontWeight: theme.font.fontWeight.regular
    }
  }
}))
