import React, { useEffect, useState } from 'react'
import { Modal, Backdrop, Grid, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { useStore } from 'store'

import useStyles from './style'

import { StepsContainer } from './components/Steps'
import { getSchoolsClasses } from 'services/activity'
import { ISchoolsClassResponse } from 'services/types'
import { IGradeResponse } from 'services/types/taught-disciplines'
import { LoadingWrapper } from 'components/common'
import { getGradeByCode } from 'services/grade'

const getOrientation = () => {
  if (window.innerHeight > window.innerWidth) {
    return 'portrait'
  }

  return 'landscape'
}

const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const GoToActivity: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { schoolPeriod } = useStore()

  const [schoolsClassesResponse, setSchoolsClassesResponse] = useState<ISchoolsClassResponse | null>(null)
  const [grade, setGrade] = useState<IGradeResponse | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [orientation, setOrientation] = useState(getOrientation())

  const query = new URLSearchParams(useLocation().search)
  const activityId = query.get('activity_id') ?? ''
  const gradeCode = query.get('grade_code') ?? ''
  const contentUnitId = query.get('content_unit_id') ?? ''

  const updateOrientation = (event: any) => {
    if (event?.target?.screen?.orientation?.type) {
      setOrientation(event?.target?.screen?.orientation?.type)
    } else {
      if (event?.target?.orientation === 0) setOrientation('')
      if (event?.target?.orientation === 90) setOrientation('landscape')
    }
  }

  const getSchoolsClass = async (contentUnit: string | number | undefined) => {
    if (!contentUnit || !schoolPeriod) return

    try {
      const { data } = await getSchoolsClasses(activityId, contentUnit, gradeCode, schoolPeriod.id)
      setSchoolsClassesResponse(data)
      await getGrade()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getGrade = async () => {
    const { data } = await getGradeByCode(gradeCode)

    if (data?.length > 0) {
      setGrade(data[0])
    }
  }

  useEffect(() => {
    getSchoolsClass(contentUnitId)

    window.addEventListener(
      'orientationchange',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'orientationchange',
        updateOrientation
      )
    }
  }, [])

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <LoadingWrapper loading={isLoading} type='linear'>
          <Grid
            className={classes.container}
            sx={{
              ...((orientation || '').includes('landscape') && isMobileDevice && {
                width: '100%',
                height: '100%',
                display: 'grid',
                gridTemplateRows: 'auto 10fr 1fr',
                transform: 'unset',
                top: '0',
                borderRadius: '0px',
                left: '0',
                maxWidth: 'unset',
                maxHeight: '100vh',
                ...theme.scroll,
                overflowY: 'auto'
              })
            }}
          >
            <StepsContainer
              activityId={activityId}
              contentUnitId={contentUnitId}
              grade={grade}
              gradeCode={gradeCode}
              schoolsClassesResponse={schoolsClassesResponse}
            />
          </Grid>
        </LoadingWrapper>
      </Modal>
    </div>
  )
}

export default GoToActivity
