import { useTranslation } from 'react-i18next'

import { Button } from 'components/design-system'

interface IParentalMessageReady {
  loadMore: () => Promise<void>
}

export const ParentalMessageReady = ({ loadMore }: IParentalMessageReady) => {
  const { t } = useTranslation()
  return (
    <Button
      variant='outlined'
      onClick={loadMore}
      data-testid=''
    >
      {t('Carregar mais...')}
    </Button>
  )
}
