import React, { useEffect } from 'react'
import { useStore, useCurricularStore } from 'store'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { useHistory } from 'react-router'
import { CHANGE_DEFAULT_PASSWORD, LOGIN, TAUGHT_DISCIPLINES } from 'navigation/CONSTANTS'
import { useAtom } from 'jotai'
import { isBlockedNavigationAtom, windowLoadingAtom } from './atomStore'
import { useAuth } from './ProvideAuth'
import { LoadingWrapper } from 'components/common'
import { withRouter } from 'react-router-dom'
import { useRouter } from './hooks/useRouter'

const AppMiddleware: React.FC = ({ children }) => {
  const { profile, session, subscription } = useStore()
  const auth = useAuth()
  const history = useHistory()
  const { pathname } = useRouter()
  const [, setIsBlockedNavigation] = useAtom(isBlockedNavigationAtom)
  const [loading, setLoading] = useAtom(windowLoadingAtom)
  const { setStoreDisciplines } = useCurricularStore()

  const isValidCurrentUser = async () => {
    const blockedPaths = [CHANGE_DEFAULT_PASSWORD, LOGIN]
    if (blockedPaths.includes(pathname)) return

    await auth?.currentUser()
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  const hasTeacherTaughtDisciplines = async () => {
    const profileType = profile?.type
    const countSubscriptions = session?.subscriptions
      ?.filter(subscription => subscription.user_school_profile.id === profile?.id && subscription.class?.active)
      .reduce((acc, subscription) => acc + subscription.taught_disciplines.length, 0)

    // TODO: Alterar fluxo de validação e redirecionamento de rotas
    // Somente redirecionar professor se tiver aceito os termos de uso da aplicação

    if (profileType !== IUserSchoolProfileTypeEnum.teacher || !auth?.user?.accept_terms) {
      return
    }

    if (countSubscriptions === 0) {
      setIsBlockedNavigation(true)
      history.push(TAUGHT_DISCIPLINES)

      return
    }

    setIsBlockedNavigation(false)
  }

  useEffect(() => {
    if (subscription?.class?.id) setStoreDisciplines({ theClass: subscription?.class?.id })
  }, [subscription])

  useEffect(() => {
    isValidCurrentUser()
  }, [])

  useEffect(() => {
    if (session ?? profile) {
      // TODO: tirar settimeout
      // coloquei o timeout pois está chegando mais rapido que o profile entrar como middleware nas requisicoes
      setTimeout(() => {
        void hasTeacherTaughtDisciplines()
      }, 1000)
    }
  }, [auth?.user, profile, session])

  return (
    <LoadingWrapper loading={loading} fillContainer>
      {children}
    </LoadingWrapper>
  )
}

export default withRouter(AppMiddleware)
