import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { addTransparencyToColor } from 'utils/colors'

export default makeStyles((theme: Theme) => {
  return {
    scheduleDescription: {
      backgroundColor: addTransparencyToColor(theme.colorBrand.lightest, 0.32),
      borderRadius: theme.borderRadius.sm,
      width: '100%',
      // marginTop: theme.spacingStack.xxs,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacingInline.nano,
      padding: `${theme.spacingInline.xxxs}`
    },
    noDescriptionText: {
      color: theme.colors.neutral.light40,
      fontStyle: 'italic'
    },
    teacherDescriptionInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacingInline.nano
    },
    descriptionField: {
      wordBreak: 'break-word'
    }
  }
})
