import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { BarInfoPreviousSchoolProps } from './types'

export default makeStyles((theme: Theme) => createStyles({
  '@keyframes slideAnimationBarInfo': {
    from: {
      transform: 'translateY(-15%)',
      opacity: 0
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1
    }
  },
  barInfoPreviousSchoolContainer: (props: BarInfoPreviousSchoolProps) => ({
    backgroundColor: theme.colors.contentType.default,
    padding: theme.spacingInline.xxxs,
    height: 'fit-content',
    color: theme.colors.neutral.lightBase,
    animation: '$slideAnimationBarInfo 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transition: 'all 0.3s ease',
    borderTopLeftRadius: theme.borderRadius.md,
    borderTopRightRadius: theme.borderRadius.md,
    paddingBottom: props.isContentUnitPage ? '28px' : theme.spacingInline.xxxs,

    [theme.breakpoints.down('sm')]: {
      marginTop: props.isContentUnitPage ? '42px' : '50px',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: theme.borderRadius.md,
      borderBottomRightRadius: theme.borderRadius.md,

      zIndex: 2,
      marginBottom: '-60px',
      paddingBottom: theme.spacingInline.xxxs
    }
  }),
  barInfoPreviousSchoolContent: {
    position: 'relative',
    fontSize: theme.font.fontSize.sm,

    [theme.breakpoints.down('sm')]: {
      display: 'block !important',
      paddingLeft: theme.spacingInline.xs,
      paddingRight: theme.spacingInline.xs
    }
  },
  barInfoPreviousSchoolButton: {
    borderRadius: '20px',
    padding: theme.spacingInline.nano,
    border: `2px solid ${theme.colors.neutral.lightBase}`,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.semibold,
    marginTop: `${theme.spacingInline.nano} !important`,
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  barInfoPreviousSchoolIconInfo: {
    marginRight: 10,

    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      left: 5
    }
  },
  barInfoPreviousSchoolSwitchButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacingInline.xxxs,
    paddingTop: theme.spacingInline.nano,
    cursor: 'pointer'
  },
  txtUnderlined: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))
