import { Grid } from '@mui/material'

import { IUserSchoolProfileTypeEnum } from 'services/types'
import OnlyProfile from 'components/common/OnlyProfile'

import useStyles from '../../style'

import imageTeacher from '../../assets/person-using-phone--teacher.png'
import imageStudent from '../../assets/person-using-phone--student.png'

export const CustomImage = () => {
  const classes = useStyles()

  return (
    <>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <Grid className={classes.backgroundImage} sx={{ backgroundImage: `url(${imageTeacher})` }} />
      </OnlyProfile>

      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <Grid className={classes.backgroundImage} sx={{ backgroundImage: `url(${imageStudent})` }} />
      </OnlyProfile>
    </>
  )
}
