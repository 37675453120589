import { CheckCircleOutline, ContentCopy, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import { useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    paddingBlock: theme.spacingInline.xxxs,
    color: theme.colors.support.colorSupport03,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.regular,
    lineHeight: '24px',
    letterSpacing: '0.16px',

    '& svg': {
      color: theme.colors.neutral.dark40,
      cursor: 'pointer',
      transition: 'color .5s ease-in-out',
      '&.copied': {
        color: theme.colors.positive.feedbackMedium
      }
    }
  },
  valueContent: {
    flex: 1
  }
}))

interface IProps {
  value: string
}

export const IdentifierField = (props: IProps) => {
  const { value } = props
  const [showValue, setShowValue] = useState(false)
  const [copied, setCopied] = useState(false)
  const style = useStyle()

  const valueBind = useMemo(() => {
    if (showValue) return value
    else return value.replace(/./g, '*')
  }, [showValue])

  return (
    <div className={style.container}>
      <div style={{ flex: 1 }}>
        {valueBind}
      </div>
      {
        copied
          ? <CheckCircleOutline className='copied' />
          : <ContentCopy
            onClick={async () => {
              await navigator.clipboard.writeText(value)
              setCopied(true)
              setTimeout(() => setCopied(false), 1000)
            }}
          />
      }
      {
        showValue
          ? <VisibilityOutlined onClick={() => setShowValue(false)} />
          : <VisibilityOffOutlined onClick={() => setShowValue(true)} />
      }
    </div>
  )
}

export default IdentifierField
