import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

import { Alert } from 'components/design-system'

import OnlyOffline from 'contentCacheManager/components/OnlyOffline'
import OnlyAnswered from 'contentCacheManager/components/OnlyAnswered'
import { IAnswerCachedStatus } from 'contentCacheManager'

export const OfflineSentAlert = () => {
  const [activity] = useAtom(activityCurrentAtom)
  const { t } = useTranslation()

  if (!activity) return null

  return (
    <OnlyOffline forceMobile>
      <OnlyAnswered activity={String(activity.id)} render={(data) => {
        if (data.find(answer => answer.status === IAnswerCachedStatus.SENT_ERROR)) {
          return <Alert content={t('Ocorreu um erro no envio das respostas.')} severity='error' style={{ margin: '0px 10px 20px 10px' }} />
        }
        if (data.find(answer => answer.status === IAnswerCachedStatus.SENT_SUCCESS)) {
          return <Alert content={t('Respostas enviadas com sucesso')} severity='success' style={{ margin: '0px 10px 20px 10px' }} />
        }

        return <Alert content={t('Suas respostas salvas serão enviadas automaticamente, após a conexão voltar.')} severity='info' style={{ margin: '0px 10px 20px 10px' }} />
      }} />
    </OnlyOffline>
  )
}
