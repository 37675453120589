import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  rootAuthContainer: {
    margin: '0 auto 0 109px',
    maxWidth: '360px',
    background: theme.colors.neutral.lightBase,
    padding: `${theme.spacingStack.xxs} ${theme.spacingStack.xxxs}`,
    borderRadius: '25px 25px 0 0',
    boxShadow: theme.shadowLevels.level4,
    minHeight: 'calc(100vh - 24px)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#C4C4C4',
      borderRadius: '40px',
      height: '80px',
      backgroundClip: 'content-box',
      border: 'solid 20px transparent'
    },

    // mobile
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      maxWidth: '100%',
      position: 'absolute',
      bottom: 0
    }
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacingStack.xxs,

    // mobile
    [theme.breakpoints.down('sm')]: {
      '& img': {
        height: '40px'
      }
    }
  }
}))
