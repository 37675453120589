import useStyles from 'pages/BaseAuthLayout/styles'
import { AuthLayoutBox } from './components/AuthLayoutBox'
import { Button } from 'components/design-system'
import HelpOutlineOutlined from '@mui/icons-material/HelpOutlineOutlined'
import { useTranslation } from 'react-i18next'
import { CLOE_HELP_CENTER_LINK } from './constants'

const BaseAuthLayout: React.FC = ({ children, ...props }) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.backgroundImage} />
      <div className={styles.backgroundImageIntersect} />
      <div className={styles.helpButtonContainer}>
        <Button className={styles.helpButton} href={CLOE_HELP_CENTER_LINK} target='_blank'>
          <HelpOutlineOutlined />
          <span>{t('Ajuda')}</span>
        </Button>
      </div>
      <div className={styles.root}>
        <AuthLayoutBox>
          {children}
        </AuthLayoutBox>
      </div>
    </div>
  )
}

export default BaseAuthLayout
