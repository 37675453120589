import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { CodeInputProps } from '.'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& input:not(:last-child)': {
      marginRight: '6px'
    },
    '& input': {
      fontFamily: 'monospace',
      borderRadius: '8px',
      border: (props: CodeInputProps) => props.isValid === false
        ? `2px solid ${theme.colors.negative.feedbackLight}`
        : `2px solid ${theme.colors.neutral.light10}`,
      padding: '12px 8px',
      width: '48px',
      height: '48px',
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.regular,
      boxSizing: 'border-box',
      color: theme.colors.support.colorSupport03,
      backgroundColor: 'white',
      textAlign: 'center',
      '&:focus-visible': {
        transition: 'all ease-in 0.15s',
        border: (props: CodeInputProps) => `2px solid ${props.isValid === false ? theme.colors.negative.feedbackLight : theme.colors.support.colorSupport01}`,
        outline: 'none'
      },
      '&:hover': {
        transition: 'all ease-in 0.15s',
        border: (props: CodeInputProps) => `2px solid ${props.isValid === false ? theme.colors.negative.feedbackLight : theme.colors.support.colorSupport01}`,
        outline: 'none'
      },
      '&::selection': {
        color: theme.colors.support.colorSupport03,
        background: 'none'
      }
    }
  }
}))
