import { useHistory } from 'react-router'

import { Grid } from '@mui/material'
import CardMUI from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { Button, Text2 } from 'components/design-system'

import useStyles from './styles'

interface IShortcutCardProps {
  title: string
  imageSrc: string
  description: string
  titleBtn: string
  goTo: string
  dataTestId: string
}

const ShortcutCard: React.FC<IShortcutCardProps> = ({ title, imageSrc, description, titleBtn, goTo, dataTestId }) => {
  const history = useHistory()

  const styles = useStyles()

  return (
    <CardMUI className={styles.shortcutCardContainer}>
      <div>
        <Grid item className={styles.shortcutCardHeader}>
          <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xxs' mobile='sm' colorbrand='medium'>
            {title}
          </Text2>
          <img src={imageSrc} />
        </Grid>
        <CardContent sx={{ p: 0 }}>
          <Text2 fontSize='sm' fontWeight='medium' lineHeight='xxs' mobile='sm' neutral='dark20'>
            {description}
          </Text2>
        </CardContent>
      </div>
      <CardActions sx={{ p: 0 }}>
        <Button
          fullWidth
          onClick={() => history.push(goTo)}
          data-testid={dataTestId}
          sx={{ height: '40px !important', fontSize: '14px !important' }}
        >
          {titleBtn}
        </Button>
      </CardActions>
    </CardMUI>
  )
}

export default ShortcutCard
