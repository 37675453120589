import React from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import ErrorBoundary from 'components/common/ErrorBoundary'
import { ContentUnitHeader, ResourceItem } from './components'

import useStyles from './style'

export const Resources: React.FC = () => {
  const classes = useStyles()

  const { contentId, resourcesIds } = useParams<{ contentId: string, resourcesIds: string }>()
  const resourcesIdsSplitted = resourcesIds?.split(',')

  return (
    <ErrorBoundary>
      <div className={classes.container}>
        <ContentUnitHeader contentId={contentId} />

        <Box className={classes.resourcesContainer}>
          {
            resourcesIdsSplitted?.map(resourceId => <ResourceItem resourceId={resourceId} contentId={contentId} />)
          }
        </Box>
      </div>
    </ErrorBoundary>
  )
}
