/* eslint-disable multiline-ternary */
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { addTransparencyToColor } from 'utils/colors'

interface IProps {
  cardExpanded?: boolean | string
  isActive?: boolean | string
  position?: number
  titleWidth?: string | number
  disabled?: boolean
  profileType?: IUserSchoolProfileTypeEnum
}

export default makeStyles((theme: Theme) => createStyles({
  cardWrapper: (props: IProps) => ({
    display: 'flex',
    marginRight: '10px',
    borderRadius: '8px',
    textDecoration: 'none',
    width: '100%'
  }),
  position: (props: IProps) => ({
    position: 'relative',

    color: props.isActive ? theme.colorBrand.dark : theme.colorBrand.medium,
    border: props.isActive ? `2px solid ${theme.colorBrand.dark}` : `2px solid ${theme.colorBrand.medium}`,
    borderRadius: theme.borderRadius.circular,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.xxs,
    width: '25px',
    height: '25px'
  }),
  wrapperDefault: (props: IProps) => ({
    display: 'flex',
    padding: theme.spacingInset.xxs,
    flexDirection: 'row',
    color: props.isActive ? theme.colorBrand.dark : theme.colorBrand.medium,
    borderRadius: '8px',
    border: 'none',
    flexWrap: 'wrap',
    cursor: props.disabled ? 'default' : 'pointer',
    transition: 'all ease-in-out 0.3s',
    width: '100%',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacingInset.xxs
    },

    ...(!props.isActive ? {} : {
      transition: 'all ease-in-out 0.2s',
      backgroundColor: theme.colorBrand.lightest,

      [theme.breakpoints.down('md')]: {
        backgroundColor: addTransparencyToColor(theme.colors.neutral.lightBase, 0.64)
      }
    }),

    ...(props.disabled ? {} : {
      '&:hover': {
        transition: 'all ease-in-out 0.2s',
        backgroundColor: props.profileType === IUserSchoolProfileTypeEnum.student
          ? 'rgba(194, 143, 193, 0.32)'
          : 'rgba(144, 121, 179, 0.16)',

        [theme.breakpoints.down('md')]: {
          backgroundColor: addTransparencyToColor(theme.colors.neutral.lightBase, 0.64)
        }
      }
    })
  }),
  contentWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  titleDefault: (props: IProps) => ({
    display: 'flex',
    paddingTop: props.cardExpanded ? '1rem' : '',
    alignItems: 'center',
    width: '',
    marginLeft: props.cardExpanded ? '' : !props.position ? '' : theme.spacingInline.nano,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.sm,
    color: props.disabled ? `${theme.colorBrand.light} !important` : props.isActive ? theme.colorBrand.dark : theme.colorBrand.medium,
    textDecoration: 'none',
    gap: theme.spacingInline.nano,
    fontWeight: 600
  }),
  textDefault: {
    color: theme.colorBrand.medium,
    fontSize: theme.font.fontSize.xxs,
    transition: 'all ease-in-out 0.3s',
    textOverflow: 'ellipsis',
    flexDirection: 'column',
    whiteSpace: 'normal',
    maxHeight: (props: IProps) => props.cardExpanded ? '200px' : '0',
    width: '100%',
    flexGrow: 1,
    opacity: (props: IProps) => props.cardExpanded ? '1' : '0',
    overflow: 'hidden',
    wordWrap: 'break-word'
  },
  navTitles: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: (props: IProps) => `${props.titleWidth as string}px`
  }
}
)
)
