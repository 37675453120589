import { AutoStoriesOutlined } from '@mui/icons-material'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { parseKaTex } from 'utils/katex'

interface IContentProps {
  content: string | null
}

export const Reference: React.FC<IContentProps> = ({ content }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  content = parseKaTex(content)

  return (
    <div className={classes.referenceContainer}>
      <div className={classes.title}>
        <AutoStoriesOutlined sx={{ marginRight: '8px' }} /> <span>{t('Referências')}</span>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: content ?? ''
        }}
        className={classes.dangerouslyContent}
      />
      <div className={classes.fakeBorder} />
    </div>
  )
}
