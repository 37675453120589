/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Theme } from '@mui/material'

import { createStyles, makeStyles } from '@mui/styles'
import { IBackToTopProps } from '.'

export default makeStyles((theme: Theme) =>
  createStyles({
    block: {
      '&.MuiButtonBase-root': {
        display: 'none'
      }
    },

    dissapear: {
      '&.MuiButtonBase-root': {
        transition: 'all ease-in 0.2s',
        position: 'sticky',
        visibility: 'hidden',
        bottom: (props: IBackToTopProps) => props.staryHeight ? `calc(${props.staryHeight})` : '-100px',
        left: (props: IBackToTopProps) => props.left ? `${props.left}%` : '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(96, 63, 146, 0.8)',
        zIndex: 999,
        width: 'fit-content',
        [theme.breakpoints.up('sm')]: {
          visibility: 'hidden',
          width: 0,
          height: 0
        }
      }
    },

    scrollDown: {
      '&.MuiButtonBase-root': {
        transition: 'all ease-in 0.1s',
        position: 'sticky',
        bottom: (props: IBackToTopProps) => props.staryHeight ? `${props.staryHeight}` : '10px',
        left: (props: IBackToTopProps) => props.left ? `${props.left}%` : '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(96, 63, 146, 0.8)',
        zIndex: 999,
        width: 'fit-content',
        [theme.breakpoints.up('sm')]: {
          visibility: 'hidden',
          width: 0,
          height: 0
        }
      }
    },

    scrollTop: {
      '&.MuiButtonBase-root': {
        transition: 'all ease-in 0.1s',
        position: 'sticky',
        bottom: (props: IBackToTopProps) => props.staryHeight ? `${props.staryHeight}` : '10px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(96, 63, 146, 0.8)',
        left: (props: IBackToTopProps) => props.left ? `${props.left}%` : '50%',
        transform: 'translateX(-50%)',
        opacity: '0.2',
        zIndex: 999,
        width: 'fit-content',
        [theme.breakpoints.up('sm')]: {
          visibility: 'hidden',
          width: 0,
          height: 0
        }
      }
    }
  })
)
