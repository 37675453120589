import { EventRepeatOutlined, ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import useStyle from './style'
import { BarInfoPreviousSchoolProps } from './types'

const BarInfoPreviousSchool = (props: BarInfoPreviousSchoolProps) => {
  const {
    message,
    isExpanded, isMobile, rootRouter,
    history, setIsExpanded
  } = props
  const classes = useStyle(props)
  const { t } = useTranslation()

  return (
    <>
      <Grid container className={classes.barInfoPreviousSchoolContainer} display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>
        <Grid item display='flex' alignItems='center' textAlign='center' className={classes.barInfoPreviousSchoolContent}>
          <EventRepeatOutlined className={classes.barInfoPreviousSchoolIconInfo} />
          <strong>{message.title}</strong>
          {isExpanded && <span><strong>:</strong> {message.subTitle}</span>}
        </Grid>
        {isExpanded && (
          <>
            <Grid item textAlign='center' className={classes.barInfoPreviousSchoolContent}>
              {message.description} {''}
              {message.showLink &&
                <span
                  className={classes.txtUnderlined}
                  onClick={() => {
                    history.replace(rootRouter)
                    setIsExpanded(false)
                  }}
                >
                  {t('Minhas turmas.')}
                </span>
              }
            </Grid>
            {!isMobile && (
              <Grid item className={classes.barInfoPreviousSchoolButton} onClick={() => setIsExpanded(!isExpanded)}>
                Entendi!
              </Grid>
            )}
          </>
        )}
        <Grid item
          className={classes.barInfoPreviousSchoolSwitchButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <ExpandLessOutlined fontSize='large' /> : <ExpandMoreOutlined fontSize='large' />}
        </Grid>
      </Grid>
      {/* {isShowMarginBottom && <div style={{ marginBottom }} />} */}
    </>
  )
}

export default BarInfoPreviousSchool
