import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'

import QuestionSelector from './components/QuestionSelector'
import SummaryQuestion from './components/SummaryQuestion'
import ListStudentsAnswer from './components/ListStudentsAnswer'
import Loading from './components/Loading'

import { activeQuestionAtom, getStudentsAnswerByQuestionAtom, isLoadingstudentsAnswerAtom, schoolLevelsAtom } from './atomStore'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { useMediaQuery, useTheme } from '@mui/material'
import { getLevels } from 'services/activity'
import NavigationButtons from './components/NavigationButtons'

type StateTeacherActivityAnswersView = 'loading' | 'renderListStudentsAnswer'

export const TeacherActivityAnswersView: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, activityId: string, evaluationId: string }>()

  const [state, setState] = useState<StateTeacherActivityAnswersView>('loading')

  // atom
  const [activity] = useAtom(activityCurrentAtom)
  const [activeQuestion, setActiveQuestion] = useAtom(activeQuestionAtom)
  const [isLoadingstudentsAnswer] = useAtom(isLoadingstudentsAnswerAtom)
  const [, getStudentsAnswerByQuestion] = useAtom(getStudentsAnswerByQuestionAtom)
  const [, setSchoolLevels] = useAtom(schoolLevelsAtom)

  const handleActiveQuestion = async () => {
    const questionId = (activeQuestion !== '' ? activeQuestion : activity?.questions?.[0]?.id) ?? 0
    setActiveQuestion(questionId)
    await getStudentsAnswerByQuestion({ classId, activityId: activityId ?? evaluationId, questionId })
  }

  const requestLevels = async () => {
    const response = await getLevels()
    if (response.success) {
      setSchoolLevels(response.data)
    }
  }

  useEffect(() => {
    requestLevels()

    return () => {
      setActiveQuestion('')
    }
  }, [])

  useEffect(() => {
    handleActiveQuestion()
  }, [activeQuestion])

  useEffect(() => {
    if (isLoadingstudentsAnswer) setState('loading')
    else setState('renderListStudentsAnswer')
  }, [isLoadingstudentsAnswer])

  const render: { [key in StateTeacherActivityAnswersView]: any } = {
    loading: <Loading />,
    renderListStudentsAnswer: <ListStudentsAnswer />
  }

  return (
    <Grid container paddingTop={2} paddingBottom={isMobile ? 26 : 20}>
      <QuestionSelector />
      {activeQuestion && <SummaryQuestion activeQuestion={activity?.questions?.find(question => question.id === activeQuestion)} />}
      {render[state]}
      <NavigationButtons />
    </Grid>
  )
}

export default TeacherActivityAnswersView
