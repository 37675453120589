// constants and types
import {
  CONTENTS_STUDENT,
  CONTENT_UNIT_STUDENT,
  ROOT,
  STUDENT_TIMELINE,
  STUDENT_SCHEDULE,
  PENDING_ACTIVITIES,
  RESULTS_TRACKING,
  PARENTAL_MESSAGES
} from 'navigation/CONSTANTS'
import { IUserSchoolProfileTypeEnum } from 'services/types'

import { OnlyCurrentSchoolPeriod, ShowCloeProMiddleware, HideCloeProMiddleware } from 'navigation/middlewares'

// pages imports
import {
  StudentTimeline,
  Dashboard,
  ContentsPage,
  PendingActivities,
  Home,
  ResultsTracking,
  ParentalMessages
} from 'pages'
import ContentUnit from 'pages/ContentUnit'

import { OnlyNotPWA } from 'contentCacheManager/components/OnlyNotPWA'

// local imports
import { IRoutesGroupProps } from '..'

export const studentPrivateRoutes: IRoutesGroupProps = {
  profileTypes: [IUserSchoolProfileTypeEnum.student],
  routes: [
    {
      path: PENDING_ACTIVITIES(),
      component: PendingActivities,
      isPrivate: true,
      middlewares: [OnlyNotPWA, HideCloeProMiddleware]
    },
    {
      path: PARENTAL_MESSAGES(),
      component: ParentalMessages,
      isPrivate: true,
      middlewares: [OnlyNotPWA, HideCloeProMiddleware]
    },
    {
      path: STUDENT_TIMELINE,
      component: StudentTimeline,
      isPrivate: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: CONTENTS_STUDENT,
      component: ContentsPage,
      isPrivate: true,
      middlewares: [ShowCloeProMiddleware]
    },
    {
      path: CONTENT_UNIT_STUDENT(),
      component: ContentUnit,
      isPrivate: true,
      middlewares: [ShowCloeProMiddleware]
    },
    {
      path: STUDENT_SCHEDULE,
      component: Dashboard,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, HideCloeProMiddleware]
    },
    {
      path: RESULTS_TRACKING,
      component: ResultsTracking,
      isPrivate: true,
      exact: true,
      middlewares: [HideCloeProMiddleware]
    },
    {
      path: ROOT,
      component: Home,
      isPrivate: true,
      exact: true,
      middlewares: [OnlyCurrentSchoolPeriod, HideCloeProMiddleware]
    }
  ]
}
