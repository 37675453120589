import { Route, RouteProps } from 'react-router-dom'

import PrivateRoute from 'navigation/routes/components/PrivateRoute'
import OnlyNotAuthRoute from './OnlyNotAuthRoute'

export interface IRouteWithSubRouteProps extends RouteProps {
  isPrivate?: boolean
  isOnlyNotAuth?: boolean
  routes?: RouteProps[]
  middlewares?: React.FC[]
}

export const RouteWithSubRoutes: React.FC<IRouteWithSubRouteProps> = ({ isPrivate, isOnlyNotAuth, component, ...route }) => {
  if (component) {
    const NewComponent: React.ComponentType<any> = component

    if (isPrivate) {
      return (
        <PrivateRoute {...route}>
          <NewComponent/>
        </PrivateRoute>
      )
    }

    if (isOnlyNotAuth) {
      return (
        <OnlyNotAuthRoute {...route}>
          <NewComponent/>
        </OnlyNotAuthRoute>
      )
    }

    return <Route {...route} component={NewComponent} />
  }
  return <></>
}
