import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    contentTextTitle: {
      textAlign: 'center',
      marginBottom: theme.spacingStack.nano,
      fontSize: theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.semibold,
      color: theme.colors.neutral.dark40,
      display: 'block'
    },
    contentText: {
      textAlign: 'center',
      fontSize: theme.font.fontSize.md,
      fontWeight: theme.font.fontWeight.medium,
      color: theme.colors.neutral.dark40,
      margin: theme.spacingInline.xxxs,
      display: 'block'
    }
  })
)
