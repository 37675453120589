import { Card, Text2 } from 'components/design-system'
import React from 'react'
import useStyles from './style'
import { AccountCircleOutlined } from '@mui/icons-material'
import { CardContent, Grid } from '@mui/material'
import { ICurrentUserPerformance } from 'pages/ResultsTracking/atomStore'

export interface IStudentCardInfo extends ICurrentUserPerformance {
  noBorder?: boolean
  noPadding?: boolean
  center?: boolean
}

export const StudentCardInfo: React.FC<IStudentCardInfo> = ({ center, ...props }) => {
  const classes = useStyles(props)

  const { user } = props
  const { username } = user

  return (
    <Card format='horizontal' extraClasses={classes.studentCard} extraClassesCardContent={classes.extraClassesCardContent}>
      <CardContent className={classes.cardContent}>
        <Grid container direction='column' alignItems='center' justifyContent='center' className={classes.studentCardContent}>
          <Grid container item columnSpacing={1} display='flex' flexDirection='column' {...(center && { alignItems: 'center' })}>
            <Grid item>
              <Text2 fontSize='sm' fontWeight='semibold' lineHeight='md' mobile='sm' colorbrand='medium'>
                <AccountCircleOutlined /> Nome de usuário
              </Text2>
            </Grid>
            <Grid item>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='md' mobile='sm' neutral='dark10'>
                {username}
              </Text2>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
