import create, { GetState, SetState } from 'zustand'
import { devtools, persist, StoreApiWithPersist, StoreApiWithSubscribeWithSelector, subscribeWithSelector } from 'zustand/middleware'
import { StoreProps } from './interfaces'

// https://github.com/pmndrs/zustand/blob/9277cff13a0d1b513907b39c1c25b22232e1a83d/tests/middlewareTypes.test.tsx >> ver teste linha 411

const initialParameters = {
  loadingDisciplines: true,
  currentDiscipline: null,
  disciplines: [],
  classId: null,
  currentClass: null,
  currentContentUnit: null,
  currentSegment: null,
  currentGrade: null
}

export const useLocalCurricularStore = create(
  devtools(subscribeWithSelector(
    persist<
    StoreProps,
    SetState<StoreProps>,
    GetState<StoreProps>,
    StoreApiWithSubscribeWithSelector<StoreProps> &
    StoreApiWithPersist<StoreProps>
    >(
      (set, _get) => ({
        ...initialParameters,
        syncData: set,
        reset: () => set(initialParameters)
      }),
      { name: 'cloe::web::curricular', version: 2, getStorage: () => localStorage }
    )
  ))
)
