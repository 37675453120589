import React, { useMemo, useState, createRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import { useStore, useCurricularStore } from 'store'

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { DeleteOutline, DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material'
import { Grid, useTheme } from '@mui/material'
import { Icon, Link, Text, Button } from 'components/design-system'
import { CurrentClass, StatusClassHandle } from 'components/common'

import { IFindStudentTimelineResponse, IRecordTimelineResponse, IStudentRecordsTimelineResponse, IUserSchoolProfileTypeEnum } from 'services/types'

import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'

import { EDIT_SINGLE_RECORD, MILESTONES_BY_ID } from 'navigation/CONSTANTS'

import AnsweredFile from '../AnsweredFile'
import { getDayFormatted, getMonthFormatted } from 'utils/date'

import { useParams, useHistory, NavLink } from 'react-router-dom'

import { useAuth } from 'navigation/ProvideAuth'

import { tabIndexModalTimelineAtom, openModalTimelineAtom, contentModalTimelineAtom, openModalConfirmationTimelineAtom, confirmationModalPropsAtom } from '../atomStore'
import { reloadTimelineAtom } from 'pages/Dashboard/teacher/atomStore'
import { ClassId } from '../../../../services/types/common'
import { useContentUnitLinks } from 'utils/contentUnit/useContentUnitLinks'

import { alternativeParsed, getTimelineContentTypes, parseQuestion } from '../utils'
import { deleteMilestone } from 'services/milestones'
import { deleteClassRecord } from 'services/class-records'
import { toast } from 'components/design-system/Toast/manager'
import { renderLeftMonthLabels, handleMilestoneMonthLabel } from './utils'
import { MonthLabel } from './components'

import useStyles from './style'

export interface ITimelineItemProps {
  studentRecord: IStudentRecordsTimelineResponse
  classId: ClassId
  timelineData: IFindStudentTimelineResponse[]
}

export interface ITimelineRenderItemProps {
  studentRecord: IStudentRecordsTimelineResponse
  milestone: boolean
}

export type TMilestoneRecordsByTime = {
  monthNumber: number
  records: IStudentRecordsTimelineResponse[] | null | undefined
}

const TimelineComponent = (props: ITimelineItemProps) => {
  const { studentRecord } = props
  const [, setIndexSelected] = useAtom(tabIndexModalTimelineAtom)
  const [open, setOpen] = useAtom(openModalTimelineAtom)
  const [, setContent] = useAtom(contentModalTimelineAtom)
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false)
  const [reloadTimeline] = useAtom(reloadTimelineAtom)
  const [, setConfirmationModalProps] = useAtom(confirmationModalPropsAtom)
  const [, setReloadTimeline] = useAtom(reloadTimelineAtom)
  const [, setOpenConfirmationModal] = useAtom(openModalConfirmationTimelineAtom)

  const classes = useStyles(props)
  const theme = useTheme()
  const { t } = useTranslation()

  const auth = useAuth()
  const TimelineContentTypes = getTimelineContentTypes(theme)
  const { profile, session } = useStore()
  const { currentDiscipline } = useCurricularStore()

  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()
  const history = useHistory()
  const { getExerciceURL } = useContentUnitLinks()

  const cardRef = createRef<HTMLDivElement>()

  const hasCoordinator = !!session?.profiles?.filter(cur => [IUserSchoolProfileTypeEnum.coordinator].includes(cur.type)).length

  const allowedDimensionsFilesQuery = {
    small: 'w=150&h=150',
    medium: 'w=500&h=500',
    large: 'w=1024&h=1024'
  }

  // Function brougth from ContentLink and MyCloe
  // TODO: Move all limita functions to utils or replace them with /utils/string/maxLength
  const limita = (str: string, limite: number): string => {
    let nova: string = ''
    if (str !== null && str !== undefined) {
      for (let i = 0; i < limite; i++) {
        nova += str.substr(i, 1)
      }
      if (str.length <= nova.length) {
        return str
      }
      return `${nova}...`
    }
    return str
  }

  const handleDeleteTimelineComponent = async (elementType: 'milestone' | 'record', elementId: string) => {
    if (elementId) {
      const deleteTimelineComponent = {
        milestone: deleteMilestone,
        record: deleteClassRecord
      }
      const successMessages = {
        milestone: t('Agrupamento excluido com sucesso'),
        record: t('Registro excluído com sucesso')
      }
      try {
        const response = await deleteTimelineComponent[elementType](elementId)

        if (response.success) {
          toast.handler({
            content: successMessages[elementType],
            duration: 5000,
            severity: 'success'
          })
          setReloadTimeline(true)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setConfirmationModalProps(null)
      }
    }
    setOpenConfirmationModal(false)
  }

  const modalProps = {
    record: {
      title: t('Excluir Registro'),
      description: t('Ao excluir este registro, será excluído também de todos os alunos que estão vinculados ao registro. Deseja excluir o registro permanentemente?'),
      confirmLabel: t('Excluir'),
      onCancel: () => {
        // This is intentional
      },
      onConfirm: () => {
        // This is intentional
      },
      dataTestidCancel: 'delete_register_cancel',
      dataTestidConfirm: 'delete_register_confirm'
    },
    milestone: {
      title: t('Excluir Agrupamento de Registro'),
      description: t('Ao excluir este agrupamento, será excluído também de todos os alunos que estão vinculados ao agrupamento. Deseja excluir permanentemente?'),
      confirmLabel: t('Excluir'),
      onCancel: () => {
        // This is intentional
      },
      onConfirm: () => {
        // This is intentional
      },
      dataTestidCancel: 'confirm_delete_grouping_not_delete',
      dataTestidConfirm: 'confirm_delete_grouping_delete'
    }
  }

  const useConfirmationModal = (exclusionType: 'milestone' | 'record', elementId: string) => {
    modalProps.record.onConfirm = async () => await handleDeleteTimelineComponent(exclusionType, elementId)
    modalProps.milestone.onConfirm = async () => await handleDeleteTimelineComponent(exclusionType, elementId)

    setConfirmationModalProps(modalProps[exclusionType])
    setOpenConfirmationModal(true)
  }

  const handleDataTagging = () => cardRef?.current?.click()

  const RenderTimeline = (props: ITimelineRenderItemProps) => {
    const linkHrefContentUnit = useMemo(() =>
      getExerciceURL({
        currentDisciplineCode: currentDiscipline?.code,
        gradeTypeCode,
        gradeCode,
        classId,
        contentUnitId: String(props.studentRecord.contentUnitId),
        activityId: String(props.studentRecord.id),
        suggestedApplicationType: props.studentRecord.suggestedApplicationType
      })
    , [profile])

    const handleClickCard = () => {
      setOpen(!open)
      handleDataTagging()
    }

    const sortByQuestionOrder = (a: IRecordTimelineResponse, b: IRecordTimelineResponse) => {
      if (!a?.questionOrder || !b?.questionOrder) {
        return 0
      }

      return a.questionOrder - b.questionOrder
    }

    return (
      <React.Fragment key={props.studentRecord.id} >
        <TimelineItem key={props.studentRecord.id} >
          <TimelineOppositeContent
            className={classes.oppositeContent}
            variant='body2'
          >
            {`${t('Dia')} ${getDayFormatted(props.studentRecord.createdAt)}`}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              sx={{ background: props.milestone ? TimelineContentTypes.marco.backgroundColor : TimelineContentTypes[props.studentRecord.suggestedApplicationType].backgroundColor }}>
              {props.studentRecord.suggestedApplicationType && TimelineContentTypes[props.studentRecord.suggestedApplicationType].icon}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ p: theme.spacingStack.xxxs }}>
            <div className={classes.contentDay}>
              <Text>{`${t('Dia')} ${getDayFormatted(props.studentRecord.createdAt)}`}</Text>
            </div>
            <Grid className={classes.itemTimeline}>
              <Grid item>
                <Grid item xs={12} className={classes.activityTitleContainer}>
                  { // Ativação apenas quando existir retorno do contentUnitId (feature ainda não liberada)
                    props.studentRecord.suggestedApplicationType !== 'class_record' && props.studentRecord.contentUnitId
                      ? (
                        <>
                          <Text type='body' style={{ color: theme.colors.neutral.dark30 }}>
                            {props.studentRecord.contentUnitTitle}
                          </Text>
                          <div className={'w-auto'}>
                            <Link
                              href={linkHrefContentUnit}
                              target={'_blank'}
                              style={{ color: theme.colors.neutral.darkBase, fontWeight: theme.font.fontWeight.semibold }}
                              data-testid='register_open_link'
                            >
                              {props.studentRecord.title}
                            </Link>
                          </div>
                        </>
                      )
                      : (
                        <Text type='body' style={{ color: theme.colors.neutral.dark30, fontWeight: theme.font.fontWeight.semibold }}>
                          {props.studentRecord.title}
                        </Text>
                      )
                  }
                  {props.studentRecord.suggestedApplicationType === 'class_record' &&
                    <>
                      {props.studentRecord.description &&
                        <Grid item xs={12} md={6}>
                          <Text type='body' size='medium' style={{ color: theme.colors.neutral.dark30, fontWeight: theme.font.fontWeight.medium }}>
                            {props.studentRecord.description}
                          </Text>
                        </Grid>
                      }
                    </>
                  }
                  <Grid className={classes.itensContainer}>
                    {props.studentRecord.records?.sort(sortByQuestionOrder)?.map((record, index) =>
                      <React.Fragment key={record.id}>
                        {(record.choiceAlternative || record.text || record.url) &&
                          <Grid item>
                            <Card
                              ref={cardRef}
                              key={record.id}
                              className={classes.cardItemTimeline}
                              onClick={() => [handleClickCard(), setIndexSelected(index), setContent(props.studentRecord)]}
                              sx={{ background: TimelineContentTypes[props.studentRecord.suggestedApplicationType].backgroundColor }}
                              data-testid='register_open'
                            >
                              <CardContent
                                className={classes.cardItemContentTimeline}
                                sx={{ '&.MuiCardContent-root': { paddingBottom: 0 } }}
                              >
                                {record.type === 'TEXT' &&
                                  <p key={index} className={classes.text}>
                                    {limita(record.text, 30)}
                                  </p>
                                }
                                {(record.type === 'FILE' && record.url !== null) &&
                                  <AnsweredFile key={index} src={`${record.url}?${allowedDimensionsFilesQuery.small}`} />
                                }
                                {(record.type === 'CHOICE') &&
                                  <>
                                    {parseQuestion(record)
                                      ? <div className={classes.containerAlternativeImage}>
                                        <img src={parseQuestion(record)} className={classes.alternativeImage} />
                                      </div>
                                      : <div className={classes.text}
                                        dangerouslySetInnerHTML={{ __html: alternativeParsed(record) }}
                                      />
                                    }
                                  </>
                                }
                              </CardContent>
                            </Card>
                          </Grid>
                        }
                      </React.Fragment>
                    )
                    }
                  </Grid>
                  <StatusClassHandle>
                    <CurrentClass>
                      {(['class_record', 'marco'].includes(props.studentRecord.suggestedApplicationType)) && (session?.id === props.studentRecord.ownerId || hasCoordinator) &&
                        <Grid item display='flex' className={classes.buttonsGroup}>
                          <Button
                            startIcon={<DeleteOutline data-testid='delete_register_delete' />}
                            variant='ghost'
                            className={classes.deleteButton}
                            onClick={() => useConfirmationModal('record', props.studentRecord.id?.toString())}
                            data-testid='delete_register_delete'
                          >
                            {t('Excluir registro')}
                          </Button>
                          <Button
                            startIcon={<EditOutlined data-testid='edit_register' />}
                            variant='primary'
                            className={classes.editButton}
                            onClick={() => history.push(EDIT_SINGLE_RECORD(gradeTypeCode, gradeCode, classId, props.studentRecord.id))}
                            data-testid='edit_register'
                          >
                            {t('Editar registro')}
                          </Button>
                        </Grid>
                      }
                    </CurrentClass>
                  </StatusClassHandle>
                </Grid>
              </Grid>
            </Grid>
          </TimelineContent>
        </TimelineItem>
      </React.Fragment >)
  }

  const RenderMarcoHeader = () => (
    <React.Fragment>
      <TimelineSeparator>
        <TimelineDot
          sx={{ background: TimelineContentTypes[studentRecord.suggestedApplicationType].backgroundColor }}>
          {studentRecord.suggestedApplicationType && TimelineContentTypes[studentRecord.suggestedApplicationType].icon}
        </TimelineDot>
        {!isDropDownOpened && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ p: theme.spacingStack.xxxs }}>
        <div className={classes.contentDay}>
          <Text>{`${t('Dia')} ${getDayFormatted(studentRecord.dateStart ? studentRecord.dateStart : '')}`}</Text>
        </div>
        <Grid item lg={9} sm={12} className={classes.milestoneCard} sx={{ display: 'flex', flexDirection: 'column', borderColor: TimelineContentTypes.marco.backgroundColor }} >
          <Grid style={{ backgroundColor: TimelineContentTypes.marco.backgroundColor, display: 'flex', flexDirection: 'column' }}>
            <Grid className={classes.milestoneCardHeader}>
              <Grid
                item
                md={6}
                xs={12}
                className={classes.milestoneDropdownContainer}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsDropDownOpened(!isDropDownOpened)}
                data-testid={isDropDownOpened ? 'agroup_close' : 'agroup_open'}
              >
                <Text type='body' className={classes.milestoneDropdown} >
                  {isDropDownOpened
                    ? <>
                      {t('Fechar agrupamento')}
                      <Icon titleAccess={t('Fechar agrupamento')} size='medium'>
                        <KeyboardArrowUpOutlinedIcon />
                      </Icon>
                    </>
                    : <>
                      {t('Abrir agrupamento')}
                      <Icon titleAccess={t('Abrir agrupamento')} size='medium'>
                        <KeyboardArrowDownOutlinedIcon />
                      </Icon>
                    </>
                  }
                </Text>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}>
                <Text className={classes.milestoneUpper}>
                  {t('Criado pelo professor')}
                </Text>
              </Grid>
            </Grid>
            <Text className={classes.milestoneTitle}>
              {studentRecord.title}
            </Text>
            <img
              className={classes.milestoneCover}
              src={studentRecord.cover}
            />
          </Grid>
          <Text className={classes.milestoneDescription}>
            {studentRecord.description}
          </Text>
          <StatusClassHandle>
            <CurrentClass>
              {(auth?.user?.id === studentRecord.milestoneOwner || hasCoordinator) &&
                <Grid container style={{ display: 'flex', alignItems: 'center', marginTop: 2, gap: 8, justifyContent: 'flex-end', marginBottom: 8 }}>
                  <Grid
                    item
                    display='flex'
                    alignItems='center'
                    style={{ cursor: 'pointer', padding: '8px 14px' }}
                    onClick={() => useConfirmationModal('milestone', studentRecord.id?.toString())}
                    data-testid={'agroup_delete'}
                  >
                    <Icon titleAccess={t('Excluir')} iconColor={TimelineContentTypes.marco.backgroundColor} size='medium'>
                      <DeleteOutlineOutlined />
                    </Icon>
                    <Text style={{ color: TimelineContentTypes.marco.backgroundColor, fontWeight: theme.font.fontWeight.semibold }} type='body' size='small' >
                      {t('Excluir')}
                    </Text>
                  </Grid>
                  <Grid
                    item
                    display='flex'
                    alignItems='center'
                    style={{
                      cursor: 'pointer',
                      padding: '8px 14px',
                      backgroundColor: TimelineContentTypes.marco.backgroundColor,
                      borderRadius: 9999
                    }}
                    marginRight={2}
                  >
                    <NavLink
                      to={MILESTONES_BY_ID(gradeTypeCode, gradeCode, classId?.toString(), studentRecord.id?.toString())}
                      style={{ display: 'flex', textDecoration: 'none', alignItems: 'center', justifyContent: 'center' }}
                      data-testid={'agroup_edit'}
                    >
                      <Icon iconColor={'white'} titleAccess={t('Editar')} size='medium'>
                        <EditOutlined />
                      </Icon>
                      <Text style={{ color: 'white', fontWeight: theme.font.fontWeight.semibold }} type='body' size='small' >
                        {t('Editar')}
                      </Text>
                    </NavLink>
                  </Grid>
                </Grid>
              }
            </CurrentClass>
          </StatusClassHandle>
        </Grid>
      </TimelineContent>
    </React.Fragment>
  )

  const RenderMarcoFooter = () => (
    <React.Fragment>
      <TimelineSeparator>
        <TimelineDot
          sx={{ background: TimelineContentTypes[studentRecord.suggestedApplicationType].backgroundColor }}>
          {studentRecord.suggestedApplicationType && TimelineContentTypes[studentRecord.suggestedApplicationType].icon}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ p: theme.spacingStack.xxxs }}>
        <Grid item lg={9} sm={12} className={classes.milestoneCard} sx={{ display: 'flex', flexDirection: 'column', border: 'none', borderRadius: `0 0 ${theme.borderRadius.md} ${theme.borderRadius.md}` }} >
          <Grid display='flex' flexDirection='column' style={{ backgroundColor: TimelineContentTypes.marco.backgroundColor }}>
            <Grid className={classes.milestoneEndGroupHeader}>
              <Grid
                item
                md={6}
                xs={12}>
                <Text className={classes.milestoneEndGroup}>
                  {t('Fim do agrupamento: ') + studentRecord.title}
                </Text>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                className={classes.milestoneDropdownContainer}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsDropDownOpened(!isDropDownOpened)}
                data-testid='agroup_close'
              >
                <Text type='body'
                  className={classes.milestoneDropdownClose}>
                  {t('Fechar agrupamento')}
                  <Icon titleAccess={t('Fechar agrupamento')} size='medium'>
                    <KeyboardArrowUpOutlinedIcon />
                  </Icon>
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TimelineContent>
    </React.Fragment>
  )

  const RenderMarco = () => {
    const milestoneRecordsByMonth = studentRecord.milestoneRecords?.reduce<Record<string, TMilestoneRecordsByTime>>((milestonesMonth, currentValue) => {
      const monthNumber = Number(getMonthFormatted(currentValue.createdAt)) - 1
      if (milestonesMonth[monthNumber]) {
        (milestonesMonth[monthNumber].records as IStudentRecordsTimelineResponse[]).push(currentValue)
      } else {
        milestonesMonth[monthNumber] = { monthNumber, records: [currentValue] }
      }
      return milestonesMonth
    }, {})

    let milestoneRecordsByMonthFormated: TMilestoneRecordsByTime[] = []
    if (milestoneRecordsByMonth) {
      milestoneRecordsByMonthFormated = Object.keys(milestoneRecordsByMonth).map((key: string) => milestoneRecordsByMonth[key])
    }

    handleMilestoneMonthLabel({
      timelineData: props.timelineData,
      dateStart: studentRecord.dateStart ?? '',
      dateEnd: studentRecord.dateEnd ?? '',
      isDropDownOpened
    })

    return (
      <Grid className={studentRecord.suggestedApplicationType === 'marco' ? classes.milestoneBackground : ''} >
        <React.Fragment key={studentRecord.id}>
          <TimelineItem key={studentRecord.id}>
            <TimelineOppositeContent
              className={classes.oppositeContent}
              variant='body2'
            >
              {`${t('Dia')} ${getDayFormatted((isDropDownOpened ? studentRecord.dateEnd : studentRecord.dateStart) ?? '')}`}
            </TimelineOppositeContent>
            {isDropDownOpened ? <RenderMarcoFooter /> : <RenderMarcoHeader />}
          </TimelineItem>
          {isDropDownOpened && (
            <>
              {
                milestoneRecordsByMonthFormated?.reverse().map(({ monthNumber, records }) => (
                  <div>
                    {dayjs(studentRecord.dateEnd).month() !== monthNumber && <MonthLabel monthNumber={monthNumber} />}

                    {records?.map((record) => <RenderTimeline studentRecord={record} milestone={true} />)}
                  </div>
                ))
              }
              {renderLeftMonthLabels({ milestoneRecords: milestoneRecordsByMonthFormated, milestoneDateStart: studentRecord.dateStart ?? '' })}
            </>
          )
          }
          {isDropDownOpened && (
            <TimelineItem key={studentRecord.id}>
              <TimelineOppositeContent
                className={classes.oppositeContent}
                variant='body2'
              >
                {`${t('Dia')} ${getDayFormatted(studentRecord.dateStart ? studentRecord.dateStart : '')}`}
              </TimelineOppositeContent>
              <RenderMarcoHeader />
            </TimelineItem>
          )}
        </React.Fragment >
      </Grid >
    )
  }

  return (
    reloadTimeline ? <></> : studentRecord.suggestedApplicationType === 'marco' ? <RenderMarco /> : <RenderTimeline studentRecord={studentRecord} milestone={false} />
  )
}

export default TimelineComponent
