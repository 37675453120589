import OnlyProfile from 'components/common/OnlyProfile'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { TeacherParentalMessages, StudentParentalMessages } from './components'

export const ParentalMessages: React.FC = () => {
  return (
    <>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        <StudentParentalMessages />
      </OnlyProfile>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        <TeacherParentalMessages />
      </OnlyProfile>
    </>
  )
}

export default ParentalMessages
