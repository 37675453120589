import { createStyles, makeStyles } from '@mui/styles'
import { styled, Theme } from '@mui/material'
import { IDisciplineResponse } from 'services/types'

export default makeStyles((theme: Theme) => createStyles({
  cardContainer: {
    borderRadius: '8px'
  },
  cardLink: {
    textDecoration: 'none',
    borderRadius: '8px',
    '&:hover': {
      transition: 'all ease-in-out 0.1s',
      transform: 'scale(1.04)'
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  title: {
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    color: theme.colors.neutral.dark10,
    textDecoration: 'none',
    width: 'max-content',
    lineHeight: theme.font.lineHeight.xxs
  },
  tag: {
    marginRight: '8px'
  },
  tagLast: {
    color: 'white'
  }
}))

interface IWrapperProps {
  theme?: Theme
  firstdiscipline: IDisciplineResponse
}

export const WrapperCardError = styled('div')(({ theme }) => ({
  width: 270,
  height: 130,
  padding: theme?.spacingInline.xxxs,
  backgroundColor: theme?.colors.neutral.light20,
  borderRadius: theme?.borderRadius.sm,
  '::before': {
    opacity: '.4'
  }
}))

export const Wrapper = styled('div')(({ theme, firstdiscipline }: IWrapperProps) => ({
  maxWidth: 'fit-content',
  padding: theme?.spacingInline.xxxs,
  backgroundColor: theme?.colors.bgCurricularComponents[firstdiscipline.code] ?? theme?.colors.bgCurricularComponents.DEFAULT,
  borderRadius: theme?.borderRadius.sm,
  '::before': {
    opacity: '.4'
  }
}))

export const DisciplinesArea = styled('div')(({ theme }) => ({
  width: 'max-content',
  marginTop: theme.spacingInset.xs
}))
