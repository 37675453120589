import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  textField: {
    paddingTop: theme.spacingStack.xxs,
    '& div': { width: '100%' },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacingStack.xs
    }
  },
  helpTextTitle: {
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.lineHeight.xxs,
    color: theme.colors.neutral.dark30,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 0,
    '&.limit': {
      color: theme.colors.negative.feedbackDark
    }
  },
  selectedQuestionsField: {
    border: `2px solid ${theme.colors.neutral.light30}`,
    padding: '16px 12px',
    borderRadius: theme.borderRadius.sm
  },
  editButton: {
    padding: '8px 16px !important'
  }
}))
