import { setExpeditionCachedData } from './setExpeditionCachedData'
import { OfflineTableExpeditionCachedStatus } from './../../dtos/IExpeditionCached'
import { createExpeditionCached } from './createExpeditionCached'
import { makeDatabaseInstanceOffline } from './../../'

export const addCacheExpeditionToLocalDatabase = async (
  expeditionId: number
) => {
  const expedition = await makeDatabaseInstanceOffline().expeditionsCached.where('expeditionId').equals(expeditionId).first()
  if (expedition) {
    if (expedition.status === OfflineTableExpeditionCachedStatus.PARTIAL_DOWNLOADED) {
      setExpeditionCachedData({ status: OfflineTableExpeditionCachedStatus.DOWNLOADING }, expedition)
    }
    return expedition
  } else {
    return await createExpeditionCached({
      cachedAt: new Date(),
      createdAt: new Date(),
      embeddedUrls: [],
      errorUrls: [],
      expeditionId,
      size: {
        content: 0,
        files: 0
      },
      status: OfflineTableExpeditionCachedStatus.DOWNLOADING,
      successUrls: []
    })
  }
}
