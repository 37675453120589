import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

interface IProps {
  cardExpanded: boolean | string | undefined
  cardActive: boolean | string | undefined
  position: number | undefined
  titleWidth: string | number | undefined
}

export default makeStyles((theme: CustomTheme) => createStyles({
  cardWrapper: {
    display: 'flex',
    marginRight: '10px',
    borderRadius: '8px',
    backgroundColor: 'rgba(194, 143, 193, 0.32)',
    textDecoration: 'none'
  },
  position: {
    backgroundColor: 'transparent',
    color: theme.colorBrand.medium,
    border: `1px solid ${theme.colorBrand.medium}`,
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    alignItems: 'center',
    borderRadius: '50%',
    left: '50%',
    top: (props: IProps) => props.cardExpanded ? '50%' : '15px',
    transform: (props: IProps) => props.cardExpanded ? 'translate(-50%, -50%)' : 'translate(-100%, -50%)',
    width: (props: IProps) => props.cardExpanded ? '24px' : '33px',
    height: (props: IProps) => props.cardExpanded ? '24px' : '33px',
    padding: '0 10px 0 10px',
    '&:active': {
      backgroundColor: theme.colorBrand.darkest,
      color: theme.colorBrand.lightest,
      border: '1px solid transparent'
    }
  },
  positionWrapper: {
    width: (props: IProps) => props.cardExpanded ? '100%' : '0',
    marginTop: (props: IProps) => props.cardExpanded ? '1rem' : '',
    marginBottom: (props: IProps) => props.cardExpanded ? '1rem' : '',
    position: 'relative'
  },
  wrapperDefault: {
    backgroundColor: theme.colorBrand.lightest,
    padding: '24px',
    color: theme.colorBrand.medium,
    borderRadius: '8px',
    border: 'none',
    flexWrap: 'wrap',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.3s',
    paddingLeft: (props: IProps) => props.cardExpanded ? '' : !props.position ? '' : '48px',
    '&:hover': {
      transition: 'all ease-in-out 0.2s',
      backgroundColor: ' rgba(194, 143, 193, 0.32)',
      color: theme.colorBrand.darkest,
      '& $titleDefault': {
        transition: 'all ease-in-out 0.2s',
        color: theme.colorBrand.darkest
      },
      '& $textDefault': {
        transition: 'all ease-in-out 0.2s',
        color: theme.colorBrand.darkest
      },
      '& $position': {
        transition: 'all ease-in-out 0.2s',
        backgroundColor: theme.colorBrand.darkest,
        color: '#fff',
        border: '1px solid transparent'
      }
    }
  },
  wrapperDefaultSelected: {
    padding: '24px',
    borderRadius: '8px',
    display: 'flex',
    border: 'none',
    flexWrap: 'wrap',
    cursor: 'pointer',
    transition: 'all ease-in-out 0.3s',
    paddingLeft: (props: IProps) => props.cardExpanded ? '' : !props.position ? '' : '48px',
    alignItems: 'start',
    backgroundColor: ' rgba(194, 143, 193, 0.32)',
    color: theme.colorBrand.darkest,
    '& $titleDefault': {
      transition: 'all ease-in-out 0.2s',
      color: theme.colorBrand.darkest
    },
    '& $textDefault': {
      transition: 'all ease-in-out 0.2s',
      color: theme.colorBrand.darkest
    },
    '& $position': {
      transition: 'all ease-in-out 0.2s',
      backgroundColor: theme.colorBrand.darkest,
      color: '#fff',
      border: '1px solid transparent'
    }
  },
  titleActive: {
    color: theme.colorBrand.darkest,
    fontSize: theme.font.fontSize.sm,
    lineHeight: theme.font.lineHeight.sm
  },
  titleDefault: {
    display: 'flex',
    paddingTop: (props: IProps) => props.cardExpanded ? '1rem' : '',
    alignItems: 'center',
    width: '',
    marginLeft: (props: IProps) => props.cardExpanded ? '' : !props.position ? '' : '1rem',
    lineHeight: theme.font.lineHeight.sm,
    fontSize: theme.font.fontSize.sm,
    color: theme.colorBrand.medium,
    textDecoration: 'none',
    gap: '12px',
    fontWeight: 600,
    minWidth: 'max-content'
  },
  textActive: {
    color: theme.colorBrand.darkest,
    fontSize: theme.font.fontSize.xxs
  },
  textDefault: {
    color: theme.colorBrand.medium,
    fontSize: theme.font.fontSize.xxs,
    transition: 'all ease-in-out 0.3s',
    textOverflow: 'ellipsis',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    maxHeight: (props: IProps) => props.cardExpanded ? '200px' : '0',
    width: '100%',
    flexGrow: 1,
    opacity: (props: IProps) => props.cardExpanded ? '1' : '0',
    overflow: 'hidden'
  },
  navTitles: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: (props: IProps) => `${props.titleWidth as string}px`
  }
}
)
)
