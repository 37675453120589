import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import { MyClassesHeader } from '../MyClassesHeader'
import { BusinessError } from 'navigation/BusinessError'

import useStyle from './styles'

export const ErrorState = () => {
  const { t } = useTranslation()

  const classes = useStyle()

  return (
    <Grid container className={classes.containerWrapper} flexDirection='column'>
      <MyClassesHeader />
      <Grid container ml={0} mr={0} mt={3}>
        <Grid item xs={12}>
          <BusinessError error={t('Não foi possível carregar os dados. Tente novamente!')} />
        </Grid>
      </Grid>
    </Grid>
  )
}
