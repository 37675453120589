/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { ptBR } from 'date-fns/locale'
import dayjs from 'dayjs'

import { CircularProgress, Grid, Stack, Theme, useTheme } from '@mui/material'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import AccordionMultiStudents from '../AccordionMultiStudents'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider } from '@mui/lab'
import { InputDate, InputFilePreview } from 'components/common'
import { Button, Text, TextField } from 'components/design-system'
import { Disciplines } from 'components/common/Disciplines'
import { IPreviewState } from 'components/common/InputFilePreview/atomStore'
import { toast } from 'components/design-system/Toast/manager'

import { findstudentByClass } from 'services/timeline'
import { getDisciplines } from 'services/discipline'
import { IClassSubscriptionResponse, IDisciplineResponse, IFileRecordRequest, ISubscriptionsSessionResponse } from 'services/types'
import { deleteFile } from 'services/upload'
import { getClassRecordById, updateClassRecord } from 'services/class-records'
import { DateStringType } from 'services/types/common'

import { inUploadingProcess, tabIndexAtom } from '../../../atomStore'

import { useStore } from 'store'

import useStyle from './style'
import { getFileExtension } from 'utils/files'
import { removeTimezoneFromDate } from 'utils/date'
import { ConfirmationModal } from 'components/common/CustomizedTimeline/ConfirmationModal'
import { openModalConfirmationTimelineAtom } from 'components/common/CustomizedTimeline/atomStore'

export const EditSingleRecord: React.FC = () => {
  const [disciplinesData, setDisciplinesData] = useState<IDisciplineResponse[]>([])
  const [initialDate, setInitialDate] = useState<Date | null>(null)
  const [currentDisciplineId, setCurrentDisciplineId] = useState<IDisciplineResponse | null>(null)
  const [studentsData, setStudentsData] = useState<IClassSubscriptionResponse[]>([])
  const [studentCollection, setStudentCollection] = useState<number[]>([])
  const [titleField, setTitleField] = useState<string>('')
  const [recordField, setRecordField] = useState<string>('')
  const [recordFiles, setRecordFiles] = useState<IFileRecordRequest[]>([])
  const [previewRecords, setPreviewRecords] = useState<IPreviewState[]>([])
  const [loadingButton, setLoadingButton] = useState<boolean>(false)

  const [, setInUploadingProcess] = useAtom(inUploadingProcess)
  const [, setTabIndex] = useAtom(tabIndexAtom)
  const [, setOpenConfirmation] = useAtom(openModalConfirmationTimelineAtom)

  const { classId, classRecordId } = useParams<{ classId: string, classRecordId: string }>()
  const history = useHistory()

  const { t } = useTranslation()

  const classes = useStyle()
  const theme = useTheme<Theme>()

  const { profile, session } = useStore()
  const userSchoolProfileId = profile?.id

  const descriptionFieldMaxLength = 1000

  const classSelected = session?.subscriptions
    .filter((cur: ISubscriptionsSessionResponse) => cur.class)
    .find(sub => sub?.class?.id?.toString() === classId)?.class
  const timelineTab = !classSelected?.grades[0]?.code?.includes('EI_') ? 3 : 2

  const today = dayjs().toDate()
  const YearFirstDay = dayjs().set('month', 0).set('date', 1).toDate()

  const handleUploadFiles = (uploads: IPreviewState[]) => {
    const uploadData = uploads.map(file => ({
      existingFile: !!file.existingFile,
      id: file.id,
      upload_id: file.id,
      record_id: file.record_id,
      url: file.url,
      transaction_id: file.transaction_id
    }))

    setRecordFiles(uploadData)

    setInUploadingProcess(false)
  }

  const handleChangeDiscipline = useCallback((disciplineId: IDisciplineResponse) => {
    if (disciplineId !== currentDisciplineId) {
      setCurrentDisciplineId(disciplineId)
    }
  }, [currentDisciplineId])

  useEffect(() => {
    const loadingData = async () => {
      const responseStudentsByClass = await findstudentByClass(classId)
      const { data: students } = responseStudentsByClass
      setStudentsData(students)
      handleChangeStudent([students[0].user_school_profile.id])

      if (classRecordId) {
        const { data: { title, description, discipline, record_date, class_records_students, records } } = await getClassRecordById(classRecordId)
        const studentsIds = class_records_students?.map(student => student.user_school_profile.id) || []

        const previewRecords = records?.map(({ id, url, record_id }, index) => ({
          id: Number(id),
          url,
          name: `${t('Arquivo')} ${String(index + 1)}`,
          thumb: url,
          ext: getFileExtension(url),
          existingFile: true,
          record_id: record_id
        })) as IPreviewState[]

        const initialDateWithoutUTC = removeTimezoneFromDate(record_date)

        setTitleField(title ?? '')
        setRecordField(description ?? '')
        setCurrentDisciplineId(discipline ?? null)
        setInitialDate(initialDateWithoutUTC ?? null)
        setStudentCollection(studentsIds ?? [])
        setRecordFiles(records ?? [])
        setPreviewRecords(previewRecords ?? [])
      }
    }
    loadingData()
  }, [])

  const handleChangeStudent = (student: number[]) => {
    setStudentCollection(student)
  }

  const getInitialData = async () => {
    const disciplines = await getDisciplines(parseInt(classId))
    const taughtDisciplines = disciplines.data.filter(discipline => discipline.taught_discipline)

    if (taughtDisciplines.length) {
      const responseStudentsByClass = await findstudentByClass(classId)
      const { data: students } = responseStudentsByClass
      setDisciplinesData(taughtDisciplines)
      setStudentsData(students)
    }
  }

  const validationSubmitButton = (
    initialDate: Date | null,
    studentCollection: number[],
    titleField: string,
    recordField: string,
    currentDisciplineId: IDisciplineResponse | null
  ) => {
    return !(initialDate &&
      studentCollection.length &&
      titleField &&
      currentDisciplineId &&
      recordField)
  }

  const goBack = () => {
    setTabIndex(timelineTab)
    history.goBack()
  }

  const handleCancel = async () => {
    setTitleField('')
    setRecordField('')
    setCurrentDisciplineId(null)

    goBack()

    if (recordFiles.length) {
      await Promise.all(recordFiles.map(async (fileRecord) => {
        try {
          if (fileRecord.upload_id) await deleteFile(fileRecord.upload_id)
        } catch (err) {
          console.error(err)
        }
      }))
    }
  }

  const onClickToSend = async () => {
    setLoadingButton(true)
    try {
      if (currentDisciplineId) {
        const payload = {
          title: titleField,
          discipline: currentDisciplineId?.id,
          record_date: dayjs(initialDate).format('YYYY-MM-DD') as DateStringType,
          class: classId,
          user_school_profile: userSchoolProfileId as number,
          description: recordField,
          students_id: studentCollection,
          records: recordFiles
        }
        // TODO: checar erro de tipo aqui
        // @ts-expect-error
        const response = await updateClassRecord(classRecordId, payload)

        if (response.success) {
          toast.handler({
            content: t('Registro avulso atualizado com sucesso'),
            duration: 6000,
            severity: 'success'
          })
          setTimeout(() => {
            goBack()
          }, 1000)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setOpenConfirmation(false)
    }
    setLoadingButton(false)
  }

  useEffect(() => {
    const loadingData = async () => {
      await getInitialData()
    }
    void loadingData()
  }, [])

  const handleConfirmButton = () => setOpenConfirmation(true)

  const onCancelClassRecordEdit = () => setOpenConfirmation(false)

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={3} display='flex' sx={{ pb: '32px' }} alignItems='center'>
        <div
          className={classes.backLink}
          onClick={handleCancel}
          data-testid='edit_registry_back_to_class_registrys'
        >
          <ArrowBackIosNewOutlined />
          <Text
            className={classes.backButton}
          >{t('Voltar')}
          </Text>
        </div>
      </Grid>
      <Grid item xs={12} display='grid'>
        <Text
          type='subtitle'
          size='medium'
          className={classes.titleAccordion}
        >{t('Editando registro')}
        </Text>
        <Text
          className={classes.subtitleAccordion}>{`*${t('Campo obrigatório')}`}
        </Text>
      </Grid>
      <AccordionMultiStudents
        selectedStudents={studentCollection}
        onChangeStudent={handleChangeStudent}
        studentsData={studentsData}
        dataTestidHide='edit_registry_hide_student_list'
        dataTestidSelectAllStudents='edit_registry_select_all_students'
        dataTestidAllStudents='edit_registry_students'
      />
      <Grid item xs={12}>
        <Text
          type='body'
          size='small'
          className={classes.subtitleAccordion}
        >
          {`${t('Selecione o componente curricular onde será inserido o registro')}*:`}
        </Text>
      </Grid>
      <Grid item xs={12} className={classes.disciplineContainer}>
        <Disciplines
          disciplines={disciplinesData}
          currentDiscipline={currentDisciplineId}
          changeDiscipline={handleChangeDiscipline}
          dataTestid='edit_registry_select_curricular_component'
        />
      </Grid>
      <Grid item display='contents'>
        <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
          <Stack spacing={2} className={classes.stackContainer}>
            <InputDate
              label={`${t('Data do registro')}*`}
              initialDate={initialDate}
              minDate={YearFirstDay}
              maxDate={today}
              onChangeDate={(value) => setInitialDate(value)}
              textFieldProps={{
                variant: 'filled'
              }}
              dataTestid='edit_registry_insert_registration_date'
            />
          </Stack>
        </LocalizationProvider>
        <Grid item xs={12} className={classes.textField}>
          <TextField
            maxLength={50}
            required
            fullWidth
            id='outlined-helperText'
            label={`${t('Título')}*`}
            variant='filled'
            value={titleField}
            onChange={e => {
              setTitleField(e.target.value)
            }}
          />
          <p className={`${classes.helpTextTitle} ${(titleField.length === 50) && 'limit'}`}>{t('HelpTextTitle', { titleNumber: `${titleField.length}` })}</p>
        </Grid>
        <Grid item xs={12} className={classes.textField}>
          <TextField
            maxLength={descriptionFieldMaxLength}
            required
            fullWidth
            id='filled-multiline-static'
            label={`${t('Descrição')}*`}
            variant='filled'
            value={recordField}
            onChange={e => {
              setRecordField(e.target.value)
            }}
            multiline
          />
          <p className={`${classes.helpTextDescription} ${recordField.length === descriptionFieldMaxLength && 'limit'}`}>{t('HelpTextDescription', { descriptionNumber: `${recordField.length}` })}</p>
        </Grid>
      </Grid>
      <p className={classes.text}>
        {t('Você também pode adicionar arquivos')}:
      </p>
      <Grid item xs={12} mb={2}>
        <InputFilePreview
          initialPreviews={previewRecords}
          reset={!recordFiles.length}
          availableArchivesTypes='.jpeg,.jpg,.png,.gif,.pdf,.webp,.mp4,.mov,.mp3,.wav,.m4a,.ogg'
          onChange={urls => handleUploadFiles(urls)}
          onProgress={progress => {
            if (progress > 1) setInUploadingProcess(true)
          }}
          dataTestid='edit_registry_add_files_to_registry'
          maxFileSize={70}
          description={t('fileFormats', { joinArrays: '\n' })}
        />
      </Grid>
      <Grid className={classes.classButton}>
        <Button
          sx={{ marginTop: '10px' }}
          variant='ghost'
          data-testid='edit_registry_cancel_registration_creation'
          onClick={handleCancel}
        >
          {t('Cancelar')}
        </Button>
        <Button
          disabled={validationSubmitButton(initialDate, studentCollection, titleField, recordField, currentDisciplineId) || loadingButton}
          variant='primary'
          data-testid='edit_registry_finish_creating_the_record'
          onClick={handleConfirmButton}
        >
          {
            !loadingButton
              ? <>
                {t('Enviar')}
              </>
              : <CircularProgress
                sx={{
                  color: theme.colorBrand.medium,
                  height: '30px !important',
                  width: '30px !important'
                }}
              />
          }
        </Button>
      </Grid>
      <ConfirmationModal
        title={t('Editando registro')}
        description={t('Ao editar este registro, será editado de todos os alunos que estão vinculados ao registro. Deseja editar o registro?')}
        onCancel={onCancelClassRecordEdit}
        onConfirm={onClickToSend}
        dataTestidCancel='confirm_edit_cancel'
        dataTestidConfirm='confirm_edit_confirm'
      />
    </Grid>
  )
}

export default EditSingleRecord
