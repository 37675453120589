import { IContentUnitResponse, IApiResponse, IContentSkillResponse, ISkillResponse, ISingleDidacticContentResponse, IContentUnitSkillTaxonomiesResponse, IContentUnitClassProgressResponse, IContentUnitStatus } from 'services/types'
import { CONTENT_UNIT, CONTENT_UNIT_V2, CONTENT_UNIT_BY_CLASS_DISCIPLINE, CONTENT_UNIT_SKILLS, CONTENT_UNIT_CONTENT_SKILL, CONTENT_UNIT_DIDATIC_CONTENT, CONTENT_UNIT_BY_CLASS_DISCIPLINE_V2, CONTENT_UNIT_SKILL_TAXONOMIES, CONTENT_UNIT_CLASS_PROGRESS, GET_CONTENT_UNIT_CLASS_PROGRESS } from './CONSTANTS'
import { request } from 'services/base'
import { AxiosRequestConfig } from 'axios'

export const getContentUnit = async (id: string): Promise<IApiResponse<IContentUnitResponse>> =>
  await request({ url: CONTENT_UNIT(id) })

export const getContentUnitV2 = async (id: string): Promise<IApiResponse<IContentUnitResponse>> =>
  await request({ url: CONTENT_UNIT_V2(id) })

export const getAllContentUnit = async (query?: string): Promise<IApiResponse<IContentUnitResponse[]>> =>
  await request({ url: `${CONTENT_UNIT()}?${query ?? ''}` })

export const getContentUnitByClassDiscipline = async (idClass: number, idDiscipline: number): Promise<IApiResponse<IContentUnitResponse[]>> =>
  await request({ url: CONTENT_UNIT_BY_CLASS_DISCIPLINE(idClass, idDiscipline) })

export const getContentSkills = async (id: string | number): Promise<IApiResponse<IContentSkillResponse[]>> =>
  await request({ url: CONTENT_UNIT_CONTENT_SKILL(id) })

export const getSkills = async (id: string | number): Promise<IApiResponse<ISkillResponse[]>> =>
  await request({ url: CONTENT_UNIT_SKILLS(id) })

export const getDidaticContent = async (classId: number, contentUnitId: number): Promise<IApiResponse<ISingleDidacticContentResponse>> =>
  await request({ url: CONTENT_UNIT_DIDATIC_CONTENT(classId, contentUnitId) })

export const getContentUnitByClassDisciplinev2 = async (idClass: number, idDiscipline: number): Promise<IApiResponse<IContentUnitResponse[]>> =>
  await request({ url: CONTENT_UNIT_BY_CLASS_DISCIPLINE_V2(idClass, idDiscipline) })

export const getContentSkillTaxonomies = async (id: string | number, cancelToken?: AxiosRequestConfig['cancelToken']): Promise<IApiResponse<IContentUnitSkillTaxonomiesResponse>> =>
  await request({ url: CONTENT_UNIT_SKILL_TAXONOMIES(id), cancelToken })

export const getContentUnitProgress = async (contentUnitId: number, classId: number): Promise<IApiResponse<IContentUnitClassProgressResponse>> =>
  await request({ url: GET_CONTENT_UNIT_CLASS_PROGRESS(contentUnitId, classId), disableToast: true })

export const updateContentUnitProgress = async (contentUnitId: string | number, classId: string | number, status: IContentUnitStatus): Promise<IApiResponse<IContentUnitClassProgressResponse>> =>
  await request({
    url: CONTENT_UNIT_CLASS_PROGRESS(),
    method: 'post',
    data: {
      content_unit_id: contentUnitId,
      class_id: classId,
      status
    }
  })
