import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  glossary: {
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'rgba(230, 230, 230, 0.40)',
    padding: theme.spacingInset.xs,
    paddingLeft: theme.spacingInset.md,
    color: theme.colors.neutral.dark10,
    borderRadius: theme.borderRadius.md,
    position: 'relative',

    [theme.breakpoints.up('xl')]: {
      width: '70%',
      maxWidth: '70%'
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.font.fontSize.lg,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: '32px',
    letterSpacing: '0.24px',
    color: theme.colors.support.colorSupport02,
    '& svg': {
      fontSize: '26px',
      marginRight: '8px'
    }
  },
  dangerouslyContent: {
    marginTop: '8px',
    '& > p > span, > p': {
      marginBlock: 0,
      fontSize: '18px', // theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.bold,
      lineHeight: '32px',
      letterSpacing: '0.16px'
    },
    '& > ol': {
      paddingInlineStart: '20px',
      marginBlock: '8px'
    },
    '& > ol > li > span, & > ol > li': {
      fontSize: '18px', // theme.font.fontSize.lg,
      fontWeight: theme.font.fontWeight.regular,
      lineHeight: '32px',
      letterSpacing: '0.16px',
      color: theme.colors.neutral.dark10
    }
  },
  fakeBorder: {
    position: 'absolute',
    width: '8px',
    background: theme.colors.support.colorSupport02,
    top: 0,
    bottom: 0,
    left: 0,
    borderTopLeftRadius: theme.borderRadius.md,
    borderBottomLeftRadius: theme.borderRadius.md
  }
}))
