import { useTranslation } from 'react-i18next'
// lib external components
import { Grid } from '@mui/material'
// global components
import { Header } from './Header'

import { Loading } from 'components/common'

interface ILoadingStateTypes {
  dropDown: boolean
  setDropDown: React.Dispatch<React.SetStateAction<boolean>>
}

export const LoadingState = ({ dropDown, setDropDown }: ILoadingStateTypes) => {
  const { t } = useTranslation()

  return (
    <>
      <Header dropDown={dropDown} setDropDown={setDropDown} />
      <Grid flexDirection='row' display='flex' sx={{ alignItems: 'center', justifyContent: 'flex-start', width: 'inherit', height: '300px' }}>
        {dropDown &&
          <div style={{ width: 'inherit', textAlign: 'center' }}>
            <Loading type={'circular'} />
            {t('Carregando o conteúdo...')}
          </div>
        }
      </Grid>
    </>
  )
}
