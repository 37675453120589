import { useEffect, useState } from 'react'

import Loading from './components/loading'
import Card from './components/Card'

import { IContentUnitResponse } from 'services/types'

export interface ContentCardLongProps {
  index: number
  contentUnit: IContentUnitResponse
  navigation: {
    gradeCode: string
    gradeTypeCode: string
    classId: number
  }
  dataTestid?: string
  highlight?: string
}

type IStateContentCardLong = 'idle' | 'loading' | 'card' | 'error'

export const ContentCardLong: React.FC<ContentCardLongProps> = ({ index, contentUnit, dataTestid, navigation, highlight }) => {
  const [state, setState] = useState<IStateContentCardLong>('idle')

  useEffect(() => {
    setState('loading')
    if (contentUnit) {
      setState('card')
    } else {
      setState('error')
    }
  }, [])

  const render: { [key in IStateContentCardLong]: any } = {
    idle: <Loading />,
    loading: <Loading />,
    card: <Card contentUnit={contentUnit} dataTestid={dataTestid} navigation={navigation} index={index} highlight={highlight} />,
    error: <>{'..error ContentCardLong'}</>
  }

  return render[state]
}

export default ContentCardLong
