import { useEffect, useState, Fragment } from 'react'
import { IActivityResponse, IContentUnitResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { Idle } from './components/Idle'
import { Loading } from './components/Loading'
import { WithActivities } from './components/WithActivities'
import { Error } from './components/Error'
import { useParams } from 'react-router-dom'
import { useStore } from 'store'
import isProjectViewModeRule from 'rules/isProjectViewModeRule'
import { OnlyProfile } from 'components/common'

interface IShallWePractiseProps {
  activities?: IActivityResponse[] | null
  content?: IContentUnitResponse | null
}

type IStateRender = 'idle' | 'loading' | 'withActivities' | 'error' | 'empty' | 'withoutActivities'

export const ShallWePractise: React.FC<IShallWePractiseProps> = ({
  activities,
  content
}) => {
  const [state, setState] = useState<IStateRender>('idle')
  const { gradeTypeCode, gradeCode, classId, sectionId, contentId } =
    useParams<{
      gradeTypeCode: string
      gradeCode: string
      classId: string
      sectionId: string
      contentId: string
    }>()
  const { profile } = useStore()
  const profileType = profile?.type
  const lessonId = Number(sectionId)

  let activityCardDataTestId = {
    activity: {
      available: 'lets_pratice_select_activity_realease',
      to_correct: 'lets_pratice_select_activity_answered',
      finished: 'lets_pratice_select_activity_returned'
    },
    evaluation: {
      available: 'lets_pratice_select_assessment_realease',
      to_correct: 'lets_pratice_select_assessment_answered',
      finished: 'lets_pratice_select_assessment_returned'
    }
  }

  if (profileType === IUserSchoolProfileTypeEnum.teacher) {
    activityCardDataTestId = {
      activity: {
        available: 'lets_pratice_select_activity_available',
        to_correct: 'lets_pratice_select_activity_to_correct',
        finished: 'lets_pratice_select_activity_finished'
      },
      evaluation: {
        available: 'lets_pratice_select_assessment_available',
        to_correct: 'lets_pratice_select_assessment_to_correct',
        finished: 'lets_pratice_select_assessment_finished'
      }
    }
  }

  useEffect(() => {
    setState('loading')
  }, [])

  useEffect(() => {
    const isProjectViewMode = isProjectViewModeRule(content?.grade?.grade_type ?? 0, content?.disciplines?.map(d => d.code) ?? [])
    if (activities?.length && isProjectViewMode) {
      setState('withActivities')
    } else {
      setState('withoutActivities')
    }
  }, [activities, content])

  const stateRender: { [key in IStateRender]: React.FC | React.ReactFragment } =
  {
    idle: <Idle />,
    loading: <Loading />,
    error: <Error />,
    empty: <Fragment />,
    withoutActivities: <Fragment />,
    withActivities: (
      <WithActivities
        activities={activities}
        lessonId={lessonId}
        classId={Number(classId)}
        gradeTypeCode={gradeTypeCode}
        gradeCode={gradeCode}
        contentId={Number(contentId)}
        activityCardDataTestId={activityCardDataTestId}
      />
    )
  }

  return (
    <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
      <div className='' id='component--shall-we-practise'>
        {stateRender[state]}
      </div>
    </OnlyProfile>
  )
}
