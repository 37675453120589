import { IApiResponse } from 'services/types'
import { IDisciplineResponse } from 'services/types/discipline'
import { DISCIPLINES, DISCIPLINES_V2 } from './CONSTANTS'
import { request } from 'services/base'

export const getDisciplines = async (id: number): Promise<IApiResponse<IDisciplineResponse[]>> =>
  await request({ url: DISCIPLINES(id) })

export const getDisciplinesByProfile = async (id: number): Promise<IApiResponse<IDisciplineResponse[]>> =>
  await request({ url: DISCIPLINES_V2(id) })
