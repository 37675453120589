import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { IAlternativeProps } from '.'

export default makeStyles((theme: Theme) => createStyles({
  question: {
    padding: theme.spacingStack.nano,
    marginLeft: 0,
    marginBlock: theme.spacingStack.xxxs,
    borderRadius: theme.spacingStack.nano,
    width: '100%',
    border: (props: IAlternativeProps) => {
      if (props.alternative.levelAlternative === 0) {
        return `2px solid ${theme.colors.negative.feedbackMedium}`
      } else if (props.alternative.levelAlternative === 100) {
        return `2px solid ${theme.colors.positive.feedbackMedium}`
      } else {
        return `1px solid ${theme.colors.neutral.light30}`
      }
    },
    '& span': {
      fontWeight: theme.font.fontWeight.medium,
      color: (props: IAlternativeProps) => {
        if (props.alternative.levelAlternative === 0) {
          return `${theme.colors.negative.feedbackMedium}`
        } else if (props.alternative.levelAlternative === 100) {
          return `${theme.colors.positive.feedbackMedium}`
        } else {
          return `${theme.colors.neutral.light30}`
        }
      }
    },
    '& .MuiFormControlLabel-label': {
      width: '100%'
    }
  },
  colorButtonDefault: {
    color: theme.colors.neutral.dark20
  },
  correctText: {
    padding: `0 ${theme.spacingInline.xxxs}`,
    fontSize: theme.font.fontSize.xxs,
    alignSelf: 'center'
  },
  alternativeText: {
    fontSize: '18px', // theme.font.fontSize.xxs,
    // mobile
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: theme.font.fontSize.sm,
      color: (props: IAlternativeProps) => {
        if (props.alternative.levelAlternative === 0) {
          return `${theme.colors.negative.feedbackMedium}`
        } else if (props.alternative.levelAlternative === 100) {
          return `${theme.colors.positive.feedbackMedium}`
        } else {
          return `${theme.colors.neutral.dark40}`
        }
      }
    }
  },
  questionText: {
    fontSize: theme.font.fontSize.sm,
    // mobile
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontSize: theme.font.fontSize.sm,
      color: theme.colors.neutral.dark40
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
})
)
