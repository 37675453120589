import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AccountCircleOutlined } from '@mui/icons-material'

import { Button, Alert } from 'components/design-system'

import { GridInfo, CustomButton, CustomContent } from './components'

import { STATE, IRecipientsSelectProps } from './types'

import useStyles from './style'

const EMPTY: STATE = 'empty'
const FILL: STATE = 'fill'
const CHANGED_RECIPIENT: STATE = 'changed_recipient'

export const RecipientsSelect = ({ currentState, setRecipientsModalSelector, validateInfoSubscriptions }: IRecipientsSelectProps) => {
  // const
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()

  const states = {
    [EMPTY]: (
      <>
        <GridInfo theme={theme} />
        <CustomButton onClick={() => setRecipientsModalSelector(true)} dataTestid='event_without_expedition_select_recipients'>
          <CustomContent border={`solid 2px ${theme.colors.neutral.light30}`} theme={theme}>
            <Grid
              item
              alignSelf='center'
              display='flex'
              paddingLeft={theme.spacingInline.nano}
              sx={{
                '& svg': {
                  color: theme.colorBrand.medium
                }
              }}
            >
              <AccountCircleOutlined />
            </Grid>

            <Grid display='flex' item alignSelf='center' paddingY={theme.spacingInline.nano}>
              <span style={{ color: theme.colorBrand.medium, fontWeight: theme.font.fontWeight.semibold }} >{t('Destinatários')}</span>
            </Grid>
          </CustomContent>
        </CustomButton>
      </>
    ),
    [FILL]: (
      <>
        <GridInfo theme={theme} />
        <CustomContent border={`solid 2px ${theme.colorBrand.medium}`} theme={theme}>
          <Grid display='flex' item alignSelf='center' paddingY={theme.spacingInline.nano}>
            <span style={{ color: theme.colors.neutral.dark20, fontWeight: theme.font.fontWeight.medium, fontSize: theme.font.fontSize.xs }}>
              {validateInfoSubscriptions()}
            </span>
          </Grid>
        </CustomContent>

        <Button
          variant='outlined'
          sx={{
            padding: '8px 12px !important',
            fontWeight: `${theme.font.fontWeight.semibold} !important`
          }}
          onClick={() => setRecipientsModalSelector(true)}
          data-testid='event_without_expedition_edit_selection'
        >
          {t('Editar')}
        </Button>
      </>
    ),
    [CHANGED_RECIPIENT]: (
      <>
        <Grid item display='flex'>
          <Alert icon={false} severity='warning' content='Você incluiu estudantes em um evento já iniciado. Comunique-os para que não percam o evento.' />
        </Grid>
        <GridInfo theme={theme} />
        <CustomContent border={`solid 2px ${theme.colorBrand.medium}`} theme={theme}>
          <Grid display='flex' item alignSelf='center' paddingY={theme.spacingInline.nano}>
            <span style={{ color: theme.colors.neutral.dark20, fontWeight: theme.font.fontWeight.medium, fontSize: theme.font.fontSize.xs }}>
              {validateInfoSubscriptions()}
            </span>
          </Grid>
        </CustomContent>

        <Button
          variant='outlined'
          sx={{
            padding: '8px 12px !important',
            fontWeight: `${theme.font.fontWeight.semibold} !important`
          }}
          onClick={() => setRecipientsModalSelector(true)}
          data-testid='event_without_expedition_edit_selection'
        >
          {t('Editar')}
        </Button>
      </>
    )
  }

  return (
    <Grid
      container
      sx={{
        padding: theme.spacingSquish.nano,
        paddingY: theme.spacingInline.xxs,
        borderBottom: `solid 1px ${theme.colors.neutral.light10}`,
        display: 'grid',
        gridTemplateColumns: '1fr'
      }}
      rowGap={'16px'}
    >
      <Grid
        item
        sx={{
          display: 'grid',
          alignContent: 'center',
          gridTemplateColumns: 'auto auto',
          columnGap: theme.spacingInset.xs
        }}
      >
        <span className={classes.txtTitle}>{t('Destinatários')}</span>
      </Grid>
      {states[currentState]}
    </Grid>
  )
}
