import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'components/design-system/Toast/manager'
import {
  expeditionCachePipe,
  ExpeditionCachedTable,
  getExpeditionCached,
  OfflineTableExpeditionCachedStatus,
  DownloadButtonStatus,
  addCacheExpeditionToLocalDatabase,
  setCurrentWorking,
  getCurrentWorking
} from '../'

type useExpeditionCacheProps = {
  expeditionId: number
  onSuccess?: () => void
  onError?: () => void
}

type useExpeditionCacheReturn = {
  run: (currentStatus: DownloadButtonStatus) => Promise<boolean>
  expeditionInCache: ExpeditionCachedTable | undefined | null
  changeStatus: (newStatus: OfflineTableExpeditionCachedStatus) => void
  clear: () => void
}

export const useExpeditionCache = (props: useExpeditionCacheProps): useExpeditionCacheReturn => {
  const { expeditionId, onError, onSuccess } = props
  const [expeditionInCache, setExpeditionInCache] = useState<ExpeditionCachedTable | undefined | null>()
  const { t } = useTranslation()

  const getExpedition = useCallback(async (expeditionId: number) => {
    if (!expeditionId) return

    const expeditionInCache = await getExpeditionCached(expeditionId)
    if (expeditionInCache) {
      setExpeditionInCache(expeditionInCache)
    } else {
      setExpeditionInCache(null)
    }

    return expeditionInCache
  }, [])

  useEffect(() => {
    getExpedition(expeditionId)

    return () => {
      setExpeditionInCache(undefined)
    }
  }, [expeditionId])

  useEffect(() => {
    window.addEventListener('expeditionInCacheUpdated', () => { getExpedition(expeditionId) })

    return () => {
      window.removeEventListener('expeditionInCacheUpdated', () => { getExpedition(expeditionId) })
    }
  }, [])

  const run = useCallback(async (currentStatus: DownloadButtonStatus) => {
    const currentExpedition = getCurrentWorking('expeditionId')

    if (currentExpedition) {
      toast.handler({
        content: t('Você só pode baixar um conteúdo de cada vez.'),
        duration: 5000,
        severity: 'info'
      })
      return false
    }

    if (['ERROR', 'INITIAL'].includes(currentStatus)) {
      const expeditionIndexed = await addCacheExpeditionToLocalDatabase(expeditionId)
      setCurrentWorking('expeditionId', String(expeditionId))
      setExpeditionInCache(expeditionIndexed)

      const pipeResponse = await expeditionCachePipe(expeditionId, expeditionIndexed)
      if (pipeResponse) {
        onSuccess?.()
      } else {
        onError?.()
      }
      return pipeResponse
    }
    return true
  }, [expeditionId, expeditionInCache])

  const changeStatus = useCallback((newStatus: OfflineTableExpeditionCachedStatus) => {
    setExpeditionInCache(prevExpeditionInCache => {
      if (prevExpeditionInCache) {
        return ({
          ...prevExpeditionInCache,
          status: newStatus
        })
      }
    })
  }, [])

  const clear = useCallback(() => {
    setExpeditionInCache(null)
  }, [])

  return {
    run,
    expeditionInCache,
    changeStatus,
    clear
  }
}
