import { CustomTheme } from 'styles/types/CustomTheme'
import { createStyles, CSSProperties, makeStyles } from '@mui/styles'

const styleDefault: (theme: CustomTheme) => CSSProperties = theme => ({
  minWidth: '97px',
  textTransform: 'none',
  fontFamily: theme.font.fontFamily,
  fontSize: theme.font.fontSize.sm,
  fontWeight: theme.font.fontWeight.semibold,
  border: theme.borderThickness.none,
  borderRadius: theme.borderRadius.pill,
  padding: theme.spacingSquish.xs,
  zIndex: 1,
  height: 'auto',
  '&.Mui-focusVisible': {
    transition: '.1s',
    boxShadow: `0px 0px 0px 3px ${theme.colorBrand.darkest}`
  },
  '&.MuiButton-sizeSmall': {
    padding: theme.spacingSquish.nano,
    fontSize: theme.font.fontSize.xxs
  }
})

export default makeStyles((theme: CustomTheme) => createStyles({
  root: {
    '&.primary': {
      ...styleDefault(theme),
      background: theme.colorBrand.medium,
      color: theme.colors.neutral.lightBase,
      border: `1px solid ${theme.colorBrand.medium}`,
      padding: '14px 24px',
      '&:hover': {
        background: theme.colorBrand.dark,
        border: `1px solid ${theme.colorBrand.dark}`
      },
      '&:disabled': {
        color: theme.colors.neutral.dark40,
        background: theme.colors.neutral.light10,
        border: `1px solid ${theme.colors.neutral.light10}`
      }
    },
    '&.outlined': {
      ...styleDefault(theme),
      color: theme.colorBrand.medium,
      border: `solid ${theme.borderThickness.hairline}`,
      borderColor: theme.colorBrand.medium,
      padding: '14px 24px',
      '&:hover': {
        background: theme.colorBrand.lightest
      },
      '&:disabled': {
        color: theme.colors.neutral.light40,
        background: theme.colors.neutral.light10,
        borderColor: theme.colors.neutral.light40
      }
    },
    '&.ghost': {
      ...styleDefault(theme),
      color: theme.colorBrand.medium,
      '&:hover': {
        background: theme.colorBrand.lightest
      },
      '&:disabled': {
        color: theme.colors.neutral.light40
      }
    },
    '&.outlinedGhost': {
      ...styleDefault(theme),
      color: theme.colors.neutral.dark30,
      border: `solid ${theme.borderThickness.hairline}`,
      borderColor: theme.colors.neutral.dark30,
      '&:hover': {
        background: theme.colorBrand.medium,
        color: theme.colors.neutral.lightBase
      },
      '&:disabled': {
        color: theme.colors.neutral.light40,
        background: theme.colors.neutral.light10,
        borderColor: theme.colors.neutral.light40
      }
    }
  }
}))

export const ripple = {
  primary: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.darkest,
      filter: 'brightness(50%)'
    }
  })),
  outlined: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.light,
      filter: 'brightness(50%)'
    }
  })),
  ghost: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.lightest,
      filter: 'brightness(50%)'
    }
  })),
  outlinedGhost: makeStyles((theme: CustomTheme) => ({
    child: {
      background: theme.colorBrand.lightest,
      filter: 'brightness(50%)'
    }
  }))
}
