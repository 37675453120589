import { useState } from 'react'
import { Grid } from '@mui/material'
import { IScheduleResponse } from 'services/types'
import { ScheduleCard, ScheduleOriginalContent } from 'pages/Dashboard/common'

interface IReadyProps {
  schedules: IScheduleResponse[]
}

export const ShowCards: React.FC<IReadyProps> = ({ schedules }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentSchedule, setCurrentSchedule] = useState<IScheduleResponse>()

  return (
    <>
      <Grid item xs={12} mt={3} mb={4} display='flex' flexWrap='wrap' gap={2}>
        {schedules.map((schedule, i) =>
          <ScheduleCard
            key={schedule.id}
            dataTestid='teacher_materials_open'
            coverUrl={
              schedule.content_unit?.cover_mobile?.formats?.small?.url ??
              schedule.content_unit?.cover_mobile?.formats?.medium?.url ??
              schedule.content_unit?.cover_mobile?.formats?.large?.url ??
              schedule.content_unit?.cover_mobile?.formats?.thumbnail?.url ??
              schedule.content_unit?.cover_mobile?.url ?? ''
            }
            onClick={() => {
              if (schedule.type === 'original_content') {
                setCurrentSchedule(schedule)
                setIsOpen(true)
              }
            }}
            {...schedule}
          />
        )
        }
      </Grid>
      {
        currentSchedule &&
        <ScheduleOriginalContent
          {...currentSchedule}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(!isOpen)
            setCurrentSchedule(undefined)
          }}
          dataTestIdClose='preview_teacher_materials_close'
          dataTestIdOpenAttachment='preview_teacher_materials_open_attachment'
        />
      }
    </>
  )
}
