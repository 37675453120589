import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { StudentCardInfo, StudentCardInfoWithAvatar } from '..'
import useStyles from './styles'
import { useStore } from 'store'

export const StudentCards: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { session, profile } = useStore()

  const showTwoCards = useMediaQuery(theme.breakpoints.up('lg'))
  const showTwoCardsFullWidth = useMediaQuery('(max-width:1229px)')

  // atoms

  return (
    <Grid container item columnSpacing={3} rowSpacing={showTwoCardsFullWidth ? 2 : 0} className={classes.studentCardsGrid}>
      {session && profile && (
        <Grid item xs={12} lg={6} xl={showTwoCardsFullWidth ? 12 : 6}>
          <StudentCardInfoWithAvatar user={session} userSchoolProfile={profile} />
        </Grid>
      )}
      {showTwoCards && session && profile && (
        <Grid item xs={12} lg={6} xl={showTwoCardsFullWidth ? 12 : 6}>
          <StudentCardInfo user={session} userSchoolProfile={profile} />
        </Grid>
      )}
    </Grid>
  )
}
