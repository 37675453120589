import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  galleryContainer: {
    width: 'fit-content',

    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  galleryToolbarContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.neutral.lightBase,
    backgroundColor: theme.colors.neutral.light40,
    boxShadow: '0px 4px 8px rgba(20, 20, 20, 0.16)',
    borderTopLeftRadius: theme.borderRadius.xs,
    borderTopRightRadius: theme.borderRadius.xs,
    padding: theme.spacingInline.nano,
    paddingRight: 0,
    fontSize: theme.font.fontSize.xs,

    '& span': {
      overflowWrap: 'anywhere'
    }
  },
  movWarning: {
    width: '100%',
    marginTop: '10px'
  },
  groupIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& svg': {
      marginRight: theme.spacingInline.nano,
      fontSize: theme.font.fontSize.md,
      cursor: 'pointer'
    }
  },
  galleryContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto'
  },
  mediaContent: {
    zIndex: 2,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    border: 'none',
    borderBottomLeftRadius: theme.borderRadius.xs,
    borderBottomRightRadius: theme.borderRadius.xs
  },
  galleryPagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacingStack.md
  },
  pdfModalTitle: {
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: '15px',
    '&:focus-visible': {
      outline: 'none'
    }
  },
  pdfModalButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  audioContainer: {
    '& audio': {
      width: '100%',
      maxWidth: '100%'
    }
  },
  fullWidth: {
    width: '100%'
  },
  contentDownload: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacingInline.xxxs,
    backgroundColor: theme.colorBrand.medium,
    color: theme.colors.neutral.lightBase,

    '& svg': {
      fill: theme.colors.neutral.lightBase,
      cursor: 'pointer'
    }
  }
}))
