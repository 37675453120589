import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IClassSubscriptionResponse } from 'services/types'

import { Grid } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Avatar, CheckBox } from 'components/design-system'

import useStyle from './style'

export interface IAccordionSingleStudentsProps {
  onChangeStudent: (student: number[]) => void
  studentsData: IClassSubscriptionResponse[]
  selectedStudents: number[]
  dataTestidAllStudents?: string
  dataTestidHide?: string
  dataTestidSelectAllStudents?: string
}

const AccordionMultiStudents = ({
  onChangeStudent,
  studentsData,
  selectedStudents,
  dataTestidAllStudents,
  dataTestidHide,
  dataTestidSelectAllStudents
}: IAccordionSingleStudentsProps) => {
  const [studentCollection, setStudentCollection] = useState<number[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const { t } = useTranslation()
  const classes = useStyle()

  useEffect(() => {
    setStudentCollection(selectedStudents)
  }, [selectedStudents])

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectAll(true)
      onChangeStudent(studentsData.map(student => student.user_school_profile.id))
      setStudentCollection(studentsData.map(student => student.user_school_profile.id))
    } else {
      setSelectAll(false)
      onChangeStudent([])
      setStudentCollection([])
    }
  }

  const handleChangeStudent = (student: IClassSubscriptionResponse) => {
    if (studentCollection.includes(student.user_school_profile.id)) {
      let newStudentCollection = []

      newStudentCollection = studentCollection.filter((st) => st !== student.user_school_profile.id)

      if (newStudentCollection.length < studentsData.length) {
        setSelectAll(false)
      }

      onChangeStudent(newStudentCollection)
      setStudentCollection(newStudentCollection)
    } else {
      const newStudentCollection = studentCollection.concat([student.user_school_profile.id])

      if (newStudentCollection.length === studentsData.length) {
        setSelectAll(true)
      }

      onChangeStudent(newStudentCollection)
      setStudentCollection(newStudentCollection)
    }
  }

  return (
    <Grid item xs={12} mt={3} gap={1}>
      <Accordion
        defaultExpanded
        square={false}
        className={classes.containerAcordeon}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon data-testid={dataTestidHide}/>}
          aria-controls='panel1a-content'
          sx={{ margin: '8px 0', padding: '0px' }}
          className={classes.accordeonSummary}
        >
          <p className={classes.titleAcordeon}>{`${t('Estudantes')}*`}</p>
        </AccordionSummary>
        <div className={classes.checkboxContainer}>
          <button>
            <CheckBox
              label={t('Selecionar todos')}
              size='small'
              checked={selectAll}
              onClick={handleSelectAll}
              dataTestid={dataTestidSelectAllStudents}
            />
          </button>
        </div>
        <AccordionDetails className={classes.accordionDetails}>
          {studentsData?.map((student) => (
            <button
              key={student.user_school_profile.id}
              className={`${classes.avatarCard} ${(studentCollection.includes(student.user_school_profile.id) || selectAll) && 'is-active'}`}
              onClick={() => handleChangeStudent(student)}
              data-testid={dataTestidAllStudents}
            >
              <Avatar size='small' userName={student.user_id.name} />
              <p className={classes.avatarName}>
                {`${student.user_id.name} ${student.user_id.surname}`}
              </p>
            </button>
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default AccordionMultiStudents
