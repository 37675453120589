import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  bigNumbesReportsContainer: {
    display: 'flex',
    gap: theme.spacingInset.lg,
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacingInset.sm
    }
  }
}))
