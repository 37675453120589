import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { TextField } from 'components/design-system'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.colors.neutral.dark30,
      fontFamily: theme.font.fontFamily,
      fontWeight: theme.font.fontWeight.regular,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.sm,
      paddingTop: 0,
      marginTop: 0,
      '& > a': {
        color: theme.colorBrand.medium,
        fontWeight: theme.font.fontWeight.bold
      }
    },
    answerInput: {
      width: '100%',
      textAlign: 'left'
    }
  })
)

interface IShortTextProps {
  label: string
  value?: string
  name?: string
  disabled?: boolean
  onChange: (value: string) => void
}

export const ShortText: React.FC<IShortTextProps> = ({ label, value, onChange, disabled, ...props }) => {
  const classes = useStyle()
  return (
    <>
      <Grid item xs={12}>
        <TextField
          id='answer'
          variant='filled'
          multiline
          className={classes.answerInput}
          label={label}
          name={props.name ?? ''}
          onChange={e => onChange(e.target.value.trimStart())}
          disabled={disabled}
          value={value}
        />
      </Grid>
    </>
  )
}
