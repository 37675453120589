/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, CircularProgressProps, CircularProgress as CircularProgressMUI, useTheme, Theme } from '@mui/material'
import { Text } from 'components/design-system'

import useStyles from './style'

export interface ICircularProgress extends CircularProgressProps {
  value: number
  showPercentageSymbol?: boolean
  circleSize?: string
  textFontSize?: string
  customText?: string
  theme: Theme
}
// TODO: REMOVE THEME PROP
export const CircularProgress: React.FC<ICircularProgress> = ({ value, customText, showPercentageSymbol = false, circleSize = '5rem', textFontSize, theme }) => {
  // TODO: FIX MUI THEMES NOT BEIGN APPLIED TO STYLE TS
  const classes = useStyles()

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgressMUI
        variant='determinate'
        sx={{
          color: (theme: Theme) => (theme.colorBrand.lightest)
        }}
        size={circleSize}
        thickness={5}
        value={100}
      />
      <CircularProgressMUI
        variant='determinate'
        sx={{
          color: (theme: Theme) => (theme.colorBrand.dark),
          position: 'absolute',
          left: 0
        }}
        size={circleSize}
        thickness={5}
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Text
          type='subtitle'
          size='medium'
          className={classes.circularProgressCaption}
        >
          {
            customText ?? `${Math.round(value)}${showPercentageSymbol ? '%' : ''}`
          }
        </Text>
      </Box>
    </Box>
  )
}
