import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  infogramContainer: {
    margin: 'auto',
    width: '100%',
    maxWidth: '720px',
    textAlign: 'center'
  }
}))
