import { atom } from 'jotai'
import { IClassActivityStatusesResponse } from 'services/types'

export interface IReviseObjectAtomProps {
  questionId: number
  revision?: boolean
}

export interface IStudentsCorrectedAtomProps {
  studentId: number
}

export const showModalConfirmationAtom = atom<boolean>(false)
export const showModalFinalizationAtom = atom<boolean>(false)
export const showModalCorrectedAtom = atom<boolean>(false)

export const selectedStudentProfileAtom = atom<number>(0)
export const loadingRequestAtom = atom<boolean>(false)
export const reloadingActivityAtom = atom<boolean>(false)
export const indexStudentPaginationAtom = atom<number>(0)
export const reviseObjectAtom = atom<IReviseObjectAtomProps[] | null>(null)
export const studentsCorrectedAtom = atom<IStudentsCorrectedAtomProps[] | null>(null)
export const classActivityStatusesAtom = atom<IClassActivityStatusesResponse[] | null>(null)
export const activitySuggestedApplicationTypeAtom = atom<'activity' | 'evaluation' | null>(null)
