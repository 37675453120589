import React from 'react'
import { Grid } from '@mui/material'
import { useAtom } from 'jotai'

import useStyles from './style'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'
import { Button } from 'components/design-system'
import { activeQuestionAtom } from '../../atomStore'

export const NavigationButtons: React.FC = () => {
  const classes = useStyles()

  // atom
  const [activity] = useAtom(activityCurrentAtom)
  const [activeQuestion, setActiveQuestion] = useAtom(activeQuestionAtom)

  const indexFound = activity?.questions?.findIndex(question => question.id === activeQuestion)

  const handlePreviousQuestion = () => {
    if (indexFound !== undefined && indexFound > -1) {
      if (indexFound > 0) {
        setActiveQuestion(activity?.questions?.[indexFound - 1]?.id ?? 0)
      }
    }
  }

  const handleNextQuestion = () => {
    if (indexFound !== undefined && indexFound > -1) {
      if (indexFound < (activity?.questions ?? []).length - 1) {
        setActiveQuestion(activity?.questions?.[indexFound + 1]?.id ?? 0)
      }
    }
  }

  const dataTestIdNext = activity?.suggested_application_type === 'activity' ? 'correction_activity_menu_answers_next_question' : 'correction_assessment_menu_assessments_next_question'
  const dataTestIdPrevious = activity?.suggested_application_type === 'activity' ? 'correction_activity_menu_answers_previous_question' : 'correction_assessment_menu_assessments_previous_question'

  return (
    <Grid container className={classes.navigationButtonsContainer} display='flex' flexDirection='row' justifyContent='space-between'>
      {(indexFound !== undefined && indexFound > 0) ? <Button data-testid={dataTestIdPrevious} variant='outlined' size='small' onClick={handlePreviousQuestion}>Questão anterior</Button> : <div />}
      {(indexFound !== undefined && indexFound < (activity?.questions ?? []).length - 1) ? <Button data-testid={dataTestIdNext} variant='outlined' size='small' onClick={handleNextQuestion}>Próxima questão</Button> : <div />}
    </Grid>
  )
}

export default NavigationButtons
