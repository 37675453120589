import { Button, Tag } from 'components/design-system'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import { DisciplineCodeEnum } from 'services/types/discipline'
import { contentUnitCardAtom, IContentUnitCardAtom, openContentUnitModalAtom } from '../../../atomStore'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useAtom } from 'jotai'
import { IDisciplinesResponse } from 'services/types/schedule'
import { IMediaResponse } from 'services/types'
import { useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
export interface ICoverProps {
  title?: string
  disciplines?: IDisciplinesResponse[]
  suggestedPosition?: number
  imageUrl?: IMediaResponse
}

export const ContentModalCover: React.FC<ICoverProps> = () => {
  const [contentCardUnit] = useAtom(contentUnitCardAtom)
  const { title, cover, coverMobile, suggested_position: suggestedPosition, disciplines } = contentCardUnit as IContentUnitCardAtom

  const [, setOpenContentUnitModal] = useAtom(openContentUnitModalAtom)

  const isCloeExpand = disciplines?.map(d => d.code).includes(DisciplineCodeEnum.BI)

  const props = {
    title,
    imageUrl: cover ?? coverMobile,
    suggestedPosition,
    disciplines
  }

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const classes = useStyles(props)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const handleCloseModal = () => setOpenContentUnitModal(false)

  return (
    isMobile
      ? <Grid item xs={12} className={classes.backgroundImage}>
        <div className={classes.boxCover}>
          <div className={classes.iconWrapper}>
            <Button style={{
              background: 'transparent',
              padding: '4px',
              minWidth: 'unset'
            }} onClick={handleCloseModal}>
              <KeyboardArrowLeftIcon style={{ fontSize: '32px', color: 'white' }} />
            </Button>
          </div>
          <div className={classes.disciplineContainer}>
            {
              disciplines?.map(d =>
                <Tag key={d.id} variant={d.code as DisciplineCodeEnum}>
                  {t(d.name, true)}
                </Tag>
              )
            }
          </div>
        </div>
      </Grid>
      : <Grid item xs={12} className={classes.backgroundImage}>
        <div className={classes.boxCover}>
          {
            isMobile
              ? <div className={classes.iconWrapper}>
                <Button style={{
                  background: 'transparent',
                  padding: '4px'
                }} onClick={handleCloseModal}>
                  <CloseIcon style={{ fontSize: '28px', color: 'white' }} />
                </Button>
              </div>
              : ''
          }
          <div className={classes.exitbuttonWrapper}>
            <Button style={{
              background: 'transparent',
              padding: '4px',
              minWidth: '0px'
            }} onClick={handleCloseModal}>
              <CloseIcon style={{ fontSize: '28px', color: 'white' }} />
            </Button>
          </div>
          <div className={classes.disciplineContainer}>
            {
              disciplines?.map(d =>
                <Tag key={d.id} variant={d.code as DisciplineCodeEnum}>
                  {t(d.name, true)}
                </Tag>
              )
            }
          </div>
          <div className={classes.expeditionContainer}>
            {t('Unidade')} {suggestedPosition}
          </div>
          <div className={classes.titleContainer}>
            {title}
          </div>
        </div>
      </Grid>
  )
}

export default ContentModalCover
