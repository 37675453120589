import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  collumnFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  resourceContainer: {
    width: '100%',
    marginTop: theme.spacingStack.xxs,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&.vimeoContainer': {
      width: '100% !important'
    }
  },
  resourceType: {
    backgroundColor: theme.colors.support.colorSupport02,
    padding: theme.spacingInline.nano,
    borderRadius: theme.borderRadius.xs,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
