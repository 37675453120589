import { useMemo } from 'react'
import { ActivityControlStatusEnum, IQuestionResponse } from 'services/types'
import Grid from '@mui/material/Grid'
import InputTypeComponent from '../InputTypeComponent'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { activityStatusControlAtom, questionIndexAtom, activityCurrentAtom } from '../../../../../../../../atomStore'
import { useTheme } from '@mui/material'
import { Button } from 'components/design-system'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Solution } from '../Solution'

interface IQuestionsProps {
  question: IQuestionResponse
}

export const TeacherQuestionView: React.FC<IQuestionsProps> = ({ question }) => {
  // TODO: refactor this hook to make it smaller
  const { t } = useTranslation()
  const theme = useTheme()
  const [activity] = useAtom(activityCurrentAtom)
  const [questionIndex, setQuestionIndex] = useAtom(questionIndexAtom)
  const [activityStatusControl] = useAtom(activityStatusControlAtom)
  const isActivityCorrection = useMemo(() => [
    ActivityControlStatusEnum.CLOSED,
    ActivityControlStatusEnum.PENDING,
    ActivityControlStatusEnum.DONE,
    ActivityControlStatusEnum.CORRECTED
  ].includes(parseInt(activityStatusControl as string)), [activityStatusControl])
  const isActivitySuggestedType = useMemo(() => activity?.suggested_application_type === 'activity', [activity?.suggested_application_type])

  const nextQuestion = () => {
    setQuestionIndex(questionIndex + 1)
  }

  if (!activity) return null

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          {/* // TODO: entender pq como componente quebra */}
          {/* <InputTypeComponent question={question} /> */}
          {InputTypeComponent({ question })}
        </Grid>
      </Grid>

      <Solution
        questions={activity?.questions} questionIndex={questionIndex}
        dataTestidShow={isActivitySuggestedType
          ? 'correction_activity_menu_questions_view_solution'
          : 'correction_assessment_menu_questions_view_solution'
        }
        dataTestidHide={isActivitySuggestedType
          ? 'correction_activity_menu_questions_hide_solution'
          : 'correction_assessment_menu_questions_hide_solution'
        }
      />

      <Grid
        container
        justifyContent='space-between'
        mt={3}
        sx={{ marginTop: { lg: '32px' }, marginBottom: '41px' }}
      >
        <Grid container sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          // eslint-disable-next-line @typescript-eslint/quotes
          gridTemplateAreas: `'interact button'`,
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gridTemplateAreas: `"interact"
                  "button"`,
            rowGap: theme.spacingInline.xxs
          }
        }}>
          <Grid item sx={{
            gridArea: 'button',
            display: 'flex',
            justifyContent: 'right',
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'center'
            }
          }}>
            {
              (questionIndex < activity.questions.length - 1) &&
              <Button
                variant='ghost'
                sx={{ alignSelf: 'end', gap: theme.spacingInline.xxxs }}
                onClick={() => nextQuestion()}
                data-testid={isActivityCorrection
                  ? isActivitySuggestedType
                    ? 'correction_activity_menu_answers_next_question'
                    : 'correction_assessment_menu_assessments_next_question'
                  : ''}
              >
                {t('Ir para a próxima questão')}
                <ArrowForwardIcon />
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TeacherQuestionView
