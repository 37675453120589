import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useStore } from 'store'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'

import Grid from '@mui/material/Grid'
import { Alert, useTheme } from '@mui/material'
import { ReactComponent as CalendarClock } from 'assets/calendar-clock.svg'
import { ReactComponent as InsertInvitation } from 'assets/insert-invitation.svg'
import { Tag, Text2 } from 'components/design-system'
import OnlyProfile from 'components/common/OnlyProfile'
import { StatusTag } from './components/StatusTag'

import {
  CONTENT_UNIT_TEACHER,
  CONTENT_UNIT_TEACHER_ACTIVITY,
  CONTENT_UNIT_TEACHER_EVALUATION
} from 'navigation/CONSTANTS'

import {
  ISectionResponse,
  IContentUnitResponse,
  IActivityResponse,
  IUserSchoolProfileTypeEnum,
  ActivityControlStatusEnum,
  DisciplineCodeEnum
} from 'services/types'

import makeStyles from './style'
import { ActivityDescription } from '../ActivityDescription'

interface IRelatedContentProps {
  activityType?: string
  section?: ISectionResponse
  content?: IContentUnitResponse
  activity?: IActivityResponse
}

export const RelatedContent: React.FC<IRelatedContentProps> = ({ activityType, section, content, activity }) => {
  dayjs.extend(customParseFormat)

  const classes = makeStyles()
  const theme = useTheme()
  const { subscription, profile } = useStore()

  const profileType = subscription?.user_school_profile?.type ?? profile?.type
  const isCloeExpand = content?.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI) ?? false
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const { gradeTypeCode, gradeCode, classId, contentId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string, contentId: string }>()

  const doesHaveDescription = activity?.custom_type === 'clone' || !!activity?.schedule?.content

  const getContentLink = (activityId: string) => {
    if (profileType === IUserSchoolProfileTypeEnum.teacher) {
      if (activity) {
        if (activity.suggested_application_type === 'activity') {
          return CONTENT_UNIT_TEACHER_ACTIVITY(gradeTypeCode, gradeCode, classId, contentId, activityId)
        } else {
          return CONTENT_UNIT_TEACHER_EVALUATION(gradeTypeCode, gradeCode, classId, contentId, activityId)
        }
      }
    } else {
      return CONTENT_UNIT_TEACHER(gradeTypeCode, gradeCode, classId, contentId)
    }
    return ''
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        mt={{
          xs: 0,
          md: 0,
          lg: 3
        }}
        mb={3}
      >
        <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
          {
            activity?.origin && (
              <Grid
                item
                display='grid'
                xs={12}
                sx={{
                  color: theme.colors.neutral.dark20,
                  fontSize: theme.font.fontSize.xs,
                  fontWeight: theme.font.fontWeight.medium,
                  mb: theme.spacingInset.xs
                }}
              >
                {t('Derivada de')}: <NavLink to={getContentLink(activity.origin.id.toString())} className={classes.usedInContent}>{activity.origin.title}</NavLink>
              </Grid>
            )
          }
        </OnlyProfile>
        {
          activity?.activity_status?.has_answer && (activity.activity_status.status === ActivityControlStatusEnum.CLOSED || activity.activity_status.status === ActivityControlStatusEnum.DONE) &&
          <Grid item display='grid' xs={12} sx={{ pb: 2 }}>
            <Text2 fontSize='xs' mobile='xs' lineHeight='xs' fontWeight='medium' warning='feedbackMedium'>
              {t('Aguardando correção')}
            </Text2>
          </Grid>
        }
        {
          activity?.moment_type && (
            <Grid item display='grid'>
              <Tag
                className={classes.tag}
                color={activity?.suggested_application_type ? theme.colors.neutral.lightBase : undefined}
                background={activity?.suggested_application_type ? theme.colors.contentType[activity?.suggested_application_type] : undefined}
              >
                {t(activity?.moment_type)}
              </Tag>
            </Grid>)
        }
        {
          activityType && (
            <Grid item display='grid'>
              <Tag
                className={classes.tag}
                color={activity?.suggested_application_type ? theme.colors.neutral.lightBase : undefined}
                background={activity?.suggested_application_type ? theme.colors.contentType[activity?.suggested_application_type] : undefined}
              >
                {/* {setIcon()} */}
                {t(activityType)}
              </Tag>
            </Grid>)
        }
        {
          activity?.schedule &&
          <>
            {
              dayjs().isBefore(activity?.schedule.startDate) &&
              <Grid item display='grid'>
                <div className={classes.tagSchedule} style={{ background: theme.colors.warning.feedbackLight, color: theme.colors.warning.feedbackDarkest }}>
                  <CalendarClock /> {t('Início')}: {dayjs(activity?.schedule.startDate).format('DD/MM HH:mm')}
                </div>
              </Grid>
            }
            <Grid item display='grid'>
              {
                activity?.activity_status?.status === ActivityControlStatusEnum.CLOSED
                  ? <div className={classes.tagSchedule} style={{ background: theme.colors.negative.feedbackMedium, color: '#FFF' }}>
                    {t('Envios encerrados')}
                  </div>
                  : <div className={classes.tagSchedule} style={{ background: theme.colors.information.feedbackLight, color: theme.colors.information.feedbackDarkest }}>
                    <InsertInvitation /> {t('Entrega')}: {dayjs(activity?.schedule.endDate).format('DD/MM/YYYY HH:mm')}
                  </div>
              }
            </Grid>
          </>
        }
        <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
          {
            (activity?.custom_type !== 'clone' && activity?.clonedTimes !== undefined) && (
              <Grid item display='grid'>
                <Tag
                  color={theme.colorBrand.light}
                  className={`${classes.tag} ${classes.usedTimes}`}
                  background={'transparent'}
                >
                  {`Utilizada ${activity.clonedTimes} vez(es)`}
                </Tag>
              </Grid>
            )
          }
        </OnlyProfile>
        {
          activity?.activity_status && (
            <Grid item display='grid'>
              <StatusTag
                activityStatusControl={activity.activity_status.status}
                suggestedApplicationType={activity.suggested_application_type}
              />
            </Grid>)
        }
      </Grid>
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
        {
          activity?.activity_status?.status === ActivityControlStatusEnum.AVAILABLE && (
            <Grid container mb={3}>
              <Alert severity='warning' icon={<React.Fragment />}>
                {t('O professor ainda não liberou esta atividade')}
              </Alert>
            </Grid>
          )
        }
      </OnlyProfile>
      {
        activity?.used_in && (
          <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
            <Grid container flexDirection='column' sx={{ pb: theme.spacingInset.xs }}>
              <span style={{ color: theme.colorBrand.medium, fontSize: theme.font.fontSize.xs, fontWeight: theme.font.fontWeight.medium }}>
                {t('Utilizada em')}:
              </span>
              <Grid item>
                {activity?.used_in?.map(cur => (
                  <NavLink to={getContentLink(cur.id.toString())} className={classes.usedInContent}>
                    {cur.title}
                  </NavLink>
                ))}
              </Grid>
            </Grid>
          </OnlyProfile>
        )
      }
      {doesHaveDescription && <ActivityDescription activity={activity} />}
    </>
  )
}
