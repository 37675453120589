import { parseKaTex } from 'utils/katex'
import useStyles from './style'
import { ReactComponent as FormatQuote } from 'assets/format_quote.svg'

interface IContentProps {
  content: string | null
}

export const Quote: React.FC<IContentProps> = ({ content }) => {
  const classes = useStyles()

  content = parseKaTex(content)

  return (
    <div className={classes.quoteContainer}>
      <FormatQuote />
      <div
        className={classes.quote}
        dangerouslySetInnerHTML={{
          __html: content ?? ''
        }}
      />
      <div className={classes.fakeBorder} />
    </div>
  )
}
