import useStyles from './style'

interface ResourceCaptionProps {
  source?: string | null
  description?: string | null
}

export const ResourceCaption: React.FC<ResourceCaptionProps> = ({ source, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.captionContainer}>
      {
        (source ?? description)
          ? <div className={classes.caption}>
            <b>
              <span
                dangerouslySetInnerHTML={{
                  __html: description ?? ''
                }}
              />
            </b>
            <span className={classes.source}>{source ?? ''}</span>
          </div>
          : ''
      }
    </div>
  )
}
