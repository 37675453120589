import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    color: theme.colors.contentType.default,
    padding: `0 ${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`,

    '& .currentPeriodsContainer': {
      color: theme.colorBrand.medium
    },
    '& .previousPeriods': {
      color: theme.colors.contentType.default
    }
  },
  dropdownContainer: {
    width: '100%',

    '& .selectedText': {
      fontSize: `${theme.font.fontSize.sm} !important`
    },

    [theme.breakpoints.up('sm')]: {
      width: '225px'
    }
  },

  groupContainer: {
    flex: 1,
    margin: `${theme.spacingStack.xxxs} 0 0`,

    '& .MuiAccordion-root': {
      boxShadow: 'none',
      margin: '0 !important',

      '&:before': {
        display: 'none'
      }
    },
    '& .groupHeader': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacingInline.nano
    },
    '& .subgroupNavHeader': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: `${theme.spacingStack.nano} 0 !important`,
      minHeight: '0 !important',
      '& .MuiAccordionSummary-content ': {
        margin: 0
      }
    },
    '& .subgroupItemsList': {
      display: 'flex',
      flexDirection: 'column !important' as 'column',
      gap: theme.spacingInline.nano,
      '&.MuiAccordionDetails-root': {
        padding: '0',
        display: 'flex',
        flexWrap: 'wrap'
      }
    }
  },

  groupTitle: {
    fontWeight: `${theme.font.fontWeight.semibold} !important`,
    fontSize: `${theme.font.fontSize.md} !important`,

    [theme.breakpoints.up('sm')]: {
      fontSize: `${theme.font.fontSize.sm} !important`
    }
  },

  groupLabel: {
    background: theme.colorBrand.medium,
    padding: `${theme.spacingInline.quarck} ${theme.spacingInline.nano}`,
    borderRadius: theme.borderRadius.xs,

    color: `${theme.colors.neutral.lightBase} !important`,
    fontWeight: `${theme.font.fontWeight.medium} !important`,

    '&.previousPeriods': {
      background: theme.colors.contentType.default
    }
  },

  optionContainer: {
    cursor: 'pointer',
    fontWeight: `${theme.font.fontWeight.bold} !important`,
    fontSize: theme.font.fontSize.sm,

    margin: `${theme.spacingStack.nano} 0`,

    boxSizing: 'border-box',
    borderRadius: theme.borderRadius.sm,
    border: `3px solid ${theme.colors.neutral.light10}`,
    padding: `${theme.spacingInline.nano} ${theme.spacingInline.nano} ${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`,
    backgroundColor: theme.colors.neutral.lightBase,

    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    wordWrap: 'break-word',

    '&.active': {
      border: `3px solid ${theme.colorBrand.medium}`
    },
    '&.focused': {
      border: `3px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    },
    '&:hover': {
      transition: 'all ease-in-out 55ms',
      border: `3px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    },

    '&.previousPeriods': {
      '&.active': {
        border: `3px solid ${theme.colors.contentType.default}`
      },
      '&.focused': {
        border: `3px solid ${theme.colors.contentType.default}`,
        backgroundColor: 'rgba(105, 118, 145, 0.2);'
      },
      '&:hover': {
        border: `3px solid ${theme.colors.contentType.default}`,
        backgroundColor: 'rgba(105, 118, 145, 0.2);'
      }
    }
  }
}))
