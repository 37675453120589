import { Grid } from '@mui/material'
import { IContentUnitResponse } from 'services/types'
import useStyles from '../../style'
import { useTranslation } from 'react-i18next'

export interface IKeyWordsProps {
  keyWords: string[]
  contentUnit: IContentUnitResponse
}

export const KeyWords: React.FC<IKeyWordsProps> = ({
  keyWords,
  contentUnit
}) => {
  const classes = useStyles(contentUnit)
  const { t } = useTranslation()

  return (
    <Grid item xs={12} lg={3} className={classes.gridTheme}>
      <div className={classes.subTitle}>{t('Palavras-chave')}</div>
      <div className={classes.listContainer}>
        <ul className={classes.listTheme}>
          {keyWords?.map(keyWord => (
            <li>{keyWord}</li>
          ))}
        </ul>
      </div>
    </Grid>
  )
}

export default KeyWords
