import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import emptyStateScreenStudents from 'assets/empty-state-screen-students.svg'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    paddingTop: theme.spacingInset.sm
  },
  milestoneBackground: {
    backgroundColor: 'rgba(231, 210, 230, 0.3)',
    borderRadius: theme.borderRadius.md,
    '& .MuiTimelineConnector-root': {
      backgroundColor: theme.colors.timelineRecordsItems.marco
    },
    '& .MuiTimelineOppositeContent-root': {
      // larger screens
      [theme.breakpoints.up('sm')]: {
        color: theme.colors.timelineRecordsItems.marco
      }
    }
  },
  milestoneCard: {
    backgroundColor: theme.colors.neutral.lightBase,
    border: `${theme.spacingInset.quarck} solid ${theme.colors.neutral.light30}`,
    borderRadius: theme.borderRadius.md,
    overflow: 'hidden',
    alignItems: 'space-between',
    // mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  milestoneCover: {
    width: '100%',
    maxHeight: '300px',
    objectFit: 'cover',
    borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0 0`,
    [theme.breakpoints.up('sm')]: {
      borderRadius: 0
    }
  },
  milestoneEndGroup: {
    lineHeight: theme.font.lineHeight.sm,
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.xs,
    fontWeight: theme.font.fontWeight.bold
  },
  milestoneEndGroupHeader: {
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  milestoneUpper: {
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.light,
    [theme.breakpoints.up('sm')]: {
      lineHeight: theme.font.lineHeight.sm,
      fontSize: theme.font.fontSize.xs
    }
  },
  milestoneTitle: {
    lineHeight: theme.font.lineHeight.sm,
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.md,
    fontWeight: theme.font.fontWeight.bold,
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs} ${theme.spacingInset.xs} ${theme.spacingInset.xs}`
  },
  milestoneDescription: {
    lineHeight: theme.font.lineHeight.sm,
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.sm,
    padding: theme.spacingInset.xs
  },
  milestoneCardHeader: {
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  milestoneDropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // larger screens
    [theme.breakpoints.up('md')]: {
      order: 2
    }
  },
  milestoneDropdown: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.neutral.lightBase,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.xs,
    paddingTop: theme.spacingInset.xxs,
    paddingBottom: theme.spacingInset.xs,
    justifyContent: 'flex-end',
    '& .MuiSvgIcon-root': {
      color: theme.colors.neutral.lightBase,
      lineHeight: theme.font.lineHeight.md,
      fontSize: theme.font.fontSize.lg,
      marginLeft: theme.spacingInset.xxs,
      display: 'inline-flex',
      verticalAlign: 'middle',
      [theme.breakpoints.up('md')]: {
        color: theme.colors.timelineRecordsItems.marco
      }
    },
    // larger screens
    [theme.breakpoints.up('md')]: {
      color: theme.colors.timelineRecordsItems.marco,
      borderRadius: theme.borderRadius.pill,
      backgroundColor: theme.colors.neutral.lightBase,
      padding: `${theme.spacingInset.xxs} ${theme.spacingInset.sm}`,
      order: 2
    }
  },
  milestoneDropdownClose: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.neutral.lightBase,
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.xs,
    paddingTop: theme.spacingInset.xxs,
    paddingBottom: theme.spacingInset.xs,
    justifyContent: 'flex-end',
    '& .MuiSvgIcon-root': {
      color: theme.colors.neutral.lightBase,
      lineHeight: theme.font.lineHeight.md,
      fontSize: theme.font.fontSize.lg,
      marginLeft: theme.spacingInset.xxs,
      display: 'inline-flex',
      verticalAlign: 'middle',
      [theme.breakpoints.up('md')]: {
        color: theme.colors.neutral.lightBase
      }
    },
    // larger screens
    [theme.breakpoints.up('md')]: {
      color: theme.colors.neutral.lightBase,
      borderRadius: theme.borderRadius.pill,
      padding: `${theme.spacingInset.xxs} ${theme.spacingInset.sm}`,
      order: 2
    }
  },
  cardMonth: {
    width: theme.spacingStack.xxxl,
    height: theme.spacingStack.md,
    background: theme.colorBrand.lightest,
    borderRadius: theme.borderRadius.sm,
    padding: theme.spacingStack.nano,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.medium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '141px',
      height: theme.spacingStack.lg,
      fontSize: theme.font.fontSize.md
    }
  },
  oppositeContent: {
    '&.MuiTimelineOppositeContent-root': {
      fontFamily: theme.font.fontFamily,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xxs,
      marginTop: '12px',
      padding: `6px ${theme.spacingStack.xxxs} ${theme.spacingStack.xxxs} 0px`,
      flex: 'none',
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'inherit'
      }
    }
  },
  itemTimeline: {
    display: 'block'
  },
  activityTitleContainer: {
    '&.MuiGrid-root': {
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacingInset.xxs,
      [theme.breakpoints.up('sm')]: {
        gap: theme.spacingInset.xs,
        fontSize: theme.font.fontSize.sm
      }
    },
    '& span': {
      color: theme.colors.neutral.lightBase
    }
  },
  itensContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  cardItemTimeline: {
    minWidth: 150,
    cursor: 'pointer',
    padding: 0,
    height: 150,
    width: 150,
    margin: '12px 12px 0px 0px'
  },
  cardItemContentTimeline: {
    padding: 0,
    '&.MuiGrid-root': { maxWidth: '100%' },
    '&.MuiCardContent-root': {
      paddingBottom: 0,
      padding: 0,
      width: '150px',
      height: '150px'
    }
  },
  cardsTimeline: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacingInset.xs
  },
  text: {
    lineHeight: theme.font.lineHeight.xs,
    flexWrap: 'wrap',
    overflow: 'hidden',
    width: '100%',
    WebkitLineClamp: 6,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    margin: 0,
    color: theme.colors.neutral.lightBase,
    padding: theme.spacingInset.xs,
    '& > p': {
      marginBlock: '0px'
    }
  },
  contentDay: {
    paddingBottom: theme.spacingInset.xxs,
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  subTitle: {
    padding: '12px 0',
    color: theme.colors.neutral.dark30,
    fontSize: theme.font.fontSize.xxs,
    fontWeight: theme.font.fontWeight.medium
  },
  tagSubTitle: {
    marginBottom: theme.spacingStack.quarck,
    lineHeight: theme.font.lineHeight.xs
  },
  navLink: {
    textDecorationColor: theme.colorBrand.medium
  },
  containerEmptyRecord: {
    maxWidth: '20rem',
    textAlign: 'center',
    paddingBottom: theme.spacingInset.lg
  },
  image: {
    backgroundImage: `url(${emptyStateScreenStudents})`,
    marginBlock: theme.spacingInline.md,
    backgroundSize: '100%',
    maxWidth: '20rem',
    height: '20rem',
    width: '100%',
    maxHeight: '30rem',
    margin: 'auto',
    minHeight: 'auto',
    backgroundRepeat: 'no-repeat'
  },
  buttonsGroup: {
    marginTop: theme.spacingStack.xxxs
  },
  editButton: {
    '&.primary': {
      width: '150px',
      padding: `${theme.spacingStack.nano} ${theme.spacingStack.xxxs}`,
      fontSize: theme.font.fontSize.xxs,
      marginLeft: theme.font.fontSize.xxs
    }
  },
  deleteButton: {
    '&.ghost': {
      width: '150px',
      padding: theme.spacingStack.nano,
      fontSize: theme.font.fontSize.xxs
    },
    '& .MuiButton-startIcon': {
      color: theme.colorBrand.medium
    }
  },
  resourcesImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  alternativeImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  containerAlternativeImage: {
    width: '150px',
    height: '150px'
  }
}))
