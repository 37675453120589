import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  backLink: {
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.xs,
    color: theme.colorBrand.medium,
    overflow: 'hidden',
    flexWrap: 'wrap',
    width: '100%',
    fontSize: theme.font.fontSize.sm,

    margin: 0,

    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',

    // larger screens
    [theme.breakpoints.up('sm')]: {
      padding: 0,
      fontSize: theme.font.fontSize.xs
    },

    '& .MuiSvgIcon-root': {
      marginRight: theme.spacingInline.nano,
      color: theme.colorBrand.medium,
      width: theme.font.fontSize.xxs,
      height: theme.font.fontSize.xxs
    }
  }
}))
