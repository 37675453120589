import { Grid } from '@mui/material'

import { ReactComponent as CloeAvatar } from 'assets/cloe-avatar-sentada.svg'

import useStyle from './style'

type EmptyStateComponentProps = {
  children?: React.ReactNode
  title?: string
  errorMessage?: string
  ErrorAvatar?: JSX.Element
  maxMessageWidth?: string
}

export const EmptyStateComponent = ({ children, title, errorMessage, ErrorAvatar, maxMessageWidth }: EmptyStateComponentProps) => {
  const classes = useStyle({ maxMessageWidth })

  return (
    <Grid container>
      <Grid item xs={12} mt={4} mb={4} display='flex' justifyContent='center'>
        {ErrorAvatar ?? (<CloeAvatar />)}
      </Grid>
      {title && (
        <Grid item xs={12} className={classes.title}>
          <span>{title}</span>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className={classes.errorMessage}>
          {errorMessage}
        </div>
      </Grid>
      {children}
    </Grid>
  )
}

export default EmptyStateComponent
