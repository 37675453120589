import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { Grid } from '@mui/material'
import {
  TextField,
  Button,
  Alert
} from 'components/design-system'
import BaseAuthLayout from 'pages/BaseAuthLayout'
import { NavLink } from 'react-router-dom'
import { LOGIN } from 'navigation/CONSTANTS'
import { getUsernameByIdentifier } from 'services/users'
import { useAuth } from 'navigation/ProvideAuth'
import { toast } from 'components/design-system/Toast/manager'
import { LockOutlined, NavigateBefore } from '@mui/icons-material'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { UsernameRadioGroup } from './components/UsernameRadioGroup'

export const ForgotPassword = () => {
  const [usernameChoosen, setUsernameChoosen] = useState('')
  const [usernames, setUsernames] = useState<string[]>([])
  const [indentifier, setIdentifier] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState(false)

  const { t } = useTranslation()
  const styles = useStyles()
  const auth = useAuth()

  useEffect(() => {
    setIsSendBtnDisabled(indentifier === '')
  }, [indentifier])

  const checksForExistingUsernamesBeforeRecoverPassword = async () => {
    try {
      const request = await getUsernameByIdentifier(indentifier)
      if (!request.success) {
        setErrorMessage('Dado não encontrado. Tente novamente.')
        setIsSendBtnDisabled(true)
        return []
      }
      setUsernames(request.data.map(u => u.username))
      return request.data
    } catch (error) {
      toast.handler({
        content: 'Houve um problema ao checar usuário. Tente novamente.',
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const sendForgotPassword = async (usernameChoosen: string) => {
    if (usernameChoosen === '') return
    const _ignoredOptionalParams = undefined
    const response = await auth?.forgotPassword(_ignoredOptionalParams, _ignoredOptionalParams, usernameChoosen)

    if (!response?.success) {
      setErrorMessage('Não foi possível enviar recuperação de senha. Tente novamente.')
      return
    }

    if (response?.data?.sentToEmail) {
      const userRecipientEmail = response?.data?.sentToEmail
      setSuccessMessage(`Instruções enviadas para ${userRecipientEmail}`)
    }

    if (response?.data?.sentToSms) {
      const userRecipientSms = response?.data?.sentToSms
      setSuccessMessage(`Instruções enviadas para ${userRecipientSms}`)
    }
    setIsSendBtnDisabled(true)
    setUsernameChoosen('')
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      if (usernameChoosen !== '') {
        await sendForgotPassword(usernameChoosen)
      } else if (indentifier !== '') {
        const response = await checksForExistingUsernamesBeforeRecoverPassword()
        if (response && response.length === 1) {
          setUsernameChoosen(response[0].username)
          await sendForgotPassword(response[0].username)
        } else {
          setIsSendBtnDisabled(true)
        }
      }
    } catch (error) {
      toast.handler({
        content: 'Houve um problema ao enviar credenciais. Tente novamente.',
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIdentifier(e.target.value.trim())
    setErrorMessage(null)
    setSuccessMessage(null)
    setUsernameChoosen('')
    setUsernames([])
  }

  return (
    <BaseAuthLayout>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NavLink to={LOGIN} className={styles.returnLink} data-testid='login_help_options_close'>
              <NavigateBefore fontSize='small' />
              <span>{t('Voltar')}</span>
            </NavLink>
          </Grid>
          <Grid item xs={12} pt={3}>
            <div className={styles.title}>
              <LockOutlined sx={{ marginRight: '8px' }} /> {t('Esqueci a senha')}
            </div>
            <div className={styles.description}>
              {t('Digite o seu nome de usuário, e-mail ou celular (com DDD) cadastrado na sua conta Cloe.')}
            </div>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <TextField
                id='identifier'
                variant='outlined'
                label={t('Nome de usuário, e-mail ou celular')}
                className={styles.input}
                value={indentifier}
                success={successMessage !== null && !errorMessage}
                error={errorMessage !== null && !successMessage}
                onChange={handleInputChange}
              />
            </Grid>
            {
              usernames.length > 1 && (
                <>
                  <Grid item xs={12}>
                    <div className={styles.description}>
                      {t('Dois ou mais usuários foram encontrados. Selecione o usuário desejado e clique em Continuar.')}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <UsernameRadioGroup
                      usernames={usernames}
                      onSelect={e => {
                        setUsernameChoosen(e.target.value)
                        setIsSendBtnDisabled(false)
                      }}
                    />
                  </Grid>
                </>
              )
            }
            {errorMessage &&
              <Grid item xs={12}>
                <Alert
                  className={styles.alertError}
                  content={t(`${errorMessage}`)}
                  severity='error'
                />
              </Grid>}
            {successMessage &&
              <Grid item xs={12}>
                <Alert
                  className={styles.alertSuccess}
                  content={t(`${successMessage}`)}
                  severity='success'
                />
              </Grid>}
            <Grid item xs={12}>
              <Button
                data-testid='login_help_options_submit'
                className={`${styles.submitButton} ${!isSendBtnDisabled ? 'active' : ''}`}
                variant='primary'
                disabled={isSendBtnDisabled}
                type='submit'
              >
                {t('Continuar')}
                <ArrowForward sx={{ marginLeft: '14px' }} />
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.title} style={{ fontWeight: 700 }}>
              {t('Esqueceu seu nome de usuário ou não tem um contato cadastrado?')}
            </div>
            <div className={styles.description}>
              {t('Sua escola pode ajudar informando seu nome de usuário, restaurando sua senha ou gerando uma chave de acesso para você.')}
            </div>
          </Grid>
        </Grid>
      </form>
    </BaseAuthLayout>
  )
}
