import React from 'react'
import { TextField, Grid, InputAdornment, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'

import { IStudentResponse } from 'services/types'
import { Tag } from 'components/design-system'

import { SubscriptionSelected } from '../RecipientsModal/types'
import useStyles from './style'

interface ISearcAndSelectProps {
  students: IStudentResponse[]
  selectedStudents: SubscriptionSelected[]
  isStudentSelected: (selectedStudents: SubscriptionSelected[], value: number) => boolean
  setSelectedStudents: (value: SubscriptionSelected[]) => void
  removeStudent: (setSelectedStudents: React.Dispatch<SubscriptionSelected[]>, selectedStudents: SubscriptionSelected[], id: number) => void
  search: string
  setSearch: React.Dispatch<string>
}

const SearchAndSelect = ({ students, selectedStudents, isStudentSelected, setSelectedStudents, removeStudent, search, setSearch }: ISearcAndSelectProps) => {
  const classes = useStyles({ studentsLength: students.length })
  const { t } = useTranslation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      container gap={0} display='flex' flexDirection='column'
      justifyContent='flex-start' alignItems='center' className={classes.container}
    >
      <TextField
        variant='outlined'
        className={classes.searchInput}
        label={t('Pesquisar por estudantes')}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        InputLabelProps={{
          shrink: search !== '',
          className: search !== '' ? undefined : classes.inputLabelNoShrink
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          ),
          endAdornment: search ? <span className={classes.txtClear} data-testid='recipients_clear_search' onClick={() => setSearch('')}>{`Limpar ${isMobile ? '' : 'pesquisa'}`}</span> : ''
        }}
      />
      { (search?.length > 1 && students.length > 0) &&
        <Grid item className={classes.studentsLisContainer} container gap={1} display='flex' flexWrap='wrap' flexDirection='row' justifyContent='flex-start' alignItems='flex-start'>
          {students.map(student => (
            isStudentSelected(selectedStudents, student.id)
              ? (
                <Tag
                  color={theme.colors.neutral.lightBase}
                  background={theme.colorBrand.medium}
                  className={classes.tagSelected}
                  onClick={() => removeStudent(setSelectedStudents, selectedStudents, student.id)}
                  key={student.id}
                >
                  {`${student.user_id?.name} ${student.user_id.surname}`}
                </Tag>
              )
              : (
                <Tag
                  background={theme.colors.neutral.lightBase}
                  className={classes.tagDefault}
                  onClick={() => {
                    setSelectedStudents([...(selectedStudents ?? []), { name: `${student.user_id?.name} ${student.user_id.surname}`, id: student.id }])
                  }}
                  key={student.id}
                >
                  {`${student.user_id?.name} ${student.user_id.surname}`}
                </Tag>
              )
          )
          )}
        </Grid>
      }
    </Grid>
  )
}

export default SearchAndSelect
