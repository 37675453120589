import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    infoBarContainer: {
      backgroundColor: theme.colors.information.feedbackLightest,
      boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
      borderRadius: theme.borderRadius.sm,
      padding: theme.spacingInline.xxxs,

      '&.bottom-fixed': {
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        zIndex: 5,
        paddingLeft: 110,
        paddingRight: 64,

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacingInline.xxxs
        }
      }
    },
    infoBarText: {
      color: theme.colors.information.feedbackDark,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold
    },
    infoBarButtonsHandles: {
      backgroundColor: `${theme.colors.information.feedbackDark} !important`,

      '&.Mui-disabled': {
        backgroundColor: 'transparent !important'
      }
    }
  })
)
