import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { ITextField } from './index'

interface FilledProps {
  '&.MuiFilledInput-root': {
    boxSizing: string
    borderRadius: string
    backgroundColor: string
    border: string
    '&.Mui-focused': {
      border: string
    }
    '&.Mui-disabled': {
      border: string
      backgroundColor: string
    }
  } // shape
  '&.MuiFilledInput-root:hover': {
    transition: string
    border: string
    backgroundColor: string
    '&.Mui-disabled': {
      border: string
      backgroundColor: string
    }
  } // shape
  '&.Mui-focused fieldset': {
    border: 'solid 1px red'
  } // shape
  '& .MuiInputBase-input': {
    fontFamily: string
    fontSize: string
    letterSpaccing: string
    color: string
    caretColor: string
    padding: string
  }
  '& .MuiOutlined-input': {
    fontFamily: string
    fontSize: string
    letterSpaccing: string
    color: string
    caretColor: string
    padding: string
  } // inputText
  '& .MuiFormLabel-root': {
    fontFamily: string
    color: string
    '&.MuiInputLabel-root': {
      fontFamily: string
      color: string
    } // Label color onDefault
  } // Label color onDefault
}
interface OutlinedProps {
  '&.MuiOutlinedInput-root': {
    boxSizing: string
    borderRadius: string
    backgroundColor: string
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: string
      }
    }
  } // shape
  '& .MuiOutlinedInput-notchedOutline': {
    border: string
  }
  '&.MuiOutlinedInput-root:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      transition: string
      border: string
    }
  } // shape
  '& .MuiInputBase-input': {
    fontFamily: string
    fontSize: string
    letterSpaccing: string
    color: string
    caretColor: string
    padding: string
  } // inputText
  '& .MuiFormLabel-root': {
    fontFamily: string
    color: string
    '&.MuiInputLabel-root': {
      fontFamily: string
      color: string
    } // Label color onDefault
  } // Label color onDefault
}

const filledDefault: (theme: Theme) => FilledProps = theme => ({
  '&.MuiFilledInput-root': {
    boxSizing: 'border-box',
    borderRadius: theme.borderRadius.sm,
    border: '2px solid transparent',
    backgroundColor: 'rgba(230, 230, 230, 0.32);',
    '&.Mui-focused': {
      border: `2px solid ${theme.colorBrand.medium}`
    },
    '&.Mui-disabled': {
      border: 'none',
      backgroundColor: 'rgba(230, 230, 230, 0.32);'
    }
  }, // shape
  '&.MuiFilledInput-root:hover': {
    transition: 'all ease-in-out 55ms',
    border: `2px solid ${theme.colorBrand.medium}`,
    backgroundColor: 'rgba(230, 230, 230, 0.32);',
    '&.Mui-disabled': {
      border: 'none',
      backgroundColor: 'rgba(230, 230, 230, 0.32);'
    }
  }, // shape
  '&.Mui-focused fieldset': {
    border: 'solid 1px red'
  }, // shape
  '& .MuiInputBase-input': {
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.sm,
    letterSpaccing: theme.font.spacing.distant,
    color: theme.colors.neutral.darkBase,
    caretColor: theme.colorBrand.medium,
    padding: `${theme.spacingInline.xxs} ${theme.spacingInline.xxxs} ${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`
  }, // inputText
  '& .MuiOutlined-input': {
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.sm,
    letterSpaccing: theme.font.spacing.distant,
    color: theme.colors.neutral.darkBase,
    caretColor: theme.colorBrand.medium,
    padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.xxxs} ${theme.spacingInline.xxxs} ${theme.spacingInline.xxxs}`
  }, // inputText
  '& .MuiFormLabel-root': {
    fontFamily: theme.font.fontFamily,
    color: theme.colors.neutral.darkBase,
    '&.MuiInputLabel-root': {
      fontFamily: theme.font.fontFamily,
      color: theme.colors.neutral.darkBase
    } // Label color onDefault
  } // Label color onDefault
})

const outlinedDefault: (theme: Theme) => OutlinedProps = theme => ({
  '&.MuiOutlinedInput-root': {
    boxSizing: 'border-box',
    borderRadius: theme.borderRadius.sm,
    backgroundColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid  ${theme.colors.neutral.light10}`
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid  ${theme.colorBrand.medium}`
      },
      '& .MuiSvgIcon-root': {
        fill: theme.colorBrand.medium
      }
    },
    '& .MuiSvgIcon-root': {
      fill: theme.colors.neutral.dark40
    }
  }, // shape
  '& .MuiOutlinedInput-notchedOutline': {
    border: `2px solid  ${theme.colors.neutral.light10}`,
    '& .MuiSvgIcon-root': {
      fill: theme.colors.neutral.light10
    }
  },
  '&.MuiOutlinedInput-root:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'all ease-in 0.15s',
      border: `2px solid  ${theme.colorBrand.medium}`
    },
    '& .MuiSvgIcon-root': {
      fill: theme.colorBrand.medium
    }
  }, // shape
  '& .MuiInputBase-input': {
    fontFamily: theme.font.fontFamily,
    fontSize: theme.font.fontSize.sm,
    letterSpaccing: theme.font.spacing.distant,
    color: theme.colors.neutral.darkBase,
    caretColor: theme.colorBrand.medium,
    padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.xxxs} ${theme.spacingInline.xxxs} ${theme.spacingInline.xxxs}`
  }, // inputText
  '& .MuiFormLabel-root': {
    fontFamily: theme.font.fontFamily,
    color: theme.colors.neutral.darkBase,
    '&.MuiInputLabel-root': {
      fontFamily: theme.font.fontFamily,
      color: theme.colors.neutral.darkBase
    } // Label color onDefault
  } // Label color onDefault
})

const assistiveTextDefault = (theme: Theme) => ({
  '&.MuiFormHelperText-root': {
    fontFamily: theme.font.fontFamily
  },
  '&:disabled': {
    '&.MuiFormHelperText-root': {
      color: theme.colors.neutral.light40
    }
  } // assistive text color
})

export const helperTextStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  root: {
    '&.default': {
      '&.MuiFormHelperText-root': {
        color: theme.colors.neutral.dark40,
        ...assistiveTextDefault(theme)
      }
    },
    '&.warning': {
      '&.MuiFormHelperText-root': {
        ...assistiveTextDefault(theme),
        color: theme.colors.warning.feedbackDarkest
      }
    },
    '&.success': {
      '&.MuiFormHelperText-root': {
        ...assistiveTextDefault(theme),
        color: theme.colors.positive.feedbackMedium
      }
    },
    '&.error': {
      '&.MuiFormHelperText-root': {
        ...assistiveTextDefault(theme),
        color: theme.colors.negative.feedbackMedium
      }
    },
    '&.mandatory': {
      '&.MuiFormHelperText-root': {
        ...assistiveTextDefault(theme),
        color: theme.colorBrand.medium,
        fontStyle: 'italic',
        marginLeft: 0
      }
    },
    '&.maxCounter': {
      '&.MuiFormHelperText-root': {
        fontSize: theme.font.fontSize.xxs,
        fontWeight: theme.font.fontWeight.medium,
        lineHeight: theme.font.lineHeight.xxs,
        color: theme.colors.neutral.dark30,
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 0,
        '&.limit': {
          color: theme.colors.negative.feedbackDark
        }
      }
    }
  }
}))

export const formStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&.default': {
      '&.MuiFormControl-root:focus': {
        border: `3px solid  ${theme.colorBrand.medium}`
      }
    }, // focused border
    '&.warning': {
      '&.MuiFormControl-root:focus': {
        border: `3px solid  ${theme.colorBrand.medium}`
      }
    }, // focused border
    '&.success': {
      '&.MuiFormControl-root:focus': {
        border: `3px solid  ${theme.colorBrand.medium}`
      }
    }, // focused border
    '&.error': {
      '&.MuiFormControl-root:focus': {
        border: `3px solid  ${theme.colorBrand.medium}`
      }
    }, // focused border
    '&.madatory': {
      '&.MuiFormControl-root:focus': {
        border: `3px solid  ${theme.colorBrand.medium}`
      }
    }, // focused border
    '&:disabled': {
      '&.MuiFormControl-root:focus': {
        border: `3px solid  ${theme.colorBrand.medium}`
      }
    } // focused border
  }
}))

export const InputLabelStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&.default': {
      ...filledDefault(theme)['& .MuiFormLabel-root'],
      '&.MuiInputLabel-shrink': {
        transform: (props: ITextField) =>
          props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
        '&.Mui-focused': {
          transform: (props: ITextField) =>
            props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
          color: theme.colorBrand.medium // label color on focus
        }
      }
    }, // focused border
    '&.warning': {
      ...filledDefault(theme)['& .MuiFormLabel-root'],
      '&.MuiInputLabel-shrink': {
        transform: (props: ITextField) =>
          props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
        '&.Mui-focused': {
          transform: (props: ITextField) =>
            props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
          color: theme.colors.warning.feedbackMedium // label color on focus
        }
      }
    }, // focused border
    '&.success': {
      ...filledDefault(theme)['& .MuiFormLabel-root'],
      '&.MuiInputLabel-shrink': {
        transform: (props: ITextField) =>
          props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
        '&.Mui-focused': {
          transform: (props: ITextField) =>
            props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
          color: theme.colors.positive.feedbackMedium // label color on focus
        }
      }
    }, // focused border
    '&.error': {
      ...filledDefault(theme)['& .MuiFormLabel-root'],
      '&.MuiInputLabel-shrink': {
        color: theme.colors.negative.feedbackMedium,
        transform: (props: ITextField) =>
          props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
        '&.Mui-focused': {
          transform: (props: ITextField) =>
            props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
          color: theme.colors.negative.feedbackMedium // label color on focus
        }
      }
    }, // focused border
    '&.mandatory': {
      ...filledDefault(theme)['& .MuiFormLabel-root'],
      '&.MuiInputLabel-shrink': {
        transform: (props: ITextField) =>
          props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
        '&.Mui-focused': {
          transform: (props: ITextField) =>
            props.variant === 'filled' ? 'translate(18px, 12px) scale(0.75)' : '',
          color: theme.colorBrand.medium // label color on focus
        }
      }
    }, // focused border
    '&:disabled': {
      '&.MuiInputLabel-shrink': {
        transform: 'translate(18px, 12px) scale(0.75)',
        '&.Mui-focused': {
          transform: 'translate(18px, 12px) scale(0.75)',
          color: theme.colorBrand.medium // label color on focus
        }
      }
    } // focused border
  }
}))

export const FilledInputStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&.default': {
      ...filledDefault(theme)
    },
    '&.error': {
      '& .MuiInputBase-input': {
        ...filledDefault(theme)['& .MuiInputBase-input'],
        caretColor: theme.colors.negative.feedbackMedium
      },
      '&.MuiFilledInput-root': {
        borderRadius: theme.borderRadius.sm,
        border: `2px solid  ${theme.colors.negative.feedbackMedium}`,
        '&.Mui-focused': {
          border: `2px solid  ${theme.colors.negative.feedbackMedium}`
        }
      }
    },
    '&.warning': {
      '& .MuiInputBase-input': {
        ...filledDefault(theme)['& .MuiInputBase-input'],
        caretColor: theme.colors.warning.feedbackMedium
      },
      '&.MuiFilledInput-root': {
        borderRadius: theme.borderRadius.sm,
        border: `2px solid  ${theme.colors.warning.feedbackMedium}`,
        '&.Mui-focused': {
          border: `2px solid  ${theme.colors.warning.feedbackMedium}`
        }
      }
    },
    '&.success': {
      ...filledDefault(theme),
      '& .MuiInputBase-input': {
        ...filledDefault(theme)['& .MuiInputBase-input'],
        caretColor: theme.colors.positive.feedbackMedium
      },
      '&.MuiFilledInput-root': {
        borderRadius: theme.borderRadius.sm,
        '&.Mui-focused': {
          border: `2px solid  ${theme.colors.positive.feedbackMedium}`
        }
      }
    },
    '&.mandatory': {
      ...filledDefault(theme)
    }
  }
}))

export const OutlinedInputStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&.default': {
      ...outlinedDefault(theme)
    },
    '&.error': {
      '& .MuiInputBase-input': {
        ...outlinedDefault(theme)['& .MuiInputBase-input'],
        caretColor: `2px solid  ${theme.colors.negative.feedbackMedium}`
      },
      '&.MuiOutlinedInput-root': {
        ...outlinedDefault(theme)['&.MuiOutlinedInput-root'],
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid  ${theme.colors.negative.feedbackMedium}`
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colors.negative.feedbackMedium}`
        }
      }
    },
    '&.warning': {
      '& .MuiInputBase-input': {
        ...outlinedDefault(theme)['& .MuiInputBase-input'],
        caretColor: `2px solid  ${theme.colors.warning.feedbackMedium}`
      },
      '&.MuiOutlinedInput-root': {
        ...outlinedDefault(theme)['&.MuiOutlinedInput-root'],
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid  ${theme.colors.warning.feedbackMedium}`
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colors.warning.feedbackMedium}`
        }
      }
    },
    '&.success': {
      ...outlinedDefault(theme),
      '& .MuiInputBase-input': {
        ...outlinedDefault(theme)['& .MuiInputBase-input'],
        caretColor: theme.colors.positive.feedbackMedium
      },
      '&.MuiOutlinedInput-root': {
        ...outlinedDefault(theme)['&.MuiOutlinedInput-root'],
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: `2px solid  ${theme.colors.positive.feedbackMedium}`
          }
        }
      }
    },
    '&.mandatory': {
      ...outlinedDefault(theme)
    }
  }
}))

export const inputAdornmentStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&.default': {
      '& .MuiButtonBase-root': {
        color: theme.font.fontWeight.medium
      }
    },
    '&.warning': {
      '& .MuiButtonBase-root': {
        color: theme.colors.warning.feedbackMedium
      }
    },
    '&.success': {
      '& .MuiButtonBase-root': {
        color: theme.colors.positive.feedbackMedium
      }
    },
    '&.error': {
      '& .MuiButtonBase-root': {
        color: theme.colors.negative.feedbackMedium
      }
    },
    '&.mandatory': {
      '& .MuiButtonBase-root': {
        color: theme.colors.negative.feedbackMedium
      }
    }
  }
}))
