import { createContext, useContext } from 'react'
import { useProvideAuth } from './useProvideAuth'
import {
  IApiResponse,
  IForgotPasswordResponse,
  IUserLoginResponse,
  IUserMeResponse
} from 'services/types'

interface IAuthProviderContext {
  user: IUserMeResponse | null
  registerSignin: (token: string) => Promise<IApiResponse<IUserMeResponse> | undefined>
  signin: (username: string, password: string) => Promise<IApiResponse<IUserLoginResponse>>
  temporaryAccess: (token: string) => Promise<IApiResponse<IUserLoginResponse>>
  currentUser: () => Promise<IApiResponse<IUserMeResponse> | undefined>
  signout: (resetRedirectLink?: boolean) => Promise<void>
  setSession: (windowLoading: boolean) => void
  clean: () => void
  forgotPassword: (email?: string, phone?: string, username?: string | null) => Promise<IApiResponse<IForgotPasswordResponse>>
  resetPassword: (code: string, password: string, passwordConfirmation: string) => Promise<IApiResponse<IUserLoginResponse>>
  changeDefaultPassword: (userId: number, newPassword: string) => Promise<IApiResponse<void>>
  updateLastUsedProfile: (profileId: number) => Promise<void>
  loginWithSignedJwt: (jwt: string) => void
}

const AuthContext = createContext<IAuthProviderContext | null>(null)

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth()
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): IAuthProviderContext | null {
  return useContext(AuthContext)
}
