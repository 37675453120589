import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardStudentAnswerCommentsTextField: {
      width: '100%',

      '&.MuiOutlinedInput-root': {
        backgroundColor: `${theme.colors.neutral.lightBase} !important`,

        '& fieldset': {
          '&.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important'
          }
        }
      }
    }
  })
)
