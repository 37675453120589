import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { EditOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { Button } from 'components/design-system'
import { QuickAccessHeader } from './components'
import { StatusClassHandle, CurrentClass } from 'components/common'

import { TAUGHT_DISCIPLINES } from 'navigation/CONSTANTS'

import useStyle from './styles'

interface IMyClassesHeader {
  showEditButton?: boolean
}

export const MyClassesHeader = ({ showEditButton = true }: IMyClassesHeader) => {
  const { t } = useTranslation()
  const classes = useStyle()

  return (
    <>
      <StatusClassHandle>
        <CurrentClass>
          <QuickAccessHeader />
        </CurrentClass>
      </StatusClassHandle>

      <Grid container m={0} justifyContent='space-between' className={classes.titleContent}>
        <Grid item xs={8} sm={6}>
          <div tabIndex={0} className={classes.title}>
            {t('Minhas turmas')}
          </div>
        </Grid>
        {
          showEditButton && (
            <Grid item xs={4} sm={6} textAlign='right'>
              <NavLink to={TAUGHT_DISCIPLINES} data-testid='my_classes_group_edition' style={{ textDecoration: 'none' }}>
                <Button tabIndex={1} className={classes.editButton} >
                  <EditOutlined />
                  <span >
                    {t('Editar turmas')}
                  </span>
                </Button>
              </NavLink>
            </Grid>
          )
        }
      </Grid>
    </>
  )
}
