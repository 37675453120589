/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCurricularStore, useStore } from 'store'
// lib external components
import Grid from '@mui/material/Grid'
// global components
// services
import { IScheduleActivitiesPendentResponse, IUserSchoolProfileTypeEnum } from 'services/types'
// styles
import useStyles from './styles'
import { useParams } from 'react-router-dom'
import { getPendentActivitiesStudent, getPendentActivitiesTeacher } from 'services/schedule'
import { EmptyState, Ready, ErrorState, LoadingState } from './components'

export interface IPendingActivitiesBoxProps {
  dataTestid?: string
}

enum BoxStateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR'
}

export const PendingActivitiesBox: React.FC<IPendingActivitiesBoxProps> = props => {
  const [boxStateMachine, setBoxStateMachine] = useState<BoxStateMachineEnum>(BoxStateMachineEnum.IDLE)

  const [dropDown, setDropDown] = useState<boolean>(true)
  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type
  const styleProps = { ...props, profileType }
  const classes = useStyles(styleProps)
  const [activitiesPendent, setActivitiesPendent] = useState<IScheduleActivitiesPendentResponse[] | null>(null)

  const { currentDiscipline } = useCurricularStore()

  const { classId } = useParams<{ classId: string }>()
  const classIdSubscription = subscription?.class.id ?? '0'

  useEffect(() => {
    void getActivitiesPendent()
  }, [currentDiscipline])

  const getActivitiesPendent = async () => {
    setBoxStateMachine(BoxStateMachineEnum.LOADING)

    const response = profileType === IUserSchoolProfileTypeEnum.teacher
      ? await getPendentActivitiesTeacher({ classId, limit: 7, disciplineId: currentDiscipline?.id })
      : await getPendentActivitiesStudent({ classId: classIdSubscription, limit: 7, disciplineId: currentDiscipline?.id })

    if (!response.success) {
      setBoxStateMachine(BoxStateMachineEnum.ERROR)
      return
    }

    if (!response.data.length) {
      setBoxStateMachine(BoxStateMachineEnum.NOT_FOUND)
      return
    }

    setActivitiesPendent(response.data)
    setBoxStateMachine(BoxStateMachineEnum.READY)
  }

  const actionTryAgain = async () => {
    await getActivitiesPendent()
  }

  const states = {
    IDLE: '',
    LOADING: <LoadingState dropDown={dropDown} setDropDown={setDropDown} />,
    READY: <Ready activitiesPendent={activitiesPendent ?? []} dropDown={dropDown} setDropDown={setDropDown} />,
    NOT_FOUND: <EmptyState dropDown={dropDown} setDropDown={setDropDown} />,
    ERROR: <ErrorState dropDown={dropDown} setDropDown={setDropDown} actionTryAgain={actionTryAgain} />
  }

  const render = useCallback(() => {
    return states[boxStateMachine]
  }, [boxStateMachine, dropDown])

  const getGridHeightClass = useMemo(() => {
    if (!dropDown) {
      return 'closed' // 72px
    }

    if ([BoxStateMachineEnum.NOT_FOUND, BoxStateMachineEnum.ERROR].includes(boxStateMachine)) {
      return 'adjustable' // auto
    }

    return 'opened' // 298px
  }, [dropDown, boxStateMachine])

  return (
    <Grid
      container
      spacing={2}
      className={getGridHeightClass}
      classes={{ root: classes.background }}
    >
      {render()}
    </Grid >
  )
}

export default PendingActivitiesBox
