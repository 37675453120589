/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { createRef, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useParams } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'

import { Avatar, Button, TextField } from 'components/design-system'

import useStyles from './style'
import { editCommentById, saveCommentRecord } from 'services/comments'
import { activeQuestionAtom, getCommentsByAnswerAtom } from '../../../../../../atomStore'
import { ICommentsResponse } from 'services/types/comments'
import { useAuth } from 'navigation/ProvideAuth'
import { activityCurrentAtom } from 'pages/ContentUnit/components/Activity/atomStore'

interface ICardStudentAnswerCommentFormProps {
  user_profile: number
  isEditing?: boolean
  currentComment?: ICommentsResponse
  handleClose?: () => void
}

export const CardStudentAnswerCommentForm: React.FC<ICardStudentAnswerCommentFormProps> = ({ user_profile, isEditing, currentComment, handleClose }) => {
  // const
  const classes = useStyles()
  const auth = useAuth()
  const inputRef = createRef<HTMLButtonElement>()

  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, activityId: string, evaluationId: string }>()

  // state
  const [newComment, setNewComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // atom
  const [activeQuestion] = useAtom(activeQuestionAtom)
  const [activity] = useAtom(activityCurrentAtom)
  const [, getCommentsByAnswer] = useAtom(getCommentsByAnswerAtom)

  useEffect(() => {
    if (isEditing) {
      setNewComment(currentComment?.comment ?? '')
    }
  }, [])

  const createComment = async () => {
    setIsLoading(true)
    const response = await saveCommentRecord({ classId: parseInt(classId), activity: parseInt(activityId ?? evaluationId ?? '0'), user_profile, questionId: Number(activeQuestion), comment: newComment })
    if (response.success) {
      await getCommentsByAnswer({ userSchoolProfile: Number(response.data.user_school_profile.id ?? '0'), activityId: response.data.activity.id, classId: response.data.class.id, questionId: response.data.questionId })
      setNewComment('')
      setIsLoading(false)
    }
  }

  const editComment = async () => {
    setIsLoading(true)
    const response = await editCommentById(`${currentComment?.id}`, newComment)
    if (response.success) {
      await getCommentsByAnswer({ userSchoolProfile: Number(response.data.user_school_profile.id ?? '0'), activityId: response.data.activity.id, classId: response.data.class.id, questionId: response.data.questionId })
      setNewComment('')
      setIsLoading(false)
    }
    handleClose?.()
  }

  const onSubmit = () => {
    if (isEditing) {
      editComment()
      return
    }

    createComment()
  }

  const onEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault()
      inputRef?.current?.click()
    }
  }

  const validateDataTestIdButtonSubmit = () => {
    if (isEditing) {
      return activity?.suggested_application_type === 'activity'
        ? 'correction_activity_menu_answers_confirm_edit_comment'
        : 'correction_assessment_menu_answers_confirm_edit_comment'
    }

    return activity?.suggested_application_type === 'activity'
      ? 'correction_activity_menu_answers_comment_activity'
      : 'correction_assessment_menu_answers_comment_assessment'
  }

  const validateDataTestIdButtonNotSubmit = () => {
    if (isEditing) {
      return activity?.suggested_application_type === 'activity'
        ? 'correction_activity_menu_answers_not_edit_comment'
        : 'correction_assessment_menu_answers_not_edit_comment'
    }

    return activity?.suggested_application_type === 'activity'
      ? 'correction_activity_menu_answers_not_comment_activity'
      : 'correction_assessment_menu_answers_not_comment_assessment'
  }

  return (
    <Grid container display='flex' flexDirection='column'>
      <Grid xs={12}
        component='form'
        display='flex'
        flexDirection='column'
        alignItems='center'
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          onSubmit()
        }}
      >
        <Grid item display='flex' gap='8px' width={isEditing ? '95%' : '100%'} mb='16px'>
          <button hidden type='submit' ref={inputRef} />
          {isEditing ? <></> : <Avatar userName={auth?.user?.username} sx={{ width: 24, height: 24 }} />}
          <TextField
            multiline
            required
            className={classes.cardStudentAnswerCommentsTextField}
            fullWidth
            id='new-comment'
            variant='outlined'
            label='Escreva um comentário...'
            value={newComment}
            inputProps={{ maxLength: 2200 }}
            onKeyDown={onEnterPress}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </Grid>

        <Grid item display='flex' gap='8px' width='95%' alignSelf='flex-end'>
          <Button size='small' variant='outlined' type='submit' disabled={isLoading} data-testid={validateDataTestIdButtonSubmit()}>
            {isLoading ? <CircularProgress size={20} /> : (isEditing ? 'Editar' : 'Comentar')}
          </Button>
          <Button size='small' variant='ghost' onClick={() => { setNewComment(''); handleClose?.() }} data-testid={validateDataTestIdButtonNotSubmit()}>Cancelar</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CardStudentAnswerCommentForm
