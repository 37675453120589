import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  title: {
    color: theme.colorBrand.medium,
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xl,
    letterSpacing: theme.font.spacing.distant,
    lineHeight: '40px',
    margin: '0 0 24px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.lg,
      lineHeight: theme.font.lineHeight.sm,
      marginBottom: 0
    }
  }
}))
