import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((_theme: CustomTheme) => createStyles({
  root: {
    padding: '8px 16px!important',
    width: '100%!important',
    marginBottom: '20px!important'
  }
}))
