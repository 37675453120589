const addTransparencyToHex = (color: string, transparency: number): string => {
  // Convert hex color
  const hex = color.slice(1)

  const alphaHex = Math.round(transparency * 255).toString(16).padStart(2, '0')
  return `#${hex}${alphaHex}`
}

const addTransparencyToRGB = (color: string, transparency: number): string => {
  // Convert RGB color
  const rgb = color.match(/\d+/g)?.map(Number)

  if (!rgb || rgb.length < 3) {
    throw new Error('Invalid RGB color format')
  }

  const [r, g, b] = rgb
  return `rgba(${r}, ${g}, ${b}, ${transparency})`
}

export const addTransparencyToColor = (color: string, transparency: number): string => {
  // Clamp transparency value between 0 and 1
  transparency = Math.max(0, Math.min(transparency, 1))

  if (color.startsWith('#')) {
    return addTransparencyToHex(color, transparency)
  }

  if (color.startsWith('rgb')) {
    return addTransparencyToRGB(color, transparency)
  }

  throw new Error('Unsupported color format')
}
