import { atom } from 'jotai'

export interface IPreviewState {
  transaction_id?: string | null
  id: number | null
  url: string
  name: string
  thumb: string
  ext: string
  maxFileName?: number
  fileRecordId?: number
  existingFile?: boolean
  record_id?: string
}

export const previewsAtom = atom<IPreviewState[]>([])
export const percentageAtom = atom<number>(0)
export const emptyStateAtom = atom<boolean>(true)
export const disabledAtom = atom<boolean>(false)
