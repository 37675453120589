import React from 'react'
import { Grid, useTheme, useMediaQuery } from '@mui/material'

import useStyles from '../../style'
import { StepConfirmationProps } from '../../types'
import { CustomImage } from '../CustomImage'
import { truncateExtension } from 'utils/string'

export const StepConfirmation: React.FC<StepConfirmationProps> = ({ schoolSelected, classSelected, gradeSelected, maxStep }: StepConfirmationProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container>
      <span className={classes.txtTitleConfirmation}>{maxStep === 0 ? 'Atividade' : 'Você selecionou a atividade' } para a {truncateExtension(schoolSelected?.name, 20)}, <br /> {truncateExtension(gradeSelected?.name, 20)} {truncateExtension(classSelected?.name, 20)}.</span>
      <Grid item display='flex' flexDirection='column' className={classes.contentStep} sx={{ paddingRight: 0, marginTop: isMobile ? theme.spacingInline.xxs : theme.spacingInline.sm }}>
        <CustomImage />
      </Grid>
    </Grid>
  )
}

export default StepConfirmation
