import { Skeleton, Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({

  tag: {
    marginRight: theme.spacingInline.xxxs,
    marginBlock: theme.spacingInline.nano
  }
}))

export const Loading: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      {[...Array(8)].map((cur, i) => <Skeleton key={i} className={`${classes.tag}`} animation='wave' width={110} height={50} />)}
    </>
  )
}
