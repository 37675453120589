import { useEffect, useState } from 'react'
import { DisciplineCodeEnum, IContentUnitResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import useStyles from './style'

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import { useTranslationNamespace } from 'locales/useTranslationNamespace'
import { MyMaterials, StartingPoint } from './components'
import { useCurricularStore, useStore } from 'store'
import { toast } from 'components/design-system/Toast/manager'
import { getClassSchedulesByContentUnit } from 'services/schedule'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { currentSubMenuTabAtom, subMenuTabsAtom, subMenuTabsLoadingAtom } from '../SideMenu/components/atomStore'
import { RESET, useResetAtom } from 'jotai/utils'

interface IOverviewProps {
  content: IContentUnitResponse
}

export enum SubTabsMenus {
  STARTING_POINT = 0,
  MY_MATERIALS = 1,
}

export const OverviewProjectViewMode: React.FC<IOverviewProps> = ({ content }) => {
  const classes = useStyles()

  const { profile } = useStore()
  const profileType = profile?.type

  const { contentId, classId: classIdParams } = useParams<{ contentId: string, classId: string }>()
  const { classId: classIdStore } = useCurricularStore()

  const classId = classIdParams ?? classIdStore

  const isCloeExpand = content.disciplines.map(d => d.code).includes(DisciplineCodeEnum.BI)
  const { t } = useTranslationNamespace({ ns: ['translation', 'cloe_expand'], activeIndex: isCloeExpand ? 1 : 0 })

  const subMenuTabsDefault = [
    {
      title: t('Resumo'),
      iconComponent: <FormatListBulletedIcon />,
      dataTestid: 'starting_point_summary'
    }
  ]

  const [currentSubMenuTab, setCurrentSubMenuTab] = useAtom(currentSubMenuTabAtom)
  const [, setSubMenuTabs] = useAtom(subMenuTabsAtom)
  const [, setSubMenuTabsLoading] = useAtom(subMenuTabsLoadingAtom)
  const currentTabIndex = (currentSubMenuTab ?? 0) as unknown as SubTabsMenus

  const [isMyMaterialsEnabled, setIsMyMaterialsEnabled] = useState(false)

  const resetSubMenuTabs = useResetAtom(subMenuTabsAtom)

  useEffect(() => {
    handleTabsByProfileType()

    return () => {
      setCurrentSubMenuTab(RESET)
      resetSubMenuTabs()
    }
  }, [profileType])

  const hasMaterials = async () => {
    const response = await getClassSchedulesByContentUnit({ classId, contentUnitId: contentId, type: 'original_content', limit: 1 })
    if (!response.success || !response.data) {
      throw new Error()
    }

    return !!response.data.length
  }

  const handleTabsByProfileType = async () => {
    setCurrentSubMenuTab(SubTabsMenus.STARTING_POINT)
    setSubMenuTabsLoading(true)
    try {
      if (profileType === IUserSchoolProfileTypeEnum.teacher) {
        setSubMenuTabs([...subMenuTabsDefault, {
          title: t('Meus materiais'),
          iconComponent: <BookOutlinedIcon />,
          dataTestid: 'starting_point_teacher_materials',
          disabled: false
        }])
        setIsMyMaterialsEnabled(true)
      }
      if (profileType === IUserSchoolProfileTypeEnum.student) {
        const disabled = !(await hasMaterials())
        setIsMyMaterialsEnabled(!disabled)
        setSubMenuTabs([...subMenuTabsDefault, {
          title: t('Materiais do professor'),
          iconComponent: <BookOutlinedIcon />,
          disabled,
          dataTestid: 'starting_point_teacher_materials'
        }])
      }
    } catch (err) {
      toast.handler({
        content: 'Ocorreu um erro ao buscar por materiais do professor.',
        duration: 5000,
        severity: 'error'
      })
    }
  }

  const stateMachine = {
    [SubTabsMenus.STARTING_POINT]: <StartingPoint content={content} isMyMaterialsEnabled={isMyMaterialsEnabled} />,
    [SubTabsMenus.MY_MATERIALS]: <MyMaterials />
  }

  return (
    <div className={classes.overviewWrapper}>
      {stateMachine[currentTabIndex]}
    </div>
  )
}
