import { Skeleton, useTheme, Grid } from '@mui/material'
import { CustomInfoTooltip } from 'components/common'
import { Text2 } from 'components/design-system'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai/utils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { lastUpdatedDateAtom } from '../../atomStore'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
}

interface ILastUpdate {
  isLoading: boolean
}

export const LastUpdate: React.FC<ILastUpdate> = ({ isLoading }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  // atoms
  const lastUpdatedDate = useAtomValue(lastUpdatedDateAtom)

  // states
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  useEffect(() => {
    setStateMachine(
      isLoading
        ? StateMachineEnum.LOADING
        : StateMachineEnum.READY
    )
  }, [isLoading])

  const states = {
    IDLE: <></>,
    LOADING: (
      <Grid container item xs={12} alignItems='center' columnSpacing={1}>
        <Grid item>
          <Skeleton variant='text' width={300} height={20} animation='wave' />
        </Grid>
      </Grid>
    ),
    READY: (
      <Grid container item xs={12} alignItems='center' columnSpacing={1}>
        <Grid item>
          <Text2
            fontSize='xxs'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xxs'
            customColor={theme.colors.neutral.dark40}
          >
            {t('Dados atualizados em {{date}}, às {{time}}', { date: dayjs(lastUpdatedDate).format('DD/MM/YYYY'), time: dayjs(lastUpdatedDate).format('HH:mm') })}
          </Text2>
        </Grid>
        <Grid item>
          <CustomInfoTooltip text={t('Os dados são gerados após a liberação de uma atividade ou avaliação e atualizados uma vez ao dia. Dados gerados depois da atualização serão considerados no dia seguinte.')} />
        </Grid>
      </Grid>
    )
  }

  const renderStateMachine = () => states[stateMachine]

  return (
    <>
      {renderStateMachine()}
    </>
  )
}
