import { Grid, useTheme } from '@mui/material'
import { Tooltip } from 'components/common'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface ICustomInfoTooltip {
  text: string
}

export const CustomInfoTooltip: React.FC<ICustomInfoTooltip> = ({ text }) => {
  const theme = useTheme()
  return (
    <Tooltip
      component={
        <Grid
          item
          sx={{
            height: '100%',
            display: 'flex',
            '& svg': {
              color: theme.colorBrand.medium
            }
          }}
        >
          <InfoOutlinedIcon
            style={{
              alignSelf: 'center'
            }}
          />
        </Grid>
      }
      text={text}
    />
  )
}
