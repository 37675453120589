import { Icon, Tag } from 'components/design-system'
import { useAtom } from 'jotai'
import useStyle from './style'
import { IClassDisciplinesGradeResponse } from 'services/types/taught-disciplines'
import { useTranslation } from 'react-i18next'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import { BusinessError } from 'navigation/BusinessError'
import { subscrisptionLocalAtom } from '../ModalClassesDisciplines'
import { IDisciplineResponse } from 'services/types'
import Analytics from 'utils/analytics'
import { selectedGradeTypeAtom } from 'pages/TaughtDisciplines'
import { useStore } from 'store'

interface IClassDisciplinesProps {
  gradeClass: IClassDisciplinesGradeResponse
  dataTestId?: string
}

export const ClassDisciplines: React.FC<IClassDisciplinesProps> = (props) => {
  const { gradeClass, dataTestId } = props
  const [teacherSubscription, setTeacherSubscription] = useAtom(subscrisptionLocalAtom)
  const [selectedGradeType] = useAtom(selectedGradeTypeAtom)
  const { t } = useTranslation()
  const classes = useStyle()
  const { profile } = useStore()

  const chooseDiscipline = (discipline: IDisciplineResponse) => {
    const newTeacherSubscription = JSON.parse(JSON.stringify(teacherSubscription))

    const findClass = teacherSubscription?.findIndex(cur => cur.class_id === gradeClass.class.id)
    if (findClass !== undefined && findClass !== null && findClass !== -1) {
      // se encontrar vai editar
      const findDiscipline = newTeacherSubscription[findClass].disciplines.findIndex((curDiscipline: { id: string | number }) => curDiscipline.id === discipline.id)
      if (findDiscipline !== -1) {
        // se encontrar apaga
        newTeacherSubscription[findClass].disciplines.splice(findDiscipline, 1)
      } else {
        // se não encontrar cadastra um novo
        newTeacherSubscription[findClass].disciplines.push({
          id: discipline.id,
          name: discipline.name
        })
      }
      // se disciplines estiver vazio apaga o nó
      // deixei de apagar o nó porque preciso que envie vazio para o back apagar no banco nesses casos
      // if (!newTeacherSubscription[findClass].disciplines.length) {
      //   newTeacherSubscription.splice(findClass, 1)
      // }
    } else {
      // se não encontrar cadastra um novo
      newTeacherSubscription?.push({
        class_id: gradeClass.class.id,
        disciplines: [{
          id: discipline.id,
          name: discipline.name
        }]
      })
    }
    setTeacherSubscription(newTeacherSubscription)

    if (profile && discipline) {
      Analytics.recordEventClick({
        name: 'select_curricular_component',
        attributes: {
          ...profile,
          segment_id: selectedGradeType?.id,
          segment_name: selectedGradeType?.name,
          grade_id: gradeClass?.grade?.id,
          grade_name: gradeClass?.grade?.id,
          group_id: gradeClass?.class?.id,
          group_name: gradeClass?.class?.name,
          discipline_id: discipline?.id,
          discipline_name: discipline?.name
        }
      })
    }
  }

  const checkIfTaughtDiscipline = (discipline: IDisciplineResponse) => {
    if (teacherSubscription) {
      const findClass = teacherSubscription.find(cur => cur.class_id === gradeClass.class.id)
      if (findClass) {
        const findDiscipline = findClass.disciplines.find(cur => cur.id === discipline.id)
        if (findDiscipline) return discipline.code
      }
    }

    return undefined
  }

  // se não existir gradeClass não carrega o componente
  if (gradeClass === null || gradeClass.disciplines === null || gradeClass.class === null || gradeClass.disciplines === undefined) {
    if (profile) {
      Analytics.recordEventClick({
        name: 'error',
        attributes: {
          ...profile?.analytics,
          segment_id: selectedGradeType?.id,
          segment_name: selectedGradeType?.name,
          grade_id: gradeClass?.grade?.id,
          grade_name: gradeClass?.grade?.id,
          group_id: gradeClass?.class?.id,
          group_name: gradeClass?.class?.name
        }
      })
    }
    return <BusinessError error={t('Falha ao encontrar turma')} />
  }

  return (
    <div className={classes.itemContent} key={gradeClass?.grade.id}>
      <div className={classes.itemHeader}>
        <Icon titleAccess={t('Turma')} size='large'>
          <SchoolOutlinedIcon className={classes.schoolIcon} />
        </Icon>
        <p tabIndex={0} className={classes.itemclass}>{gradeClass.class.name !== undefined ? gradeClass.class.name : ''}</p>
      </div>
      <div className={classes.tagsContainer} data-testid={dataTestId}>
        {gradeClass?.disciplines.map((d, i) => (
          <Tag
            tabIndex={0}
            className={classes.tag}
            key={i}
            variant={checkIfTaughtDiscipline(d)}
            onClick={() => chooseDiscipline(d)}
          >
            {t(d.name)}
          </Tag>
        ))}
      </div>
    </div>
  )
}
