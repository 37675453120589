import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    gap: theme.spacingInline.xxxs,
    flexDirection: 'column',
    padding: `0 ${theme.spacingInset.xxs}`,
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  contentContainer: {
    gap: theme.spacingStack.xxs
  },
  title: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.md,
    lineHeight: theme.font.lineHeight.sm,
    letterSpacing: '0.2px',
    color: theme.colors.information.feedbackDarkest,
    marginTop: theme.spacingStack.xxxs
  },
  sectionContainer: {
    display: 'flex',
    width: '100%',
    marginLeft: '0 !important',
    marginTop: `clamp(0, 0, ${theme.spacingInset.xxs})`,
    '& > .MuiGrid-item': {
      paddingLeft: '0 !important'
    },

    '& p:first-of-type': {
      marginTop: 0
    },
    '& p:last-of-type': {
      marginBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      margin: '0 !important',
      '& > .MuiGrid-item': {
        margin: 0
      }
    }
  },
  sectionContainerText: {
    [theme.breakpoints.down('md')]: {
      color: theme.colors.information.feedbackDark,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: '24px',
      letterSpacing: '0.16px'
    }
  },
  activityItem: {
    display: 'flex'
  },
  duration: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    fontWeight: theme.font.fontWeight.semibold,
    lineHeight: theme.font.lineHeight.sm,
    color: theme.colors.information.feedbackDarkest,
    margin: `${theme.spacingStack.xxxs} 0`,
    '& svg': {
      marginRight: theme.spacingInline.nano
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.font.fontSize.sm
    }
  }
}))
