import Amplify, { Analytics } from 'aws-amplify'
import { IRecordEventClick, IAnalytics, IDataSetup } from '../interfaces'

export class AnalyticsAmplify implements IAnalytics {
  readonly name = 'amplify'
  readonly hasSetup = true
  constructor(identityPoolId: string, appId: string, region: string) {
    Amplify.configure({
      Auth: {
        identityPoolId,
        region
      },
      Analytics: {
        AWSPinpoint: {
          appId,
          region
        }
      }
    })
  }

  setup(data: IDataSetup) {
    Analytics.updateEndpoint({
      address: data.username,
      userAttributes: data.attributes
    }).then(() => {
    }).catch(() => {
      console.error('Error AnalyticsAmplify')
    })
  }

  recordEventClick(data: IRecordEventClick) {
    void Analytics.record({ name: data.name, attributes: data.attributes })
  }

  async recordEventClickSync(data: IRecordEventClick): Promise<unknown> {
    return await Analytics.record({ name: data.name, attributes: data.attributes, immediate: true })
  }
}
