import { useState, useEffect, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, useTheme, useMediaQuery } from '@mui/material'
import { Alert, Button, Text2, Search, Select } from 'components/design-system'
import { KnowMoreModal } from './components'

import { IStudentsListData } from '../..'

import useStyle from './styles'

interface IReportsListHeaderProps {
  sortByCallback: (sortBy: string) => void
  searchCallback: (search: string) => void
  studentsListData: IStudentsListData
  tryAgainCallback: () => Promise<void>
}

type IOrderByValue = 'asc' | 'desc' | 'situation'

interface IOrderByOption {
  id: number
  label: string
  value: IOrderByValue
  dataTestId?: string
}

export const ReportsListHeader: React.FC<IReportsListHeaderProps> = ({ sortByCallback, searchCallback, studentsListData, tryAgainCallback }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyle()

  const orderByOptionsWithSituation: IOrderByOption[] = [
    { id: 1, label: 'Situação', value: 'situation' },
    { id: 2, label: 'Nome - crescente (A - Z)', value: 'asc' },
    { id: 3, label: 'Nome - decrescente (Z - A)', value: 'desc' }
  ]
  const orderByOptions: IOrderByOption[] = [
    { id: 1, label: 'Nome - crescente (A - Z)', value: 'asc' },
    { id: 2, label: 'Nome - decrescente (Z - A)', value: 'desc' }
  ]

  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false)
  const [options, setOptions] = useState<IOrderByOption[]>(orderByOptionsWithSituation.map(op => ({ ...op, dataTestId: 'followup_select_order' })))
  const [sortBy, setSortBy] = useState<IOrderByValue>('situation')

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const someStudentsHaveAverage = studentsListData.data?.result.some(s => s.performance ? s.performance.dec_performance !== null : s.performance !== null)

  useEffect(() => {
    if (!someStudentsHaveAverage) {
      setSortBy('asc')
      setOptions(orderByOptions.map(op => ({ ...op, dataTestId: 'followup_select_order' })))
      sortByCallback('asc')
    } else {
      setSortBy('situation')
      sortByCallback('situation')
    }
  }, [])

  const ActionButton = () => (
    <Button
      className='actionButton'
      variant='ghost'
      size='small'
      data-testid='followup_reload'
      onClick={tryAgainCallback}
    >
      {t('Recarregar')}
    </Button>
  )

  const handleSortBy = (e: ChangeEvent<HTMLInputElement>) => {
    const _sortBy = e.target.value as IOrderByValue
    setSortBy(_sortBy)
    sortByCallback(_sortBy)
  }

  return (
    <>
      <Grid item xs={12} sx={{ pb: 3 }}>
        <Text2
          fontSize='lg'
          fontWeight='medium'
          lineHeight='xxs'
          mobile='sm'
          customColor={theme.colorBrand.medium}
        >
          {t('Desempenho por estudante')}
        </Text2>
      </Grid>
      {
        ((!!studentsListData.data?.error && studentsListData.data?.error.length > 0) || !!studentsListData.error) && (
          <Grid container>
            <Alert
              content={studentsListData.error ?? t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')}
              outlined={true}
              severity='error'
              actionButton={<ActionButton />}
            />
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Text2
          fontSize='sm'
          fontWeight='medium'
          lineHeight='xs'
          mobile='sm'
          customColor={theme.colors.neutral.dark10}
        >
          <div dangerouslySetInnerHTML={{ __html: t('Aqui você encontra o desempenho individual de todos os estudantes da turma. O campo <b>situação</b> indica a necessidade do estudante com base na média individual. A partir dele, é possível definir próximos passos para auxiliar no desempenho de cada um.', { interpolation: { escapeValue: false } }) }} />
        </Text2>
      </Grid>
      <Grid item xs={12} sx={{ pt: 3, pb: 3 }}>
        <Button data-testid={'followup_know_more'} onClick={() => setOpenKnowMore(true)} className={classes.knowMoreButton}>
          {t('Saiba mais')}
        </Button>
      </Grid>
      <Grid container item direction={isMobile ? 'column-reverse' : 'row'} columnSpacing={3} rowSpacing={2}>
        <Grid item xs={isMobile ? 'auto' : 12} md={9}>
          <Search
            placeholder={t('Pesquisar estudante')}
            onSubmit={(search: string) => searchCallback(search)}
            onClear={() => searchCallback('')}
            dataTestId={{
              submit: 'followup_search_student',
              clear: 'followup_close_search'
            }}
          />
        </Grid>
        <Grid item xs={isMobile ? 'auto' : 12} md={3}>
          <Select
            dataTestId='followup_order_by'
            id='order-by'
            label={t('Ordenar por')}
            options={options}
            value={sortBy}
            onChange={handleSortBy}
          />
        </Grid>
      </Grid>

      <KnowMoreModal open={openKnowMore} setOpen={setOpenKnowMore} />
    </>
  )
}
