import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useStore } from 'store'

import { truncateExtension } from 'utils/string'
import { recipientsModalSelectorAtom, scheduleAtom, subscriptionsAtom, subscriptionsAllSelectedAtom } from '../atomStore'

import { RecipientsSelect } from './RecipientsSelect'

import { STATE } from './types'

export const RecipientsSelectContainer = () => {
  // state
  const [currentState, setCurrentState] = useState<STATE>('empty')

  // atoms and stores
  const { session } = useStore()
  const [schedule] = useAtom(scheduleAtom)
  const [subscriptions] = useAtom(subscriptionsAtom)
  const [, setRecipientsModalSelector] = useAtom(recipientsModalSelectorAtom)
  const [subscriptionsAllSelected, setSubscriptionsAllSelected] = useAtom(subscriptionsAllSelectedAtom)

  // params
  const { classId } = useParams<{ classId: string }>()

  // const
  const classSelected = session?.subscriptions.filter(cur => cur.class).find(sub => sub?.class?.id?.toString() === classId)?.class

  useEffect(() => {
    if (!subscriptionsAllSelected && (subscriptions ?? []).length === 0 && !schedule?.id) {
      setCurrentState('empty')
      return
    }

    // já cadastrado
    if (schedule?.id && schedule?.start_date) {
      if ((subscriptions ?? []).length === 0 && !subscriptionsAllSelected) {
        setSubscriptionsAllSelected(true)
        setCurrentState('fill')
        return
      }

      // verificar quando ouve alteração em um evento que já está cadastrado
      if (new Date() > new Date(schedule.start_date)) {
        // nesse caso havia sido gravado apenas alguns estudantes e agora foi selecionado toda a turma
        if ((schedule?.subscriptions ?? []).length > 0 && subscriptionsAllSelected) {
          setCurrentState('changed_recipient')
          return
        }

        // nesse caso havia sido gravado apenas alguns estudantes e agora foi adicionado mais estudantes
        if ((schedule?.subscriptions ?? []).length > 0 && !subscriptionsAllSelected && (subscriptions ?? []).length >= (schedule?.subscriptions ?? []).length) {
          // verificar se foi adicionado algum estudante diferente
          const differentStudents = (subscriptions ?? []).filter(subscription => (schedule?.subscriptions ?? []).every(scheduleSubscription => scheduleSubscription.id !== subscription.id))
          if (differentStudents.length > 0) {
            setCurrentState('changed_recipient')
            return
          }
        }
      }
    }

    setCurrentState('fill')
  }, [subscriptions, schedule])

  const validateInfoSubscriptions = () => {
    if (subscriptionsAllSelected) {
      return truncateExtension(classSelected?.name ?? '', 30)
    }

    if ((subscriptions ?? []).length > 0) {
      return ((subscriptions ?? []).map(subscription => subscription.name)).join(', ')
    }

    return ''
  }

  if (schedule?.content_unit) {
    return <></>
  }

  return <RecipientsSelect {...{ currentState, setRecipientsModalSelector, validateInfoSubscriptions }} />
}
