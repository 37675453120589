import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  title: {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.md,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    color: theme.colors.information.feedbackDarkest,

    [theme.breakpoints.down('md')]: {
      fontWeight: theme.font.fontWeight.semibold

    }
  },
  mobileCloseButton: {
    padding: `0 ${theme.spacingInline.nano} !important`,
    boxSizing: 'content-box !important' as 'content-box',
    width: '24px !important',
    color: theme.colorBrand.dark,
    minWidth: 'auto !important',
    '& svg': {
      color: theme.colors.information.feedbackDarkest
    },

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  navigationHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  navigationHeaderButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacingInline.xxxs
  },
  navigationFooterButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacingInline.xxxs,
    justifyContent: 'space-between',
    marginTop: theme.spacingStack.xxs
  },
  navigationHeaderButton: {
    borderRadius: theme.borderRadius.circular,
    color: `${theme.colors.information.feedbackDarkest} !important`,
    borderColor: `${theme.colors.information.feedbackDarkest} !important`,
    width: '32px !important',
    height: '32px !important',
    minWidth: 'auto !important',
    padding: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover:not(.Mui-disabled)': {
      backgroundColor: `${theme.colors.information.feedbackLight} !important`
    },
    '& svg': {
      fontSize: theme.font.fontSize.xs
    },
    '&.Mui-disabled': {
      color: `${theme.colors.neutral.dark40} !important`,
      borderColor: `${theme.colors.neutral.dark40} !important`,
      backgroundColor: 'transparent !important'
    }
  },
  navigationFooterButton: {
    borderRadius: theme.borderRadius.circular,
    color: `${theme.colors.information.feedbackDarkest} !important`,
    borderColor: `${theme.colors.information.feedbackDarkest} !important`,

    fontSize: `${theme.font.fontSize.xs} !important`,

    width: 'calc(100% - 8px) !important',
    height: '40px !important',
    padding: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      fontSize: `${theme.font.fontSize.sm} !important`
    },
    '&:hover:not(.Mui-disabled)': {
      backgroundColor: `${theme.colors.information.feedbackLight} !important`
    },
    '&.Mui-disabled': {
      color: `${theme.colors.neutral.dark40} !important`,
      borderColor: `${theme.colors.neutral.dark40} !important`,
      backgroundColor: 'transparent !important'
    }
  },
  printButton: {
    borderColor: `${theme.colors.information.feedbackDarkest} !important`,
    color: `${theme.colors.information.feedbackDarkest} !important`,
    width: '40px !important',
    height: '40px !important',

    '&:hover': {
      background: `${theme.colors.information.feedbackLightest} !important`
    },

    '& svg': {
      width: `${theme.font.fontSize.md} !important`
    }
  },
  instructionalContainer: {
    background: theme.colors.information.feedbackLightest,
    overflow: 'hidden',
    borderRadius: theme.borderRadius.md,
    paddingBottom: theme.spacingStack.xl,
    height: '100% !important',

    '&.enabledMobileFullPage': {
      [theme.breakpoints.down('md')]: {
        overflowY: 'auto',
        borderRadius: 0
      }
    }
  },
  headerContainer: {
    boxSizing: 'border-box',
    background: theme.colors.information.feedbackLight,
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs}`,

    '&.enabledMobileFullPage': {
      [theme.breakpoints.down('md')]: {
        background: 'transparent',
        padding: theme.spacingInline.xxxs
      }
    }
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    gap: '24px',
    padding: theme.spacingInset.xs,

    [theme.breakpoints.down('md')]: {
      padding: theme.spacingInline.xxxs
    }
  }
}))
