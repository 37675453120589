import useStyles from './style'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import { useState, useEffect, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
import { CONTENT_UNIT_STUDENT_EXPEDITION, CONTENT_UNIT_TEACHER_CONTENT, CONTENT_UNIT_STUDENT_CONTENT } from 'navigation/CONSTANTS'
import { useStore } from 'store'
import { IUserSchoolProfileTypeEnum } from 'services/types'
import { parseKaTex } from 'utils/katex'
import { useAtom } from 'jotai'
import { activityIndexSelectedAtom, allActivities } from 'pages/ContentUnit/atomStore'

export interface CardProps {
  position?: number
  title?: string
  subtitles?: string[]
  text?: string
  section?: Object
  expanded?: boolean
  iconType?: string
  idSection?: number | string
  idActivity?: number | string
  fowardedRef?: React.LegacyRef<HTMLDivElement>
  endAdornment?: React.ReactNode
  dataTestid?: string
}

function setIconType(iconType: string) {
  switch (iconType) {
    case 'evaluation':
      return <BallotOutlinedIcon />
    case 'activity':
      return <ExtensionOutlinedIcon />
    default:
      return <DescriptionOutlinedIcon />
  }
}

const Card = ({
  expanded = false,
  iconType = 'content',
  position,
  title,
  subtitles,
  text,
  idSection,
  idActivity,
  endAdornment,
  dataTestid
}: CardProps) => {
  const { gradeTypeCode, gradeCode, contentId, sectionId, activityId, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, contentId: string, sectionId?: string, activityId?: string, classId: string }>()
  const cardExpanded = expanded
  const [cardActive, setActive] = useState(false)
  const titleRef = useRef<HTMLAnchorElement | null>()
  const { subscription, profile } = useStore()
  const [activities] = useAtom(allActivities)
  const [, setActivityIndexSelected] = useAtom(activityIndexSelectedAtom)
  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  useEffect(() => {
    setActive(false)
    if (sectionId && idSection) {
      setActive(sectionId === idSection?.toString())
    } else if (activityId && idActivity) {
      setActive(activityId === idActivity?.toString())
      setActivityIndexSelected(activities?.findIndex(a => a.id === +activityId) ?? 0)
    }
  }, [sectionId, activityId])

  const props = { cardExpanded, cardActive, position, titleWidth: titleRef.current?.clientWidth }
  const classes = useStyles({ ...props })

  const getLink = () => {
    if (profileType === IUserSchoolProfileTypeEnum.teacher) {
      return CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, contentId, idSection as string)
    } else if (profileType === IUserSchoolProfileTypeEnum.student && idActivity) {
      return CONTENT_UNIT_STUDENT_EXPEDITION(contentId, idActivity as string)
    }
    return CONTENT_UNIT_STUDENT_CONTENT(contentId, idSection as string)
  }

  const link = getLink()

  return (
    <Link
      to={link}
      ref={(el) => {
        titleRef.current = el
      }}
      className={classes.cardWrapper}
      id={cardActive ? 'card-selected' : ''}
      data-testid={dataTestid}
    >
      <div className={cardActive ? classes.wrapperDefaultSelected : classes.wrapperDefault}>
        {
          position &&
          <div className={classes.positionWrapper}>
            <div className={classes.position}>{position}</div>
          </div>
        }
        <div className={classes.titleDefault}>
          {setIconType(iconType)}
          {parseKaTex(title ?? '')}
          {endAdornment ?? null}
        </div>
        <div className={classes.navTitles}>
          {
            subtitles?.map((subtitle: string, index) =>
              <div key={index} className={classes.textDefault}>
                {parseKaTex(subtitle)}
              </div>)
          }
        </div>
        <div className={classes.textDefault}>
          {text}
        </div>
      </div>
    </Link>
  )
}

export default Card
