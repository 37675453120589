import { useMediaQuery, useTheme } from '@mui/material'
import { atom } from 'jotai'
import { atomWithReset, useUpdateAtom } from 'jotai/utils'
import { _openMyCloeAtom } from 'navigation/components/MyCloe/atomStore'
import { useEffect, useLayoutEffect } from 'react'

export const _isOpenInstructionalAtom = atomWithReset<boolean>(false)
export const _isOpenSideMenuAtom = atomWithReset<boolean>(true)

export const useToggleInstructionalAtom = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const setIsOpenSideMenuAtom = useUpdateAtom(_isOpenSideMenuAtom)
  const setIsOpenInstructionalAtom = useUpdateAtom(_isOpenInstructionalAtom)
  const setIsOpenMyCloeAtom = useUpdateAtom(_openMyCloeAtom)

  useLayoutEffect(() => {
    if (isMobile) {
      setIsOpenSideMenuAtom(false)
      setIsOpenInstructionalAtom(false)
      setIsOpenMyCloeAtom(false)
    }
  }, [isMobile])

  const toggleInstructionalAtom = atom(
    (get) => get(_isOpenInstructionalAtom),
    (get, set, newIsOpenInstructional: boolean) => {
      const isOpenInstructional = newIsOpenInstructional ?? !get(_isOpenInstructionalAtom)

      set(_isOpenInstructionalAtom, isOpenInstructional)
      if (isMobile) {
        set(_isOpenSideMenuAtom, false)
      } else {
        set(_isOpenSideMenuAtom, !isOpenInstructional)
      }
    }
  )
  return {
    toggleInstructionalAtom
  }
}

export const useToggleSideMenuAtom = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const setIsOpenSideMenuAtom = useUpdateAtom(_isOpenSideMenuAtom)
  const setIsOpenInstructionalAtom = useUpdateAtom(_isOpenInstructionalAtom)
  const setIsOpenMyCloeAtom = useUpdateAtom(_openMyCloeAtom)

  useEffect(() => {
    if (isMobile) {
      setIsOpenSideMenuAtom(false)
      setIsOpenInstructionalAtom(false)
      setIsOpenMyCloeAtom(false)
    }
  }, [isMobile])

  const toggleSideMenuAtom = atom(
    (get) => get(_isOpenSideMenuAtom),
    (get, set, newIsOpenSideMenu: boolean) => {
      const isOpenSideMenu = newIsOpenSideMenu ?? !get(_isOpenSideMenuAtom)

      set(_isOpenSideMenuAtom, isOpenSideMenu)

      if (isMobile) {
        set(_isOpenInstructionalAtom, false)
        set(_openMyCloeAtom, false)
      } else {
        set(_isOpenInstructionalAtom, !isOpenSideMenu)
        set(_openMyCloeAtom, !isOpenSideMenu)
      }
    }
  )

  return {
    toggleSideMenuAtom
  }
}
