import React from 'react'
import { Button } from 'components/design-system/Button'
import { DownloadOutlined, ViewList } from '@mui/icons-material'
import makeStyles from './style'
import { useTranslation } from 'react-i18next'
import { useOnlineStatus } from 'contentCacheManager'
import { useMediaQuery, useTheme } from '@mui/material'

type ButtonFilterProps = {
  id?: string
  onClick?: () => void
  filterEnable: boolean
}

export const ButtonFilter: React.FC<ButtonFilterProps> = (props) => {
  const style = makeStyles()
  const { t } = useTranslation()
  const isOnline = useOnlineStatus()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (isOnline && isMobile)
    ? (
      <Button
        id={props.id}
        startIcon={props.filterEnable ? <ViewList /> : <DownloadOutlined />}
        variant={props.filterEnable ? 'outlined' : 'primary'}
        className={style.root}
        onClick={props.onClick}
      >
        {props.filterEnable ? t('Todos Conteúdos') : t('Conteúdos baixados')}
      </Button>
    )
    : null
}
