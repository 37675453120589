/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useRef } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { ImageOutlined, PlayCircleOutline, PictureAsPdfOutlined } from '@mui/icons-material'
import { ReactComponent as CameraIcon } from 'assets/icon_camera.svg'
import { ReactComponent as PdfIcon } from 'assets/icon_pdf.svg'
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined'
import { Icon } from 'components/design-system'

import useStyles from './style'

interface ICardFileHoverProps {
  fileUrl: string
  isActive?: boolean
  onClick?: () => void
  dataTestId?: string
}

export const CardFileHover: React.FC<ICardFileHoverProps> = ({
  fileUrl,
  dataTestId = '',
  isActive = false,
  onClick = () => {
    // This is intentional
  }
}) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isHover, isMobile, isActive })
  const isSafari = /apple/i.test(navigator.vendor)
  const isiPhone = /iPhone/i.test(navigator.userAgent)
  const tagginRef = useRef<HTMLAnchorElement>(null)

  const getFileExtension = (): string => {
    return (fileUrl.split('.').pop() ?? '').toLowerCase()
  }

  const getFileName = (): string => {
    return fileUrl.split('/').pop() ?? ''
  }

  const renderIcon = () => {
    const extension = getFileExtension()

    if (extension === 'pdf') return <PictureAsPdfOutlined />

    if (['mp4', 'mov', 'qt'].includes(extension)) return <PlayCircleOutline />

    if (['mp3', 'wav', 'm4a', 'ogg'].includes(extension)) {
      return <Icon size='large' iconColor='#fff'><VolumeUpOutlinedIcon /></Icon>
    }

    return <ImageOutlined />
  }

  const renderFile = () => {
    const extension = getFileExtension()

    if (extension === 'pdf' && !isMobile) return <iframe className={classes.cardFileHoverContent} src={`${fileUrl}#view=fit`} />

    if (extension === 'pdf' && isMobile) {
      return (
        <div className={classes.svgIconContainer} >
          <PdfIcon />
        </div>
      )
    }

    if (['mp4', 'qt'].includes(extension) || (isSafari && extension === 'mov')) return <video className={classes.cardFileHoverContent} src={`${fileUrl}#t=0.001`} controls={false} />

    if (['mp3', 'wav', 'm4a', 'ogg'].includes(extension)) {
      return (
        <div className={classes.svgIconContainer} >
          <div className={`${classes.volumeUpContainer} volumeIconContainer`}>
            <Icon size='xlarge' iconColor='#fff'>
              <VolumeUpOutlinedIcon />
            </Icon>
          </div>
        </div>
      )
    }

    if (!isSafari && !isiPhone && extension === 'mov') {
      return (
        <div className={classes.svgIconContainer} >
          <CameraIcon />
        </div>
      )
    }

    if (['jpg', 'png', 'jpeg', 'webp'].includes(extension)) return <img className={classes.cardFileHoverContent} src={fileUrl} />

    return <object className={classes.cardFileHoverContent} data={fileUrl} />
  }

  const handleMouseEnter = () => {
    if (isMobile) return

    setIsHover(true)
  }

  const handleMouseLeave = () => {
    if (isMobile) return

    setIsHover(false)
  }

  const handleClick = () => {
    tagginRef?.current?.click()
    onClick()
  }

  return (
    <>
      <a ref={tagginRef} data-testid={dataTestId} style={{ display: 'none' }} />
      <div
        className={classes.cardFileHoverContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {renderFile()}
        <div className={classes.cardFileHoverInfoContent}>
          {isHover && (
            <>
              {renderIcon()}
              <span>{getFileName()}</span>
            </>
          )}
        </div>
        <div className={classes.cardFileHoverInfoContentMobile} />
      </div>
    </>
  )
}
