import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: 'unset',
      paddingTop: theme.spacingStack.xl,
      // larger screens
      [theme.breakpoints.up('sm')]: {
        borderRadius: `${theme.borderRadius.md} ${theme.borderRadius.md} 0px 0px`,
        background: theme.colors.neutral.lightBase,
        height: 'auto',
        boxShadow: theme.shadowLevels.level5,
        padding: `${theme.spacingStack.xs} ${theme.spacingStack.xxs}`
      }
    },
    backLink: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      fontWeight: theme.font.fontWeight.medium,
      color: theme.colorBrand.medium,
      padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.nano}`,
      lineHeight: theme.font.lineHeight.xs,
      fontSize: theme.font.fontSize.md,
      // larger screens
      [theme.breakpoints.up('sm')]: {
        padding: 0
      },
      '& svg': {
        marginRight: theme.spacingInline.nano
      }
    },
    backButton: {
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colorBrand.medium
    },
    headerContainer: {
      alignItems: 'center',
      display: 'block',
      // larger screens
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      },
      '&.containerAcordeon': {
        borderBlock: 'none'
      }
    },
    titleAccordion: {
      alignItems: 'center',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.md,
      color: theme.colors.neutral.dark30,
      lineHeight: theme.font.lineHeight.xs
    },
    subtitleAccordion: {
      alignItems: 'center',
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs,
      color: theme.colors.neutral.dark30,
      lineHeight: theme.font.lineHeight.sm
    },
    stackContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    textField: {
      paddingTop: theme.spacingStack.xxs,
      // larger screens
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacingStack.xs,
        '& div': { width: '100%' }
      }
    },
    helpText50: {
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.xxs,
      color: theme.colors.neutral.dark30,
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 0,
      '&.limit': {
        color: theme.colors.negative.feedbackDark
      }
    },
    helpText300: {
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.xxs,
      color: theme.colors.neutral.dark30,
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 0,
      '&.limit': {
        color: theme.colors.negative.feedbackDark
      }
    },
    text: {
      color: theme.colors.neutral.dark30,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.xs,
      paddingTop: 0,
      marginTop: 20,
      marginBottom: 0
    },
    classButton: {
      paddingBlock: theme.spacingStack.nano,
      justifyContent: 'space-around',
      // larger screens
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        display: 'flex',
        paddingBlock: theme.spacingStack.xs,
        width: '100%',
        justifyContent: 'end'
      }
    },
    disciplineContainer: {
      padding: `${theme.spacingInset.xxs} 0px ${theme.spacingInset.md} 0px`
    }
  })
)
