import { useMediaQuery, useTheme } from '@mui/material'
import { atom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { _isOpenInstructionalAtom, _isOpenSideMenuAtom } from 'pages/ContentUnit/components/atomStore'
import { useEffect } from 'react'

export enum MY_CLOE_CONTEXT {
  HOME = 'HOME',
  CONTENT_UNIT = 'CONTENT_UNIT',
  ACTIVITY = 'ACTIVITY',
  CLASSES = 'CLASSES'
}

export const _openMyCloeAtom = atom<boolean>(false)
export const myCloeContextAtom = atom<MY_CLOE_CONTEXT>(MY_CLOE_CONTEXT.HOME)

export const useToggleOpenMyCloeAtom = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const setIsOpenSideMenuAtom = useUpdateAtom(_isOpenSideMenuAtom)
  const setIsOpenInstructionalAtom = useUpdateAtom(_isOpenInstructionalAtom)
  const setIsOpenMyCloeAtom = useUpdateAtom(_openMyCloeAtom)

  useEffect(() => {
    if (isMobile) {
      setIsOpenSideMenuAtom(false)
      setIsOpenInstructionalAtom(false)
      setIsOpenMyCloeAtom(false)
    }
  }, [isMobile])

  const toggleOpenMyCloeAtom = atom(
    (get) => get(_openMyCloeAtom),
    (get, set, newOpenMyCloe: boolean) => {
      const openMyCloe = newOpenMyCloe ?? !get(_openMyCloeAtom)

      set(_openMyCloeAtom, openMyCloe)

      if (isMobile) {
        set(_isOpenSideMenuAtom, false)
      } else {
        set(_isOpenSideMenuAtom, !openMyCloe)
      }
    }
  )

  return {
    toggleOpenMyCloeAtom
  }
}
