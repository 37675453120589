import { generateChatAIPromptCompletion } from 'services/chat-ai'
import { ICurricularData } from './types'
import generateFormattedPrompt from './utils/generateFormattedPrompt'

export const useChatAi = (curricularData: ICurricularData) => {
  const generateFeedback = async () => {
    const promptFormatted = generateFormattedPrompt(curricularData)
    console.log('%c Prompt gerado para essa resposta: \n %s', 'color: green; background: black; font-size: 22px', promptFormatted)
    const response = await generateChatAIPromptCompletion(promptFormatted)

    return response
  }

  return {
    generateFeedback
  }
}
