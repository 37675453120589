import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiTab-root': {
      color: theme.colorBrand.medium,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.semibold,
      marginRight: '24px',
      minHeight: 'auto',
      textTransform: 'none',
      // mobile
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px'
      },
      '&.Mui-selected': {
        color: theme.colorBrand.medium
      }
    }
  }
}))
