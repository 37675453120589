import { useEffect } from 'react'
import { ActivityControlStatusEnum, IQuestionResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import Grid from '@mui/material/Grid'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'
import { activityStatusControlAtom, answersAtom, questionIndexAtom } from '../../../../atomStore'
import { Alert } from '@mui/material'
import { TabPanel } from 'components/design-system'
import OnlyProfile from 'components/common/OnlyProfile'
import Question from './components/Question'

interface IQuestionsProps {
  questions: IQuestionResponse[]
}

export const QuestionList: React.FC<IQuestionsProps> = ({ questions }) => {
  const { t } = useTranslation()
  const [questionIndex, setQuestionIndex] = useAtom(questionIndexAtom)
  const [answers] = useAtom(answersAtom)
  const [activityStatusControl] = useAtom(activityStatusControlAtom)
  const { subscription } = useStore()
  const { isExpeditionView } = useStudentAnswer()

  const isNotAnswered = (questionIndex === questions.length - 1) && answers.length < questions.length - 1

  const showQuestionsListFormat = isExpeditionView && subscription?.user_school_profile?.type === 'student'

  useEffect(() => { setQuestionIndex(0) }, [])

  if (!questions.length) {
    return (
      <Grid container
        spacing={1}
        justifyContent='center'
        ml={0}>
        <h1>{t('Não existe questões cadastradas')}</h1>
      </Grid>
    )
  }

  return (
    <Grid
      container
      justifyContent='center'
      ml={0}
    >
      <Grid item xs={12}>
        <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
          {
            isNotAnswered && [ActivityControlStatusEnum.PENDING, ActivityControlStatusEnum.DONE].includes(Number(activityStatusControl)) &&
            <Grid item xs={12}>
              <Alert severity='warning'>
                {t('Ainda existem questões a serem respondidas.')}
              </Alert>
            </Grid>
          }
        </OnlyProfile>
        {
          questions.map((question, index) => {
            if (!showQuestionsListFormat) {
              return (
                <TabPanel key={question.id} value={questionIndex} index={index}>
                  <Question question={question} index={index} />
                </TabPanel>
              )
            }
            return <Question question={question} index={index} />
          })
        }
      </Grid>
    </Grid>
  )
}

export default Question
