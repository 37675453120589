import { IActivityResponse, IContentUnitResponse, IQuestionResponse } from 'services/types'
import { ContentTypeEnum } from 'services/types/content'

export enum StateTypes {
  DEFAULT = 'DEFAULT',
  EI = 'EI',
  EF1 = 'EF1',
  EM_IT = 'EM_IT'
}

export type IContentArea = {
  content: IContentUnitResponse
}

export type IContentAreaProps = {
  state: StateTypes
  title: string
  value: number
  isBtnDisabled: boolean
  content: IContentUnitResponse

  setValue: (value: number) => void
  updateContentSelected: () => void
}

export type ITabsProps = {
  title: string
  value: number
  content: IContentUnitResponse

  setValue: (value: number) => void
}

export type CustomCardSelectorProps = {
  type: ContentTypeEnum
  suggestedApplicationType?: 'activity' | 'evaluation'
  title: string
  cardId: string
  isDisabled?: boolean
  questions?: IQuestionResponse[]
  isTemplateActivity?: boolean
  clonedTimes?: number
  momentType?: 'home' | 'during_class'
  showTypeTag: boolean
}

export type CustomTitleProps = {
  title: string
  description: string
}

export type RenderListProps = {
  title: string
  description: string
  filtered: IActivityResponse[]
  showTypeTag: boolean
}
