import { IAnswerRecordRequest } from 'contentCacheManager'

export enum IAnswerCachedStatus {
  INITIAL = 'CREATED',
  SENT_SUCCESS = 'SENT_SUCCESS',
  SENT_ERROR = 'SENT_ERROR',
}

export interface IAnswerCached {
  id?: number
  requestAnswer: IAnswerRecordRequest
  status: IAnswerCachedStatus
  filesOffline: Array<{
    content_unit: string
    activity: string
    question_id: number
    files: Array<{
      fileName?: string
      file: Blob
    }>
  }>
  errorReason?: string
  sentAt?: Date
  userId?: string
  isEditing?: boolean
}
