import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ListContentUnit } from './components/ListContentUnit'
import { BusinessError } from 'navigation/BusinessError'
import { useTranslation } from 'react-i18next'
import Loading from './components/Loading'
import { useCurricularStore } from 'store'

type StateContents = 'idle' | 'loading' | 'listContentsUnit' | 'notFoundClass' | 'notFoundDisciplines' | 'doNotTeachClass'

export const Contents = () => {
  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()

  const [state, setState] = useState<StateContents>('idle')
  const { disciplines, loadingDisciplines } = useCurricularStore()

  const { t } = useTranslation()

  if (!classId) {
    setState('notFoundClass')
  }

  useEffect(() => {
    // Só carrega componente caso tenha todas as condições
    if (!['notFoundClass'].includes(state) && loadingDisciplines) {
      setState('loading')
      // void fetchDiscisplines()
    } else if (disciplines === null) {
      setState('notFoundDisciplines')
    } else if (!disciplines?.length) {
      setState('doNotTeachClass')
    } else {
      setState('listContentsUnit')
    }
  }, [loadingDisciplines])

  const render: { [key in StateContents]: any } = {
    idle: <Loading />,
    loading: <Loading />,
    notFoundClass: <BusinessError error={t('Faltam parametros para definir turma')} />,
    notFoundDisciplines: <BusinessError error={t('Não foi possível carregar disciplinas para a turma.')} />,
    doNotTeachClass: <BusinessError error={t('Professor não ministra nenhuma disciplina para a turma.')} />,
    listContentsUnit: <ListContentUnit navigation={{ classId: Number(classId), gradeCode, gradeTypeCode }} />
  }

  return render[state]
}

export default Contents
