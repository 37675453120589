import { Loading } from 'components/design-system'
import { useAuth } from 'navigation/ProvideAuth'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const AuthSSO = () => {
  const { jwt: token } = useParams<{ jwt: string }>()
  const auth = useAuth()

  useEffect(() => {
    auth?.loginWithSignedJwt(token)
  }, [])

  return <Loading />
}

export default AuthSSO
