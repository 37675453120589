import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useAtom } from 'jotai'
import { useMediaQuery, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'

import InfoBar from './components/InfoBar'
import ListStudentsAnswer from './components/ListStudentsAnswer'
import { getStudentsAnswerByActivityAtom, isLoadingStudentsAnswerAtom } from './atomStore'
import Loading from './components/Loading'

type StateTeacherActivityResultsView = 'loading' | 'renderListStudentsAnswer'

export const TeacherActivityResultsView: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // params
  const { classId, activityId, evaluationId } = useParams<{ classId: string, activityId: string, evaluationId: string }>()

  // state
  const [state, setState] = useState<StateTeacherActivityResultsView>('loading')

  // atom
  const [isLoadingstudentsAnswer] = useAtom(isLoadingStudentsAnswerAtom)
  const [, getStudentsAnswerByActivity] = useAtom(getStudentsAnswerByActivityAtom)

  useEffect(() => {
    getStudentsAnswerByActivity({ classId, activityId: activityId ?? evaluationId })
  }, [])

  useEffect(() => {
    if (isLoadingstudentsAnswer) setState('loading')
    else setState('renderListStudentsAnswer')
  }, [isLoadingstudentsAnswer])

  const render: { [key in StateTeacherActivityResultsView]: any } = {
    loading: <Loading />,
    renderListStudentsAnswer: <ListStudentsAnswer />
  }

  return (
    <Grid container paddingTop={2} paddingBottom={isMobile ? 26 : 15}>
      <InfoBar />
      {render[state]}
    </Grid>
  )
}

export default TeacherActivityResultsView
