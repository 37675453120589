import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => createStyles({
  audio: {
    margin: 'auto',
    textAlign: 'center',

    '& audio': {
      maxWidth: '75%',
      width: '75%'
    },
    [theme.breakpoints.down('md')]: {
      '& audio': {
        width: '100%',
        maxWidth: '100%'
      }
    }
  }
}))
