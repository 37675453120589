import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { IAverageEvaluation } from './index'

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: (props: IAverageEvaluation) => props.flexStart ? 'flex-start' : 'flex-end',
      alignSelf: 'center'
    },
    average: {
      background: theme.colors.support.colorSupport01,
      color: theme.colors.neutral.lightBase,
      fontSize: theme.font.fontSize.sm,
      borderRadius: theme.borderRadius.circular,
      textAlign: 'center',
      width: 40,
      height: 40,
      fontWeight: theme.font.fontWeight.bold,
      padding: 8,
      flex: '0 0 auto',
      marginRight: 8,

      '&.outlined': {
        background: 'none',
        width: 'max-content',
        height: 'max-content',
        marginTop: 0,
        color: theme.colors.support.colorSupport01,
        fontWeight: theme.font.fontWeight.medium,
        fontSize: theme.font.fontSize.lg,
        padding: 0
      }
    },
    text: {
      color: theme.colors.neutral.dark40,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium,
      flex: '0 0 auto',
      alignSelf: 'center',
      lineHeight: '17px'
    }
  })
)
