import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { LoadingWrapper } from 'components/common'
import { Disciplines } from 'components/store/Disciplines'
import ListContentUnit from './components/ListContentUnit'
import { useStore, useCurricularStore } from 'store'
import { BusinessError } from 'navigation/BusinessError'
import NotFoundDisciplinesComponent from './components/NotFoundDisciplinesComponent'

export const ContentsPage: React.FC = () => {
  // store
  const { subscription } = useStore()
  const { loadingDisciplines, disciplines } = useCurricularStore()

  const classes = useStyles()
  const { t } = useTranslation()

  // TODO: colocar validação descente
  if (!subscription) {
    return <BusinessError error={t('Erro ao selecionar turma.')} />
  }

  return (
    <div id='page-contents' className={`${classes.studentsContainer} ${!disciplines?.length && 'fullScreen'}`}>
      <LoadingWrapper type='linear' loading={loadingDisciplines}>
        {
          disciplines?.length
            ? <>
              <Disciplines title={t('Exibir por componente curricular')} dataTestid='home_content_select_curriculum_components' />
              <ListContentUnit key='students' />
            </>
            : <NotFoundDisciplinesComponent />
        }
      </LoadingWrapper>
    </div>
  )
}

export default ContentsPage
