import React from 'react'
import Grid from '@mui/material/Grid'
import { Resource } from 'components/common'
import useStyles from './style'
import useStudentAnswer from '../../../../hooks/useStudentAnswer'

const QuestionEnunciate = () => {
  const classes = useStyles()
  const { activity } = useStudentAnswer()

  if (activity?.text ?? activity?.resources?.length) {
    return (
      <Grid container gap='24px' className={classes.enunciate}>
        <div className={classes.utteranceText} dangerouslySetInnerHTML={{ __html: activity.text ?? '' }} />
        {activity?.resources?.map((resource, i) =>
          (<React.Fragment key={resource.id}>
            <Resource resource={resource} />
            <br />
          </React.Fragment>
          ))}
      </Grid>
    )
  }
  return null
}

export default QuestionEnunciate
