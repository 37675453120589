import { makeDatabaseInstanceOffline } from 'contentCacheManager'
import { getProfile } from 'contentCacheManager/utils'

export const getAllAnswersOffline = async () => {
  try {
    const database = makeDatabaseInstanceOffline()
    const { id: profileId } = getProfile()
    if (!profileId) return []
    return (await database.answersCached.toArray()).filter(item => item.userId === String(profileId))
  } catch (error) {
    return []
  }
}
