import React, { useEffect, useMemo } from 'react'
import { Button } from 'components/design-system/Button'
import { Loading } from 'components/common'
import { toast } from 'components/design-system/Toast/manager'
import { DownloadOutlined, Check, Error as ErrorIcon } from '@mui/icons-material'
import { useStore } from 'store'
import {
  IUserSchoolProfileTypeEnum,
  OfflineTableExpeditionCachedStatus,
  useExpeditionCache,
  useOnlineStatus,
  useContentCached,
  removeAllUrlsFromCache,
  deleteExpeditionCached
} from 'contentCacheManager'
import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import makeStyles from './styles'

export type DownloadButtonStatus = 'DOWNLOADING' | 'ERROR' | 'DOWNLOADED' | 'INITIAL'

type DownloadButtonProps = {
  expeditionId: number
  context?: 'LIST' | 'INSIDE'
  dataTestid?: string | null
  customClassName?: string
}

export const DownloadButton: React.FC<DownloadButtonProps> = (
  {
    expeditionId,
    context = 'LIST',
    dataTestid,
    customClassName = ''
  }
) => {
  const { subscription, profile } = useStore()
  const isOnline = useOnlineStatus()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const contentCached = useContentCached()
  const { run, expeditionInCache, changeStatus, clear } = useExpeditionCache({
    expeditionId,
    onSuccess: () => {
      changeStatus(OfflineTableExpeditionCachedStatus.FULL_DOWNLOADED)
    },
    onError: () => {
      changeStatus(OfflineTableExpeditionCachedStatus.ERROR)
      toast.handler({
        content: t('Ocorreu um erro ao tentar baixar um conteúdo. Tente novamente.'),
        duration: 5000,
        severity: 'error'
      })
    }
  })

  const makeStatus = useMemo<DownloadButtonStatus>(() => {
    if (expeditionInCache) {
      switch (expeditionInCache.status) {
        case OfflineTableExpeditionCachedStatus.ERROR:
          return 'ERROR'
        case OfflineTableExpeditionCachedStatus.DOWNLOADING:
        case OfflineTableExpeditionCachedStatus.PARTIAL_DOWNLOADED:
          return 'DOWNLOADING'
        case OfflineTableExpeditionCachedStatus.FULL_DOWNLOADED:
          return 'DOWNLOADED'
        default:
          return 'INITIAL'
      }
    }
    return 'INITIAL'
  }, [expeditionInCache])

  const profileType = subscription?.user_school_profile?.type ?? profile?.type
  const classe = makeStyles()

  const isTeacher = profileType === IUserSchoolProfileTypeEnum.teacher

  const makeContextClass = context === 'INSIDE' ? classe.rootInside : classe.root

  const makeTypeClass = {
    DOWNLOADING: context === 'INSIDE' ? classe.commonInside : classe.common,
    ERROR: context === 'INSIDE' ? classe.errorInside : classe.error,
    DOWNLOADED: context === 'INSIDE' ? classe.downloadedInside : classe.downloaded,
    INITIAL: ''
  }

  const makeLabel = {
    DOWNLOADING: 'Baixando',
    ERROR: 'Tentar novamente',
    DOWNLOADED: 'Remover',
    INITIAL: 'Baixar'
  }

  const makeIcon = {
    DOWNLOADING: Loading,
    ERROR: ErrorIcon,
    DOWNLOADED: Check,
    INITIAL: DownloadOutlined
  }

  const Icon = makeIcon[makeStatus]
  const render = useMemo(() => {
    if (isTeacher || !isMobile || expeditionInCache === undefined) {
      return <></>
    }

    if (!isOnline && makeStatus === 'INITIAL') {
      return <></>
    }

    const makeRun = () => {
      if (makeStatus === 'DOWNLOADED') {
        contentCached.openRemoveModal({
          expeditionId,
          onRemove: () => {
            clear()
            toast.handler({
              content: t('Conteúdo removido com sucesso.'),
              duration: 5000,
              severity: 'success'
            })
          }
        })
      } else {
        run(makeStatus)
      }
    }

    return (
      <Button
        onClick={makeRun}
        variant='outlined'
        className={`${makeContextClass} ${makeTypeClass[makeStatus]} ${customClassName}`}
        startIcon={<Icon />}
        data-testid={dataTestid}>
        {makeLabel[makeStatus]}
      </Button>
    )
  }, [
    isTeacher,
    makeStatus,
    run,
    isOnline,
    expeditionId,
    isMobile,
    expeditionInCache
  ])

  useEffect(() => {
    if (makeStatus === 'ERROR') {
      setTimeout(() => {
        changeStatus(OfflineTableExpeditionCachedStatus.INITIAL)
        deleteExpeditionCached(expeditionId)
        if (expeditionInCache) {
          removeAllUrlsFromCache(expeditionInCache.successUrls)
        }
      }, 10000)
    }
  }, [makeStatus, changeStatus, expeditionId, expeditionInCache])

  return render
}
