import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column !important' as 'column',
    padding: `0 ${theme.spacingInline.nano} ${theme.spacingInline.xxxs}`,

    '& .currentPeriodsContainer': {
      color: theme.colorBrand.medium
    },
    '& .previousPeriods': {
      color: theme.colors.contentType.default
    }
  },
  dropdownContainer: {
    minWidth: '50px',
    flex: 1,

    '& .selectedText': {
      fontSize: `${theme.font.fontSize.sm} !important`
    },

    [theme.breakpoints.up('sm')]: {
      width: '270px'
    }
  },

  optionContainer: {
    cursor: 'pointer',
    fontWeight: `${theme.font.fontWeight.bold} !important`,
    fontSize: theme.font.fontSize.sm,

    margin: `${theme.spacingStack.nano} 0`,

    boxSizing: 'border-box',
    borderRadius: theme.borderRadius.sm,
    border: `3px solid ${theme.colors.neutral.light10}`,
    padding: `${theme.spacingInline.nano}`,
    backgroundColor: theme.colors.neutral.lightBase,

    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    wordWrap: 'break-word',

    '&.active': {
      border: `3px solid ${theme.colorBrand.medium}`
    },
    '&.focused': {
      border: `3px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    },
    '&:hover': {
      transition: 'all ease-in-out 55ms',
      border: `3px solid ${theme.colorBrand.medium}`,
      backgroundColor: 'rgba(231, 210, 230, 0.64);'
    },

    '&.previousPeriods': {
      '&.active': {
        border: `3px solid ${theme.colors.contentType.default}`
      },
      '&.focused': {
        border: `3px solid ${theme.colors.contentType.default}`,
        backgroundColor: 'rgba(105, 118, 145, 0.2);'
      },
      '&:hover': {
        border: `3px solid ${theme.colors.contentType.default}`,
        backgroundColor: 'rgba(105, 118, 145, 0.2);'
      }
    }
  }
}))
