import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    tag: {
      '& svg': {
        marginRight: theme.spacingInline.nano
      }
    },
    tagSchedule: {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      padding: '7px 10px',
      fontSize: '14px',
      minWidth: 'fit-content',
      fontWeight: 500,
      lineHeight: '1em',
      borderRadius: '4px',
      minHeight: '32px',
      '& svg': {
        height: '18px'
      }
    },
    usedTimes: {
      border: `2px solid ${theme.colorBrand.light}`,
      padding: '6px 6px'
    },
    usedInContent: {
      color: theme.colorBrand.medium,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.semibold,
      paddingRight: theme.spacingInset.sm
    }
  }
})
