import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  '@media print': {
    header: {
      display: 'none'
    }
  },
  header: {
    width: '100%',
    padding: theme.spacingInset.xs,
    background: 'white',

    // mobile
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 0,
      zIndex: 4,
      padding: '12px 16px',
      boxShadow: theme.shadowLevels.level1,
      borderRadius: `0px 0px ${theme.borderRadius.md} ${theme.borderRadius.md}`,
      transition: 'top ease-in-out .35s',

      '&.hidden': {
        top: '-80px'
      }
    }
  },
  iconChangeClass: {
    display: 'flex',
    cursor: 'pointer',
    padding: theme.spacingInset.xxs,
    [theme.breakpoints.down('sm')]: {
      paddingBlock: '0px',
      paddingInline: '0px'
    }
  },
  iconSvg: {
    fontSize: theme.font.fontSize.lg,
    color: theme.colorBrand.medium,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.lg
    }
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'baseline'
    }
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center'
  },
  iconMenuContainer: {
    padding: 0,
    marginRight: theme.spacingInline.quarck,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      padding: theme.spacingInset.xs,
      cursor: 'pointer'
    }
  },
  brandHeaderContainer: {
    maxWidth: '200px',
    minWidth: '56px'
  },
  iconMenuSvg: {
    color: theme.colorBrand.medium,
    fontSize: theme.font.fontSize.lg,
    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.lg
    }
  },
  container: {
    borderRight: 'none',
    paddingInline: '0px',
    minWidth: 'fit-content !important',
    width: 'auto !important',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      paddingInline: theme.spacingInline.xxxs,
      justifyContent: 'flex-end'
    }
  },
  classContainer: {
    display: 'flex',
    paddingInline: theme.spacingInline.xxxs,
    borderRight: `1px solid ${theme.colors.neutral.light10}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      paddingInline: '0px'
    }
  },
  configsContainer: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  avatar: {
    paddingRight: theme.spacingInline.nano,
    paddingLeft: theme.spacingInline.xxxs,
    padding: `12px ${theme.spacingInline.nano} 0px ${theme.spacingInline.nano}`,
    [theme.breakpoints.down('sm')]: {
      '& .avatarItem': {
        width: '32px',
        height: '32px',
        fontSize: '14px'
      },
      paddingTop: theme.spacingInline.quarck
    }
  },
  userInfo: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 0,
      maxHeight: '40px',
      overflow: 'hidden',
      transition: 'all ease-in-out .3s',
      '&.opened': {
        paddingRight: theme.spacingInline.nano,
        maxWidth: '130px !important'
      }
    },
    maxWidth: 0,
    transition: 'all ease-in-out .3s',
    overflow: 'hidden',
    textAlignLast: 'right',
    maxHeight: 0,
    '&.opened': {
      maxWidth: '30px',
      marginLeft: '12px',
      maxHeight: '40PX'
    }
  },
  userInfoName: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      fontWeight: theme.font.fontWeight.bold,
      fontSize: theme.font.fontSize.xs,
      lineHeight: theme.font.lineHeight.xs,
      color: theme.colors.neutral.dark40,
      overflow: 'hidden',
      width: 'max-content'
    }
  },
  userInfoLogout: {
    fontWeight: theme.font.fontWeight.semibold,
    fontSize: theme.font.fontSize.xxs,
    lineHeight: '100%',
    color: theme.colorBrand.medium,
    cursor: 'pointer',
    paddingTop: '5px'
  },
  userLinkProfile: {
    paddingTop: '10px',
    paddingInline: '8px',
    [theme.breakpoints.up('sm')]: {
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs,
      lineHeight: '100%',
      color: theme.colorBrand.medium,
      cursor: 'pointer',
      paddingTop: '20px'
    }
  }
}))
