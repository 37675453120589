import { ModalWrapper, CheckSelector } from 'components/common'
import { activityRemovedQuestionsAtom, openActivityQuestionsSelectModalAtom, updateContentSelectAtom } from '../atomStore'
import { useAtom } from 'jotai'
import { Grid, useTheme, useMediaQuery } from '@mui/material'
import { Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
import { useAtomValue } from 'jotai/utils'
import { toast } from 'components/design-system/Toast/manager'

import useStyles from './style'
import { useCallback, useEffect, useState } from 'react'
import { IQuestionResponse } from 'contentCacheManager'
import QuestionResource from 'pages/ContentUnit/components/Activity/common/ActivityBody/components/QuestionList/components/Question/components/QuestionResource'

export const ActivityQuestionsSelectModal = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [openModal, setOpenModal] = useAtom(openActivityQuestionsSelectModalAtom)
  const contentSelectAtom = useAtomValue(updateContentSelectAtom)
  const [activityRemovedQuestions, setActivityRemovedQuestions] = useAtom(activityRemovedQuestionsAtom)

  const [temporaryActivityRemovedQuestions, setTemporaryActivityRemovedQuestions] = useState(activityRemovedQuestions)
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestionResponse | null>(null)

  // Assuming there is only one activity
  const activityData = contentSelectAtom ? contentSelectAtom[0] : null
  const activityOriginalTitle = contentSelectAtom ? contentSelectAtom[0]?.title : ''
  const availableQuestions = contentSelectAtom ? contentSelectAtom[0]?.questions ?? [] : []
  const isActivity = activityData?.suggestedApplicationType === 'activity'

  const selectedQuestionsText = availableQuestions?.map((question, index) => temporaryActivityRemovedQuestions.includes(String(question.id)) ? null : index + 1).filter(el => !!el).join(', ')
  const hasSelectedQuestions = temporaryActivityRemovedQuestions.length < availableQuestions?.length

  useEffect(() => {
    if (!availableQuestions) {
      return
    }

    setSelectedQuestion(availableQuestions[0])
    setTemporaryActivityRemovedQuestions(activityRemovedQuestions)
  }, [openModal])

  const handleCancelModal = () => {
    setTemporaryActivityRemovedQuestions([])
    setOpenModal(false)
  }

  const handleConfirmModal = () => {
    if (availableQuestions?.length === temporaryActivityRemovedQuestions?.length) {
      toast.handler({
        content: t('Você deve selecionar ao menos uma questão!'),
        duration: 5000,
        severity: 'warning'
      })

      return
    }

    setActivityRemovedQuestions(temporaryActivityRemovedQuestions)
    setOpenModal(false)
  }

  const addQuestionToRemovedList = (questionId: string | number) => {
    setTemporaryActivityRemovedQuestions(old => [...old, String(questionId)])
  }

  const removeQuestionFromRemovedList = (index: number) => {
    setTemporaryActivityRemovedQuestions(old => {
      const arr = old.filter((_, elIndex) => index !== elIndex)
      return arr
    })
  }

  const handleCheckQuestion = (question: IQuestionResponse) => {
    const removedQuestionIndex = temporaryActivityRemovedQuestions.findIndex((questionId) => questionId === String(question.id))
    const isAlreadyRemoved = removedQuestionIndex === -1

    if (isAlreadyRemoved) {
      addQuestionToRemovedList(question.id)
      return
    }

    removeQuestionFromRemovedList(removedQuestionIndex)
  }

  const handleSelectQuestion = (question: IQuestionResponse) => {
    setSelectedQuestion(question)
  }

  const SelectedQuestionsText = useCallback(() => {
    if (hasSelectedQuestions) {
      return (
        <Text2
          fontSize='xs'
          fontWeight='regular'
          lineHeight='xs'
          mobile='xs'
          customColor={theme.colors.neutral.dark40}
        >
          {t('Incluídas na {tipo_atividade}: {numeros}', { type: isActivity ? t('atividade') : t('avaliação'), numbers: selectedQuestionsText })}
        </Text2>
      )
    }

    return (
      <Text2
        fontSize='xs'
        fontWeight='regular'
        lineHeight='xs'
        mobile='xs'
        customColor={theme.colors.negative.feedbackMedium}
      >
        {t('Selecione ao menos uma questão.')}
      </Text2>
    )
  }, [hasSelectedQuestions, isActivity, selectedQuestionsText])

  if (!availableQuestions) {
    return <></>
  }

  return (
    <ModalWrapper
      open={openModal}
      onCancel={handleCancelModal}
      onConfirm={handleConfirmModal}
      maxWidth='lg'
      footer={{
        background: theme.colors.neutral.light10
      }}
      customClassName={classes.modalContainer}
      fullscreen={isMobile}
    >
      <Text2
        fontSize='lg'
        fontWeight='semibold'
        lineHeight='xs'
        mobile='lg'
        customColor={theme.colorBrand.medium}
      >
        {isActivity ? t('Configurar atividade') : t('Configurar avaliação')}
      </Text2>
      <Text2
        fontSize='sm'
        fontWeight='medium'
        lineHeight='sm'
        mobile='sm'
        sx={{ textAlign: 'justify' }}
        customColor={theme.colors.neutral.dark20}
      >
        {t('Clique sobre o símbolo ')}
        <CheckIcon sx={{ position: 'relative', fontSize: theme.font.fontSize.md, color: theme.colorBrand.medium }} />
        {isActivity
          ? t(' junto ao número da questão para removê-la da atividade. As questões removidas não serão enviadas à turma, mas permanecem disponíveis na versão original.')
          : t(' junto ao número da questão para removê-la da avaliação. As questões removidas não serão enviadas à turma, mas permanecem disponíveis na versão original.')}
      </Text2>

      <Grid className={classes.questionsContainer}>
        <Text2
          fontSize='lg'
          fontWeight='semibold'
          lineHeight='xs'
          mobile='lg'
          customColor={theme.colorBrand.medium}
        >
          {activityOriginalTitle}
        </Text2>

        <Grid>
          <Text2
            fontSize='xs'
            fontWeight='semibold'
            lineHeight='xs'
            mobile='xs'
            customColor={theme.colorBrand.medium}
          >
            {t('Questões')}:
          </Text2>

          <Grid className={classes.questionsSelectorContainer}>
            {availableQuestions?.map((question, index) => {
              const isCheckActive = !temporaryActivityRemovedQuestions.includes(String(question.id))
              const isLabelActive = selectedQuestion?.id === question.id

              return (
                <CheckSelector
                  key={question.id}
                  isLabelActive={isLabelActive}
                  isCheckActive={isCheckActive}
                  label={`${index + 1}`}
                  handleCheck={() => handleCheckQuestion(question)}
                  handleLabelClick={() => handleSelectQuestion(question)}
                />
              )
            })}
          </Grid>
          <Grid sx={{ marginTop: theme.spacingStack.nano }}>
            <SelectedQuestionsText />
          </Grid>
        </Grid>
        {
          selectedQuestion && <QuestionResource question={selectedQuestion} />
        }
      </Grid>
    </ModalWrapper>
  )
}
