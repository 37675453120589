import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    studentAnswerContainer: {
      paddingTop: theme.spacingStack.xxxs,
      paddingBottom: theme.spacingStack.xxxs
    },
    studentAnswerContentNoAnswer: {
      color: theme.colors.neutral.light40,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.sm,
      fontStyle: 'italic',
      padding: theme.spacingInline.xxs
    },
    studentAnswerContentTextResource: {
      padding: theme.spacingInline.xxs,
      backgroundColor: 'rgba(191, 178, 211, 0.16)',
      borderRadius: theme.borderRadius.sm,
      color: theme.colorBrand.dark,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.sm
    }
  })
)
