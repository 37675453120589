import { ExpeditionCachedTable } from '../../'
import { makeDatabaseInstanceOffline } from 'contentCacheManager'

export const addSuccessRequestToExpeditionCached = async (url: string | string[], expedition: number | ExpeditionCachedTable) => {
  let currentExpedition
  if (expedition instanceof ExpeditionCachedTable) {
    currentExpedition = expedition
  } else {
    currentExpedition = await makeDatabaseInstanceOffline().expeditionsCached.where('expeditionId').equals(expedition).first()
  }

  if (currentExpedition) {
    let newData: string[] = []
    if (Array.isArray(url)) {
      newData = [...currentExpedition.successUrls, ...url]
    } else {
      newData = [...currentExpedition.successUrls, url]
    }

    return await makeDatabaseInstanceOffline().expeditionsCached.update(Number(currentExpedition.id), {
      successUrls: Array.from(new Set(newData))
    })
  }
}
