import React from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '../Loading'
import useStyles from './style'

interface ILoadingWrapperProps {
  loading: boolean
  description?: string
  fillContainer?: boolean
  type?: 'circular' | 'linear'
}

export const LoadingWrapper: React.FC<ILoadingWrapperProps> = ({ loading, description, fillContainer = false, type, children }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const fillContainerClass = fillContainer ? classes.fillContainer : classes.basicFillContainer

  return (
    loading
      ? (
        <div className={fillContainerClass}>
          <Loading type={type} />
          {t(description ?? '')}
        </div>
      )
      : (
        <>
          {children}
        </>
      )
  )
}

export default LoadingWrapper
