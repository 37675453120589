interface IConvertArrToGETParams {
  rawURL: string
  paramsObj: {
    [key: string]: string | number | undefined | boolean
  }
}

export const convertArrToGETParams = ({ rawURL, paramsObj }: IConvertArrToGETParams) => {
  const url = new URL(rawURL)

  Object.entries(paramsObj).forEach(
    ([key, value]) => value != null && typeof value !== 'undefined' && url.searchParams.set(key, String(value))
  )

  return url.href
}
