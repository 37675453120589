import { Grid } from '@mui/material'
import useStyles from '../AuthLayoutBox/styles'
import brandImg from 'assets/cloe-brand.svg'
import { authLoadingAtom } from 'pages/atomStore'
import { useAtom } from 'jotai'

interface AuthLayoutBoxProps {
  children: React.ReactNode
}

export const AuthLayoutBox = ({ children }: AuthLayoutBoxProps) => {
  const styles = useStyles()

  const [isLoading] = useAtom(authLoadingAtom)

  return (
    <>
      <div className={styles.rootAuthContainer}>
        <Grid container className={styles.brandContainer} sx={{ opacity: isLoading ? '0.36' : '1' }}>
          <Grid item xs={12}>
            <img src={brandImg} alt='' />
          </Grid>
        </Grid>
        {children}
      </div>
    </>
  )
}
