import React from 'react'
import { BusinessError } from 'navigation/BusinessError'

interface IErrorWrapperProps {
  hasError: boolean
  errorMessage?: string
  errorComponent?: React.ReactNode
}

export const ErrorWrapper: React.FC<IErrorWrapperProps> = ({ hasError, errorMessage, errorComponent, children }) => {
  return hasError
    ? <BusinessError error={errorMessage ?? ''}>
      {errorComponent}
    </BusinessError>
    : <React.Fragment>{children}</React.Fragment>
}

export default ErrorWrapper
