import { useTranslation } from 'react-i18next'
import { Grid, useTheme } from '@mui/material'
import { Text, Icon } from 'components/design-system'
import BookmarkIcon from '@mui/icons-material/Bookmark'

import useStyles from './style'
import { IContentUnitBookMarker } from 'services/types'

export interface IBookmarkProps {
  data?: IContentUnitBookMarker
}

export const Bookmark: React.FC<IBookmarkProps> = ({
  data
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  if (!data) {
    return <></>
  }

  return (
    <Grid container className={classes.bookmarkContainer}>
      <Icon
        titleAccess={t('ícone de marcador de unidade de conteúdo')}
        size='medium'
        iconColor={theme.colorBrand.medium}
      >
        <BookmarkIcon />
      </Icon>

      <Text
        className={classes.bookmarkTitle}
      >
        {data.title}
      </Text>
    </Grid>
  )
}
