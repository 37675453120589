import { convertArrToGETParams } from 'utils/url'

export const GRADE_TYPE_SCHOOL = () => `${process.env.REACT_APP_BACKEND_URL as string}/grade-types/school/all`

export const DISCIPLINES_GRADE = (gradeid: number, schoolPeriodId?: number, classActive?: boolean) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/classes/grade/${gradeid}/disciplines`
  const paramsObj = { active: classActive, school_period: schoolPeriodId }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const DISCIPLINES_MULTIGRADE = (classId: number, schoolPeriodId?: number, classActive?: boolean) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/classes/${classId}/grade/multigrades/disciplines`
  const paramsObj = { active: classActive, school_period: schoolPeriodId }

  return convertArrToGETParams({ rawURL, paramsObj })
}

export const SUBSCRIPTIONS_TAUGHT_DISCIPLINES = (schoolPeriodId?: number) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/classes/subscriptions/taught-disciplines`
  const paramsObj = { schoolPeriod: schoolPeriodId }

  return convertArrToGETParams({ rawURL, paramsObj })
}
