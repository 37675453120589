import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  dropdownContainer: {
    [theme.breakpoints.down('lg')]: {
      flex: 1,
      width: '100%'
    }
  }
}))
