import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.colorBrand.medium,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xl,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: '40px',
      margin: '0 0 40px 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.lg,
        lineHeight: theme.font.lineHeight.sm,
        marginBottom: theme.spacingStack.xxs
      }
    },
    RecordContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      }
    },
    RecordTitle: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xl,
      paddingBlock: theme.spacingInline.nano,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.md
      }
    },
    currentLevel: {
      color: theme.colors.support.colorSupport01,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xl,
      paddingInline: theme.spacingInline.nano
    },
    RecordText: {
      color: theme.colors.neutral.dark40,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xs,
      maxWidth: theme.spacingInline.lg,
      lineHeight: theme.font.lineHeight.xxs
    },
    text: {
      color: theme.colors.neutral.dark30,
      fontFamily: theme.font.fontFamily,
      fontWeight: theme.font.fontWeight.regular,
      fontSize: theme.font.fontSize.sm,
      lineHeight: theme.font.lineHeight.sm,
      paddingTop: 0,
      marginTop: 0,
      '& > a': {
        color: theme.colorBrand.medium,
        fontWeight: theme.font.fontWeight.bold
      }
    },
    nextButton: {
      width: '15rem',
      height: '2rem',
      '& > svg': {
        marginLeft: '1rem'
      }
    },
    thumbGrid: {
      display: 'inline-table',
      width: '100%',
      maxWidth: '121px'
    },
    containerThumbGrid: {
      width: '100%'
    },
    uploadFileWrapper: {
      padding: `${theme.spacingInline.xxxs} ${theme.spacingInline.xs}`,
      border: '1px solid #CCCCCC',
      borderRadius: '16px',
      textAlign: 'center',
      display: 'flex',
      gap: '1rem',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      // mobile
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacingInline.xxxs
      }
    }
  })
)
