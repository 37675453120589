import React from 'react'
import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { InsertDriveFileOutlined, PlayCircleOutlined, PictureAsPdf } from '@mui/icons-material'
import ExpandableImage from 'components/common/ExpandableImage'
import { AudioPlayer } from 'components/common'
interface IAnsweredFileProps {
  src: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  linkWrapper: {
    width: '100%',
    height: '100%'
  },
  fileWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.font.fontSize.xxxl,
    background: theme.colors.neutral.light20,
    width: '100%',
    minHeight: '121px',
    backgroundImage: ({ src }: { src: string }) => `url(${src})`,
    backgroundSize: 'cover',
    borderRadius: theme.borderRadius.sm,

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxWidth: '121px',
      maxHeight: '121px'
    },

    '& video': {
      width: '100%',
      borderRadius: theme.borderRadius.sm
    },
    '& svg': {
      zIndex: 4,
      position: 'absolute',
      color: 'white',
      fontSize: theme.font.fontSize.xxxl,
      filter: 'drop-shadow(1px 1px 2px rgb(179 179 179 / 0.6))'
    }
  },
  fileWrapperLayer: {
    zIndex: 3,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.colors.neutral.light20,
    borderRadius: theme.borderRadius.sm,
    mixBlendMode: 'multiply'
  },
  answeredFileExpandableImage: {
    height: '100% !important',

    [theme.breakpoints.down('sm')]: {
      height: 'min-content'
    }
  }
}))

export const AnsweredFile: React.FC<IAnsweredFileProps> = ({ src }) => {
  const classes = useStyles({ src: src })

  const urlExtension = src.split('.')[src.split('.').length - 1]

  const renderFormat = () => {
    switch (urlExtension) {
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ogg':
        return (
          <AudioPlayer url={src} />
        )
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'webm':
        return (
          <a target='_blank' href={src} className={classes.linkWrapper}>
            <div className={classes.fileWrapper}>
              <div className={classes.fileWrapperLayer} />
              <video muted>
                <source src={src} type={`video/${urlExtension}`} />
              </video>
              <PlayCircleOutlined />
            </div>
          </a>
        )
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'png':
      case 'jpeg':
        return (
          <ExpandableImage key={src} src={src} image={src} className={classes.answeredFileExpandableImage} />
        )
      case 'pdf':
        return (
          <a target='_blank' href={src} className={classes.linkWrapper}>
            <div className={classes.fileWrapper}>
              <PictureAsPdf />
            </div>
          </a>
        )
      default:
        return (
          <a target='_blank' href={src} className={classes.linkWrapper}>
            <div className={classes.fileWrapper}>
              <InsertDriveFileOutlined />
            </div>
          </a>
        )
    }
  }

  return renderFormat()
}

export default AnsweredFile
