import { useTranslation } from 'react-i18next'

import { Button } from 'components/design-system'
import { useStore } from 'store'
import { IUserSchoolProfileTypeEnum } from 'services/types'

interface IReadyState {
  loadMore: () => Promise<void>
}

export const Ready = ({ loadMore }: IReadyState) => {
  const { profile } = useStore()
  const dataTestId = profile?.type === IUserSchoolProfileTypeEnum.teacher ? 'activities_to_correct_load_more' : 'activities_to_answer_load_more'
  const { t } = useTranslation()

  return (
    <Button
      variant='outlined'
      onClick={loadMore}
      data-testid={dataTestId}
    >
      {t('Carregar mais...')}
    </Button>
  )
}
