export const randomElement: <T>(array: T[]) => T = array => array[Math.floor(Math.random() * array.length)]

export const flatDeep = (arr: any[], depth = 1): any[] => {
  if (!arr) return []

  if (depth <= 0) {
    return arr.slice()
  }

  return arr.reduce((acc, val) => acc.concat(
    Array.isArray(val) ? flatDeep(val, depth - 1) : val
  ), [])
}

export const cloneObject = (obj: Object) => JSON.parse(JSON.stringify(obj))
