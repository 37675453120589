import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.colorBrand.medium,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xl,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: '40px',
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        fontSize: theme.font.fontSize.lg,
        fontWeight: theme.font.fontWeight.semibold,
        lineHeight: '32px',
        marginBottom: '24px'
      }
    },
    customTypeLabel: {
      display: 'flex',
      fontStyle: 'italic',
      gap: theme.spacingInline.nano,
      color: theme.colorBrand.light,
      fontWeight: theme.font.fontWeight.bold,
      fontSize: theme.font.fontSize.lg,
      letterSpacing: theme.font.spacing.distant,
      lineHeight: '40px',
      margin: 0,
      marginBottom: 0,

      [theme.breakpoints.down('md')]: {
        fontSize: theme.font.fontSize.lg,
        lineHeight: theme.font.lineHeight.sm
      }
    }
  })
)
