import { ConfirmationModal } from 'components/common'
import { useTranslation } from 'react-i18next'

interface IProps {
  showModal: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const BackToLoginConfirmationModal = ({ showModal, onConfirm, onCancel }: IProps) => {
  const { t } = useTranslation()
  // const styles = useStyles()
  return (
    <ConfirmationModal
      onConfirmModal={onConfirm}
      onCloseModal={onCancel}
      isOpen={showModal}
      title={t('Tem certeza que deseja sair?')}
      description={t('As informações preenchidas serão descartadas.')}
    />
  )
}
