import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { toast } from 'components/design-system/Toast/manager'
import { AnswerCacheTable, getAllAnswersOffline, IAnswerCachedStatus } from 'contentCacheManager/localDatabaseManager'
import { IFileUploadResponse } from 'services/types/upload'
import { UploadApiService } from 'services/upload'
import { updateAnswerOffline } from 'contentCacheManager/localDatabaseManager/data/answers/updateAnswerOffline'
import Analytics from 'utils/analytics'
import { useStore } from 'store'
import { editAnswerRecord, saveAnswerRecord } from 'services/answer'

export const useSendAnswersCachedToServer = () => {
  const { t } = useTranslation()
  const { profile } = useStore()

  const fireGAnalyticsEvent = (eventName: string, activityId: string) => {
    Analytics.recordEventClick({
      name: eventName,
      attributes: {
        ...profile?.analytics,
        activity_id: activityId
      }
    })
  }

  const run = useCallback(async () => {
    try {
      const allAnswers = await getAllAnswersOffline()
      const senderData: AnswerCacheTable[] = []
      const allAnswersFiltered = allAnswers.filter(answer => [IAnswerCachedStatus.INITIAL, IAnswerCachedStatus.SENT_ERROR].includes(answer.status))

      if (!allAnswersFiltered.length) return

      toast.handler({
        content: t('Sua conexão voltou e suas respostas já estão sendo enviadas.'),
        severity: 'success',
        duration: 5000
      })

      toast.handler({
        content: 'Começamos a enviar suas respostas.',
        duration: 5000,
        severity: 'info'
      })

      for (const answer of allAnswersFiltered) {
        const answersOfQuestion = answer

        const filesUploaded = await Promise.all(answer.filesOffline.map(async (offlineFile) => {
          const data = offlineFile.files.map(({ file, fileName }) => {
            return new File([file], fileName ?? '')
          })
          let fileUploadRes: IFileUploadResponse[] = []

          const fileUploadService = new UploadApiService(data, null)
          fileUploadRes = await fileUploadService.uploadMultipleFiles()

          return {
            ...offlineFile,
            fileResponse: fileUploadRes
          }
        }))

        filesUploaded.forEach(fileUploaded => {
          const answerSavedIndex = answersOfQuestion.requestAnswer.answers.findIndex(answerItem => Number(answerItem.question_id) === fileUploaded.question_id)
          if (answerSavedIndex >= 0) {
            answersOfQuestion.requestAnswer.answers[answerSavedIndex].records[0].file_records = fileUploaded.fileResponse.map(({ transaction_id, url }) => ({
              transaction_id,
              upload_id: null,
              url
            }))
          } else {
            answersOfQuestion.requestAnswer.answers.push({
              question_id: String(fileUploaded.question_id),
              records: [{
                file_records: fileUploaded.fileResponse.map(({ transaction_id, url }) => ({
                  transaction_id,
                  upload_id: null,
                  url
                }))
              }]
            })
          }
        })

        senderData.push(answersOfQuestion)
      }

      const resultSender = await Promise.all(senderData.map(async data => data.isEditing ? await editAnswerRecord(data.requestAnswer) : await saveAnswerRecord(data.requestAnswer)))
      resultSender.forEach((response, index) => {
        const paramsAnswer = senderData[index].requestAnswer
        if (response.success) {
          updateAnswerOffline({
            ...paramsAnswer
          }, {
            status: IAnswerCachedStatus.SENT_SUCCESS,
            requestAnswer: {
              ...paramsAnswer,
              answers: response.data?.answers
                ? response.data.answers.map(answer => ({
                  question_id: `${answer.question_id}`,
                  records: answer.records?.map(record => ({
                    id: record.id,
                    source_type: Number(record.source_type),
                    file_records: record.file_records,
                    choice_record: record.choice_record,
                    text_record: record.text_record
                  })),
                  id: answer.id
                }))
                : paramsAnswer.answers
            }
          })

          fireGAnalyticsEvent('answer_activity_offline', paramsAnswer.activity)

          toast.handler({
            content: 'Resposta enviada com sucesso',
            duration: 3000,
            severity: 'success'
          })
        } else {
          updateAnswerOffline({
            ...paramsAnswer
          }, {
            status: IAnswerCachedStatus.SENT_ERROR
          })
          fireGAnalyticsEvent('send_activity_error', paramsAnswer.activity)
        }
      })
      const allSuccess = resultSender.reduce((acc, next) => acc && next.success, true)
      if (allSuccess) {
        toast.handler({
          content: 'Enviamos todas as repostas com sucesso.',
          duration: 5000,
          severity: 'success'
        })
      } else {
        toast.handler({
          content: 'Não foi possível enviar algumas respostas, por favor verifique e tente novamente',
          duration: 5000,
          severity: 'warning'
        })
      }
    } catch (error) {
      toast.handler({
        content: 'Houve um problema ao enviar suas repostas offline por gentileza verifique-as',
        duration: 10000,
        severity: 'error'
      })
      throw error
    }
  }, [])

  return {
    run
  }
}
