import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import useStyles from '../../style'
import { Button } from 'components/design-system'
import { useCurricularStore } from 'store'
import { useEffect, useState } from 'react'
import { BusinessError } from 'navigation/BusinessError'

type StateContents = 'idle' | 'notFoundProgram' | 'notFoundDisciplines'

const NotFoundDisciplinesComponent = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { disciplines } = useCurricularStore()
  const [state, setState] = useState<StateContents>('idle')

  useEffect(() => {
    if (disciplines !== null && disciplines?.length === 0) setState('notFoundDisciplines')
    setState('notFoundProgram')
  }, [])

  const render: { [key in StateContents]: any } = {
    idle: <></>,
    notFoundDisciplines: (
      <BusinessError error={t('Sem componentes vinculados a essa turma')}>
        <div className={classes.boxButton}>
          <NavLink to='/' style={{ textDecoration: 'none' }} >
            <Button className={classes.button} variant='outlined' type='submit' id='error_back_to_home'>
              {t('Voltar para Página Inicial')}
            </Button>
          </NavLink>
          <Button
            className={classes.button}
            variant='primary'
            type='submit'
            onClick={() => window.location.reload()}
            id='error_try_again'
          >
            {t('Tentar novamente')}
          </Button>
        </div>
      </BusinessError>
    ),
    notFoundProgram: (
      <BusinessError error={t('Não conseguimos carregar o conteúdo para você')}>
        <div className={classes.boxButton}>
          <NavLink to='/' style={{ textDecoration: 'none' }} >
            <Button className={classes.button} variant='outlined' type='submit' id='error_back_to_home'>
              {t('Voltar para Página Inicial')}
            </Button>
          </NavLink>
        </div>
      </BusinessError>
    )
  }

  return render[state]
}

export default NotFoundDisciplinesComponent
