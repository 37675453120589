import { useAtom } from 'jotai'
import { getSkills } from 'services/content-unit'
import { hasResponseErrorAtom, skillsAtom } from '../atomStore'

export const useSkills = (contentId: number) => {
  const [hasResponseError, setHasResponseError] = useAtom(hasResponseErrorAtom)
  const [skills, setSkills] = useAtom(skillsAtom)

  const get = async () => {
    const response = await getSkills(contentId)
    if (response.success) {
      setSkills(response.data)
    } else {
      setHasResponseError(true)
    }
  }

  return {
    get,
    hasResponseError,
    skills
  }
}
