/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useState, useEffect } from 'react'
import {
  RemoveExpeditionModal,
  OnlineStatusProvider,
  getExpeditionCached,
  OfflineTableExpeditionCachedStatus,
  deleteExpeditionCached,
  removeAllUrlsFromCache,
  getCurrentWorking,
  removeCurrentWorking,
  useOnlineStatus
} from 'contentCacheManager'
import { useAuth } from 'navigation/ProvideAuth'
import { useSendAnswersCachedToServer } from 'contentCacheManager/hooks/useSendAnswersCachedToServer'

export type ExpeditionRemoveData = {
  expeditionId: number
  onRemove?: () => void
}

type ContentCacheProviderValue = {
  openRemoveModal: (data: ExpeditionRemoveData) => void
  closeRemoveModal: () => void
}

const initialState: ContentCacheProviderValue = {
  openRemoveModal: (data: ExpeditionRemoveData) => { },
  closeRemoveModal: () => {
    // This is intentional
  }
}

const ContentCacheContext = React.createContext<ContentCacheProviderValue>(initialState)

export const ContentCacheProvider: React.FC = (
  {
    children
  }
) => {
  const [expeditionToRemove, setExpeditionToRemove] = useState<ExpeditionRemoveData | undefined>()
  const [isRunningColector, setIsRunningColector] = useState(false)
  const auth = useAuth()
  const isOnline = useOnlineStatus()
  const { run } = useSendAnswersCachedToServer()

  const openRemoveModal = useCallback((data: ExpeditionRemoveData) => {
    setExpeditionToRemove(data)
  }, [])

  const closeRemoveModal = useCallback(() => {
    setExpeditionToRemove(undefined)
  }, [])

  const removeExpeditionProps = expeditionToRemove ?? { expeditionId: 0 }

  useEffect(() => {
    const checkLocalStorage = () => {
      const currentExpeditionWorking = getCurrentWorking('expeditionId')
      if (currentExpeditionWorking) {
        const expeditionInCacheDelete = async () => {
          const expeditionInCache = await getExpeditionCached(Number(currentExpeditionWorking))
          if (expeditionInCache &&
            [
              OfflineTableExpeditionCachedStatus.DOWNLOADING,
              OfflineTableExpeditionCachedStatus.PARTIAL_DOWNLOADED,
              OfflineTableExpeditionCachedStatus.REMOVING
            ].includes(expeditionInCache.status)) {
            await removeAllUrlsFromCache(expeditionInCache.successUrls.concat(expeditionInCache.embeddedUrls))
            await deleteExpeditionCached(Number(currentExpeditionWorking))
            removeCurrentWorking('expeditionId')
          }
        }

        expeditionInCacheDelete()
      }
    }
    checkLocalStorage()
  }, [])

  const offlineCollector = useCallback(async () => {
    if (isRunningColector) return

    try {
      if (!isOnline) return
      setIsRunningColector(true)
      await run()
    } catch (error) {
      console.error('Envio automático erro:', error)
    } finally {
      setIsRunningColector(false)
    }
  }, [])

  useEffect(() => {
    if (auth?.user) {
      offlineCollector()
      window.addEventListener('online', () => { offlineCollector() })

      return () => {
        window.removeEventListener('online', () => { offlineCollector() })
      }
    }
  }, [auth?.user])

  return (
    <ContentCacheContext.Provider value={{
      closeRemoveModal,
      openRemoveModal
    }}>
      <OnlineStatusProvider>

        {children}
        <RemoveExpeditionModal {...removeExpeditionProps} />
      </OnlineStatusProvider>
    </ContentCacheContext.Provider>
  )
}

export const useContentCached = () => useContext(ContentCacheContext)
