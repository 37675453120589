import { useEffect } from 'react'
import { getDisciplinesByProfile } from 'services/discipline'
import { IContentUnitResponse, IDisciplineResponse, IDisciplineStore } from 'services/types'
import { SetState } from 'zustand'
import { StoreProps, IGrade, ISegment } from './interfaces'
import { useLocalCurricularStore } from './localCurricularStore'
import { useSessionCurricularStore } from './sessionCurricularStore'
import { getClassById } from 'services/class'

interface ISetStoreDisciplines {
  theClass?: number | null
  disciplines?: IDisciplineResponse[]
}

export const useCurricularStore = () => {
  const localStore = useLocalCurricularStore()
  const sessionStore = useSessionCurricularStore()

  useEffect(() => {
    syncSessionStorage()
  }, [])

  const syncSessionStorage = () => {
    if (!sessionStore.newTab) {
      return
    }

    const { disciplines, currentDiscipline, classId, currentContentUnit, loadingDisciplines } = localStore

    sessionStore.syncData({ disciplines, currentDiscipline, classId, currentContentUnit, loadingDisciplines, newTab: false })
  }

  const reset = () => {
    sessionStore.reset()
    localStore.reset()
  }

  const updateStorage: SetState<StoreProps | any> = (props) => {
    sessionStore.syncData(props)
    localStore.syncData(props)
  }

  const setCurrentContentUnit = async (contentUnit: IContentUnitResponse) => {
    updateStorage({ currentContentUnit: contentUnit })

    // validate Selected Discipline

    let availableDisciplines = sessionStore.disciplines ?? []

    let contentUnitDisciplineToSelect = availableDisciplines.find(discipline => discipline.content_units?.includes(contentUnit.id))

    const classId = sessionStore.classId

    if (!contentUnitDisciplineToSelect && classId) {
      const resultDisciplines = await setStoreDisciplines({ theClass: Number(classId) })
      availableDisciplines = resultDisciplines ?? []
    }

    contentUnitDisciplineToSelect = availableDisciplines.find(discipline => discipline.content_units?.includes(contentUnit.id))

    updateStorage({ currentDiscipline: contentUnitDisciplineToSelect })
  }

  const setStoreDisciplines = async ({ theClass, disciplines }: ISetStoreDisciplines): Promise<IDisciplineStore[] | null> => {
    if (disciplines) {
      updateStorage({ loadingDisciplines: true, currentDiscipline: null, disciplines: [] })
      updateStorage({ disciplines, classId: theClass, currentDiscipline: null, loadingDisciplines: false, currentClass: null })
      return disciplines
    }

    const storeClass = sessionStore.classId
    if (theClass && theClass !== storeClass) {
      updateStorage({ loadingDisciplines: true, currentDiscipline: null, disciplines: [] })
      const response = await getDisciplinesByProfile(theClass)

      if (response.success) {
        const responseClass = await getClassById(theClass)

        const disciplinesResponse = response.data
        updateStorage({ disciplines: disciplinesResponse, classId: theClass, currentDiscipline: null, loadingDisciplines: false, currentClass: responseClass?.data })
        return disciplinesResponse
      } else {
        updateStorage({ disciplines: null, classId: null, currentDiscipline: null, loadingDisciplines: false, currentClass: null })
      }
    }

    return null
  }

  const setSegmentAndGrade = (segment: ISegment, grade: IGrade) => {
    updateStorage({ ...sessionStore, currentSegment: segment, currentGrade: grade })
  }

  return ({
    ...sessionStore,
    setCurrentContentUnit,
    setStoreDisciplines,
    setSegmentAndGrade,
    reset
  })
}
