import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => {
  return {
    container: {
      padding: '16px'
    },
    image: {
      margin: '0 auto',
      maxWidth: '500px',
      '& img': {
        textAlign: 'center',
        width: '100%'
      }
    },
    message: {
      color: theme.colors.neutral.dark30,
      fontSize: theme.font.fontSize.sm,
      fontWeight: theme.font.fontWeight.medium,
      maxWidth: '675px',
      textAlign: 'center'
    }
  }
})
