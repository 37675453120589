import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { Grid } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import { Text, Button } from 'components/design-system'
import SubscriptionCard from 'components/common/SubscriptionCard'
import { EmptyStateComponent } from 'components/common'
import { SchoolPeriodSelector, SegmentSelector } from './components'
import { MyClassesHeader } from '../MyClassesHeader'
import { ReactComponent as AvatarWithCrossedArms } from 'assets/avatar-with-crossed-arms.svg'

import { IGradeTypeEnum } from 'services/types/grade'
import { ISubscriptionsTaughtDisciplinesResponse, IGradeResponse } from 'services/types/taught-disciplines'

import { useCurricularStore, useStore } from 'store'
import { DASHBOARD_TEACHER, TAUGHT_DISCIPLINES } from 'navigation/CONSTANTS'

import useStyle from './styles'

interface IReadyState {
  taughtDisciplines: ISubscriptionsTaughtDisciplinesResponse[]
  grades: IGradeResponse[]
}

export const ReadyState = ({ taughtDisciplines, grades }: IReadyState) => {
  const { schoolPeriod } = useStore()
  const { setSegmentAndGrade } = useCurricularStore()

  const [gradeTypeIndexSelected, setGradeTypeIndexSelected] = useState<number>(0)

  const { t } = useTranslation()

  const gradesLength = taughtDisciplines[gradeTypeIndexSelected]?.grades?.length

  const classes = useStyle({ gradesLength })

  const segmentCode = taughtDisciplines[gradeTypeIndexSelected]?.code ?? 'EI'
  const gradeCode = IGradeTypeEnum[segmentCode]

  const isValidGrade = Object.values(IGradeTypeEnum).includes(segmentCode)

  const handleShowSegmentSelector = useMemo(() => {
    if (!taughtDisciplines[gradeTypeIndexSelected]?.grades.length || !isValidGrade) return <></>

    return (
      <Grid item className={'selectorContainer'}>
        <Text className={'selectorLabel'}>{t('Segmento:')}</Text>
        <SegmentSelector taughtDisciplines={taughtDisciplines} selected={gradeTypeIndexSelected} setSelected={setGradeTypeIndexSelected} />
      </Grid>
    )
  }, [taughtDisciplines, gradeTypeIndexSelected])

  const handleShowEmptyState = useMemo(() => {
    if (!grades.length) {
      return (
        <Grid className={classes.notFoundContainer}>
          <EmptyStateComponent
            ErrorAvatar={<AvatarWithCrossedArms />}
            title={t('O ano escolar selecionado ainda não foi configurado')}
            errorMessage={t('Solicite a configuração das turmas à coordenação da sua escola.')}
            maxMessageWidth='500px'
          />
        </Grid>
      )
    }

    return (
      <Grid className={classes.notFoundContainer}>
        <EmptyStateComponent
          ErrorAvatar={<AvatarWithCrossedArms />}
          title={t('Você ainda não possui turmas adicionadas')}
          errorMessage={t('Escolha as turmas e os componentes curriculares em que leciona para começar.')}
          maxMessageWidth='500px'
        />
        <NavLink to={TAUGHT_DISCIPLINES} data-testid='my_classes_group_edition' style={{ textDecoration: 'none' }}>
          <Button sx={{ mt: 3, '& span': { marginLeft: '14px' } }}>
            <AddOutlinedIcon />
            <span>
              {t('Adicionar turmas')}
            </span>
          </Button>
        </NavLink>
      </Grid>
    )
  }, [grades])

  return (
    <Grid container className={classes.containerWrapper} flexDirection='column' flexWrap='nowrap'>
      <MyClassesHeader showEditButton={schoolPeriod?.current === true && !!taughtDisciplines[gradeTypeIndexSelected]?.grades.length} />
      <Grid container ml={0} mr={0} mt={3}>
        <Grid container className={classes.selectorsContainer}>
          <Grid item className={'selectorContainer'}>
            <Text className={'selectorLabel'}>{t('Ano Escolar:')}</Text>
            <SchoolPeriodSelector />
          </Grid>
          {handleShowSegmentSelector}
        </Grid>
        {
          !taughtDisciplines[gradeTypeIndexSelected]?.grades.length || !isValidGrade
            ? (
              handleShowEmptyState
            )
            : taughtDisciplines[gradeTypeIndexSelected]?.grades.map((grade, index) =>
              <Grid key={index}
                item sm={12}
                xs={12}
                mt={5}
              >
                <Grid item xs={12}>
                  <Text size='large' tabIndex={0} style={{ fontWeight: 500 }}>
                    {grade.name}
                  </Text>
                </Grid>
                <Grid sx={{
                  background: 'white'
                }}
                item xs={12} mt={2}
                display='flex' gap={1}
                flexWrap='wrap'
                data-testid={'my_classes_group_and_discipline_selection'}
                onClick={() => {
                  const segmment = taughtDisciplines[gradeTypeIndexSelected]
                  setSegmentAndGrade(
                    { id: segmment?.id ?? '', code: segmment?.code ?? '', name: segmment?.name ?? '' },
                    { id: grade?.id ?? '', code: grade?.code ?? '', name: grade?.name ?? '' }
                  )
                }}
                >
                  {
                    grade.classes.map(_class =>
                      <SubscriptionCard
                        tabIndex={0}
                        key={_class.id}
                        classId={_class.id}
                        className={_class.name}
                        disciplines={_class.disciplines}
                        linkTo={DASHBOARD_TEACHER(segmentCode, gradeCode.toString(), _class.id?.toString())}
                      />)
                  }
                </Grid>
              </Grid>
            )
        }
      </Grid>
    </Grid>
  )
}
