import { SvgIconProps } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import useStyles from './style'

export interface IIconProps extends Omit<SvgIconProps, 'size'> {
  titleAccess?: string
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxxl' | 'xs'
  iconColor?: 'white' | 'black' | string
}

export const Icon: React.FC<IIconProps> = ({ size = 'medium', children, iconColor, ...props }) => {
  const classes = useStyles()

  return (
    <SvgIcon {...props} className={`${classes.root} ${size}`} sx={{ color: iconColor }}>
      {children}
    </SvgIcon>
  )
}
