import { atom } from 'jotai'

export enum ActivityStateMachineEnum {
  IDLE = 'IDLE',
  GETING_ACTIVITY_DATA = 'GETING_ACTIVITY_DATA',
  ACTIVITY_UNAVAILABLE = 'ACTIVITY_UNAVAILABLE',
  ACTIVITY_UNAVAILABLE_FUND1 = 'ACTIVITY_UNAVAILABLE_FUND1',
  UNABLE_ANSWERS = 'UNABLE_ANSWERS',
  ENABLE_ANSWERS = 'ENABLE_ANSWERS',
  FAILED_TO_LOAD_ACTIVITY = 'FAILED_TO_LOAD_ACTIVITY',
  FAILED_TO_LOAD_ANSWERS = 'FAILED_TO_LOAD_ANSWERS',
  SENDING_ANSWERS = 'SENDING_ANSWERS',
  ANSWER_SENT_DEFAULT = 'ANSWER_SENT_DEFAULT',
  ANSWER_SENT_FUND1 = 'ANSWER_SENT_FUND1',
  NO_PROFILE_ERROR = 'NO_PROFILE_ERROR',
}

export const loadingAtom = atom(true)
export const isVerifyingRoutesAtom = atom(false)
export const isUnavailableAtom = atom(false)
export const isEditingAtom = atom(false)
export const sendLoadingAtom = atom(false)
export const hasResponseErrorAtom = atom(false)
export const errorMessageAtom = atom<string>('')
export const actvityStateMachineAtom = atom<ActivityStateMachineEnum>(ActivityStateMachineEnum.IDLE)
