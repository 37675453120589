import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAtomValue } from 'jotai/utils'

import { Pagination, QuestionList } from '..'
import ActivityEnunciate from './components/ActivityEnunciate'

import useStudentAnswer from '../../hooks/useStudentAnswer'

import { questionIndexAtom } from '../../atomStore'

import useStyles from './style'

export const ActivityBody = () => {
  const questionIndex = useAtomValue(questionIndexAtom)

  const { t } = useTranslation()
  const classes = useStyles()
  const { activity, isExpeditionView, isActivitySuggestedType, handleAnswerRecordCache, isAnswered, isEditing } = useStudentAnswer()

  if (!activity) return null

  useEffect(() => {
    if (activity && (isEditing || !isAnswered)) handleAnswerRecordCache()
  }, [questionIndex])

  return (
    <>
      {/* TODO: checar o porque de o enunciado estar marcado para não renderizar no mobile do fund1 */}
      <ActivityEnunciate />
      {
        activity.questions.length > 0 &&
        <>
          <div className={classes.awnserQuestions}>
            {t('Responda')}:
          </div>
          {!isExpeditionView && <Pagination questions={activity.questions} isActivitySuggestedType={isActivitySuggestedType} />}
          <QuestionList questions={activity.questions} />
        </>
      }
    </>
  )
}

export default ActivityBody
