import React from 'react'
import { useAtom } from 'jotai'

import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import { Icon, TabPanel } from 'components/design-system'

import { tabIndexModalTimelineAtom, openModalTimelineAtom, contentModalTimelineAtom } from '../atomStore'

import Pagination from '../Pagination'
import AnsweredFile from '../AnsweredFile'

import { Alternative } from '../Alternative'

import useStyles from './style'

export const ModalTimeline: React.FC = () => {
  const classes = useStyles()

  const [indexSelected, setIndexSelected] = useAtom(tabIndexModalTimelineAtom)
  const [open, setOpen] = useAtom(openModalTimelineAtom)
  const [content, setContent] = useAtom(contentModalTimelineAtom)
  const scroll = 'paper'

  const theme = useTheme<Theme>()

  const handleClose = () => {
    setOpen(!open)
    setIndexSelected(0)
    setContent(null)
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      sx={{
        '& .MuiPaper-root': {
          bgcolor: theme.colors.neutral.lightBase,
          height: '75vh'
        }
      }}
    >
      <DialogTitle id='scroll-dialog-title' className={classes.boxContainer}>
        <button data-testid='register_close' onClick={handleClose} className={classes.boxClose}>
          <Icon size='small' >
            <CloseIcon className={classes.boxIcon} />
          </Icon>
          <p className={classes.close}>Fechar</p>
        </button>
      </DialogTitle>
      <DialogContent dividers={false} className={classes.modalTabs}>
        {
          content?.records.map((item, index) => (
            <React.Fragment key={index}>
              <TabPanel key={item.id} value={indexSelected} index={index}>
                {item.url && <AnsweredFile src={item.url} modalOpen={true} />}
                {item.text && <p key={index} className={classes.text}>{item.text}</p>}
                {item.choiceAlternative && <Alternative alternative={item} />}
              </TabPanel>
            </React.Fragment>
          ))
        }
      </DialogContent>
      <DialogActions>
        <Pagination content={content?.records ?? []} dataTestid='register_paginate' />
      </DialogActions>
    </Dialog>
  )
}
