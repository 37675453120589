import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'

import { Grid } from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import { Text } from 'components/design-system'
import BackToTopWindow from 'components/common/BackToTopWindow'
import { StatusClassHandle, CurrentClass, InactiveClass } from 'components/common/'

import { IFindStudentTimelineResponse } from 'services/types'

import { monthsTitle } from 'utils/date'

import { confirmationModalPropsAtom } from './atomStore'

import TimelineComponent from './TimelineComponent'
import { ConfirmationModal } from './ConfirmationModal'
import { ModalTimeline } from './ModalTimeline'

import useStyle from './styles'

interface ICustomizedTimelineProps {
  timelineData: IFindStudentTimelineResponse[]
}

export const CustomizedTimeline: React.FC<ICustomizedTimelineProps> = ({ timelineData }) => {
  const classes = useStyle()
  const [confirmationModalProps] = useAtom(confirmationModalPropsAtom)

  const { t } = useTranslation()

  const HasNoRecord = () => {
    return (
      <Grid item display='flex' justifyContent='center'>
        <div className={classes.containerEmptyRecord}>
          <div className={classes.image} />
          <StatusClassHandle>
            <CurrentClass>
              <p>
                {t('Ainda não temos registros para exibir. Assim que entrar um registro você encontrará aqui.')}
              </p>
            </CurrentClass>
            <InactiveClass>
              <p>
                {t('Não foi encontrado histórico de registros.')}
              </p>
            </InactiveClass>
          </StatusClassHandle>
        </div>
      </Grid>
    )
  }

  return (
    <Grid item xs={12} className={classes.container}>
      {!timelineData.length
        ? <HasNoRecord />
        : <React.Fragment>

          {timelineData.map((contentUnit) => (
            <React.Fragment key={contentUnit.id}>
              {
                <div className={classes.cardMonth} id={`timeline-month-label-${contentUnit.monthNum}`}>
                  <Text type='body' color='dark'>
                    {monthsTitle[contentUnit.monthNum]}
                  </Text>
                </div>
              }

              <Timeline position='right' sx={{ p: '6px 4px' }}>
                {contentUnit.studentRecords.map((studentRecord) => (<TimelineComponent key={studentRecord.id} classId={Number(contentUnit.classId)} studentRecord={studentRecord} timelineData={timelineData} />))}
              </Timeline>
              {confirmationModalProps && <ConfirmationModal {...confirmationModalProps} />}
              <ModalTimeline />
            </React.Fragment>
          )
          )}
          <BackToTopWindow />
        </React.Fragment>
      }
    </Grid>
  )
}
