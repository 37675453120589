import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  text: {
    fontFamily: theme.font.fontFamily,
    lineHeight: theme.font.lineHeight.xs,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    color: theme.colorBrand.medium
  },
  selector: {
    '&.MuiOutlinedInput-root': {
      borderRadius: theme.borderRadius.sm,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid  ${theme.colors.neutral.light30}`,
        borderTop: `3px solid  ${theme.colors.neutral.light30}`
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colors.neutral.light30}`,
          borderTop: `3px solid  ${theme.colors.neutral.light30}`
        }
      },
      '&.Mui-focused': {
        color: theme.colorBrand.medium,
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid  ${theme.colors.neutral.light30}`,
          borderTop: `3px solid  ${theme.colors.neutral.light30}`
        }
      }
    }
  }
}))
