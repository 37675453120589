/* eslint-disable @typescript-eslint/no-unused-vars */
import { statusSortActionsEnum, IContentUnitResponse } from 'contentCacheManager'

export const sortContentUnits = (option: statusSortActionsEnum, unsortedList: IContentUnitResponse[]): IContentUnitResponse[] => {
  const sortWeights = {
    IDLE: {
      IDLE: 1,
      INPROGRESS: 2,
      DONE: 3
    },
    INPROGRESS: {
      IDLE: 2,
      INPROGRESS: 1,
      DONE: 3
    },
    DONE: {
      IDLE: 2,
      INPROGRESS: 3,
      DONE: 1
    }
  }

  return unsortedList
    .sort((a, b) => {
      if (option === 'ASC') {
        return a.suggested_position - b.suggested_position
      }
      if (option === 'DESC') {
        return b.suggested_position - a.suggested_position
      }

      const aWeight = a.status ? sortWeights[option][a.status] : 3
      const bWeight = b.status ? sortWeights[option][b.status] : 3

      return aWeight - bWeight
    })
}
