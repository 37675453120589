import { useEffect } from 'react'

import {
  IContentUnitResponse
} from 'services/types'
import { BusinessError } from 'navigation/BusinessError'
import { StudentActivityView } from './components/StudentActivityView'
import UnavailableActivity from './components/UnavailableActivityFund1'
import ActivityFetchingError from './components/ActivityFetchingError'
import { Completed } from '../common'

import useStudentAnswer from '../hooks/useStudentAnswer'

import { useOnlineStatus } from 'contentCacheManager'
import useInternetToast from '../hooks/useInternetToast'

interface IStudentActivityProps {
  content?: IContentUnitResponse
}

export const StudentActivity: React.FC<IStudentActivityProps> = ({ content }) => {
  const {
    activity,
    activityOrEvaluationId,
    cleanupState,
    getActivityAndAnswersData,
    activityStateMachine,
    loadOfflineAnswers,
    setIsLoading
  } = useStudentAnswer()
  const showInternetToast = useInternetToast()
  const isOnline = useOnlineStatus()

  useEffect(() => {
    cleanupState()

    if (!activityOrEvaluationId) {
      setIsLoading(true)
      return
    }

    getActivityAndAnswersData()
  }, [activityOrEvaluationId])

  useEffect(() => {
    if (!activityOrEvaluationId) return

    showInternetToast(isOnline)

    if (!isOnline) {
      loadOfflineAnswers()
    }
  }, [isOnline])

  switch (activityStateMachine) {
    // case 'ACTIVITY_UNAVAILABLE':
    //   return <UnavailableActivity contentId={content?.id?.toString() ?? ''} />
    case 'ANSWER_SENT_DEFAULT':
      return activity ? <Completed activity={activity} /> : <StudentActivityView content={content} />
    case 'FAILED_TO_LOAD_ACTIVITY':
      return <ActivityFetchingError />
    case 'FAILED_TO_LOAD_ANSWERS':
      return <ActivityFetchingError />
    case 'NO_PROFILE_ERROR':
      return <BusinessError error={'Erro ao selecionar turma'} />
    case 'ACTIVITY_UNAVAILABLE_FUND1':
      return <UnavailableActivity />
    default:
      return <StudentActivityView content={content} />
  }
}

export default StudentActivity
