import ErrorBoundary from 'components/common/ErrorBoundary'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { IActivityResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { CustomInfoTooltip } from 'components/common'

interface IActivityTitleProps {
  title: string
  customType?: IActivityResponse['custom_type']
  profileType?: IUserSchoolProfileTypeEnum
}

export const ActivityTitle: React.FC<IActivityTitleProps> = ({ title, customType, profileType }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleCustomTooltipText = (): string => {
    const texts: { [key in IUserSchoolProfileTypeEnum]?: string } = {
      [IUserSchoolProfileTypeEnum.teacher]: t('As atividades/avaliações originais são usadas como modelo. Ao liberá-las para a turma, você criará uma cópia e poderá editar quais questões serão enviadas aos estudantes.'),
      [IUserSchoolProfileTypeEnum.student]: t('As atividades/avaliações originais são usadas como modelo pelo(a) professor(a). Ao liberá-las para a turma, ele(a) criará uma cópia e poderá editar a quantidade de questões.')
    }

    return texts[profileType ?? IUserSchoolProfileTypeEnum.teacher] ?? t('As atividades/avaliações originais são usadas como modelo. Ao liberá-las para a turma, você criará uma cópia e poderá editar quais questões serão enviadas aos estudantes.')
  }

  return (
    <ErrorBoundary>
      {
        customType !== 'clone'
          ? <>
            <h1 className={classes.customTypeLabel}>{t('[Original]')} <CustomInfoTooltip text={handleCustomTooltipText()} /></h1>
          </>
          : null
      }
      <h1 className={classes.title}>{title}</h1>
    </ErrorBoundary>
  )
}

export default ActivityTitle
