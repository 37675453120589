import Slider, { SliderProps } from '@mui/material/Slider'
import makeStyles from './styles'

export interface IProgressBar extends SliderProps {
  background: string
  textColor: string
}

export const ProgressBar: React.FC<IProgressBar> = ({ ...props }) => {
  const styles = makeStyles(props)

  return <Slider {...props} className={styles.root} />
}
