import { atom } from 'jotai'

import { ILevelResponse, IStudentsAnswerByQuestionResponse } from 'contentCacheManager'
import { getStudentsAnswerByQuestion } from 'services/answer'
import { ICommentsResponse } from 'services/types/comments'
import { getCommentsRecords } from 'services/comments'

export const activeQuestionAtom = atom<number | string>('')
export const schoolLevelsAtom = atom<ILevelResponse[]>([])

// students answer
export const studentsAnswerAtom = atom<IStudentsAnswerByQuestionResponse[]>([])
export const isLoadingstudentsAnswerAtom = atom<boolean>(true)

export const getStudentsAnswerByQuestionAtom = atom(
  null,
  async (get, set, data: { classId: string, activityId: string, questionId: string | number }) => {
    set(isLoadingstudentsAnswerAtom, true)

    try {
      const response = await getStudentsAnswerByQuestion(data.classId, data.activityId, data.questionId)

      if (response.success) {
        set(studentsAnswerAtom, response.data)
      }
      set(isLoadingstudentsAnswerAtom, false)
    } catch (error) {
      set(studentsAnswerAtom, [])
      set(isLoadingstudentsAnswerAtom, false)
    }
  }
)

// comments
export const currentAnswerCommentOpenAtom = atom<number | null>(null)
export const commentsByAnswerAtom = atom<ICommentsResponse[]>([])
export const isLoadingCommentsByAnswerAtom = atom<boolean>(true)

export const getCommentsByAnswerAtom = atom(
  null,
  async (get, set, data: { userSchoolProfile: number, activityId: number, classId: number, questionId: number }) => {
    set(isLoadingCommentsByAnswerAtom, true)

    try {
      const response = await getCommentsRecords(data.userSchoolProfile, data.activityId, data.classId, data.questionId)

      if (response.success) {
        set(commentsByAnswerAtom, response.data)
      }
      set(isLoadingCommentsByAnswerAtom, false)
    } catch (error) {
      set(commentsByAnswerAtom, [])
      set(isLoadingCommentsByAnswerAtom, false)
    }
  }
)
