import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const ITEM_HEIGHT = 48

interface IDropDownProps {
  name?: string
  dataTestid?: string
  handleClose?: () => void
}

export const DropDown: React.FC<IDropDownProps> = ({ children, name, dataTestid, handleClose }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const onHandleClose = () => {
    handleClose?.()
    setAnchorEl(null)
  }

  return (
    <div onClick={e => {
      e.preventDefault()
      e.stopPropagation()
    }}>
      <IconButton
        id='long-button'
        name={name}
        aria-label='more'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          background: 'rgba(255, 255, 255, 0.8)',
          width: '24px',
          height: '24px'
        }}
        data-testid={dataTestid}
      >
        <MoreVertIcon name={name} />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
          'aria-hidden': true,
          tabIndex: -1
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={onHandleClose}
        onClose={onHandleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
            borderRadius: '8px',
            boxShadow: '0px 4px 8px rgba(20, 20, 20, 0.16)'
          }
        }}
      >
        {children}
      </Menu>
    </div>
  )
}

export default DropDown
