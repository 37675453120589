import { Grid } from '@mui/material'
import useStyle from './styles'

import { NoSchoolPeriodFound } from 'pages/NoSchoolPeriodFound'

export const SchoolPeriodNotFound = () => {
  const classes = useStyle()

  return (
    <Grid container className={classes.containerWrapper} flexDirection='column'>
      <NoSchoolPeriodFound />
    </Grid>
  )
}
