import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { SchoolOutlined } from '@mui/icons-material'

import { Tag } from 'components/design-system'
import { truncateExtension } from 'utils/string'

import useStyles from '../../style'
import { StepClassSelectProps } from '../../types'

export const StepClassSelect: React.FC<StepClassSelectProps> = ({ schoolSelected, classSelected, setClassSelected, gradeSelected, setGradeSelected }: StepClassSelectProps) => {
  const classes = useStyles()
  const theme = useTheme()

  const render = (gradeId: string) => (
    <Grid
      item
      key={gradeId}
      display='flex'
      className={classes.customButton}
      flexDirection='column'
      sx={{
        cursor: 'initial',

        [theme.breakpoints.down('sm')]: {
          border: 0,
          padding: 0
        }
      }}
    >
      <Grid item
        gap={2}
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        className={classes.infoClassName}
        sx={{
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
          }
        }}
      >
        <SchoolOutlined />
        <span>{validateGroup(schoolSelected?.classes ?? [])[gradeId].name}</span>
      </Grid>

      <Grid item display='flex' flexDirection='row' alignItems='center' gap={2} flexWrap='wrap'>
        {validateGroup(schoolSelected?.classes ?? [])[gradeId]?.classes?.map((grade: any) => (
          <Tag
            dataTestid='qr_code_select_the_class'
            key={grade.id}
            onClick={() => {
              setGradeSelected(validateGroup(schoolSelected?.classes ?? [])[gradeId])
              setClassSelected(grade)
            }}
            color={theme.colorBrand.darkest}
            background={classSelected?.id === grade.id ? theme.colorBrand.lightest : theme.colors.neutral.light10}
          >
            {truncateExtension(grade.name, 12)}
          </Tag>
        ))}
      </Grid>
    </Grid>
  )

  const renderMultiseriate = () => (
    <Grid
      item
      key='multiseriate'
      display='flex'
      className={classes.customButton}
      flexDirection='column'
      sx={{
        cursor: 'initial',

        [theme.breakpoints.down('sm')]: {
          border: '0px !important',
          padding: '0px !important'
        }
      }}
    >
      <Grid item
        gap={2}
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        className={classes.infoClassName}
        sx={{
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start'
          }
        }}
      >
        <SchoolOutlined />
        <span>Multisseriadas</span>
      </Grid>

      <Grid item display='flex' flexDirection='row' alignItems='center' gap={2} flexWrap='wrap'>
        {validateGroupMultiseriate(schoolSelected?.classes ?? [])?.classes?.map((grade: any, index: number) => (
          <Tag
            data-testid='qr_code_select_the_class'
            key={index}
            onClick={() => {
              setGradeSelected(grade)
              setClassSelected({ ...validateGroupMultiseriate(schoolSelected?.classes ?? []), id: grade.id })
            }}
            color={theme.colorBrand.darkest}
            background={gradeSelected?.id === grade.id ? theme.colorBrand.lightest : theme.colors.neutral.light10}
          >
            {grade.name}
          </Tag>
        ))}
      </Grid>
    </Grid>
  )

  const validateGroup = (data: any[]): any => {
    return data.reduce((previousValue: any, currentValue) => {
      const grade = currentValue.grades[0]

      // MULTISERIADA
      if (currentValue?.grades?.length > 1) {
        return {
          ...previousValue,
          [grade.id]: {
            ...(previousValue[grade.id]),
            name: grade?.name,
            id: grade?.id,
            code: grade?.code,
            classes: (previousValue[grade.id] ?? { classes: [] }).classes ?? []
          }
        }
      }

      return {
        ...previousValue,
        [grade.id]: {
          ...(previousValue[grade.id]),
          name: grade?.name,
          id: grade?.id,
          code: grade?.code,
          classes: [...(previousValue[grade.id]?.classes ?? []), currentValue]
        }
      }
    }, {})
  }

  const validateGroupMultiseriate = (data: any[]): any => {
    const aux = { name: 'Multisseriada', classes: [] } as any
    for (const row of data) {
      if ((row?.grades ?? []).length > 1) {
        aux.classes.push(row)
      }
    }

    if (aux?.classes?.length > 0) {
      return aux
    }

    return null
  }

  return (
    <Grid container display='flex' flexDirection='column' justifyContent='flex-start' sx={{ height: '100%', flexDirection: 'column', flexFlow: 'column' }}>
      <span className={classes.txtTitle}>Selecione a turma da {schoolSelected?.name}, que você deseja realizar a atividade:</span>
      <Grid item display='flex' flexDirection='column' className={classes.contentStep}>
        {Object.keys(validateGroup(schoolSelected?.classes ?? []))?.map((gradeId) => (
          <>
            {validateGroup(schoolSelected?.classes ?? [])[gradeId]?.classes?.length > 0 && render(gradeId)}
          </>
        ))}

        {/* MULTISERIADA */}
        {validateGroupMultiseriate(schoolSelected?.classes ?? []) && (
          <>
            {renderMultiseriate()}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default StepClassSelect
