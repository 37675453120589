import Grid from '@mui/material/Grid'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import RouterPrompt from 'navigation/components/RouterPrompt'
import useStyles from './style'
import { Text } from 'components/design-system'
import useStudentAnswer from '../../hooks/useStudentAnswer'
import { contentUnitAtom } from 'pages/ContentUnit/atomStore'
import { IGradeTypeEnum } from 'services/types/grade'
import { CacheConfirmationModal } from './components'

export const LeavingActivityWarning = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    allQuestionsAnswered,
    isAnswered,
    isEditing,
    isActivitySuggestedType
  } = useStudentAnswer()
  const [contentUnit] = useAtom(contentUnitAtom)

  const dataTestIdCancelButton = isActivitySuggestedType ? 'respond_activities_continue_responding_activity' : 'respond_assessments_continue_responding_activity'
  const dataTestIdConfirmButton = isActivitySuggestedType ? 'respond_activities_leave_without_completing_activity' : 'respond_assessments_leave_without_completing_activity'

  const isBlockSituation = !isAnswered && allQuestionsAnswered

  // Do not show modal cached responses for EI
  const willShowCacheModal = contentUnit?.grade?.grade_type ? contentUnit?.grade?.grade_type >= IGradeTypeEnum.EF1 : false

  if (willShowCacheModal) {
    return <CacheConfirmationModal dataTestIdCancelButton={dataTestIdCancelButton} dataTestIdConfirmButton={dataTestIdConfirmButton} isBlockSituation={isBlockSituation} />
  }

  return (
    <RouterPrompt
      when={isBlockSituation}
      btnCancelDataTestId={dataTestIdCancelButton}
      btnLeaveDataTestId={dataTestIdConfirmButton}
    >
      <Grid item xs={12}>
        <Text className={classes.contentTextTitle} type='heading'>
          {t('Tem certeza que deseja sair?')}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text className={classes.contentText} type='heading'>
          {t(`Você está saindo da atividade e suas ${isEditing ? 'alterações' : 'respostas'} não serão registradas`)?.toString()}
        </Text>
      </Grid>
    </RouterPrompt>
  )
}

export default LeavingActivityWarning
