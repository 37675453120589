import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    cardStudentAnswerContainer: {
      backgroundColor: theme.colors.neutral.lightBase,
      border: `1px solid ${theme.colors.neutral.light20}`,
      borderRadius: theme.borderRadius.md,
      marginTop: theme.spacingStack.xxs
    },
    cardStudentAnswerTextName: {
      color: theme.colors.neutral.dark20,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xs
    },
    cardStudentAnswerTextDate: {
      color: theme.colors.neutral.light40,
      fontWeight: theme.font.fontWeight.medium,
      fontSize: theme.font.fontSize.xs
    },
    cardStudentAnswerContentInfo: {
      padding: theme.spacingInline.xxs,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    cardStudentAnswerContentLevel: {
      padding: '8px 24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(230, 230, 230, 0.32)',
      borderBottomLeftRadius: theme.borderRadius.md,
      borderBottomRightRadius: theme.borderRadius.md,
      flexWrap: 'wrap',

      '& svg': {
        color: theme.colorBrand.medium,
        fontSize: theme.font.fontSize.md
      }
    },
    cardStudentAnswerContentLevelValue: {
      width: '32px',
      height: '32px',
      border: `1px solid ${theme.colorBrand.medium}`,
      borderRadius: theme.borderRadius.circular,
      color: theme.colorBrand.medium,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 4,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.xxs
    },
    cardStudentAnswerTxtLevelValue: {
      color: theme.colorBrand.medium,
      fontWeight: theme.font.fontWeight.semibold,
      fontSize: theme.font.fontSize.sm
    },
    cardStudentAnswerContentTag: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      borderRadius: 6,
      fontSize: theme.font.fontSize.sm
    }
  })
)
