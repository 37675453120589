import React, { useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { useAtom } from 'jotai'

import { Grid, useTheme, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Text, Icon } from 'components/design-system'
import { DropdownSelector, ConfirmationModal } from 'components/common'

import { ISubscriptionsSessionResponse, IClassSubscriptionProfile } from 'services/types'

import { useRouter } from 'navigation/hooks/useRouter'
import { ROOT } from 'navigation/CONSTANTS'
import { selectorClassOpenAtom } from 'navigation/atomStore'

import { useClassesGroups } from './utils'

import useStyle from './style'

export const ClassSelector: React.FC = () => {
  const { subscription, schoolPeriod, setSubscription, setProfile, setSchoolPeriod } = useStore()
  const [activeModalConfirmation, setActiveModalConfirmation] = useState<boolean>(false)

  const [isSelectorOpen, setIsSelectorOpen] = useAtom(selectorClassOpenAtom)

  const [selectedSubscription, setSelectedSubscription] = useState<Partial<IClassSubscriptionProfile> | null>(subscription)
  const { t } = useTranslation()
  const classes = useStyle()
  const theme = useTheme()
  const tagginRef = useRef<HTMLDivElement>(null)
  const tagginRefInside = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const { classesGroups } = useClassesGroups()
  const selectedSameSubscription = selectedSubscription?.id === subscription?.id

  const handleSelectClass = (selectedClass: IClassSubscriptionProfile) => {
    setSelectedSubscription(selectedClass)
  }

  const handleCloseModal = () => {
    setActiveModalConfirmation(false)
  }

  const handleConfirmSelector = () => {
    if (!selectedSubscription?.class?.name) return
    if (!selectedSubscription.schoolPeriod?.current && schoolPeriod?.current && selectedSubscription.class.name) return setActiveModalConfirmation(true)
    handleModalConfirm()
  }

  const handleModalConfirm = () => {
    setActiveModalConfirmation(false)
    setIsSelectorOpen(false)

    const selectedSchoolPeriod = selectedSubscription?.schoolPeriod
    const selectedProfile = selectedSubscription?.actualProfile

    if (!selectedSchoolPeriod || !selectedSubscription || !selectedProfile) return

    setSchoolPeriod(selectedSchoolPeriod)
    setProfile(selectedProfile)
    setSubscription(selectedSubscription as ISubscriptionsSessionResponse)

    router.push(ROOT)
  }

  const handleSelectorClick = () => {
    setIsSelectorOpen(oldIsSelectorOpen => !oldIsSelectorOpen)
    if (!selectedSameSubscription) {
      setSelectedSubscription(subscription)
    }
  }

  const renderGroups = useCallback(() => {
    return classesGroups.map(({ subgroups, ...group }, i) => (
      <Grid className={`${classes.groupContainer} ${group.className}`} key={i}>
        <Grid className={'groupHeader'}>
          <Icon size='medium'>
            {group.icon}
          </Icon>
          <Text className={classes.groupTitle}>{group.title}</Text>
        </Grid>
        {
          subgroups.map((subgroupArr, index) => {
            const isCurrent = !!subgroupArr[0].schoolPeriod.current
            if (!isSelectorOpen) return <React.Fragment key={index} />
            return (
              <React.Fragment key={index}>
                <div ref={tagginRef} data-testid='class_change_retract_classes_list' />
                <Accordion ref={tagginRefInside} data-testid='class_change_expand_classes_list' key={`${subgroupArr[0].schoolPeriod.id}index${index}`} defaultExpanded={isCurrent} square={false} onChange={(event, iseExpanded) => iseExpanded ? tagginRefInside?.current?.click() : tagginRef?.current?.click()}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: group.className === 'currentClassesContainer' ? theme.colorBrand.medium : theme.colors.contentType.default }} />}
                    aria-controls='panel1a-content'
                    sx={{ margin: '8px 0', padding: '0px' }}
                    className={'subgroupNavHeader'}
                  >
                    <Text size='small' className={`${classes.groupLabel} ${group.className}`}>{subgroupArr[0].schoolPeriod.title}</Text>
                  </AccordionSummary>
                  <AccordionDetails className={'subgroupItemsList'}>
                    {subgroupArr?.map(option => {
                      const isActive = subscription?.class?.id === option.class.id
                      const isFocused = selectedSubscription?.class?.id === option.class.id
                      return (
                        <a
                          key={`subgroupItemsList - ${option.id}`}
                          className={`${classes.optionContainer} ${group.className} ${isActive && 'active'} ${isFocused && 'focused'}`}
                          onClick={() => handleSelectClass(option)}
                          data-testid={'class_change_select_class'}
                        >
                          {option.class.name}
                        </a>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
              </React.Fragment>
            )
          }
          )}
      </Grid>
    ))
  }, [classesGroups, selectedSubscription, isSelectorOpen, subscription])

  return (
    <>
      <DropdownSelector
        placeholder={subscription?.class?.name ?? t('Selecione sua turma')}
        onConfirmLabel={classesGroups.length ? t('Confirmar troca') : undefined}
        onConfirm={handleConfirmSelector}
        disableOnConfirm={selectedSameSubscription}
        colorTheme={schoolPeriod?.current === false ? theme.colors.contentType.default : theme.colorBrand.medium}
        label={schoolPeriod?.title}
        isOpen={isSelectorOpen}
        className={classes.dropdownContainer}
        setIsOpen={setIsSelectorOpen}
        dataTestId={isSelectorOpen ? 'class_change_retract_classes_list' : 'class_change_expand_classes_list'}
        confirmDataTestId={'class_change_confirm_change'}
        backButtonLabel={t('Turma')}
        handleSelectorClick={handleSelectorClick}
      >
        <Grid className={classes.container}>
          {renderGroups()}
        </Grid>
      </DropdownSelector >

      <ConfirmationModal
        onConfirmModal={handleModalConfirm}
        onCloseModal={handleCloseModal}
        isOpen={activeModalConfirmation}
        highLightedText={selectedSubscription?.class?.name ?? ''}
        title={t('Deseja ser redirecionado para a turma abaixo?')}
        description={t('Se estiver respondendo uma atividade ou avaliação, lembre-se de enviar as respostas antes da troca.')}
        confirmLabel={t('Ir para a turma')}
        confirmDatatestId={'confirm_class_change_confirm'}
        cancelDatatestId={'confirm_class_change_cancel'}
      />
    </>
  )
}
