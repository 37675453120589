import { createStyles, makeStyles } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'

export default makeStyles((theme: CustomTheme) => createStyles({
  paginantionWrapper: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '-2px 0px 4px rgb(20 20 20 / 16%)',
    zIndex: 1
  },
  paginationControl: {
    backgroundColor: theme.colors.neutral.lightBase,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    margin: theme.spacingInset.xs
  },
  paginantionButton: {
    display: 'flex',
    color: theme.colorBrand.medium,
    border: 'none',
    background: 'transparent',
    padding: '10px',
    cursor: 'pointer',
    '&:disabled': {
      color: theme.colors.neutral.light40,
      cursor: 'default'
    },
    '& svg': {
      fontSize: '22px'
    }
  },
  paginationDivider: {
    width: '1px',
    height: '18px',
    background: theme.colors.neutral.light10
  }
}))
