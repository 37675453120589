import React from 'react'
import { Grid, Skeleton, Box } from '@mui/material'
import useStyle from './styles'

export const BigNumberSkeleton: React.FC = () => {
  const classes = useStyle()

  return (
    <Grid className={`${classes.bigNumbersItem} ${classes.bigNumbersItemLoading}`}>
      <Box>
        <Skeleton variant='circular' width={70} height={70} animation='wave' />
      </Box>
      <Skeleton variant='text' sx={{ width: '100%' }} height={45} animation='wave' />
    </Grid>
  )
}
