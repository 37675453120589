import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { useHistory } from 'react-router'
import { useStore } from 'store'

import { IUserSchoolProfileTypeEnum } from 'services/types'

import { StepSchoolSelect } from './SchoolSelect'
import { StepClassSelect } from './ClassSelect'
import { StepConfirmation } from './Confirmation'
import { StepError } from './Error'

import { STATES, StepsContainerProps, SchoolSelected, ClassSelected, GradeSelected } from '../../types'
import useStyles from '../../style'
import { CustomHeader } from '../CustomHeader'
import { CustomFooter } from '../CustomFooter'
import { CONTENT_UNIT_STUDENT_ACTIVITY, CONTENT_UNIT_TEACHER_ACTIVITY } from 'navigation/CONSTANTS'

export const StepsContainer: React.FC<StepsContainerProps> = ({ schoolsClassesResponse, activityId, grade, gradeCode, contentUnitId }: StepsContainerProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { profile, session, setProfile, setSubscription } = useStore()

  const [currentState, setCurrentState] = useState<STATES>(STATES.idle)
  const [schoolSelected, setSchoolSelected] = useState<SchoolSelected | null>(null)
  const [classSelected, setClassSelected] = useState<ClassSelected | null>(null)
  const [gradeSelected, setGradeSelected] = useState<GradeSelected | null>(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [maxStep, setMaxStep] = useState(2)

  const states = {
    [STATES.idle]: <></>,
    [STATES.choose_school]: (
      <>
        <Grid className={classes.content}>
          <StepSchoolSelect schoolsClassesResponse={schoolsClassesResponse} schoolSelected={schoolSelected} setSchoolSelected={setSchoolSelected} />
        </Grid>
        <CustomFooter
          isDisable={!schoolSelected}
          maxStep={maxStep} currentStep={currentStep}
          onSubmit={() => {
            if (schoolSelected?.classes?.length === 1) {
              setClassSelected(schoolSelected?.classes[0])
              setGradeSelected(schoolSelected?.classes[0].grades[0])
              setCurrentState(STATES.sucess)
              setCurrentStep(3)
              return
            }
            setCurrentState(STATES.choose_class)
            setCurrentStep(2)
          }}
          onCancel={() => history.push('/')}
        />
      </>
    ),
    [STATES.choose_class]: (
      <>
        <Grid className={classes.content}>
          <StepClassSelect
            schoolsClassesResponse={schoolsClassesResponse}
            schoolSelected={schoolSelected}
            classSelected={classSelected} setClassSelected={setClassSelected}
            gradeSelected={gradeSelected} setGradeSelected={setGradeSelected}
          />
        </Grid>
        <CustomFooter
          isDisable={!classSelected}
          maxStep={maxStep} currentStep={currentStep}
          onSubmit={() => {
            setCurrentState(STATES.sucess)
            setCurrentStep(3)
          }}
          onCancel={() => {
            if (maxStep > 1) {
              setCurrentStep(1)
              setCurrentState(STATES.choose_school)
              setClassSelected(null)
              setGradeSelected(null)
              return
            }

            history.push('/')
          }}
        />
      </>
    ),
    [STATES.sucess]: (
      <>
        <Grid className={classes.content}>
          <StepConfirmation schoolSelected={schoolSelected} classSelected={classSelected} gradeSelected={gradeSelected} maxStep={maxStep} />
        </Grid>
        <CustomFooter
          isConfirmation
          isDisable={false}
          maxStep={maxStep}
          currentStep={currentStep}
          onSubmit={() => {
            if (profile?.type === IUserSchoolProfileTypeEnum.student) {
              const subscriptionSelected = session?.subscriptions?.filter(sub => sub.class && sub.user_school_profile.type === IUserSchoolProfileTypeEnum.student)?.find(studentSub => studentSub.id === classSelected?.subscription_id)

              if (subscriptionSelected) {
                setSubscription(subscriptionSelected)

                const selectProfileFromClass = session?.profiles.find(cur => cur.id === subscriptionSelected?.user_school_profile.id)
                if (selectProfileFromClass) setProfile(selectProfileFromClass)
              }

              setTimeout(() => {
                history.push(CONTENT_UNIT_STUDENT_ACTIVITY(`${contentUnitId ?? ''}`, `${activityId ?? ''}`))
              }, 500)
              return
            }

            const profileSelected = session?.profiles?.find(profile => profile.id === schoolSelected?.userSchoolProfile)
            if (profileSelected) setProfile(profileSelected)
            const segmentName = grade.grade_type.code ?? '0'
            setTimeout(() => {
              history.push(CONTENT_UNIT_TEACHER_ACTIVITY(segmentName, gradeCode, `${classSelected?.id ?? ''}`, `${contentUnitId ?? ''}`, `${activityId ?? ''}`))
            }, 500)
          }}
          onCancel={() => {
            if (schoolSelected?.classes?.length === 1) {
              if (schoolSelected?.classes[0]?.grades?.length === 1) {
                setCurrentState(STATES.choose_school)
                setCurrentStep(1)
                return
              }
            }

            setCurrentStep(maxStep)
            setCurrentState(STATES.choose_class)
          }}
        />
      </>
    ),
    [STATES.error]: (
      <>
        <Grid className={classes.content}>
          <StepError grade={grade} />
        </Grid>
        <CustomFooter
          isError
          isDisable={false}
          maxStep={maxStep}
          currentStep={currentStep}
          onSubmit={() => history.push('/')}
          onCancel={() => {
            // This is intentional
          }}
        />
      </>
    )
  }

  const existsClasses = () => {
    return schoolsClassesResponse?.schools?.some(school => (school?.classes ?? []).length > 0)
  }

  const validateData = () => {
    if ((schoolsClassesResponse?.schools ?? []).length === 0) {
      setCurrentState(STATES.error)
      return
    }

    if (!existsClasses()) {
      setCurrentState(STATES.error)
      return
    }

    if (schoolsClassesResponse?.schools?.length === 1) {
      if (schoolsClassesResponse?.schools[0]?.classes?.length === 1) {
        setSchoolSelected(schoolsClassesResponse?.schools[0])
        setClassSelected(schoolsClassesResponse?.schools[0].classes[0])
        setGradeSelected(schoolsClassesResponse?.schools[0].classes[0].grades[0])
        setCurrentState(STATES.sucess)
        setMaxStep(0)
        return
      }

      setSchoolSelected(schoolsClassesResponse?.schools[0])
      setCurrentState(STATES.choose_class)
      setMaxStep(1)
      return
    }

    setCurrentState(STATES.choose_school)
    setCurrentStep(1)
    setMaxStep(2)
  }

  useEffect(() => {
    validateData()
  }, [])

  return (
    <>
      <CustomHeader
        maxStep={maxStep}
        currentStep={currentStep}
        isError={currentState === STATES.error}
        onClose={() => history.push('/')}
      />
      {states[currentState]}
    </>
  )
}

export default StepsContainer
