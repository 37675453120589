import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    dataTag: {
      backgroundColor: `${theme.colors.neutral.dark30}`,
      fontWeight: theme.font.fontWeight.regular,
      color: theme.colors.neutral.lightBase,
      '&.active': {
        backgroundColor: theme.colors.negative.feedbackMedium,
        color: theme.colors.neutral.lightBase
      }
    }
  })
)
