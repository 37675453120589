import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import {
  IQuestionResponse,
  IAnswersRequest,
  IRecordRequest,
  IFileRecordRequest,
  IUserSchoolProfileTypeEnum,
  ActivityControlStatusEnum,
  ILevelResponse
} from 'services/types'
import { ShortText } from './ShortText'
import { InputFilePreview } from 'components/common'
import { useTranslation } from 'react-i18next'
import {
  answersAtom,
  isAnsweredAtom,
  inUploadingProcess,
  activityStatusControlAtom,
  studentLevelsAtom,
  schoolLevelsAtom,
  isStudentViewAtom
} from '../../../../../../../../../../atomStore'
import { useAtom } from 'jotai'
import AnsweredFile from './AnsweredFile'
import useStyles from './style'
import OnlyProfile from 'components/common/OnlyProfile'
import { IPreviewState } from 'components/common/InputFilePreview/atomStore'
import useStudentAnswer from 'pages/ContentUnit/components/Activity/hooks/useStudentAnswer'
import { useStore } from 'store'

interface IRecordProps {
  question: IQuestionResponse
}

export const Record: React.FC<IRecordProps> = ({ question }) => {
  const [answers, setAnswers] = useAtom(answersAtom)
  const [isAnswered] = useAtom(isAnsweredAtom)
  const [, setInUploadingProcess] = useAtom(inUploadingProcess)
  const [activityStatusControl] = useAtom(activityStatusControlAtom)
  const [studentLevels] = useAtom(studentLevelsAtom)
  const [schoolLevels] = useAtom(schoolLevelsAtom)
  const [isStudentView] = useAtom(isStudentViewAtom)
  const { t } = useTranslation()
  const classes = useStyles()
  const { isActivitySuggestedType } = useStudentAnswer()
  const { contentId, activityId, evaluationId } = useParams<{ contentId: string, activityId: string, evaluationId: string }>()

  const { subscription } = useStore()

  const currentAnswer = answers.find(a => a.question_id === question.id?.toString())
  const levelAnswer = studentLevels.find(a => a.question_id === question.id)

  let currentLevel
  if (levelAnswer && schoolLevels) {
    if (levelAnswer.level) {
      currentLevel = (levelAnswer && (schoolLevels.find(cur => cur.id === (levelAnswer?.level as ILevelResponse).id)))
    }
  }

  const setRecordText = (text: string, question: IQuestionResponse) => {
    const currentAnswer = answers.find(a => a.question_id === question.id?.toString())
    const idRecord = currentAnswer?.records[0].text_record?.id
    setRecordAnswer({ text_record: { id: idRecord, text } }, question)
  }

  const setRecordFiles = (uploads: IPreviewState[], question: IQuestionResponse) => {
    const fileRecords: IFileRecordRequest[] = uploads.map(upload => ({
      url: upload.url,
      upload_id: upload.id,
      transaction_id: upload.transaction_id
    }))
    setRecordAnswer({ file_records: fileRecords }, question)
    setInUploadingProcess(false)
  }

  const setRecordAnswer = (record: IRecordRequest, question: IQuestionResponse) => {
    const currentAnswer = answers.find(a => a.question_id === question.id?.toString())
    const oldAnswers = answers.filter(a => a.question_id !== currentAnswer?.question_id)

    const newFileRecords = record.file_records !== undefined ? record.file_records : currentAnswer?.records[0].file_records
    const newTextRecord = record.text_record !== undefined ? record.text_record : currentAnswer?.records[0].text_record

    const newAnswer: IAnswersRequest = {
      id: currentAnswer?.id?.toString(),
      question_id: question.id?.toString(),
      records: [{
        source_type: currentAnswer?.records[0]?.source_type,
        id: currentAnswer?.records[0]?.id,
        file_records: newFileRecords,
        text_record: newTextRecord
      }]
    }
    setAnswers([...oldAnswers, newAnswer])
  }

  return (
    <>
      {
        activityStatusControl !== ActivityControlStatusEnum.AVAILABLE &&
        <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.student]}>
          {
            ActivityControlStatusEnum.RELEASED === activityStatusControl && levelAnswer
              ? <>
                <Grid className={classes.RecordContainer}>
                  <div className={classes.RecordTitle}>{t('Registros da atividade')}:</div>
                  {currentLevel
                    ? <Grid item display={'flex'} alignItems={'center'}>
                      <div className={classes.currentLevel}>
                        {currentLevel?.value}
                      </div>
                      <div className={classes.RecordText}>{t('Nota da tarefa')}</div>
                    </Grid>
                    : ''
                  }
                </Grid>
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentAnswer?.records[0]?.text_record?.text ?? ''
                  }}
                />
              </>
              : <ShortText
                label={t('Sua Resposta')}
                value={currentAnswer?.records[0]?.text_record?.text ?? ''}
                onChange={(text => setRecordText(text, question))}
                disabled={isAnswered}
              />
          }
        </OnlyProfile>
      }
      <OnlyProfile profileTypes={[IUserSchoolProfileTypeEnum.teacher]}>
        {
          !isStudentView && (currentAnswer?.records[0]?.text_record?.text && currentAnswer?.records[0]?.text_record?.text !== '') &&
          <ShortText
            label={t('Sua Resposta')}
            value={currentAnswer?.records[0]?.text_record?.text ?? ''}
            onChange={text => setRecordText(text, question)}
            disabled={isAnswered}
          />
        }
      </OnlyProfile>
      {
        isAnswered
          ? <Grid container mt={4} spacing={2}>
            {
              !isStudentView && currentAnswer?.records[0]?.file_records?.map((mock, i) =>
                <Grid item key={i} xs={6} sm={3}>
                  <AnsweredFile src={mock.url} />
                </Grid>)
            }
          </Grid>
          : <>
            <Grid item xs={12} mt={4}>
              <p className={classes.text}>
                {t('Você também pode adicionar arquivos ao seu registro')}:
              </p>
            </Grid>
            <Grid item xs={12} mb={2}>
              <div className={classes.uploadFileWrapper}>
                <InputFilePreview
                  currentQuestion={question}
                  onChange={urls => setRecordFiles(urls, question)}
                  onProgress={progress => {
                    if (progress > 1) {
                      setInUploadingProcess(true)
                    }
                  }}
                  availableArchivesTypes={'.jpeg,.jpg,.png,.gif,.webp,.mp4,.mov,.pdf,.mp3,.wav,.m4a,.ogg'}
                  description={
                    t(`Formatos de imagem suportados: JPG, PNG, GIF, WEBPG
                      Formatos de vídeo suportados: MP4 e MOV
                      Formatos de áudio suportados: MP3, WAV, M4A e OGG
                      Formatos de documentos suportados: PDF`)
                  }
                  initialPreviews={currentAnswer?.records[0]?.file_records?.map(record => ({
                    fileRecordId: Number(record.id),
                    id: record.upload_id,
                    url: record.url,
                    transaction_id: record.transaction_id,
                    name: record.url,
                    thumb: record.url,
                    ext: record.url
                  }))}
                  dataTestid={isActivitySuggestedType ? 'respond_activities_add_files_to_registry' : 'respond_assessments_add_files_to_registry'}
                  maxFileSize={50}
                  metaData={{
                    question_id: question.id,
                    content_unit_id: contentId,
                    class_id: subscription ? subscription.class.id : '',
                    activity_id: activityId || evaluationId,
                    user_school_profile: subscription ? subscription.user_school_profile.id : ''
                  }}
                />
              </div>
            </Grid>
          </>
      }
    </>
  )
}

export default Record
