import React, { useCallback, useEffect, useState } from 'react'
import Cover, { CoverSkeleton } from 'pages/ContentUnit/components/Cover'

import { IContentUnitResponse } from 'services/types'
import { getContentUnitV2 } from 'services/content-unit'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

interface ContentUnitHeaderProps {
  contentId: string | number
}

export const ContentUnitHeader: React.FC<ContentUnitHeaderProps> = ({ contentId }) => {
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)
  const [contentUnitData, setContentUnitData] = useState<IContentUnitResponse | null>(null)

  useEffect(() => {
    void fetchContentUnitData()
  }, [contentId])

  const fetchContentUnitData = useCallback(async () => {
    if (!contentId) {
      return
    }

    setStateMachine(StateMachineEnum.LOADING)

    const response = await getContentUnitV2(String(contentId))

    if (response.success) {
      setContentUnitData(response.data)
    } else {
      setContentUnitData(null)
    }

    setStateMachine(StateMachineEnum.READY)
  }, [contentId])

  const Ready = () => {
    if (!contentUnitData) {
      return <></>
    }

    return (
      <Cover
        title={contentUnitData.title}
        disciplines={contentUnitData.disciplines}
        suggestedPosition={contentUnitData.suggested_position}
        imageUrl={contentUnitData.cover}
        code={contentUnitData.code}
        showReturnButton={false}
      />
    )
  }

  const states = {
    IDLE: '',
    LOADING: <CoverSkeleton />,
    READY: <Ready />,
    ERROR: ''
  }

  const renderMachineState = () => {
    return states[stateMachine]
  }

  return (
    <>
      {renderMachineState()}
    </>
  )
}
