import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  icon: {
    '& svg': {
      marginTop: theme.spacingInline.quarck
    }
  },
  inverted: {
    '& svg': {
      transform: 'scaleX(-1)'
    }
  }
}))
