import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.support.colorSupport03,
    fontSize: theme.font.fontSize.sm,
    fontWeight: theme.font.fontWeight.semibold,
    '& svg': {
      color: theme.colors.support.colorSupport01,
      fontSize: theme.font.fontSize.sm
    },
    // mobile
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.font.fontSize.xs
    }
  },
  input: {
    width: '100%',
    textAlign: 'left'
  },
  backButton: {
    width: '100%',
    color: `${theme.colors.support.colorSupport02}!important`,
    textAlign: 'center'
  },
  submitButton: {
    width: '100%',
    background: theme.colorBrand.medium,
    '&.active': {
      background: `${theme.colors.support.colorSupport01} !important`,
      border: `1px solid ${theme.colors.support.colorSupport01} !important`,
      '&:hover': {
        background: '#602680 !important',
        border: '1px solid #602680 !important'
      }
    }
  },
  messageError: {
    color: 'red',
    fontSize: theme.font.fontSize.xs
  },
  alertError: {
    color: `${theme.colors.negative.feedbackDarkest}!important`,
    fontSize: `${theme.font.fontSize.xxs}!important`,
    fontWeight: `${theme.font.fontWeight.semibold}!important`,
    padding: '0px 8px!important',
    textAlign: 'left',
    '& .MuiAlert-icon': {
      margin: '0px 8px'
    }
  }
}))
