import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiCheckbox-root': {
      width: '16px',
      height: '16px',
      margin: '0px 8px',
      '&.Mui-checked': {
        color: theme.colorBrand.medium
      }
    },
    '&.MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontFamily: theme.font.fontFamily,
        fontSize: theme.font.fontSize.xs,
        fontWeight: theme.font.fontWeight.medium,
        lineHeight: theme.font.lineHeight.xs,
        color: theme.colors.neutral.dark20
      }
    }
  }
}))
