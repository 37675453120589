import MUITooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { styled, useTheme, useMediaQuery } from '@mui/material'
import { Tag } from 'components/design-system'

export interface ITooltipProps {
  text: string
  label?: string
  component?: JSX.Element
  minWidth?: number
  maxWidth?: number
  placement?: TooltipProps['placement']
}

const CoustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colorBrand.darkest,
    color: theme.colors.neutral.lightBase,
    fontSize: theme.font.fontSize.sm,
    fontFamily: theme.font.fontFamily,
    fontWeight: theme.font.fontWeight.medium,
    lineHeight: theme.font.fontSize.lg,
    padding: theme.spacingInset.xs,
    borderRadius: theme.borderRadius.sm
  }
}))

export const Tooltip = (props: ITooltipProps) => {
  const { text, label, component, maxWidth = 253, minWidth = 253, placement = 'top-start' } = props

  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <CoustomTooltip
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      title={label ? <><b>{label}: </b>{text}</> : <>{text}</>}
      placement={placement}
      componentsProps={{
        popper: { sx: { minWidth, maxWidth } }
      }}
    >
      <a onClick={() => setShowTooltip(!showTooltip)} style={{ paddingBottom: isMobile ? theme.spacingInset.xxs : 0 }}>
        {
          label &&
            <Tag>{label}</Tag>
        }
        {
          component ?? <React.Fragment />
        }
      </a>
    </CoustomTooltip>
  )
}
