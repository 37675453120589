import { useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material'

import { useStore } from 'store'

import useStyles from './style'
import { useHistory, useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { activityIndexSelectedAtom, allActivities } from 'pages/ContentUnit/atomStore'
import { IUserSchoolProfileTypeEnum } from 'contentCacheManager'
import { CONTENT_UNIT_TEACHER_CONTENT, CONTENT_UNIT_STUDENT_EXPEDITION, CONTENT_UNIT_STUDENT_CONTENT } from 'navigation/CONSTANTS'

export interface CardProps {
  position?: number
  title: string
  subtitles?: string[]
  text?: string
  id?: number | string
  expanded?: boolean
  iconComponent?: React.ReactNode
  endAdornment?: React.ReactNode
  dataTestid?: string
  href?: string
  isActive?: boolean
  idActivity?: number | string
  onClick?: () => void
  idSection?: number | string
  disabled?: boolean
  iconType?: string
  onlyIcon?: boolean
}

export const Card = ({
  expanded = false,
  iconType = 'content',
  iconComponent,
  position,
  title,
  subtitles,
  idSection,
  text,
  endAdornment,
  dataTestid,
  idActivity,
  isActive: isActiveRaw = false,
  onlyIcon = false,
  disabled = false,
  onClick,
  id
}: CardProps) => {
  const { gradeTypeCode, gradeCode, contentId, sectionId, activityId, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, contentId: string, sectionId?: string, activityId?: string, classId: string }>()

  const cardExpanded = expanded
  const titleRef = useRef<HTMLDivElement | null>()

  const { subscription, profile } = useStore()
  const profileType = subscription?.user_school_profile?.type ?? profile?.type

  const history = useHistory()

  const [isActive, setIsActive] = useState(isActiveRaw)
  const [activities] = useAtom(allActivities)
  const [, setActivityIndexSelected] = useAtom(activityIndexSelectedAtom)

  const props = { cardExpanded, isActive, position, titleWidth: titleRef.current?.clientWidth, disabled, profileType }
  const classes = useStyles({ ...props })

  useEffect(() => {
    if (!sectionId && !activityId) return

    setIsActive(false)
    if (sectionId && idSection) {
      setIsActive(sectionId === idSection?.toString())
    } else if (activityId && idActivity) {
      setIsActive(activityId === idActivity?.toString())
      setActivityIndexSelected(activities?.findIndex(a => a.id === +activityId) ?? 0)
    }
  }, [sectionId, activityId])

  const getLink = () => {
    if (profileType === IUserSchoolProfileTypeEnum.teacher) {
      return CONTENT_UNIT_TEACHER_CONTENT(gradeTypeCode, gradeCode, classId, contentId, idSection as string)
    } else if (profileType === IUserSchoolProfileTypeEnum.student && idActivity) {
      return CONTENT_UNIT_STUDENT_EXPEDITION(contentId, idActivity as string)
    }
    return CONTENT_UNIT_STUDENT_CONTENT(contentId, idSection as string)
  }

  const handleClick = () => {
    // titleRef?.current?.click()

    if (onClick) onClick()

    if (sectionId ?? activityId) {
      const link = getLink()
      history.push(link)
    }
  }

  return (
    <Grid
      ref={(el) => {
        titleRef.current = el
      }}
      className={classes.cardWrapper}
      id={isActive ? 'card-selected' : `${id}`}
      onClick={disabled ? () => { } : handleClick}
      data-testid={dataTestid}
    >
      <div className={classes.wrapperDefault}>
        {
          position &&
          <div className={classes.position}>{position}</div>
        }
        <div className={classes.contentWrapper}>
          <div className={classes.titleDefault}>
            {iconComponent ?? null}
            {!onlyIcon ? title : null}
            {endAdornment ?? null}
          </div>
          <div className={classes.navTitles}>
            {
              subtitles?.map((subtitle: string, index) =>
                <div key={index} className={classes.textDefault}>
                  {subtitle}
                </div>)
            }
          </div>
          <div className={classes.textDefault}>
            {text}
          </div>
        </div>
      </div>
    </Grid>
  )
}
