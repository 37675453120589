import { Tag } from 'components/design-system'
import { ActivityControlStatusEnum } from 'services/types'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react'
import * as SvgIcons from '@mui/icons-material'
import useStyles from './style'

interface IStatusTag {
  activityStatusControl: number
  suggestedApplicationType: 'activity' | 'evaluation'
}

export const StatusTag: React.FC<IStatusTag> = ({
  activityStatusControl,
  suggestedApplicationType
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const activityStatusControlStatus =
    ActivityControlStatusEnum[activityStatusControl]

  const renderReleased = () => {
    const SvgIcon = SvgIcons.Check
    const label =
      suggestedApplicationType === 'activity'
        ? t('Atividade vistada')
        : t('Avaliação corrigida')
    return (
      <Tag className={classes.tagReleased}>
        <SvgIcon />
        {label}
      </Tag>
    )
  }

  const typesToRender: { [index: string]: () => ReactElement } = {
    RELEASED: renderReleased
  }

  if (
    activityStatusControlStatus &&
    activityStatusControlStatus in typesToRender
  ) {
    return typesToRender[activityStatusControlStatus]()
  }

  return <></>
}
