import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) => createStyles({

  historyButton: {
    marginTop: `${theme.spacingStack.xxs} !important`,
    fontSize: theme.font.fontSize.xs,
    padding: `${theme.spacingInset.xxs} ${theme.spacingInset.xs} !important`
  }
}))
