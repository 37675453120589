import React, { useMemo, useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import useStyles from './style'
import { DesktopTimePicker } from '@mui/lab'
import dayjs from 'dayjs'
import objectSupport from 'dayjs/plugin/objectSupport'
import { TextField as TextFieldMUI } from '@mui/material'

interface IInputTimeProps {
  label?: string
  inputTime?: Date | null
  onChange: (date: Date | null) => void
  dataTestid?: string | null
  defaultTimeIsNow?: boolean
  ToolbarComponent?: React.JSXElementConstructor<any> | null
}

export const InputTime = ({ label, inputTime = null, onChange, dataTestid, defaultTimeIsNow = false, ToolbarComponent }: IInputTimeProps) => {
  dayjs.extend(objectSupport)
  const [isInputFocused, setInputFocused] = useState(false)
  const [inputValue, setInputValue] = useState<string | null>('')
  const [openPicker, setOpenPicker] = useState(false)
  const showNowAsLable = useMemo(() => !inputTime && !inputValue && !isInputFocused && defaultTimeIsNow, [inputTime, inputValue, isInputFocused, defaultTimeIsNow])
  const inputRef = React.createRef<HTMLDivElement>()
  const classes = useStyles()

  const handleClose = () => {
    setOpenPicker(false)
  }

  const handleDataTaging = () => {
    // solução para funcionar o tagamento
    inputRef?.current?.click()
  }

  return (
    <>
      <div ref={inputRef} data-testid={dataTestid} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopTimePicker
          ampm={false}
          openTo='hours'
          views={['hours', 'minutes']}
          inputFormat='HH:mm'
          ignoreInvalidInputs
          open={openPicker}
          mask='__:__'
          label={showNowAsLable ? 'Agora' : label }
          value={inputTime}
          onChange={onChange}
          onOpen={() => setOpenPicker(true)}
          onClose={handleClose}
          renderInput={(params) => (
            <TextFieldMUI
              {...params}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              className={classes.selector}
              onClick={handleDataTaging}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
          )}
          showToolbar={defaultTimeIsNow}
          // @ts-expect-error
          ToolbarComponent={() => <ToolbarComponent setOpenPicker={setOpenPicker} setInputValue={setInputValue} />}
        />
      </LocalizationProvider>
    </>
  )
}
