// global components
import { Header } from './Header'
import { ScheduleCard } from 'components/common'
import useStyles from '../styles'
import { IScheduleActivitiesPendentResponse, IUserSchoolProfileTypeEnum } from 'services/types'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowRight } from '@mui/icons-material'
import { Icon } from 'components/design-system'
import { Grid } from '@mui/material'
import { PENDING_ACTIVITIES } from 'navigation/CONSTANTS'
import { NavLink, useParams } from 'react-router-dom'
import { useStore } from 'store'

interface IReadyTypes {
  dropDown: boolean
  setDropDown: React.Dispatch<React.SetStateAction<boolean>>
  activitiesPendent: IScheduleActivitiesPendentResponse[]
}

export const Ready = ({ dropDown, setDropDown, activitiesPendent }: IReadyTypes) => {
  const { gradeTypeCode, gradeCode, classId } = useParams<{ gradeTypeCode: string, gradeCode: string, classId: string }>()
  const { profile, subscription } = useStore()
  const profileType = profile?.type
  const classes = useStyles({ profileType: profileType })
  const { t } = useTranslation()

  const classIdSubscription = subscription?.class.id?.toString() ?? '0'

  const seeAllDataTestId = profileType === IUserSchoolProfileTypeEnum.teacher ? 'activities_to_correct_see_all' : 'activities_to_answer_see_all'

  const Content = () => {
    if (!dropDown) {
      return <></>
    }

    return (
      <>
        <Grid item className={classes.cardsContainer}>
          {
            activitiesPendent?.map((card, i) => (
              <div key={i} className={classes.cardsItemContainer}>
                <ScheduleCard activitiesPendents={card} ClassName={classes.paddingCards} type={card.suggestedApplicationType} />
              </div>
            ))
          }
          <div className={classes.cardsFadeBackground} />
        </Grid>
        <Grid item className={classes.cardsBottom}>
          <NavLink to={PENDING_ACTIVITIES(gradeTypeCode, gradeCode, classId ?? classIdSubscription)} className={classes.titleDrop} data-testid={seeAllDataTestId} id='see_all_mobile'>
            <p>{t('Ver todos')}</p>
            <Icon titleAccess={t('Ver todos')} size='medium'>
              <KeyboardArrowRight />
            </Icon>
          </NavLink>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Header dropDown={dropDown} setDropDown={setDropDown} />
      <Content />
    </>
  )
}
